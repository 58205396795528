import { connect } from "react-redux";
import { withRouter } from "react-router";
import AlertList from "./AlertList";
import {
  fetchAlertList,
  setAlertPage,
  resetAlertPage,
  resetError,
} from "./data/action";
import { push } from "connected-react-router";
import {
  resetFilterParams,
  setAlertFilterParams,
} from "../../components/filteralert_form/data/action";
import { resetEventTimeStamp, setEventTimeStamp } from "../history/data/action";
import {
  resetFromDate,
  resetToDate,
  resetPlateNo,
  resetAlertType,
  resetZone,
  resetDistrict,
  resetField,
  resetCamera,
  resetOffender,
  resetVehicle,
  resetLane,
} from "../../components/filterhistory_form/data/action";

function mapStateToProps(state) {
  return {
    alertList: state.alertList.alertList,
    alertCount: state.alertList.alertCount,
    //showFilterWindow: state.filter.showFilterWindow,
    filterParams: state.filteralert.filterParams,
    warningCount: state.warningDetails.warningCount.habitualOffendersCount,
    multipleEwayBillCount:
      state.warningDetails.multipleEwayBillCount.multipleEwaybillCount,
    flammableGoodsCount: state.warningDetails.flammableGoodsCount,
    page: state.alert.page,
    rowsPerPage: state.alert.rowsPerPage,
    loading: state.progress.loading,
    error: state.alert.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAlertList: (page, limit, type, filterParams) =>
      dispatch(fetchAlertList(page, limit, type, filterParams)),
    navigateTo: (url) => dispatch(push(url)),
    resetFilterParams: () => dispatch(resetFilterParams()),
    setAlertFilterParams: (filterAlertParams) =>
      dispatch(setAlertFilterParams(filterAlertParams)),
    setAlertPage: (page) => dispatch(setAlertPage(page)),
    resetAlertPage: () => dispatch(resetAlertPage()),
    resetError: () => dispatch(resetError()),
    setEventTimeStamp: (time) => dispatch(setEventTimeStamp(time)),
    resetEventTimeStamp: () => dispatch(resetEventTimeStamp()),

    //filterparams
    resetFromDate: () => dispatch(resetFromDate()),
    resetToDate: () => dispatch(resetToDate()),
    resetPlateNo: () => dispatch(resetPlateNo()),
    resetAlertType: () => dispatch(resetAlertType()),
    resetZone: () => dispatch(resetZone()),
    resetDistrict: () => dispatch(resetDistrict()),
    resetField: () => dispatch(resetField()),
    resetCamera: () => dispatch(resetCamera()),
    resetOffender: () => dispatch(resetOffender()),
    resetVehicle: () => dispatch(resetVehicle()),
    resetLane: () => dispatch(resetLane()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AlertList)
);
