import { SET_LOCATION_ID, RESET_LOCATION_ID } from "./constants";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const setLocationId = (filterParams, oldData) => {
  let data = {
    newData: filterParams,
    oldData: oldData,
  };
  return (dispatch) => {
    dispatch(setLocationId(data));
  };

  function setLocationId(data) {
    return { type: SET_LOCATION_ID, data };
  }
};

export const resetLocationId = () => {
  return (dispatch) => {
    dispatch(resetLocationId());
  };
  function resetLocationId() {
    return { type: RESET_LOCATION_ID };
  }
};
