import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "connected-react-router";
import { Grid, Container } from "@material-ui/core";
import StorageHandler from "../../../util/storage/index";
class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const permissions = new StorageHandler().getUserPermissions();
    {
      for (var i = 0; i < permissions.length; i++) {
        if (
          permissions[i].permissions.view_status &&
          permissions[i].sub_menus.length === 0
        ) {
          return this.props.navigateTo(permissions[i].url);
          break;
        } else if (
          permissions[i].permissions.view_status &&
          permissions[i].sub_menus.length !== 0
        ) {
          for (var j = 0; j < permissions[i].sub_menus.length; j++)
            if (permissions[i].sub_menus[j].permissions.view_status) {
              return this.props.navigateTo(permissions[i].sub_menus[j].url);
              break;
            }
        }
      }
    }
  }
  render() {
    return (
      <>
        <center>
          <Container>
            <Grid container spacing={3} style={{ marginTop: "200px" }}>
              <Grid item xs={12}>
                <h3>Loading...</h3>
              </Grid>
            </Grid>
          </Container>
        </center>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => dispatch(push(url)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LandingPage)
);
