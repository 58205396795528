import Storagehandler from "../../storage/";

//Request Interceptors
export const onRequest = (config) => {
  let localStorageHandler = new Storagehandler();
  const token = localStorageHandler.getToken();
  if (config.url.indexOf("login") < 0)
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
};
export const onRequestError = (error) => {
  return error;
};
