import { connect } from "react-redux";
import { withRouter } from "react-router";
import SummaryActionReport from "./summaryAction";

function mapStateToProps(state, ownProps) {
  return {
    reportFilterParams: state.actionsummaryreportfilter.reportFilterParams,
  };
}

function mapDispatchToProps(dispatch) {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SummaryActionReport)
);
