import Storagehandler from "../../storage/";

//Response Interceptors
export const onResponse = (response) => {
  response.headers["Cache-Control"] = "no-store";
  return response;
};

export const onResponseError = (error) => {
  const responseError = { ...error };

  if (error.response === undefined) {
    responseError.error = {
      message: "Network Error, Please check your internet connection",
    };
    return responseError;
  } else if (error.response.status === 401) {
    new Storagehandler().removeUser();

    if (error.response.config.url.indexOf("login") < 0) {
      window.location.reload("/login");
    }
  } else if (error.response.status === 500) {
    responseError.error = {
      message: "Service unavailable. Please try after some time.",
    };
  } else if (error.response.status === 502) {
    responseError.error = {
      message: "Service temporarily overloaded. Please try after some time.",
    };
  } else if (error.response.status === 400) {
    responseError.error = {
      message: "Bad Request. Please try after some time.",
    };
  } else if (error.response.status === 405) {
    responseError.error = {
      message: "Method Not Allowed. Please try after some time.",
    };
  } else {
    responseError.error = {
      message: "Service unavailable. Please try after some time.",
    };
  }

  return responseError;
};
