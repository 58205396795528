import APIRequest from "../util/api/APIRequest";

class WarningService {
  fetchActualWarningList = (page, limit, type, filterParams) => {
    return APIRequest({
      url: `api/alerts/`,
      method: "POST",
      params: {
        page,
        limit,
        type,
      },
      data: filterParams,
    });
  };

  fetchWarningRecord = (id, event_timestamp) => {
    return APIRequest({
      url: `api/history/${id}`,
      method: "GET",
      params: {
        event_timestamp,
      },
    });
  };
  warningsnextprev = (data) => {
    return APIRequest({
      url: `api/alertsnextprev`,
      method: "POST",
      data: data,
    });
  };
}
export default WarningService;
