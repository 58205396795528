import React, { Fragment } from "react";
import {
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  TextField,
  FormGroup,
  IconButton,
  Tooltip,
  FormHelperText,
  Divider,
  MenuItem,
} from "@material-ui/core";
import StyledCheckbox from "./styledCheckbox";
import "./style.css";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import classNames from "classnames";
import propTypes from "prop-types";
import { Checkbox } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterListIcon from "@material-ui/icons/FilterList";
import io from "socket.io-client";
import Storagehandler from "../../../util/storage/";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import { ReactComponent as BackIcon } from "../../../assets/SvgIcons/back.svg";

import { ReactComponent as DownArrow } from "../../../assets/SvgIcons/dropdown.svg";
import { ReactComponent as CameraIcon } from "../../../assets/Visual Assets/camera.svg";

const styles = (theme) => ({
  //
  root: {
    width: "100%",
    borderRadius: theme.border.radius,
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
  },
  root1: {
    backgroundColor: theme.toolbar.backgroundColor,
  },
  spacer: {
    flex: "1 1 1%",
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: "33px",
    paddingRight: "31px",
    paddingTop: "6px",
  },
  butt: {
    background: "white",
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "5px",
    paddingBottom: "4.5px",
    height: "57px",
  },
  cursor: {
    cursor: "pointer",
  },

  filterbutton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.palette.secondary.main,
    color: theme.colors.whiteColor,
  },
  filterclearButton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.BlueButton,
    color: theme.colors.whiteColor,
  },
  filtercloseButton: {
    fontWeight: theme.font.fontWeight,
    color: theme.colors.blackColor,
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },

  popIcon: {
    marginTop: "6px",
    marginRight: "6px",
  },
  popIconOpen: {
    marginTop: "4px",
    marginRight: "6px",
  },
  textNoOptions: {
    letterSpacing: "0px",

    opacity: 1,
    fontSize: "18px",
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  buttons: {
    marginTop: "14px",
    marginRight: "9px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    width: "111px",
    height: "42px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    textTransform: "none",
  },
  btn: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    "&:hover": {
      background: "#E7EBF4 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginRight: "4px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  btns: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  tableTitle: {
    marginTop: "10px",
    // marginLeft: "10px",
    paddingLeft: "6px",
    width: "275px",
    height: "22px",
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
  },
  iconButton: {
    paddingLeft: "0px",
    paddingTop: "18px",
  },
  grid: {
    marginTop: "25px",
    paddingTop: "22px",
    paddingRight: "14px",
    paddingLeft: "14px",
    borderRadius: theme.border.radius,
  },
  grid2: {
    paddingTop: "14px",
    paddingRight: "14px",
    paddingLeft: "14px",
    borderRadius: theme.border.radius,
  },
  grid2: {
    paddingTop: "14px",
    paddingRight: "14px",
    paddingLeft: "14px",
    borderRadius: theme.border.radius,
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
    paddingBottom: "24px",
  },
  divider: {
    marginTop: "15px",

    height: "0px",
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
  filterIcon: {
    width: "25px",
    height: "23px",
    color: "#A4AFC6",
    opacity: 1,
  },
  actions1: {
    marginTop: "9px",
    paddingRight: "16px",
  },
  textStyle: {
    color: "#ffffff",
  },
  root2: {
    color: "red",
    backgroundColor: "white",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "0px solid red",
    borderRadius: "5px",
    "& .MuiInputLabel-shrink": {
      paddingLeft: "7px",
      paddingTop: "5px",
    },
    "& MuiInputLabel-formControl ": {
      // paddingLeft: "20px",
      left: "10",
    },
    "& MuiInputLabel-root ": {
      paddingLeft: "50px",
    },
    "& MuiFormLabel-root ": {
      paddingLeft: "10px",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
  textField1: {
    marginTop: "19px",
    // paddingLeft: "14px",
    // paddingTop: "12px",
    // paddingBottom: "13px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    height: "42px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    //  border: "1px solid #C4CCDC",
    borderRadius: "5px",
    opacity: 1,
  },
  textField2: {
    marginTop: "19px",
    // paddingLeft: "14px",
    // paddingTop: "12px",
    // paddingBottom: "13px",
    // width: "178px",
    height: "40px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // border: "1px solid #C4CCDC",
    borderRadius: "5px",
    opacity: 1,
    letterSpacing: "0px",
    color: "#797C82",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },
  },
  dialogBox: {
    // width: "327px",
    height: "161px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: "20px",
  },
  dialogContent: {
    //width: "247px",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "11px",

    marginRight: "17px",
    marginLeft: "16px",

    font: "normal normal normal 16px Karla",
    // marginRight: "17px",
    // marginLeft: "16px",
  },
  dialogActions: {
    "&.MuiDialogActions-root": {
      justifyContent: "center",
    },
  },
  gridContainer: {
    display: "flex",
    flexDirection: "row",
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textMenu: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "6px 16px",
  },
  dropIcon: {
    marginTop: "8px",
    marginRight: "8px",
  },
  button: {
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    opacity: 1,
    textTransform: "none",
    // paddingLeft: "38px",
    // paddingRight: "37px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    height: "42px",
    width: "121px",
  },
  clearButton: {
    width: "121px",
    height: "42px",
    // marginRight: "10px",
    textTransform: "none",
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.colors.BlueButton,
    textAlign: "center",
    width: "121px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
  },
  btns: {
    paddingTop: "5px",
    paddingRight: "25%",
  },
  btnsEdit: {
    paddingRight: "25%",
    marginTop: "-3px",
  },
  btnGrid: {
    padding: 0,
    paddingLeft: "12px",
  },

  cardGrid: {
    marginTop: "-8px",
  },

  selectedCard: {
    //height: "84px",
    width: "172px",
    //borderColor: theme.colors.blueColor,
    borderRadius: "10px 10px 10px 10px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #00000012",
    border: "2px solid #1BB99A",
    opacity: 1,
    marginLeft: "23px",
    marginRight: "-8px",
    marginBottom: "15px",
    cursor: "pointer",
  },
  assignedCard: {
    //height: "84px",
    width: "172px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #00000012",
    border: "2px solid #1BB99A",
    opacity: 1,
    borderRadius: "10px 10px 10px 10px",
    //borderColor: theme.palette.secondary.main,
    marginLeft: "23px",
    marginRight: "-8px",
    marginBottom: "15px",
    cursor: "pointer",
  },
  unassignedCard: {
    //height: "84px",
    width: "172px",
    border: "none",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #00000012",
    opacity: 1,
    borderRadius: "10px 10px 10px 10px",
    // backgroundColor: theme.CameraCard.backgroundColor,
    marginLeft: "23px",
    marginRight: "-8px",
    marginBottom: "15px",
    cursor: "pointer",
  },

  locationName: {
    textAlign: "left",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingLeft: "9px",
    //marginTop: "-23px",
  },
  cameraIcon: {
    paddingLeft: "9px",
    paddingBottom: "3px",
  },
  cameraName: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    paddingBottom: "3px",
  },

  textFielddes: {
    paddingTop: "2%",
    marginLeft: "2%",
    margigRight: "2%",
  },
  // button: {
  //   width: "100%",
  //   marginBottom: "2%",
  //   backgroundColor: theme.palette.secondary.main,
  //   color: theme.colors.whiteColor,
  //   fontWeight: theme.font.fontWeight,
  // },
  closeButton: {
    width: "100%",
    marginBottom: "2%",
    fontWeight: theme.font.fontWeight,
  },

  filterbutton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.palette.secondary.main,
    color: theme.colors.whiteColor,
  },
  filterclearButton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.BlueButton,
    color: theme.colors.whiteColor,
  },
  filtercloseButton: {
    fontWeight: theme.font.fontWeight,
    color: theme.colors.blackColor,
  },

  listCamButton: {
    width: "100%",
    marginTop: "9%",
    backgroundColor: theme.palette.secondary.main,
    color: theme.colors.whiteColor,
    fontWeight: theme.font.fontWeight,
  },
  listAllButton: {
    width: "100%",
    marginTop: "4%",
    backgroundColor: theme.palette.secondary.main,
    color: theme.colors.whiteColor,
    fontWeight: theme.font.fontWeight,
  },
  // btns: {
  //   paddingTop: "2%",
  //   paddingBottom: "2%",
  // },
  err: {
    paddingLeft: "1%",
  },
  dense: {
    marginTop: 16,
  },
  checkbox: {
    marginLeft: "10%",
  },
  btns2: {
    paddingTop: "2%",
    paddingRight: "20%",
  },
  textFieldGrid: { display: "flex", justifyContent: "center" },
  checkboxColor: {
    color: theme.palette.secondary.main,
  },
  visibilityColor: {
    color: theme.visibilityIcon.color,
  },

  title: {
    fontSize: 18,
    color: theme.CameraCard.titleColor,
    textAlign: "left",
  },
  formHelperText: {
    color: theme.colors.redColor,
  },
  gridBorder: {
    borderRadius: "15px",
    border: "none",
    background: "#F1F4FB 0% 0% no-repeat padding-box",
    opacity: 1,
    // maxHeight: "311px",
    // minHeight: "210px",
    // overflowY: "auto"
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      // maxHeight: "100vh",

      overflowY: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      minHeight: "12vh",
      maxHeight: "45vh",
      overflowY: "auto",
    },
    // /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      // maxHeight: "100vh",

      overflowY: "auto",
    },
  },
});

class chooseLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testData: [],
      Zone: [],
      District: [],
      Field: [],
      CameraList: [],
      Cameras: [],
      zone: "",
      changeFlag: true,
      district: "",
      field: "",
      selectedId: "",
      camera_id: "",
      locationData: [],
      checked: false,
      user_type: "",
      dialStatus: false,
      allSelectStatus: false,
      errors: {
        zone: "",
        district: "",
        field: "",
      },
      rules: { zone: { required: true } },
    };
    this.socket = null;
  }
  componentDidMount() {
    let storageHandler = new Storagehandler();
    let userType = storageHandler.getUserType();
    this.props.fetchZone();
    this.props.fetchStateCamera();

    if (
      this.props.locationId !== "" &&
      this.props.locationId !== undefined &&
      this.props.locationId !== null
    ) {
      // this.setState({ locationData: this.props.locationId.cameraId });

      this.setState({
        ...this.state,
        locationData: this.props.locationId.cameraId,
        user_type: userType.user_type,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.Zone != this.props.Zone) {
      this.setState({
        ...this.state,
        Zone: this.props.Zone,
      });
    }
    if (prevProps.District != this.props.District) {
      this.setState({
        ...this.state,
        District: this.props.District,
      });
    }
    if (prevProps.Field != this.props.Field) {
      this.setState({
        ...this.state,
        Field: this.props.Field,
      });
    }
    if (prevProps.CameraList != this.props.CameraList) {
      this.setState({
        ...this.state,
        CameraList: this.props.CameraList,
        // locationData: [],
        changeFlag: true,
      });
    }
    if (prevProps.locationId !== this.props.locationId) {
      if (this.props.appBarPath === "/camera") {
        this.props.navigateTo("/camera");
      } else this.props.navigateTo("/dashboard");
    }
  }
  handleZone = (e, value) => {
    e.preventDefault();
    this.setState(
      {
        ...this.state,
        zone: value,
        changeFlag: false,
        errors: {
          ...this.state.errors,
          zone: "",
        },
      },
      () => {
        this.setState(
          {
            ...this.state,
            district: "",
            field: "",
          },
          () => {
            this.props.fetchDistrict(this.state.zone.zone_id);

            this.props.fetchZoneCamera(this.state.zone.zone_id);
          }
        );
        if (
          this.state.zone.zone_id != "" &&
          this.state.field == "" &&
          this.state.district == ""
        ) {
          this.setState({
            ...this.state,
            locationData: [],
          });
          this.setState({ dialStatus: false });
        }
        // else if (
        //   this.state.zone.zone_id != "" &&
        //   this.state.field == "" &&
        //   this.state.district != ""
        // ) {
        //   this.setState({
        //     ...this.state,
        //     locationData: []
        //   });
        //   this.props.fetchDistrictCamera(this.state.district.district_id);
        //   this.setState({ dialStatus: false });
        // } else if (
        //   this.state.zone.zone_id != "" &&
        //   this.state.field != "" &&
        //   this.state.district != ""
        // ) {
        //   this.setState({
        //     ...this.state,
        //     locationData: []
        //   });
        //   this.props.fetchFieldCamera(this.state.field.location_id);
        //   this.setState({ dialStatus: false });
        // }
      }
    );
  };

  handleDistrict = (e, value) => {
    e.preventDefault();
    this.setState(
      {
        ...this.state,
        district: value,
        changeFlag: false,
        errors: {
          ...this.state.errors,
          district: "",
        },
      },
      () => {
        this.setState(
          {
            ...this.state,
            field: "",
          },
          () => {
            this.props.fetchField(this.state.district.district_id);
            this.props.fetchDistrictCamera(this.state.district.district_id);
          }
        );

        // if (
        //   this.state.zone.zone_id != "" &&
        //   this.state.field == "" &&
        //   this.state.district == ""
        // ) {
        //   this.setState({
        //     ...this.state,
        //     locationData: []
        //   });
        //   this.props.fetchZoneCamera(this.state.zone.zone_id);
        //   this.setState({ dialStatus: false });
        // }
        // else
        if (
          this.state.zone.zone_id != "" &&
          this.state.field == "" &&
          this.state.district != ""
        ) {
          this.setState({
            ...this.state,
            locationData: [],
          });

          this.setState({ dialStatus: false });
        }
        //  else if (
        //   this.state.zone.zone_id != "" &&
        //   this.state.field != "" &&
        //   this.state.district != ""
        // ) {
        //   this.setState({
        //     ...this.state,
        //     locationData: []
        //   });
        //   this.props.fetchFieldCamera(this.state.field.location_id);
        //   this.setState({ dialStatus: false });
        // }
      }
    );
  };
  handleField = (e, value) => {
    e.preventDefault();
    this.setState(
      {
        ...this.state,
        field: value,
        changeFlag: false,
        errors: {
          ...this.state.errors,
          field: "",
        },
      },
      () => {
        // if (
        //   this.state.zone.zone_id != "" &&
        //   this.state.field == "" &&
        //   this.state.district == ""
        // ) {
        //   this.setState({
        //     ...this.state,
        //     locationData: []
        //   });
        //   this.props.fetchZoneCamera(this.state.zone.zone_id);
        //   this.setState({ dialStatus: false });
        // } else if (
        //   this.state.zone.zone_id != "" &&
        //   this.state.field == "" &&
        //   this.state.district != ""
        // ) {
        //   this.setState({
        //     ...this.state,
        //     locationData: []
        //   });
        //   this.props.fetchDistrictCamera(this.state.district.district_id);
        //   this.setState({ dialStatus: false });
        // } else
        if (
          this.state.zone.zone_id != "" &&
          this.state.field != "" &&
          this.state.district != ""
        ) {
          this.setState({
            ...this.state,
            locationData: [],
          });
          this.props.fetchFieldCamera(this.state.field.location_id);
          this.setState({ dialStatus: false });
        }
      }
    );
  };
  onReset = () => {
    this.setState(
      {
        ...this.state,
        zone: [],
        district: [],
        field: [],
        locationData: [],
        CameraList: [],
      },
      () => {
        this.props.fetchStateCamera();
      }
    );
  };
  // onSubmit = () => {
  //   if (
  //     this.state.zone == "" &&
  //     this.state.district == "" &&
  //     this.state.field == ""
  //   ) {
  //     this.props.showSnackbar("Please select filter Params !!!", "error");
  //   } else
  //   if (
  //     this.state.zone.zone_id != "" &&
  //     this.state.field == "" &&
  //     this.state.district == ""
  //   ) {
  //     this.setState({
  //       ...this.state,
  //       locationData: []
  //     });
  //     this.props.fetchZoneCamera(this.state.zone.zone_id);
  //     this.setState({ dialStatus: false });
  //   } else if (
  //     this.state.zone.zone_id != "" &&
  //     this.state.field == "" &&
  //     this.state.district != ""
  //   ) {
  //     this.setState({
  //       ...this.state,
  //       locationData: []
  //     });
  //     this.props.fetchDistrictCamera(this.state.district.district_id);
  //     this.setState({ dialStatus: false });
  //   } else if (
  //     this.state.zone.zone_id != "" &&
  //     this.state.field != "" &&
  //     this.state.district != ""
  //   ) {
  //     this.setState({
  //       ...this.state,
  //       locationData: []
  //     });
  //     this.props.fetchFieldCamera(this.state.field.location_id);
  //     this.setState({ dialStatus: false });
  //   }
  // };
  onBackButtonClick = () => {
    this.props.navigateTo("/dashboard");
  };

  handleChange = (event) => {};
  handleMouseOver = (id) => {
    this.setState({ selectedId: id });
  };

  handleMouseOut = () => {
    this.setState({ ...this.state, selectedId: -2 });
  };
  handleClick = (event, item) => {
    const { locationData } = this.state;
    const selectedIndex = locationData.indexOf(item);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(locationData, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(locationData.slice(1));
    } else if (selectedIndex === locationData.length - 1) {
      newSelected = newSelected.concat(locationData.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        locationData.slice(0, selectedIndex),
        locationData.slice(selectedIndex + 1)
      );
    }
    this.setState({
      ...this.state,
      checked: true,
      locationData: newSelected,
      // assignedId: item,
      //selectedId: item.camera_id,
    });
  };
  performAction = (e) => {
    if (this.state.locationData.length === 0) {
      this.props.showSnackbar("Please Choose minimum 1 Camera !!!!", "error");
    } else if (this.state.locationData.length > 0) {
      let firstElement = this.state.locationData[0];
      let locationList = [];
      let finalLocation = "";
      this.state.locationData.map((obj) => {
        this.state.CameraList.map((resp) => {
          if (resp.camera_id === obj) {
            locationList.push(resp.field);
          }
        });
      });
      locationList = [...new Set(locationList)];

      if (
        this.state.CameraList.length === 46 &&
        this.state.CameraList.length === this.state.locationData.length
      ) {
        finalLocation = "All Camera";
      } else if (locationList.length > 1) {
        finalLocation = locationList[0].substring(0, 7) + "...";
      } else if (locationList.length === 1) {
        finalLocation = locationList[0];
      }
      let finalData = {
        location: finalLocation,
        cameraId: this.state.locationData,
      };
      this.props.setLocationId(finalData, this.props.locationId);
    }
  };
  isSelected = (item) => this.state.locationData.indexOf(item) !== -1;
  showFilterWindow = () => {
    this.setState({
      ...this.state,
      dialStatus: true,
      District: [],
      Field: [],
    });
  };
  onDialClose = () => {
    this.setState({ dialStatus: false });
  };
  handleSelectAll = () => {
    if (this.state.CameraList.length !== this.state.locationData.length) {
      let data = [];
      this.state.CameraList.map((resp) => {
        data.push(resp.camera_id);
      });
      this.setState({
        ...this.state,
        locationData: data,
      });
    } else {
      this.setState({
        ...this.state,
        locationData: [],
      });
    }
  };
  render() {
    const { classes } = this.props;
    const { Zone, zone, District, district, Field, field, errors } = this.state;
    const { user_type } = this.state;

    return (
      <>
        <Grid className={classes.grid}>
          <Paper className={classes.paper}>
            <Toolbar className={classNames(classes.roots)}>
              <IconButton
                onClick={(e) => this.onBackButtonClick(e)}
                classes={{ root: classes.iconButton }}
              >
                <BackIcon />
              </IconButton>

              <Typography
                variant="h6"
                id="tableTitle"
                className={classes.tableTitle}
              >
                Choose Camera
              </Typography>
              <div className={classes.spacer} />
              {user_type === "State Level" ? (
                <Grid container spacing={1} className={classes.gridContainer}>
                  <Grid item xs={12} md={3}></Grid>
                  <Grid item xs={12} md={3}>
                    {" "}
                    <Autocomplete
                      disableClearable="true"
                      disableOpenOnFocus="true"
                      options={Zone}
                      getOptionLabel={(option) =>
                        option.zone_name ? option.zone_name : ""
                      }
                      onChange={this.handleZone}
                      value={zone}
                      popupIcon={<DownArrow />}
                      classes={{
                        input: classes.textValue,
                        option: classes.textValue,
                        popupIndicatorOpen: classes.popIconOpen,
                        popupIndicator: classes.popIcon,
                        noOptions: classes.textNoOptions,
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="Choose Zone"
                          id="outlined-margin-dense"
                          margin="dense"
                          variant="outlined"
                          required
                          name="zone"
                          error={errors.zone ? true : false}
                          {...params}
                          className={classes.textField2}
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                          SelectProps={{
                            IconComponent: DownArrow,
                            classes: {
                              icon: classes.dropIcon,
                              iconOpen: classes.dropIconOpen,
                            },
                          }}
                        ></TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    {" "}
                    <Autocomplete
                      disableClearable="true"
                      disableOpenOnFocus="true"
                      options={District}
                      getOptionLabel={(option) =>
                        option.district_name ? option.district_name : ""
                      }
                      onChange={this.handleDistrict}
                      value={district}
                      popupIcon={<DownArrow />}
                      classes={{
                        input: classes.textValue,
                        option: classes.textValue,
                        popupIndicatorOpen: classes.popIconOpen,
                        popupIndicator: classes.popIcon,
                        noOptions: classes.textNoOptions,
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="Choose District"
                          id="outlined-margin-dense"
                          margin="dense"
                          variant="outlined"
                          required
                          name="district"
                          //value={data.district}
                          error={errors.district ? true : false}
                          {...params}
                          className={classes.textField2}
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                          SelectProps={{
                            IconComponent: DownArrow,
                            classes: {
                              icon: classes.dropIcon,
                              iconOpen: classes.dropIconOpen,
                            },
                          }}
                        ></TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      disableClearable="true"
                      disableOpenOnFocus="true"
                      options={Field}
                      getOptionLabel={(option) =>
                        option.place ? option.place : ""
                      }
                      onChange={this.handleField}
                      value={field}
                      popupIcon={<DownArrow />}
                      classes={{
                        input: classes.textValue,
                        option: classes.textValue,
                        popupIndicatorOpen: classes.popIconOpen,
                        popupIndicator: classes.popIcon,
                        noOptions: classes.textNoOptions,
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="Choose field"
                          name="field"
                          id="outlined-margin-dense"
                          margin="dense"
                          variant="outlined"
                          required
                          //value={data.field}
                          error={errors.field ? true : false}
                          {...params}
                          className={classes.textField2}
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                          SelectProps={{
                            IconComponent: DownArrow,
                            classes: {
                              icon: classes.dropIcon,
                              iconOpen: classes.dropIconOpen,
                            },
                          }}
                        ></TextField>
                      )}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Toolbar>
            <Divider className={classes.divider} />

            <Grid className={classes.grid2}>
              <Grid
                style={{ padding: "10px" }}
                className="CameraGrid"
                classes={{ root: classes.gridBorder }}
              >
                <br></br>

                {this.state.CameraList.length > 0 ? (
                  <Grid
                    // container
                    // direction="row"
                    // justify="flex-end"
                    // style={{ paddingBottom: "2%" }}
                    xs={12}
                    container
                    spacing={1}
                    className={classes.cardGrid}
                  >
                    <Grid
                      item
                      direction="row"
                      style={{ paddingLeft: "20px", paddingBottom: "19px" }}
                    >
                      <div
                        style={{
                          fontFamily: "Karla",
                          fontSize: "14px",
                          color: "#2B3D51",
                        }}
                      >
                        <StyledCheckbox
                          checked={
                            this.state.CameraList.length ===
                            this.state.locationData.length
                              ? true
                              : false
                          }
                          color="primary"
                          onClick={this.handleSelectAll}
                        ></StyledCheckbox>
                        Select All
                      </div>
                    </Grid>
                  </Grid>
                ) : null}

                <Grid
                  xs={12}
                  container
                  spacing={2}
                  className={classes.cardGrid}
                >
                  {this.state.CameraList.length > 0
                    ? this.state.CameraList.map((item, index) => {
                        const isItemSelected = this.isSelected(item.camera_id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <>
                            <>
                              <Grid
                                item
                                // xs={12} md={3}
                              >
                                <Card
                                  elevation={0}
                                  elevation={0}
                                  onMouseOver={() =>
                                    this.handleMouseOver(item.camera_id)
                                  }
                                  onClick={(event) =>
                                    this.handleClick(event, item.camera_id)
                                  }
                                  onMouseOut={this.handleMouseOut}
                                  className={
                                    this.state.selectedId === item.camera_id
                                      ? classes.selectedCard
                                      : isItemSelected
                                      ? classes.assignedCard
                                      : classes.unassignedCard
                                  }
                                >
                                  <CardActions>
                                    <Typography
                                      // style={{
                                      //   fontSize: "15px",
                                      //   marginLeft: "5px",
                                      // }}
                                      className={classes.locationName}
                                    >
                                      {item.field.length > 14 ? (
                                        <Tooltip
                                          title={item.field}
                                          placement="bottom"
                                        >
                                          <b>
                                            {item.field
                                              ? item.field.substring(0, 12) +
                                                "..)"
                                              : ""}
                                          </b>
                                        </Tooltip>
                                      ) : (
                                        <b>{item.field ? item.field : ""}</b>
                                      )}
                                    </Typography>
                                    <StyledCheckbox
                                      className={classes.checkboxColor}
                                      checked={
                                        // this.state.checked ? isItemSelected : false
                                        this.isSelected(item.camera_id)
                                      }
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                      style={{
                                        marginLeft: "auto",
                                      }}
                                    />
                                  </CardActions>

                                  <CardActions disableSpacing>
                                    <CameraIcon
                                      className={classes.cameraIcon}
                                    />
                                    &nbsp;&nbsp;
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="p"
                                      // style={{
                                      //   marginLeft: "5px",
                                      //   fontSize: "15px",
                                      // }}
                                      className={classes.cameraName}
                                    >
                                      {item ? (
                                        <>Camera ID: {item.camera_id}</>
                                      ) : (
                                        ""
                                      )}
                                    </Typography>
                                  </CardActions>
                                </Card>
                              </Grid>
                            </>
                          </>
                        );
                      })
                    : null}
                </Grid>
              </Grid>
            </Grid>

            {this.state.CameraList.length > 0 ? (
              <Grid
                xs={12}
                style={{
                  paddingRight: "25%",
                  paddingTop: "32px",
                  // paddingBottom:"20px"
                }}
                container
              >
                <Grid item xs={12} md={6}></Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  style={{
                    textAlign: "right",
                    //   "&.SquadDetails-timeGrid-271":{
                    //     paddingBottom: "15px",
                    // }
                  }}
                >
                  <Button
                    disableElevation={true}
                    variant="contained"
                    className={classes.clearButton}
                    onClick={() => this.onBackButtonClick()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} className={classes.btnGrid}>
                  <Button
                    disableElevation={true}
                    elevation={0}
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => this.performAction()}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </Paper>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(chooseLocation);
