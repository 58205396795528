import { connect } from "react-redux";
import Filter from "./reportFilter";
import {
  fetchZone,
  fetchDistrict,
  fetchField,
} from "../../../../components/filterhistory_form/data/action.js";
import { fetchOffice } from "../../../squadMaster/components/data/action";
import { fetchOfficer, fetchSquad } from "../../../reports/data/action";
import { setReportFilterParams, resetReportFilterParams } from "./data/actions";
import { snackbarActions } from "../../../../components/snackbar/data/action";

function mapStateToProps(state) {
  return {
    field: state.filterhistory.field,
    fetchedzone: state.filterhistory.fetchedzone,
    district: state.filterhistory.district,
    office: state.squadDetails.office,
    squad: state.reports.squad,
    officer: state.reports.officer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchZone: (type) => dispatch(fetchZone(type)),
    fetchDistrict: (type, id) => dispatch(fetchDistrict(type, id)),
    fetchField: (type, id) => dispatch(fetchField(type, id)),
    fetchOffice: (id) => dispatch(fetchOffice(id)),
    fetchSquad: (id, fromdate, todate) =>
      dispatch(fetchSquad(id, fromdate, todate)),
    fetchOfficer: (type, id) => dispatch(fetchOfficer(type, id)),
    setReportFilterParams: (params) => dispatch(setReportFilterParams(params)),
    resetReportFilterParams: () => dispatch(resetReportFilterParams()),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
