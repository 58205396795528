import APIRequest from "../util/api/APIRequest";

class ActionSummaryReportService {
  fetchReport = (datas) => {
    return APIRequest({
      url: "api/reports/ActionSummaryOverall",
      method: "POST",
      params: datas.generateParams,
    });
  };
  exportReport = (datas) => {
    return APIRequest({
      url: "api/reports/ActionSummaryOverall-print",
      method: "POST",
      params: datas.exportParams,
    });
  };
}
export default ActionSummaryReportService;
