import {
  FETCH_LOCATION_LIST,
  FETCH_LOCATION_LIST_ERROR,
  DELETE_LOCATION,
  DELETE_LOCATION_ERROR,
  RESET_DELETE
} from "./constants";

const initialState = {
  locationLists: [],
  locationCount: 0,
  deleteSuccess: false,
  deleteSuccessResponse: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_LOCATION_LIST:
      return {
        ...state,
        locationLists: action.data.data.rows,
        locationCount: action.data.data.count
      };
    case FETCH_LOCATION_LIST_ERROR:
      return {
        ...initialState
      };
    case DELETE_LOCATION:
      return {
        ...initialState,
        deleteSuccessResponse: action.data.message,
        error: action.data.message,
        deleteSuccess: true
      };
    case DELETE_LOCATION_ERROR:
      return {
        ...initialState
      };
    case RESET_DELETE:
      return {
        ...initialState,
        deleteSuccess: false
      };

    default:
      return state;
  }
}
