export const FETCH_ZONE = "FETCH_ZONE";
export const FETCH_ZONE_FAILED = "FETCH_ZONE_FAILED";

export const FETCH_DISTRICT = "FETCH_DISTRICT";
export const FETCH_DISTRICT_FAILED = "FETCH_DISTRICT_FAILED";

export const FETCH_FIELD = "FETCH_FIELD";
export const FETCH_FIELD_FAILED = "FETCH_FIELD_FAILED";

export const FETCH_STATE_CAMERA = "FETCH_STATE_CAMERA";
export const FETCH_STATE_CAMERA_FAILED = "FETCH_STATE_CAMERA_FAILED";

export const FETCH_ZONE_CAMERA = "FETCH_ZONE_CAMERA";
export const FETCH_ZONE_CAMERA_FAILED = "FETCH_ZONE_CAMERA_FAILED";

export const FETCH_DISTRICT_CAMERA = "FETCH_DISTRICT_CAMERA";
export const FETCH_DISTRICT_CAMERA_FAILED = "FETCH_DISTRICT_CAMERA_FAILED";

export const FETCH_FIELD_CAMERA = "FETCH_FIELD_CAMERA";
export const FETCH_FIELD_CAMERA_FAILED = "FETCH_FIELD_CAMERA_FAILED";

export const GET_CAMERA_DATA = "GET_CAMERA_DATA";
