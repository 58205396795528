import { connect } from "react-redux";
import { withRouter } from "react-router";
import VehicleEwayBill from "./VehicleEwayBill";

function mapStateToProps(state) {
  return {
    record: state.vehicleInfo.record
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VehicleEwayBill)
);
