const VEHICLE_CATEGORY = "VEHICLE_CATEGORY";
const CONFIDENCE = "CONFIDENCE";
const LANE = "LANE";
const CAMERA = "CAMERA";
const COMMODITY_CATEGORY = "COMMODITY_CATEGORY";

const dropdownTypes = {
  VEHICLE_CATEGORY,
  CONFIDENCE,
  LANE,
  CAMERA,
  COMMODITY_CATEGORY,
};
export default dropdownTypes;
