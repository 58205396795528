import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "connected-react-router";
import Drawer from "./Drawer";
import { drawerToggle } from "./data/actions";

function mapStateToProps(state) {
  return {
    pathname: state.router.location.pathname,
    showAppDrawer: state.drawer.showAppDrawer,
    appBarPath: state.drawer.appBarPath,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => dispatch(push(url)),
    drawerToggle: () => dispatch(drawerToggle()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Drawer));
