import {
  GET_OVERVIEW_DATA,
  GET_MESSAGE_DATA,
  GET_VEHICLE_CATEGORY,
  GET_COMMODITY_DATA,
  RESET_DATA,
} from "./constants";
import DashboardService from "../../../../service/DashboardService";
import { snackbarActions } from "../../../../components/snackbar/data/action";
import { progressActions } from "../../../../components/progressbar/data/action";

export const getVehicleTypeCount = (params) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DashboardService().getVehicleTypeCount(params).then((response) => {
      if (response.data) {
        dispatch(success(response.data.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: GET_VEHICLE_CATEGORY, data };
  }
};

export const getOverviewData = (socket, newData, oldData) => {
  return (dispatch) => {
    // if (oldData === null || oldData === "" || oldData === undefined) {
    // } else {
    //   dispatch(resetData());
    //   console.log("unsubcribe getOverviewData");
    //   console.log(oldData);
    //   oldData.cameraId.map((resp) => {
    //     socket.emit("unsubscribe", resp);
    //   });
    // }
    // if (newData !== "") {
    //   console.log("subcribe getOverviewData");
    //   console.log(newData);
    //   newData.cameraId.map((resp) => {
    //     socket.emit("subscribe", resp);
    //   });
    // }
    // socket.on("connect_failed", function () {
    //   console.log("Socket Error");
    // });
    // socket.on("connect_error", (err) => console.log("Socket Error", err));

    socket.on("message", function (newData) {
      const content = JSON.parse(newData);

      if (content.commodity != undefined) {
        dispatch(commodityVehicle(content.commodity));
      }
      if (content.overview != undefined) {
        dispatch(vehicle(content.overview));
      }
      if (content.message != undefined) {
        dispatch(vehicleMessage(content.message));
      }
      if (content.vehicleCategory != undefined) {
        dispatch(vehicleCategory(content.vehicleCategory));
      }
    });
  };

  function vehicle(res) {
    return { type: GET_OVERVIEW_DATA, data: res };
  }
  function vehicleMessage(res) {
    return { type: GET_MESSAGE_DATA, data: res };
  }
  function vehicleCategory(res) {
    return { type: GET_VEHICLE_CATEGORY, data: res };
  }
  function commodityVehicle(res) {
    return { type: GET_COMMODITY_DATA, data: res };
  }
  function resetData(res) {
    return { type: RESET_DATA };
  }
};
export const getOverviewDatas = (socket, newData, oldData) => {
  return (dispatch) => {
    if (oldData === null || oldData === "" || oldData === undefined) {
    } else {
      dispatch(resetData());
      oldData.cameraId.map((resp) => {
        socket.emit("unsubscribe", resp);
      });
    }
    if (newData !== "") {
      newData.cameraId.map((resp) => {
        socket.emit("subscribe", resp);
      });
    }

    socket.on("reconnect", function () {
      if (oldData === null || oldData === "" || oldData === undefined) {
      } else {
        dispatch(resetData());
        oldData.cameraId.map((resp) => {
          socket.emit("unsubscribe", resp);
        });
      }
      if (newData !== "") {
        newData.cameraId.map((resp) => {
          socket.emit("subscribe", resp);
        });
      }
    });
  };

  function resetData(res) {
    return { type: RESET_DATA };
  }
};
export const getVehicleCount = (params) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DashboardService().getVehicleCount(params).then((response) => {
      if (response.data) {
        dispatch(success(response.data.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        //dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: GET_OVERVIEW_DATA, data };
  }
};
