import { connect } from "react-redux";
import { withRouter } from "react-router";
import Camera from "./Camera";

function mapStateToProps(state) {
  return {
    vehicleList: state.vehicleList.vehicleList
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Camera)
);
