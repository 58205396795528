import { connect } from "react-redux";
import { withRouter } from "react-router";
import NoEwayBillWarningList from "./Warnings";
import {
  fetchActualWarningList,
  setActualWarningPage,
  resetActualWarningPage,
  resetError,
} from "./data/action";
import { push } from "connected-react-router";
import { resetFilterParams } from "../../components/warningfilter/data/action";
import { resetConfidence } from "../../components/filterwarning_form/data/action";
import { resetEventTimeStamp, setEventTimeStamp } from "../history/data/action";
import {
  resetFromDate,
  resetToDate,
  resetPlateNo,
  resetZone,
  resetDistrict,
  resetField,
  resetCamera,
  resetVehicle,
  resetLane,
} from "../../components/filterhistory_form/data/action";

function mapStateToProps(state) {
  return {
    actualwarningList: state.warning.actualwarningList,
    recordsCount: state.warning.recordsCount,
    filterParams: state.warningfilter.filterParams,
    page: state.warning.page,
    rowsPerPage: state.warning.rowsPerPage,
    loading: state.progress.loading,
    error: state.warning.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchActualWarningList: (page, limit, type, filterParams) =>
      dispatch(fetchActualWarningList(page, limit, type, filterParams)),
    navigateTo: (url) => dispatch(push(url)),
    resetFilterParams: () => dispatch(resetFilterParams()),
    resetActualWarningPage: () => dispatch(resetActualWarningPage()),
    setActualWarningPage: (page) => dispatch(setActualWarningPage(page)),
    resetError: () => dispatch(resetError()),
    setEventTimeStamp: (time) => dispatch(setEventTimeStamp(time)),
    resetEventTimeStamp: () => dispatch(resetEventTimeStamp()),

    //filterparams
    resetFromDate: () => dispatch(resetFromDate()),
    resetToDate: () => dispatch(resetToDate()),
    resetPlateNo: () => dispatch(resetPlateNo()),
    resetZone: () => dispatch(resetZone()),
    resetDistrict: () => dispatch(resetDistrict()),
    resetField: () => dispatch(resetField()),
    resetCamera: () => dispatch(resetCamera()),
    resetVehicle: () => dispatch(resetVehicle()),
    resetLane: () => dispatch(resetLane()),
    resetConfidence: () => dispatch(resetConfidence()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NoEwayBillWarningList)
);
