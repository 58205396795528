import {
  FETCH_FLAMMABLE_GOODS_LIST,
  FETCH_FLAMMABLE_GOODS_LIST_ERROR,
  DELETE_FLAMMABLE_GOOD,
  DELETE_FLAMMABLE_GOOD_ERROR,
  RESET_DELETE
} from "./constants";

const initialState = {
  flammableGoodsLists: [],
  flammableGoodsCount: 0,
  deleteSuccess: false,
  deleteSuccessMessage: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_FLAMMABLE_GOODS_LIST:
      return {
        ...state,
        flammableGoodsLists: action.data.data.rows,
        flammableGoodsCount: action.data.data.count
      };
    case FETCH_FLAMMABLE_GOODS_LIST_ERROR:
      return {
        ...initialState
      };
    case DELETE_FLAMMABLE_GOOD:
      return {
        ...initialState,
        deleteSuccessMessage: action.data,
        error: action.data.message,
        deleteSuccess: true
      };
    case DELETE_FLAMMABLE_GOOD_ERROR:
      return {
        ...initialState
      };
    case RESET_DELETE:
      return {
        ...initialState,
        deleteSuccess: false
      };

    default:
      return state;
  }
}
