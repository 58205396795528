import { connect } from "react-redux";
import { withRouter } from "react-router";
import WarningList from "./WarningList";
import {
  fetchWarningList,
  fetchHabitualOffenderList,
  setWarningPage,
  resetWarningPage,
  resetError,
} from "./data/action";
import { push } from "connected-react-router";
import {
  toggleFilterWindow,
  resetFilterParams,
  setWarningFilterParams,
  resetConfidence,
} from "../../components/filterwarning_form/data/action";
import { resetEventTimeStamp, setEventTimeStamp } from "../history/data/action";
import {
  resetFromDate,
  resetToDate,
  resetPlateNo,
  resetZone,
  resetDistrict,
  resetField,
  resetCamera,
  resetVehicle,
  resetLane,
} from "../../components/filterhistory_form/data/action";

function mapStateToProps(state) {
  return {
    warningList: state.review.warningList,
    warningCount: state.review.warningCount,
    //showFilterWindow: state.filter.showFilterWindow,
    filterParams: state.filterwarning.filterParams,
    page: state.review.page,
    rowsPerPage: state.review.rowsPerPage,
    loading: state.progress.loading,
    error: state.review.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchWarningList: (page, limit, filterParams) =>
      dispatch(fetchWarningList(page, limit, filterParams)),
    navigateTo: (url) => dispatch(push(url)),
    // toggleFilterWindow: () => dispatch(toggleFilterWindow()),
    resetFilterParams: () => dispatch(resetFilterParams()),
    setWarningFilterParams: (filterWarningParams) =>
      dispatch(setWarningFilterParams(filterWarningParams)),
    resetWarningPage: () => dispatch(resetWarningPage()),
    setWarningPage: (page) => dispatch(setWarningPage(page)),
    resetError: () => dispatch(resetError()),
    setEventTimeStamp: (time) => dispatch(setEventTimeStamp(time)),
    resetEventTimeStamp: () => dispatch(resetEventTimeStamp()),

    //filterparams
    resetFromDate: () => dispatch(resetFromDate()),
    resetToDate: () => dispatch(resetToDate()),
    resetPlateNo: () => dispatch(resetPlateNo()),
    resetZone: () => dispatch(resetZone()),
    resetDistrict: () => dispatch(resetDistrict()),
    resetField: () => dispatch(resetField()),
    resetCamera: () => dispatch(resetCamera()),
    resetVehicle: () => dispatch(resetVehicle()),
    resetLane: () => dispatch(resetLane()),
    resetConfidence: () => dispatch(resetConfidence()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WarningList)
);
