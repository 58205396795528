/** @format */

import React, { createRef } from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField, Grid, FormHelperText } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StorageHandler from "../../../../util/storage/index";
import { Drawer, Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import moment from "moment";
import { ReactComponent as DownArrow } from "../../../../assets/SvgIcons/dropdown.svg";
import { ReactComponent as DateTime } from "../../../../assets/Visual Assets/date.svg";

const drawerWidth = 420;
const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginTop: theme.spacing.unit,
  },
  textField: {
    width: "103.5%",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 400,
    height: 300,
  },
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    opacity: 1,
    textTransform: "none",
    paddingLeft: "38px",
    paddingRight: "37px",
    height: "42px",
  },
  clearButton: {
    color: "#1BB99A",
    textTransform: "none",
    font: "normal normal normal 16px/36px Karla",
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: "white",
    marginTop: "-15px",
    marginRight: "6px",
  },
  closeButton: {
    height: "42px",
    textTransform: "none",
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.colors.BlueButton,
    paddingLeft: "38px",
    paddingRight: "37px",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    padding: "0px",
  },
  drawerPaper: {
    // width: drawerWidth,
    top: "0",
    width: "330px",
  },

  formHelperText: {
    color: theme.colors.redColor,
  },
  title: {
    textAlign: "left",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "1px",
  },
  divider: {
    border: "1px solid #E7EBF4",
    opacity: 1,
    marginTop: "6px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  popIconOpen: {
    marginTop: "4px",
    marginRight: "6px",
  },
  popIcon: {
    marginTop: "6px",
    marginRight: "6px",
  },
  textNoOptions: {
    letterSpacing: "0px",
    opacity: 1,
    fontSize: "18px",
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  dialogContent: { paddingLeft: "23px", paddingTop: "7.5px" },
  dialogTitleGrid: { paddingTop: "15px", paddingLeft: "22px" },
  dateIcon: {
    marginTop: "0px",
    marginRight: "-8px",
  },
  dialogAction: {
    background: "#F9F9FE 0% 0% no-repeat padding-box",
    opacity: 1,
    padding: "16px",
  },
  subGrid: { paddingTop: "2.5px" },
});

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.confidence = createRef();
    this.state = {
      fromDate: null,
      toDate: null,
      zone: null,
      district: null,
      field: null,
      office: null,
      squad: null,
      officer: null,
      Zone: [],
      District: [],
      Field: [],
      Office: [],
      Squad: [],
      Officer: [],
      errors: {
        confidence: "",
        selectedToDate: "",
      },
      rules: {
        confidence: {
          number: true,
        },
      },
      zoneType: "zone",
      districtType: "district",
      fieldType: "field",
      // assignedDistrict: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.district_master
      //   : "",
      // assignedField: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.location_master
      //   : "",
      // assignedZone: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.zone_master
      //   : "",
    };
  }

  componentDidMount() {
    this.props.fetchZone(this.state.zoneType);
    this.props.applyFilter();
    // if (
    //   new StorageHandler().getUser() &&
    //   new StorageHandler().getUser().basicDetails.zone_master &&
    //   new StorageHandler().getUser().basicDetails.zone_master != null
    // ) {
    //   this.props.fetchDistrict(this.state.assignedZone.zone_id);
    //   this.props.fetchOfficer("zone", this.state.assignedZone.zone_id);
    // }

    // if (
    //   new StorageHandler().getUser() &&
    //   new StorageHandler().getUser().basicDetails.district_master &&
    //   new StorageHandler().getUser().basicDetails.district_master != null
    // ) {
    //   this.props.fetchField(this.state.assignedDistrict.district_id);
    //   this.props.fetchOfficer(
    //     "district",
    //     this.state.assignedDistrict.district_id
    //   );
    // }

    // if (
    //   new StorageHandler().getUser() &&
    //   new StorageHandler().getUser().basicDetails.location_master &&
    //   new StorageHandler().getUser().basicDetails.location_master != null
    // ) {
    //   this.props.fetchOffice(
    //     new StorageHandler().getUser().basicDetails.location_master.location_id
    //   );
    //   this.props.fetchOfficer(
    //     "field",
    //     new StorageHandler().getUser().basicDetails.location_master.location_id
    //   );
    // }

    // if (
    //   this.state.assignedZone &&
    //   this.state.assignedZone != null &&
    //   this.state.assignedZone != "" &&
    //   this.state.assignedDistrict &&
    //   this.state.assignedDistrict != null &&
    //   this.state.assignedDistrict != "" &&
    //   this.state.assignedField === null
    // ) {
    //   this.setState({
    //     filterParams: { district: this.state.assignedDistrict.district_id },
    //   });
    // } else if (
    //   this.state.assignedZone &&
    //   this.state.assignedZone != null &&
    //   this.state.assignedZone != "" &&
    //   this.state.assignedDistrict &&
    //   this.state.assignedDistrict != null &&
    //   this.state.assignedDistrict != "" &&
    //   this.state.assignedField &&
    //   this.state.assignedField != null &&
    //   this.state.assignedField != ""
    // ) {
    //   this.setState({
    //     filterParams: { field: this.state.assignedField.location_id },
    //   });
    // } else if (
    //   this.state.assignedZone &&
    //   this.state.assignedZone != null &&
    //   this.state.assignedZone != "" &&
    //   this.state.assignedDistrict === null &&
    //   this.state.assignedField === null
    // ) {
    //   this.setState({
    //     filterParams: { zone: this.state.assignedZone.zone_id },
    //   });
    // } else {
    //   this.setState({
    //     filterParams: {},
    //   });
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.fetchedzone != this.props.fetchedzone) {
      this.setState({
        ...this.state,
        Zone: this.props.fetchedzone,
      });
    }
    if (prevProps.district != this.props.district) {
      this.setState({
        ...this.state,
        District: this.props.district,
      });
    }
    if (prevProps.field != this.props.field) {
      this.setState({
        ...this.state,
        Field: this.props.field,
      });
    }
    if (prevProps.squad != this.props.squad) {
      this.setState({
        Squad: this.props.squad,
      });
    }
    if (prevProps.office != this.props.office) {
      this.setState({
        Office: this.props.office,
      });
    }
    if (prevProps.officer != this.props.officer) {
      this.setState({
        Officer: this.props.officer,
      });
    }
  }

  handleChangeZone = (event, value) => {
    this.setState(
      {
        ...this.state,
        assignedZone: value,
        assignedDistrict: {},
        assignedField: {},
        zone: value,
        officer: "",
        office: "",
        squad: "",
        filterParams: {
          ...this.state.filterParams,
          zone: value.zone_id,
        },
      },
      () => {
        this.props.fetchDistrict(
          this.state.districtType,
          this.state.assignedZone.zone_id
        );
        this.props.fetchOfficer(
          this.state.zoneType,
          this.state.assignedZone.zone_id
        );
        this.setState({
          Field: [],
          Office: [],
          Squad: [],
        });
      }
    );
  };

  handleChangeDistrict = (event, value) => {
    this.setState(
      {
        ...this.state,
        assignedDistrict: value,
        assignedField: {},
        district: value,
        officer: "",
        filterParams: {
          ...this.state.filterParams,
          district: value.district_id,
        },
      },
      () => {
        this.props.fetchField(
          this.state.fieldType,
          this.state.assignedDistrict.district_id
        );
        this.props.fetchOfficer(
          this.state.districtType,
          this.state.assignedDistrict.district_id
        );
      }
    );
  };

  handleChangeField = (event, value) => {
    this.setState(
      {
        ...this.state,
        assignedField: value,
        field: value,
        office: "",
        officer: "",
        squad: "",
        filterParams: { ...this.state.filterParams, field: value.location_id },
      },
      () => {
        this.props.fetchOffice(value.location_id);
        this.props.fetchOfficer(this.state.fieldType, value.location_id);
        this.setState({
          Squad: [],
        });
      }
    );
  };

  handleChangeOffice = (event, value) => {
    if (this.validate()) {
      this.setState(
        {
          ...this.state,
          office: value,
          squad: "",
          officer: "",
          filterParams: { ...this.state.filterParams, office: value.officeId },
        },
        () => {
          this.props.fetchSquad(
            value.officeId,
            this.state.filterParams.from_date,
            this.state.filterParams.to_date
          );
          this.setState({
            Officer: [],
          });
        }
      );
    } else {
      this.props.showSnackbar(
        "Please enter valid from and to dates..!",
        "info"
      );
    }
  };

  handleChangeSquad = (event, value) => {
    this.setState(
      {
        ...this.state,
        squad: value,
        officer: "",
        filterParams: { ...this.state.filterParams, squad: value.squad_id },
      },
      () => {
        this.props.fetchOfficer("squad", value.squad_id);
      }
    );
  };

  handleChangeOfficer = (event, value) => {
    this.setState({
      ...this.state,
      officer: value,
      filterParams: { ...this.state.filterParams, officer: value.userId },
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };

  handleErrorFocus = (errors) => {
    let order = ["confidence"];
    for (var i = 0; i < order.length; i++) {
      if (errors[order[i]]) {
        this[order[i]].current.focus({
          preventScroll: true,
        });
        window.scrollTo({
          top: this[order[i]].current.getBoundingClientRect().y,
          behaviour: "smooth",
        });
        break;
      }
    }
  };

  validate = () => {
    let generationFields = {
      from_date: this.state.filterParams.from_date,
      to_date: this.state.filterParams.to_date,
    };
    var validationSuccess = true;
    let validationErrors = {};
    Object.keys(generationFields).map((field) => {
      if (generationFields[field] === "" || generationFields[field] == null) {
        validationErrors[field] = true;
        validationSuccess = false;
      } else {
        validationErrors[field] = false;
      }
    });
    this.setState({
      validationErrors,
    });
    return validationSuccess;
  };

  setFilterParams = (e) => {
    if (this.validate()) {
      if (
        this.validateDate(
          this.state.filterParams.from_date,
          this.state.filterParams.to_date
        )
      ) {
        let param = {
          ...this.state.filterParams,
          ...this.state.searchParams,
        };
        this.setState(
          {
            value: false,
          },
          () => {
            this.props.setReportFilterParams(param);
            this.props.toggleFilterWindow();
            //this.props.applyFilter();
          }
        );
      }
    } else {
      this.props.showSnackbar("Please enter valid dates..!", "info");
    }
  };
  validateDate = (val1, val2) => {
    let from_date = moment(val1).format("YYYY-MM-DD");
    let to_date = moment(val2).format("YYYY-MM-DD");
    if (moment(from_date).isAfter(to_date)) {
      this.props.showSnackbar("Please enter valid dates..!", "info");
    } else {
      return true;
    }
  };
  closeWindow = () => {
    this.props.toggleFilterWindow();
    if (this.state.value == true) {
      this.props.resetReportFilterParams();
      this.setState(
        {
          ...this.state,
          fromDate: null,
          toDate: null,
          zone: null,
          district: null,
          field: null,
          office: null,
          squad: null,
          officer: null,
          searchParams: {},
          filterParams: {},
          assignedZone: {},
          assignedDistrict: {},
          District: [],
          Field: [],
          assignedField: {},
        }
        // () => {
        //   if (new StorageHandler().getUser().basicDetails.zone_master == null) {
        //     this.setState({
        //       assignedZone: {},
        //       District: [],
        //       Field: [],
        //     });
        //   } else {
        //     this.props.fetchDistrict(
        //       new StorageHandler().getUser().basicDetails.zone_master.zone_id
        //     );
        //     this.setState({
        //       Field: [],

        //       filterParams: {
        //         zone: new StorageHandler().getUser().basicDetails.zone_master
        //           .zone_id,
        //       },
        //     });
        //   }
        //   if (
        //     new StorageHandler().getUser().basicDetails.district_master == null
        //   ) {
        //     this.setState({
        //       assignedDistrict: {},
        //       Field: [],
        //     });
        //   } else {
        //     this.props.fetchField(
        //       new StorageHandler().getUser().basicDetails.district_master
        //         .district_id
        //     );
        //     this.setState({
        //       filterParams: {
        //         district: new StorageHandler().getUser().basicDetails
        //           .district_master.district_id,
        //       },
        //     });
        //   }
        //   if (
        //     new StorageHandler().getUser().basicDetails.location_master == null
        //   ) {
        //     this.setState({
        //       assignedField: {},
        //     });
        //   } else {
        //     this.setState({
        //       filterParams: {
        //         field: new StorageHandler().getUser().basicDetails
        //           .location_master.location_id,
        //       },
        //     });
        //   }
        //   if (
        //     new StorageHandler().getUser().basicDetails.location_master ==
        //       null &&
        //     new StorageHandler().getUser().basicDetails.district_master ==
        //       null &&
        //     new StorageHandler().getUser().basicDetails.zone_master == null
        //   ) {
        //     this.setState({ filterParams: {} });
        //   }
        // }
      );
    }
  };

  resetReportFilterParams = () => {
    this.props.resetReportFilterParams();
    //this.props.empty();
    this.setState(
      {
        ...this.state,
        value: true,
        Officer: [],
        fromDate: null,
        toDate: null,
        zone: null,
        district: null,
        field: null,
        office: null,
        squad: null,
        officer: null,
        searchParams: {},
        filterParams: {},

        assignedZone: {},
        assignedDistrict: {},
        assignedField: {},
        District: [],
        Field: [],
        Office: [],
        Squad: [],
      }
      // () => {
      //   if (new StorageHandler().getUser().basicDetails.zone_master == null) {
      //     this.setState({
      //       assignedZone: {},
      //       District: [],
      //       Field: [],
      //       Office: [],
      //       Squad: [],
      //     });
      //   } else {
      //     this.props.fetchDistrict(
      //       new StorageHandler().getUser().basicDetails.zone_master.zone_id
      //     );
      //     this.props.fetchOfficer(
      //       "zone",
      //       new StorageHandler().getUser().basicDetails.zone_master.zone_id
      //     );

      //     this.setState({
      //       Field: [],
      //       Office: [],
      //       Squad: [],
      //       filterParams: {
      //         zone: new StorageHandler().getUser().basicDetails.zone_master
      //           .zone_id,
      //       },
      //     });
      //   }
      //   if (
      //     new StorageHandler().getUser().basicDetails.district_master == null
      //   ) {
      //     this.setState({
      //       assignedDistrict: {},
      //       Field: [],
      //       Office: [],
      //       Squad: [],
      //     });
      //   } else {
      //     this.props.fetchField(
      //       new StorageHandler().getUser().basicDetails.district_master
      //         .district_id
      //     );
      //     this.props.fetchOfficer(
      //       "district",
      //       new StorageHandler().getUser().basicDetails.district_master
      //         .district_id
      //     );
      //     this.setState({
      //       Office: [],
      //       Squad: [],
      //       filterParams: {
      //         district: new StorageHandler().getUser().basicDetails
      //           .district_master.district_id,
      //       },
      //     });
      //   }
      //   if (
      //     new StorageHandler().getUser().basicDetails.location_master == null
      //   ) {
      //     this.setState({
      //       assignedField: {},
      //       Office: [],
      //       Squad: [],
      //     });
      //   } else {
      //     this.props.fetchOffice(
      //       new StorageHandler().getUser().basicDetails.location_master
      //         .location_id
      //     );
      //     this.props.fetchOfficer(
      //       "field",
      //       new StorageHandler().getUser().basicDetails.location_master
      //         .location_id
      //     );
      //     this.setState({
      //       Squad: [],
      //       filterParams: {
      //         field: new StorageHandler().getUser().basicDetails.location_master
      //           .location_id,
      //       },
      //     });
      //   }
      //   if (
      //     new StorageHandler().getUser().basicDetails.location_master == null &&
      //     new StorageHandler().getUser().basicDetails.district_master == null &&
      //     new StorageHandler().getUser().basicDetails.zone_master == null
      //   ) {
      //     this.setState({ filterParams: {} });
      //   }
      // }
    );
  };

  render() {
    const { classes } = this.props;
    var d5f = new Date();
    d5f.setFullYear(d5f.getFullYear() - 1);
    return (
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          // variant="persistent"
          anchor="right"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={this.props.showReportFilterWindow}
          onClose={this.props.toggleFilterWindow}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title" style={{ padding: "0px" }}>
            <Grid
              container
              justify={"space-between"}
              className={classes.dialogTitleGrid}
            >
              <Grid item className={classes.title}>
                Filter
              </Grid>
              <Grid item>
                <Button
                  onClick={this.resetReportFilterParams}
                  className={classes.clearButton}
                >
                  Clear All
                </Button>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
          </DialogTitle>{" "}
          <DialogContent className={classes.dialogContent}>
            <>
              <Grid container>
                <Grid item xs={12} md={12}>
                  {/* <Typography variant="h6">From Date</Typography> */}
                  {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> 
                   <KeyboardDatePicker
                      className={classes.textField}
                      fullWidth
                      label="From Date"
                      name="fromDate"
                      //disableToolbar
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      margin="dense"
                      value={
                        this.state.fromDate == "" ||
                        this.state.fromDate == undefined
                          ? null
                          : this.state.fromDate
                      }
                      onChange={(date) => {
                        this.setState({
                          filterParams: {
                            ...this.state.filterParams,
                            from_date:
                              date == "Invalid Date" ||
                              date == null ||
                              date == "Invalid date"
                                ? null
                                : new Date(date.getTime()).setHours(0, 0, 0, 0),
                          },
                          fromDate:
                            date == "Invalid Date" ||
                            date == null ||
                            date == "Invalid date"
                              ? null
                              : new Date(date.getTime()).setHours(0, 0, 0, 0),
                        });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      keyboardIcon={<DateTime className={classes.dateIcon} />}
                      InputLabelProps={{
                        classes: { root: classes.textLabel },
                      }}
                      InputProps={{ classes: { root: classes.textValue } }}
                    />
                  </MuiPickersUtilsProvider> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      fullWidth
                      label="From Date"
                      name="from_date"
                      // disableToolbar
                      minDate={d5f}
                      disableFuture
                      inputVariant="outlined"
                      format="dd-MM-yyyy hh:mm a"
                      margin="dense"
                      value={
                        this.state.fromDate == "" ||
                        this.state.fromDate == undefined
                          ? null
                          : this.state.fromDate
                      }
                      onChange={(date) => {
                        this.setState({
                          filterParams: {
                            ...this.state.filterParams,
                            from_date:
                              date == "Invalid Date" ||
                              date == null ||
                              date == "Invalid date"
                                ? null
                                : new Date(date).getTime(),
                          },
                          fromDate:
                            date == "Invalid Date" ||
                            date == null ||
                            date == "Invalid date"
                              ? null
                              : new Date(date).getTime(),
                        });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      keyboardIcon={<DateTime className={classes.dateIcon} />}
                      InputLabelProps={{
                        classes: { root: classes.textLabel },
                      }}
                      InputProps={{ classes: { root: classes.textValue } }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>{" "}
                <Grid item xs={12} md={12} className={classes.subGrid}>
                  {/* <Typography variant="h6">To Date</Typography> */}
                  {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.textField}
                      fullWidth
                      label="To Date"
                      name="toDate"
                      //disableToolbar
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      margin="dense"
                      value={
                        this.state.toDate == "" ||
                        this.state.toDate == undefined
                          ? null
                          : this.state.toDate
                      }
                      onChange={(date) => {
                        this.setState({
                          filterParams: {
                            ...this.state.filterParams,
                            to_date:
                              date == "Invalid Date" ||
                              date == null ||
                              date == "Invalid date"
                                ? null
                                : new Date(date.getTime()).setHours(0, 0, 0, 0),
                          },
                          toDate:
                            date == "Invalid Date" ||
                            date == null ||
                            date == "Invalid date"
                              ? null
                              : new Date(date.getTime()).setHours(0, 0, 0, 0),
                        });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      keyboardIcon={<DateTime className={classes.dateIcon} />}
                      InputLabelProps={{
                        classes: { root: classes.textLabel },
                      }}
                      InputProps={{ classes: { root: classes.textValue } }}
                    />
                  </MuiPickersUtilsProvider> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      fullWidth
                      label="To Date"
                      name="to_date"
                      // disableToolbar
                      minDate={d5f}
                      disableFuture
                      inputVariant="outlined"
                      format="dd-MM-yyyy hh:mm a"
                      margin="dense"
                      value={
                        this.state.toDate == "" ||
                        this.state.toDate == undefined
                          ? null
                          : this.state.toDate
                      }
                      onChange={(date) => {
                        this.setState({
                          filterParams: {
                            ...this.state.filterParams,
                            to_date:
                              date == "Invalid Date" ||
                              date == null ||
                              date == "Invalid date"
                                ? null
                                : new Date(date).getTime(),
                          },
                          toDate:
                            date == "Invalid Date" ||
                            date == null ||
                            date == "Invalid date"
                              ? null
                              : new Date(date).getTime(),
                        });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      keyboardIcon={<DateTime className={classes.dateIcon} />}
                      InputLabelProps={{
                        classes: { root: classes.textLabel },
                      }}
                      InputProps={{ classes: { root: classes.textValue } }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>{" "}
                <br></br> <br></br> <br></br>
                <br></br>
                <Grid item xs={12} md={12} className={classes.subGrid}>
                  {/* <Typography variant="h6" gutterBottom>
                    Zone
                  </Typography> */}
                  {this.state.Zone ? (
                    <Autocomplete
                      className={classes.textField}
                      disableClearable="true"
                      disableOpenOnFocus="true"
                      // disabled={
                      //   new StorageHandler().getUser() &&
                      //   new StorageHandler().getUser().basicDetails &&
                      //   new StorageHandler().getUser().basicDetails.zone_id
                      //     ? true
                      //     : false
                      // }
                      options={this.state.Zone}
                      value={
                        this.state.assignedZone &&
                        this.state.assignedZone !== null
                          ? this.state.assignedZone
                          : ""
                      }
                      name="zone"
                      getOptionLabel={(option) =>
                        option.zone_name ? option.zone_name : ""
                      }
                      onChange={(event, value) =>
                        this.handleChangeZone(event, value)
                      }
                      popupIcon={<DownArrow />}
                      classes={{
                        input: classes.textValue,
                        option: classes.textValue,
                        popupIndicatorOpen: classes.popIconOpen,
                        popupIndicator: classes.popIcon,
                        noOptions: classes.textNoOptions,
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="Zone"
                          name="zone"
                          id="outlined-margin-dense"
                          margin="dense"
                          variant="outlined"
                          {...params}
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                        ></TextField>
                      )}
                    />
                  ) : null}
                </Grid>{" "}
                <Grid item xs={12} md={12} className={classes.subGrid}>
                  {/* <Typography variant="h6" gutterBottom>
                    District
                  </Typography> */}
                  {this.state.District ? (
                    <Autocomplete
                      className={classes.textField}
                      disableClearable="true"
                      disableOpenOnFocus="true"
                      options={this.state.District}
                      value={
                        this.state.assignedDistrict &&
                        this.state.assignedDistrict !== null
                          ? this.state.assignedDistrict
                          : ""
                      }
                      // disabled={
                      //   new StorageHandler().getUser() &&
                      //   new StorageHandler().getUser().basicDetails &&
                      //   new StorageHandler().getUser().basicDetails.district_id
                      //     ? true
                      //     : false
                      // }
                      name="district"
                      getOptionLabel={(option) =>
                        option.district_name ? option.district_name : ""
                      }
                      onChange={(event, value) =>
                        this.handleChangeDistrict(event, value)
                      }
                      popupIcon={<DownArrow />}
                      classes={{
                        input: classes.textValue,
                        option: classes.textValue,
                        popupIndicatorOpen: classes.popIconOpen,
                        popupIndicator: classes.popIcon,
                        noOptions: classes.textNoOptions,
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="District"
                          name="district"
                          id="outlined-margin-dense"
                          margin="dense"
                          variant="outlined"
                          {...params}
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                        ></TextField>
                      )}
                    />
                  ) : null}
                </Grid>{" "}
                <Grid item xs={12} md={12} className={classes.subGrid}>
                  {/* <Typography variant="h6" gutterBottom>
                    Field
                  </Typography> */}
                  {this.state.Field ? (
                    <Autocomplete
                      className={classes.textField}
                      disableClearable="true"
                      disableOpenOnFocus="true"
                      options={this.state.Field}
                      value={
                        this.state.assignedField &&
                        this.state.assignedField !== null
                          ? this.state.assignedField
                          : ""
                      }
                      // disabled={
                      //   new StorageHandler().getUser() &&
                      //   new StorageHandler().getUser().basicDetails &&
                      //   new StorageHandler().getUser().basicDetails.location_id
                      //     ? true
                      //     : false
                      // }
                      name="field"
                      getOptionLabel={(option) =>
                        option.place_name ? option.place_name : ""
                      }
                      onChange={(event, value) =>
                        this.handleChangeField(event, value)
                      }
                      popupIcon={<DownArrow />}
                      classes={{
                        input: classes.textValue,
                        option: classes.textValue,
                        popupIndicatorOpen: classes.popIconOpen,
                        popupIndicator: classes.popIcon,
                        noOptions: classes.textNoOptions,
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="Field"
                          name="field"
                          id="outlined-margin-dense"
                          margin="dense"
                          variant="outlined"
                          {...params}
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                        ></TextField>
                      )}
                    />
                  ) : null}
                </Grid>{" "}
                <Grid xs={12} md={12} item className={classes.subGrid}>
                  {/* <Typography variant="h6">Office</Typography> */}
                  {this.state.Office ? (
                    <Autocomplete
                      className={classes.textField}
                      disableClearable="true"
                      disableOpenOnFocus="true"
                      options={this.state.Office}
                      value={
                        this.state.office && this.state.office !== ""
                          ? this.state.office
                          : null
                      }
                      getOptionLabel={(option) =>
                        option.office ? option.office : ""
                      }
                      onChange={(event, value) =>
                        this.handleChangeOffice(event, value)
                      }
                      popupIcon={<DownArrow />}
                      classes={{
                        input: classes.textValue,
                        option: classes.textValue,
                        popupIndicatorOpen: classes.popIconOpen,
                        popupIndicator: classes.popIcon,
                        noOptions: classes.textNoOptions,
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="Office"
                          name="office"
                          id="outlined-margin-dense"
                          margin="dense"
                          variant="outlined"
                          {...params}
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                        ></TextField>
                      )}
                    />
                  ) : null}
                </Grid>{" "}
                <Grid item xs={12} md={12} className={classes.subGrid}>
                  {/* <Typography variant="h6" gutterBottom>
                    Squad
                  </Typography> */}
                  {this.state.Squad ? (
                    <Autocomplete
                      className={classes.textField}
                      disableClearable="true"
                      disableOpenOnFocus="true"
                      options={this.state.Squad}
                      value={
                        this.state.squad && this.state.squad !== ""
                          ? this.state.squad
                          : null
                      }
                      name="squad"
                      getOptionLabel={(option) =>
                        option.squad_name ? option.squad_name : ""
                      }
                      onChange={(event, value) =>
                        this.handleChangeSquad(event, value)
                      }
                      popupIcon={<DownArrow />}
                      classes={{
                        input: classes.textValue,
                        option: classes.textValue,
                        popupIndicatorOpen: classes.popIconOpen,
                        popupIndicator: classes.popIcon,
                        noOptions: classes.textNoOptions,
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="Squad"
                          name="field"
                          id="outlined-margin-dense"
                          margin="dense"
                          variant="outlined"
                          {...params}
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                        ></TextField>
                      )}
                    />
                  ) : null}
                </Grid>{" "}
                <Grid item xs={12} md={12} className={classes.subGrid}>
                  {/* <Typography variant="h6" gutterBottom>
                    Officer
                  </Typography> */}
                  {this.state.Officer ? (
                    <Autocomplete
                      className={classes.textField}
                      disableClearable="true"
                      disableOpenOnFocus="true"
                      options={this.state.Officer}
                      value={
                        this.state.officer && this.state.officer !== ""
                          ? this.state.officer
                          : null
                      }
                      name="officer"
                      getOptionLabel={(option) =>
                        option.name ? option.name : ""
                      }
                      onChange={(event, value) =>
                        this.handleChangeOfficer(event, value)
                      }
                      popupIcon={<DownArrow />}
                      classes={{
                        input: classes.textValue,
                        option: classes.textValue,
                        popupIndicatorOpen: classes.popIconOpen,
                        popupIndicator: classes.popIcon,
                        noOptions: classes.textNoOptions,
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="Officer"
                          name="officer"
                          id="outlined-margin-dense"
                          margin="dense"
                          variant="outlined"
                          {...params}
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                        ></TextField>
                      )}
                    />
                  ) : null}
                </Grid>{" "}
              </Grid>
            </>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <Grid md={2}></Grid>
            <Button
              onClick={this.closeWindow}
              disableElevation={true}
              variant="contained"
              className={classes.closeButton}
            >
              Cancel
            </Button>
            {/* <Button
              onClick={this.resetReportFilterParams}
              variant="contained"
              className={classes.clearButton}
            >
              Clear 
            </Button>*/}
            <Button
              onClick={() => this.setFilterParams()}
              variant="contained"
              color="secondary"
              disableElevation={true}
              className={classes.button}
            >
              Apply
            </Button>{" "}
            <Grid md={2}></Grid>
          </DialogActions>
        </Drawer>
      </div>
    );
  }
}
export default withStyles(styles)(Filter);
