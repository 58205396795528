import APIRequest from "../util/api/APIRequest";

class UserService {
  updatePassword = params => {
    return APIRequest({
      url: "api/resetpassword",
      data: params,
      method: "POST"
    });
  };
}
export default UserService;
