import {
  FETCH_WARNINGRECORD_SUCCESS,
  FETCH_WARNINGRECORD_FAILED,
} from "./constants";
const initialState = {
  fetchedRecord: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_WARNINGRECORD_SUCCESS:
      return {
        ...initialState,
        fetchedRecord: action.data.data,
      };
    case FETCH_WARNINGRECORD_FAILED:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
