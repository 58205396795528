import { FETCH_ALERT_LIST, FETCH_ALERT_LIST_ERROR } from "./constants";

const initialState = {
  alertList: [],
  alertCount: 0
};
export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALERT_LIST:
      return {
        ...state,
        alertList: action.data.data,
        alertCount: action.data.recordCount
      };
    case FETCH_ALERT_LIST_ERROR:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
