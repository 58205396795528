const styles = (theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  notAddedStyle: {
    backgroundColor: "rgb(193, 191, 191)",
    textAlign: "center",
    margin: 15,
    color: "#fff",
    fontSize: 25,
    padding: 50,
  },

  Headroot: {
    paddingRight: theme.spacing.unit,
    backgroundColor: theme.toolbar.backgroundColor,
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    color: theme.toolbar.iconColor,
    fontWeight: "300",
  },
  filterIcon: {
    color: theme.toolbar.iconColor,
  },
  listTop: {
    paddingTop: "20px",
  },
  fabBtn: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 30,
    left: "auto",
    position: "fixed",
  },
  buttons: {
    marginLeft: "87%",
    marginTop: "2%",
  },
});

export default styles;
