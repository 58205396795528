import { connect } from "react-redux";
import { withRouter } from "react-router";
import addRole from "./addRole";
import {
  fetchUserType,
  fetchFeatures,
  addRoleMaster,
  resetFeatures,
  resetUserTypeList,
  fetchRoleStatus,
  resetRoleStatus,
} from "./data/action";
import { push } from "connected-react-router";
import { snackbarActions } from "components/snackbar/data/action";
import {
  toggleFilterWindow,
  resetFilterParams,
  setFilterParams,
} from "../../../../components/filterrole_form/data/action";

function mapStateToProps(state) {
  return {
    userTypeList: state.userTypeList,
    featureList: state.featureList,
    saveSuccess: state.saveSuccess,
    roleStatus: state.saveSuccess.roleStatus,
    message: state.message,
    error: state.error,
    showFilterWindow: state.filter.showFilterWindow,
    filterParams: state.filter.filterParams,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRoleStatus: (params) => dispatch(fetchRoleStatus(params)),
    fetchUserType: () => dispatch(fetchUserType()),
    fetchFeatures: () => dispatch(fetchFeatures()),
    addRoleMaster: (data) => dispatch(addRoleMaster(data)),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
    toggleFilterWindow: () => dispatch(toggleFilterWindow()),
    resetFilterParams: () => dispatch(resetFilterParams()),
    resetUserTypeList: () => dispatch(resetUserTypeList()),
    resetFeatures: () => dispatch(resetFeatures()),
    setFilterParams: (filterParams) => dispatch(setFilterParams(filterParams)),
    navigateTo: (url) => dispatch(push(url)),
    resetRoleStatus: () => dispatch(resetRoleStatus()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(addRole)
);
