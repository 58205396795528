import React, { Fragment } from "react";
import classNames from "classnames";
import moment from "moment";
import {
  TableHead,
  TableRow,
  TableCell,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  Table,
  TableBody,
  // TablePagination,
  Modal,
  IconButton,
  MenuItem,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { ReactComponent as Edit } from "../../assets/Visual Assets/edit.svg";
import { ReactComponent as Delete } from "../../assets/Visual Assets/delete.svg";
import { ReactComponent as ClearIcon } from "../../assets/Visual Assets/clear.svg";
import { ReactComponent as SearchIcon } from "../../assets/Visual Assets/search.svg";
import { ReactComponent as DropdownIcon } from "../../assets/Visual Assets/dropdown.svg";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import FilterListIcon from "@material-ui/icons/FilterList";
import messageConstants from "../../services/validator/MsgConsts/messageConstants";
// import { confirmAlert } from "react-confirm-alert";
// import "react-confirm-alert/src/react-confirm-alert.css";
import Filter from "../../components/filterduty_form/Filter";
import StorageHandler from "../../util/storage/index";
import { ReactComponent as DownArrow } from "../../assets/SvgIcons/dropdown.svg";
import clsx from "clsx";
import "../userMaster/style.css";
import SkeltonScreen from "../../components/skelton_screen";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const rows = [
  {
    id: "slno",
    numeric: false,
    disablepadding: true,
    label: "Sl No",
    active: true,
  },

  {
    id: "latitude",
    numeric: false,
    disablepadding: true,
    label: "Squad Name",
    active: false,
  },
  {
    id: "latitude 1",
    numeric: false,
    disablepadding: true,
    label: "District",
    active: false,
  },
  {
    id: "longitude 1",
    numeric: false,
    disablepadding: true,
    label: "In time",
    active: false,
  },
  {
    id: "longitude",
    numeric: false,
    disablepadding: true,
    label: "Out time",
    active: false,
  },
];

const headerStyles = (theme) => ({
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 14px",
    borderColor: "#E7EBF4",
    backgroundColor: "white"
  },
  tableHead1: {
    textAlign: "right",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 14px",
    borderColor: "#E7EBF4",
    backgroundColor: "white"
    // marginLeft: "100px",
  },
  tableh: {
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 24px",
    borderColor: "#E7EBF4",
    backgroundColor: "white"
  },
});
class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.render.props.onRequestSort(event, property);
  };
  render() {
    const { order, orderBy } = this.props;
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                sortDirection={orderBy === row.id ? order : false}
                className={row.active ? classes.tableh : classes.tableHead}
              >
                {row.label}
              </TableCell>
            );
          }, this)}
          {new StorageHandler().getAttributPermissions().edit_status == true ? (
            <TableCell className={classes.tableHead1}>Edit</TableCell>
          ) : null}
          {new StorageHandler().getAttributPermissions().delete_status ==
            true ? (
              <TableCell
                className={classes.tableHead1}
                style={{ paddingRight: "35px" }}
              >
                Delete
              </TableCell>
            ) : null}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSeleceted: PropTypes.number.isRequired,
  onRequestsort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);
const toolbarStyles = (theme) => ({});

let EnhancedTableToolbar = (props) => {
  const { classes, showFilterWindow } = props;

  return null;
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.border.radius,
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    paddingBottom: "24px",
  },
  root1: {
    backgroundColor: theme.toolbar.backgroundColor,
  },
  spacer: {
    flex: "1 1 1%",
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: "33px",
    paddingRight: "31px",
    // paddingTop: "6px",
    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      minHeight: "12vh",
      maxHeight: "45vh",
      overflowY: "auto",
    },
    // /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
  },
  butt: {
    background: "white",
  },
  notAddedStyle: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 55,
    fontWeight: theme.font.fontWeight,
    marginLeft: 33,
    marginRight: 33,
    marginTop: 33,
    fontFamily: theme.font.family,
  },
  filterbutton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.palette.secondary.main,
    color: theme.colors.whiteColor,
  },
  filterclearButton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.BlueButton,
    color: theme.colors.whiteColor,
  },
  filtercloseButton: {
    fontWeight: theme.font.fontWeight,
    color: theme.colors.blackColor,
  },
  filterIcon: {
    width: "25px",
    height: "23px",
    color: "#A4AFC6",
    opacity: 1,
  },
  actions1: {
    marginTop: "8px",
    paddingRight: "16px",
  },
  textStyle: {
    color: "#ffffff",
  },
  root2: {
    color: "red",
    backgroundColor: "white",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "0px solid red",
    borderRadius: "5px",
    "& .MuiInputLabel-shrink": {
      paddingLeft: "7px",
      paddingTop: "5px",
    },
    "& MuiInputLabel-formControl ": {
      // paddingLeft: "20px",
      left: "10",
    },
    "& MuiInputLabel-root ": {
      paddingLeft: "50px",
    },
    "& MuiFormLabel-root ": {
      paddingLeft: "10px",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
  textField1: {
    marginTop: "19px",
    // paddingLeft: "14px",
    // paddingTop: "12px",
    // paddingBottom: "13px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    fontSize: theme.font.size3,
    fontFamily: theme.font.family,
    fontWeight: theme.font.regular,
    letterSpacing: "0px",
    color: "#797C82",
    height: "42px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    //  border: "1px solid #C4CCDC",
    borderRadius: "5px",
    opacity: 1,
  },
  textField2: {
    marginTop: "19px",
    // paddingLeft: "14px",
    // paddingTop: "12px",
    // paddingBottom: "13px",
    // width: "178px",
    height: "40px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // border: "1px solid #C4CCDC",
    borderRadius: "5px",
    opacity: 1,
    letterSpacing: "0px",
    color: "#797C82",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    fontSize: theme.font.size3,
    fontFamily: theme.font.family,
    fontWeight: theme.font.regular,
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },
  },
  buttons: {
    marginTop: "14px",
    marginRight: "9px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    width: "111px",
    height: "42px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    textTransform: "none",
  },
  btn: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    "&:hover": {
      background: "#E7EBF4 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginRight: "4px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  btns: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  tableTitle: {
    marginTop: "6px",
    marginLeft: "10px",
    width: "275px",
    height: "22px",
    textAlign: "left",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
  },
  grid: {
    marginTop: "26px",
    paddingTop: "17px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
  },
  divider: {
    marginTop: "18px",
    height: "0px",
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "15.5px",
    paddingBottom: "16px",
    height: "57px",
  },

  dialogBox: {
    // width: "327px",
    height: "161px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: "20px",
  },
  dialogContent: {
    textAlign: "center",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    font: "normal normal normal 16px Karla",
    marginTop: "11px",
    marginRight: "17px",
    marginLeft: "16px",
  },
  typography: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    font: "normal normal normal 16px Karla",
  },
  dialogActions: {
    "&.MuiDialogActions-root": {
      justifyContent: "center",
    },
  },
  gridContainer: {
    display: "flex",
    flexDirection: "row",
  },
  actionIcon: {
    cursor: "pointer",
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textMenu: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "6px 16px",
  },
  dropIcon: {
    marginTop: "8px",
    marginRight: "8px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  notAddedStyle1: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 55,
    // marginLeft: 33,
    // marginRight: 33,
    marginTop: 33,
    font: "normal normal normal 24px Karla;",
  },
  paginationText: {
    // textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingTop: "35px",
    paddingLeft: "33px",
  },
});

class DutyList extends React.Component {
  constructor(props) {
    sessionStorage.setItem("dutyMasterError", false);
    super(props);
    this.state = {
      attributePermissions: "",
      order: "asc",
      orderBy: "date_time",
      selected: [],
      data: [],
      modal: false,
      page: 0,
      rowsPerPage: 10,
      dutyCount: -1,
      open: false,
      dutys: "",
      duty: "",
      dist: "",
      dutyListFilter: "",
      filter_type: "",
      filter_squad: "",
      filter_district: "",
      filter_status: false,
    };
  }
  componentDidMount() {
    this.props.resetFilterParams();
    this.props.fetchDutyList(
      this.state.page,
      this.state.rowsPerPage,
      this.props.filterParams
    );
    this.props.fetchDistrict();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dutyLists != this.props.dutyLists) {
      this.setState({
        data: this.props.dutyLists,
        dutyCount: this.props.dutyCount,
      });
    }
    if (prevProps.duty != this.props.duty) {
      this.setState({
        data: this.props.duty.rows,
        dutyCount: this.props.duty.count,
      });
    }
    if (prevState.page != this.state.page) {
      // this.props.fetchDutyList(
      //   this.state.page,
      //   this.state.rowsPerPage,
      //   this.props.filterParams
      // );
      this.props.onFilterChange(
        this.state.page,
        this.state.limit,
        this.state.filter_squad,
        this.state.filter_district
      );
    }
    if (prevState.rowsPerPage != this.state.rowsPerPage) {
      this.props.fetchDutyList(
        this.state.page,
        this.state.rowsPerPage,
        this.props.filterParams
      );
    }
    if (this.props.deleteSuccess) {
      this.props.resetDelete();
      let params = {
        Page: this.state.page,
        rowsPerPage: this.state.rowsPerPage,
        filterParams: this.state.filterParams,
      };

      this.props.fetchDutyList(
        this.state.page,
        this.state.rowsPerPage,
        this.state.filterParams
      );
      this.props.showSnackbar(
        this.props.deleteSuccessReposnse.message,
        "success"
      );
    }
    if (prevProps.dist != this.props.dist) {
      this.setState({ dist: this.props.dist });
    }

    if (prevProps.duty != this.props.duty) {
      this.setState({
        dutys: this.props.duty.rows,
        dutyCount: this.props.duty.count,
      });
      // console.log("testduty", this.props.duty.rows);
      // console.log("testdutyss", this.props.duty.count);
    }
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleChangePage = (event, pagess) => {
    this.setState({ page: pagess - 1 });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  performAction = () => {
    this.props.navigateTo(`dutymaster/assign`);
  };

  editDuty = (duty_id) => {
    this.props.navigateTo(`dutymaster/update/${duty_id}`);
  };

  handleOpen = (duty_id, squad_name) => {
    this.setState({
      dutyId: duty_id,
      squad_name: squad_name,
      modal: !this.state.modal,
    });
  };

  deleteDuty = (dutyId) => {
    this.setState({
      modal: !this.state.modal,
    });
    this.props.deleteDuty(dutyId);
  };

  hangeDialCloseStatus = () => {
    this.props.dialogueWindowStatus();
  };
  changeDialCloseStatus = () => {
    this.props.toggleFilterWindow();
  };
  onUserNameChange = (event) => {
    this.setState({ filter_squad: event.target.value }, () => {
      this.props.onFilterChange(
        this.state.page,
        this.state.limit,
        this.state.filter_squad,
        this.state.filter_district
      );
    });
  };
  onDistrictNameChange = (event) => {
    this.setState({ filter_district: event.target.value }, () => {
      this.props.onFilterChange(
        this.state.page,
        this.state.limit,
        this.state.filter_squad,
        this.state.filter_district
      );
    });
  };
  onFilterChange = () => {
    this.props.onFilterChange(
      this.state.page,
      this.state.limit,
      this.state.filter_squad,
      this.state.filter_district
    );
    this.setState({ filter_status: true });
  };
  onFilterReset = () => {
    this.setState(
      {
        filter_status: true,
        filter_squad: "",
        filter_district: "",
      },
      () => {
        this.props.fetchDutyList(
          this.state.page,
          this.state.rowsPerPage,
          this.props.filterParams
        );
        this.props.fetchDistrict();
      }
    );
  };
  closeWindow = () => {
    this.props.toggleFilterWindow();
    if (!this.props.val) {
      this.setState({ filter_squad: "" });
      this.setState({ filter_district: "" });
    }
  };
  componentWillUnmount() {
    sessionStorage.setItem("dutyMasterError", false);
  }
  render() {
    const { classes, showFilterWindow } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, this.state.offendersCount - page * rowsPerPage);
    let firstPageNum = page * rowsPerPage + 1;
    return (
      <>
        <Grid className={classes.grid}>
          <Paper className={classes.root}>
            <Toolbar className={classNames(classes.roots)}>
              <Typography
                variant="h6"
                id="tableTitle"
                className={classes.tableTitle}
              >
                Duty Master
              </Typography>
              <div className={classes.spacer} />
              <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="custom-css-standard-input"
                    label="&nbsp; Squad Name"
                    variant="outlined"
                    className={classes.textField1}
                    fullWidth
                    margin="dense"
                    onChange={this.onUserNameChange}
                    value={this.state.filter_squad}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      classes: { root: classes.textValue },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="custom-css-standard-input"
                    label="&nbsp; Zone"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    select="true"
                    onChange={this.onDistrictNameChange}
                    value={this.state.filter_district}
                    className={classes.textField2}
                    InputProps={{ classes: { root: classes.textValue } }}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    SelectProps={{
                      IconComponent: DownArrow,
                      classes: {
                        icon: classes.dropIcon,
                        iconOpen: classes.dropIconOpen,
                      },
                    }}
                  // invalid={this.state.errors.user_type ? true : false}
                  >
                    <MenuItem value="" classes={{ root: classes.textMenu }}>
                      <em>None</em>
                    </MenuItem>
                    {this.state.dist !== "" && this.state.dist.rows
                      ? this.state.dist.rows.map((data) => (
                        <MenuItem
                          value={data.district_id}
                          classes={{ root: classes.textMenu }}
                        >
                          {data.district_name}
                        </MenuItem>
                      ))
                      : null}
                  </TextField>
                </Grid>{" "}
              </Grid>
              <div className={classes.actions1}>
                <Tooltip title="Clear fields">
                  <IconButton
                    aria-label="Filter list"
                    onClick={this.onFilterReset}
                  >
                    <ClearIcon className={classes.filterIcon} />
                  </IconButton>
                </Tooltip>{" "}
              </div>
              <div className={classes.actions}>
                {new StorageHandler().getAttributPermissions().add_status !==
                  null &&
                  new StorageHandler().getAttributPermissions().add_status ==
                  true ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation={true}
                      className={classes.buttons}
                      onClick={() => this.performAction()}
                    >
                      Assign Duty
                    </Button>
                  ) : null}{" "}
              </div>
            </Toolbar>
            <Divider className={classes.divider} />
            <EnhancedTableToolbar
              showFilterWindow={this.showFilterWindow}
            ></EnhancedTableToolbar>

            {/* {this.state.dutyCount === 0 ? (
              <div className={classes.notAddedStyle}>
                {messageConstants.NOT_ADDED}
              </div>
            ) : ( */}
            {/* {this.state.dutyCount === 0 ? (
              this.props.loading ? (
                <SkeltonScreen />
              ) : (
                <div className={classes.notAddedStyle}>
                  {messageConstants.NOT_ADDED}
                </div>
              )
            ) : ( */}
            {this.props.loading === false && this.state.dutyCount == 0 ? (
              <div className={classes.notAddedStyle}>
                {messageConstants.NOT_ADDED}
              </div>
            ) : (
                <>
                  <div className={classes.tableWrapper}>
                    {data && data.length > 0 ? (
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        stickyHeader
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={this.handleRequestSort}
                          rowCount={this.state.offendersCount}
                        />
                        <TableBody>
                          {data === "" || data === null || data === undefined
                            ? null
                            : data.map((n, i) => {
                              let from_date = moment(n.from_date).format(
                                "DD-MM-YYYY"
                              );
                              let to_date = moment(n.to_date).format(
                                "DD-MM-YYYY"
                              );
                              const isSelected = this.isSelected(n.id);
                              return (
                                <TableRow
                                  // hover
                                  role="checkbox"
                                  aria-checked={isSelected}
                                  tabIndex={-1}
                                  key={n.id}
                                  selected={isSelected}
                                  className={classes.zebrafont}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                    className={classes.tableCell}
                                    style={{ paddingLeft: "32px" }}
                                  >
                                    {firstPageNum + i}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {n.squad_master
                                      ? n.squad_master.squad_name
                                      : ""}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {n.squad_master
                                      ? n.squad_master.district_master
                                        ? n.squad_master.district_master
                                          .district_name
                                        : null
                                      : null}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {from_date} - {n.in_time}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {to_date} - {n.out_time}
                                  </TableCell>{" "}
                                  {new StorageHandler().getAttributPermissions()
                                    .edit_status == true ? (
                                      <TableCell
                                        className={classes.tableCell}
                                        style={{ textAlign: "right" }}
                                      >
                                        <i
                                          onClick={() => this.editDuty(n.duty_id)}
                                        >
                                          <Edit className={classes.actionIcon} />
                                        </i>
                                      </TableCell>
                                    ) : null}
                                  {new StorageHandler().getAttributPermissions()
                                    .delete_status == true ? (
                                      <TableCell
                                        className={classes.tableCell}
                                        style={{
                                          // paddingLeft: "25px",
                                          paddingRight: "50px",
                                          textAlign: "right",
                                        }}
                                      >
                                        <i
                                          onClick={() =>
                                            this.handleOpen(
                                              n.duty_id,
                                              n.squad_master
                                                ? n.squad_master.squad_name
                                                : ""
                                            )
                                          }
                                        >
                                          <Delete
                                            className={classes.actionIcon}
                                          />
                                        </i>
                                      </TableCell>
                                    ) : null}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    ) : sessionStorage.getItem("dutyMasterError") === "true" ? (
                      <div className={classes.notAddedStyle1}>
                        {messageConstants.NOT_ADDED}
                      </div>
                    ) : (
                          <SkeltonScreen />
                        )}
                  </div>
                  {data && data.length > 0 ? (
                    <Grid container xs={12}>
                      <Grid item xs={4}>
                        <Typography className={classes.paginationText}>
                          {parseInt(page) * rowsPerPage +
                            1 +
                            " - " +
                            (parseInt(page) * rowsPerPage + 10 >
                              this.props.dutyCount
                              ? this.props.dutyCount
                              : parseInt(page) * rowsPerPage + 10) +
                            " of " +
                            +this.props.dutyCount +
                            " Duties"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{ float: "right", paddingRight: "43px" }}
                        xs={8}
                      >
                        <Pagination
                          count={Math.ceil(this.state.dutyCount / rowsPerPage)}
                          shape="rounded"
                          page={page + 1}
                          onChange={this.handleChangePage}
                          className="pagination"
                          color="secondary"
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </>
              )}
            {/* <Grid style={{ paddingRight: "13px" }}>
              <TablePagination
                component="div"
                count={this.state.dutyCount}
                rowsPerPageOptions={[5, 10, 15]}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Grid> */}
            <Dialog
              open={this.state.modal}
              aria-labelledby="customized-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{
                paper: classes.dialogBox,
              }}
            >
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  className={classes.dialogContent}
                >
                  <Typography className={classes.typography}>
                    Are you sure want to delete {this.state.squad_name}?{" "}
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btn}
                  disableElevation={true}
                  onClick={() => this.handleOpen()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btns}
                  disableElevation={true}
                  onClick={() => this.deleteDuty(this.state.dutyId)}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </>
    );
  }
}

DutyList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(DutyList);

// import React, { Fragment } from "react";
// import classNames from "classnames";
// import moment from "moment";
// import {
//   TableHead,
//   TableRow,
//   TableCell,
//   Toolbar,
//   Tooltip,
//   Typography,
//   withStyles,
//   Paper,
//   Grid,
//   Button,
//   Table,
//   TableBody,
//   TablePagination,
//   Modal,
//   IconButton,
//   MenuItem,
// } from "@material-ui/core";
// import RotateLeftIcon from "@material-ui/icons/RotateLeft";

// import ClearAllIcon from "@material-ui/icons/ClearAll";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogActions from "@material-ui/core/DialogActions";
// import HighlightOffIcon from "@material-ui/icons/HighlightOff";

// import TextField from "@material-ui/core/TextField";
// import PropTypes from "prop-types";
// import FilterListIcon from "@material-ui/icons/FilterList";
// import Edit from "../../assets/SvgIcons/edit";
// import Delete from "../../assets/SvgIcons/delete";
// import messageConstants from "../../services/validator/MsgConsts/messageConstants";
// // import { confirmAlert } from "react-confirm-alert";
// // import "react-confirm-alert/src/react-confirm-alert.css";
// import Filter from "../../components/filterduty_form/Filter";
// import StorageHandler from "../../util/storage/index";

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

// function getModalStyle() {
//   const top = 50 + rand();
//   const left = 50 + rand();

//   return {
//     top: `${top}%`,
//     left: `${left}%`,
//     transform: `translate(-${top}%, -${left}%)`,
//   };
// }

// const rows = [
//   {
//     id: "#",
//     numeric: false,
//     disablepadding: true,
//     label: "#",
//   },

//   {
//     id: "latitude",
//     numeric: false,
//     disablepadding: true,
//     label: "Squad Name",
//   },
//   {
//     id: "latitude 1",
//     numeric: false,
//     disablepadding: true,
//     label: "District",
//   },
//   { id: "longitude 1", numeric: false, disablepadding: true, label: "In time" },
//   { id: "longitude", numeric: false, disablepadding: true, label: "Out time" },
// ];
// const tableHeaderStyles = (theme) => ({
//   tableTitle: {
//     fontWeight: theme.font.fontWeight,
//     // fontSize: theme.tableheader.fontSize,
//   },
// });
// const headerStyles = (theme) => ({
//   tableHead: {
//     fontSize: theme.tableHeader.fontsize,
//     fontWeight: theme.font.fontWeight,
//   },
// });
// class EnhancedTableHead extends React.Component {
//   createSortHandler = (property) => (event) => {
//     this.render.props.onRequestSort(event, property);
//   };
//   render() {
//     const { order, orderBy } = this.props;
//     const { classes } = this.props;
//     return (
//       <TableHead>
//         <TableRow>
//           {rows.map((row) => {
//             return (
//               <TableCell
//                 key={row.id}
//                 numeric={row.numeric}
//                 sortDirection={orderBy === row.id ? order : false}
//                 className={classes.tableHead}
//                 align="center"
//               >
//                 {/* <Tooltip
//                   title="Sort"
//                   placement={row.numeric ? "bottom-end" : "bottom-start"}
//                   enterDelay={300}
//                 >
//                   <TableSortLabel
//                     active={orderBy === row.id}
//                     direction={order}
//                     onClick={this.createSortHandler(row.id)}
//                   > */}
//                 {row.label}
//                 {/* </TableSortLabel>
//                 </Tooltip> */}
//               </TableCell>
//             );
//           }, this)}
//           {new StorageHandler().getAttributPermissions().edit_status == true ? (
//             <TableCell className={classes.tableHead} align="center">
//               Edit
//             </TableCell>
//           ) : null}
//           {new StorageHandler().getAttributPermissions().delete_status ==
//           true ? (
//             <TableCell className={classes.tableHead} align="center">
//               Delete
//             </TableCell>
//           ) : null}
//         </TableRow>
//       </TableHead>
//     );
//   }
// }

// EnhancedTableHead.propTypes = {
//   numSeleceted: PropTypes.number.isRequired,
//   onRequestsort: PropTypes.func.isRequired,
//   order: PropTypes.string.isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// };

// EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);
// const toolbarStyles = (theme) => ({});

// let EnhancedTableToolbar = (props) => {
//   const { classes, showFilterWindow } = props;

//   return null;
// };

// EnhancedTableToolbar.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

// const styles = (theme) => ({
//   root: {
//     width: "100%",
//   },
//   root1: {
//     backgroundColor: theme.toolbar.backgroundColor,
//   },
//   spacer: {
//     flex: " 1 1 20%",
//     justifyContent: "flex-end",
//   },
//   table: {
//     minWidth: 1020,
//   },
//   tableWrapper: {
//     overflowX: "auto",
//   },
//   butt: {
//     background: "white",
//   },
//   tableCell: {
//     fontSize: theme.tableCell.fontSize,
//     fontWeight: theme.font.fontWeight,
//   },
//   notAddedStyle: {
//     backgroundColor: theme.notAddedStyle.backgroundColor,
//     textAlign: "center",
//     margin: 15,
//     color: theme.notAddedStyle.color,
//     fontSize: 25,
//     padding: 50,
//     fontWeight: theme.font.fontWeight,
//   },
//   filterbutton: {
//     fontWeight: theme.font.fontWeight,
//     backgroundColor: theme.palette.secondary.main,
//     color: theme.colors.whiteColor,
//   },
//   filterclearButton: {
//     fontWeight: theme.font.fontWeight,
//     backgroundColor: theme.colors.BlueButton,
//     color: theme.colors.whiteColor,
//   },
//   filtercloseButton: {
//     fontWeight: theme.font.fontWeight,
//     color: theme.colors.blackColor,
//   },

//   buttons: {
//     marginLeft: "87%",
//     marginTop: "2%",
//     backgroundColor: theme.palette.secondary.main,
//     color: theme.notAddedStyle.color,
//     fontWeight: theme.font.fontWeight,
//   },
//   btn: {
//     fontWeight: theme.font.fontWeight,
//     backgroundColor: theme.palette.secondary.main,
//     color: theme.colors.whiteColor,
//   },
//   btns: {
//     fontWeight: theme.font.fontWeight,
//     backgroundColor: theme.palette.secondary.main,
//     color: theme.colors.whiteColor,
//   },
//   tableTitle: {
//     color: theme.toolbar.iconColor,
//     fontWeight: theme.font.fontWeight,
//     paddingTop: "12px",
//   },
//   paper: {
//     position: "absolute",
//     width: theme.spacing.unit * 60,
//     backgroundColor: theme.palette.background.paper,
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing.unit * 4,
//   },
//   zebrafont: {
//     "&:nth-of-type(odd)": {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
//   filterIcon: {
//     color: theme.toolbar.iconColor,
//     marginTop: "9px",
//   },
//   textStyle: {
//     color: "#ffffff",
//   },
//   root2: {
//     color: "red",
//     backgroundColor: "white",
//     paddingLeft: "10px",
//     paddingRight: "10px",
//     border: "0px solid red",
//     borderRadius: "5px",
//     "& .MuiInputLabel-shrink": {
//       paddingLeft: "7px",
//       paddingTop: "5px",
//     },
//     "& MuiInputLabel-formControl ": {
//       // paddingLeft: "20px",
//       left: "10",
//     },
//     "& MuiInputLabel-root ": {
//       paddingLeft: "50px",
//     },
//     "& MuiFormLabel-root ": {
//       paddingLeft: "10px",
//     },
//     "& .MuiInput-underline:before": {
//       borderBottomColor: "white",
//     },
//     "& .MuiInput-underline:after": {
//       borderBottomColor: "white",
//     },
//     "& .MuiSelect-select.MuiSelect-select": {
//       background: "none",
//     },

//     "& .MuiOutlinedInput-root": {
//       "& fieldset": {
//         borderColor: "red",
//       },
//       "&:hover fieldset": {
//         borderColor: "yellow",
//       },
//       "&.Mui-focused fieldset": {
//         borderColor: "green",
//       },
//     },
//   },
// });

// class DutyList extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       attributePermissions: "",
//       order: "asc",
//       order: "asc",
//       orderBy: "date_time",
//       selected: [],
//       data: [],
//       modal: false,
//       page: 0,
//       rowsPerPage: 10,
//       dutyCount: 0,
//       open: false,
//       dutys: "",
//       duty: "",
//       dist: "",
//       dutyListFilter: "",
//       filter_type: "",
//       filter_squad: "",
//       filter_district: "",
//       filter_status: false,
//     };
//   }
//   componentDidMount() {
//     this.props.resetFilterParams();
//     this.props.fetchDutyList(
//       this.state.page,
//       this.state.rowsPerPage,
//       this.props.filterParams
//     );
//     this.props.fetchDistrict();

//     console.log(new StorageHandler().getAttributPermissions());
//   }

//   componentDidUpdate(prevProps, prevState) {
//     if (prevProps.dutyLists != this.props.dutyLists) {
//       this.setState({
//         data: this.props.dutyLists,
//         dutyCount: this.props.dutyCount,
//       });
//       console.log("tttttt", this.props.dutyCount);
//       console.log("dutylist", this.props.dutyLists);
//     }
//     if (prevProps.duty != this.props.duty) {
//       console.log("test duty", this.props.duty);
//       this.setState({
//         data: this.props.duty.rows,
//         dutyCount: this.props.duty.count,
//       });
//       console.log("countttt", this.props.duty.count);
//     }
//     if (prevState.page != this.state.page) {
//       this.props.fetchDutyList(
//         this.state.page,
//         this.state.rowsPerPage,
//         this.props.filterParams
//       );
//     }
//     if (prevState.rowsPerPage != this.state.rowsPerPage) {
//       this.props.fetchDutyList(
//         this.state.page,
//         this.state.rowsPerPage,
//         this.props.filterParams
//       );
//     }
//     if (this.props.deleteSuccess) {
//       this.props.resetDelete();
//       let params = {
//         Page: this.state.page,
//         rowsPerPage: this.state.rowsPerPage,
//         filterParams: this.state.filterParams,
//       };

//       this.props.fetchDutyList(
//         this.state.page,
//         this.state.rowsPerPage,
//         this.state.filterParams
//       );
//       this.props.showSnackbar(
//         this.props.deleteSuccessReposnse.message,
//         "success"
//       );
//     }
//     if (prevProps.dist != this.props.dist) {
//       this.setState({ dist: this.props.dist });
//     }

//     if (prevProps.duty != this.props.duty) {
//       this.setState({
//         dutys: this.props.duty.rows,
//         dutyCount: this.props.duty.count,
//       });
//       console.log("testduty", this.props.duty.rows);
//       console.log("testdutyss", this.props.duty.count);
//     }
//   }

//   isSelected = (id) => this.state.selected.indexOf(id) !== -1;

//   handleChangePage = (event, page) => {
//     this.setState({ page });
//   };

//   handleChangeRowsPerPage = (event) => {
//     // console.log(event.target.value);
//     this.setState({ rowsPerPage: event.target.value });
//   };

//   performAction = () => {
//     this.props.navigateTo(`dutymaster/assign`);
//   };

//   editDuty = (duty_id) => {
//     this.props.navigateTo(`dutymaster/update/${duty_id}`);
//   };

//   handleOpen = (duty_id, squad_name) => {
//     this.setState({
//       dutyId: duty_id,
//       squad_name: squad_name,
//       modal: !this.state.modal,
//     });
//   };

//   deleteDuty = (dutyId) => {
//     this.setState({
//       modal: !this.state.modal,
//     });
//     this.props.deleteDuty(dutyId);
//   };

//   hangeDialCloseStatus = () => {
//     this.props.dialogueWindowStatus();
//   };
//   changeDialCloseStatus = () => {
//     this.props.toggleFilterWindow();
//   };
//   onUserNameChange = (event) => {
//     this.setState({ filter_squad: event.target.value }, () => {
//       this.props.onFilterChange(
//         this.state.page,
//         this.state.limit,
//         this.state.filter_squad,
//         this.state.filter_district
//       );
//     });
//   };
//   onDistrictNameChange = (event) => {
//     this.setState({ filter_district: event.target.value }, () => {
//       this.props.onFilterChange(
//         this.state.page,
//         this.state.limit,
//         this.state.filter_squad,
//         this.state.filter_district
//       );
//     });
//   };
//   onFilterChange = () => {
//     this.props.onFilterChange(
//       this.state.page,
//       this.state.limit,
//       this.state.filter_squad,
//       this.state.filter_district
//     );
//     this.setState({ filter_status: true });
//   };
//   onFilterReset = () => {
//     this.setState({ filter_status: true });
//     this.setState({ filter_squad: "" });
//     this.setState({ filter_district: "" });
//     this.props.onFilterChange();
//     this.props.fetchDistrict();
//   };
//   closeWindow = () => {
//     this.props.toggleFilterWindow();
//     if (!this.props.val) {
//       this.setState({ filter_squad: "" });
//       this.setState({ filter_district: "" });
//     }
//   };

//   render() {
//     const { classes, showFilterWindow } = this.props;
//     const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
//     const emptyRows =
//       rowsPerPage -
//       Math.min(rowsPerPage, this.state.offendersCount - page * rowsPerPage);
//     let firstPageNum = page * rowsPerPage + 1;
//     return (
//       <>
//         <Toolbar className={classNames(classes.root1)}>
//           <Grid xs={12} container justify="space-between">
//             <Grid item xs={4} md={4} alignItems={"center"}>
//               <Typography
//                 variant="h6"
//                 id="tableTitle"
//                 className={classes.tableTitle}
//               >
//                 Duty Master
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               className={classes.spacer}
//               container
//               xs={8}
//               md={8}
//               spacing={2}
//             >
//               <Grid item xs={12} md={3}></Grid>
//               <Grid item xs={12} md={3}>
//                 <>
//                   <TextField
//                     id="custom-css-standard-input"
//                     label="&nbsp; Squad Name"
//                     className={classes.root2}
//                     fullWidth
//                     onChange={this.onUserNameChange}
//                     value={this.state.filter_squad}
//                   />
//                 </>
//               </Grid>
//               <Grid item xs={12} md={3}>
//                 <TextField
//                   className={classes.margin}
//                   id="custom-css-standard-input"
//                   label="&nbsp; Zone"
//                   className={classes.root2}
//                   fullWidth={true}
//                   select="true"
//                   onChange={this.onDistrictNameChange}
//                   value={this.state.filter_district}
//                   // invalid={this.state.errors.user_type ? true : false}
//                 >
//                   <MenuItem value="">
//                     <em>None</em>
//                   </MenuItem>
//                   {this.state.dist !== "" && this.state.dist.rows
//                     ? this.state.dist.rows.map((data) => (
//                         <MenuItem value={data.district_id}>
//                           {data.district_name}
//                         </MenuItem>
//                       ))
//                     : null}
//                 </TextField>
//               </Grid>

//               <div className={classes.actions1}>
//                 <Tooltip title="Clear fields">
//                   <IconButton
//                     aria-label="Filter list"
//                     onClick={this.onFilterReset}
//                   >
//                     <RotateLeftIcon className={classes.filterIcon} />
//                   </IconButton>
//                 </Tooltip>
//               </div>
//             </Grid>
//           </Grid>
//         </Toolbar>

//         <Fragment>
//           <Paper className={classes.root}>
//             <EnhancedTableToolbar
//               showFilterWindow={this.showFilterWindow}
//             ></EnhancedTableToolbar>
//             <Grid item spacing={16}>
//               {new StorageHandler().getAttributPermissions().add_status ==
//               true ? (
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   className={classes.buttons}
//                   onClick={() => this.performAction()}
//                 >
//                   Assign Duty
//                 </Button>
//               ) : null}
//             </Grid>

//             {this.state.dutyCount === 0 ? (
//               <div className={classes.notAddedStyle}>
//                 {messageConstants.NOT_ADDED}
//               </div>
//             ) : (
//               <div className={classes.tableWrapper}>
//                 <Table className={classes.table} aria-labelledby="tableTitle">
//                   <EnhancedTableHead
//                     numSelected={selected.length}
//                     order={order}
//                     orderBy={orderBy}
//                     onRequestSort={this.handleRequestSort}
//                     rowCount={this.state.offendersCount}
//                   />
//                   <TableBody>
//                     {data === "" || data === null || data === undefined
//                       ? null
//                       : data.map((n, i) => {
//                           let from_date = moment(n.from_date).format(
//                             "DD-MM-YYYY"
//                           );
//                           let to_date = moment(n.to_date).format("DD-MM-YYYY");
//                           const isSelected = this.isSelected(n.id);
//                           return (
//                             <TableRow
//                               // hover
//                               role="checkbox"
//                               aria-checked={isSelected}
//                               tabIndex={-1}
//                               key={n.id}
//                               selected={isSelected}
//                               className={
//                                 i % 2 === 0
//                                   ? classes.tableRowStyle
//                                   : classes.tableRowStyles
//                               }
//                             >
//                               <TableCell
//                                 style={{ fontSize: "18px" }}
//                                 component="th"
//                                 scope="row"
//                                 className={classes.tableCell}
//                                 align="center"
//                               >
//                                 {firstPageNum + i}
//                               </TableCell>
//                               <TableCell
//                                 className={classes.tableCell}
//                                 align="center"
//                               >
//                                 {n.squad_master
//                                   ? n.squad_master.squad_name
//                                   : ""}
//                               </TableCell>
//                               <TableCell
//                                 className={classes.tableCell}
//                                 align="center"
//                               >
//                                 {n.squad_master
//                                   ? n.squad_master.district_master
//                                     ? n.squad_master.district_master
//                                         .district_name
//                                     : null
//                                   : null}
//                               </TableCell>
//                               <TableCell
//                                 className={classes.tableCell}
//                                 align="center"
//                               >
//                                 {from_date} <br></br>
//                                 {n.in_time}
//                               </TableCell>
//                               <TableCell
//                                 className={classes.tableCell}
//                                 align="center"
//                               >
//                                 {to_date} <br></br>
//                                 {n.out_time}
//                               </TableCell>{" "}
//                               {new StorageHandler().getAttributPermissions()
//                                 .edit_status == true ? (
//                                 <TableCell
//                                   className={classes.tableCell}
//                                   align="center"
//                                 >
//                                   <i onClick={() => this.editDuty(n.duty_id)}>
//                                     <Edit />
//                                   </i>
//                                 </TableCell>
//                               ) : null}
//                               {new StorageHandler().getAttributPermissions()
//                                 .delete_status == true ? (
//                                 <TableCell
//                                   className={classes.tableCell}
//                                   align="center"
//                                 >
//                                   <i
//                                     onClick={() =>
//                                       this.handleOpen(
//                                         n.duty_id,
//                                         n.squad_master
//                                           ? n.squad_master.squad_name
//                                           : ""
//                                       )
//                                     }
//                                   >
//                                     <Delete />
//                                   </i>
//                                 </TableCell>
//                               ) : null}
//                             </TableRow>
//                           );
//                         })}
//                   </TableBody>
//                 </Table>
//               </div>
//             )}
//             <TablePagination
//               component="div"
//               count={this.state.dutyCount}
//               rowsPerPageOptions={[5, 10, 15]}
//               rowsPerPage={rowsPerPage}
//               page={page}
//               backIconButtonProps={{
//                 "aria-label": "Previous Page",
//               }}
//               nextIconButtonProps={{
//                 "aria-label": "Next Page",
//               }}
//               onChangePage={this.handleChangePage}
//               onChangeRowsPerPage={this.handleChangeRowsPerPage}
//             />

//             <Dialog
//               aria-labelledby="alert-dialog-title"
//               aria-labelledby="draggable-dialog-title"
//               open={this.state.modal}
//             >
//               <DialogTitle id="form-dialog-title">
//                 <Typography variant="subtitle1" id="simple-modal-description">
//                   {/* Are you sure you want to delete ?{" "} */}
//                   Are you sure you want to delete {this.state.squad_name}?{" "}
//                 </Typography>
//               </DialogTitle>{" "}
//               <DialogActions>
//                 {/* <Grid> */}{" "}
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   className={classes.btns}
//                   onClick={() => this.handleOpen()}
//                 >
//                   Cancel
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   className={classes.btn}
//                   onClick={() => this.deleteDuty(this.state.dutyId)}
//                 >
//                   OK
//                 </Button>
//                 {/* </Grid> */}
//                 {/* </div>{" "} */}
//               </DialogActions>
//             </Dialog>
//           </Paper>
//         </Fragment>
//       </>
//     );
//   }
// }

// DutyList.propTypes = {
//   classes: PropTypes.object.isRequired,
// };
// export default withStyles(styles)(DutyList);
