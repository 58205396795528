import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import RecentActivity from "../recent_activity/RecentActivity_live";
import PhotoStepper from "../photo_stepper/";
import VehicleEwayBill from "../vehicle_ewaybill/";
import { toDateTime } from "../../../../util/date";
import messageConstants from "../../../../services/validator/MsgConsts/messageConstants";

const styles = (theme) => ({
  root: {
    width: "100%",
    opacity: 1,
    paddingBottom: "24px",
  },
  grid: {
    paddingTop: "19px",
    paddingRight: "18px",
    paddingLeft: "22px",
  },
  vehicleImageStyle: {
    height: "149px",
    overflow: "auto",
    border: 1,
  },
  paperDiv: {
    height: "239px",
    borderRadius: "15px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
  },

  emptyInfo: {
    flexGrow: 1,
    display: "block",
    textAlign: "center",
    borderRadius: "5px",
    padding: "50px",
    fontWeight: theme.font.fontWeight,
  },

  alertIcon: {
    color: theme.alertIcon.color,
  },
  noAlertIcon: {
    color: theme.noAlertIcon.color,
  },
  numberPlate: {
    backgroundColor: "#FFEE00",
    font: "normal normal bold 28px/39px Karla",
    color: "#2B3D51",
  },

  typo: {
    font: "normal normal normal 16px/36px Karla",
    color: "#2B3D51",
  },
});

class VehicleInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidUpdate(prevProps) {
    if (prevProps.record !== this.props.record) {
      //this.props.fetchRecord(this.props.record);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.grid}>
        <Grid container spacing={3}>
          {this.props.record !== null ? (
            <>
              <Grid item xs={4}>
                <Paper className={classes.paperDiv}>
                  <div>
                    <div
                      className={classes.vehicleImageStyle}
                      style={{
                        // paddingTop: "20px",

                        borderTopLeftRadius: "15px",
                        borderTopRightRadius: "15px",
                      }}
                    >
                      <PhotoStepper />
                    </div>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      style={{ padding: "11px", paddingBottom: "0px" }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          align="center"
                          className={classes.numberPlate}
                        >
                          {this.props.record.plate}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      style={{
                        paddingLeft: "11px",
                        paddingRight: "11px",
                        paddingTop: "0px",
                        paddingBottom: "13px",
                      }}
                    >
                      <Typography className={classes.typo}>
                        Camera
                        {this.props.record.camera_id}
                      </Typography>
                      <Typography className={classes.typo}>
                        {toDateTime(this.props.record.event_timestamp)}
                      </Typography>
                    </Grid>
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={4}>
                <Paper className={classes.paperDiv}>
                  <VehicleEwayBill />
                </Paper>
              </Grid>

              <Grid item xs={4}>
                <Paper className={classes.paperDiv}>
                  <RecentActivity />
                </Paper>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
    );
  }
}
VehicleInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VehicleInfo);
