import {
  FETCH_REPORT_DATA_SUCCESS,
  FETCH_REPORT_DATA_FAILED,
  RESET_REPORT_DATA,
} from "./constants";
const initialState = {
  reportData: [],
  types: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORT_DATA_SUCCESS:
      var types = false;
      if (action.data.data.length > 0 && action.data.data[0].monthly) {
        types = true;
      }
      return {
        ...state,
        reportData: action.data.data,
        types: types,
      };
    case FETCH_REPORT_DATA_FAILED:
      sessionStorage.setItem("summaryreportsMasterError", true);
      return {
        ...state,
        reportData: [],
        types: false,
      };
    case RESET_REPORT_DATA:
      return {
        ...state,
        reportData: [],
        types: false,
      };
    default:
      return state;
  }
}
