import React, { Component } from "react";
import { withStyles, Grid } from "@material-ui/core";
import BarChart from "./barchart_declarative/BarChart";
import PieChart from "./pichart/PieChart";
import DeclrativePie from "./piechart_declarative/PieChart";
import Overview from "./overview/";
import TestChart from "./language/TestChart";
import DeclarativeLine from "./line_declrative/DeclarativeLine";
import LineGraph from "./line_declrative/LineGraph";
import VehicleDetail from "./vehicleDetails/vehicleDetail";
import { connect } from "react-redux";

const styles = (theme) => ({
  overallGrid: {
    minHeight: "12vh",
    maxHeight: "84vh",
    overflowX: "hidden",
    overflowY: "auto",
  },
  grid1: {
    padding: "13px",
    paddingLeft: "20px",
    paddingTop: "19px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "22px",
    },
  },
  grid2: {
    padding: "13px",
    paddingRight: "21px",
    paddingTop: "19px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "22px",
    },
  },
  grid1Open: {
    padding: "9px",
    paddingLeft: "20px",
    paddingTop: "19px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "22px",
    },
  },
  grid2Open: {
    padding: "11px",
    paddingRight: "21px",
    paddingTop: "19px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "22px",
    },
  },
  grid3: {
    paddingTop: "16px",
    paddingLeft: "20px",
    paddingRight: "21px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "22px",
    },
  },
});
class Dashboard extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={12} className={classes.overallGrid}>
        <Grid item xs={12}>
          {" "}
          <Overview />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={
            this.props.showAppDrawer ? classes.grid1Open : classes.grid1
          }
        >
          <BarChart />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={
            this.props.showAppDrawer ? classes.grid2Open : classes.grid2
          }
        >
          <DeclrativePie width={200} height={200} />
        </Grid>
        {/* <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.grid3}
        >
          <VehicleDetail />
        </Grid> */}
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  return {
    showAppDrawer: state.drawer.showAppDrawer,
  };
}

export default connect(mapStateToProps, null)(withStyles(styles)(Dashboard));
