import { connect } from "react-redux";
import { withRouter } from "react-router";
import RoleList from "./roleMaster";
import {
  fetchRollMasterList,
  fetchPermissionList,
} from "./components/addRole/data/action";
import {
  toggleFilterWindow,
  resetFilterParams,
  setFilterParams,
} from "../../components/filterrole_form/data/action";
import { push } from "connected-react-router";
import { fetchUserTypeList } from "./components/viewRole/data/action";

function mapStateToProps(state) {
  return {
    roleMasterList: state.roleMasterList,
    permissionList: state.permissionList,
    userTypeList: state.userTypeList,
    deleteStatus: state.saveSuccess.deleteStatus,
    showFilterWindow: state.filter.showFilterWindow,
    filterParams: state.filter.filterParams,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserTypeList: () => dispatch(fetchUserTypeList()),
    fetchRollMasterList: () => dispatch(fetchRollMasterList()),
    fetchPermissionList: (id) => dispatch(fetchPermissionList(id)),
    // fetchUserType: () => dispatch(fetchUserType()),

    // fetchvehicleList: (page, limit, filterParams) =>
    //   dispatch(fetchvehicleList(page, limit, filterParams)),
    // fetchRoleList: (page, limit, filterParams) =>
    //   dispatch(fetchRoleList(page, limit, filterParams)),
    toggleFilterWindow: () => dispatch(toggleFilterWindow()),
    resetFilterParams: () => dispatch(resetFilterParams()),
    setFilterParams: (filterParams) => dispatch(setFilterParams(filterParams)),
    navigateTo: (url) => dispatch(push(url)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RoleList)
);
