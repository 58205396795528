import {
  FETCH_RECENT_ALERTS_SUCCESS,
  FETCH_RECENT_ALERTS_FAIL
} from "./constants";

const initialState = {
  recentAlerts: []
};
export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_RECENT_ALERTS_SUCCESS:
      return {
        ...state,
        recentAlerts: action.data.data
      };
    case FETCH_RECENT_ALERTS_FAIL:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
