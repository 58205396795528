import APIRequest from "../util/api/APIRequest";
class FlammableGoodsService {
  fetchDutyList = (page, limit, filterParams) => {
    return APIRequest({
      url: `api/dutys`,
      params: {
        page,
        limit,
      },
      data: filterParams,
      method: "GET",
    });
  };

  deleteDuty = (id) => {
    return APIRequest({
      url: `api/dutys/${id}`,
      method: "DELETE",
    });
  };
  fetchDistrict = (params) => {
    return APIRequest({
      url: `api/districts`,
      data: params,
      method: "GET",
    });
  };
  fetchSqard = (id) => {
    return APIRequest({
      url: `api/squads/district/${id}`,

      method: "GET",
    });
  };
  fetchSquardDetails = (id) => {
    return APIRequest({
      url: `api/squads/${id}`,
      method: "GET",
    });
  };
  addDuty = (params) => {
    return APIRequest({
      url: `api/dutys`,
      data: params,
      method: "POST",
    });
  };

  fetchDuty = (id) => {
    return APIRequest({
      url: `api/dutys/${id}`,
      method: "GET",
    });
  };

  editDuty = (params, id) => {
    return APIRequest({
      url: `api/dutys/${id}`,
      data: params,
      method: "PUT",
    });
  };
  ///api/dutys?page=0&limit=1&squadname=update&district=2
  onFilterChange = (page, limit, squadname, district) => {
    let params = {
      page: page === "" ? null : page,
      limit: limit === "" ? null : limit,
      squadname: squadname === "" ? null : squadname,
      district: district === "" ? null : district,
    };
    return APIRequest({
      url: `api/dutys`,
      params: params,
      method: "GET",
    });
  };
}

export default FlammableGoodsService;
