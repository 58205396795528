import {
  TOGGLE_FILTER_WINDOW,
  SET_FILTER_PARAMS,
  RESET_FILTER_PARAMS,
  FETCH_SQUAD_NAME,
  FETCH_SQUAD_NAME_FAILED,
  FETCH_DISTRICT,
  FETCH_DISTRICT_FAILED,
  CLOSE_WINDOW,
} from "./constants";
const initialState = {
  showFilterWindow: false,
  filterParams: {},
  //   fetchSquadNames: [],
  //   fetchDistricts: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FILTER_WINDOW:
      return {
        ...state,
        showFilterWindow: !state.showFilterWindow,
      };
    case CLOSE_WINDOW:
      return {
        ...state,
        val: !state.val,
      };
    case SET_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.filterParams,
      };
    case RESET_FILTER_PARAMS:
      return {
        ...state,
        filterParams: {},
      };
    case FETCH_SQUAD_NAME:
      return {
        ...state,
        fetchSquadNames: action.data.data,
      };
    case FETCH_SQUAD_NAME_FAILED:
      return {
        ...initialState,
      };
    case FETCH_DISTRICT:
      return {
        ...state,
        fetchDistricts: action.data.data,
      };
    case FETCH_DISTRICT_FAILED:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
