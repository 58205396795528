/** @format */

import APIRequest from "../util/api/APIRequest";

class RoleMasterService {
  addRoleMaster = (params) => {
    return APIRequest({
      url: `api/roles`,
      data: params,
      method: "POST",
    });
  };

  fetchRoleStatus = (params) => {
    let usertypeid = params.usertypeid;
    let rolename = params.rolename;
    return APIRequest({
      url: `api/roles/serach/usertype/${usertypeid}/rolename/${rolename}`,
      method: "GET",
    });
  };

  // onFilterChange = (type, rolename, page, limit) => {
  //   return APIRequest({
  //     url: `api/roles`,
  //     params: {
  //       type,
  //       rolename,
  //       page,
  //       limit,
  //     },
  //     method: "GET",
  //   });
  // };
  fetchUserTypeList = () => {
    return APIRequest({
      url: `api/userType`,
      method: "GET",
    });
  };
  fetchFeatureList = () => {
    return APIRequest({
      url: `api/menus/menu`,
      method: "GET",
    });
  };

  fetchRollMasterList = (page, limit) => {
    return APIRequest({
      url: `api/roles`,
      method: "GET",
      params: {
        page,
        limit,
      },
    });
  };
  onFilterChange = (type, rolename, page, limit) => {
    // console.log(type, rolename, page, limit);
    if (type === "undefined" || type === null || type === "") {
      return APIRequest({
        url: `api/roles`,
        params: {
          rolename,
          page,
          limit,
        },
        method: "GET",
      });
    }
    return APIRequest({
      url: `api/roles`,
      params: {
        type,
        rolename,
        page,
        limit,
      },

      method: "GET",
    });
  };
  fetchPermissionList = (id) => {
    return APIRequest({
      url: `api/roles/${id}`,
      method: "GET",
    });
  };

  deleteRoleMaster = (id) => {
    return APIRequest({
      url: `api/roles/${id}`,
      method: "DELETE",
    });
  };

  updateRoleMaster = (params) => {
    return APIRequest({
      url: `api/roles`,
      data: params,
      method: "PUT",
    });
  };
}
export default RoleMasterService;
