import {
  ADD_EDIT_DESIGNATION_MASTER,
  ADD_EDIT_DESIGNATION_MASTER_FAILED,
  RESET_DESIGNATION_MASTER_STATE,
  FETCH_DESIGNATION_MASTER_FAILED,
  FETCH_DESIGNATION_MASTER_SUCCESS,
  FETCH_DESIGNATION_DETAILS,
  FETCH_DESIGNATION_DETAILS_ERROR,
  DELETE_DESIGNATION_MASTER,
  DELETE_DESIGNATION_MASTER_ERROR,
} from "./constants";
import DesignationMasterService from "service/DesignationMasterService";
import { snackbarActions } from "../../../../../components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";
//import { fail } from "assert";

export const addDesignationMaster = (params) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DesignationMasterService()
      .addDesignationMaster(params)
      .then((response) => {
        if (response.data) {
          snackbarActions.showSnackbar(response.data.message, "success");
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: ADD_EDIT_DESIGNATION_MASTER, data };
  }
  function failure(error) {
    return { type: ADD_EDIT_DESIGNATION_MASTER_FAILED, error };
  }
};

export const fetchDesignationMaster = (page, rowsPerPage) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DesignationMasterService()
      .fetchDesignationMaster(page, rowsPerPage)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: FETCH_DESIGNATION_MASTER_SUCCESS, data };
  }
  function failure(error) {
    return { type: FETCH_DESIGNATION_MASTER_FAILED, error };
  }
};

export const fetchDesignationDetails = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DesignationMasterService()
      .fetchDesignationDetails(id)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: FETCH_DESIGNATION_DETAILS, data };
  }
  function failure(error) {
    return { type: FETCH_DESIGNATION_DETAILS_ERROR, error };
  }
};

export const editDesignationMaster = (params, id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DesignationMasterService()
      .editDesignationMaster(params, id)
      .then((response) => {
        if (response.data) {
          snackbarActions.showSnackbar(response.data.message, "success");
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: ADD_EDIT_DESIGNATION_MASTER, data };
  }
  function failure(error) {
    return { type: ADD_EDIT_DESIGNATION_MASTER_FAILED, error };
  }
};

export const deleteDesignationMaster = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DesignationMasterService()
      .deleteDesignationMaster(id)
      .then((response) => {
        if (response.data) {
          dispatch(
            snackbarActions.showSnackbar(response.data.message, "error")
          );

          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: DELETE_DESIGNATION_MASTER, data };
  }
  function failure(error) {
    return { type: DELETE_DESIGNATION_MASTER_ERROR, error };
  }
};

export function resetDesignationMasterDetail() {
  return {
    type: RESET_DESIGNATION_MASTER_STATE,
  };
}
