import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Button, Avatar } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Paper, Hidden } from "@material-ui/core";
// import UltsLogo from "../../screens/login/images/ults-logo.png";
import UltsLogo from "../../screens/login/images/ULTSLOGO.png";
import Progress from "../progressbar/Progress";
import SecurityIcon from "@material-ui/icons/Security";
import { SOCKET_URL } from "../../apiConfig";
import io from "socket.io-client";
import Storagehandler from "../../util/storage";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Tooltip from "@material-ui/core/Tooltip";
import messageConstants from "../../services/validator/MsgConsts/messageConstants";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import zIndex from "@material-ui/core/styles/zIndex";
import axios from "axios";
import { ReactComponent as CameraIcon } from "../../assets/SvgIcons/camera_access.svg";
import { ReactComponent as LogoutIcon } from "../../assets/SvgIcons/logout.svg";
import { ReactComponent as ChangePasswordIcon } from "../../assets/SvgIcons/change_password.svg";
import { ReactComponent as DropIcon } from "../../assets/SvgIcons/dropdown.svg";
import { ReactComponent as AlertIcon } from "../../assets/SvgIcons/notification.svg";

const drawerWidth = 267;
const styles = (theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    paddingTop: "11px",
    paddingBottom: "11px",
    height: "90px",
  },

  appBar: {
    backgroundColor: theme.appBar.backgroundColor,
    height: "90px",
    //boxShadow: theme.appBar.boxShadow,
    //zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    height: "90px",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  title: {
    flexGrow: 1,
    color: theme.palette.primary.main,
    fontSize: theme.font.size4,
    fontWeight: theme.font.bold,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
  },

  appBarSpacer: theme.mixins.toolbar,

  appBarHead: {
    flexGrow: 1,
    marginLeft: 110,
  },
  poweredByStyle: {
    //backgroundColor: theme.poweredByStyle.backgroundColor,
    color: theme.poweredByStyle.color,
    fontWeight: "100",
    borderRadius: "2px",
    padding: "4px",
    cursor: "pointer",
    display: "flex",
  },
  poweredByLogoStyle: {
    height: "17px",
    width: "45px",
    paddingLeft: "5px",
    marginLeft: "2px",
    marginTop: "0px",
  },
  progress: {},
  paper: {
    width: "195px",
    height: "84px",
    borderRadius: "9px",
    paddingTop: "0px",
    paddingBottom: "0px",
    // paddingLeft: "12px",
    // marginRight: theme.spacing.unit * 2,
  },
  menuPaper: { paddingTop: "0px", paddingBottom: "0px", borderRadius: "9px" },
  menuItem: {
    paddingTop: "15px",
    paddingBottom: "9px",
    borderTopLeftRadius: "9px",
    borderTopRightRadius: "9px",
    "&:hover": {
      backgroundColor: "#E7EBF4",
    },
    "&.Mui-selected": {
      backgroundColor: "#E7EBF4",
    },
  },
  menuItem2: {
    // paddingTop: "15px",
    paddingBottom: "17px",
    borderBottomLeftRadius: "9px",
    borderBottomRightRadius: "9px",
    "&:hover": {
      backgroundColor: "#E7EBF4",
    },
  },

  badge: {
    marginLeft: "10%",
    width: "2%",
  },
  alertCount: {
    fontFamily: "Karla",
  },
  button: {
    color: theme.appBarButton.color,
    cursor: "pointer",
  },
  iconColor: {
    color: theme.appBarIcon.color,
  },
  appName: {
    height: "31px",
    textAlign: "left",
    font: "normal normal",
    letterSpacing: "0px",
    color: "#1BB99A",
    textTransform: "uppercase",
    opacity: 1,
    fontFamily: "Karla",
    fontSize: "24px",
    fontWeight: "bold",
  },
  appName2: {
    width: "125px",
    height: "23px",
    textAlign: "left",
    font: "normal normal",
    fontWeight: "bold",
    letterSpacing: "0px",
    color: "#2B3D51",
    textTransform: "none",
    fontFamily: "Karla",
    fontSize: "20px",
    opacity: 1,
  },
  allCamera: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingLeft: "8px",
    textTransform: "none",
  },
  allCamera1: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingLeft: "10px",
    textTransform: "none",
  },
  avatar: {
    marginRight: "8px",
    width: "41px",
    backgroundColor: "#1BB99A",
    height: "41px",
    font: "normal normal normal 24px Karla",
  },
  buttonStyle: {
    marginRight: "30px",
  },
  buttonStyle2: {
    marginLeft: "33px",
  },
});

var refreshIntervalId;
class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertCount: 0,
      warningData: {},
      warningCount: {},
      multipleEwayBillCount: {},
      flammableGoodsCount: {},
      multipleEwayBill: {},

      isHabitualOffender: true,
      open: false,
      userOpen: false,
      anchorEl: null,
      userModal: false,
    };
    this.socket = null;
  }

  doLogout = () => {
    this.props.doLogout();
    this.props.navigateTo("/login");
  };
  handleAlertClick = () => {
    this.setState({
      ...this.state,
      alertCount: 0,
    });

    this.props.navigateTo("/alerts/");
  };

  componentDidMount() {
    // let routeUrl = "/dashboard";
    // this.props.changetoAppbarPath(routeUrl);
    let storageHandler = new Storagehandler();
    if (!storageHandler.isAuthenticated()) {
      this.doLogout();
    } else {
      const camera = storageHandler.getUserCameraPermissions();
      if (camera[0].location_master !== undefined) {
        if (camera.length > 0) {
          const temp = this.selectCameraBasedOnLocation(camera);
          let oldData = null;
          this.socket = io.connect(SOCKET_URL, {
            query: {
              token: storageHandler.getToken(),
            },
          });
          this.props.setLocationId(temp[0], oldData);
          this.props.getOverviewData(this.socket, temp[0], oldData);
        }
      } else {
        this.doLogout();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pathname !== this.props.pathname) {
      if (
        this.props.pathname === "/user"
        // ||
        // this.props.pathname === "/chooseCamera"
      ) {
        let routeUrl = "/dashboard";
        this.props.changetoAppbarPath(routeUrl);
      } else if (this.props.pathname === "/camera") {
        let routeUrl = "/camera";
        this.props.changetoAppbarPath(routeUrl);
      } else if (this.props.pathname === "/dashboard") {
        let routeUrl = "/dashboard";
        this.props.changetoAppbarPath(routeUrl);
      } else if (
        this.props.pathname === "/alerts/lists/2" ||
        this.props.pathname === "/alerts/lists/3" ||
        this.props.pathname === "/alerts/"
      ) {
        let routeUrl = "/alerts/";
        this.props.changetoAppbarPath(routeUrl);
      }
    }

    if (prevProps.locationData !== this.props.locationData) {
      this.props.getOverviewDatas(
        this.socket,
        this.props.locationData,
        this.props.oldLocationData
      );
      this.props.resetVehicleList();
      this.props.resetPlateNumber();
      this.props.resetSelectedRecord();
    }
    if (prevProps.messageData != this.props.messageData) {
      this.props.getVehicleList(this.props.messageData);
    }

    if (prevProps.vehicleList !== this.props.vehicleList) {
      let vehicleWithalert = this.props.vehicleList.filter((item, index) => {
        return item.alertFlag;
      });
      this.setState({ ...this.state, alertCount: vehicleWithalert.length });
    }

    if (prevProps.warningCount != this.props.warningCount) {
      this.setState({ ...this.state, warningCount: this.props.warningCount });
    }
    if (
      this.props.warningCount.habitualOffendersCount !== 0 &&
      this.props.warningCount.habitualOffendersCount !==
        prevProps.warningCount.habitualOffendersCount
    ) {
      this.props.warningCount.habitualOffendersCount = this.props.warningCount.habitualOffendersCount;
      // this.props.showSnackbar("Habitual Offenders Found.....!", "error");
      this.props.showSnackbar(
        messageConstants.HABITUAL_OFFENDER_FOUND,
        "error"
      );
    }

    if (prevProps.flammableGoodsCount != this.props.flammableGoodsCount) {
      this.setState({
        ...this.state,
        flammableGoodsCount: this.props.flammableGoodsCount,
      });
    }
    if (
      this.props.flammableGoodsCount.flammableVehiclesCount !== 0 &&
      this.props.flammableGoodsCount.flammableVehiclesCount !==
        prevProps.flammableGoodsCount.flammableVehiclesCount
    ) {
      this.props.flammableGoodsCount.flammableVehiclesCount = this.props.flammableGoodsCount.flammableVehiclesCount;
      this.props.showSnackbar(messageConstants.FLAMMABLE_GOODS_FOUND, "error");
    }

    if (prevProps.multipleEwayBillCount != this.props.multipleEwayBillCount) {
      this.setState({
        ...this.state,
        multipleEwayBillCount: this.props.multipleEwayBillCount,
      });
    }
    if (
      this.props.multipleEwayBillCount.multipleEwaybillCount !== 0 &&
      this.props.multipleEwayBillCount.multipleEwaybillCount !==
        prevProps.multipleEwayBillCount.multipleEwaybillCount
    ) {
      this.props.multipleEwayBillCount.multipleEwaybillCount = this.props.multipleEwayBillCount.multipleEwaybillCount;
      this.props.showSnackbar(messageConstants.SAME_EWAY_BILL, "error");
    }

    if (
      this.props.warningCount.habitualOffendersCount ||
      this.props.multipleEwayBillCount.multipleEwaybillCount ||
      this.props.flammableGoodsCount.flammableVehiclesCount
    ) {
      refreshIntervalId = setInterval(this.run.bind(this), 1500);
      // repeatedly calls a function or executes a code snippet,
      //with a fixed time delay between each call.
      //It returns an interval ID which uniquely identifies the interval
    }
    if (
      this.props.warningCount.habitualOffendersCount === 0 &&
      this.props.multipleEwayBillCount.multipleEwaybillCount === 0 &&
      this.props.flammableGoodsCount.flammableVehiclesCount === 0
    ) {
      clearInterval(refreshIntervalId);
    }
  }

  componentWillUnmount() {
    if (this.socket !== null) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
  selectCameraBasedOnLocation = (cameraList) => {
    let x = [];
    let dictSub = {};
    let sub = [];
    let sup = [];
    if (cameraList.length > 0) {
      x.push("All Camera");
    }
    cameraList.map((obj) => {
      x.push(obj.location_master.place);
    });
    x = [...new Set(x)];

    x.map((x) => {
      cameraList.map((y) => {
        if (x === y.location_master.place) {
          sub.push(y.vendor_camera_id);
        } else if (x === "All Camera") {
          sub.push(y.vendor_camera_id);
        }
      });
      dictSub = {
        location: x,
        cameraId: sub,
      };

      sup.push(dictSub);
      sub = [];
      dictSub = [];
    });
    return sup;
  };

  handleToggle = () => {
    this.setState((state) => ({ open: !state.open }));
    this.setState({ anchorEl: null });
  };
  handleUserToggle = () => {
    this.setState((state) => ({ userOpen: !state.userOpen }));
    this.setState({ anchorEl: null });
  };

  handleClose = (event) => {
    this.setState({ open: false, userOpen: false });
  };
  userInfo = () => {
    this.props.navigateTo(`/user`);
    this.setState({ anchorEl: null, open: false, userOpen: false });
  };
  cameraChange = () => {
    this.props.navigateTo("/chooseCamera");
  };
  handleoffenders = () => {
    this.props.navigateTo("/alerts/lists/2");
    this.setState({ open: false });
  };

  handleewaybill = () => {
    this.props.navigateTo("/alerts/lists/3");
    this.setState({ open: false });
  };
  handleFlammableGoods = () => {
    this.props.navigateTo("/alerts/lists/4");
    this.setState({ open: false });
  };

  run = () => {
    this.el &&
      this.el.animate(
        [
          { transform: "scale(1.3)" },
          { transform: "scale(2.3)" },
          { transform: "scale(1.3)" },
        ],
        {
          duration: 800,
        }
      );
  };
  onPoweredByClick = () => {
    let win = window.open("http://ults.in", "_blank");
    win.focus();
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    const { anchorEl } = this.state;

    return (
      <AppBar
        elevation={0}
        position="absolute"
        //position="fixed"
        className={classNames(
          classes.appBar,
          this.props.showAppDrawer && classes.appBarShift
        )}
      >
        <Toolbar
          disableGutters={!this.props.showAppDrawer}
          className={classes.toolbar}
        >
          <Typography
            variant="h6"
            // color="inherit"

            noWrap
            className={classNames(
              classes.title,
              !this.props.showAppDrawer && classes.appBarHead
            )}
          >
            <Typography className={classes.appName}>
              ANPR <span className={classes.appName2}>Management</span>
            </Typography>
          </Typography>
          {this.props.pathname === "/dashboard" ||
          this.props.pathname === "/camera" ? (
            <Tooltip title="Camera Location" placement="top">
              <Button
                color="inherit"
                // color={classes.iconColor}
                placement="bottom"
                onClick={this.cameraChange}
                className={classes.buttonStyle}
              >
                <CameraIcon />
                <Typography className={classes.allCamera}>
                  {this.props.locationData === ""
                    ? null
                    : this.props.locationData.location}
                </Typography>
              </Button>
            </Tooltip>
          ) : null}

          <Tooltip title="Notifications" placement="top">
            <IconButton
              //color="inherit"
              color={classes.iconColor}
              placement="bottom"
              onClick={this.handleAlertClick}
            >
              {this.state.alertCount ? (
                <Badge
                  className={classes.alertCount}
                  badgeContent={this.state.alertCount}
                  color="secondary"
                >
                  <AlertIcon />
                </Badge>
              ) : (
                <AlertIcon />
              )}
            </IconButton>
          </Tooltip>

          <Button
            color="inherit"
            // color={classes.iconColor}
            placement="bottom"
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
            aria-owns={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
            className={classes.buttonStyle2}
          >
            <Avatar
              className={classes.avatar}
              style={{ backgroundColor: "#1BB99A" }}
            >
              {new Storagehandler().getUser() &&
              new Storagehandler().getUser().basicDetails
                ? new Storagehandler().getUser().basicDetails.name.charAt(0)
                : "A"}
            </Avatar>
            <DropIcon />
          </Button>

          <Popper
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={classes.popper}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper classes={{ root: classes.paper }}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      classes={{
                        root: classes.menuPaper,
                      }}
                    >
                      <MenuItem
                        onClick={this.userInfo}
                        selected={this.props.pathname == "/user" ? true : false}
                        // selected
                        classes={{
                          root: classes.menuItem,
                        }}
                      >
                        <ChangePasswordIcon />
                        <Typography className={classes.allCamera1}>
                          Change Password
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={this.doLogout}
                        classes={{ root: classes.menuItem2 }}
                      >
                        <LogoutIcon />
                        <Typography className={classes.allCamera1}>
                          Logout
                        </Typography>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
        <Progress />
      </AppBar>
    );
  }
}
export default withStyles(styles)(AppHeader);
