import React, { Fragment } from "react";
import {
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import classNames from "classnames";
import propTypes from "prop-types";
import validator from "../../../../services/validator";
import { find } from "lodash";
// import Recaptcha from "react-google-invisible-recaptcha";
// import CaptchaSiteKeyMasters from "../../../../services/siteKeyMasters";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    backgroundColor: theme.toolbar.backgroundColor,
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    color: theme.toolbar.iconColor,
    fontWeight: theme.font.fontWeight,
  },
});

let EnhancedTableToolbar = (props) => {
  const { classes } = props;
  return (
    <Toolbar className={classNames(classes.root)}>
      <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
        Evasion Prone Commodity Goods Vehicle Details
      </Typography>
      <div className={classes.spacer} />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: propTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  textField: {
    width: "95%",
    //  paddingTop: "2%"
    //marginLeft: "5%"
  },
  textFielddes: {
    paddingTop: "5%",
    marginLeft: "2%",
    fontWeight: theme.font.fontWeight,
  },
  button: {
    marginLeft: theme.spacing.unit * 2,
    width: "30%",
    marginBottom: "2%",
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.palette.secondary.main,
    color: theme.colors.whiteColor,
  },
  cancelButton: {
    width: "30%",
    marginBottom: "2%",
    marginLeft: theme.spacing.unit * 2,
    fontWeight: theme.font.fontWeight,
    color: theme.colors.blackColor,
  },
  btns: {
    paddingTop: "2%",
    paddingRight: "30%",
    marginLeft: "35%",
  },
  err: {
    paddingLeft: "1%",
  },
  dense: {
    marginTop: 16,
  },
  vldclr: {
    color: "#FF0000",
  },
  alignment: {
    flexDirection: "row",
  },
  radbtns: {
    paddingLeft: "5%",
  },
  formitems: {
    paddingTop: "6%",
  },
});

class flammableGoodsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.onResolved = this.onResolved.bind(this);

    this.state = {
      n: 2,
      data: {
        vehicleNumber: "",
        status: "true",
      },
      alertType: "",
      alertVisible: false,
      alert: "",
      errors: {
        vehicleNumber: "",
      },
      rules: {
        vehicleNumber: {
          required: true,
        },
      },
    };
  }
  componentDidMount() {
    if (this.props.match.params.goods_id) {
      this.props.fetchFlammableGoods(this.props.match.params.goods_id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.goods_id &&
      this.props !== prevProps &&
      this.props.fetchedFlammableGoods.vehicle_number !==
        this.state.vehicle_number
    ) {
      this.setState({
        data: {
          vehicleNumber: this.props.fetchedFlammableGoods.vehicle_number,
          status: this.props.fetchedFlammableGoods.active.toString(),
        },
      });
    }

    if (prevProps.flammableGoods != this.props.flammableGoods) {
      if (this.props.flammableGoods) {
        this.props.showSnackbar(this.props.message, "success");
        this.onBackButtonClick();
      }
    }
    if (this.props.saveSuccess !== prevProps.saveSuccess && !this.props.error) {
      this.setState(
        {
          alertVisible: true,
          alertType: "success",
          alert: this.props.message,
        },
        () => {
          setTimeout(() => {
            this.props.resetFlammableGoodsDetail();
          }, 1000);
        }
      );
    }
    if (this.props.error && this.props.error !== this.state.alert) {
      this.setState({
        alert: this.props.error,
        alertType: "danger",
        alertVisible: true,
      });
    }
  }

  onBackButtonClick = () => {
    this.props.navigateTo("/flammableGoods-master");
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };

  validate = () => {
    const { rules, data } = this.state;
    const errors = validator(rules)(data);
    const hasErrors = find(errors, (error) => error !== "");
    this.setState({ errors });
    return !hasErrors;
  };

  performAction = (e) => {
    if (this.props.match.params.goods_id) {
      if (this.validate()) {
        // this.recaptcha.execute();
        this.onResolved();
      }
    } else {
      if (this.validate()) {
        // this.recaptcha.execute();
        this.onResolved();
      }
    }
  };
  onResolved = () => {
    let params = {
      vehicle_number: this.state.data.vehicleNumber,
      active: this.state.data.status,
    };

    if (this.props.match.params.goods_id) {
      if (this.validate()) {
        this.props.editFlammableGoods(params, this.props.match.params.goods_id);
      }
    } else {
      if (this.validate()) {
        this.props.addFlammableGoods(params);
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar onBackButtonClick={this.onBackButtonClick} />
        <div className={classes.textFielddes}>
          <Fragment>
            <div className={classes.root}>
              <Grid container spacing={24}>
                <Grid xs={4} item>
                  <FormControl error={true} className={classes.textField}>
                    <TextField
                      label="Vehicle Number(eg.KL 12 AX 3242)"
                      className={classes.textField}
                      name="vehicleNumber"
                      id="vehicleNumber"
                      value={this.state.data.vehicleNumber}
                      onChange={(e) => this.handleChange(e)}
                      margin="dense"
                      fullWidth
                      variant="outlined"
                    />
                    <FormHelperText>
                      {this.state.errors.vehicleNumber}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item>
                  <Typography variant="h6" className={classes.textFielddes}>
                    Status
                  </Typography>
                </Grid>
                <Grid direction="row" className={classes.radbtns}>
                  <FormControl
                    component="fieldset"
                    className={classes.formitems}
                  >
                    <Grid>
                      <RadioGroup
                        aria-label="Is active?"
                        name="status"
                        className={classes.alignment}
                        value={this.state.data.status}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Active"
                        />

                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Inactive"
                        />
                      </RadioGroup>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
              <div className={classes.btns}>
                <Grid>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => this.performAction()}
                  >
                    SAVE
                  </Button>

                  <Button
                    // variant="contained"
                    // color="secondary"
                    className={classes.cancelButton}
                    onClick={() => this.onBackButtonClick()}
                  >
                    CANCEL
                  </Button>
                  {/* <Recaptcha
                    ref={ ref => this.recaptcha = ref }
                    sitekey={CaptchaSiteKeyMasters}
                    size="invisible"
                    onResolved={ this.onResolved } 
                  /> */}
                </Grid>
              </div>
            </div>
          </Fragment>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(flammableGoodsDetails);
