import { push } from "connected-react-router";
import { withRouter } from "react-router";
import flammableGoodsDetails from "./flammableGoodsDetails";
import { connect } from "react-redux";
import {
  addFlammableGoods,
  fetchFlammableGood,
  editFlammableGoods,
  resetFlammableGoodsDetail
} from "./data/action";
import { snackbarActions } from "components/snackbar/data/action";

function mapStateToProps(state) {
  return {
    saveSuccess: state.flammableGoodsDetails.saveSuccess,
    message: state.flammableGoodsDetails.message,
    error: state.flammableGoodsDetails.error,
    flammableGoods: state.flammableGoodsDetails.flammableGoods,
    fetchedFlammableGoods: state.flammableGoodsDetails.fetchedFlammableGoods
  };
}

function mapDispatchToProps(dispach) {
  return {
    navigateTo: url => dispach(push(url)),
    // addHabitualOffender: data => dispach(addHabitualOffender(data)),
    fetchFlammableGoods: id => dispach(fetchFlammableGood(id)),
    editFlammableGoods: (data, id) => dispach(editFlammableGoods(data, id)),
    resetFlammableGoodsDetail: () => dispach(resetFlammableGoodsDetail()),
    addFlammableGoods: data => dispach(addFlammableGoods(data)),
    showSnackbar: (message, type) =>
      dispach(snackbarActions.showSnackbar(message, type))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(flammableGoodsDetails)
);
