import {
  FETCH_WARNING_LIST,
  FETCH_WARNING_LIST_ERROR,
  SET_ACTUALWARNING_PAGE,
  RESET_ACTUALWARNING_PAGE,
  RESET_ERROR_PAGE,
} from "./constants";
import WarningService from "../../../service/WarningService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchActualWarningList = (page, limit, type, filterParams) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new WarningService()
      .fetchActualWarningList(page, limit, type, filterParams)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: FETCH_WARNING_LIST, data };
  }
  function failure(error) {
    return { type: FETCH_WARNING_LIST_ERROR, error };
  }
};

export const setActualWarningPage = (page) => {
  return (dispatch) => {
    dispatch(setActualWarningPage(page));
  };
  function setActualWarningPage(page) {
    return { type: SET_ACTUALWARNING_PAGE, page };
  }
};

export const resetActualWarningPage = () => {
  return (dispatch) => {
    dispatch(resetActualWarningPage());
  };
  function resetActualWarningPage() {
    return { type: RESET_ACTUALWARNING_PAGE };
  }
};
export const resetError = () => {
  return (dispatch) => {
    dispatch(resetError());
  };
  function resetError() {
    return { type: RESET_ERROR_PAGE };
  }
};
