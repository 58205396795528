import React, { Component } from "react";
import { Element } from "react-faux-dom";
import * as d3 from "d3";
import { Paper } from "@material-ui/core";
import "./style.css";
const data = [
  {
    country: "India",
    value: 273,
  },
  {
    country: "USA",
    value: 2420,
  },
  {
    country: "China",
    value: 1270,
  },
  {
    country: "UK",
    value: 553,
  },
  {
    country: "Germany",
    value: 731,
  },
  {
    country: "Sweden",
    value: 136,
  },
  {
    country: "France",
    value: 682,
  },
  {
    country: "Australia",
    value: 239,
  },
  {
    country: "Canada",
    value: 367,
  },
  {
    country: "Brazil",
    value: 442,
  },
];

class PieChart extends Component {
  plot(chart, width, height) {
    // create scales!
    var radius = Math.min(width, height) / 2;
    var color = d3.scaleOrdinal(d3.schemeCategory10);
    var pie = d3.pie();
    var arc = d3
      .arc()
      .innerRadius(radius - 100)
      .outerRadius(radius);
    var arcs = chart
      .selectAll("arc")
      .data(pie(data.map((d) => d.value)))
      .enter()
      .append("g")
      .attr("class", "arc");

    arcs
      .append("path")
      .attr("fill", function(d, i) {
        return color(i);
      })
      .attr("d", arc);

    arcs
      .append("text")
      .attr("text-anchor", "middle")
      .attr("x", function(d) {
        var a = d.startAngle + (d.endAngle - d.startAngle) / 2 - Math.PI / 2;
        d.cx = Math.cos(a) * (radius - 45);
        return (d.x = Math.cos(a) * (radius + 30));
      })
      .attr("y", function(d) {
        var a = d.startAngle + (d.endAngle - d.startAngle) / 2 - Math.PI / 2;
        d.cy = Math.sin(a) * (radius - 45);
        return (d.y = Math.sin(a) * (radius + 30));
      })
      .text(function(d) {
        return d.value.toFixed(2);
      })
      .each(function(d) {
        /* var bbox = d.getBBox();
        d.sx = d.x - bbox.width / 2 - 2;
        d.ox = d.x + bbox.width / 2 + 2;
        d.sy = d.oy = d.y + 5; */
      });

    arcs
      .append("path")
      .attr("class", "pointer")
      .style("fill", "red")
      .style("stroke", "black")
      .attr("d", function(d) {
        if (d.cx > d.ox) {
          return (
            "M" +
            d.sx +
            "," +
            d.sy +
            "L" +
            d.ox +
            "," +
            d.oy +
            " " +
            d.cx +
            "," +
            d.cy
          );
        } else {
          return (
            "M" +
            d.ox +
            "," +
            d.oy +
            "L" +
            d.sx +
            "," +
            d.sy +
            " " +
            d.cx +
            "," +
            d.cy
          );
        }
      });
  }

  drawChart() {
    const width = 500;
    const height = 400;

    const el = new Element("div");
    const svg = d3
      .select(el)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const margin = {
      top: 60,
      bottom: 100,
      left: 80,
      right: 40,
    };

    const chart = svg
      .append("g")
      .classed("display", true)
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;
    this.plot(chart, chartWidth, chartHeight);

    return el.toReact();
  }

  render() {
    return <Paper>{this.drawChart()}</Paper>;
  }
}

export default PieChart;
