import { push } from "connected-react-router";
import WarningsDetailedView from "./Warnings_detailedview.jsx";
import { connect } from "react-redux";
import { setPlateNumber } from "../../../camera/components/recent_activity/data/action";
import { fetchWarningRecord, fetchWarningsNextandPrev } from "./data/action";
import { snackbarActions } from "components/snackbar/data/action";

function mapStateToProps(state) {
  return {
    fetchedWarningRecord: state.warningDetailView.fetchedRecord,
    loading: state.progress.loading,
    filterParams: state.warningfilter.filterParams,
    event_timestamp: state.historyList.event_timestamp,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => dispatch(push(url)),
    setPlateNumber: (plate) => dispatch(setPlateNumber(plate)),
    fetchWarningRecord: (id, event_timestamp) =>
      dispatch(fetchWarningRecord(id, event_timestamp)),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
    fetchWarningsNextandPrev: (data) =>
      dispatch(fetchWarningsNextandPrev(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarningsDetailedView);
