import APIRequest from "../util/api/APIRequest";

class HistoryService {
  fetchRecord = (recordID) => {
    return APIRequest({
      url: `vehicle/${recordID}`,
      method: "GET",
    });
  };
  fetchHistoryList = (page, limit, filterParams) => {
    return APIRequest({
      url: `api/history`,
      method: "POST",
      params: {
        page,
        limit,
      },
      data: filterParams,
    });
  };
}
export default HistoryService;
