import { EDIT_USER, EDIT_USER_FAILED, RESET_USER_STATE } from "./constants";

const initialState = {
  error: "",
  saveSuccess: false,
  message: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case EDIT_USER:
      return {
        ...state,
        saveSuccess: true,
        message: action.data.message,
        error:
          action.response !== undefined ? action.response.data.error[0] : ""
      };

    case EDIT_USER_FAILED:
      return {
        ...initialState,
        error: action.response !== undefined ? action.response.data.message : ""
      };
    case RESET_USER_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
