import APIRequest from "../util/api/APIRequest";

class SquadService {
  fetchSquadList = (page, limit, filterParams) => {
    return APIRequest({
      url: `api/squads`,
      params: {
        page,
        limit,
      },
      data: filterParams,
      method: "GET",
    });
  };

  deleteSquad = (id) => {
    return APIRequest({
      url: `api/squads/${id}`,
      method: "DELETE",
    });
  };

  addSquad = (params) => {
    return APIRequest({
      url: `/api/squads`,
      data: params,
      method: "POST",
    });
  };

  fetchMemberData = (id, district_id) => {
    return APIRequest({
      url: `api/users/pen/${id}/district/${district_id}`,
      method: "GET",
    });
  };

  fetchSquadDetails = (id) => {
    return APIRequest({
      url: `/api/squads/${id}`,
      method: "GET",
    });
  };

  editSquad = (params, id) => {
    return APIRequest({
      url: `/api/squads/${id}`,
      data: params,
      method: "PUT",
    });
  };

  fetchZone = () => {
    return APIRequest({
      url: "/api/zones",
      method: "GET",
    });
  };

  fetchDistrict = (id) => {
    return APIRequest({
      url: `api/districtList`,
      params: { id },
      method: "GET",
    });
  };
  fetchDistricts = (params) => {
    return APIRequest({
      url: `api/districts`,
      data: params,
      method: "GET",
    });
  };

  fetchField = (id) => {
    return APIRequest({
      url: `api/fieldList`,
      params: { id },
      method: "GET",
    });
  };
  fetchOffice = (id) => {
    return APIRequest({
      url: `api/offices/location/${id}`,
      method: "GET",
    });
  };
  fetchLeader = (id) => {
    return APIRequest({
      url: `api/users/office/${id}`,
      method: "GET",
    });
  };
  onFilterChange = (page, limit, squadname, district) => {
    // console.log(page, limit, squadname, district);
    if (district === "undefined" || district === null || district === "") {
      return APIRequest({
        url: `api/squads`,
        params: {
          page,
          limit,
          squadname,
        },

        method: "GET",
      });
    }

    return APIRequest({
      url: `api/squads`,
      params: {
        // page,
        // limit,
        squadname,
        district,
      },

      method: "GET",
    });
  };
}

export default SquadService;
// api/squads?page=0&limit=10&squadname=test&district=1
