import { connect } from "react-redux";
import { withRouter } from "react-router";
import ActionSummaryReport from "./actionsummary";
import { resetReportData, fetchReport, exportReport } from "./data/actions";
import {
  fetchOverallReport,
  // exportOverallReport,
  resetOverallReportData,
} from "./components/overallActionSummary/data/actions";

function mapStateToProps(state) {
  return {
    reportData: state.actionsummary.reportData,
    reportDatas: state.summaryAction.reportData,
    reportFilterParams: state.actionsummaryreportfilter.reportFilterParams,
    loading: state.progress.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchReport: (data) => dispatch(fetchReport(data)),
    fetchOverallReport: (data) => dispatch(fetchOverallReport(data)),
    exportReport: (data) => dispatch(exportReport(data)),
    // exportOverallReport: (data) => dispatch(exportOverallReport(data)),
    resetReportData: () => dispatch(resetReportData()),
    resetOverallReportData: () => dispatch(resetOverallReportData()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionSummaryReport)
);
