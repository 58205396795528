export const ADD_EDIT_DESIGNATION_MASTER = "ADD_EDIT_DESIGNATION_MASTER";
export const DELETE_DESIGNATION_MASTER = "DELETE_DESIGNATION_MASTER";
export const DELETE_DESIGNATION_MASTER_ERROR =
  "DELETE_DESIGNATION_MASTER_ERROR";
export const ADD_EDIT_DESIGNATION_MASTER_FAILED =
  "ADD_EDIT_DESIGNATION_MASTER_FAILED";
export const RESET_DESIGNATION_MASTER_STATE = "RESET_DESIGNATION_MASTER_STATE";
export const FETCH_DESIGNATION_MASTER_SUCCESS =
  "FETCH_DESIGNATION_MASTER_SUCCESS";
export const FETCH_DESIGNATION_MASTER_FAILED =
  "FETCH_DESIGNATION_MASTER_FAILED";
export const FETCH_DESIGNATION_DETAILS = "FETCH_DESIGNATION_DETAILS";
export const FETCH_DESIGNATION_DETAILS_ERROR =
  "FETCH_DESIGNATION_DETAILS_ERROR";
