import {
  ADD_EDIT_HABITUAL_OFFENDERS,
  ADD_EDIT_HABITUAL_OFFENDERS_FAILED,
  FETCH_HABITUAL_OFFENDER_SUCCESS,
  FETCH_HABITUAL_OFFENDER_FAILED,
  RESET_HABITUAL_OFFENDER_STATE,
} from "./constants";

const initialState = {
  fetchedHabitualOffender: {},
  habitualOffenders: "",
  error: "",
  saveSuccess: false,
  message: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_HABITUAL_OFFENDER_SUCCESS:
      return {
        ...initialState,
        fetchedHabitualOffender: action.data.data,
      };

    case FETCH_HABITUAL_OFFENDER_FAILED:
      return {
        ...initialState,
      };

    case ADD_EDIT_HABITUAL_OFFENDERS:
      return {
        ...state,
        habitualOffenders: action.data,
        saveSuccess: true,
        message: action.data.message,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };

    case ADD_EDIT_HABITUAL_OFFENDERS_FAILED:
      return {
        ...initialState,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };
    case RESET_HABITUAL_OFFENDER_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
