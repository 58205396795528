import { connect } from "react-redux";
import { withRouter } from "react-router";
import viewRole from "./view";
import {
  fetchRollMasterList,
  fetchPermissionList,
  resetPermissionList,
  onFilterChange,
  deleteRoleMaster,
  resetDeleteStatus,
} from "../addRole/data/action";
import {
  toggleFilterWindow,
  resetFilterParams,
  setFilterParams,
  setValue,
} from "../../../../components/filterrole_form/data/action";
import { fetchUserTypeList, dialogueWindowStatus } from "./data/action";
import { push } from "connected-react-router";
import { snackbarActions } from "components/snackbar/data/action";
import { toggleViewWindow } from "./data/action";

function mapStateToProps(state) {
  return {
    permissionList: state.permissionList,
    userTypeList: state.userTypeList.userTypeList,
    showDialogue: state.showDialogue,
    viewWindowStatus: state.showDialogue.viewWindowStatus,
    deleteStatus: state.saveSuccess.deleteStatus,
    roleMasterList: state.roleMasterList,
    showFilterWindow: state.filter.showFilterWindow,
    // filterParams: state.filter.filterParams,
    val: state.filter.val,
    loading: state.progress.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    deleteRoleMaster: (id) => dispatch(deleteRoleMaster(id)),
    onFilterChange: (type, rolename, page, limit) =>
      dispatch(onFilterChange(type, rolename, page, limit)),
    fetchRollMasterList: (page, rowsPerPage) =>
      dispatch(fetchRollMasterList(page, rowsPerPage)),
    resetPermissionList: () => dispatch(resetPermissionList()),
    dialogueWindowStatus: () => dispatch(dialogueWindowStatus()),
    fetchUserTypeList: () => dispatch(fetchUserTypeList()),
    fetchPermissionList: (id) => dispatch(fetchPermissionList(id)),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
    toggleFilterWindow: () => dispatch(toggleFilterWindow()),
    toggleViewWindow: () => dispatch(toggleViewWindow()),
    resetFilterParams: () => dispatch(resetFilterParams()),
    setFilterParams: (filterParams) => dispatch(setFilterParams(filterParams)),
    navigateTo: (url) => dispatch(push(url)),
    setValue: () => dispatch(setValue()),
    resetDeleteStatus: () => dispatch(resetDeleteStatus()),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(viewRole)
);
