import { TOGGLE_APP_DRAWER, CHANGE_TO_APPBAR_PATH } from "./constants";
export const drawerToggle = () => {
  return (dispatch) => {
    dispatch(drawerToggle());
  };
  function drawerToggle() {
    return { type: TOGGLE_APP_DRAWER };
  }
};

export const changetoAppbarPath = (data) => {
  return (dispatch) => {
    dispatch(appBarPath(data));
  };
  function appBarPath(data) {
    return { type: CHANGE_TO_APPBAR_PATH, data };
  }
};
