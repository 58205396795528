import APIRequest from "../util/api/APIRequest";

class VehicleService {
  fetchRecord = recordID => {
    return APIRequest({
      url: `vehicle/${recordID}`,
      method: "GET"
    });
  };
  fetchRecentActivity = plate => {
    return APIRequest({
      url: `api/recentactivity/`,
      method: "GET",
      params: { plate }
    });
  };

  fetchRecentAlerts = plate => {
    return APIRequest({
      url: `api/recentalerts/`,
      method: "GET",
      params: { plate }
    });
  };
}
export default VehicleService;
