import dropdownTypes from "../../util/dropdown/dropdownTypes";

export default [
  {
    id: "vehicle-report",
    title: "Vehicle Report",
    generateUrl: "api/reports/vehicle/",
    exportUrl: "api/reports/printVehicle",
    generationFields1: [
      {
        name: "from_date",
        label: "From Date",
        type: "datepicker",
        rules: {
          required: true,
        },
      },
      {
        name: "to_date",
        label: "To Date",
        type: "datepickers",
        rules: {
          required: true,
        },
      },
      {
        name: "plate",
        label: "Vehicle Number",
        type: "textfield",
        rules: {
          required: false,
        },
      },
      {
        name: "zone",
        label: "Zone",
        type: "zone",
        rules: {
          required: false,
        },
      },
      {
        name: "district",
        label: "District",
        type: "district",
        rules: {
          required: false,
        },
      },
      {
        name: "field",
        label: "Field",
        type: "field",
        rules: {
          required: false,
        },
      },
    ],
    filterFields: [
      {
        name: "from_date",
        label: "From Date",
        type: "datepicker",
        rules: {
          required: true,
        },
      },
      {
        name: "to_date",
        label: "To Date",
        type: "datepickers",
        rules: {
          required: true,
        },
      },
      {
        name: "plate",
        label: "Vehicle Number",
        type: "textfield1",
        rules: {
          required: false,
        },
      },
      {
        name: "zone",
        label: "Zone",
        type: "zone",
        rules: {
          required: false,
        },
      },
      {
        name: "district",
        label: "District",
        type: "district",
        rules: {
          required: false,
        },
      },
      {
        name: "field",
        label: "Field",
        type: "field",
        rules: {
          required: false,
        },
      },
      // {
      //   name: "confidence",
      //   label: "Confidence",
      //   type: "textfield",
      //   placeholder: "Please enter a confidence between 1 and 100",

      //   rules: {
      //     required: true,
      //   },
      // },

      {
        name: "camera_id",
        label: "Camera",
        type: "camera",
        dropdown: dropdownTypes.CAMERA,
        dropdownData: "camera",
        rules: {
          required: true,
        },
      },
      {
        name: "lane",
        label: "Lane",
        type: "lane",
        dropdown: dropdownTypes.LANE,
        dropdownData: "lane",
        rules: {
          required: true,
        },
      },
      {
        name: "category",
        label: "Vehicle Category",
        type: "vehicle",
        dropdown: dropdownTypes.VEHICLE_CATEGORY,
        dropdownData: "vehicleCategories",
        rules: {
          required: true,
        },
      },
    ],
  },

  {
    id: "warning-report",
    title: "Review Report",
    generateUrl: "api/reports/warning/",
    exportUrl: "api/reports/printWarning",
    generationFields1: [
      {
        name: "from_date",
        label: "From Date",
        type: "datepicker",
        rules: {
          required: true,
        },
      },
      {
        name: "to_date",
        label: "To Date",
        type: "datepickers",
        rules: {
          required: true,
        },
      },
      {
        name: "warning_type",
        label: "Review Type",
        type: "warningtype",
        rules: {
          required: false,
        },
      },
      {
        name: "zone",
        label: "Zone",
        type: "zone",
        rules: {
          required: false,
        },
      },
      {
        name: "district",
        label: "District",
        type: "district",
        rules: {
          required: false,
        },
      },
      {
        name: "field",
        label: "Field",
        type: "field",
        rules: {
          required: false,
        },
      },
    ],
    filterFields: [
      {
        name: "from_date",
        label: "From Date",
        type: "datepicker",
        rules: {
          required: true,
        },
      },
      {
        name: "to_date",
        label: "To Date",
        type: "datepickers",
        rules: {
          required: true,
        },
      },
      {
        name: "warning_type",
        label: "Review Type",
        type: "warningtype",
        rules: {
          required: false,
        },
      },
      {
        name: "zone",
        label: "Zone",
        type: "zone",
        rules: {
          required: false,
        },
      },
      {
        name: "district",
        label: "District",
        type: "district",
        rules: {
          required: false,
        },
      },
      {
        name: "field",
        label: "Field",
        type: "field",
        rules: {
          required: false,
        },
      },
      // {
      //   name: "confidence",
      //   label: "Confidence",
      //   type: "textfield",
      //   placeholder: "Please enter a confidence between 1 and 100",

      //   rules: {
      //     required: true,
      //   },
      // },
      {
        name: "camera_id",
        label: "Camera",
        type: "camera",
        dropdown: dropdownTypes.CAMERA,
        dropdownData: "camera",
        rules: {
          required: true,
        },
      },
      {
        name: "lane",
        label: "Lane",
        type: "lane",
        dropdown: dropdownTypes.LANE,
        dropdownData: "lane",
        rules: {
          required: true,
        },
      },
      {
        name: "category",
        label: "Vehicle Category",
        type: "vehicle",
        dropdown: dropdownTypes.VEHICLE_CATEGORY,
        dropdownData: "vehicleCategories",
        rules: {
          required: true,
        },
      },
    ],
  },
  {
    id: "alert-report",
    title: "Alert Report",
    generateUrl: "api/reports/alert/",
    exportUrl: "api/reports/printAlert",

    generationFields1: [
      {
        name: "from_date",
        label: "From Date",
        type: "datepicker",
        rules: {
          required: true,
        },
      },
      {
        name: "to_date",
        label: "To Date",
        type: "datepickers",
        rules: {
          required: true,
        },
      },
      {
        name: "alert",
        label: "Alert Type",
        type: "alerttype",
        rules: {
          required: false,
        },
      },
      {
        name: "reportType",
        label: "Report Type",
        type: "reporttype",
        rules: {
          required: false,
        },
      },
      {
        name: "zone",
        label: "Zone",
        type: "zone",
        rules: {
          required: false,
        },
      },
      {
        name: "district",
        label: "District",
        type: "district",
        rules: {
          required: false,
        },
      },
      {
        name: "field",
        label: "Field",
        type: "field",
        rules: {
          required: false,
        },
      },
    ],
    filterFields: [
      {
        name: "from_date",
        label: "From Date",
        type: "datepicker",
        rules: {
          required: true,
        },
      },
      {
        name: "to_date",
        label: "To Date",
        type: "datepickers",
        rules: {
          required: true,
        },
      },
      {
        name: "alert",
        label: "Alert Type",
        type: "alerttype",
        rules: {
          required: false,
        },
      },
      {
        name: "reportType",
        label: "Report Type",
        type: "reporttype",
        rules: {
          required: false,
        },
      },
      {
        name: "zone",
        label: "Zone",
        type: "zone",
        rules: {
          required: false,
        },
      },
      {
        name: "district",
        label: "District",
        type: "district",
        rules: {
          required: false,
        },
      },
      {
        name: "field",
        label: "Field",
        type: "field",
        rules: {
          required: false,
        },
      },
      // {
      //   name: "confidence",
      //   label: "Confidence",
      //   type: "textfield",
      //   placeholder: "Please enter a confidence between 1 and 100",

      //   rules: {
      //     required: true,
      //   },
      // },
      {
        name: "camera_id",
        label: "Camera",
        type: "camera",
        dropdown: dropdownTypes.CAMERA,
        dropdownData: "camera",
        rules: {
          required: true,
        },
      },
      {
        name: "lane",
        label: "Lane",
        type: "lane",
        dropdown: dropdownTypes.LANE,
        dropdownData: "lane",
        rules: {
          required: true,
        },
      },
      {
        name: "category",
        label: "Vehicle Category",
        type: "vehicle",
        dropdown: dropdownTypes.VEHICLE_CATEGORY,
        dropdownData: "vehicleCategories",
        rules: {
          required: true,
        },
      },
      {
        name: "commodity_category",
        label: "Commodity Category",
        type: "commodity",
        dropdown: dropdownTypes.COMMODITY_CATEGORY,
        dropdownData: "commodityCategories",
        rules: {
          required: true,
        },
      },
    ],
  },

  {
    id: "eway-report",
    title: "Multiple Truck with Same E-Way bill Report",
    generateUrl: "api/reports/multiple-ewaybill-report/",
    exportUrl: "api/reports/multiple-ewaybill-report-print",
    generationFields: [
      {
        name: "from_date",
        label: "From Date",
        type: "datepicker",
        rules: {
          required: true,
        },
      },
      {
        name: "to_date",
        label: "To Date",
        type: "datepickers",
        rules: {
          required: true,
        },
      },
    ],
    filterFields: [
      // {
      //   name: "confidence",
      //   label: "Confidence",
      //   type: "textfield",
      //   placeholder: "Please enter a confidence between 1 and 100",
      //   rules: {
      //     required: true,
      //   },
      // },
      {
        name: "camera_id",
        label: "Camera",
        type: "camera",
        dropdown: dropdownTypes.CAMERA,
        dropdownData: "camera",
        rules: {
          required: true,
        },
      },
      {
        name: "lane",
        label: "Lane",
        type: "lane",
        dropdown: dropdownTypes.LANE,
        dropdownData: "lane",
        rules: {
          required: true,
        },
      },
      {
        name: "category",
        label: "Vehicle Category",
        type: "vehicle",
        dropdown: dropdownTypes.VEHICLE_CATEGORY,
        dropdownData: "vehicleCategories",
        rules: {
          required: true,
        },
      },
    ],
  },
  {
    id: "offender-report",
    title: "Habitual Offenders Report",
    generateUrl: "api/reports/habitual-offenders-report/",
    exportUrl: "/api/reports/habitual-offenders-report-print",

    generationFields: [
      {
        name: "from_date",
        label: "From Date",
        type: "datepicker",
        rules: {
          required: true,
        },
      },
      {
        name: "to_date",
        label: "To Date",
        type: "datepickers",
        rules: {
          required: true,
        },
      },
    ],
    filterFields: [
      // {
      //   name: "confidence",
      //   label: "Confidence",
      //   type: "textfield",
      //   placeholder: "Please enter a confidence between 1 and 100",
      //   rules: {
      //     required: true,
      //   },
      // },
      {
        name: "camera_id",
        label: "Camera",
        type: "camera",
        dropdown: dropdownTypes.CAMERA,
        dropdownData: "camera",
        rules: {
          required: true,
        },
      },
      {
        name: "lane",
        label: "Lane",
        type: "lane",
        dropdown: dropdownTypes.LANE,
        dropdownData: "lane",
        rules: {
          required: true,
        },
      },
      {
        name: "category",
        label: "Vehicle Category",
        type: "vehicle",
        dropdown: dropdownTypes.VEHICLE_CATEGORY,
        dropdownData: "vehicleCategories",
        rules: {
          required: true,
        },
      },
    ],
  },
  {
    id: "action-report",
    title: "Action Report",
    generateUrl: "/api/reports/action-report/",
    exportUrl: "api/reports/action-report-print",
    size: 3,
    generationFields2: [
      {
        name: "from_date",
        label: "From Date",
        type: "datepicker",
        rules: {
          required: true,
        },
      },
      {
        name: "to_date",
        label: "To Date",
        type: "datepickers",
        rules: {
          required: true,
        },
      },
      {
        name: "alert",
        label: "Alert Type",
        type: "alerttype",
        rules: {
          required: false,
        },
      },
      {
        name: "zone",
        label: "Zone",
        type: "zone",
        rules: {
          required: false,
        },
      },
      {
        name: "district",
        label: "District",
        type: "district",
        rules: {
          required: false,
        },
      },
      {
        name: "field",
        label: "Field",
        type: "field",
        rules: {
          required: false,
        },
      },

      {
        name: "office",
        label: "Office",
        type: "office",
        rules: {
          required: false,
        },
      },
      {
        name: "squad",
        label: "Squad",
        type: "squad",
        rules: {
          required: false,
        },
      },
      {
        name: "officer",
        label: "Officer",
        type: "officer",
        rules: {
          required: false,
        },
      },
    ],
    filterFields: [
      {
        name: "from_date",
        label: "From Date",
        type: "datepicker",
        rules: {
          required: true,
        },
      },
      {
        name: "to_date",
        label: "To Date",
        type: "datepickers",
        rules: {
          required: true,
        },
      },
      {
        name: "alert",
        label: "Alert Type",
        type: "alerttype",
        rules: {
          required: false,
        },
      },
      {
        name: "zone",
        label: "Zone",
        type: "zone",
        rules: {
          required: false,
        },
      },
      {
        name: "district",
        label: "District",
        type: "district",
        rules: {
          required: false,
        },
      },
      {
        name: "field",
        label: "Field",
        type: "field",
        rules: {
          required: false,
        },
      },
      {
        name: "camera_id",
        label: "Camera",
        type: "camera",
        dropdown: dropdownTypes.CAMERA,
        dropdownData: "camera",
        rules: {
          required: true,
        },
      },
      {
        name: "office",
        label: "Office",
        type: "office",
        rules: {
          required: false,
        },
      },
      {
        name: "squad",
        label: "Squad",
        type: "squad",
        rules: {
          required: false,
        },
      },
      {
        name: "officer",
        label: "Officer",
        type: "officer",
        rules: {
          required: false,
        },
      },

      // {
      //   name: "confidence",
      //   label: "Confidence",
      //   type: "textfield",
      //   placeholder: "Please enter a confidence between 1 and 100",
      //   rules: {
      //     required: true,
      //   },
      // },

      {
        name: "lane",
        label: "Lane",
        type: "lane",
        dropdown: dropdownTypes.LANE,
        dropdownData: "lane",
        rules: {
          required: true,
        },
      },
      {
        name: "category",
        label: "Vehicle Category",
        type: "vehicle",
        dropdown: dropdownTypes.VEHICLE_CATEGORY,
        dropdownData: "vehicleCategories",
        rules: {
          required: true,
        },
      },

      {
        name: "eway_bill_status",
        label: "Eway Bill Status",
        type: "ewaybill status",
        // dropdown: dropdownTypes.COMMODITY_CATEGORY,
        // dropdownData: "commodityCategories",
        rules: {
          required: true,
        },
      },
      {
        name: "action_type",
        label: "Action Type",
        type: "action type",
        // dropdown: dropdownTypes.COMMODITY_CATEGORY,
        // dropdownData: "commodityCategories",
        rules: {
          required: true,
        },
      },
      {
        name: "commodity_category",
        label: "Commodity Category",
        type: "commodity",
        dropdown: dropdownTypes.COMMODITY_CATEGORY,
        dropdownData: "commodityCategories",
        rules: {
          required: true,
        },
      },
    ],
  },
];
