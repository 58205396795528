import React, { Fragment } from "react";
import {
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Divider,
  FormHelperText,
  IconButton,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ReactComponent as BackIcon } from "../../../../assets/SvgIcons/back.svg";
import { ReactComponent as DateTime } from "../../../../assets/Visual Assets/date.svg";
import { ReactComponent as CameraIcon } from "../../../../assets/Visual Assets/camera.svg";
import classNames from "classnames";
import propTypes from "prop-types";
import validator from "../../../../services/validator/validator";
import { find } from "lodash";
import StepConnector from "@material-ui/core/StepConnector";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
// import Recaptcha from "react-google-invisible-recaptcha";
// import CaptchaSiteKeyMasters from "../../../../services/siteKeyMasters";
import { ReactComponent as DownArrow } from "../../../../assets/SvgIcons/dropdown.svg";
import { ReactComponent as Visibility } from "../../../../assets/SvgIcons/eye.svg";
import { ReactComponent as VisibilityOff } from "../../../../assets/SvgIcons/eye_hide.svg";
import { ReactComponent as PersonIcon } from "../../../../assets/SvgIcons/basic_info_white.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/SvgIcons/select_features_white.svg";
import { ReactComponent as CameraAltIcon } from "../../../../assets/SvgIcons/camera_access_white.svg";
import StyledRadio from "../../../../components/filteralert_form/styledRadio";
import StyledCheckbox from "./styledCheckbox";
import "./style.css";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    marginTop: "43px",
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "17px",
    marginBottom: "15px",
  },
  iconButton: {
    paddingLeft: "0px",
  },
  divde: {
    border: "1px solid #E7EBF4",
    opacity: 1,
    marginLeft: "-8px",
  },
});

let EnhancedTableToolbar = (props) => {
  const { classes } = props;
  return (
    <>
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          <IconButton
            onClick={(e) => props.onBackButtonClick(e)}
            classes={{ root: classes.iconButton }}
          >
            <BackIcon />
          </IconButton>
          {props.header}
        </Typography>
      </Toolbar>
      <Divider className={classes.divde} />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: propTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  outerGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "15px",
  },
  root: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    borderRadius: theme.border.radius,
    opacity: 1,
    marginLeft: "21px",
    marginRight: "17px",
    paddingLeft: "8px",
    paddingBottom: "32px",
    // height: "354px"
  },
  root1: {
    width: "100%",
  },

  stepperDiv: {
    marginTop: "0px",
    width: "100%",
  },
  stepperRoot: {
    paddingBottom: "22px",
    paddingTop: "16px",
    paddingLeft: "197px",
    paddingRight: "202px",
  },
  textField: {
    width: "95%",
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "45px",
  },
  alternativeLabel: {},
  labelContainer: {
    "& $alternativeLabel": {
      font: "normal normal normal 14px Karla",
      letterSpacing: "0px",
      opacity: 1,
      marginTop: "6px",
    },
  },
  textFielddes: {
    marginLeft: "10px",
    marginRight: "13px",
  },
  innerGrid: {
    paddingBottom: "6px",
    paddingRight: "4px",
  },
  innerGridEdit: {
    paddingBottom: "16px",
    paddingRight: "4px",
  },
  button: {
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    color: "white",
    opacity: 1,
    textTransform: "none",
    width: "121px",
    height: "42px",
    textAlign: "center",
    background: "#1BB99A 0% 0% no-repeat padding-box",
  },
  clearButton: {
    height: "42px",
    textTransform: "none",
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    width: "121px",
    textAlign: "center",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
  },
  button1: {
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    opacity: 1,
    textTransform: "none",
    textAlign: "center",
    width: "121px",
    height: "42px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
  },
  clearButton1: {
    height: "42px",
    textTransform: "none",
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.colors.BlueButton,
    textAlign: "center",
    width: "121px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
  },
  btns: {
    paddingTop: "131px",
    // paddingRight: "25%",
    paddingBottom: "2px",
  },
  btns1: {
    paddingTop: "131px",
    paddingRight: "25%",
    paddingBottom: "2px",
  },
  btnsEdit: {
    paddingRight: "24%",
    marginTop: "-17px",
  },
  btnGrid: {
    padding: 0,
    paddingLeft: "12px",
  },
  btnGrids: {
    padding: 0,
    paddingLeft: "12px",
  },
  btnGrid1: {
    padding: 0,
    paddingLeft: "20px",
  },
  err: {
    paddingLeft: "1%",
  },
  dense: {
    marginTop: 16,
  },
  vldclr: {
    color: "#FF0000",
  },
  typo: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingRight: "14px",
  },
  formLabel: {
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
  },
  radioButtons: {
    marginTop: "5%",
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  textNoOptions: {
    letterSpacing: "0px",
    opacity: 1,
    fontSize: "18px",
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  paper: {
    height: "100px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  alignment: {
    flexDirection: "row",
  },

  radbtns: {
    paddingLeft: "5%",
  },

  formitems: {
    paddingTop: "6%",
  },
  typo1: {
    marginTop: "4%",
    paddingTop: "5%",
    //textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingRight: "14px",
  },

  btns3: {
    // paddingRight: "25%",
    paddingTop: "22px",
    paddingBottom: "2px",
  },
  visibilityColor: {
    color: theme.visibilityIcon.color,
  },
  selectedCard: {
    //height: "84px",
    width: "172px",
    //borderColor: theme.colors.blueColor,
    borderRadius: "10px 10px 10px 10px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #00000012",
    border: "2px solid #1BB99A",
    opacity: 1,
    marginLeft: "23px",
    marginRight: "-8px",
    marginBottom: "15px",
    cursor: "pointer",
  },
  assignedCard: {
    //height: "84px",
    width: "172px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #00000012",
    border: "2px solid #1BB99A",
    opacity: 1,
    borderRadius: "10px 10px 10px 10px",
    //borderColor: theme.palette.secondary.main,
    marginLeft: "23px",
    marginRight: "-8px",
    marginBottom: "15px",
    cursor: "pointer",
  },
  unassignedCard: {
    //height: "84px",
    width: "172px",
    border: "none",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #00000012",
    opacity: 1,
    borderRadius: "10px 10px 10px 10px",
    // backgroundColor: theme.CameraCard.backgroundColor,
    marginLeft: "23px",
    marginRight: "-8px",
    marginBottom: "15px",
    cursor: "pointer",
  },
  cardGrid: {
    marginTop: "-8px",
  },

  locationName: {
    textAlign: "left",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingLeft: "9px",
    //marginTop: "-23px",
  },
  cameraIcon: {
    paddingLeft: "9px",
    paddingBottom: "3px",
  },
  cameraName: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    paddingBottom: "3px",
  },
  title: {
    color: theme.CameraCard.titleColor,
    textAlign: "left",
    paddingLeft: "23px",
    paddingTop: "14px",
    font: "normal normal bold 16px Karla",
    letterSpacing: "0px",
    opacity: 1,
  },
  formHelperText: {
    color: theme.colors.redColor,
  },
  gridBorder: {
    borderRadius: "15px",
    border: "none",
    background: "#F1F4FB 0% 0% no-repeat padding-box",
    opacity: 1,
    maxHeight: "201px",
    minHeight: "89px",
    overflowY: "auto",
    "@media screen and (min-width: 1900px)": {
      maxHeight: "268px",
    },
    /* devices (1280px) */
    "@media screen and (width : 1280px)": {
      maxHeight: "218px",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      maxHeight: "180px",
    },
  },
  popIcon: {
    marginTop: "6px",
    marginRight: "6px",
  },
  popIconOpen: {
    marginTop: "4px",
    marginRight: "6px",
  },
  dateIcon: {
    marginTop: "0px",
    marginRight: "-8px",
  },
  NoItem: {
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    paddingLeft: "27px",
    paddingBottom: "25px",
  },
  overallGrid: {
    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "80vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "81vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "86vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
    // /*devices 1366px and up) */
    // "@media screen and (min-width: 1366px)": {
    //   minHeight: "12vh",
    //   maxHeight: "60vh",
    //   overflowX: "hidden",
    //   overflowY: "auto",
    // },
    // /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "80vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 18,
    left: "calc(-50% + 20px)",
    right: "calc(50% + 20px)",
  },
  active: {
    "& $line": {
      borderTop: "2px solid #1BB99A",
    },
  },
  completed: {
    "& $line": {
      borderTop: "2px solid #1BB99A",
    },
  },
  line: {
    height: 0,
    border: 0,
    borderTop: "2px dashed #D9DEE9",
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  roots: {
    backgroundColor: "#ccc",
    // zIndex: 1,
    color: "white",
    width: "41px",
    height: "41px",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    background: "#1BB99A 0% 0% no-repeat padding-box",
  },
  completed: {
    background: "#1BB99A 0% 0% no-repeat padding-box",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <PersonIcon />,
    2: <InfoIcon />,
    3: <CameraAltIcon />,
  };

  return (
    <div
      className={clsx(classes.roots, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: propTypes.bool,
  completed: propTypes.bool,
  icon: propTypes.node,
};

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.onResolved = this.onResolved.bind(this);

    this.state = {
      isVerified: false,
      editCameras: [],
      validateData: {},
      fetchedUserDetails: {},
      data: {
        captcha: "",

        name: "",
        pen: "",
        designation: "",
        office: "",
        email: "",
        contact_no: "",
        role: "",
        username: "",
        password: "",
        confirmPassword: "",
        userType: "",
        field: "",
        zone: "",
        district: "",
        status: "true",
        effective_from: null,
        effective_to: null,
        camera_access: [],
      },
      selectedId: 0,
      selected: 0,
      assignedId: "",
      checked: false,
      checked_1: false,
      assignedId_1: "",
      showPassword: false,
      showConfirmPassword: false,
      errors: {
        name: "",
        pen: "",
        designation: "",
        office: "",
        email: "",
        contact_no: "",
        role: "",
        username: "",
        password: "",
        confirmPassword: "",
        userType: "",
        field: "",
        zone: "",
        district: "",
        effective_from: null,
        effective_to: null,
      },
      rules: {
        name: {
          required: true,
          minLength: 4,
          maxLength: 30,
          alphanumericwithspecialchar: true,
        },
        pen: {
          required: true,
          numeric: true,
          minLength: 5,
          maxLength: 7,
        },
        designation: {
          required: true,
        },
        office: {
          required: true,
        },
        email: {
          required: false,
          email: true,
          minLength: 8,
          maxLength: 40,
        },
        contact_no: {
          required: true,
          number: true,
          minLength: 10,
          maxLength: 16,
          maxNumberLength: 13,
          isNumberWithplus: true,
          isNumberWithoutplus: true,
          alphanumericWithPlus: true,
        },
        role: { required: true },

        username: {
          required: true,
          minLength: 8,
          maxLength: 20,
          noSpace: true,
          alphanumericwithspecialchar: true,
          isExist: true,
        },
        password: {
          required: true,
          minLength: 8,
          maxLength: 20,
          password: true,
          noSpace: true,
          isNotSameUsername: "username",
          isNotSameFullname: "name",
          isNotSameemail: "email",
        },
        confirmPassword: {
          required: true,
          isSame: "password",
        },
        userType: {
          required: true,
        },
        field: {
          required: true,
        },
        zone: {
          required: true,
        },
        district: {
          required: true,
        },
        effective_from: {
          required: true,
        },
        effective_to: {
          required: true,
        },
      },
      activeStep: 0,
      steps: ["Personal Details", "User Info", "Camera Access"],
      designationList: [],
      officeList: [],
      Role: [],
      UserType: [],
      Field: [],
      Zone: [],
      District: [],
      Cameras: [],
      DefaultCameras: [],
      OtherCameras: [],
      type: "zone",
      zone_enabled: "",
      district_enabled: "",
      field_enabled: "",
      state_enabled: "",
      office_enabled: "",
    };
  }
  componentDidMount() {
    this.props.fetchUserType();
    this.props.fetchZone();
    this.props.fetchDesignationList();

    if (this.props.match.params.user_id) {
      this.props.fetchUserDeatils(this.props.match.params.user_id);
      this.props.fetchEditCameraAccess(this.props.match.params.user_id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.match.params.user_id &&
      this.props.fetchedUserDetails &&
      this.props.fetchedUserDetails !== prevProps.fetchUserDeatils &&
      this.props.fetchedUserDetails !== this.state.fetchedUserDetails
    ) {
      const { fetchedUserDetails } = this.props;
      this.setState(
        {
          ...this.state,
          fetchedUserDetails: fetchedUserDetails,
          data: {
            ...this.state.data,
            name: fetchedUserDetails.name,
            pen: fetchedUserDetails.pen,
            designation: fetchedUserDetails.designation,
            office: fetchedUserDetails.office,
            email: fetchedUserDetails.email,
            contact_no: fetchedUserDetails.contact_no,
            role: fetchedUserDetails.role,
            username: fetchedUserDetails.login
              ? fetchedUserDetails.login.username
              : "",
            userType: fetchedUserDetails.role
              ? fetchedUserDetails.role.userType
              : "",
            field: fetchedUserDetails.location_master,
            zone: fetchedUserDetails.zone_master,
            district: fetchedUserDetails.district_master,
            status:
              fetchedUserDetails.status != undefined
                ? fetchedUserDetails.status.toString()
                : "",
            effective_from: fetchedUserDetails.effective_from,
            effective_to: fetchedUserDetails.effective_to,
          },
        },
        () => {
          if (this.state.data.userType.user_type == "State Level") {
            this.setState(
              {
                ...this.state,
                state_enabled: "true",
                zone_enabled: "",
                district_enabled: "",
                field_enabled: "",
                office_enabled: "",
              },
              () => {
                const { fetchedUserDetails } = this.props;
                this.props.fetchRole(
                  fetchedUserDetails.role
                    ? fetchedUserDetails.role.userType.id
                    : null
                );
                this.props.fetchDistrict(
                  fetchedUserDetails.zone_master
                    ? fetchedUserDetails.zone_master.zone_id
                    : null
                );
                this.props.fetchField(
                  fetchedUserDetails.district_master
                    ? fetchedUserDetails.district_master.district_id
                    : null
                );
                this.props.fetchOfficeList(
                  fetchedUserDetails.location_master
                    ? fetchedUserDetails.location_master.location_id
                    : null
                );
              }
            );
          }
          if (
            this.state.data.userType.user_type == "Zone Level" ||
            this.state.data.userType.user_type == "District Level" ||
            this.state.data.userType.user_type == "Field Level"
          ) {
            this.setState(
              {
                ...this.state,
                zone_enabled: "true",
                state_enabled: "",
                district_enabled: "",
                field_enabled: "",
                office_enabled: "",
              },
              () => {
                const { fetchedUserDetails } = this.props;
                this.props.fetchRole(
                  fetchedUserDetails.role
                    ? fetchedUserDetails.role.userType.id
                    : null
                );
                this.props.fetchDistrict(
                  fetchedUserDetails.zone_master
                    ? fetchedUserDetails.zone_master.zone_id
                    : null
                );
                this.props.fetchField(
                  fetchedUserDetails.district_master
                    ? fetchedUserDetails.district_master.district_id
                    : null
                );
                this.props.fetchOfficeList(
                  fetchedUserDetails.location_master
                    ? fetchedUserDetails.location_master.location_id
                    : null
                );
              }
            );
          }
          if (
            this.state.data.userType.user_type == "District Level" ||
            this.state.data.userType.user_type == "Field Level"
          ) {
            this.setState(
              {
                ...this.state,
                zone_enabled: "",
                state_enabled: "",
                district_enabled: "true",
                field_enabled: "",
                office_enabled: "",
              },
              () => {
                const { fetchedUserDetails } = this.props;
                this.props.fetchRole(
                  fetchedUserDetails.role
                    ? fetchedUserDetails.role.userType.id
                    : null
                );
                this.props.fetchDistrict(
                  fetchedUserDetails.zone_master
                    ? fetchedUserDetails.zone_master.zone_id
                    : null
                );
                this.props.fetchField(
                  fetchedUserDetails.district_master
                    ? fetchedUserDetails.district_master.district_id
                    : null
                );
                this.props.fetchOfficeList(
                  fetchedUserDetails.location_master
                    ? fetchedUserDetails.location_master.location_id
                    : null
                );
              }
            );
          }
          if (this.state.data.userType.user_type == "Field Level") {
            this.setState(
              {
                ...this.state,
                zone_enabled: "",
                district_enabled: "",
                state_enabled: "",
                field_enabled: "true",
                office_enabled: "true",
              },
              () => {
                const { fetchedUserDetails } = this.props;
                this.props.fetchRole(
                  fetchedUserDetails.role
                    ? fetchedUserDetails.role.userType.id
                    : null
                );
                this.props.fetchDistrict(
                  fetchedUserDetails.zone_master
                    ? fetchedUserDetails.zone_master.zone_id
                    : null
                );
                this.props.fetchField(
                  fetchedUserDetails.district_master
                    ? fetchedUserDetails.district_master.district_id
                    : null
                );
                this.props.fetchOfficeList(
                  fetchedUserDetails.location_master
                    ? fetchedUserDetails.location_master.location_id
                    : null
                );
              }
            );
          }
        }
      );
    }
    if (
      this.props.match.params.user_id &&
      this.props !== prevProps &&
      this.props.editCameras !== prevProps.editCameras &&
      this.props.editCameras !== this.state.editCameras
    ) {
      let matched = [];
      let defaultCamera = [];
      let other = [];
      this.props.editCameras &&
        this.props.editCameras.map((item, index) => {
          if (item.status == true) {
            matched.push(item.camera_id);
            defaultCamera.push(item);
          }
          if (item.status == false) {
            other.push(item);
          }
        });
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          camera_access: matched,
        },
        Cameras: this.props.editCameras,
        DefaultCameras: defaultCamera,
        OtherCameras: other,
      });
    }
    if (this.state.data.username != prevState.data.username) {
      this.props.checkUsername(this.state.data.username);
    }

    if (prevProps.fetchedOfficeList != this.props.fetchedOfficeList) {
      this.setState({
        ...this.state,
        officeList: this.props.fetchedOfficeList,
      });
    }
    if (prevProps.fetchedDesignationList != this.props.fetchedDesignationList) {
      this.setState({
        ...this.state,
        designationList: this.props.fetchedDesignationList,
      });
    }

    if (prevProps.fetcheduserType != this.props.fetcheduserType) {
      this.setState({
        ...this.state,
        UserType: this.props.fetcheduserType,
      });
    }
    if (prevProps.role != this.props.role) {
      this.setState({
        ...this.state,
        Role: this.props.role,
      });
    }
    if (prevProps.fetchedzone != this.props.fetchedzone) {
      this.setState({
        ...this.state,
        Zone: this.props.fetchedzone,
      });
    }
    if (prevProps.district != this.props.district) {
      this.setState({
        ...this.state,
        District: this.props.district,
      });
    }
    if (prevProps.field != this.props.field) {
      this.setState({
        ...this.state,
        Field: this.props.field,
      });
    }
    if (prevProps.cameras != this.props.cameras) {
      let matched = [];
      let defaultCamera = [];
      let other = [];
      this.props.cameras &&
        this.props.cameras.map((item, index) => {
          if (item.status == true) {
            matched.push(item.camera_id);
            defaultCamera.push(item);
          }
          if (item.status == false) {
            other.push(item);
          }
        });
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          camera_access: matched,
        },
        Cameras: this.props.cameras,
        DefaultCameras: defaultCamera,
        OtherCameras: other,
      });
    }

    if (
      this.props.saveSuccess &&
      this.props.statusmessage !== prevProps.statusmessage &&
      !this.props.error &&
      this.props.statusmessage !== "failed"
    ) {
      setTimeout(() => {
        this.props.showSnackbar(this.props.message, "success");
      }, 1000);
      this.props.resetUserDetail();
      this.props.navigateTo("/master/user-master");
    }

    if (
      this.props.saveSuccess &&
      !this.props.error &&
      this.props.saveStaus !== prevProps.saveStaus &&
      this.props.statusmessage === "failed"
    ) {
      this.props.showSnackbar(this.props.message, "error");
    }
    if (this.props.error && this.props.error !== this.state.alert) {
      this.props.showSnackbar(this.props.message, "error");
    }
  }

  componentWillUnmount() {
    this.props.resetUserDetail();
    this.setState({
      ...this.state,
      fetchedUserDetails: {},
    });
  }
  onBackButtonClick = () => {
    this.props.resetUserDetail();
    this.setState({
      ...this.state,
      fetchedUserDetails: {},
    });
    this.props.navigateTo("/master/user-master");
  };
  onResolved = () => {
    // this.setState({
    //   captcha: this.recaptcha.getResponse(),
    // });
    if (this.state.data.userType.user_type === "Zone Level") {
      let params = {
        // captcha: this.state.captcha,

        name: this.state.data.name,
        email: this.state.data.email,
        username: this.state.data.username,
        password: this.state.data.password,
        role_id: this.state.data.role ? this.state.data.role.role_id : "",
        pen: this.state.data.pen,
        contact_no: this.state.data.contact_no,
        status: this.state.data.status,
        field_id: null,
        district_id: null,
        zone_id: this.state.data.zone ? this.state.data.zone.zone_id : "",
        designation_id: this.state.data.designation
          ? this.state.data.designation.designationId
          : "",

        office_id: null,
        camera: this.state.data.camera_access,
        effective_from: this.state.data.effective_from,
        effective_to: this.state.data.effective_to,
      };

      let editparams = {
        // captcha: this.state.captcha,

        user_id: this.props.match.params.user_id,
        name: this.state.data.name,
        email: this.state.data.email,
        username: this.state.data.username,
        password:
          this.state.data.password !== "" ? this.state.data.password : null,
        role_id: this.state.data.role ? this.state.data.role.role_id : "",
        pen: this.state.data.pen,
        contact_no: this.state.data.contact_no,
        status: this.state.data.status,
        field_id: null,
        district_id: null,
        zone_id: this.state.data.zone ? this.state.data.zone.zone_id : "",
        designation_id: this.state.data.designation
          ? this.state.data.designation.designationId
          : "",

        office_id: null,
        camera: this.state.data.camera_access,
        effective_from: this.state.data.effective_from,
        effective_to: this.state.data.effective_to,
      };
      if (this.props.match.params.user_id) {
        this.props.editUser(editparams);
      } else {
        this.props.addUser(params);
      }
    } else if (this.state.data.userType.user_type === "State Level") {
      let params = {
        // captcha: this.state.captcha,

        name: this.state.data.name,
        email: this.state.data.email,
        username: this.state.data.username,
        password: this.state.data.password,
        role_id: this.state.data.role ? this.state.data.role.role_id : "",
        pen: this.state.data.pen,
        contact_no: this.state.data.contact_no,
        status: this.state.data.status,
        field_id: null,
        district_id: null,
        zone_id: null,
        designation_id: this.state.data.designation
          ? this.state.data.designation.designationId
          : "",

        office_id: null,
        camera: this.state.data.camera_access,
        effective_from: this.state.data.effective_from,
        effective_to: this.state.data.effective_to,
      };
      let editparams = {
        // captcha: this.state.captcha,

        user_id: this.props.match.params.user_id,
        name: this.state.data.name,
        email: this.state.data.email,
        username: this.state.data.username,
        password:
          this.state.data.password !== "" ? this.state.data.password : null,
        role_id: this.state.data.role ? this.state.data.role.role_id : "",
        pen: this.state.data.pen,
        contact_no: this.state.data.contact_no,
        status: this.state.data.status,
        field_id: null,
        district_id: null,
        zone_id: null,
        designation_id: this.state.data.designation
          ? this.state.data.designation.designationId
          : "",

        office_id: null,
        camera: this.state.data.camera_access,
        effective_from: this.state.data.effective_from,
        effective_to: this.state.data.effective_to,
      };
      if (this.props.match.params.user_id) {
        this.props.editUser(editparams);
      } else {
        this.props.addUser(params);
      }
    } else if (this.state.data.userType.user_type === "Field Level") {
      let params = {
        // captcha: this.state.captcha,

        name: this.state.data.name,
        email: this.state.data.email,
        username: this.state.data.username,
        password: this.state.data.password,
        role_id: this.state.data.role ? this.state.data.role.role_id : "",
        pen: this.state.data.pen,
        contact_no: this.state.data.contact_no,
        status: this.state.data.status,
        field_id: this.state.data.field
          ? this.state.data.field.location_id
          : "",
        district_id: this.state.data.district
          ? this.state.data.district.district_id
          : "",
        zone_id: this.state.data.zone ? this.state.data.zone.zone_id : "",
        designation_id: this.state.data.designation
          ? this.state.data.designation.designationId
          : "",
        office_id: this.state.data.office
          ? this.state.data.office.officeId
          : "",
        camera: this.state.data.camera_access,
        effective_from: this.state.data.effective_from,
        effective_to: this.state.data.effective_to,
      };
      let editparams = {
        // captcha: this.state.captcha,

        user_id: this.props.match.params.user_id,
        name: this.state.data.name,
        email: this.state.data.email,
        username: this.state.data.username,
        password:
          this.state.data.password !== "" ? this.state.data.password : null,
        role_id: this.state.data.role ? this.state.data.role.role_id : "",
        pen: this.state.data.pen,
        contact_no: this.state.data.contact_no,
        status: this.state.data.status,
        field_id: this.state.data.field
          ? this.state.data.field.location_id
          : "",
        district_id: this.state.data.district
          ? this.state.data.district.district_id
          : "",
        zone_id: this.state.data.zone ? this.state.data.zone.zone_id : "",
        designation_id: this.state.data.designation
          ? this.state.data.designation.designationId
          : "",
        office_id: this.state.data.office
          ? this.state.data.office.officeId
          : "",
        camera: this.state.data.camera_access,
        effective_from: this.state.data.effective_from,
        effective_to: this.state.data.effective_to,
      };
      if (this.props.match.params.user_id) {
        this.props.editUser(editparams);
      } else {
        this.props.addUser(params);
      }
    } else if (this.state.data.userType.user_type === "District Level") {
      let params = {
        // captcha: this.state.captcha,

        name: this.state.data.name,
        email: this.state.data.email,
        username: this.state.data.username,
        password: this.state.data.password,
        role_id: this.state.data.role ? this.state.data.role.role_id : "",
        pen: this.state.data.pen,
        contact_no: this.state.data.contact_no,
        status: this.state.data.status,
        field_id: null,
        district_id: this.state.data.district
          ? this.state.data.district.district_id
          : "",
        zone_id: this.state.data.zone ? this.state.data.zone.zone_id : "",
        designation_id: this.state.data.designation
          ? this.state.data.designation.designationId
          : "",

        office_id: null,
        camera: this.state.data.camera_access,
        effective_from: this.state.data.effective_from,
        effective_to: this.state.data.effective_to,
      };
      let editparams = {
        // captcha: this.state.captcha,

        user_id: this.props.match.params.user_id,
        name: this.state.data.name,
        email: this.state.data.email,
        username: this.state.data.username,
        password:
          this.state.data.password !== "" ? this.state.data.password : null,
        role_id: this.state.data.role ? this.state.data.role.role_id : "",
        pen: this.state.data.pen,
        contact_no: this.state.data.contact_no,
        status: this.state.data.status,
        field_id: null,
        district_id: this.state.data.district
          ? this.state.data.district.district_id
          : "",
        zone_id: this.state.data.zone ? this.state.data.zone.zone_id : "",
        designation_id: this.state.data.designation
          ? this.state.data.designation.designationId
          : "",

        office_id: null,
        camera: this.state.data.camera_access,
        effective_from: this.state.data.effective_from,
        effective_to: this.state.data.effective_to,
      };
      if (this.props.match.params.user_id) {
        this.props.editUser(editparams);
      } else {
        this.props.addUser(params);
      }
    }
  };
  performAction = (e) => {
    if (this.state.data.camera_access.length != 0) {
      // this.recaptcha.execute();
      this.onResolved();
    } else {
      this.props.showSnackbar("Select any Camera", "error");
    }
  };

  getValidateData = (event) => {
    const value = this.state.data.password;
    const isPasswordEmpty =
      isPasswordEmpty === "" ||
      String(value).trim() === "" ||
      value === undefined ||
      value === null ||
      value === false;

    if (
      this.props.match.params.stepID === "0" &&
      this.props.match.params.user_id === undefined
    ) {
      const validateData = {
        name: this.state.data.name,
        pen: this.state.data.pen,
        designation: this.state.data.designation,

        email: this.state.data.email,
        contact_no: this.state.data.contact_no,
        username: this.state.data.username,
        password: this.state.data.password,
        confirmPassword: this.state.data.confirmPassword,
      };

      return this.validate(this.state.rules, validateData);
    } else if (
      this.props.match.params.stepID === "0" &&
      this.props.match.params.user_id &&
      isPasswordEmpty == true
    ) {
      const validateData = {
        name: this.state.data.name,
        pen: this.state.data.pen,
        designation: this.state.data.designation,

        email: this.state.data.email,
        contact_no: this.state.data.contact_no,
      };

      return this.validate(this.state.rules, validateData);
    } else if (
      this.props.match.params.stepID === "0" &&
      this.props.match.params.user_id &&
      isPasswordEmpty == false
    ) {
      const validateData = {
        name: this.state.data.name,
        pen: this.state.data.pen,
        designation: this.state.data.designation,

        email: this.state.data.email,
        contact_no: this.state.data.contact_no,

        password: this.state.data.password,
        confirmPassword: this.state.data.confirmPassword,
      };

      return this.validate(this.state.rules, validateData);
    } else if (
      this.props.match.params.stepID === "1" &&
      this.state.data.userType === ""
    ) {
      const validateData = {
        userType: this.state.data.userType,
        role: this.state.data.role,
        zone: this.state.data.zone,
        district: this.state.data.district,
        field: this.state.data.field,
        office: this.state.data.office,
        effective_from: this.state.data.effective_from,
        effective_to: this.state.data.effective_to,
      };
      if (this.validate(this.state.rules, validateData)) {
        if (
          this.validateDate(
            this.state.data.effective_from,
            this.state.data.effective_to
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (
      this.props.match.params.stepID === "1" &&
      this.state.data.userType.user_type === "State Level"
    ) {
      const validateData = {
        userType: this.state.data.userType,
        role: this.state.data.role,

        effective_from: this.state.data.effective_from,
        effective_to: this.state.data.effective_to,
      };
      if (this.validate(this.state.rules, validateData)) {
        if (
          this.validateDate(
            this.state.data.effective_from,
            this.state.data.effective_to
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (
      this.props.match.params.stepID === "1" &&
      this.state.data.userType.user_type === "Zone Level"
    ) {
      const validateData = {
        userType: this.state.data.userType,
        role: this.state.data.role,
        zone: this.state.data.zone,

        effective_from: this.state.data.effective_from,
        effective_to: this.state.data.effective_to,
      };
      if (this.validate(this.state.rules, validateData)) {
        if (
          this.validateDate(
            this.state.data.effective_from,
            this.state.data.effective_to
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (
      this.props.match.params.stepID === "1" &&
      this.state.data.userType.user_type === "District Level"
    ) {
      const validateData = {
        userType: this.state.data.userType,
        role: this.state.data.role,
        zone: this.state.data.zone,
        district: this.state.data.district,

        effective_from: this.state.data.effective_from,
        effective_to: this.state.data.effective_to,
      };
      if (this.validate(this.state.rules, validateData)) {
        if (
          this.validateDate(
            this.state.data.effective_from,
            this.state.data.effective_to
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (
      this.props.match.params.stepID === "1" &&
      this.state.data.userType.user_type === "Field Level"
    ) {
      const validateData = {
        userType: this.state.data.userType,
        role: this.state.data.role,
        zone: this.state.data.zone,
        district: this.state.data.district,
        field: this.state.data.field,
        office: this.state.data.office,
        effective_from: this.state.data.effective_from,
        effective_to: this.state.data.effective_to,
      };
      if (this.validate(this.state.rules, validateData)) {
        if (
          this.validateDate(
            this.state.data.effective_from,
            this.state.data.effective_to
          )
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };
  validate = (rules, data) => {
    const errors = validator(rules)(data);
    const hasErrors = find(errors, (error) => error !== "");
    this.setState({ errors });
    return !hasErrors;
  };
  validateDate = (val1, val2) => {
    if (val2 < val1) {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          effective_to: "To Date should be greater than From Date",
        },
      });
    } else {
      return true;
    }
  };

  handleBack = (id) => {
    if (this.props.match.params.user_id) {
      this.props.navigateTo(
        `/master/user-master/edit/${this.props.match.params.user_id}/${id}`
      );
    } else {
      this.props.navigateTo(`/master/user-master/add/${id}`);
    }
  };
  handleNext = (id) => {
    if (this.props.match.params.user_id) {
      if (this.getValidateData()) {
        this.props.navigateTo(
          `/master/user-master/edit/${this.props.match.params.user_id}/${id}`
        );
      }
    } else {
      if (this.getValidateData()) {
        this.props.navigateTo(`/master/user-master/add/${id}`);
      }
    }
  };

  handleClearPersonalDetails = () => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        name: "",
        pen: "",
        designation: "",

        email: "",
        contact_no: "",
        username: "",
        password: "",
        confirmPassword: "",
      },
      errors: {
        ...this.state.errors,
        name: "",
        pen: "",
        designation: "",

        email: "",
        contact_no: "",
        username: "",
        password: "",
        confirmPassword: "",
      },
    });
  };

  handleClearUserInfo = () => {
    this.setState({
      ...this.state,
      officeList: [],
      Role: [],
      Field: [],
      District: [],
      data: {
        ...this.state.data,
        userType: "",
        role: "",
        zone: "",
        district: "",
        field: "",
        office: "",
        effective_from: null,
        effective_to: null,
        status: "true",
      },

      errors: {
        ...this.state.errors,
        userType: "",
        office: "",
        field: "",
        zone: "",
        district: "",
        role: "",
        effective_from: null,
        effective_to: null,
      },
      zone_enabled: "",
      district_enabled: "",
      field_enabled: "",
      state_enabled: "",
      office_enabled: "",
    });
  };

  handleClearCamera = () => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        camera_access: [],
      },
      checked: true,
    });
  };
  handleChange = (e) => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errors: {
        ...this.state.errors,
        [e.target.name]: "",
      },
    });
  };

  handleFromDateChange = (date) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        effective_from: moment(date).format("YYYY-MM-DD"),
      },
      errors: { ...this.state.errors, effective_from: null },
    });
  };

  handleToDateChange = (date) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        effective_to: moment(date).format("YYYY-MM-DD"),
      },
      errors: { ...this.state.errors, effective_to: null },
    });
  };

  handleChangeRole = (e, value) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        role: value,
      },
      errors: {
        ...this.state.errors,
        role: "",
      },
    });
  };
  handleChangeDesignation = (e, value) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        designation: value,
      },
      errors: {
        ...this.state.errors,
        designation: "",
      },
    });
  };
  handleChangeOffice = (e, value) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        office: value,
      },
      errors: {
        ...this.state.errors,
        office: "",
      },
    });
  };

  handleUserType = (e, value) => {
    e.preventDefault();
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,

          userType: value,
        },
        errors: {
          ...this.state.errors,
          userType: "",
        },
      },
      () => {
        this.setState(
          {
            ...this.state,
            data: {
              ...this.state.data,
              role: "",
              zone: "",
              field: "",
              district: "",
              office: "",
            },
          },
          () => {
            this.props.fetchRole(this.state.data.userType.id);
            if (this.state.data.userType.user_type == "State Level") {
              this.setState(
                {
                  ...this.state,
                  state_enabled: "true",
                  zone_enabled: "",
                  district_enabled: "",
                  field_enabled: "",
                  office_enabled: "",
                },
                () => {
                  this.props.fetchCameraAccess(
                    this.state.data.userType.id,
                    null
                  );
                }
              );
            }
            if (
              this.state.data.userType.user_type == "Zone Level" ||
              this.state.data.userType.user_type == "District Level" ||
              this.state.data.userType.user_type == "Field Level"
            ) {
              this.setState({
                ...this.state,
                state_enabled: "",
                zone_enabled: "true",
                district_enabled: "",
                field_enabled: "",
                office_enabled: "",
              });
            }
            if (
              this.state.data.userType.user_type == "District Level" ||
              this.state.data.userType.user_type == "Field Level"
            ) {
              this.setState({
                ...this.state,
                state_enabled: "",
                zone_enabled: "",
                district_enabled: "true",
                field_enabled: "",
                office_enabled: "",
              });
            }
            if (this.state.data.userType.user_type == "Field Level") {
              this.setState({
                ...this.state,
                state_enabled: "",
                zone_enabled: "",
                district_enabled: "",
                field_enabled: "true",
                office_enabled: "true",
              });
            }
          }
        );
      }
    );
  };

  handleZone = (e, value) => {
    e.preventDefault();
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,

          zone: value,
        },
        errors: {
          ...this.state.errors,
          zone: "",
        },
      },
      () => {
        this.setState(
          {
            ...this.state,
            data: {
              ...this.state.data,
              district: "",
              field: "",
              office: "",
            },
          },
          () => {
            this.props.fetchDistrict(this.state.data.zone.zone_id);
            if (this.state.data.userType.user_type == "Zone Level") {
              this.props.fetchCameraAccess(
                this.state.data.userType.id,
                this.state.data.zone.zone_id
              );
            }
          }
        );
      }
    );
  };
  handleDistrict = (e, value) => {
    e.preventDefault();
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,

          district: value,
        },
        errors: {
          ...this.state.errors,
          district: "",
        },
      },
      () => {
        this.setState(
          {
            ...this.state,
            data: {
              ...this.state.data,
              field: "",
              office: "",
            },
          },
          () => {
            this.props.fetchField(this.state.data.district.district_id);
            if (this.state.data.userType.user_type == "District Level") {
              this.props.fetchCameraAccess(
                this.state.data.userType.id,
                this.state.data.district.district_id
              );
            }
          }
        );
      }
    );
  };
  handleField = (e, value) => {
    e.preventDefault();
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,

          field: value,
        },
        errors: {
          ...this.state.errors,
          field: "",
          office: "",
        },
      },
      () => {
        this.setState(
          {
            ...this.state,
            data: {
              ...this.state.data,
              office: "",
            },
          },
          () => {
            this.props.fetchOfficeList(this.state.data.field.location_id);
            if (this.state.data.userType.user_type == "Field Level")
              this.props.fetchCameraAccess(
                this.state.data.userType.id,
                this.state.data.field.location_id
              );
          }
        );
      }
    );
  };

  handleClickShowPassword = () => {
    this.setState({ ...this.state, showPassword: !this.state.showPassword });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      ...this.state,
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };
  handleSelectAllClick = (event) => {
    const { camera_list } = this.state;
    if (event.target.checked) {
      const newSelecteds = camera_list.map((n) => n);
      this.setState({
        ...this.state,
        data: { ...this.state.data, camera_access: newSelecteds },
      });
      return;
    }
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        camera_access: [],
      },
    });
  };

  handleClick = (event, item) => {
    const { camera_access } = this.state.data;
    const selectedIndex = camera_access.indexOf(item);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(camera_access, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(camera_access.slice(1));
    } else if (selectedIndex === camera_access.length - 1) {
      newSelected = newSelected.concat(camera_access.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        camera_access.slice(0, selectedIndex),
        camera_access.slice(selectedIndex + 1)
      );
    }
    this.setState({
      ...this.state,
      checked: true,
      assignedId: item,

      data: { ...this.state.data, camera_access: newSelected },
    });
  };
  isSelected = (item) => this.state.data.camera_access.indexOf(item) !== -1;

  handleClick1 = (event, item) => {
    const { camera_access } = this.state.data;
    const selectedIndex = camera_access.indexOf(item);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(camera_access, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(camera_access.slice(1));
    } else if (selectedIndex === camera_access.length - 1) {
      newSelected = newSelected.concat(camera_access.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        camera_access.slice(0, selectedIndex),
        camera_access.slice(selectedIndex + 1)
      );
    }
    this.setState({
      ...this.state,
      checked_1: true,
      assignedId_1: item,

      data: { ...this.state.data, camera_access: newSelected },
    });
  };
  isSelected_1 = (item) => this.state.data.camera_access.indexOf(item) !== -1;

  handleMouseOver = (id) => {
    this.setState({ ...this.state, selectedId: id });
  };

  handleMouseOut = () => {
    this.setState({ ...this.state, selectedId: 123 });
  };
  handlePress = () => {
    this.setState({
      ...this.state,
      assignedId: 0,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      steps,
      showPassword,
      showConfirmPassword,
      data,
      errors,
      Role,
      UserType,
      Field,
      Zone,
      District,
      Cameras,
      designationList,
      officeList,
      DefaultCameras,
      OtherCameras,
    } = this.state;

    return (
      <Grid className={classes.overallGrid}>
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            onBackButtonClick={this.onBackButtonClick}
            header={
              this.props.match.params.user_id
                ? "Edit User Master Details"
                : "Add User Master Details"
            }
          />

          <div className={classes.textFielddes}>
            <Fragment>
              <div className={classes.stepperDiv}>
                <Grid container spacing={24}>
                  <Grid item xs={12}>
                    <Stepper
                      alternativeLabel
                      activeStep={parseInt(this.props.match.params.stepID)}
                      connector={<ColorlibConnector />}
                      classes={{ root: classes.stepperRoot }}
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                            classes={{
                              // label: classes.stepLabel,
                              alternativeLabel: classes.alternativeLabel,
                              labelContainer: classes.labelContainer,
                            }}
                          >
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                </Grid>
              </div>

              {this.props.match.params.stepID === "0" ? (
                <>
                  <Grid container className={classes.outerGrid}>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={
                        // this.props.match.params.user_id
                        //   ? classes.innerGridEdit
                        //   :
                        classes.innerGrid
                      }
                    >
                      <TextField
                        fullWidth
                        inputProps={{
                          maxLength: 30,
                        }}
                        className={classes.textField}
                        label="Full Name"
                        id="outlined-margin-dense"
                        margin="dense"
                        name="name"
                        value={data.name}
                        error={errors.name ? true : false}
                        onChange={(e) => this.handleChange(e)}
                        variant="outlined"
                        required
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                        InputProps={{ classes: { root: classes.textValue } }}
                      ></TextField>

                      {errors.name ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.name}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={
                        // this.props.match.params.user_id
                        //   ? classes.innerGridEdit
                        //   :
                        classes.innerGrid
                      }
                    >
                      <TextField
                        fullWidth
                        inputProps={{
                          maxLength: 7,
                        }}
                        className={classes.textField}
                        label="PEN"
                        id="outlined-margin-dense"
                        margin="dense"
                        name="pen"
                        value={data.pen}
                        onChange={(e) => this.handleChange(e)}
                        error={errors.pen ? true : false}
                        variant="outlined"
                        required
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                        InputProps={{ classes: { root: classes.textValue } }}
                      ></TextField>
                      {errors.pen ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.pen}
                        </FormHelperText>
                      ) : null}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={
                        // this.props.match.params.user_id
                        //   ? classes.innerGridEdit
                        //   :
                        classes.innerGrid
                      }
                    >
                      {designationList ? (
                        <Autocomplete
                          disableClearable="true"
                          disableOpenOnFocus="true"
                          options={designationList}
                          value={data.designation}
                          getOptionLabel={(option) =>
                            option.designation ? option.designation : ""
                          }
                          onChange={this.handleChangeDesignation}
                          popupIcon={<DownArrow />}
                          classes={{
                            input: classes.textValue,
                            option: classes.textValue,
                            popupIndicatorOpen: classes.popIconOpen,
                            popupIndicator: classes.popIcon,
                            noOptions: classes.textNoOptions,
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              maxLength={20}
                              className={classes.textField}
                              label="Designation"
                              id="outlined-margin-dense"
                              margin="dense"
                              name="designation"
                              error={errors.designation ? true : false}
                              variant="outlined"
                              required
                              {...params}
                              InputLabelProps={{
                                classes: { root: classes.textLabel },
                              }}
                            ></TextField>
                          )}
                        />
                      ) : null}
                      {errors.designation ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.designation}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={
                        // this.props.match.params.user_id
                        //   ? classes.innerGridEdit
                        //   :
                        classes.innerGrid
                      }
                    >
                      <TextField
                        fullWidth
                        inputProps={{
                          maxLength: 40,
                        }}
                        className={classes.textField}
                        label="Enter Email ID"
                        id="outlined-margin-dense"
                        margin="dense"
                        name="email"
                        value={data.email}
                        onChange={(e) => this.handleChange(e)}
                        error={errors.email ? true : false}
                        variant="outlined"
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                        InputProps={{ classes: { root: classes.textValue } }}
                      ></TextField>
                      {errors.email ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.email}
                        </FormHelperText>
                      ) : null}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={
                        // this.props.match.params.user_id
                        //   ? classes.innerGridEdit
                        //   :
                        classes.innerGrid
                      }
                    >
                      <TextField
                        fullWidth
                        inputProps={{
                          maxLength: 16,
                        }}
                        className={classes.textField}
                        label="Enter Mobile Number"
                        id="outlined-margin-dense"
                        margin="dense"
                        name="contact_no"
                        value={data.contact_no}
                        onChange={(e) => this.handleChange(e)}
                        error={errors.contact_no ? true : false}
                        variant="outlined"
                        required
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                        InputProps={{ classes: { root: classes.textValue } }}
                      ></TextField>
                      {errors.contact_no ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.contact_no}
                        </FormHelperText>
                      ) : null}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={
                        // this.props.match.params.user_id
                        //   ? classes.innerGridEdit
                        //   :

                        classes.innerGrid
                      }
                    >
                      <TextField
                        disabled={
                          this.props.match.params.user_id ? true : false
                        }
                        type="text"
                        fullWidth
                        inputProps={{
                          maxLength: 20,
                        }}
                        className={classes.textField}
                        label="Preferred Username"
                        id="outlined-margin-dense"
                        margin="dense"
                        name="username"
                        value={data.username}
                        onChange={(e) => this.handleChange(e)}
                        error={errors.username ? true : false}
                        variant="outlined"
                        required
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                        InputProps={{ classes: { root: classes.textValue } }}
                      ></TextField>
                      {errors.username ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.username}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={
                        // this.props.match.params.user_id
                        //   ? classes.innerGridEdit
                        //   :
                        classes.innerGrid
                      }
                    >
                      <TextField
                        fullWidth
                        name="password"
                        value={data.password}
                        error={errors.password ? true : false}
                        onChange={this.handleChange}
                        type={showPassword ? "text" : "password"}
                        className={classes.textField}
                        label="Password"
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                        InputProps={{ classes: { root: classes.textValue } }}
                        required
                        InputProps={{
                          maxLength: 20,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <Visibility color={classes.visibilityColor} />
                                ) : (
                                  <VisibilityOff
                                    color={classes.visibilityColor}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                      {errors.password ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.password}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={
                        // this.props.match.params.user_id
                        //   ? classes.innerGridEdit
                        //   :
                        classes.innerGrid
                      }
                    >
                      <TextField
                        name="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        fullWidth
                        className={classes.textField}
                        label="Confirm Password"
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                        InputProps={{ classes: { root: classes.textValue } }}
                        required
                        error={errors.confirmPassword ? true : false}
                        value={data.confirmPassword}
                        onChange={this.handleChange}
                        InputProps={{
                          maxLength: 20,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickShowConfirmPassword}
                              >
                                {showConfirmPassword ? (
                                  <Visibility color={classes.visibilityColor} />
                                ) : (
                                  <VisibilityOff
                                    color={classes.visibilityColor}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                      {errors.confirmPassword ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.confirmPassword}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={
                        // this.props.match.params.user_id
                        //   ? classes.innerGridEdit
                        //   :
                        classes.innerGrid
                      }
                    ></Grid>
                  </Grid>

                  <Grid xs={12} className={classes.btns1} container>
                    <Grid item xs={12} md={6}></Grid>

                    <Grid item xs={12} md={2} style={{ textAlign: "right" }}>
                      <Button
                        disableElevation={true}
                        elevation={0}
                        variant="contained"
                        className={classes.clearButton1}
                        onClick={() => this.handleClearPersonalDetails()}
                      >
                        Clear
                      </Button>
                    </Grid>

                    <Grid item xs={12} md={3} className={classes.btnGrids}>
                      <Button
                        disableElevation={true}
                        elevation={0}
                        variant="contained"
                        color="secondary"
                        className={classes.button1}
                        onClick={() => this.handleNext(1)}
                      >
                        Next
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={1}></Grid>
                    {/* <Recaptcha
                      ref={(ref) => (this.recaptcha = ref)}
                      sitekey={CaptchaSiteKeyMasters}
                      size="invisible"
                      onResolved={this.onResolved}
                    /> */}
                  </Grid>
                </>
              ) : null}

              {this.props.match.params.stepID === "1" ? (
                <>
                  <Grid container className={classes.outerGrid}>
                    <Grid item xs={12} md={4} className={classes.innerGrid}>
                      {UserType ? (
                        <Autocomplete
                          disableClearable="true"
                          disableOpenOnFocus="true"
                          options={UserType}
                          getOptionLabel={(option) =>
                            option.user_type ? option.user_type : ""
                          }
                          onChange={this.handleUserType}
                          value={data.userType}
                          popupIcon={<DownArrow />}
                          classes={{
                            input: classes.textValue,
                            option: classes.textValue,
                            popupIndicatorOpen: classes.popIconOpen,
                            popupIndicator: classes.popIcon,
                            noOptions: classes.textNoOptions,
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              maxLength={20}
                              className={classes.textField}
                              label="User Type"
                              id="outlined-margin-dense"
                              margin="dense"
                              variant="outlined"
                              required
                              name="userType"
                              error={errors.userType ? true : false}
                              {...params}
                              InputLabelProps={{
                                classes: { root: classes.textLabel },
                              }}
                            ></TextField>
                          )}
                        />
                      ) : null}
                      {errors.userType ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.userType}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid item xs={12} md={4} className={classes.innerGrid}>
                      {Zone ? (
                        <Autocomplete
                          disableClearable="true"
                          disableOpenOnFocus="true"
                          disabled={
                            this.state.state_enabled === "true" ? true : false
                          }
                          options={Zone}
                          getOptionLabel={(option) =>
                            option.zone_name ? option.zone_name : ""
                          }
                          onChange={this.handleZone}
                          value={data.zone}
                          popupIcon={<DownArrow />}
                          classes={{
                            input: classes.textValue,
                            option: classes.textValue,
                            popupIndicatorOpen: classes.popIconOpen,
                            popupIndicator: classes.popIcon,
                            noOptions: classes.textNoOptions,
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              maxLength={20}
                              className={classes.textField}
                              label="Choose Zone"
                              id="outlined-margin-dense"
                              margin="dense"
                              variant="outlined"
                              required
                              name="zone"
                              error={errors.zone ? true : false}
                              {...params}
                              InputLabelProps={{
                                classes: { root: classes.textLabel },
                              }}
                            ></TextField>
                          )}
                        />
                      ) : null}

                      {errors.zone ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.zone}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.innerGrid}>
                      <Autocomplete
                        disableClearable="true"
                        disableOpenOnFocus="true"
                        disabled={
                          this.state.state_enabled ||
                          this.state.zone_enabled === "true"
                            ? true
                            : false
                        }
                        options={District}
                        getOptionLabel={(option) =>
                          option.district_name ? option.district_name : ""
                        }
                        onChange={this.handleDistrict}
                        value={data.district}
                        popupIcon={<DownArrow />}
                        classes={{
                          input: classes.textValue,
                          option: classes.textValue,
                          popupIndicatorOpen: classes.popIconOpen,
                          popupIndicator: classes.popIcon,
                          noOptions: classes.textNoOptions,
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            maxLength={20}
                            className={classes.textField}
                            label="Choose District"
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            required
                            name="district"
                            error={errors.district ? true : false}
                            {...params}
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                          ></TextField>
                        )}
                      />

                      {errors.district ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.district}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.innerGrid}>
                      <Autocomplete
                        disableClearable="true"
                        disableOpenOnFocus="true"
                        disabled={
                          this.state.state_enabled ||
                          this.state.zone_enabled ||
                          this.state.district_enabled === "true"
                            ? true
                            : false
                        }
                        options={Field}
                        getOptionLabel={(option) =>
                          option.place ? option.place : ""
                        }
                        onChange={this.handleField}
                        value={data.field}
                        popupIcon={<DownArrow />}
                        classes={{
                          input: classes.textValue,
                          option: classes.textValue,
                          popupIndicatorOpen: classes.popIconOpen,
                          popupIndicator: classes.popIcon,
                          noOptions: classes.textNoOptions,
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            maxLength={20}
                            className={classes.textField}
                            label="Choose field"
                            name="field"
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            required
                            error={errors.field ? true : false}
                            {...params}
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                          ></TextField>
                        )}
                      />

                      {errors.field ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.field}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.innerGrid}>
                      {officeList ? (
                        <Autocomplete
                          disableClearable="true"
                          disableOpenOnFocus="true"
                          disabled={
                            this.state.state_enabled ||
                            this.state.zone_enabled ||
                            this.state.district_enabled === "true"
                              ? true
                              : false
                          }
                          options={officeList}
                          value={data.office}
                          popupIcon={<DownArrow />}
                          classes={{
                            input: classes.textValue,
                            option: classes.textValue,
                            popupIndicatorOpen: classes.popIconOpen,
                            popupIndicator: classes.popIcon,
                            noOptions: classes.textNoOptions,
                          }}
                          getOptionLabel={(option) =>
                            option.office ? option.office : ""
                          }
                          onChange={this.handleChangeOffice}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              maxLength={20}
                              className={classes.textField}
                              label="Choose Office"
                              id="outlined-margin-dense"
                              margin="dense"
                              name="office"
                              error={errors.office ? true : false}
                              variant="outlined"
                              required
                              {...params}
                              InputLabelProps={{
                                classes: { root: classes.textLabel },
                              }}
                            ></TextField>
                          )}
                        />
                      ) : null}
                      {errors.office ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.office}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.innerGrid}>
                      <Autocomplete
                        disableClearable="true"
                        disableOpenOnFocus="true"
                        options={Role}
                        value={data.role}
                        getOptionLabel={(option) =>
                          option.role_name ? option.role_name : ""
                        }
                        popupIcon={<DownArrow />}
                        classes={{
                          input: classes.textValue,
                          option: classes.textValue,
                          popupIndicatorOpen: classes.popIconOpen,
                          popupIndicator: classes.popIcon,
                          noOptions: classes.textNoOptions,
                        }}
                        onChange={this.handleChangeRole}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            maxLength={20}
                            className={classes.textField}
                            label="Choose Role"
                            id="outlined-margin-dense"
                            margin="dense"
                            name="role"
                            error={errors.role ? true : false}
                            variant="outlined"
                            required
                            {...params}
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                          ></TextField>
                        )}
                      />
                      {errors.role ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.role}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid item xs={12} md={4} className={classes.innerGrid}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          required
                          disableToolbar
                          label="Effective From"
                          name="effective_from"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          margin="dense"
                          className={classes.textField}
                          error={errors.effective_from ? true : false}
                          onChange={this.handleFromDateChange}
                          value={data.effective_from}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                          InputProps={{ classes: { root: classes.textValue } }}
                          keyboardIcon={
                            <DateTime className={classes.dateIcon} />
                          }
                        />
                      </MuiPickersUtilsProvider>

                      {errors.effective_from ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.effective_from}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.innerGrid}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          required
                          label="Effective To"
                          name="effective_to"
                          disableToolbar
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          margin="dense"
                          className={classes.textField}
                          value={data.effective_to}
                          error={errors.effective_to ? true : false}
                          onChange={this.handleToDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                          InputProps={{ classes: { root: classes.textValue } }}
                          keyboardIcon={
                            <DateTime className={classes.dateIcon} />
                          }
                        />
                      </MuiPickersUtilsProvider>

                      {errors.effective_to ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.effective_to}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.innerGrid}>
                      <Grid direction="row">
                        <FormControl component="fieldset">
                          <Grid>
                            <RadioGroup
                              aria-label="Is active?"
                              name="status"
                              className={classes.alignment}
                              value={data.status}
                              onChange={(e) => this.handleChange(e)}
                            >
                              <Typography
                                className={classes.typo1}
                                variant="h6"
                              >
                                Status:
                              </Typography>
                              <FormControlLabel
                                classes={{ label: classes.formLabel }}
                                value="true"
                                control={<StyledRadio />}
                                label="Active"
                                className={classes.radioButtons}
                              />
                              <FormControlLabel
                                classes={{ label: classes.formLabel }}
                                value="false"
                                control={<StyledRadio />}
                                label="Inactive"
                                className={classes.radioButtons}
                              />
                            </RadioGroup>
                          </Grid>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    className={classes.btns}
                    container
                    // spacing={4}
                  >
                    <Grid item xs={12} md={3}></Grid>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{ textAlign: "right", marginRight: "-11px" }}
                    >
                      <Button
                        disableElevation={true}
                        elevation={0}
                        className={classes.clearButton}
                        variant="contained"
                        onClick={() => this.handleBack(0)}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{
                        textAlign: "center",
                        // paddingLeft: "12px",
                        // marginLeft: "12px",
                      }}
                    >
                      <Button
                        disableElevation={true}
                        elevation={0}
                        variant="contained"
                        className={classes.clearButton}
                        onClick={() => this.handleClearUserInfo()}
                      >
                        Clear
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{
                        marginLeft: "-11px",
                        textAlign: "left",
                      }}
                    >
                      <Button
                        disableElevation={true}
                        elevation={0}
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={() => this.handleNext(2)}
                      >
                        Next
                      </Button>
                      {/* 
                      <Recaptcha
                        ref={(ref) => (this.recaptcha = ref)}
                        sitekey={CaptchaSiteKeyMasters}
                        size="invisible"
                        onResolved={this.onResolved}
                      /> */}
                    </Grid>
                    <Grid item xs={12} md={3}></Grid>
                  </Grid>
                </>
              ) : null}
              {this.props.match.params.stepID === "2" ? (
                <>
                  <Grid
                    className="CameraGrid"
                    classes={{ root: classes.gridBorder }}
                  >
                    <Typography className={classes.title}>
                      Default Cameras
                    </Typography>
                    <br></br>
                    <Grid
                      xs={12}
                      container
                      spacing={1}
                      className={classes.cardGrid}
                    >
                      {DefaultCameras && DefaultCameras.length != 0 ? (
                        DefaultCameras.map((item, index) => {
                          const isItemSelected = this.isSelected(
                            item.camera_id
                          );
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <>
                              {item.status == true ? (
                                <>
                                  <Grid
                                    item
                                    //xs={12} md={3}
                                  >
                                    <Card
                                      elevation={0}
                                      // onMouseOver={() =>
                                      //   this.handleMouseOver(item.camera_id)
                                      // }
                                      onClick={(event) =>
                                        this.handleClick(event, item.camera_id)
                                      }
                                      onMouseOut={this.handleMouseOut}
                                      className={
                                        this.state.selectedId === item.camera_id
                                          ? classes.selectedCard
                                          : isItemSelected
                                          ? classes.assignedCard
                                          : classes.unassignedCard
                                      }
                                    >
                                      <CardActions>
                                        <Typography
                                          className={classes.locationName}
                                        >
                                          {item.location_master.place.length >
                                          14 ? (
                                            <Tooltip
                                              title={item.location_master.place}
                                              placement="bottom"
                                            >
                                              <b>
                                                {item.location_master
                                                  ? item.location_master.place.substring(
                                                      0,
                                                      12
                                                    ) + "..)"
                                                  : ""}
                                              </b>
                                            </Tooltip>
                                          ) : (
                                            <b>
                                              {item.location_master
                                                ? item.location_master.place
                                                : ""}
                                            </b>
                                          )}
                                        </Typography>
                                        <StyledCheckbox
                                          checked={
                                            this.state.checked
                                              ? isItemSelected
                                              : true
                                          }
                                          inputProps={{
                                            "aria-labelledby": labelId,
                                          }}
                                          style={{
                                            marginLeft: "auto",
                                          }}
                                        />
                                      </CardActions>
                                      <CardActions disableSpacing>
                                        <CameraIcon
                                          className={classes.cameraIcon}
                                        />
                                        &nbsp;&nbsp;
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          component="p"
                                          className={classes.cameraName}
                                        >
                                          {item.camera_name
                                            ? item.camera_name
                                            : ""}
                                        </Typography>
                                      </CardActions>
                                    </Card>
                                  </Grid>
                                </>
                              ) : null}
                            </>
                          );
                        })
                      ) : (
                        <Typography className={classes.NoItem}>
                          No Item
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <br></br>

                  <Grid
                    className="CameraGrid"
                    classes={{ root: classes.gridBorder }}
                  >
                    <Typography className={classes.title}>
                      Other Cameras
                    </Typography>
                    <br></br>
                    <Grid
                      xs={12}
                      container
                      spacing={1}
                      className={classes.cardGrid}
                    >
                      {OtherCameras && OtherCameras.length != 0 ? (
                        OtherCameras.map((item, index) => {
                          const isItemSelected_1 = this.isSelected_1(
                            item.camera_id
                          );
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <>
                              {item.status == false ? (
                                <>
                                  <Grid
                                    item
                                    //xs={12}
                                    // md={3}
                                  >
                                    <Card
                                      elevation={0}
                                      // onMouseOver={() =>
                                      //   this.handleMouseOver(item.camera_id)
                                      // }
                                      onClick={(event) =>
                                        this.handleClick1(event, item.camera_id)
                                      }
                                      onMouseOut={this.handleMouseOut}
                                      className={
                                        this.state.selectedId === item.camera_id
                                          ? classes.selectedCard
                                          : isItemSelected_1
                                          ? classes.assignedCard
                                          : classes.unassignedCard
                                      }
                                    >
                                      <CardActions>
                                        <Typography
                                          className={classes.locationName}
                                        >
                                          {item.location_master.place.length >
                                          14 ? (
                                            <Tooltip
                                              title={item.location_master.place}
                                              placement="bottom"
                                            >
                                              <b>
                                                {item.location_master
                                                  ? item.location_master.place.substring(
                                                      0,
                                                      12
                                                    ) + "..)"
                                                  : ""}
                                              </b>
                                            </Tooltip>
                                          ) : (
                                            <b>
                                              {item.location_master
                                                ? item.location_master.place
                                                : ""}
                                            </b>
                                          )}
                                        </Typography>
                                        <StyledCheckbox
                                          checked={isItemSelected_1}
                                          inputProps={{
                                            "aria-labelledby": labelId,
                                          }}
                                          style={{
                                            marginLeft: "auto",
                                          }}
                                        />
                                      </CardActions>
                                      <CardActions disableSpacing>
                                        <CameraIcon
                                          className={classes.cameraIcon}
                                        />
                                        &nbsp;&nbsp;
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          component="p"
                                          className={classes.cameraName}
                                        >
                                          {item.camera_name
                                            ? item.camera_name
                                            : ""}
                                        </Typography>
                                      </CardActions>
                                    </Card>
                                  </Grid>
                                </>
                              ) : null}
                            </>
                          );
                        })
                      ) : (
                        <Typography className={classes.NoItem}>
                          No Item
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    xs={12}
                    className={classes.btns3}
                    container
                    // spacing={4}
                  >
                    <Grid item xs={12} md={3}></Grid>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{ textAlign: "right", marginRight: "-11px" }}
                    >
                      <Button
                        disableElevation={true}
                        elevation={0}
                        className={classes.clearButton}
                        onClick={() => this.handleBack(1)}
                        variant="contained"
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{
                        textAlign: "center",
                        // paddingLeft: "12px",
                        // marginLeft: "12px",
                      }}
                    >
                      <Button
                        disableElevation={true}
                        elevation={0}
                        variant="contained"
                        className={classes.clearButton}
                        onClick={() => this.handleClearCamera()}
                      >
                        Clear
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{
                        marginLeft: "-11px",
                        textAlign: "left",
                      }}
                    >
                      <Button
                        disableElevation={true}
                        elevation={0}
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={this.performAction}
                      >
                        Submit
                      </Button>
                      {/* <Recaptcha
                        ref={(ref) => (this.recaptcha = ref)}
                        sitekey={CaptchaSiteKeyMasters}
                        size="invisible"
                        onResolved={this.onResolved}
                      /> */}
                    </Grid>
                    <Grid item xs={12} md={3}></Grid>
                  </Grid>
                </>
              ) : null}
            </Fragment>
          </div>
        </Paper>
      </Grid>
    );
  }
}

export default withStyles(styles)(UserDetails);
