// /** @format */

// import { createMuiTheme } from "@material-ui/core/styles";
// import purple from "@material-ui/core/colors/purple";

// const theme = createMuiTheme({
//   palette: {
//     primary: { main: "#212B35" }, // Purple and green play nicely together.
//     secondary: {
//       //main: "#00796b"
//       main: "#49B283",
//     }, // This is just green.A700 as hex.
//     text: {},
//   },

//   alertIcon: {
//     color: "red",
//   },
//   warningIcon: {
//     color: "orange",
//   },
//   noAlertIcon: {
//     color: "green",
//   },
//   visibilityIcon: {
//     color: "#424242",
//   },
//   colors: {
//     redColor: "red",
//     orangeColor: "#FF7F0E",
//     greenColor: "green",
//     greyColor: "#9e9e9e",
//     blueColor: "#000051",
//     whiteColor: "#ffffff",
//     loginDivColor: "#6C757D",
//     BlueButton: "#01A8EB",
//     blackColor: "#000000",
//     greyColor2: "#6C757D",
//     BackButton: "#FFB300",
//   },
//   numberPlate: {
//     backgroundColor: "yellow",
//     border: "0.01em solid black",
//   },
//   margin: {
//     backgroundColor: "#000",
//   },

//   font: {
//     fontWeight: 300,
//   },
//   appBar: {
//     //color: "#FAFAFA",
//     //backgroundColor: "transparent!important",
//     backgroundColor: "#E0E0E0",

//     boxShadow: "none",
//   },

//   poweredByStyle: {
//     backgroundColor: "#E0E0E0",
//     color: "#5d5d5d",
//   },
//   appBarIcon: {
//     color: "#000000",
//   },
//   appBarButton: {
//     color: "#F00",
//   },
//   drawerSelectedItem: {
//     backgroundColor: "#1BB99A",
//     color: "#FFFFFF",
//   },
//   drawerSelectedItemText: {
//     color: "#FFFFFF",
//   },
//   footerdiv: {
//     bottom: 0,
//     //position: "fixed",
//     position: "absolute",
//     //backgroundColor: "#FAFAFA",
//     backgroundColor: "#E0E0E0",
//     width: "100vw",
//   },
//   footerText: {
//     fontSize: "0.7em",
//     paddingRight: 10,
//     paddingLeft: 10,
//   },
//   headColumn: {
//     backgroundColor: "#D8D8D8",
//     color: "#000",
//   },
//   BorderStyle: {
//     border: "1px solid #E0E0E0",
//   },
//   toolbar: {
//     //backgroundColor: "#00796b",
//     backgroundColor: "#49B283",
//     color: "#FFFFFF",
//     iconColor: "#FFFFFF",
//   },
//   menuItem: {
//     //selectedTextColor: "#00796b",
//     selectedTextColor: "#49B283",
//   },
//   CameraCard: {
//     backgroundColor: "#fafafa",
//     //borderColor: "#00796b",
//     borderColor: "#49B283",
//     titleColor: "#757575",
//   },
//   dashboardStyle: {
//     cardTitleNumericColor: "#3c4858",
//     cardIconContainerColor: "#fff",
//     cardColor: "#333",
//   },
//   notAddedStyle: {
//     backgroundColor: "rgb(193, 191, 191)",
//     color: "#fff",
//   },

//   tableHeader: {
//     fontsize: "18px",
//   },
//   tableCell: {
//     fontsize: "14px",
//   },
//   list: { fontsize: "10px" },
//   listIcon: { fontSize: "25px" },
//   passedToday: {
//     fontSize: "15px",

//     Layout: {
//       backgroundColor: "#E0E0E0",
//     },
//   },
// });
// export default theme;

/** @format */

import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#212B35" }, // Purple and green play nicely together.
    secondary: {
      main: "#1BB99A",
      dark: "#1BB99A",
      contrastText: "#fff",
    }, // This is just green.A700 as hex.
    text: {},
  },

  alertIcon: {
    color: "red",
  },
  warningIcon: {
    color: "orange",
  },
  noAlertIcon: {
    color: "green",
  },
  visibilityIcon: {
    color: "#424242",
  },
  colors: {
    redColor: "red",
    orangeColor: "#FF7F0E",
    greenColor: "green",
    greyColor: "#9e9e9e",
    blueColor: "#000051",
    whiteColor: "#ffffff",
    loginDivColor: "#6C757D",
    BlueButton: "#E7EBF4",
    blackColor: "#000000",
    greyColor2: "#6C757D",
    BackButton: "#FFB300",
    chartHeading: "#2B3D51",
    green: "#28AC47",
    orange: "#FF9500",
    blue: "#3E56DE",
    red: "#FF7B9C",
    grey: "#C4CCDC",
    tableTitle: "#797C82",
    filterIconColor: "#A4AFC6",
  },
  numberPlate: {
    backgroundColor: "yellow",
    border: "0.01em solid black",
  },
  margin: {
    backgroundColor: "#000",
  },
  border: {
    radius: "15px",
    legentRadius: "4px",
  },
  font: {
    fontWeight: 300,
    family: "Karla",
    medium: "Medium",
    regular: "Regular",
    bold: "Bold",
    size1: "16px",
    size2: "36px",
    size3: "14px",
    size4: "24px",
    size5: "30px",
  },
  appBar: {
    //color: "#FAFAFA",
    // backgroundColor: "#F5F5FB",
    backgroundColor: "#F9F9FE",
    // backgroundColor: "green",
    boxShadow: "none",
  },
  poweredByStyle: {
    backgroundColor: "#E0E0E0",
    color: "#5d5d5d",
  },
  appBarIcon: {
    color: "#000000",
  },
  appBarButton: {
    color: "#F00",
  },
  drawerSelectedItem: {
    //backgroundColor: "#00796B !important",
    backgroundColor: "#1BB99A !important",
    color: "#ffffff",
    noBg: "rgba(0, 0, 0, 0) !important",
    sideIndicator: "#18A489 !important",
  },
  drawerSelectedItemText: {
    color: "#fff",
  },
  footerdiv: {
    bottom: 0,
    //position: "fixed",
    position: "absolute",
    //backgroundColor: "#FAFAFA",
    backgroundColor: "#E0E0E0",
    width: "100vw",
  },
  footerText: {
    fontSize: "0.7em",
    paddingRight: 100,
    paddingLeft: 100,
  },
  headColumn: {
    backgroundColor: "#D8D8D8",
    color: "#000",
  },
  BorderStyle: {
    border: "1px solid #E0E0E0",
  },
  toolbar: {
    //backgroundColor: "#00796b",
    backgroundColor: "#49B283",
    color: "#FFFFFF",
    iconColor: "#FFFFFF",
  },
  menuItem: {
    //selectedTextColor: "#00796b",
    selectedTextColor: "#49B283",
  },
  CameraCard: {
    backgroundColor: "#fafafa",
    //borderColor: "#00796b",
    borderColor: "#49B283",
    titleColor: "#2B3D51",
  },
  dashboardStyle: {
    cardTitleNumericColor: "#3c4858",
    cardIconContainerColor: "#fff",
    cardColor: "#333",
  },
  notAddedStyle: {
    backgroundColor: "rgb(193, 191, 191)",
    color: "#fff",
  },

  tableHeader: {
    fontsize: "18px",
  },
  tableCell: {
    fontsize: "14px",
  },
  list: { fontsize: "10px" },
  listIcon: { fontSize: "25px" },
  passedToday: {
    fontSize: "15px",

    Layout: {
      backgroundColor: "#E0E0E0",
    },
  },

  zebrafont: {
    oddBgColor: "#F1F4FB",
    evenBgColor: "rgb(250 251 254)",
  },
});
export default theme;
