import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const styles = (theme) => ({
  notAddedStyleSkleton: {
    textAlign: "center",
    color: theme.notAddedStyle.color,
    fontSize: 25,
    // padding: 20,
    fontWeight: theme.font.fontWeight,
    // marginLeft: 33,
    // marginRight: 33,
    marginTop: 30,
    // fontWeight: theme.font.fontWeight,
    // marginLeft: 33 props.left,
    // marginRight: 33 props.right,
    // marginTop: 30 props.top,
    // paddingTop:props.ptop,
    fontFamily: theme.font.family,
  },
});
const Skelton = (props) => {
  const { classes } = props;
  return (
    <SkeletonTheme highlightColor="#FAFBFE" color=" #F1F4FB">
      <div className={classes.notAddedStyleSkleton}>
        <Skeleton
          count={props.counts ? props.counts : 5}
          height={10}
          // duration={2}
        />
      </div>
    </SkeletonTheme>
  );
};
export default withStyles(styles)(Skelton);
