import APIRequest from "../util/api/APIRequest";

class ReportService {
  fetchReport = (data) => {
    return APIRequest({
      url: data.generateUrl,
      params: data.generateParams,
      method: "GET",
    });
  };
  exportReport = (data) => {
    return APIRequest({
      url: data.exportUrl,
      params: data.exportParams,
      method: "GET",
    });
  };
  fetchAlert = () => {
    return APIRequest({
      url: "/api/combo?type=alertList",
      method: "GET",
    });
  };
  fetchWarning = () => {
    return APIRequest({
      url: "",
      method: "GET",
    });
  };
  fetchSquad = (id, fromdate, todate) => {
    return APIRequest({
      url: `/api/squadReport`,
      params: {
        office: id,
        from_date: fromdate,
        to_date: todate,
      },
      method: "GET",
    });
  };

  fetchOfficer = (type, id) => {
    return APIRequest({
      url: `/api/reportOfficers?` + type + "=" + id,
      method: "GET",
    });
  };
}
export default ReportService;
