import APIRequest from "../util/api/APIRequest";
class SelectCameraService {
  fetchZone = () => {
    return APIRequest({
      url: "api/zones",
      method: "GET",
    });
  };

  fetchDistrict = (id) => {
    return APIRequest({
      url: `api/districtList`,
      params: { id },
      method: "GET",
    });
  };

  fetchField = (id) => {
    return APIRequest({
      url: `api/fieldList`,
      params: { id },
      method: "GET",
    });
  };
  fetchStateCamera = () => {
    return APIRequest({
      url: `api/socket/camera`,
      method: "GET",
    });
  };
  fetchZoneCamera = (zone) => {
    return APIRequest({
      url: `api/socket/camera`,
      params: {
        zone,
      },
      method: "GET",
    });
  };

  fetchDistrictCamera = (district) => {
    return APIRequest({
      url: `api/socket/camera`,
      params: {
        district,
      },
      method: "GET",
    });
  };
  fetchFieldCamera = (field) => {
    return APIRequest({
      url: `api/socket/camera`,
      params: {
        field,
      },
      method: "GET",
    });
  };
}

export default SelectCameraService;
