import {
  FETCH_OFFICE_LIST,
  DELETE_OFFICE,
  RESET_DELETE,
  FETCH_OFFICE_LIST_ERROR,
  DELETE_OFFICE_ERROR,
  FETCH_ZONE,
  FETCH_ZONE_FAILED,
  FETCH_DISTRICT,
  FETCH_DISTRICT_FAILED,
  DIALOGUE_WINDOW_STATUS,
  FILTER_CHANGE,
  FILTER_CHANGE_ERROR,
} from "./constants";

const initialState = {
  officeLists: [],
  zone: [],
  district: [],
  officeListFilter: [],
  officesCount: 0,
  deleteSuccess: false,
  deleteSuccessMessage: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OFFICE_LIST:
      return {
        ...state,
        officeLists: action.data.data.rows,
        officesCount: action.data.data.count,
      };
    case FETCH_OFFICE_LIST_ERROR:
      sessionStorage.setItem("officeError", true);
      return {
        ...initialState,
      };
    case DELETE_OFFICE:
      return {
        ...initialState,
        deleteSuccessMessage: action.data,
        error: action.data.message,
        deleteSuccess: true,
      };
    case DELETE_OFFICE_ERROR:
      return {
        ...initialState,
      };
    case RESET_DELETE:
      return {
        ...initialState,
        deleteSuccess: false,
      };
    case FETCH_ZONE:
      return {
        ...state,
        zone: action.data.data,
      };
    case FETCH_ZONE_FAILED:
      return {
        ...state,
        zone: [],
      };
    case FETCH_DISTRICT:
      return {
        ...state,
        district: action.data.data,
      };
    case FETCH_DISTRICT_FAILED:
      return {
        ...state,
        district: [],
      };
    case DIALOGUE_WINDOW_STATUS:
      return {
        ...state,
        showDialogue: !state.showDialogue,
      };
    case FILTER_CHANGE:
      return {
        ...state,
        officeListFilter: action.data.data,
      };
    case FILTER_CHANGE_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
