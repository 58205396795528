import {
  FETCH_ALERT_LIST,
  FETCH_ALERT_LIST_ERROR,
  SET_ALERT_PAGE,
  RESET_ALERT_PAGE,
  RESET_ERROR_PAGE,
} from "./constants";
import AlertService from "service/AlertService";

import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchAlertList = (page, limit, type, filterParams) => {
  let alertService = new AlertService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    alertService
      .fetchAlertList(page, limit, type, filterParams)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: FETCH_ALERT_LIST, data };
  }
  function failure(error) {
    return { type: FETCH_ALERT_LIST_ERROR, error };
  }
};

export const setAlertPage = (page) => {
  return (dispatch) => {
    dispatch(setAlertPage(page));
  };
  function setAlertPage(page) {
    return { type: SET_ALERT_PAGE, page };
  }
};
export const resetAlertPage = () => {
  return (dispatch) => {
    dispatch(resetAlertPage());
  };
  function resetAlertPage() {
    return { type: RESET_ALERT_PAGE };
  }
};
export const resetError = () => {
  return (dispatch) => {
    dispatch(resetError());
  };
  function resetError() {
    return { type: RESET_ERROR_PAGE };
  }
};
