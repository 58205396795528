import { connect } from "react-redux";
import { withRouter } from "react-router";
import RecentAlerts from "./RecentAlerts";
import { fetchRecentAlerts } from "./data/action";

function mapStateToProps(state) {
  return {
    plate: state.recentActivity.plate,
    recentAlerts: state.recentAlerts.recentAlerts,
    loading: state.progress.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRecentAlerts: (plate) => dispatch(fetchRecentAlerts(plate)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecentAlerts)
);
