import { connect } from "react-redux";
import { withRouter } from "react-router";
import SquadMasterList from "./squadMasterList";
import { push } from "connected-react-router";
import {
  toggleFilterWindow,
  resetFilterParams,
  setFilterParams,
  setValue,
} from "../../components/filtersquad_form/data/action";
import { dialogueWindowStatus, onFilterChange } from "./data/action";
import {
  fetchSqadList,
  deleteSquad,
  resetDelete,
  fetchDistricts,
} from "./data/action";
import { snackbarActions } from "components/snackbar/data/action";

function mapStateToProps(state) {
  return {
    squadList: state.squadList.squadList,
    squadCount: state.squadList.squadCount,
    filterParams: state.filter.filterParams,
    deleteSuccessReposnse: state.squadList.deleteSuccessMessage,
    deleteSuccess: state.squadList.deleteSuccess,
    showDialogue: state.showDialogue,
    showFilterWindow: state.filter.showFilterWindow,
    squad: state.squadList.squadListFilter,
    dist: state.squadList.district,
    val: state.filter.val,
    loading: state.progress.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onFilterChange: (page, limit, squadname, district) =>
      dispatch(onFilterChange(page, limit, squadname, district)),
    fetchSqadList: (page, limit, filterParams) =>
      dispatch(fetchSqadList(page, limit, filterParams)),
    navigateTo: (url) => dispatch(push(url)),
    toggleFilterWindow: () => dispatch(toggleFilterWindow()),
    resetFilterParams: () => dispatch(resetFilterParams()),
    deleteSquad: (id) => dispatch(deleteSquad(id)),
    resetDelete: () => dispatch(resetDelete()),
    fetchDistricts: () => dispatch(fetchDistricts()),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
    dialogueWindowStatus: () => dispatch(dialogueWindowStatus()),
    setValue: () => dispatch(setValue()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SquadMasterList)
);
