/** @format */

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import layout from "../screens/layout/data/reducer";
import vehicleList from "../screens/camera/components/vehicle_list/data/reducer";
import vehicleInfo from "../screens/camera/components/vehicle_info/data/reducer";
import recentActivity from "../screens/camera/components/recent_activity/data/reducer";
import authentication from "../screens/login/data/reducer";
import snackbar from "../components/snackbar/data/reducer";
import alertList from "../screens/alerts/components/alertlist/data/reducer";
import alert from "../screens/alerts/data/reducer";
import historyList from "../screens/history/data/reducer";
import historyView from "../screens/history/components/history_detailed_view/data/reducer";
import filter from "../components/filter_form/data/reducer";
import filterhistory from "../components/filterhistory_form/data/reducer";
import filteralert from "../components/filteralert_form/data/reducer";
import filterwarning from "../components/filterwarning_form/data/reducer";
import filterRole from "../components/filterrole_form/data/reducer";
import filterUser from "../components/filteruser_form/data/reducer";
import filterduty from "../components/filterduty_form/data/reducer";
import progress from "../components/progressbar/data/reducer";
import drawer from "../components/drawer/data/reducer";
import alertView from "../screens/alerts/components/alert_detailed_view/data/reducer";
import overview from "./../screens/dashboard/overview/data/reducer";
import warningDetails from "./../components/appbar/data/reducer";
import recentAlerts from "../screens/alerts/components/recent_alerts/data/reducer";
import review from "../screens/review/data/reducer";
import warningView from "../screens/review/components/warnings_detailed_view/data/reducer";
import warning from "../screens/warning/data/reducer";
import warningfilter from "../components/warningfilter/data/reducer";
import warningDetailView from "../screens/warning/components/warnings_details/data/reducer";
// import cameraDetails from "../screens/cameraMaster/components/camera_details/data/reducer";
// import cameraList from "../screens/cameraMaster/data/reducer";
import locationDetails from "../screens/location/components/location_details/data/reducer";
import locationList from "../screens/location/data/reducer";
// import laneDetails from "../screens/lane/components/lane_details/data/reducer";
// import laneList from "../screens/lane/data/reducer";
import habitualOffendersList from "../screens/habitualOffenders/data/reducer";
import habitualOffendersDetails from "../screens/habitualOffenders/components/habitualOffenders_details/data/reducer";
import dropdown from "../util/dropdown/data/reducer";
import reports from "../screens/reports/data/reducer";
import reportFilter from "../screens/reports/components/report_filter/data/reducer";
import user from "../screens/userInfo/data/reducer";
import alertAndWarningCount from "../screens/dashboard/piechart_declarative/data/reducer";
import vehicleTypeCount from "../screens/dashboard/overview/data/reducer";
import AssignDuty from "../screens/dutyMaster/components/data/reducer";
import dutyList from "../screens/dutyMaster/data/reducer";
import squadList from "../screens/squadMaster/data/reducer";
import squadDetails from "../screens/squadMaster/components/data/reducer";
import flammableGoodsDetails from "../screens/flammableGoods/components/flammableGoods_details/data/reducer";
import flammableGoodsList from "../screens/flammableGoods/data/reducer";
import userTypeList from "../screens/roleMaster/components/addRole/data/reducer";
import featureList from "../screens/roleMaster/components/addRole/data/reducer";
import message from "../screens/roleMaster/components/addRole/data/reducer";
import saveSuccess from "../screens/roleMaster/components/addRole/data/reducer";
import error from "../screens/roleMaster/components/addRole/data/reducer";
import roleMasterList from "../screens/roleMaster/components/addRole/data/reducer";
import permissionList from "../screens/roleMaster/components/addRole/data/reducer";
import showDialogue from "../screens/roleMaster/components/viewRole/data/reducer";
import userList from "../screens/userMaster/data/reducer";
import userDetails from "../screens/userMaster/components/userDetails/data/reducer";
import officeDetails from "../screens/officeMaster/components/officeDetails/data/reducer";
import officeList from "../screens/officeMaster/data/reducer";
import editRoleMaster from "../screens/roleMaster/components/editRole/data/reducer";
import deleteRoleMaster from "../screens/roleMaster/components/deleteRole/data/reducer";
import designationMaster from "../screens/designationMaster/components/designationMaster_details/data/reducer";
import locationSelector from "../screens/locationSelector/data/reducer";
import cameraReducer from "../screens/camera/cameraSelector/data/reducer";
import summary from "../screens/summaryReport/data/reducer";
import actionsummary from "../screens/actionsummaryReport/data/reducer";
import actionsummaryreportfilter from "../screens/actionsummaryReport/components/reportFilter/data/reducer";
import summaryAction from "../screens/actionsummaryReport/components/overallActionSummary/data/reducer";
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    vehicleList,
    vehicleInfo,
    recentActivity,
    authentication,
    snackbar,
    alertList,
    alert,
    historyList,
    historyView,
    filter,
    filteralert,
    filterhistory,
    filterwarning,

    filterRole,
    filterUser,
    filterduty,
    progress,
    drawer,
    alertView,
    overview,
    recentAlerts,
    alertAndWarningCount,
    vehicleTypeCount,
    review,
    warningView,
    dropdown,
    reports,
    reportFilter,
    // cameraDetails,
    // cameraList,
    locationDetails,
    locationList,
    warningDetails,
    // laneDetails,
    // laneList
    habitualOffendersList,
    habitualOffendersDetails,
    user,
    AssignDuty,
    dutyList,
    squadList,
    squadDetails,
    flammableGoodsDetails,
    flammableGoodsList,
    userList,
    userDetails,
    officeDetails,
    officeList,
    userTypeList,
    featureList,
    message,
    saveSuccess,
    error,
    roleMasterList,
    permissionList,
    showDialogue,
    editRoleMaster,
    deleteRoleMaster,
    locationSelector,
    designationMaster,
    cameraReducer,
    summary,
    actionsummary,
    actionsummaryreportfilter,
    summaryAction,
    warning,
    warningfilter,
    warningDetailView,
  });
