import APIRequest from "../util/api/APIRequest";

class ReviewService {
  fetchWarningList = (page, limit, filterParams) => {
    return APIRequest({
      url: "api/warnings/",
      method: "POST",
      params: {
        page,
        limit,
      },
      data: filterParams,
    });
  };

  fetchRecord = (id, event_timestamp) => {
    return APIRequest({
      url: `api/history/${id}`,
      method: "GET",
      params: {
        event_timestamp,
      },
    });
  };

  updateStatus = (params) => {
    return APIRequest({
      url: "api/update-warning",
      data: params,
      method: "POST",
    });
  };
  warningnextprev = (data) => {
    return APIRequest({
      url: `api/warningsnextprev`,
      method: "POST",
      data: data,
    });
  };
}
export default ReviewService;
