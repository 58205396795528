import {
  GET_VEHICLE_CATEGORY_DROPDOWN,
  GET_CONFIDENCE_DROPDOWN,
  GET_LANE_DROPDOWN,
  GET_CAMERA_DROPDOWN,
  GET_COMMODITY_DROPDOWN,
} from "./data/constants";
import dropdownTypes from "./dropdownTypes";

export const getActionByComboType = (type) => {
  switch (type) {
    case dropdownTypes.VEHICLE_CATEGORY:
      return {
        action: GET_VEHICLE_CATEGORY_DROPDOWN,
        url: "/combo/?type=vehicleCategory",
      };
    case dropdownTypes.CONFIDENCE:
      return {
        action: GET_CONFIDENCE_DROPDOWN,
        url: "/combo/?type=confidence",
      };
    case dropdownTypes.LANE:
      return {
        action: GET_LANE_DROPDOWN,
        url: "/combo/?type=lane",
      };
    case dropdownTypes.CAMERA:
      return {
        action: GET_CAMERA_DROPDOWN,
        url: "/socket/camera",
      };
    case dropdownTypes.COMMODITY_CATEGORY:
      return {
        action: GET_COMMODITY_DROPDOWN,
        url: "/goods",
      };

    default:
      return;
  }
};
