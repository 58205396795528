import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  GET_CAPTCHA_SUCCESS,
  GET_CAPTCHA_FAILURE,
  VERIFY_CAPTCHA,
  AUTH_STATUS,
  USER_INPUT,
  RESET_USER_INPUT,
} from "./constants";
import authenticationService from "../../../service/AuthenticationService";
import SvgCaptchaService from "../../../service/SvgCaptchaService";
import Storagehandler from "../../../util/storage";
import { snackbarActions } from "../../../components/snackbar/data/action";

let storageHandler = new Storagehandler();
export const doLogin = (userCredentials) => {
  return (dispatch) => {
    storageHandler.removeUser();
    dispatch(snackbarActions.showSnackbar("Logging in...", "info"));
    authenticationService.login(userCredentials).then(
      (response) => {
        if (response.data) {
          if (response.data.status !== "failed") {
            storageHandler.setUser(response.data);
            dispatch(
              snackbarActions.showSnackbar("Login Success..!", "success")
            );
            dispatch(success(response.data));
          } else {
            dispatch(
              snackbarActions.showSnackbar(response.data.message, "info")
            );
            dispatch(statusCheck(response.data));
          }
        } else {
          if (response.response && response.response.status === 401) {
            dispatch(
              snackbarActions.showSnackbar(
                response.response.data.reason,
                "error"
              )
            );
            dispatch(authStatus(response.response.data));
          } else {
            dispatch(
              snackbarActions.showSnackbar(response.error.message, "error")
            );
          }

          dispatch(failure(response));
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(userCredentials) {
    return { type: LOGIN_REQUEST, userCredentials };
  }
  function success(data) {
    return { type: LOGIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: LOGIN_FAILURE, error };
  }
  function statusCheck(data) {
    return { type: VERIFY_CAPTCHA, data };
  }
  function authStatus(data) {
    return { type: AUTH_STATUS, data };
  }
};

export const setUserInput = (data) => {
  return (dispatch) => {
    dispatch(setUserInput(data));
  };
  function setUserInput(data) {
    return { type: USER_INPUT, data };
  }
};
export const resetUserInput = () => {
  return (dispatch) => {
    dispatch(resetUserInput());
  };
  function resetUserInput() {
    return { type: RESET_USER_INPUT };
  }
};
export const doLogout = () => {
  return (dispatch) => {
    storageHandler.removeUser();
    dispatch(lgoutAction());
    dispatch(
      snackbarActions.showSnackbar("Logged out successfully...!", "success")
    );
  };

  function lgoutAction() {
    return { type: LOGOUT };
  }
};

export const getSvgCaptcha = () => {
  return (dispatch) => {
    new SvgCaptchaService().getSvgCaptcha().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
    });
  };
  function success(data) {
    return { type: GET_CAPTCHA_SUCCESS, data };
  }
  function failure(error) {
    return { type: GET_CAPTCHA_FAILURE, error };
  }
};
