import React from "react";
import { toDateTime } from "util/date";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchRecentActivity } from "./data/action";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
  TableBody,
  Paper,
  Typography,
  AppBar,
  Toolbar,
  Divider,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    minHeight: "100%",
    //border: theme.BorderStyle.border,
    //padding: 3,
  },
  table: {
    minWidth: "100%",
    // maxHeight: "174px",
  },
  tableHeadRow: { height: "10px" },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  headColumn: {
    font: "normal normal bold 14px/43px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    paddingBottom: "2px",
    paddingTop: "2px",
    paddingRight: "1px",
    border: "1px solid #E7EBF4",
    opacity: 1,
    paddingTop: "5px",
    paddingBottom: "4.5px",
  },
  toolbar: {
    paddingRight: theme.spacing.unit,
    backgroundColor: theme.toolbar.backgroundColor,
  },
  tableTitle: {
    color: "#2B3D51",
    font: "normal normal bold 18px/22px Karla",
    paddingBottom: "18.5px",
  },
  scroller: {
    margin: "0 auto",
    height: "245px",
    // width: "420px",
    overflow: "auto",
    paddingTop: "22px",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  typo: {
    fontWeight: theme.font.fontWeight,
    fontSize: theme.tableHeader.fontsize,
  },
  toolbar: {
    padding: "2px",
    backgroundColor: theme.colors.whiteColor,
  },
  tableWrapper: {
    // overflowX: "auto",
    // paddingTop: "6px",

    overflow: "auto",
    maxHeight: "22vh",
    minHeight: "12vh",
    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      maxHeight: "20vh",
    },
    /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      maxHeight: "16vh",
    },
    /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      maxHeight: "20vh",
    },
    /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      maxHeight: "21vh",
    },
    /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      maxHeight: "15vh",
    },
  },
  divider: { border: "1px solid #E7EBF4", opacity: 1 },
  zebrafont: {
    paddingTop: "0px",
    paddingBottom: "0px",
    "&:nth-of-type(odd)": {
      backgroundColor: "#F1F4FB",
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgb(250 251 254)",
    },
  },
});

// const CustomTableCell = withStyles((theme) => ({
//   root: {
//     minWidth: "10px",
//   },
//   head: {
//     //backgroundColor: theme.palette.common.black,
//     //color: theme.palette.common.white,
//   },
//   body: {
//     minWidth: "10px",
//     "padding-left": "10px",
//     "padding-right": "5px",
//   },
// }))(TableCell);
class RecentActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.fetchRecentActivity(this.props.plate);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.plate !== this.props.plate) {
      this.props.fetchRecentActivity(this.props.plate);
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.scroller} style={{ paddingBottom: "10px" }}>
        <div className={classes.root}>
          <Typography
            className={classes.tableTitle}
            variant="h6"
            id="tableTitle"
          >
            Recent Activity
          </Typography>

          <Divider className={classes.divider} />
          {this.props.loading ? null : ( // <Skelton counts={4} />
            <div className={classes.tableWrapper}>
              <Table className={classes.table} stickyHeader>
                <TableHead>
                  <TableRow>
                    {" "}
                    <TableCell className={classes.headColumn}>
                      Location
                    </TableCell>
                    <TableCell className={classes.headColumn}>Camera</TableCell>
                    <TableCell className={classes.headColumn}>
                      Date & Time
                    </TableCell>
                    <TableCell className={classes.headColumn}>Lane</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.recentActivities.length > 0 &&
                    this.props.recentActivities.map((row, index) => {
                      return (
                        <TableRow
                          className={classes.zebrafont}
                          key={index}
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                          {" "}
                          <TableCell className={classes.tableCell}>
                            {row.location}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.camera_id}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {toDateTime(row.event_timestamp)}
                          </TableCell>{" "}
                          <TableCell className={classes.tableCell}>
                            {row.lane_id}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    plate: state.recentActivity.plate,
    recentActivities: state.recentActivity.recentActivities,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRecentActivity: (plate) => dispatch(fetchRecentActivity(plate)),
  };
}

const styledRecentActivity = withStyles(styles)(RecentActivity);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(styledRecentActivity)
);
