import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  GET_CAPTCHA_SUCCESS,
  GET_CAPTCHA_FAILURE,
  VERIFY_CAPTCHA,
  AUTH_STATUS,
  USER_INPUT,
  RESET_USER_INPUT,
} from "./constants";
import Storagehandler from "../../../util/storage/";
let storageHandler = new Storagehandler();
const initialState = {
  isAuthenticated: storageHandler.isAuthenticated(),
  user: {},
  loginFailed: false,
  captchaImg: "",
  captchaText: "",
  checkStatus: "",
  authStatus: "",
  userInput: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.data,
        checkStatus: "success",
        authStatus: true,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        loggingIn: false,
        loginFailed: true,
      };
    case LOGOUT:
      return { ...initialState, isAuthenticated: false };
    case GET_CAPTCHA_SUCCESS:
      return {
        ...state,
        captchaImg: action.data.data,
        captchaText: action.data.text,
        checkStatus: "success",
        authStatus: "",
      };
    case GET_CAPTCHA_FAILURE:
      return {
        ...state,
        captchaImg: "",
        captchaText: "",
      };
    case VERIFY_CAPTCHA:
      return {
        ...state,
        checkStatus: action.data.status,
      };
    case AUTH_STATUS:
      return {
        ...state,
        authStatus: action.data.auth,
      };
    case USER_INPUT:
      return {
        ...state,
        userInput: action.data,
      };
    case RESET_USER_INPUT:
      return {
        ...state,
        userInput: "",
        checkStatus: "success",
        authStatus: "",
      };
    default:
      return state;
  }
}
