import { connect } from "react-redux";
import { withRouter } from "react-router";
import VehicleInfo from "./VehicleInfo";
import { fetchRecord } from "./data/actions";

function mapStateToProps(state) {
  return {
    record: state.vehicleInfo.record
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRecord: recordID => dispatch(fetchRecord(recordID))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VehicleInfo)
);
