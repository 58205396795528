import APIRequest from "../util/api/APIRequest";

class DashboardService {
  getAlertAndWarningCount = (params) => {
    let x = "";
    params.map((resp) => {
      x = x + resp + ",";
    });
    x = x.slice(0, -1);
    let data = {
      camera: x,
    };

    return APIRequest({
      url: "api/alert-count",
      method: "GET",
      params: data,
    });
  };

  getVehicleTypeCount = (params) => {
    let x = "";
    params.map((resp) => {
      x = x + resp + ",";
    });
    x = x.slice(0, -1);
    let data = {
      camera: x,
    };
    return APIRequest({
      url: "api/vehicle-category-count",
      method: "GET",
      params: data,
    });
  };

  getVehicleCount = (params) => {
    let x = "";
    params.map((resp) => {
      x = x + resp + ",";
    });
    x = x.slice(0, -1);
    let data = {
      camera: x,
    };
    return APIRequest({
      url: "api/vehicle-count",
      method: "GET",
      params: data,
    });
  };
}
export default DashboardService;
