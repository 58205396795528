import { push } from "connected-react-router";
import { withRouter } from "react-router";
import CameraSelector from "./cameraSelector";
import { connect } from "react-redux";
// import { snackbarActions } from "components/snackbar/data/action";
import { snackbarActions } from "../../../components/snackbar/data/action";
import { changetoAppbarPath } from "../../../components/drawer/data/actions";
import { setLocationId } from "../../locationSelector/data/action";
import {
  fetchZone,
  fetchDistrict,
  fetchField,
  fetchStateCamera,
  fetchZoneCamera,
  fetchDistrictCamera,
  fetchFieldCamera,
  getCameraData,
} from "./data/actions";

function mapStateToProps(state) {
  return {
    Zone: state.cameraReducer.zone,
    District: state.cameraReducer.district,
    Field: state.cameraReducer.field,
    CameraList: state.cameraReducer.cameraList,
    locationId: state.locationSelector.filterParams,
    status: state.cameraReducer.statuss,
    appBarPath: state.drawer.appBarPath,
  };
}

function mapDispatchToProps(dispach) {
  return {
    changetoAppbarPath: (data) => dispach(changetoAppbarPath(data)),
    fetchZone: () => dispach(fetchZone()),
    fetchDistrict: (id) => dispach(fetchDistrict(id)),
    fetchField: (id) => dispach(fetchField(id)),
    fetchStateCamera: () => dispach(fetchStateCamera()),
    fetchZoneCamera: (zone) => dispach(fetchZoneCamera(zone)),
    fetchDistrictCamera: (district) => dispach(fetchDistrictCamera(district)),
    fetchFieldCamera: (field) => dispach(fetchFieldCamera(field)),
    getCameraData: (socket, data) => dispach(getCameraData(socket, data)),
    setLocationId: (data, oldData) => dispach(setLocationId(data, oldData)),
    navigateTo: (url) => dispach(push(url)),
    showSnackbar: (message, type) =>
      dispach(snackbarActions.showSnackbar(message, type)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CameraSelector)
);
