import APIRequest from "../util/api/APIRequest";

class AlertService {
  fetchAlertList = (page, limit, type, filterParams) => {
    return APIRequest({
      url: "api/alerts/",
      method: "POST",
      params: {
        page,
        limit,
        type,
      },
      data: filterParams,
    });
  };

  fetchRecord = (id, event_timestamp) => {
    return APIRequest({
      url: `api/history/${id}`,
      method: "GET",
      params: { event_timestamp },
    });
  };
  historynextprev = (data) => {
    return APIRequest({
      url: `api/historynextprev`,
      method: "POST",
      data: data,
    });
  };
  alertnextprev = (data) => {
    return APIRequest({
      url: `api/alertsnextprev`,
      method: "POST",
      data: data,
    });
  };
}
export default AlertService;
