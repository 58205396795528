import {
  FETCH_RECORD_SUCCESS,
  FETCH_RECORD_FAILED,
  UPDATE_WARNING_SUCCESS,
  UPDATE_WARNING_FAILED,
} from "./constants";
const initialState = {
  fetchedRecord: {},
  updateStatus: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_RECORD_SUCCESS:
      return {
        ...initialState,
        updateStatus: false,
        fetchedRecord: action.data.data,
      };
    case FETCH_RECORD_FAILED:
      return {
        ...initialState,
      };
    case UPDATE_WARNING_SUCCESS:
      return {
        ...state,
        updateStatus: true,
      };
    case UPDATE_WARNING_FAILED:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
