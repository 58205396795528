import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
  TableBody,
  Paper,
  Typography,
  AppBar,
  Toolbar,
  Divider,
} from "@material-ui/core";
import { toDateTime } from "util/date";
const styles = (theme) => ({
  viewIcon: {
    marginTop: "-10px",
  },
  heading: {
    fontWeight: theme.font.fontWeight,
    color: "#000000",
    fontSize: theme.list.fontsize,
  },
  list: {
    paddingTop: "10px",
    paddingLeft: "0px",
    paddingRight: "25px",
    paddingBottum: "10px",
  },
  listItem: { paddingTop: "2px" },
  listIcon: { fontSize: theme.listIcon.fontSize },
  root: {
    minHeight: "100%",
  },
  table: {
    minWidth: "100%",
  },
  tableHeadRow: { height: "10px" },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  headColumn: {
    font: "normal normal bold 14px/43px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    paddingBottom: "2px",
    paddingTop: "2px",
    paddingRight: "1px",
    border: "1px solid #E7EBF4",
    opacity: 1,
    paddingTop: "5px",
    paddingBottom: "4.5px",
    backgroundColor: "white",
  },
  toolbar: {
    paddingRight: theme.spacing.unit,
    backgroundColor: theme.toolbar.backgroundColor,
  },
  tableTitle: {
    color: theme.toolbar.iconColor,
    fontWeight: theme.font.fontWeight,
    fontSize: theme.tableHeader.fontsize,
  },
  scroller: {
    margin: "0 auto",
    // width: "420px",
    overflow: "auto",
  },
  typo: {
    fontWeight: theme.font.fontWeight,
    fontSize: theme.tableHeader.fontsize,
  },
  toolbar: {
    padding: "2px",
    backgroundColor: theme.colors.whiteColor,
  },
  tableWrapper: {
    overflowX: "auto",
    // height: "190px",
    // paddingLeft: "23px",
    marginLeft: "23px",
    paddingRight: "23px",
    paddingBottom: "23px",

    // paddingTop: "6px",

    minHeight: "12vh",

    "@media screen and (max-width : 1280px)": {
      maxHeight: "33vh",
      overflowY: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      maxHeight: "35vh",
      overflowY: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      maxHeight: "34vh",
      overflowY: "auto",
    },
    // /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      maxHeight: "30vh",
      overflowY: "auto",
    },
    "@media screen and (min-width: 1900px)": {
      // height: "350px",
      maxHeight: "34vh",
      overflowY: "auto",
    },
    "@media screen and (min-width: 2000px)": {
      // height: "550px",
      maxHeight: "35vh",
      overflowY: "auto",
    },
  },
  tableTitle: {
    color: theme.colors.chartHeading,
    fontWeight: theme.font.fontWeight,
    textAlign: "left",
    fontWeight: "bold",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    opacity: 1,
    marginLeft: "20px",
  },
  divider: { border: "1px solid #E7EBF4", opacity: 1 },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F1F4FB",
      opacity: 1,
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0px 0px 15px #00000036",
      },
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgb(250 251 254)",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0px 0px 15px #00000036",
      },
    },
  },
  alertDetails: {
    paddingLeft: "12.5px",
    paddingBottom: "12px",
  },
  title: {
    textAlign: "left",
    font: "normal normal normal 16px/36px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
  },
  subTitle: {
    textAlign: "left",
    font: "normal normal bold 16px/17px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
  },
  firstAlertGrid: {
    paddingBottom: "24px",
    paddingLeft: "18px",
    paddingRight: "18px",
    paddingTop: "23px",
  },
});

// const CustomTableCell = withStyles((theme) => ({
//   root: {
//     minWidth: "10px",
//   },
//   head: {
//     //backgroundColor: theme.palette.common.black,
//     //color: theme.palette.common.white,
//   },
//   body: {
//     minWidth: "10px",
//     "padding-left": "10px",
//     "padding-right": "5px",
//   },
// }))(TableCell);
class RecentActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.fetchRecentActivity(this.props.plate);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.plate !== this.props.plate) {
      this.props.fetchRecentActivity(this.props.plate);
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.scroller}>
        <div className={classes.root}>
          <AppBar position="static" elevation={0}>
            <Toolbar variant="dense" className={classes.toolbar}>
              <Typography
                className={classes.tableTitle}
                variant="h6"
                id="tableTitle"
              >
                Recent Activity
              </Typography>
            </Toolbar>
          </AppBar>

          <Divider className={classes.divider} />
          {this.props.loading ? null : ( // <Skelton counts={4} />
            <div className={classes.tableWrapper}>
              <Table className={classes.table} stickyHeader>
                <TableHead>
                  <TableRow>
                    {" "}
                    <TableCell className={classes.headColumn}>
                      Location
                    </TableCell>
                    <TableCell className={classes.headColumn}>Camera</TableCell>
                    <TableCell className={classes.headColumn}>
                      Date & Time
                    </TableCell>
                    <TableCell className={classes.headColumn}>Lane</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.recentActivities.length > 0 &&
                    this.props.recentActivities.map((row, index) => {
                      return (
                        <TableRow className={classes.zebrafont} key={index}>
                          {" "}
                          <TableCell className={classes.tableCell}>
                            {row.location}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.camera_id}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {toDateTime(row.event_timestamp)}
                          </TableCell>{" "}
                          <TableCell className={classes.tableCell}>
                            {row.lane_id}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(RecentActivity);
