const tileData = [
  {
    img:
      "https://d1u4oo4rb13yy8.cloudfront.net/article/87578-jxyilybgfi-1524127120.jpg",
    title: "Camera 1",
    author: "jill111",
    featured: true
  },
  {
    img:
      "http://www.chitrasoundar.com/wp-content/uploads/2014/12/chennai_traffic.jpg",
    title: "Camera 2",
    author: "director90"
  },
  {
    img:
      "https://www.ft.com/__origami/service/image/v2/images/raw/https%3A%2F%2Fs3-ap-northeast-1.amazonaws.com%2Fpsh-ex-ftnikkei-3937bb4%2Fimages%2F4%2F2%2F8%2F3%2F2133824-7-eng-GB%2F20170301IndiaTraffic.jpg?source=nar-cms",
    title: "Camera 3",
    author: "Danson67"
  },
  {
    img:
      "http://images.newindianexpress.com/uploads/user/imagelibrary/2018/4/15/w600X300/Night.jpg",
    title: "Camera 4",
    author: "fancycrave1",
    featured: true
  },
  {
    img:
      "https://d1u4oo4rb13yy8.cloudfront.net/article/87578-jxyilybgfi-1524127120.jpg",
    title: "Camera 5",
    author: "jill111",
    featured: true
  },
  {
    img:
      "http://www.chitrasoundar.com/wp-content/uploads/2014/12/chennai_traffic.jpg",
    title: "Camera 6",
    author: "director90"
  }
  // {
  //   img:
  //     "https://www.ft.com/__origami/service/image/v2/images/raw/https%3A%2F%2Fs3-ap-northeast-1.amazonaws.com%2Fpsh-ex-ftnikkei-3937bb4%2Fimages%2F4%2F2%2F8%2F3%2F2133824-7-eng-GB%2F20170301IndiaTraffic.jpg?source=nar-cms",
  //   title: "Valayar Camera 3",
  //   author: "Danson67"
  // },
  // {
  //   img:
  //     "http://images.newindianexpress.com/uploads/user/imagelibrary/2018/4/15/w600X300/Night.jpg",
  //   title: "Valayar Camera 4",
  //   author: "fancycrave1",
  //   featured: true
  // },
  // {
  //   img:
  //     "https://d1u4oo4rb13yy8.cloudfront.net/article/87578-jxyilybgfi-1524127120.jpg",
  //   title: "Valayar Camera 1",
  //   author: "jill111",
  //   featured: true
  // },
  // {
  //   img:
  //     "http://www.chitrasoundar.com/wp-content/uploads/2014/12/chennai_traffic.jpg",
  //   title: "Valayar Camera 2",
  //   author: "director90"
  // },
  // {
  //   img:
  //     "https://www.ft.com/__origami/service/image/v2/images/raw/https%3A%2F%2Fs3-ap-northeast-1.amazonaws.com%2Fpsh-ex-ftnikkei-3937bb4%2Fimages%2F4%2F2%2F8%2F3%2F2133824-7-eng-GB%2F20170301IndiaTraffic.jpg?source=nar-cms",
  //   title: "Valayar Camera 3",
  //   author: "Danson67"
  // },
  // {
  //   img:
  //     "http://images.newindianexpress.com/uploads/user/imagelibrary/2018/4/15/w600X300/Night.jpg",
  //   title: "Valayar Camera 4",
  //   author: "fancycrave1",
  //   featured: true
  // }
];

export default tileData;
