import { connect } from "react-redux";
import { withRouter } from "react-router";
import FlammableGoodsList from "./flammableGoodsList";
import { push } from "connected-react-router";
import {
  toggleFilterWindow,
  resetFilterParams,
} from "../../components/filter_form/data/action";
import {
  fetchFlammableGoodsList,
  deleteflammableGoods,
  resetDelete,
} from "./data/action";
import { snackbarActions } from "components/snackbar/data/action";

function mapStateToProps(state) {
  return {
    flammableGoodsList: state.flammableGoodsList.flammableGoodsLists,
    flammableGoodsCount: state.flammableGoodsList.flammableGoodsCount,
    filterParams: state.filter.filterParams,
    deleteSuccessReposnse: state.flammableGoodsList.deleteSuccessMessage,
    deleteSuccess: state.flammableGoodsList.deleteSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFlammableGoodsList: (page, limit, filterParams) =>
      dispatch(fetchFlammableGoodsList(page, limit, filterParams)),
    navigateTo: (url) => dispatch(push(url)),
    toggleFilterWindow: () => dispatch(toggleFilterWindow()),
    resetFilterParams: () => dispatch(resetFilterParams()),
    deleteflammableGoods: (id) => dispatch(deleteflammableGoods(id)),
    resetDelete: () => dispatch(resetDelete()),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FlammableGoodsList)
);
