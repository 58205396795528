import React, { Fragment } from "react";
import classNames from "classnames";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  Table,
  TableBody,
  TablePagination,
  Modal,
  IconButton,
  Tooltip,
  MenuItem,
  Divider,
  InputBase,
  FormControl,
  Select,
  InputLabel,
  InputAdornment,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { ReactComponent as Edit } from "../../assets/Visual Assets/edit.svg";
import { ReactComponent as Delete } from "../../assets/Visual Assets/delete.svg";
import { ReactComponent as ClearIcon } from "../../assets/Visual Assets/clear.svg";
import { ReactComponent as SearchIcon } from "../../assets/Visual Assets/search.svg";
import messageConstants from "../../services/validator/MsgConsts/messageConstants";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import StorageHandler from "../../util/storage/index";
import Pagination from "@material-ui/lab/Pagination";
import "../userMaster/style.css";
import SkeltonScreen from "../../components/skelton_screen";
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const rows = [
  {
    id: "Sl No",
    numeric: false,
    disablepadding: true,
    label: "Sl No",
    active: true,
  },

  {
    id: "Designation",
    numeric: false,
    disablepadding: true,
    label: "Designation Name",
    active: false,
  },
];
// const tableHeaderStyles = (theme) => ({
//   tableTitle: {
//     fontWeight: theme.font.fontWeight,
//     fontSize: theme.tableheader.fontSize,
//   },
// });
const headerStyles = (theme) => ({
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 14px",
    borderColor: "#E7EBF4",
    backgroundColor: "white"
  },
  tableh: {
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 24px",
    borderColor: "#E7EBF4",
    backgroundColor: "white"
  },
});
class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.render.props.onRequestSort(event, property);
  };
  render() {
    const { order, orderBy } = this.props;
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                sortDirection={orderBy === row.id ? order : false}
                className={
                  row.active === true ? classes.tableh : classes.tableHead
                }
              >
                {/* <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  > */}
                {row.label}
                {/* </TableSortLabel>
                </Tooltip> */}
              </TableCell>
            );
          }, this)}
          {new StorageHandler().getAttributPermissions().edit_status == true ? (
            <TableCell
              className={classes.tableHead}
              style={{ textAlign: "right" }}
            >
              Edit
            </TableCell>
          ) : null}
          {new StorageHandler().getAttributPermissions().delete_status ==
            true ? (
              <TableCell
                className={classes.tableHead}
                style={{ textAlign: "right", paddingRight: "35px" }}
              >
                Delete
              </TableCell>
            ) : null}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSeleceted: PropTypes.number.isRequired,
  onRequestsort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);
const toolbarStyles = (theme) => ({});

let EnhancedTableToolbar = (props) => {
  const { classes, showFilterWindow } = props;

  return null;
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.border.radius,
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
  },
  root1: {
    backgroundColor: theme.toolbar.backgroundColor,
  },
  spacer: {
    flex: "1 1 1%",
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: "33px",
    paddingRight: "31px",
    // paddingTop: "6px",

    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      minHeight: "12vh",
      maxHeight: "45vh",
      overflowY: "auto",
    },
    // /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
  },
  butt: {
    background: "white",
  },
  actionIcon: {
    cursor: "pointer",
  },
  tableCell: {
    fontSize: theme.tableCell.fontSize,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "5px",
    paddingBottom: "4.5px",
    height: "57px",
  },
  cursor: {
    cursor: "pointer",
  },
  notAddedStyle: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,

    padding: 55,

    marginLeft: 33,
    marginRight: 33,
    marginTop: 33,
    font: "normal normal normal 24px Karla;",
  },
  notAddedStyle1: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 55,
    // marginLeft: 33,
    // marginRight: 33,
    marginTop: 33,
    font: "normal normal normal 24px Karla;",
  },
  filterbutton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.palette.secondary.main,
    color: theme.colors.whiteColor,
  },
  filterclearButton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.BlueButton,
    color: theme.colors.whiteColor,
  },
  filtercloseButton: {
    fontWeight: theme.font.fontWeight,
    color: theme.colors.blackColor,
  },

  buttons: {
    marginTop: "14px",
    marginRight: "9px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    // width: "111px",
    height: "42px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    textTransform: "none",
  },
  btn: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    "&:hover": {
      background: "#E7EBF4 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginRight: "4px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  btns: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  tableTitle: {
    marginTop: "6px",
    marginLeft: "10px",
    width: "275px",
    height: "22px",
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
  },
  grid: {
    marginTop: "21px",
    paddingTop: "22px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
    paddingBottom: "24px",
  },
  divider: {
    marginTop: "15px",
    height: "0px",
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
  filterIcon: {
    width: "25px",
    height: "23px",
    color: "#A4AFC6",
    opacity: 1,
  },
  actions1: {
    marginTop: "9px",
    paddingRight: "1px",
  },
  textStyle: {
    color: "#ffffff",
  },
  root2: {
    color: "red",
    backgroundColor: "white",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "0px solid red",
    borderRadius: "5px",
    "& .MuiInputLabel-shrink": {
      paddingLeft: "7px",
      paddingTop: "5px",
    },
    "& MuiInputLabel-formControl ": {
      // paddingLeft: "20px",
      left: "10",
    },
    "& MuiInputLabel-root ": {
      paddingLeft: "50px",
    },
    "& MuiFormLabel-root ": {
      paddingLeft: "10px",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
  textField1: {
    marginTop: "19px",
    // paddingLeft: "14px",
    // paddingTop: "12px",
    // paddingBottom: "13px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    fontSize: theme.font.size3,
    fontFamily: theme.font.family,
    fontWeight: theme.font.regular,
    letterSpacing: "0px",
    color: "#797C82",
    height: "42px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    //  border: "1px solid #C4CCDC",
    borderRadius: "5px",
    opacity: 1,
  },
  textField2: {
    marginTop: "19px",
    // paddingLeft: "14px",
    // paddingTop: "12px",
    // paddingBottom: "13px",
    // width: "178px",
    height: "40px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // border: "1px solid #C4CCDC",
    borderRadius: "5px",
    opacity: 1,
    letterSpacing: "0px",
    color: "#797C82",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    fontSize: theme.font.size3,
    fontFamily: theme.font.family,
    fontWeight: theme.font.regular,
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },
  },
  dialogBox: {
    //width: "327px",
    height: "161px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: "20px",
  },
  dialogContent: {
    //width: "247px",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    font: "normal normal normal 16px Karla",
    marginTop: "11px",
    // marginRight: "17px",
    // marginLeft: "16px",
  },
  dialogActions: {
    "&.MuiDialogActions-root": {
      justifyContent: "center",
    },
  },
  gridContainer: {
    display: "flex",
    flexDirection: "row",
  },
  paginationText: {
    // textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingTop: "35px",
    paddingLeft: "33px",
  },
});

class DesignationMasterList extends React.Component {
  constructor(props) {
    sessionStorage.setItem("designationMasterError", false);
    super(props);
    this.state = {
      order: "asc",
      orderBy: "date_time",
      selected: [],
      data: "",
      count: -1,
      modal: false,
      page: 0,
      rowsPerPage: 10,
      offendersCount: 0,
      habitualOffenderId: "",
      vehicleNumber: "",
      open: false,
      delData: {
        designation: "",
        designationId: "",
      },
      delDialogStatus: false,
      filter_type: "",
      filter_status: false,
    };
  }
  showFilterWindow = () => {
    this.props.toggleFilterWindow();
    this.props.fetchDesignationMaster();
  };

  componentDidMount() {
    this.props.fetchDesignationMaster(this.state.page, this.state.rowsPerPage);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.designationMaster != this.props.designationMaster) {
      this.setState({
        data: this.props.designationMaster,
        count: this.props.designationMaster.count,
      });
    }
    if (prevProps.filterParams !== this.props.filterParams) {
      this.props.fetchDesignationMaster(
        this.state.page,
        this.state.rowsPerPage,
        this.props.filterParams
      );
    }
    if (prevProps.deleteStatus != this.props.deleteStatus) {
      this.props.showSnackbar("Designation Deleted successfully", "success");
      this.props.fetchDesignationMaster(
        this.state.page,
        this.state.rowsPerPage
      );
      this.setState({ delDialogStatus: false });
    }
    if (prevState.page != this.state.page) {
      this.props.fetchDesignationMaster(
        this.state.page,
        this.state.rowsPerPage
      );
    }
    if (prevState.rowsPerPage != this.state.rowsPerPage) {
      this.props.fetchDesignationMaster(
        this.state.page,
        this.state.rowsPerPage
      );
    }
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleChangePage = (event, pagess) => {
    this.setState({ page: pagess - 1 });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  performAction = () => {
    this.props.navigateTo(`/designation-master/add`);
  };

  editHabitualOffender = (offender_id) => {
    this.props.navigateTo(`designation-master/edit/${offender_id}`);
  };

  handleOpen = (habitualOffenderId, vehicle_number) => {
    this.setState({
      habitualOffenderId: habitualOffenderId,
      vehicleNumber: vehicle_number,
      modal: !this.state.modal,
    });
  };

  deleteHandler = (data) => {
    this.setState(
      {
        delData: {
          ...this.state.delData,
          designation: data.designation,
          designationId: data.designationId,
        },
        delDialogStatus: true,
      },
      () => { }
    );
  };
  changeDialCloseStatus = () => {
    this.props.dialogueWindowStatus();
  };
  deleteHabitualOffender = (habitualOffenderId) => {
    this.setState({
      modal: !this.state.modal,
    });
    this.props.deleteHabitualOffender(habitualOffenderId);
  };
  editDesignation = (id) => {
    this.props.navigateTo(`designation-master/add/${id}`);
  };
  deleteDesignation = (id) => {
    alert(id);
  };
  onFilterChange = () => {
    // this.props.onFilterChange(this.state.filter_type);
    this.setState({ filter_status: true });
    this.props.toggleFilterWindow();
  };
  componentWillUnmount() {
    sessionStorage.setItem("designationMasterError", false);
  }
  render() {
    const { classes, showFilterWindow } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, this.state.CountofOffenders - page * rowsPerPage);
    let firstPageNum = page * rowsPerPage + 1;
    return (
      <>
        <Grid className={classes.grid}>
          <Paper className={classes.paper}>
            <Toolbar className={classNames(classes.roots)}>
              <Typography
                variant="h6"
                id="tableTitle"
                className={classes.tableTitle}
              >
                Designation Master
              </Typography>
              <div className={classes.spacer} />
              {/* <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={4}></Grid>
              </Grid> */}
              {/* <div className={classes.actions1}></div> */}
              <div className={classes.actions}>
                {new StorageHandler().getAttributPermissions() &&
                  new StorageHandler().getAttributPermissions().add_status ==
                  true ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.buttons}
                      disableElevation={true}
                      onClick={() => this.performAction()}
                    >
                      Add Designation
                    </Button>
                  ) : null}
              </div>
            </Toolbar>
            <Divider className={classes.divider} />

            {/* {this.state.count === 0 ? (
              <div className={classes.notAddedStyle}>NOT YET ADDED</div>
            ) : ( */}
            {/* 
            {this.state.count === 0 ? (
              this.props.loading ? (
                <SkeltonScreen />
              ) : (
                <div className={classes.notAddedStyle}>
                  {messageConstants.NOT_ADDED}
                </div>
              )
              
            ) : ( */}
            {this.props.loading === false && this.state.count == 0 ? (
              <div className={classes.notAddedStyle}>
                {messageConstants.NOT_ADDED}
              </div>
            ) : (
                // ) : (
                <>
                  <div className={classes.tableWrapper}>
                    {this.state.data.rows && this.state.data.rows.length > 0 ? (
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        stickyHeader
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={this.handleRequestSort}
                          rowCount={this.state.CountofOffenders}
                        />

                        <TableBody>
                          {this.state.data !== ""
                            ? this.state.data.rows.map((n, i) => {
                              const isSelected = this.isSelected(n.id);
                              return (
                                <TableRow
                                  // hover
                                  role="checkbox"
                                  aria-checked={isSelected}
                                  tabIndex={-1}
                                  key={n.id}
                                  selected={isSelected}
                                  className={classes.zebrafont}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{ paddingLeft: "32px" }}
                                    className={classes.tableCell}
                                  >
                                    {firstPageNum + i}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCell}
                                    align="center"
                                  >
                                    {n.designation}
                                  </TableCell>
                                  {new StorageHandler().getAttributPermissions() &&
                                    new StorageHandler().getAttributPermissions()
                                      .edit_status == true ? (
                                      <TableCell
                                        className={classes.tableCell}
                                        style={{ textAlign: "right" }}
                                      >
                                        <i
                                          onClick={() =>
                                            this.editDesignation(n.designationId)
                                          }
                                        >
                                          <Edit className={classes.actionIcon} />
                                        </i>
                                      </TableCell>
                                    ) : null}
                                  {new StorageHandler().getAttributPermissions() &&
                                    new StorageHandler().getAttributPermissions()
                                      .delete_status == true ? (
                                      <TableCell
                                        className={classes.tableCell}
                                        style={{
                                          // paddingLeft: "25px",
                                          paddingRight: "50px",
                                          textAlign: "right",
                                        }}
                                      >
                                        <i onClick={() => this.deleteHandler(n)}>
                                          <Delete
                                            className={classes.actionIcon}
                                          />
                                        </i>
                                      </TableCell>
                                    ) : null}
                                </TableRow>
                              );
                            })
                            : null}
                        </TableBody>
                      </Table>
                    ) : sessionStorage.getItem("designationMasterError") ===
                      "true" ? (
                          <div className={classes.notAddedStyle1}>
                            {messageConstants.NOT_ADDED}
                          </div>
                        ) : (
                          <SkeltonScreen />
                        )}
                  </div>
                  {this.state.data.rows && this.state.data.rows.length > 0 ? (
                    <Grid container xs={12}>
                      <Grid item xs={4}>
                        <Typography className={classes.paginationText}>
                          {parseInt(page) * rowsPerPage +
                            1 +
                            " - " +
                            (parseInt(page) * rowsPerPage + 10 > this.state.count
                              ? this.state.count
                              : parseInt(page) * rowsPerPage + 10) +
                            " of " +
                            +this.state.count +
                            " Designations"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{ float: "right", paddingRight: "43px" }}
                        xs={8}
                      >
                        <Pagination
                          count={Math.ceil(this.state.count / rowsPerPage)}
                          shape="rounded"
                          page={page + 1}
                          onChange={this.handleChangePage}
                          className="pagination"
                          color="secondary"
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </>
              )}
            {/* <TablePagination
              component="div"
              count={this.state.count}
              rowsPerPageOptions={[5, 10, 15]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            /> */}

            <Dialog
              aria-labelledby="alert-dialog-title"
              aria-labelledby="draggable-dialog-title"
              PaperComponent={Paper}
              classes={{
                paper: classes.dialogBox,
              }}
              open={this.state.delDialogStatus}
            >
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  className={classes.dialogContent}
                >
                  Are you sure you want to delete
                  {this.state.delData.designation}?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btn}
                  disableElevation={true}
                  onClick={() => this.setState({ delDialogStatus: false })}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btns}
                  disableElevation={true}
                  onClick={() =>
                    this.props.deleteDesignationMaster(
                      this.state.delData.designationId
                    )
                  }
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </>
    );
  }
}

DesignationMasterList.propTypes = {
  classes: PropTypes.object.isRequired,
};
// function mapStateToProps(state) {
//   return {};
// }
// function mapDispatchToProps(dispach) {
//   return {
//     navigateTo: (url) => dispach(push(url)),
//   };
// }
const styledDesignationMaster = withStyles(styles)(DesignationMasterList);

export default withStyles(styles)(DesignationMasterList);
