import APIRequest from "../util/api/APIRequest";

class FilterService {
  fetchLane = (type) => {
    return APIRequest({
      url: "api/combo/",
      params: { type },
      method: "GET",
    });
  };
  fetchCamera = (type) => {
    return APIRequest({
      // url: "api/combo/",
      url: "api/socket/camera",
      //params: { type },
      method: "GET",
    });
  };
  fetchVehicle = (type) => {
    return APIRequest({
      url: "api/combo/",
      params: { type },
      method: "GET",
    });
  };
  fetchDesignationList = () => {
    return APIRequest({
      url: "/api/designations",
      // params: { type },
      method: "GET",
    });
  };
  // fetchOfficeList = () => {
  //   return APIRequest({
  //     url: "/api/offices",
  //     // params: { type },
  //     method: "GET",
  //   });
  // };

  fetchOfficeList = (id) => {
    return APIRequest({
      url: `api/offices/location/${id}`,
      method: "GET",
    });
  };
  fetchZone = () => {
    return APIRequest({
      url: "/api/zones",
      method: "GET",
    });
  };

  fetchDistricts = (params) => {
    // console.log("enters service dist");
    return APIRequest({
      url: `api/districts`,
      method: "GET",
    });
  };
  fetchField = (id) => {
    return APIRequest({
      url: `api/fieldList`,
      params: { id },
      method: "GET",
    });
  };
  getZone = (type) => {
    return APIRequest({
      url: `api/dropdown/permision_location`,
      params: { type },
      method: "GET",
    });
  };
  getDistrict = (type, zone_id) => {
    return APIRequest({
      url: `api/dropdown/permision_location`,
      params: { type, zone_id },
      method: "GET",
    });
  };
  getField = (type, district_id) => {
    return APIRequest({
      url: `api/dropdown/permision_location`,
      params: { type, district_id },
      method: "GET",
    });
  };
  fetchUserList = () => {};
  fetchRole = () => {};
  fetchStatus = () => {};
  fetchDistrict = () => {};
  fetchSquadName = () => {};
}

export default FilterService;
