export const FETCH_OFFICE_LIST = "FETCH_OFFICE_LIST";
export const DELETE_OFFICE = "DELETE_OFFICE";
export const RESET_DELETE = "RESET_DELETE";
export const FETCH_OFFICE_LIST_ERROR = "FETCH_OFFICE_LIST_ERROR ";
export const DELETE_OFFICE_ERROR = "DELETE_OFFICE_ERROR";
export const FETCH_ZONE = "FETCH_ZONE";
export const FETCH_ZONE_FAILED = "FETCH_ZONE_FAILED";
export const FETCH_DISTRICT = "FETCH_DISTRICT";
export const FETCH_DISTRICT_FAILED = "FETCH_DISTRICT_FAILED";
export const FETCH_OFFICE_DETAILS = "FETCH_USER_DETAILS";
export const FETCH_OFFICE_DETAILS_FAILED = "FETCH_USER_DETAILS_FAILED";

export const DIALOGUE_WINDOW_STATUS = "DIALOGUE_WINDOW_STATUS";
export const FILTER_CHANGE = "FILTER_CHANGE";
export const FILTER_CHANGE_ERROR = "FILTER_CHANGE_ERROR";
