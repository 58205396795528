//import LoginBgWeb from "../login/images/login.png";
//import LoginBgMobile from "../../assets/images/loginBg-mobile.png";
import LoginBgWeb from "../../assets/SvgIcons/login_illustration.svg";

const styles = (theme) => ({
  head: {
    paddingTop: "97px",
    paddingLeft: "79px",
    /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      paddingTop: "15vh",
      paddingLeft: "15vh",
    },
  },
  heading1: {
    color: "#1BB99A",
    width: "85px",
    height: "38px",
    fontSize: "33px",
    fontWeight: "bold",
    fontFamily: "Karla",
  },
  heading2: {
    color: "#2B3D51",
    width: "169px",
    height: "32px",
    fontSize: "27px",
    fontWeight: "bold",
    fontFamily: "Karla",
  },
  dilog: {
    paddingTop: "18px",
    color: "#2B3D51",
    width: "380px",
    height: "74px",
    fontWeight: "normal",
    fontSize: "16px",
    opacity: "100%",
    fontFamily: "Karla",
  },
  rcp: {
    marginTop: "-2px",
    paddingLeft: "70px",
    alignItems: "center",
    transform: "scale(.8)",
    width: "237px",
    // height: "58px",
    fontFamily: "Karla",
  },

  root2: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#C4CCDC",
        borderRadius: "8px",
      },
    },
    "& .MuiInputBase-input": {
      color: "#A4AFC6",
      fontFamily: "Karla",
    },
  },

  root: {
    flexGrow: 1,
    // height: "100%",
  },
  root3: {
    width: "1366px",
    height: "1080px",
  },

  poweredByLogoStyle: {
    width: "30px",
    height: "19px",
    // display: "block",
  },
  poweredByStyle: {
    // 1366 x 614
    // paddingBottom: "31px",
    // paddingLeft: "124px",
    // position: "absolute",
    // top: "auto",
    // bottom: 0,
    // width: "150px",

    width: "362px",
    color: "#A7B1C7",
    fontSize: "14px",
    fontFamily: "Karla",
    textAlign: "center",
  },

  secondaryAction: {
    width: "100%",
    marginBottom: theme.spacing.unit * 10,
  },

  formStyle: {
    // paddingTop: "5%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing.unit * 100,
    },
  },
  submitButtonStyle: {
    // marginLeft: theme.spacing.unit * 15,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: theme.palette.secondary.main,
    color: theme.colors.whiteColor,
    fontWeight: theme.font.fontWeight,
  },
  textColor: {
    color: theme.colors.whiteColor,
  },
  rightGridColor: {
    // backgroundColor: theme.colors.whiteColor,
    height: "100%",
    color: theme.colors.whiteColor,
    // backgroundColor: theme.colors.loginDivColor,
    backgroundSize: "cover",
    background: `url(${LoginBgWeb}) no-repeat`,
    [theme.breakpoints.up("md")]: {
      backgroundSize: "cover",
      //background: `url(${LoginBgWeb}) no-repeat`,
      // backgroundColor: theme.colors.loginDivColor,
      // height: "100vh",
      height: "100vh",

      width: "100%",
    },
  },
  visibilityColor: {
    color: theme.visibilityIcon.color,
  },
  formStyle: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing.unit * 10,
    },
  },
  inputField: {
    width: "362px",
    height: "50px",
    fontFamily: "Karla",
    paddingTop: "50px",

    /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      paddingTop: "90px",
    },
  },
  inputField_2: {
    width: "362px",
    height: "50px",
    fontFamily: "Karla",
    paddingTop: "7px",
  },

  captchaContainer: {
    marginTop: "15px",
    marginBottom: "4px",
  },
  svgImg: {
    height: "50px",
    backgroundColor: "#e0e0e080",
    marginLeft: "11px",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    width: "160px",
    "@media screen and (max-width : 1280px)": {
      maxWidth: "149px",
    },
    "@media screen and (min-width : 1280px)": {
      width: "180px",
    },
    "@media screen and (min-width: 1900px)": {
      width: "110px",
    },
  },
  resetGrid: {
    marginLeft: "6px",
    "@media screen and (max-width : 1280px)": {
      marginLeft: "75px",
    },
    "@media screen and (min-width : 1280px)": { marginLeft: "32px" },
    "@media screen and (min-width: 1366px)": { marginLeft: "20px" },
    "@media screen and (min-width: 1900px)": {
      marginLeft: "-85px",
    },
  },
  resetButton: {
    marginTop: "5px",
    paddingRight: "16px",
  },
  filterIcon: {
    width: "25px",
    height: "23px",
    color: "#A4AFC6",
    opacity: 1,
  },
  textField1: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    opacity: 1,
    height: "53px",
    width: "160px",
    "@media screen and (max-width : 1280px)": {
      width: "180px",
    },
    "@media screen and (min-width : 1280px)": {
      width: "180px",
    },
    "@media screen and (min-width: 1900px)": {
      width: "110px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#C4CCDC",
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "5px",
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
        height: "53px",
      },
    },
    "& .MuiInputBase-input": {
      color: "#A4AFC6",
      fontFamily: "Karla",
    },
  },
  formHelperText: {
    color: theme.colors.redColor,
  },
});

export default styles;
