import {
  FETCH_OVERALL_REPORT_DATA_SUCCESS,
  FETCH_OVERALL_REPORT_DATA_FAILED,
  RESET_OVERALL_REPORT_DATA,
} from "./constants";
const initialState = {
  reportData: {},
  isGenerate: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OVERALL_REPORT_DATA_SUCCESS:
      return {
        ...state,
        reportData: action.data.data,
      };
    case FETCH_OVERALL_REPORT_DATA_FAILED:
      return {
        ...state,
        reportData: {},
      };
    case RESET_OVERALL_REPORT_DATA:
      return {
        ...state,
        reportData: {},
      };
    default:
      return state;
  }
}
