import APIRequest from "../util/api/APIRequest";

class OfficeMasterService {
  addOffice = (params) => {
    return APIRequest({
      url: `/api/offices`,
      data: params,
      method: "POST",
    });
  };

  fetchOfficeList = (page, limit) => {
    return APIRequest({
      url: `/api/offices`,
      params: {
        page,
        limit,
      },
      method: "GET",
    });
  };

  fetchOfficeDeatils = (id) => {
    return APIRequest({
      url: `/api/offices/${id}`,
      method: "GET",
    });
  };

  editOffice = (params, id) => {
    return APIRequest({
      url: `/api/offices/${id}`,
      data: params,
      method: "PUT",
    });
  };

  deleteOffice = (id) => {
    return APIRequest({
      url: `/api/offices/${id}`,
      method: "DELETE",
    });
  };

  fetchZone = () => {
    return APIRequest({
      url: "/api/zones",
      method: "GET",
    });
  };

  fetchDistrict = (id) => {
    return APIRequest({
      url: `api/districtList`,
      params: { id },
      method: "GET",
    });
  };
  fetchDistricts = (params) => {
    if (params == "") {
      return APIRequest({
        url: `api/districts`,
        data: params,
        method: "GET",
      });
    } else {
      return APIRequest({
        url: `api/districtList`,
        params: { id: params },
        method: "GET",
      });
    }
  };

  fetchField = (id) => {
    return APIRequest({
      url: `api/fieldList`,
      params: { id },
      method: "GET",
    });
  };

  onFilterChange = (params) => {
    return APIRequest({
      url: `api/offices`,
      params: params,
      method: "GET",
    });
  };
}

export default OfficeMasterService;
// /api/offices?page=0&limit=2&location=1&zone=2&district=2
