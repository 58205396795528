import {
  FETCH_REVIEW_LIST,
  FETCH_REVIEW_LIST_ERROR,
  SET_WARNING_PAGE,
  RESET_WARNING_PAGE,
  RESET_ERROR_PAGE,
} from "./constants";

const initialState = {
  warningList: [],
  warningCount: 0,
  rowsPerPage: 10,
  page: 0,
  error: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_REVIEW_LIST:
      return {
        ...state,
        warningList: action.data.data,
        warningCount: action.data.recordCount,
      };
    case FETCH_REVIEW_LIST_ERROR:
      return {
        ...initialState,
        error: true,
      };

    case SET_WARNING_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case RESET_WARNING_PAGE:
      return {
        ...state,
        page: 0,
      };
    case RESET_ERROR_PAGE:
      return {
        ...state,
        error: false,
      };
    default:
      return state;
  }
}
