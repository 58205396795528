import {
  FETCH_ZONE,
  FETCH_ZONE_FAILED,
  FETCH_DISTRICT,
  FETCH_DISTRICT_FAILED,
  FETCH_FIELD,
  FETCH_FIELD_FAILED,
  FETCH_ZONE_CAMERA,
  FETCH_ZONE_CAMERA_FAILED,
  FETCH_FIELD_CAMERA,
  FETCH_FIELD_CAMERA_FAILED,
  GET_CAMERA_DATA,
  FETCH_STATE_CAMERA,
  FETCH_STATE_CAMERA_FAILED,
} from "./constants";
import SelectCameraService from "../../../../service/SelectCameraService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchZone = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SelectCameraService().fetchZone().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_ZONE, data };
  }

  function failure(error) {
    return { type: FETCH_ZONE_FAILED, error };
  }
};

export const fetchDistrict = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SelectCameraService().fetchDistrict(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_DISTRICT, data };
  }

  function failure(error) {
    return { type: FETCH_DISTRICT_FAILED, error };
  }
};

export const fetchField = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SelectCameraService().fetchField(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_FIELD, data };
  }

  function failure(error) {
    return { type: FETCH_FIELD_FAILED, error };
  }
};

export const fetchStateCamera = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SelectCameraService().fetchStateCamera().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_STATE_CAMERA, data };
  }

  function failure(error) {
    return { type: FETCH_STATE_CAMERA_FAILED, error };
  }
};

export const fetchZoneCamera = (zone) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SelectCameraService().fetchZoneCamera(zone).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_ZONE_CAMERA, data };
  }

  function failure(error) {
    return { type: FETCH_ZONE_CAMERA_FAILED, error };
  }
};

export const fetchDistrictCamera = (district) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SelectCameraService().fetchDistrictCamera(district).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_ZONE_CAMERA, data };
  }

  function failure(error) {
    return { type: FETCH_ZONE_CAMERA_FAILED, error };
  }
};

export const fetchFieldCamera = (zone) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SelectCameraService().fetchFieldCamera(zone).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_FIELD_CAMERA, data };
  }

  function failure(error) {
    return { type: FETCH_FIELD_CAMERA_FAILED, error };
  }
};

export const getCameraData = (socket, data) => {
  return (dispatch) => {
    if (data !== "") {
      socket.emit("subscribe", data);
    }
    // socket.on("message", (res) => {
    //   const content = JSON.parse(res);
    //   if (content.overview != undefined) {
    //     dispatch(vehicle(content.overview));
    //   }
    // });
  };
  function vehicle(res) {
    return { type: GET_CAMERA_DATA, data: res };
  }
};
