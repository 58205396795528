/** @format */

export const FETCH_USER_TYPE = "FETCH_USER_TYPE";
export const FETCH_USER_TYPE_ERROR = "FETCH_USER_TYPE_ERROR";
export const FETCH_FEATURES = "FETCH_FEATURES";
export const FETCH_FEATURES_ERROR = "FETCH_FEATURES_ERROR";
export const ADD_ROLE_MASTER = "ADD_ROLE_MASTER";
export const ADD_ROLE_MASTER_ERROR = "ADD_ROLE_MASTER_ERROR";
export const FETCH_ROLE_MASTER_LIST = "FETCH_ROLE_MASTER_LIST";
export const FETCH_ROLE_MASTER_LIST_ERROR = "FETCH_ROLE_MASTER_LIST_ERROR";
export const FETCH_PERMISSION_LIST = "FETCH_PERMISSION_LIST";
export const RESET_PERMISSION_LIST = "RESET_PERMISSION_LIST";
export const RESET_PERMISSION_LIST_ERROR = "RESET_PERMISSION_LIST_ERROR";
export const FETCH_PERMISSION_LIST_ERROR = "FETCH_PERMISSION_LIST_ERROR";
export const DELETE_ROLE_MASTER = "DELETE_ROLE_MASTER";
export const DELETE_ROLE_MASTER_ERROR = "DELETE_ROLE_MASTER_ERROR";
export const UPDATE_ROLE_MASTER = "UPDATE_ROLE_MASTER";
export const UPDATE_ROLE_MASTER_ERROR = "UPDATE_ROLE_MASTER_ERROR";
export const FILTER_CHANGE = "FILTER_CHANGE";
export const FILTER_CHANGE_ERROR = "FILTER_CHANGE_ERROR";
export const RESET_FEATURES = "RESET_FEATURES";
export const RESET_USER_TYPE_LIST = "RESET_USER_TYPE_LIST";
export const FETCH_ROLE_STATUS = "FETCH_ROLE_STATUS";
export const FETCH_ROLE_STATUS_ERROR = "FETCH_ROLE_STATUS_ERROR";
export const RESET_DELETE_STATUS = "RESET_DELETE_STATUS";
export const RESET_ROLE_STATUS = "RESET_ROLE_STATUS";
