import { connect } from "react-redux";
import { withRouter } from "react-router";
import permissions from "./viewPermissions";
import { fetchPermissionList } from "../../addRole/data/action";
import { push } from "connected-react-router";
import { toggleViewWindow } from "../data/action";
function mapStateToProps(state) {
  return {
    permissionList: state.permissionList,
    viewWindowStatus: state.viewWindowStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPermissionList: (id) => dispatch(fetchPermissionList(id)),
    navigateTo: (url) => dispatch(push(url)),
    toggleViewWindow: () => dispatch(toggleViewWindow()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(permissions)
);
