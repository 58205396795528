import React, { Fragment } from "react";
import classNames from "classnames";
import {
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  Table,
  TableBody,
  TablePagination,
  Modal,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Edit from "../../assets/SvgIcons/edit";
import Delete from "../../assets/SvgIcons/delete";
import messageConstants from "../../services/validator/MsgConsts/messageConstants";
// import { confirmAlert } from "react-confirm-alert";
// import "react-confirm-alert/src/react-confirm-alert.css";
import StorageHandler from "../../util/storage/";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const rows = [
  {
    id: "slno",
    numeric: false,
    disablepadding: true,
    label: "#",
  },

  {
    id: "latitude",
    numeric: false,
    disablepadding: true,
    label: "Vehicle Number",
  },
  { id: "longitude", numeric: false, disablepadding: true, label: "Status" },
];

const headerStyles = (theme) => ({
  tableHead: {
    fontSize: theme.tableHeader.fontsize,
    fontWeight: theme.font.fontWeight,
  },
});

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.render.props.onRequestSort(event, property);
  };
  render() {
    const { order, orderBy, classes } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                sortDirection={orderBy === row.id ? order : false}
                className={classes.tableHead}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
          {new StorageHandler().getAttributPermissions() &&
          new StorageHandler().getAttributPermissions().edit_status == true ? (
            <TableCell className={classes.tableHead}>Edit</TableCell>
          ) : null}
          {new StorageHandler().getAttributPermissions() &&
          new StorageHandler().getAttributPermissions().delete_status ==
            true ? (
            <TableCell className={classes.tableHead}>Delete</TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSeleceted: PropTypes.number.isRequired,
  onRequestsort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    backgroundColor: theme.toolbar.backgroundColor,
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    color: theme.toolbar.iconColor,
    fontWeight: theme.font.fontWeight,
  },
});

let EnhancedTableToolbar = (props) => {
  const { classes } = props;

  return (
    <Toolbar className={classNames(classes.root)}>
      <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
        Evasion Prone Commodity Goods Vehicle Master
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  notAddedStyle: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    margin: 15,
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 50,
    fontWeight: theme.font.fontWeight,
  },
  buttons: {
    marginLeft: "87%",
    marginTop: "2%",
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.palette.secondary.main,
    color: theme.colors.whiteColor,
  },
  btn: {
    marginLeft: "61%",
    marginTop: theme.spacing.unit,
  },
  btns: {
    marginLeft: "79%",
    marginTop: "-14%",
    // marginLeft: theme.spacing.unit,

    // marginTop: theme.spacing.unit
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  tableCell: {
    fontWeight: theme.font.fontWeight,
    fontSize: theme.tableCell.fontsize,
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
});

class FlammableGoodsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "date_time",
      selected: [],
      data: [],
      modal: false,
      page: 0,
      rowsPerPage: 10,
      offendersCount: 0,
      flammableGoosdId: "",
      vehicleNumber: "",
      open: false,
    };
  }

  componentDidMount() {
    this.props.resetFilterParams();
    this.props.fetchFlammableGoodsList(
      this.state.page,
      this.state.rowsPerPage,
      this.props.filterParams
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.flammableGoodsList != this.props.flammableGoodsList) {
      this.setState({
        data: this.props.flammableGoodsList,
        offendersCount: this.props.flammableGoodsCount,
      });
    }
    if (prevState.page != this.state.page) {
      this.props.fetchFlammableGoodsList(
        this.state.page,
        this.state.rowsPerPage,
        this.props.filterParams
      );
    }
    if (prevState.rowsPerPage != this.state.rowsPerPage) {
      this.props.fetchFlammableGoodsList(
        this.state.page,
        this.state.rowsPerPage,
        this.props.filterParams
      );
    }
    if (this.props.deleteSuccess) {
      this.props.resetDelete();
      let params = {
        Page: this.state.page,
        rowsPerPage: this.state.rowsPerPage,
        filterParams: this.state.filterParams,
      };

      this.props.fetchFlammableGoodsList(
        this.state.page,
        this.state.rowsPerPage,
        this.state.filterParams
      );
      this.props.showSnackbar(
        this.props.deleteSuccessReposnse.message,
        "success"
      );
    }
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  performAction = () => {
    this.props.navigateTo(`flammableGoods-master/add`);
  };

  editFlammableGoods = (offender_id) => {
    this.props.navigateTo(`flammableGoods-master/edit/${offender_id}`);
  };

  handleOpen = (flammableGoosdId, vehicle_number) => {
    this.setState({
      flammableGoosdId: flammableGoosdId,
      vehicleNumber: vehicle_number,
      modal: !this.state.modal,
    });
  };

  deleteflammableGoods = (flammableGoosdId) => {
    this.setState({
      modal: !this.state.modal,
    });
    this.props.deleteflammableGoods(flammableGoosdId);
  };

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, this.state.offendersCount - page * rowsPerPage);
    let firstPageNum = page * rowsPerPage + 1;

    return (
      <Fragment>
        <Paper className={classes.root}>
          <EnhancedTableToolbar />

          <Grid item spacing={16}>
            {new StorageHandler().getAttributPermissions() &&
            new StorageHandler().getAttributPermissions().add_status == true ? (
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttons}
                onClick={() => this.performAction()}
              >
                ADD GOODS
              </Button>
            ) : null}
          </Grid>

          {this.state.offendersCount === 0 ? (
            <div className={classes.notAddedStyle}>
              {messageConstants.NOT_ADDED}
            </div>
          ) : (
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.offendersCount}
                />
                <TableBody>
                  {data.map((n, i) => {
                    const isSelected = this.isSelected(n.id);
                    return (
                      <TableRow
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                        className={classes.zebrafont}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableCell}
                        >
                          {firstPageNum + i}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {n.vehicle_number}
                        </TableCell>
                        {n.active == true ? (
                          <TableCell className={classes.tableCell}>
                            Active
                          </TableCell>
                        ) : (
                          <TableCell className={classes.tableCell}>
                            Inactive
                          </TableCell>
                        )}
                        {new StorageHandler().getAttributPermissions() &&
                        new StorageHandler().getAttributPermissions()
                          .edit_status == true ? (
                          <TableCell>
                            <i onClick={() => this.editFlammableGoods(n.id)}>
                              <Edit />
                            </i>
                          </TableCell>
                        ) : null}
                        {new StorageHandler().getAttributPermissions() &&
                        new StorageHandler().getAttributPermissions()
                          .delete_status == true ? (
                          <TableCell>
                            <i
                              onClick={() =>
                                this.handleOpen(n.id, n.vehicle_number)
                              }
                            >
                              <Delete />
                            </i>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          )}
          <TablePagination
            component="div"
            count={this.state.offendersCount}
            rowsPerPageOptions={[5, 10, 15]}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.modal}
          >
            <div style={getModalStyle()} className={classes.paper}>
              <Typography variant="subtitle1" id="simple-modal-description">
                Are you sure you want to delete {this.state.vehicleNumber}?{" "}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                className={classes.btn}
                onClick={() =>
                  this.deleteflammableGoods(this.state.flammableGoosdId)
                }
              >
                OK
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.btns}
                onClick={() => this.handleOpen()}
              >
                Cancel
              </Button>
            </div>
          </Modal>
        </Paper>
      </Fragment>
    );
  }
}

FlammableGoodsList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(FlammableGoodsList);
