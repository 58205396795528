export const FETCH_REPORT_DATA_SUCCESS = "FETCH_REPORT_DATA_SUCCESS";
export const FETCH_REPORT_DATA_FAILED = "FETCH_REPORT_DATA_FAILED";
export const RESET_REPORT_DATA = "RESET_REPORT_DATA";

export const EXPORT_REPORT_SUCCESS = "EXPORT_REPORT_SUCCESS";

export const FETCH_ALERT_FAILED = " FETCH_ALERT_FAILED";
export const FETCH_ALERT = " FETCH_ALERT";

export const FETCH_SQUAD_FAILED = "FETCH_SQUAD_FAILED";
export const FETCH_SQUAD = "FETCH_SQUAD";

export const FETCH_OFFICER = "FETCH_OFFICER";
export const FETCH_OFFICER_FAILED = "FETCH_OFFICER_FAILED";

export const IS_GENERATE = "IS_GENERATE";
export const RESET_IS_GENERATE = "RESET_IS_GENERATE";
