import {
  FETCH_SQUAD_LIST,
  FETCH_SQUAD_LIST_ERROR,
  DELETE_SQUAD,
  DELETE_SQUAD_ERROR,
  RESET_DELETE,
  FETCH_DISTRICT,
  FETCH_DISTRICT_FAILED,
  DIALOGUE_WINDOW_STATUS,
  FILTER_CHANGE,
  FILTER_CHANGE_ERROR,
} from "./constants";

const initialState = {
  squadList: [],
  district: [],
  squadListFilter: [],
  squadCount: 0,
  deleteSuccess: false,
  deleteSuccessMessage: "",
  showDialogue: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SQUAD_LIST:
      return {
        ...state,
        squadList: action.data.data.rows,
        squadCount: action.data.data.count,
      };
    case FETCH_SQUAD_LIST_ERROR:
      sessionStorage.setItem("squadMasterError", true);
      return {
        ...initialState,
      };
    case DELETE_SQUAD:
      return {
        ...initialState,
        deleteSuccessMessage: action.data,
        error: action.data.message,
        deleteSuccess: true,
      };
    case DELETE_SQUAD_ERROR:
      return {
        ...initialState,
      };
    case RESET_DELETE:
      return {
        ...initialState,
        deleteSuccess: false,
      };
    case FETCH_DISTRICT:
      return {
        ...state,
        district: action.data.data,
      };
    case FETCH_DISTRICT_FAILED:
      return {
        ...state,
        district: [],
      };
    case DIALOGUE_WINDOW_STATUS:
      return {
        ...state,
        showDialogue: !state.showDialogue,
      };
    case FILTER_CHANGE:
      return {
        ...state,
        squadListFilter: action.data.data,
      };
    case FILTER_CHANGE_ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
}
