/** @format */

import React, { Component, Fragment } from "react";
import {
  Paper,
  Typography,
  withStyles,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Toolbar,
  Tooltip,
  Divider,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import { ReactComponent as DownloadIcon } from "../../assets/Visual Assets/download.svg";
import { ReactComponent as FilterListIcon } from "../../assets/Visual Assets/filter.svg";
import messageConstants from "../../services/validator/MsgConsts/messageConstants";
import ActionSummaryFilter from "../actionsummaryReport/components/reportFilter/index";
import OverallActionSummaryReport from "../actionsummaryReport/components/overallActionSummary/index";
import Skelton from "../actionsummaryReport/components/skeletonscreen1/index";
const styles = (theme) => ({
  root: {
    padding: "12px 35px",
    paddingRight: "16px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    marginTop: "43px",
    marginRight: "18px",
    marginLeft: "22px",
    borderTopLeftRadius: theme.border.radius,
    borderTopRightRadius: theme.border.radius,
  },
  spacer: {
    flex: "1 1 20%",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
  },
  container: {
    // padding: theme.spacing.unit,
    // overflow: "auto",
    // minWidth: "-webkit-fill-available",
    // minHeight: "48vh",
    // maxWidth: "70vw",

    overflow: "auto",
    marginLeft: "7px",
    marginRight: "8px",
    minHeight: "22vh",
    overflowY: "auto",
    "@media screen and (min-width: 1900px)": {
      maxHeight: "57vh",
    },
    "@media screen and (max-width : 1280px)": {
      maxHeight: "36vh",
    },
    // /* devices (1280px) */
    "@media screen and (width : 1280px)": {
      maxHeight: "50vh",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      maxHeight: "36vh",
    },
    "@media screen and (min-width: 1366px) and (max-width : 1900px)": {
      maxHeight: "23vh",
    },
  },
  reportTitle: {
    height: "19px",
    display: "flex",
    font: "normal normal bold 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    textAlign: "left",
    paddingLeft: "5px",
    paddingTop: "15.5px",
  },
  dateTitle: {
    height: "19px",
    display: "flex",
    font: "normal normal bold 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    textAlign: "left",
    paddingTop: "15.5px",
  },
  emptyReport: {
    maxHeight: "40vh",
    overflowY: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    font: "normal normal bold 34px Karla;",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    "@media screen and (min-width: 1900px)": {
      minHeight: "50vh",
    },
    "@media screen and (max-width : 1280px)": {
      minHeight: "36vh",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "50vh",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "36vh",
    },
    "@media screen and (min-width: 1366px) and (max-width : 1900px)": {
      minHeight: "22vh",
    },
  },
  filterIcon: {
    color: "#A4AFC6",
  },
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px Karla;",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    paddingTop: "12px",
    paddingBottom: "13px",
    //whiteSpace: "nowrap",
    overflow: "hidden",
    backgroundColor: "white",
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    padding: "20px 14px",
    border: "0px",
    whiteSpace: "nowrap",
    overFlow: "hidden",
    height: "57px",
  },
  divider: {
    marginRight: "18px",
    marginLeft: "22px",
    backgroundColor: "#E7EBF4",
  },
  tabledivider: {
    border: "1px solid #E7EBF4",
    opacity: 1,
    marginTop: "38.5px",
    marginLeft: "7px",
    marginRight: "9px",
  },

  reportPaper: {
    // padding: "10px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    paddingLeft: "26px",
    paddingRight: "23px",
    marginLeft: "22px",
    marginRight: "18px",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
    paddingBottom: "24px",
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
});
class ActionSummaryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportDatas: {},
      reportData: {},
      reportFilterParams: {},
      fromDate: moment(new Date().setDate(new Date().getDate() - 7)).format(
        "DD-MM-YYYY"
      ),
      toDate: moment(new Date()).format("DD-MM-YYYY"),
      showReportFilterWindow: false,
      data: {
        generateParams: {
          from_date: "",
          to_date: "",
        },
      },
    };
  }
  componentDidMount() {
    this.props.resetReportData();
    this.props.resetOverallReportData();
    this.initialCall();
  }
  componentWillUnmount() {
    sessionStorage.setItem("ASreportError", false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.reportFilterParams !== this.props.reportFilterParams) {
      this.generateReport();
      const { reportFilterParams } = this.props;
      return reportFilterParams.from_date !== null &&
        reportFilterParams.from_date !== undefined &&
        reportFilterParams.from_date !== "" &&
        reportFilterParams.to_date !== null &&
        reportFilterParams.to_date !== undefined &&
        reportFilterParams.to_date !== ""
        ? this.setState({
            ...this.state,
            fromDate: moment(reportFilterParams.from_date).format("DD-MM-YYYY"),
            toDate: moment(reportFilterParams.to_date).format("DD-MM-YYYY"),
          })
        : this.setState({
            ...this.state,
            fromDate: moment(
              new Date().setDate(new Date().getDate() - 7)
            ).format("DD-MM-YYYY"),
            toDate: moment(new Date()).format("DD-MM-YYYY"),
          });
    }
    if (prevProps.reportDatas != this.props.reportDatas) {
      this.setState({
        ...this.state,
        reportDatas: this.props.reportDatas,
      });
    }
    if (prevProps.reportData != this.props.reportData) {
      this.setState({
        ...this.state,
        reportData: this.props.reportData,
      });
    }
  }

  initialCall = () => {
    var d1 = new Date();
    var d = new Date();
    d.setDate(d.getDate() - 7);
    let frmdate = new Date(d.getTime()).setHours(0, 0, 0, 0);
    let todate = new Date(d1.getTime()).setHours(23, 59, 59);
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          generateParams: {
            ...this.state.data.generateParams,
            from_date: frmdate,
            to_date: todate,
          },
        },
      },
      () => {
        this.props.fetchReport(this.state.data);
        this.props.fetchOverallReport(this.state.data);
      }
    );
  };
  generateReport = () => {
    const { reportFilterParams } = this.props;
    Object.keys(reportFilterParams).map((item) => {
      if (
        reportFilterParams[item] === "" ||
        reportFilterParams[item] == null ||
        reportFilterParams[item] == undefined
      ) {
        delete reportFilterParams[item];
      }
    });
    if (
      (reportFilterParams && Object.keys(reportFilterParams).length == 0) ||
      Object.keys(reportFilterParams).length == null ||
      Object.keys(reportFilterParams).length == undefined
    ) {
      this.initialCall();
    } else {
      let generateParams = {
        ...this.props.reportFilterParams,
      };
      let data = {
        generateParams: generateParams,
      };
      this.props.fetchReport(data);
      this.props.fetchOverallReport(data);
    }
  };
  initialExport = () => {
    var d1 = new Date();
    var d = new Date();
    d.setDate(d.getDate() - 7);
    let data = {
      exportParams: {
        from_date: new Date(d.getTime()).setHours(0, 0, 0, 0),
        to_date: new Date(d1.getTime()).setHours(23, 59, 59),
      },
    };
    this.props.exportReport(data);
    // this.props.exportOverallReport(data);
  };
  exportReport = () => {
    const { reportFilterParams } = this.props;
    if (
      (reportFilterParams && Object.keys(reportFilterParams).length == 0) ||
      Object.keys(reportFilterParams).length == null ||
      Object.keys(reportFilterParams).length == undefined
    ) {
      this.initialExport();
    } else {
      let exportParams = {
        ...reportFilterParams,
      };
      let data = {
        exportParams: exportParams,
      };
      this.props.exportReport(data);
      // this.props.exportOverallReport(data);
    }
  };

  toggleFilterWindow = () => {
    this.setState({
      showReportFilterWindow: this.state.showReportFilterWindow ? false : true,
    });
  };
  render() {
    const { classes } = this.props;
    const { reportData, reportDatas, fromDate, toDate } = this.state;
    return (
      <>
        <ActionSummaryFilter
          toggleFilterWindow={this.toggleFilterWindow}
          showReportFilterWindow={this.state.showReportFilterWindow}
          empty={this.initialCall}
          applyFilter={this.generateReport}
        />
        <Fragment>
          <Toolbar className={classes.root}>
            <Typography
              variant="h6"
              id="tableTitle"
              className={classes.tableTitle}
            >
              Action Summary Report
            </Typography>
            <div className={classes.spacer} />

            <div>
              <Tooltip title="Filter list">
                <IconButton
                  aria-label="Filter list"
                  onClick={this.toggleFilterWindow}
                >
                  <FilterListIcon className={classes.filterIcon} />
                </IconButton>
              </Tooltip>
              {Object.keys(reportData).length !== 0 &&
              Object.keys(reportDatas).length !== 0 ? (
                <Tooltip title="Download Report">
                  <IconButton aria-label="Download Report">
                    <DownloadIcon
                      className={classes.filterIcon}
                      onClick={this.exportReport}
                    />
                  </IconButton>
                </Tooltip>
              ) : null}
            </div>
          </Toolbar>
          <Divider className={classes.divider} />
          <Paper className={classes.reportPaper} elevation={0}>
            {this.props.loading === false &&
            reportDatas.result &&
            reportDatas.result.length === 0 &&
            reportData.result &&
            reportData.result.length === 0 ? (
              <>
                <div className={classes.emptyReport}>
                  <Typography variant="h5" align="center" color="textSecondary">
                    {messageConstants.NO_REPORTS_FOUND}
                  </Typography>
                </div>
              </>
            ) : (
              <>
                {Object.keys(reportData).length !== 0 &&
                Object.keys(reportDatas).length !== 0 ? (
                  <>
                    {reportDatas ? (
                      <OverallActionSummaryReport overallData={reportDatas} />
                    ) : null}

                    {/* <React.Fragment> */}
                    <div className={classes.reportTitle}>
                      {reportData.title ? (
                        <>
                          <Typography
                            variant="h5"
                            gutterBottom
                            className={classes.reportTitle}
                          >
                            {reportData.title}
                          </Typography>

                          {fromDate && toDate ? (
                            <Typography
                              variant="h5"
                              gutterBottom
                              className={classes.dateTitle}
                            >
                              {"(" + fromDate + " to " + toDate + ")"}
                            </Typography>
                          ) : null}
                        </>
                      ) : null}
                    </div>

                    <Divider className={classes.tabledivider} />

                    {reportDatas.result &&
                    reportDatas.result.length > 0 &&
                    reportData.result &&
                    reportData.result.length > 0 ? (
                      <>
                        <div className={classes.container}>
                          <Table aria-label="sticky table" stickyHeader>
                            <TableHead>
                              <TableRow>
                                {reportData.headers.map((row, i) => {
                                  return (
                                    <TableCell
                                      key={i}
                                      classes={{ root: classes.tableHead }}
                                    >
                                      {row}
                                    </TableCell>
                                  );
                                }, this)}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {reportData.result.map((row, index) => (
                                <TableRow
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={index}
                                  className={classes.zebrafont}
                                >
                                  {row.map((cell, j) => (
                                    <TableCell
                                      key={j}
                                      component="th"
                                      scope="row"
                                      classes={{ root: classes.tableCell }}
                                    >
                                      {cell}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={classes.emptyReport}>
                          <Typography
                            variant="h5"
                            align="center"
                            color="textSecondary"
                          >
                            {messageConstants.NO_REPORTS_FOUND}
                          </Typography>
                        </div>
                      </>
                    )}
                    {/* </React.Fragment> */}
                  </>
                ) : (
                  <>
                    {sessionStorage.getItem("ASreportError") === "true" ? (
                      <div className={classes.emptyReport}>
                        <Typography
                          variant="h5"
                          align="center"
                          color="textSecondary"
                        >
                          {messageConstants.NO_REPORTS_FOUND}
                        </Typography>
                      </div>
                    ) : (
                      <Skelton />
                    )}
                  </>
                )}
              </>
            )}
          </Paper>
        </Fragment>
      </>
    );
  }
}

export default withStyles(styles)(ActionSummaryReport);
