import React from "react";
import "./Tooltip.css";
import { Paper } from "@material-ui/core";

export default ({ currentData, event }) => {
  const styles = {
    left: event.pageX,
    top: event.pageY,
  };

  return (
    <Paper className="Tooltip" style={styles}>
      <table>
        <thead>
          <tr>
            <th colSpan="2">{currentData.type}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="1">Count : {currentData.value}</td>
          </tr>
        </tbody>
      </table>
    </Paper>
  );
};
