import React, { createRef } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  TextField,
  InputAdornment,
  withStyles,
  Grid,
  Input,
} from "@material-ui/core";
// import { DatePicker } from "material-ui-pickers";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import validator from "../../services/validator";
import { find } from "lodash";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit,
  },
  textField: {
    marginLeft: "1%",
    marginRight: theme.spacing.unit,
    width: "98%",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
    height: 300,
  },
  // vldclr: {
  //   color: "#FF0000",
  // },
});

const status = [
  {
    value: 1,
    label: "Select",
  },
  {
    value: 2,
    label: "Active",
  },
  {
    value: 3,
    label: "Inactive",
  },
];

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.confidence = createRef();
    this.state = {
      // data: {
      user: "",
      role: "",
      status: "",
    };
  }

  componentDidMount() {
    this.props.fetchUserList(this.state.type);
    this.props.fetchRole(this.state.type);
    this.props.fetchStatus(this.state.type);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.fetchUsers != this.props.fetchUsers) {
      this.setState({
        data: this.props.fetchUsers,
      });
    }
    if (prevProps.fetchRoles != this.props.fetchRoles) {
      this.setState({
        data: this.props.fetchRoles,
      });
    }
    if (prevProps.fetchStatus != this.props.fetchStatus) {
      this.setState({
        data: this.props.fetchStatus,
      });
    }
  }
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  handleChange = (event) => {
    this.setState({
      //data: { ...this.state.data, [event.target.name]: event.target.value },
      [event.target.name]: event.target.value,
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };

  handleChanges = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleErrorFocus = (errors) => {
    let order = ["confidence"];
    for (var i = 0; i < order.length; i++) {
      if (errors[order[i]]) {
        this[order[i]].current.focus({
          preventScroll: true,
        });
        window.scrollTo({
          top: this[order[i]].current.getBoundingClientRect().y,
          behaviour: "smooth",
        });
        break;
      }
    }
  };

  validate = () => {
    const { rules, data } = this.state;
    const errors = validator(rules)(this.state);
    const hasErrors = find(errors, (error) => error !== "");
    this.setState({ errors });
    return !hasErrors;
  };

  setFilterParams = (e) => {
    let params = {
      user: this.state.user,
      role: this.state.role,
      status: this.state.offender === 2,
      status: this.state.offender === 3,
    };

    if (this.validate()) {
      this.props.setFilterParams(params);
      this.props.toggleFilterWindow();
      this.props.searchMethod(this.props.page, this.props.rowsPerPage, params);
    }
  };

  resetFilterParams = () => {
    this.setState({
      user: "",
      role: "",
      status: "",
    });
    this.props.resetFilterParams();
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Dialog
          open={this.props.showFilterWindow}
          onClose={this.props.toggleFilterWindow}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Filter"}</DialogTitle>

          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="User Name"
                  name="user"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  onChange={this.handleChange}
                  value={this.state.user}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="role"
                  select
                  label="Role"
                  className={classes.textField}
                  name="role"
                  onChange={(e) => this.handleChange(e)}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={this.state.role}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                >
                  {/* <MenuItem value={this.state._id}>Select</MenuItem>
                  {this.props.fetchRole.map((role) => {
                    return (
                      <MenuItem value={role.key} key={role.key}>
                        {role.value}
                      </MenuItem> */}
                  ); })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                {" "}
                <TextField
                  id="status"
                  select
                  label="Status"
                  className={classes.textField}
                  value={this.state.status}
                  onChange={this.handleChanges("status")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {status.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.toggleFilterWindow}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
            <Button
              onClick={this.resetFilterParams}
              color="primary"
              variant="contained"
            >
              Clear
            </Button>
            <Button
              onClick={() => this.setFilterParams()}
              color="secondary"
              autoFocus
              variant="contained"
            >
              Apply Filter
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(Filter);
