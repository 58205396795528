import React, { Fragment } from "react";
import CategoryIcon from "@material-ui/icons/CategoryOutlined";
import LineIcon from "@material-ui/icons/LineStyleOutlined";
import ConfidenceIcon from "@material-ui/icons/ThumbUpOutlined";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import DocumentIcon from "@material-ui/icons/DescriptionOutlined";
import DateIcon from "@material-ui/icons/CalendarTodayOutlined";
import PersonIcon from "@material-ui/icons/PersonOutlined";
import TruckIcon from "@material-ui/icons/LocalShippingOutlined";
import ErrorIcon from "@material-ui/icons/ErrorOutlined";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { toDateTime } from "util/date";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  withStyles,
  Typography,
  AppBar,
  Toolbar,
  Divider,
} from "@material-ui/core";
import messageConstants from "../../../../services/validator/MsgConsts/messageConstants";

const styles = (theme) => ({
  root: {
    minHeight: "100%",
    //border: theme.BorderStyle.border,
    //padding: 3,
  },
  detailsPaper: {
    height: "32vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    border: theme.BorderStyle.border,
    marginRight: "10px",
  },
  alertIcon: {
    color: theme.alertIcon.color,
  },
  warningIcon: {
    color: theme.warningIcon.color,
  },
  noAlertIcon: {
    color: theme.noAlertIcon.color,
  },
  heading: {
    font: "normal normal normal 16px Karla",
  },
  toolbar: {
    paddingRight: theme.spacing.unit,
    backgroundColor: theme.toolbar.backgroundColor,
  },
  tableTitle: {
    color: theme.toolbar.iconColor,
    fontWeight: theme.font.fontWeight,
    fontSize: theme.tableHeader.fontsize,
  },
  contentHead: {
    font: "normal normal normal 16px Karla",
    color: "#797C82",
  },
  contentBody: {
    font: "normal normal bold 16px Karla;",
    // font: "normal normal bold 16px/20px Karla",
    color: "#2B3D51",
    paddingTop: "3px",
  },
  divider: {
    marginTop: "7.5px",
  },
  rowStyle: {
    paddingTop: "8.5px",
  },
});
class VehicleEwayBill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getFormattedLatLong(lat, long) {
    return parseFloat(lat).toFixed(2).concat(",", parseFloat(long).toFixed(2));
  }
  render() {
    const { classes } = this.props;

    // console.log("data test:", this.props.record);
    return (
      <div className={classes.root}>
        <table
          size="small"
          style={{
            width: "100%",

            // maxHeight: "340px",
            // minHeight: "240px",
            paddingLeft: "18px",
            paddingRight: "11px",
            paddingTop: "13px",
            paddingBottom: "13px",
            font: "normal normal normal 16px Karla",
          }}
        >
          <tbody>
            <tr className={classes.rowStyle}>
              <td style={{ paddingRight: "12px" }}>
                <span className={classes.contentHead}>Lane</span>
                <br />
                <span className={classes.contentBody}>
                  {this.props.record.lane_id}
                </span>
                <Divider className={classes.divider} />
              </td>
              <td style={{ paddingLeft: "12px" }}>
                <span className={classes.contentHead}>Category</span>
                <br />
                <span className={classes.contentBody}>
                  {this.props.record.vehicle_category}
                </span>
                <Divider className={classes.divider} />
              </td>
            </tr>
            <tr className={classes.rowStyle}>
              <td style={{ paddingRight: "12px" }}>
                <span className={classes.contentHead}>Confidence</span>
                <br />
                <span className={classes.contentBody}>
                  {this.props.record.confidence}
                </span>
                <Divider className={classes.divider} />
              </td>
              <td style={{ paddingLeft: "12px" }}>
                <span className={classes.contentHead}>Location</span>
                <br />
                <span className={classes.contentBody}>
                  {this.props.record.location.length > 15
                    ? this.props.record.location.substring(0, 15) + "..."
                    : this.props.record.location}
                </span>
                <Divider className={classes.divider} />
              </td>
            </tr>
            <tr className={classes.rowStyle}>
              <td style={{ paddingRight: "12px" }}>
                <span className={classes.contentHead}>E-way Bill Status</span>
                <br />
                <span className={classes.contentBody}>
                  {this.props.record.eway_bill_status
                    ? this.props.record.eway_bill_status
                    : null}
                </span>
                <Divider className={classes.divider} />
              </td>
              <td style={{ paddingLeft: "12px" }}>
                <span className={classes.contentHead}>Date</span>
                <br />
                <span className={classes.contentBody}>
                  {this.props.record.event_timestamp
                    ? toDateTime(this.props.record.event_timestamp)
                    : null}

                  {/* {new Date().toLocaleDateString()} */}
                </span>
                <Divider className={classes.divider} />
              </td>
            </tr>
            <tr className={classes.rowStyle}>
              <td style={{ paddingRight: "12px" }}>
                <span className={classes.contentHead}>Vehicle No</span>
                <br />
                <span className={classes.contentBody}>
                  {this.props.record.plate}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
export default withStyles(styles)(VehicleEwayBill);
