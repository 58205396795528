import APIRequest from "../util/api/APIRequest";
import { USER_NAME_EXISTS } from "../services/validator/validator/errorMsgConst";

class UserMasterService {
  addUser = (params) => {
    return APIRequest({
      url: `api/users`,
      data: params,
      method: "POST",
    });
  };

  checkUsername = (name) => {
    return APIRequest({
      url: `api/users/username/${name}`,
      method: "GET",
    });
  };
  fetchUserDeatils = (id) => {
    return APIRequest({
      url: `api/users/${id}`,
      method: "GET",
    });
  };

  editUser = (params) => {
    return APIRequest({
      url: `api/users`,
      data: params,
      method: "PUT",
    });
  };

  fetchUserList = (page, limit) => {
    return APIRequest({
      url: `/api/users`,
      params: {
        page,
        limit,
      },
      method: "GET",
    });
  };

  deleteUser = (id) => {
    return APIRequest({
      url: `api/users/${id}`,
      method: "DELETE",
    });
  };

  fetchUserType = () => {
    return APIRequest({
      url: `api/userType`,
      method: "GET",
    });
  };

  fetchRole = (id) => {
    return APIRequest({
      url: `api/rolesList`,
      params: { id },
      method: "GET",
    });
  };

  fetchZone = () => {
    return APIRequest({
      url: "api/zones",
      method: "GET",
    });
  };

  fetchDistrict = (id) => {
    return APIRequest({
      url: `api/districtList`,
      params: { id },
      method: "GET",
    });
  };

  fetchField = (id) => {
    return APIRequest({
      url: `api/fieldList`,
      params: { id },
      method: "GET",
    });
  };
  fetchCameraAccess = (userTypeId, id) => {
    return APIRequest({
      url: `api/camera/usertype/${userTypeId}/field/${id}`,
      method: "GET",
    });
  };

  fetchEditCameraAccess = (id) => {
    return APIRequest({
      url: `api/users/camera/${id}`,
      method: "GET",
    });
  };
  fetchRoles = (page, limit) => {
    return APIRequest({
      url: `api/roles`,
      method: "GET",
      params: {
        page,
        limit,
      },
    });
  };
  onFilterChange = (params) => {
    return APIRequest({
      url: `api/users`,
      params: params,
      method: "GET",
    });
  };
}

export default UserMasterService;
