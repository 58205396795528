import {
  TOGGLE_FILTER_WINDOW,
  SET_HISTORY_FILTER_PARAMS,
  RESET_HISTORY_FILTER_PARAMS,
  FETCH_LANE,
  FETCH_LANE_FAILED,
  FETCH_CAMERA,
  FETCH_CAMERA_FAILED,
  FETCH_VEHICLE,
  FETCH_VEHICLE_FAILED,
  FETCH_ALERT_FAILED,
  FETCH_ALERT,
  FETCH_WARNING,
  FETCH_WARNING_FAILED,
  CLOSE_WINDOW,
  GET_ZONE,
  GET_ZONE_FAILED,
  GET_DISTRICT,
  GET_DISTRICT_FAILED,
  GET_FIELD,
  GET_FIELD_FAILED,
  SET_FROMDATE,
  RESET_FROMDATE,
  SET_TODATE,
  RESET_TODATE,
  SET_PLATE,
  RESET_PLATE,
  SET_ALERTTYPE,
  RESET_ALERTTYPE,
  SET_ZONE,
  RESET_ZONE,
  SET_DISTRICT,
  RESET_DISTRICT,
  SET_FIELD,
  RESET_FIELD,
  SET_CAMERA,
  RESET_CAMERA,
  SET_COMMODITY,
  RESET_COMMODITY,
  SET_OFFENDER,
  RESET_OFFENDER,
  SET_VEHICLE,
  RESET_VEHICLE,
  SET_LANE,
  RESET_LANE,
  SET_WARNINGTYPE,
  RESET_WARNINGTYPE,
} from "./constants";
import {
  SET_PAGE,
  SET_ROWSPERPAGE,
} from "../../../screens/history/data/constants";
import FilterService from "service/FilterService";
import ReportService from "../../../service/ReportService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";
import { setRowsPerPage } from "../../../screens/history/data/action";

export const toggleFilterWindow = () => {
  return (dispatch) => {
    dispatch(toggleFilterWindow());
  };
  function toggleFilterWindow() {
    return { type: TOGGLE_FILTER_WINDOW };
  }
};
export const setValue = (data) => {
  return (dispatch) => {
    dispatch(setValue(data));
  };
  function setValue() {
    return { type: CLOSE_WINDOW, data };
  }
};

export const setFromDate = (date) => {
  return (dispatch) => {
    dispatch(setFromDate(date));
  };
  function setFromDate(date) {
    return { type: SET_FROMDATE, date };
  }
};

export const resetFromDate = () => {
  return (dispatch) => {
    dispatch(resetFromDate());
  };
  function resetFromDate() {
    return { type: RESET_FROMDATE };
  }
};

export const setToDate = (date) => {
  return (dispatch) => {
    dispatch(setToDate(date));
  };
  function setToDate(date) {
    return { type: SET_TODATE, date };
  }
};

export const resetToDate = () => {
  return (dispatch) => {
    dispatch(resetToDate());
  };
  function resetToDate() {
    return { type: RESET_TODATE };
  }
};

export const setPlateNo = (plate) => {
  return (dispatch) => {
    dispatch(setPlateNo(plate));
  };
  function setPlateNo(plate) {
    return { type: SET_PLATE, plate };
  }
};
export const resetPlateNo = () => {
  return (dispatch) => {
    dispatch(resetPlateNo());
  };
  function resetPlateNo() {
    return { type: RESET_PLATE };
  }
};

export const setAlertType = (data) => {
  return (dispatch) => {
    dispatch(setAlertType(data));
  };
  function setAlertType(data) {
    return { type: SET_ALERTTYPE, data };
  }
};
export const resetAlertType = () => {
  return (dispatch) => {
    dispatch(resetAlertType());
  };
  function resetAlertType() {
    return { type: RESET_ALERTTYPE };
  }
};

export const setZone = (data) => {
  return (dispatch) => {
    dispatch(setZone(data));
  };
  function setZone(data) {
    return { type: SET_ZONE, data };
  }
};
export const resetZone = () => {
  return (dispatch) => {
    dispatch(resetZone());
  };
  function resetZone() {
    return { type: RESET_ZONE };
  }
};

export const setDistrict = (data) => {
  return (dispatch) => {
    dispatch(setDistrict(data));
  };
  function setDistrict(data) {
    return { type: SET_DISTRICT, data };
  }
};
export const resetDistrict = () => {
  return (dispatch) => {
    dispatch(resetDistrict());
  };
  function resetDistrict() {
    return { type: RESET_DISTRICT };
  }
};

export const setField = (data) => {
  return (dispatch) => {
    dispatch(setField(data));
  };
  function setField(data) {
    return { type: SET_FIELD, data };
  }
};
export const resetField = () => {
  return (dispatch) => {
    dispatch(resetField());
  };
  function resetField() {
    return { type: RESET_FIELD };
  }
};

export const setCamera = (data) => {
  return (dispatch) => {
    dispatch(setCamera(data));
  };
  function setCamera(data) {
    return { type: SET_CAMERA, data };
  }
};
export const resetCamera = () => {
  return (dispatch) => {
    dispatch(resetCamera());
  };
  function resetCamera() {
    return { type: RESET_CAMERA };
  }
};

export const setCommodity = (data) => {
  return (dispatch) => {
    dispatch(setCommodity(data));
  };
  function setCommodity(data) {
    return { type: SET_COMMODITY, data };
  }
};
export const resetCommodity = () => {
  return (dispatch) => {
    dispatch(resetCommodity());
  };
  function resetCommodity() {
    return { type: RESET_COMMODITY };
  }
};

export const setOffender = (data) => {
  return (dispatch) => {
    dispatch(setOffender(data));
  };
  function setOffender(data) {
    return { type: SET_OFFENDER, data };
  }
};
export const resetOffender = () => {
  return (dispatch) => {
    dispatch(resetOffender());
  };
  function resetOffender() {
    return { type: RESET_OFFENDER };
  }
};

export const setVehicle = (data) => {
  return (dispatch) => {
    dispatch(setVehicle(data));
  };
  function setVehicle(data) {
    return { type: SET_VEHICLE, data };
  }
};
export const resetVehicle = () => {
  return (dispatch) => {
    dispatch(resetVehicle());
  };
  function resetVehicle() {
    return { type: RESET_VEHICLE };
  }
};

export const setLane = (data) => {
  return (dispatch) => {
    dispatch(setLane(data));
  };
  function setLane(data) {
    return { type: SET_LANE, data };
  }
};
export const resetLane = () => {
  return (dispatch) => {
    dispatch(resetLane());
  };
  function resetLane() {
    return { type: RESET_LANE };
  }
};
export const setWarningType = (data) => {
  return (dispatch) => {
    dispatch(setWarningType(data));
  };
  function setWarningType(data) {
    return { type: SET_WARNINGTYPE, data };
  }
};
export const resetWarningType = () => {
  return (dispatch) => {
    dispatch(resetWarningType());
  };
  function resetWarningType() {
    return { type: RESET_WARNINGTYPE };
  }
};

export const setHistoryFilterParams = (filterHistoryParams) => {
  return (dispatch) => {
    dispatch(setHistoryFilterParams(filterHistoryParams));
    dispatch(setPage(0));
    //dispatch(setRowsPerPage(10));
  };
  function setHistoryFilterParams(filterHistoryParams) {
    return { type: SET_HISTORY_FILTER_PARAMS, filterHistoryParams };
  }
  function setPage(page) {
    return { type: SET_PAGE, page };
  }
  // function setRowsPerPage(rowsPerPage) {
  //   return { type: SET_ROWSPERPAGE, rowsPerPage };
  // }
};

export const resetFilterParams = () => {
  return (dispatch) => {
    dispatch(resetFilterParams());
  };
  function resetFilterParams() {
    return { type: RESET_HISTORY_FILTER_PARAMS };
  }
};

export const fetchLane = (type) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new FilterService().fetchLane(type).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_LANE, data };
  }

  function failure(error) {
    return { type: FETCH_LANE_FAILED, error };
  }
};

export const fetchAlert = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new ReportService().fetchAlert().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_ALERT, data };
  }

  function failure(error) {
    return { type: FETCH_ALERT_FAILED, error };
  }
};

export const fetchWarning = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new ReportService().fetchWarning().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_WARNING, data };
  }

  function failure(error) {
    return { type: FETCH_WARNING_FAILED, error };
  }
};

export const fetchCamera = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new FilterService().fetchCamera().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_CAMERA, data };
  }

  function failure(error) {
    return { type: FETCH_CAMERA_FAILED, error };
  }
};

export const fetchVehicle = (type) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new FilterService().fetchVehicle(type).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_VEHICLE, data };
  }

  function failure(error) {
    return { type: FETCH_VEHICLE_FAILED, error };
  }
};
export const fetchZone = (type) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new FilterService().getZone(type).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: GET_ZONE, data };
  }

  function failure(error) {
    return { type: GET_ZONE_FAILED, error };
  }
};
export const fetchDistrict = (type, id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new FilterService().getDistrict(type, id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: GET_DISTRICT, data };
  }

  function failure(error) {
    return { type: GET_DISTRICT_FAILED, error };
  }
};
export const fetchField = (type, id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new FilterService().getField(type, id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: GET_FIELD, data };
  }

  function failure(error) {
    return { type: GET_FIELD_FAILED, error };
  }
};
