export const FETCH_DUTY_LIST = "FETCH_DUTY_LIST";
export const DELETE_DUTY = "DELETE_DUTY";
export const RESET_DELETE = "RESET_DELETE";
export const FETCH_DUTY_LIST_ERROR = "FETCH_DUTY_LIST_ERROR ";
export const DELETE_DUTY_ERROR = "DELETE_DUTY_ERROR";
export const FETCH_DISTRICT_SUCCESS = "FETCH_DISTRICT_SUCCESS";
export const FETCH_DISTRICT_FAILED = "FETCH_DISTRICT_FAILED";
export const DIALOGUE_WINDOW_STATUS = "DIALOGUE_WINDOW_STATUS";
export const FILTER_CHANGE = "FILTER_CHANGE";
export const FILTER_CHANGE_ERROR = "FILTER_CHANGE_ERROR";
