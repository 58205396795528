import {
  FETCH_ALERT_AND_WARNING_COUNT,
  FETCH_ALERT_AND_WARNING_COUNT_ERROR,
} from "./constants";
import DashboardService from "../../../../service/DashboardService";
import { snackbarActions } from "../../../../components/snackbar/data/action";
import { progressActions } from "../../../../components/progressbar/data/action";

export const getAlertAndWarningCount = (params) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DashboardService().getAlertAndWarningCount(params).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        // dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_ALERT_AND_WARNING_COUNT, data };
  }
  function failure(error) {
    return { type: FETCH_ALERT_AND_WARNING_COUNT_ERROR, error };
  }
};
