import {
  FETCH_USER_LIST,
  DELETE_USER,
  RESET_DELETE,
  FETCH_USER_LIST_ERROR,
  DELETE_USER_ERROR,
  DIALOGUE_WINDOW_STATUS,
  FILTER_CHANGE,
  FILTER_CHANGE_ERROR,
  FETCH_ROLE,
  FETCH_ROLE_FAILED,
} from "./constants";
import UserMasterService from "../../../service/UserMasterService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";
import userDetails from "../components/userDetails/userDetails";

export const fetchUserList = (page, limit) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserMasterService().fetchUserList(page, limit).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };
  function success(data) {
    return { type: FETCH_USER_LIST, data };
  }
  function failure(error) {
    return { type: FETCH_USER_LIST_ERROR, error };
  }
};
export const fetchRoles = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserMasterService().fetchRoles().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_ROLE, data };
  }

  function failure(error) {
    return { type: FETCH_ROLE_FAILED, error };
  }
};
export const dialogueWindowStatus = () => {
  return (dispatch) => {
    dispatch(dialogueWindowStatus());
  };
  function dialogueWindowStatus() {
    return { type: DIALOGUE_WINDOW_STATUS };
  }
};
export const onFilterChange = (params) => {
  let userMasterService = new UserMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    userMasterService.onFilterChange(params).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FILTER_CHANGE, data };
  }
  function failure(error) {
    return { type: FILTER_CHANGE_ERROR, error };
  }
};

export const deleteUser = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserMasterService().deleteUser(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: DELETE_USER, data };
  }
  function failure(error) {
    return { type: DELETE_USER_ERROR, error };
  }
};

export function resetDelete() {
  return {
    type: RESET_DELETE,
  };
}
