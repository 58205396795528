import React, { Fragment } from "react";
import {
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  TextField,
  FormControl,
  FormHelperText,
  IconButton,
  Divider,
} from "@material-ui/core";
import classNames from "classnames";
import propTypes from "prop-types";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import validator from "../../../services/validator";
import { find } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  assignDuty,
  fetchSqad,
  fetchDistrict,
  fetchSquardDetails,
  resetDuties,
  resetOfficeandField,
} from "./data/action";
import moment from "moment";
import { snackbarActions } from "components/snackbar/data/action";
import { push } from "connected-react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { dispatch } from "d3";
import { ReactComponent as BackIcon } from "../../../assets/SvgIcons/back.svg";
// import Recaptcha from "react-google-invisible-recaptcha";
// import CaptchaSiteKeyMasters from "../../../services/siteKeyMasters";
import { ReactComponent as DownArrow } from "../../../assets/SvgIcons/dropdown.svg";
import { ReactComponent as DateTime } from "../../../assets/Visual Assets/date.svg";

const toolbarStyles = (theme) => ({
  root: {
    marginTop: "11px",
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "2px",
    marginBottom: "13px",
    marginLeft: "9px",
  },
  iconButton: {
    paddingLeft: "0px",
  },
  divider: {
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
});

let EnhancedTableToolbar = (props) => {
  const { classes } = props;
  return (
    <>
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          {" "}
          <IconButton
            onClick={(e) => props.onBackButtonClick(e)}
            classes={{ root: classes.iconButton }}
          >
            <BackIcon />
          </IconButton>
          Assign Duty Master
        </Typography>
        <div className={classes.spacer} />
      </Toolbar>{" "}
      <Divider className={classes.divider} />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: propTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  grid: {
    marginTop: "26px",
    paddingTop: "17px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
    minHeight: "12vh",
    maxHeight: "75vh",
    overflowY: "auto",
  },
  root: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
    width: "100%",
    overflow: "hidden",
    paddingBottom: "34px",
  },

  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  textField: {
    width: "94%",
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "45px",
  },
  errorautoComplete: {
    width: "94%",
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "70px",
  },
  errorTextField: {
    width: "94%",
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "60px",
  },
  paperStyle: {
    paddingLeft: "33px",
    paddingRight: "14px",
    paddingBottom: "8px",
  },
  textFielddes: {
    paddingTop: "1%",
  },
  subHeading: {
    textAlign: "left",
    font: "normal normal bold 16px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    paddingBottom: "9px",
    paddingTop: "4px",
  },
  button: {
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    opacity: 1,
    textTransform: "none",
    // paddingLeft: "38px",
    // paddingRight: "37px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    height: "42px",
    width: "121px",
  },
  clearButton: {
    width: "121px",
    height: "42px",
    // marginRight: "10px",
    textTransform: "none",
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.colors.BlueButton,
    textAlign: "center",
    width: "121px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
  },
  cancelButton: {
    width: "120px",
    marginBottom: "6%",
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.whiteColor,
  },
  btnGrid: {
    padding: 0,
    paddingLeft: "12px",
  },
  btns: {
    paddingTop: "5px",
    paddingRight: "25%",
  },
  err: {
    paddingLeft: "1%",
  },
  dense: {
    marginTop: 16,
  },
  alignment: {
    flexDirection: "row",
  },
  radbtns: {
    paddingLeft: "5%",
  },
  formitems: {
    paddingTop: "6%",
  },
  menu: {
    width: 200,
    height: 300,
  },
  timeGrid: {
    paddingBottom: "65px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  popIconOpen: {
    marginTop: "4px",
    marginRight: "6px",
  },
  popIcon: {
    marginTop: "6px",
    marginRight: "6px",
  },
  textNoOptions: {
    letterSpacing: "0px",
    opacity: 1,
    fontSize: "18px",
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  formHelperText: {
    color: theme.colors.redColor,
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  subGrid: {
    paddingBottom: "6px",
  },
  subDateGrid: {
    paddingBottom: "18px",
  },
  mainGrid: { paddingBottom: "15px" },
  dateIcon: {
    marginTop: "0px",
    marginRight: "-8px",
  },
});

class AssignDuty extends React.Component {
  constructor(props) {
    super(props);
    this.onResolved = this.onResolved.bind(this);

    this.state = {
      isVerified: false,

      selectSquad: [],
      selectDistrict: [],
      squad_id: false,
      data: {
        captcha: "",
        squad: "",
        district: "",
        field: "",
        zone: "",
        from_date: null,
        to_date: null,
        in_time: null,
        out_time: null,
      },
      errors: {
        squad: "",
        district: "",
        field: "",
        zone: "",
        from_date: "",
        to_date: "",
        in_time: "",
        out_time: "",
      },
      rules: {
        squad: {
          required: true,
        },
        district: { required: true },
        from_date: {
          required: true,
          isDateGreaterCurrent: true,
          // isDateGreater: true,
        },
        to_date: {
          required: true,
          isDateGreaterCurrent: true,
          // isDateGreater: true,
        },
        in_time: {
          required: true,
        },
        out_time: {
          required: true,
        },
      },
    };
  }
  componentDidMount() {
    this.props.fetchDistrict();
  }

  componentDidUpdate(prevProps, prevState) {
    const { field, zone, squardList, districtList } = this.props;

    if (prevProps.districtList != this.props.districtList) {
      this.setState({
        ...this.state,
        selectDistrict: districtList,
      });
    }

    if (prevProps.squardList != this.props.squardList) {
      this.setState({
        ...this.state,
        selectSquad: this.props.squardList,
      });
    }
    if (prevProps.zone !== this.props.zone) {
      this.setState(
        {
          ...this.state,
          data: {
            ...this.state.data,
            zone: this.props.zone,
          },
        },
        () => {
          if (prevProps.field !== this.props.field) {
            this.setState({
              ...this.state,
              data: {
                ...this.state.data,
                field: this.props.field,
              },
            });
          }
        }
      );
    }

    if (
      prevProps.duties != this.props.duties &&
      this.props.saveSuccess &&
      this.props.statusmessage !== prevProps.statusmessage &&
      !this.props.error &&
      this.props.statusmessage !== "failed"
    ) {
      setTimeout(() => {
        this.props.showSnackbar(this.props.message, "success");
      }, 1000);
      this.props.navigateTo("/duty-master");
    }
    if (
      this.props.saveSuccess &&
      !this.props.error &&
      this.props.saveStaus !== prevProps.saveStaus &&
      this.props.statusmessage === "failed"
    ) {
      this.props.showSnackbar(this.props.message, "error");
    }
    if (this.props.error && this.props.error !== this.state.alert) {
      this.props.showSnackbar(this.props.message, "error");
    }
  }

  handleSelectDistrict = (event, value) => {
    // event.preventDefault();
    // this.recaptcha.execute();
    // this.setState({
    //   isVerified: true,
    //   captcha: this.recaptcha.getResponse(),
    // });
    this.setState(
      {
        data: {
          ...this.state.data,
          district: value,
        },
        errors: {
          ...this.state.errors,
          [event.target.name]: "",
        },
      },
      () => {
        this.setState(
          {
            data: {
              ...this.state.data,
              squad: "",
              field: "",
              zone: "",
            },
          },
          () => {
            let params = this.state.data.district.district_id;
            this.props.fetchSqad(params);
          }
        );
      }
    );
  };
  handleSelectSquad = (event, value) => {
    // event.preventDefault();
    this.setState(
      {
        data: { ...this.state.data, squad: value },
        errors: {
          ...this.state.errors,
          [event.target.name]: "",
        },
      },

      () => {
        this.setState(
          {
            data: {
              ...this.state.data,
              field: "",
              zone: "",
            },
          },
          () => {
            this.props.resetOfficeandField();
            let params = this.state.data.squad.squad_id;
            this.props.fetchSquardDetails(params);
          }
        );
      }
    );
  };
  handleChangeTime = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };
  handleChangeDate = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: moment(event).format("YYYY-MM-DD"),
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };

  validateDate = () => {
    let formIsValid = true;
    let from_date = moment(this.state.data.from_date).format("YYYY-MM-DD");
    let to_date = moment(this.state.data.to_date).format("YYYY-MM-DD");
    let current_date = moment(new Date()).format("YYYY-MM-DD");
    let in_time = this.state.data.in_time;
    let out_time = this.state.data.out_time;
    let current_time = moment(new Date()).format("HH:mm:ss");

    if (moment(from_date).isAfter(to_date)) {
      formIsValid = false;
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          to_date: "Date should be after from date",
        },
      });
    }
    if (
      moment(from_date).isSame(current_date) &&
      moment(in_time).format("HH:mm:ss") < current_time
    ) {
      formIsValid = false;
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          in_time: "Time should not less than current time",
        },
      });
    }

    if (
      moment(from_date).isSame(to_date) &&
      moment(in_time).isAfter(out_time)
    ) {
      formIsValid = false;
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          out_time: "Time should be after In time",
        },
      });
    }
    return formIsValid;
  };

  onBackButtonClick = () => {
    this.props.resetOfficeandField();
    this.props.navigateTo("/duty-master");
  };
  resetDutyParams = () => {
    this.setState({
      ...this.state,
      selectSquad: [],
      data: {
        ...this.state.data,
        squad: "",
        district: "",
        field: "",
        zone: "",
        from_date: null,
        to_date: null,
        in_time: null,
        out_time: null,
      },
      errors: {
        squad: null,
        district: null,
        from_date: null,
        to_date: null,
        in_time: null,
        out_time: null,
      },
    });
    this.props.resetOfficeandField();
  };
  validate = () => {
    const { rules, data } = this.state;
    const errors = validator(rules)(data);
    const hasErrors = find(errors, (error) => error !== "");
    this.setState({ errors });
    return !hasErrors;
  };

  performAction = (e) => {
    if (this.validate()) {
      if (this.validateDate()) {
        // this.recaptcha.execute();

        // this.setState({
        //   captcha: this.recaptcha.getResponse(),
        // });
        this.onResolved();
      }
    }
  };
  onResolved = () => {
    // this.setState({
    //   captcha: this.recaptcha.getResponse(),
    // });
    let params = {
      // captcha: this.state.captcha,
      squad_id: this.state.data.squad.squad_id,
      from_date: moment(this.state.data.from_date).format("YYYY-MM-DD"),
      to_date: moment(this.state.data.to_date).format("YYYY-MM-DD"),
      in_time: moment(this.state.data.in_time).format("HH:mm:ss"),
      out_time: moment(this.state.data.out_time).format("HH:mm:ss"),
    };
    // console.log(params);
    this.props.assignDuty(params);
  };

  render() {
    const { classes, squardList, districtList, zone, field } = this.props;
    const { selectSquad, selectDistrict, errors } = this.state;
    return (
      <Grid className={classes.grid}>
        <Paper className={classes.root}>
          <EnhancedTableToolbar onBackButtonClick={this.onBackButtonClick} />
          <div className={classes.textFielddes}>
            <Fragment>
              <div>
                <Paper elevation={0} className={classes.paperStyle}>
                  <Typography className={classes.subHeading}>
                    Basic Information
                  </Typography>
                  <Grid container className={classes.mainGrid}>
                    <Grid xs={12} md={4} item className={classes.subGrid}>
                      {selectDistrict ? (
                        <FormControl error={true} className={classes.textField}>
                          <Autocomplete
                            disableClearable="true"
                            disableOpenOnFocus="true"
                            value={this.state.data.district}
                            options={selectDistrict}
                            getOptionLabel={(option) =>
                              option.district_name ? option.district_name : ""
                            }
                            error={this.state.errors.district ? true : false}
                            onChange={this.handleSelectDistrict}
                            popupIcon={<DownArrow />}
                            classes={{
                              input: classes.textValue,
                              option: classes.textValue,
                              popupIndicatorOpen: classes.popIconOpen,
                              popupIndicator: classes.popIcon,
                              noOptions: classes.textNoOptions,
                            }}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                label="District"
                                name="district"
                                id="outlined-margin-dense"
                                margin="dense"
                                {...params}
                                variant="outlined"
                                required
                                InputLabelProps={{
                                  classes: { root: classes.textLabel },
                                }}
                                error={errors.district ? true : false}
                              ></TextField>
                            )}
                          />{" "}
                          <FormHelperText className={classes.formHelperText}>
                            {this.state.errors.district}
                          </FormHelperText>
                        </FormControl>
                      ) : null}
                    </Grid>{" "}
                    <Grid xs={12} md={4} item className={classes.subGrid}>
                      {selectSquad ? (
                        <FormControl error={true} className={classes.textField}>
                          <Autocomplete
                            disableClearable="true"
                            disableOpenOnFocus="true"
                            value={this.state.data.squad}
                            options={selectSquad}
                            getOptionLabel={(option) =>
                              option.squad_name ? option.squad_name : ""
                            }
                            error={this.state.errors.squad ? true : false}
                            onChange={this.handleSelectSquad}
                            popupIcon={<DownArrow />}
                            classes={{
                              input: classes.textValue,
                              option: classes.textValue,
                              popupIndicatorOpen: classes.popIconOpen,
                              popupIndicator: classes.popIcon,
                              noOptions: classes.textNoOptions,
                            }}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                label="Squad"
                                name="squad"
                                id="outlined-margin-dense"
                                margin="dense"
                                {...params}
                                variant="outlined"
                                required
                                InputLabelProps={{
                                  classes: { root: classes.textLabel },
                                }}
                                error={errors.squad ? true : false}
                              ></TextField>
                            )}
                          />{" "}
                          <FormHelperText className={classes.formHelperText}>
                            {this.state.errors.squad}
                          </FormHelperText>
                        </FormControl>
                      ) : null}
                    </Grid>{" "}
                    <Grid xs={12} md={4} item className={classes.subGrid}>
                      <TextField
                        label="Zone"
                        className={classes.textField}
                        inputProps={{ readOnly: true }}
                        value={this.state.data.zone}
                        // value={zone}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                        InputProps={{ classes: { root: classes.textValue } }}
                      />
                    </Grid>
                    <Grid xs={12} md={4} item>
                      <TextField
                        label="Field"
                        className={classes.textField}
                        inputProps={{ readOnly: true }}
                        value={this.state.data.field}
                        //value={field}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                        InputProps={{ classes: { root: classes.textValue } }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <Paper elevation={0} className={classes.paperStyle}>
                  <Typography className={classes.subHeading}>
                    Duty Time
                  </Typography>
                  <Grid container className={classes.timeGrid}>
                    <Grid xs={12} md={4} item className={classes.subDateGrid}>
                      <FormControl error={true} className={classes.textField}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            showTodayButton
                            fullWidth
                            required
                            label="From Date"
                            name="from_date"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="dense"
                            value={this.state.data.from_date}
                            error={errors.from_date ? true : false}
                            onChange={(event) => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  from_date: moment(event).format("YYYY-MM-DD"),
                                },
                                errors: {
                                  ...this.state.errors,
                                  from_date: "",
                                },
                              });
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            keyboardIcon={
                              <DateTime className={classes.dateIcon} />
                            }
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                            InputProps={{
                              classes: { root: classes.textValue },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <FormHelperText className={classes.formHelperText}>
                          {this.state.errors.from_date}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={4} item className={classes.subDateGrid}>
                      <FormControl error={true} className={classes.textField}>
                        <KeyboardTimePicker
                          required
                          showTodayButton
                          id="time-picker"
                          label="In Time"
                          inputVariant="outlined"
                          margin="dense"
                          min={this.state.data.in_time}
                          value={this.state.data.in_time}
                          error={errors.in_time ? true : false}
                          onChange={(value) => {
                            this.setState({
                              data: {
                                ...this.state.data,
                                in_time: value,
                              },
                              errors: {
                                ...this.state.errors,
                                in_time: "",
                              },
                            });
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                          keyboardIcon={
                            <DateTime className={classes.dateIcon} />
                          }
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                          InputProps={{ classes: { root: classes.textValue } }}
                        />
                        <FormHelperText className={classes.formHelperText}>
                          {this.state.errors.in_time}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={4} item className={classes.subDateGrid}>
                      <FormControl error={true} className={classes.textField}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            showTodayButton
                            fullWidth
                            required
                            label="To Date"
                            name="to_date"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="dense"
                            value={this.state.data.to_date}
                            error={errors.to_date ? true : false}
                            // onChange={(value) => {
                            //   this.setState({
                            //     data: {
                            //       ...this.state.data,
                            //       to_date: value,
                            //     },
                            //   });
                            // }}
                            onChange={(event) => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  to_date: moment(event).format("YYYY-MM-DD"),
                                },
                                errors: {
                                  ...this.state.errors,
                                  to_date: "",
                                },
                              });
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            keyboardIcon={
                              <DateTime className={classes.dateIcon} />
                            }
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                            InputProps={{
                              classes: { root: classes.textValue },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <FormHelperText className={classes.formHelperText}>
                          {this.state.errors.to_date}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={4} item>
                      <FormControl error={true} className={classes.textField}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            showTodayButton
                            margin="dense"
                            required
                            id="Out Time"
                            label="Out Time"
                            inputVariant="outlined"
                            value={this.state.data.out_time}
                            error={errors.out_time ? true : false}
                            onChange={(value) => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  out_time: value,
                                },
                                errors: {
                                  ...this.state.errors,
                                  out_time: "",
                                },
                              });
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change time",
                            }}
                            keyboardIcon={
                              <DateTime className={classes.dateIcon} />
                            }
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                            InputProps={{
                              classes: { root: classes.textValue },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <FormHelperText className={classes.formHelperText}>
                          {this.state.errors.out_time}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid xs={12} className={classes.btns} container>
                  <Grid item xs={12} md={6}></Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <Button
                      disableElevation={true}
                      variant="contained"
                      className={classes.clearButton}
                      onClick={() => this.resetDutyParams()}
                    >
                      Clear
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.btnGrid}>
                    <Button
                      disableElevation={true}
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={() => this.performAction()}
                    >
                      Assign
                    </Button>{" "}
                    <Grid item xs={12} md={1}></Grid>
                    {/* <Recaptcha
                      ref={(ref) => (this.recaptcha = ref)}
                      sitekey={CaptchaSiteKeyMasters}
                      size="invisible"
                      onResolved={() => this.onResolved()}
                    /> */}
                  </Grid>
                </Grid>
              </div>
            </Fragment>
          </div>
        </Paper>
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  return {
    districtList: state.AssignDuty.districtList,
    squardList: state.AssignDuty.squardList,
    field: state.AssignDuty.field,
    zone: state.AssignDuty.zone,
    duties: state.AssignDuty.duties,
    saveSuccess: state.AssignDuty.saveSuccess,
    message: state.AssignDuty.message,
    error: state.AssignDuty.error,
    statusmessage: state.AssignDuty.statusmessage,
    saveStaus: state.AssignDuty.saveStaus,
  };
}

function mapDispatchToProps(dispach) {
  return {
    fetchDistrict: () => dispach(fetchDistrict()),
    fetchSqad: (id) => dispach(fetchSqad(id)),
    fetchSquardDetails: (id) => dispach(fetchSquardDetails(id)),
    navigateTo: (url) => dispach(push(url)),
    assignDuty: (data) => dispach(assignDuty(data)),
    resetDuties: () => dispach(resetDuties()),
    showSnackbar: (message, type) =>
      dispach(snackbarActions.showSnackbar(message, type)),
    resetOfficeandField: () => dispach(resetOfficeandField()),
  };
}
const styledAssignDuty = withStyles(styles)(AssignDuty);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(styledAssignDuty)
);

// import React, { Fragment } from "react";
// import {
//   Toolbar,
//   Typography,
//   withStyles,
//   Paper,
//   Grid,
//   Button,
//   TextField,
//   FormControl,
//   FormHelperText,
//   IconButton,
//   Divider,
// } from "@material-ui/core";
// import classNames from "classnames";
// import propTypes from "prop-types";
// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
// } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
// import validator from "../../../services/validator";
// import { find } from "lodash";
// import { connect } from "react-redux";
// import { withRouter } from "react-router";
// import {
//   assignDuty,
//   fetchSqad,
//   fetchDistrict,
//   fetchSquardDetails,
//   resetDuties,
//   resetOfficeandField,
// } from "./data/action";
// import moment from "moment";
// import { snackbarActions } from "components/snackbar/data/action";
// import { push } from "connected-react-router";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import { dispatch } from "d3";
// import { ReactComponent as BackIcon } from "../../../assets/SvgIcons/back.svg";
// import Recaptcha from "react-google-invisible-recaptcha";
// import CaptchaSiteKeyMasters from "../../../services/siteKeyMasters";
// import { ReactComponent as DownArrow } from "../../../assets/SvgIcons/dropdown.svg";
// import { ReactComponent as DateTime } from "../../../assets/Visual Assets/date.svg";

// const toolbarStyles = (theme) => ({
//   root: {
//     marginTop: "11px",
//   },
//   spacer: {
//     flex: "1 1 20%",
//   },
//   actions: {
//     paddingRight: theme.spacing.unit,
//   },
//   title: {
//     flex: "0 0 auto",
//   },
//   tableTitle: {
//     textAlign: "left",
//     font: "normal normal bold 18px Karla",
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//     marginTop: "2px",
//     marginBottom: "13px",
//     marginLeft: "9px",
//   },
//   iconButton: {
//     paddingLeft: "0px",
//   },
//   divider: {
//     border: "1px solid #E7EBF4",
//     opacity: 1,
//   },
// });

// let EnhancedTableToolbar = (props) => {
//   const { classes } = props;
//   return (
//     <>
//       <Toolbar className={classNames(classes.root)}>
//         <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
//           {" "}
//           <IconButton
//             onClick={(e) => props.onBackButtonClick(e)}
//             classes={{ root: classes.iconButton }}
//           >
//             <BackIcon />
//           </IconButton>
//           Assign Duty Master
//         </Typography>
//         <div className={classes.spacer} />
//       </Toolbar>{" "}
//       <Divider className={classes.divider} />
//     </>
//   );
// };

// EnhancedTableToolbar.propTypes = {
//   classes: propTypes.object.isRequired,
// };

// EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

// const styles = (theme) => ({
//   grid: {
//     marginTop: "26px",
//     paddingTop: "17px",
//     paddingRight: "18px",
//     paddingLeft: "22px",
//     borderRadius: theme.border.radius,
//   },
//   root: {
//     background: "#FFFFFF 0% 0% no-repeat padding-box",
//     boxShadow: "0px 0px 4px #0000000D",
//     opacity: 1,
//     borderRadius: theme.border.radius,
//     width: "100%",
//     overflow: "hidden",
//     paddingBottom: "34px",
//   },

//   table: {
//     minWidth: 1020,
//   },
//   tableWrapper: {
//     overflowX: "auto",
//   },
//   textField: {
//     width: "94%",
//     textAlign: "left",
//     font: "normal normal normal 16px Karla",
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//     height: "45px",
//   },
//   errorautoComplete: {
//     width: "94%",
//     textAlign: "left",
//     font: "normal normal normal 16px Karla",
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//     height: "70px",
//   },
//   errorTextField: {
//     width: "94%",
//     textAlign: "left",
//     font: "normal normal normal 16px Karla",
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//     height: "60px",
//   },
//   paperStyle: {
//     paddingLeft: "33px",
//     paddingRight: "14px",
//     paddingBottom: "8px",
//   },
//   textFielddes: {
//     paddingTop: "1%",
//   },
//   subHeading: {
//     textAlign: "left",
//     font: "normal normal bold 16px Karla",
//     letterSpacing: "0px",
//     color: theme.colors.chartHeading,
//     opacity: 1,
//     paddingBottom: "9px",
//     paddingTop: "4px",
//   },
//   button: {
//     fontSize: theme.font.size3,
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//     letterSpacing: "0px",
//     backgroundColor: theme.palette.secondary.main,
//     color: "white",
//     opacity: 1,
//     textTransform: "none",
//     // paddingLeft: "38px",
//     // paddingRight: "37px",
//     background: "#1BB99A 0% 0% no-repeat padding-box",
//     height: "42px",
//     width: "121px",
//   },
//   clearButton: {
//     width: "121px",
//     height: "42px",
//     // marginRight: "10px",
//     textTransform: "none",
//     fontSize: theme.font.size3,
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//     letterSpacing: "0px",
//     backgroundColor: theme.colors.BlueButton,
//     textAlign: "center",
//     width: "121px",
//     background: "#E7EBF4 0% 0% no-repeat padding-box",
//   },
//   cancelButton: {
//     width: "120px",
//     marginBottom: "6%",
//     fontWeight: theme.font.fontWeight,
//     backgroundColor: theme.colors.whiteColor,
//   },
//   btnGrid: {
//     padding: 0,
//     paddingLeft: "12px",
//   },
//   btns: {
//     paddingTop: "5px",
//     paddingRight: "25%",
//   },
//   err: {
//     paddingLeft: "1%",
//   },
//   dense: {
//     marginTop: 16,
//   },
//   alignment: {
//     flexDirection: "row",
//   },
//   radbtns: {
//     paddingLeft: "5%",
//   },
//   formitems: {
//     paddingTop: "6%",
//   },
//   menu: {
//     width: 200,
//     height: 300,
//   },
//   timeGrid: {
//     paddingBottom: "65px",
//   },
//   textValue: {
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//     fontSize: theme.font.size1,
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//   },
//   popIconOpen: {
//     marginTop: "4px",
//     marginRight: "6px",
//   },
//   popIcon: {
//     marginTop: "6px",
//     marginRight: "6px",
//   },
//   textNoOptions: {
//     letterSpacing: "0px",
//     opacity: 1,
//     fontSize: "18px",
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//   },
//   formHelperText: {
//     color: theme.colors.redColor,
//   },
//   textLabel: {
//     letterSpacing: "0px",
//     color: "#797C82",
//     opacity: 1,
//     fontSize: theme.font.size3,
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//     padding: "0px",
//     margin: "0px",
//   },
//   subGrid: {
//     paddingBottom: "6px",
//   },
//   subDateGrid: {
//     paddingBottom: "18px",
//   },
//   mainGrid: { paddingBottom: "15px" },
//   dateIcon: {
//     marginTop: "0px",
//     marginRight: "-8px",
//   },
// });

// class AssignDuty extends React.Component {
//   constructor(props) {
//     super(props);
//     this.onResolved = this.onResolved.bind(this);
//     this.state = {
//       isVerified: true,
//       selectSquad: [],
//       selectDistrict: [],
//       squad_id: false,
//       data: {
//         captcha: "",
//         squad: "",
//         district: "",
//         field: "",
//         zone: "",
//         from_date: null,
//         to_date: null,
//         in_time: null,
//         out_time: null,
//       },
//       errors: {
//         squad: "",
//         district: "",
//         field: "",
//         zone: "",
//         from_date: "",
//         to_date: "",
//         in_time: "",
//         out_time: "",
//       },
//       rules: {
//         squad: {
//           required: true,
//         },
//         district: { required: true },
//         from_date: {
//           required: true,
//           isDateGreaterCurrent: true,
//           // isDateGreater: true,
//         },
//         to_date: {
//           required: true,
//           isDateGreaterCurrent: true,
//           // isDateGreater: true,
//         },
//         in_time: {
//           required: true,
//         },
//         out_time: {
//           required: true,
//         },
//       },
//     };
//   }
//   componentDidMount() {
//     this.props.fetchDistrict();
//   }

//   componentDidUpdate(prevProps, prevState) {
//     const { field, zone, squardList, districtList } = this.props;

//     if (prevProps.districtList != this.props.districtList) {
//       this.setState({
//         ...this.state,
//         selectDistrict: districtList,
//       });
//     }
//     if (prevProps.squardList != this.props.squardList) {
//       this.setState({
//         ...this.state,
//         selectSquad: this.props.squardList,
//       });
//     }
//     if (prevProps.zone !== this.props.zone) {
//       this.setState(
//         {
//           ...this.state,
//           data: {
//             ...this.state.data,
//             zone: this.props.zone,
//           },
//         },
//         () => {
//           if (prevProps.field !== this.props.field) {
//             this.setState({
//               ...this.state,
//               data: {
//                 ...this.state.data,
//                 field: this.props.field,
//               },
//             });
//           }
//         }
//       );
//     }

//     if (
//       prevProps.duties != this.props.duties &&
//       this.props.saveSuccess &&
//       this.props.statusmessage !== prevProps.statusmessage &&
//       !this.props.error &&
//       this.props.statusmessage !== "failed"
//     ) {
//       setTimeout(() => {
//         this.props.showSnackbar(this.props.message, "success");
//       }, 1000);
//       this.props.navigateTo("/duty-master");
//     }
//     if (
//       this.props.saveSuccess &&
//       !this.props.error &&
//       this.props.saveStaus !== prevProps.saveStaus &&
//       this.props.statusmessage === "failed"
//     ) {
//       this.props.showSnackbar(this.props.message, "error");
//     }
//     if (this.props.error && this.props.error !== this.state.alert) {
//       this.props.showSnackbar(this.props.message, "error");
//     }
//   }
//   onResolved = () => {
//     this.setState({
//       isVerified: true,
//       captcha: this.recaptcha.getResponse(),
//     });
//   };

//   handleSelectDistrict = (event, value) => {
//     // event.preventDefault();
//     this.recaptcha.execute();
//     this.setState(
//       {
//         data: {
//           ...this.state.data,
//           district: value,
//         },
//         errors: {
//           ...this.state.errors,
//           [event.target.name]: "",
//         },
//       },
//       () => {
//         this.setState(
//           {
//             data: {
//               ...this.state.data,
//               squad: "",
//               field: "",
//               zone: "",
//             },
//           },
//           () => {
//             let params = this.state.data.district.district_id;
//             this.props.fetchSqad(params);
//           }
//         );
//       }
//     );
//   };
//   handleSelectSquad = (event, value) => {
//     // event.preventDefault();
//     this.setState(
//       {
//         data: { ...this.state.data, squad: value },
//         errors: {
//           ...this.state.errors,
//           [event.target.name]: "",
//         },
//       },

//       () => {
//         this.setState(
//           {
//             data: {
//               ...this.state.data,
//               field: "",
//               zone: "",
//             },
//           },
//           () => {
//             this.props.resetOfficeandField();
//             let params = this.state.data.squad.squad_id;
//             this.props.fetchSquardDetails(params);
//           }
//         );
//       }
//     );
//   };
//   handleChangeTime = (event) => {
//     this.setState({
//       data: { ...this.state.data, [event.target.name]: event.target.value },
//       errors: {
//         ...this.state.errors,
//         [event.target.name]: "",
//       },
//     });
//   };
//   handleChangeDate = (event) => {
//     this.setState({
//       data: {
//         ...this.state.data,
//         [event.target.name]: moment(event).format("YYYY-MM-DD"),
//       },
//       errors: {
//         ...this.state.errors,
//         [event.target.name]: "",
//       },
//     });
//   };

//   validateDate = () => {
//     let formIsValid = true;
//     let from_date = moment(this.state.data.from_date).format("YYYY-MM-DD");
//     let to_date = moment(this.state.data.to_date).format("YYYY-MM-DD");
//     let current_date = moment(new Date()).format("YYYY-MM-DD");
//     let in_time = this.state.data.in_time;
//     let out_time = this.state.data.out_time;
//     let current_time = moment(new Date()).format("HH:mm:ss");

//     if (moment(from_date).isAfter(to_date)) {
//       formIsValid = false;
//       this.setState({
//         ...this.state,
//         errors: {
//           ...this.state.errors,
//           to_date: "Date should be after from date",
//         },
//       });
//     }
//     if (
//       moment(from_date).isSame(current_date) &&
//       moment(in_time).format("HH:mm:ss") < current_time
//     ) {
//       formIsValid = false;
//       this.setState({
//         ...this.state,
//         errors: {
//           ...this.state.errors,
//           in_time: "Time should not less than current time",
//         },
//       });
//     }

//     if (
//       moment(from_date).isSame(to_date) &&
//       moment(in_time).isAfter(out_time)
//     ) {
//       formIsValid = false;
//       this.setState({
//         ...this.state,
//         errors: {
//           ...this.state.errors,
//           out_time: "Time should be after In time",
//         },
//       });
//     }
//     return formIsValid;
//   };

//   onBackButtonClick = () => {
//     this.props.resetOfficeandField();
//     this.props.navigateTo("/duty-master");
//   };
//   resetDutyParams = () => {
//     this.setState({
//       ...this.state,
//       selectSquad: [],
//       data: {
//         ...this.state.data,
//         squad: "",
//         district: "",
//         field: "",
//         zone: "",
//         from_date: null,
//         to_date: null,
//         in_time: null,
//         out_time: null,
//       },
//       errors: {
//         squad: null,
//         district: null,
//         from_date: null,
//         to_date: null,
//         in_time: null,
//         out_time: null,
//       },
//     });
//     this.props.resetOfficeandField();
//   };
//   validate = () => {
//     const { rules, data } = this.state;
//     const errors = validator(rules)(data);
//     const hasErrors = find(errors, (error) => error !== "");
//     this.setState({ errors });
//     return !hasErrors;
//   };

//   performAction = (e) => {
//     if (this.state.isVerified) {
//       let params = {
//         captcha: this.state.captcha,
//         squad_id: this.state.data.squad.squad_id,
//         from_date: moment(this.state.data.from_date).format("YYYY-MM-DD"),
//         to_date: moment(this.state.data.to_date).format("YYYY-MM-DD"),
//         in_time: moment(this.state.data.in_time).format("HH:mm:ss"),
//         out_time: moment(this.state.data.out_time).format("HH:mm:ss"),
//       };
//       if (this.validate()) {
//         if (this.validateDate()) {
//           this.props.assignDuty(params);
//         }
//       }
//     } else {
//       this.recaptcha.execute();
//     }
//   };

//   render() {
//     const { classes, squardList, districtList, zone, field } = this.props;
//     const { selectSquad, selectDistrict, errors } = this.state;
//     return (
//       <Grid className={classes.grid}>
//         <Paper className={classes.root}>
//           <EnhancedTableToolbar onBackButtonClick={this.onBackButtonClick} />
//           <div className={classes.textFielddes}>
//             <Fragment>
//               <div>
//                 <Paper elevation={0} className={classes.paperStyle}>
//                   <Typography className={classes.subHeading}>
//                     Basic Information
//                   </Typography>
//                   <Grid container className={classes.mainGrid}>
//                     <Grid xs={12} md={4} item className={classes.subGrid}>
//                       {selectDistrict ? (
//                         <FormControl
//                           error={true}
//                           className={
//                             errors.district
//                               ? classes.errorautoComplete
//                               : classes.textField
//                           }
//                         >
//                           <Autocomplete
//                             disableClearable="true"
//                             disableOpenOnFocus="true"
//                             value={this.state.data.district}
//                             options={selectDistrict}
//                             getOptionLabel={(option) =>
//                               option.district_name ? option.district_name : ""
//                             }
//                             error={this.state.errors.district ? true : false}
//                             onChange={this.handleSelectDistrict}
//                             popupIcon={<DownArrow />}
//                             classes={{
//                               input: classes.textValue,
//                               option: classes.textValue,
//                               popupIndicatorOpen: classes.popIconOpen,
//                               popupIndicator: classes.popIcon,
//                               noOptions: classes.textNoOptions,
//                             }}
//                             renderInput={(params) => (
//                               <TextField
//                                 fullWidth
//                                 label="District"
//                                 name="district"
//                                 id="outlined-margin-dense"
//                                 margin="dense"
//                                 {...params}
//                                 variant="outlined"
//                                 required
//                                 InputLabelProps={{
//                                   classes: { root: classes.textLabel },
//                                 }}
//                                 error={errors.district ? true : false}
//                               ></TextField>
//                             )}
//                           />{" "}
//                           <FormHelperText className={classes.formHelperText}>
//                             {this.state.errors.district}
//                             <br></br> <br></br> <br></br>
//                           </FormHelperText>
//                         </FormControl>
//                       ) : null}
//                     </Grid>{" "}
//                     <Grid xs={12} md={4} item className={classes.subGrid}>
//                       {selectSquad ? (
//                         <FormControl error={true} className={classes.textField}>
//                           <Autocomplete
//                             disableClearable="true"
//                             disableOpenOnFocus="true"
//                             value={this.state.data.squad}
//                             options={selectSquad}
//                             getOptionLabel={(option) =>
//                               option.squad_name ? option.squad_name : ""
//                             }
//                             error={this.state.errors.squad ? true : false}
//                             onChange={this.handleSelectSquad}
//                             popupIcon={<DownArrow />}
//                             classes={{
//                               input: classes.textValue,
//                               option: classes.textValue,
//                               popupIndicatorOpen: classes.popIconOpen,
//                               popupIndicator: classes.popIcon,
//                               noOptions: classes.textNoOptions,
//                             }}
//                             renderInput={(params) => (
//                               <TextField
//                                 fullWidth
//                                 label="Squad"
//                                 name="squad"
//                                 id="outlined-margin-dense"
//                                 margin="dense"
//                                 {...params}
//                                 variant="outlined"
//                                 required
//                                 InputLabelProps={{
//                                   classes: { root: classes.textLabel },
//                                 }}
//                                 error={errors.squad ? true : false}
//                               ></TextField>
//                             )}
//                           />{" "}
//                           <FormHelperText className={classes.formHelperText}>
//                             {this.state.errors.squad}
//                           </FormHelperText>
//                         </FormControl>
//                       ) : null}
//                     </Grid>{" "}
//                     <Grid xs={12} md={4} item className={classes.subGrid}>
//                       <TextField
//                         label="Zone"
//                         className={classes.textField}
//                         inputProps={{ readOnly: true }}
//                         value={this.state.data.zone}
//                         // value={zone}
//                         margin="dense"
//                         fullWidth
//                         variant="outlined"
//                         InputLabelProps={{
//                           classes: { root: classes.textLabel },
//                         }}
//                         InputProps={{ classes: { root: classes.textValue } }}
//                       />
//                     </Grid>
//                     <Grid xs={12} md={4} item>
//                       <TextField
//                         label="Field"
//                         className={classes.textField}
//                         inputProps={{ readOnly: true }}
//                         value={this.state.data.field}
//                         //value={field}
//                         margin="dense"
//                         fullWidth
//                         variant="outlined"
//                         InputLabelProps={{
//                           classes: { root: classes.textLabel },
//                         }}
//                         InputProps={{ classes: { root: classes.textValue } }}
//                       />
//                     </Grid>
//                   </Grid>
//                 </Paper>
//                 <Paper elevation={0} className={classes.paperStyle}>
//                   <Typography className={classes.subHeading}>
//                     Duty Time
//                   </Typography>
//                   <Grid container className={classes.timeGrid}>
//                     <Grid xs={12} md={4} item className={classes.subDateGrid}>
//                       <FormControl
//                         error={true}
//                         className={
//                           errors.from_date
//                             ? classes.errorTextField
//                             : classes.textField
//                         }
//                       >
//                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                           <KeyboardDatePicker
//                             showTodayButton
//                             fullWidth
//                             required
//                             label="From Date"
//                             name="from_date"
//                             inputVariant="outlined"
//                             format="dd/MM/yyyy"
//                             margin="dense"
//                             value={this.state.data.from_date}
//                             error={errors.from_date ? true : false}
//                             onChange={(event) => {
//                               this.setState({
//                                 data: {
//                                   ...this.state.data,
//                                   from_date: moment(event).format("YYYY-MM-DD"),
//                                 },
//                                 errors: {
//                                   ...this.state.errors,
//                                   from_date: "",
//                                 },
//                               });
//                             }}
//                             KeyboardButtonProps={{
//                               "aria-label": "change date",
//                             }}
//                             keyboardIcon={
//                               <DateTime className={classes.dateIcon} />
//                             }
//                             InputLabelProps={{
//                               classes: { root: classes.textLabel },
//                             }}
//                             InputProps={{
//                               classes: { root: classes.textValue },
//                             }}
//                           />
//                         </MuiPickersUtilsProvider>
//                         <FormHelperText className={classes.formHelperText}>
//                           {this.state.errors.from_date} <br></br> <br></br>{" "}
//                         </FormHelperText>
//                       </FormControl>
//                     </Grid>
//                     <Grid xs={12} md={4} item className={classes.subDateGrid}>
//                       <FormControl error={true} className={classes.textField}>
//                         <KeyboardTimePicker
//                           required
//                           showTodayButton
//                           id="time-picker"
//                           label="In Time"
//                           inputVariant="outlined"
//                           margin="dense"
//                           min={this.state.data.in_time}
//                           value={this.state.data.in_time}
//                           error={errors.in_time ? true : false}
//                           onChange={(value) => {
//                             this.setState({
//                               data: {
//                                 ...this.state.data,
//                                 in_time: value,
//                               },
//                               errors: {
//                                 ...this.state.errors,
//                                 in_time: "",
//                               },
//                             });
//                           }}
//                           KeyboardButtonProps={{
//                             "aria-label": "change time",
//                           }}
//                           keyboardIcon={
//                             <DateTime className={classes.dateIcon} />
//                           }
//                           InputLabelProps={{
//                             classes: { root: classes.textLabel },
//                           }}
//                           InputProps={{ classes: { root: classes.textValue } }}
//                         />
//                         <FormHelperText className={classes.formHelperText}>
//                           {this.state.errors.in_time}
//                         </FormHelperText>
//                       </FormControl>
//                     </Grid>
//                     <Grid xs={12} md={4} item className={classes.subDateGrid}>
//                       <FormControl error={true} className={classes.textField}>
//                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                           <KeyboardDatePicker
//                             showTodayButton
//                             fullWidth
//                             required
//                             label="To Date"
//                             name="to_date"
//                             inputVariant="outlined"
//                             format="dd/MM/yyyy"
//                             margin="dense"
//                             value={this.state.data.to_date}
//                             error={errors.to_date ? true : false}
//                             // onChange={(value) => {
//                             //   this.setState({
//                             //     data: {
//                             //       ...this.state.data,
//                             //       to_date: value,
//                             //     },
//                             //   });
//                             // }}
//                             onChange={(event) => {
//                               this.setState({
//                                 data: {
//                                   ...this.state.data,
//                                   to_date: moment(event).format("YYYY-MM-DD"),
//                                 },
//                                 errors: {
//                                   ...this.state.errors,
//                                   to_date: "",
//                                 },
//                               });
//                             }}
//                             KeyboardButtonProps={{
//                               "aria-label": "change date",
//                             }}
//                             keyboardIcon={
//                               <DateTime className={classes.dateIcon} />
//                             }
//                             InputLabelProps={{
//                               classes: { root: classes.textLabel },
//                             }}
//                             InputProps={{
//                               classes: { root: classes.textValue },
//                             }}
//                           />
//                         </MuiPickersUtilsProvider>
//                         <FormHelperText className={classes.formHelperText}>
//                           {this.state.errors.to_date}
//                         </FormHelperText>
//                       </FormControl>
//                     </Grid>
//                     <Grid xs={12} md={4} item>
//                       <FormControl error={true} className={classes.textField}>
//                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                           <KeyboardTimePicker
//                             showTodayButton
//                             margin="dense"
//                             required
//                             id="Out Time"
//                             label="Out Time"
//                             inputVariant="outlined"
//                             value={this.state.data.out_time}
//                             error={errors.out_time ? true : false}
//                             onChange={(value) => {
//                               this.setState({
//                                 data: {
//                                   ...this.state.data,
//                                   out_time: value,
//                                 },
//                                 errors: {
//                                   ...this.state.errors,
//                                   out_time: "",
//                                 },
//                               });
//                             }}
//                             KeyboardButtonProps={{
//                               "aria-label": "change time",
//                             }}
//                             keyboardIcon={
//                               <DateTime className={classes.dateIcon} />
//                             }
//                             InputLabelProps={{
//                               classes: { root: classes.textLabel },
//                             }}
//                             InputProps={{
//                               classes: { root: classes.textValue },
//                             }}
//                           />
//                         </MuiPickersUtilsProvider>
//                         <FormHelperText className={classes.formHelperText}>
//                           {this.state.errors.out_time}
//                         </FormHelperText>
//                       </FormControl>
//                     </Grid>
//                   </Grid>
//                 </Paper>

//                 <Grid xs={12} className={classes.btns} container>
//                   <Grid item xs={12} md={6}></Grid>
//                   <Grid
//                     item
//                     xs={12}
//                     md={2}
//                     style={{
//                       textAlign: "right",
//                     }}
//                   >
//                     <Button
//                       disableElevation={true}
//                       variant="contained"
//                       className={classes.clearButton}
//                       onClick={() => this.resetDutyParams()}
//                     >
//                       Clear
//                     </Button>
//                   </Grid>
//                   <Grid item xs={12} md={3} className={classes.btnGrid}>
//                     <Button
//                       disableElevation={true}
//                       variant="contained"
//                       color="secondary"
//                       className={classes.button}
//                       onClick={() => this.performAction()}
//                     >
//                       Assign
//                     </Button>{" "}
//                     <Grid item xs={12} md={1}></Grid>
//                     <Recaptcha
//                       ref={(ref) => (this.recaptcha = ref)}
//                       sitekey={CaptchaSiteKeyMasters}
//                       size="invisible"
//                       onResolved={this.onResolved}
//                     />
//                   </Grid>
//                 </Grid>
//               </div>
//             </Fragment>
//           </div>
//         </Paper>
//       </Grid>
//     );
//   }
// }
// function mapStateToProps(state) {
//   return {
//     districtList: state.AssignDuty.districtList,
//     squardList: state.AssignDuty.squardList,
//     field: state.AssignDuty.field,
//     zone: state.AssignDuty.zone,
//     duties: state.AssignDuty.duties,
//     saveSuccess: state.AssignDuty.saveSuccess,
//     message: state.AssignDuty.message,
//     error: state.AssignDuty.error,
//     statusmessage: state.AssignDuty.statusmessage,
//     saveStaus: state.AssignDuty.saveStaus,
//   };
// }

// function mapDispatchToProps(dispach) {
//   return {
//     fetchDistrict: () => dispach(fetchDistrict()),
//     fetchSqad: (id) => dispach(fetchSqad(id)),
//     fetchSquardDetails: (id) => dispach(fetchSquardDetails(id)),
//     navigateTo: (url) => dispach(push(url)),
//     assignDuty: (data) => dispach(assignDuty(data)),
//     resetDuties: () => dispach(resetDuties()),
//     showSnackbar: (message, type) =>
//       dispach(snackbarActions.showSnackbar(message, type)),
//     resetOfficeandField: () => dispach(resetOfficeandField()),
//   };
// }
// const styledAssignDuty = withStyles(styles)(AssignDuty);
// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(styledAssignDuty)
// );
