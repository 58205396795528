import React from "react";
import * as d3 from "d3";
import { axisBottom, axisLeft } from "d3-axis";
import { Paper } from "@material-ui/core";

const margin = { top: 30, right: 5, bottom: 20, left: 100 };
class DeclarativeLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: null,
      currentData: null,
      height: 300,
      width: 500,
      event: null,
      data: [
        {
          country: "India",
          value: 273,
        },
        {
          country: "USA",
          value: 2310,
        },
        {
          country: "China",
          value: 1270,
        },
        {
          country: "UK",
          value: 553,
        },
        {
          country: "Germany",
          value: 731,
        },
        {
          country: "Sweden",
          value: 136,
        },
        {
          country: "France",
          value: 682,
        },
        {
          country: "Australia",
          value: 239,
        },
        {
          country: "Canada",
          value: 367,
        },
        {
          country: "Brazil",
          value: 442,
        },
      ],
    };
    this.yAxisRef = React.createRef();
    this.xAxisRef = React.createRef();
    this.gridRef = React.createRef();

    this.xScale = d3
      .scaleBand()
      .range([margin.left, this.state.width - margin.right])
      .domain(this.state.data.map((a) => a.country));

    this.yScale = d3
      .scaleLinear()
      .range([this.state.height - margin.bottom, margin.top])
      .domain([0, d3.max(this.state.data.map((a) => a.value))]);

    this.xAxis = axisBottom(this.xScale);
    this.yAxis = axisLeft(this.yScale);
    this.grid = d3
      .axisLeft()
      .scale(this.yScale)
      .tickSize(-this.state.width + margin.left, 0, 0)
      .tickFormat("");

    this.line = d3
      .line()
      .defined((d) => {
        return d !== null;
      })
      .x((d) => this.xScale(d.country))
      .y((d) => this.yScale(d.value))
      .curve(d3.curveMonotoneX);
  }

  componentDidMount() {
    d3.select(this.xAxisRef.current).call(this.xAxis);
    d3.select(this.yAxisRef.current).call(this.yAxis);
    d3.select(this.gridRef.current).call(this.grid);
  }

  highlightBar = (event, currentIndex, currentData) => {
    if (event) {
      event.persist();
      this.setState({
        event,
      });
      d3.select(event.target)
        .transition()
        .duration(300)
        .attr("x", this.xScale(currentData.country) - 5)
        .attr("width", this.xScale.bandwidth() + 10);
    }
    this.setState({
      currentIndex,
      currentData,
    });
  };

  onMouseOut = (e) => {
    d3.select(e.target)
      .transition()
      .duration(300)
      .attr("x", this.xScale(this.state.currentData.country))
      .attr("width", this.xScale.bandwidth());
    this.highlightBar(null);
  };
  render() {
    const { height, width, data } = this.state;

    return (
      <Paper style={{ overflow: "auto" }}>
        <svg
          width="100%"
          height="100%"
          preserveAspectRatio="xMinYMin"
          viewBox={"0 0 " + width + " " + 350}
          style={{ display: "block", margin: "auto", padding: "20px" }}
        >
          <g
            ref={this.xAxisRef}
            transform={`translate(0, ${height - margin.bottom})`}
          />
          <g ref={this.yAxisRef} transform={`translate(${margin.left}, 0)`} />
          <g
            ref={this.gridRef}
            className="grid"
            transform={`translate(${margin.left}, 0)`}
          />
          <g>
            <path d={this.line(data)} style={{ stroke: "red", fill: "none" }} />
          </g>

          <text x={width / 2} y={margin.top - 10}>
            Country and languages
          </text>
          <text x={width / 2} y={height + margin.bottom}>
            Country
          </text>
          <text
            x={margin.left}
            y={height / 2 - margin.left / 2}
            transform={`rotate(-90,${margin.left},${height / 2})`}
          >
            GDP
          </text>
        </svg>
      </Paper>
    );
  }
}

export default DeclarativeLine;
