import {
  FETCH_FIELD,
  FETCH_FIELD_FAILED,
  FETCH_ZONE,
  FETCH_OFFICE,
  FETCH_OFFICE_FAILED,
  FETCH_LEADER,
  FETCH_LEADER_FAILED,
  FETCH_ZONE_FAILED,
  FETCH_DISTRICT,
  FETCH_DISTRICT_FAILED,
  ADD_EDIT_SQUAD_MASTERS,
  ADD_EDIT_SQUAD_MASTERS_FAILED,
  RESET_SQUAD_DETAIL,
  FETCH_SQUAD_DETAILS,
  FETCH_SQUAD_DETAILS_FAILED,
  FETCH_MEMBER_DATA,
  FETCH_MEMBER_DATA_FAILED,
  FILTER_CHANGE,
  FILTER_CHANGE_ERROR,
} from "./constants";
const initialState = {
  fetchSquadDetails: {},
  field: [],
  zone: [],
  district: [],
  office: [],
  leader: [],
  memberData: [],
  error: "",
  saveSuccess: false,
  statusmessage: "",
  message: "",
  saveStaus: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_MEMBER_DATA:
      return {
        ...state,
        memberData: action.data.data,
      };
    case FETCH_MEMBER_DATA_FAILED:
      return {
        ...state,
        memberData: "",
      };
    case FETCH_SQUAD_DETAILS:
      return {
        ...state,
        fetchSquadDetails: action.data.data,
      };

    case FETCH_SQUAD_DETAILS_FAILED:
      return {
        ...state,
        fetchSquadDetails: {},
      };

    case FETCH_FIELD:
      return {
        ...state,
        field: action.data.data,
      };
    case FETCH_FIELD_FAILED:
      return {
        ...state,
        field: [],
      };
    case FETCH_ZONE:
      return {
        ...state,
        zone: action.data.data.rows,
      };
    case FETCH_ZONE_FAILED:
      return {
        ...state,
        zone: [],
      };
    case FETCH_DISTRICT:
      return {
        ...state,
        district: action.data.data,
      };
    case FETCH_DISTRICT_FAILED:
      return {
        ...state,
        district: [],
      };
    case FETCH_OFFICE:
      return {
        ...state,
        office: action.data.data,
      };
    case FETCH_OFFICE_FAILED:
      return {
        ...state,
        office: [],
      };
    case FETCH_LEADER:
      return {
        ...state,
        leader: action.data.data,
      };
    case FETCH_LEADER_FAILED:
      return {
        ...state,
        leader: [],
      };
    case ADD_EDIT_SQUAD_MASTERS:
      return {
        ...state,
        saveSuccess: true,
        message: action.data.message,
        statusmessage: action.data.status,
        saveStaus: !state.saveSuccess,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };

    case ADD_EDIT_SQUAD_MASTERS_FAILED:
      return {
        ...state,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };
    case RESET_SQUAD_DETAIL:
      return {
        ...initialState,
      };
    case FILTER_CHANGE:
      return {
        ...state,
        squadList: action.data.data,
      };
    case FILTER_CHANGE_ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
}
