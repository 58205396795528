import {
  FETCH_SQUAD_LIST,
  DELETE_SQUAD,
  RESET_DELETE,
  FETCH_SQUAD_LIST_ERROR,
  DELETE_SQUAD_ERROR,
  FETCH_DISTRICT,
  FETCH_DISTRICT_FAILED,
  DIALOGUE_WINDOW_STATUS,
  FILTER_CHANGE,
  FILTER_CHANGE_ERROR,
} from "./constants";
import SquadMasterService from "../../../service/SquadService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchSqadList = (page, limit, filterParams) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SquadMasterService()
      .fetchSquadList(page, limit, filterParams)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };
  function success(data) {
    return { type: FETCH_SQUAD_LIST, data };
  }
  function failure(error) {
    return { type: FETCH_SQUAD_LIST_ERROR, error };
  }
};
export const dialogueWindowStatus = () => {
  return (dispatch) => {
    // dispatch(progressActions.showProgress());
    dispatch(dialogueWindowStatus());
  };
  function dialogueWindowStatus() {
    return { type: DIALOGUE_WINDOW_STATUS };
  }
};
export const fetchDistricts = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SquadMasterService().fetchDistricts().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_DISTRICT, data };
  }

  function failure(error) {
    return { type: FETCH_DISTRICT_FAILED, error };
  }
};
export const onFilterChange = (page, limit, squadname, district) => {
  let squadMasterService = new SquadMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    squadMasterService
      .onFilterChange(page, limit, squadname, district)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: FILTER_CHANGE, data };
  }
  function failure(error) {
    return { type: FILTER_CHANGE_ERROR, error };
  }
};
export const deleteSquad = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SquadMasterService().deleteSquad(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: DELETE_SQUAD, data };
  }
  function failure(error) {
    return { type: DELETE_SQUAD_ERROR, error };
  }
};

export function resetDelete() {
  return {
    type: RESET_DELETE,
  };
}
