import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "connected-react-router";
import Login from "./Login";
import {
  doLogin,
  getSvgCaptcha,
  setUserInput,
  resetUserInput,
} from "./data/actions";
import { snackbarActions } from "components/snackbar/data/action";

function mapStateToProps(state) {
  return {
    user: state.authentication.user,
    isAuthenticated: state.authentication.isAuthenticated,
    captchaImgs: state.authentication.captchaImg,
    captchaText: state.authentication.captchaText,
    checkStatus: state.authentication.checkStatus,
    authStatus: state.authentication.authStatus,
    userInput: state.authentication.userInput,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => dispatch(push(url)),
    doLogin: (userCredentials) => dispatch(doLogin(userCredentials)),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
    getSvgCaptcha: () => dispatch(getSvgCaptcha()),
    setUserInput: (data) => dispatch(setUserInput(data)),
    resetUserInput: () => dispatch(resetUserInput()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
