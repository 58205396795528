import {
  SELECTED_RECORD,
  FETCH_RECORD_FAIL,
  FETCH_RECORD_SUCCESS,
  RESET_RECORD,
} from "./constants";

const initialState = {
  record: null,
  recordData: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SELECTED_RECORD:
      return {
        ...state,
        record: action.record,
      };
    case FETCH_RECORD_SUCCESS:
      return {
        ...state,
        recordData: action.data,
      };
    case FETCH_RECORD_FAIL:
      return {
        ...initialState,
      };
    case RESET_RECORD: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
