import React from "react";
import { connect } from "react-redux";
import * as d3 from "d3";
import { getAlertAndWarningCount } from "./data/action";
import "./styles.css";
import {
  Toolbar,
  Typography,
  withStyles,
  AppBar,
  Grid,
  Divider,
  Paper,
  Tooltip,
} from "@material-ui/core";
import classNames from "classnames";
import SkeletonScreen from "../../../components/skelton_screen/piechartSkeleton.js";

const margin = { top: 30, right: 5, bottom: 30, left: 100 };
const styles = (theme) => ({
  paper: {
    // width: "607px",
    height: "346px",
    [theme.breakpoints.up("xl")]: {
      height: "470px",
    },
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
    "@media screen and (min-width: 1900px)": {
      minHeight: "40vh",
    },
  },
  openPaper: {
    // width: "607px",
    height: "346px",
    [theme.breakpoints.up("xl")]: {
      height: "470px",
    },
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
    "@media screen and (min-width: 1900px)": {
      minHeight: "43vh",
    },
  },
  appbar: {
    backgroundColor: "#FFFFFF",
    borderRadius: "15px",
    // width: "607px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    opacity: 1,
    paddingRight: "14px",
    paddingTop: "8px",
  },
  root: {
    borderRadius: theme.border.radius,
    paddingRight: theme.spacing.unit,
    backgroundColor: theme.colors.whiteColor,
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    color: theme.colors.chartHeading,
    textAlign: "left",
    fontWeight: theme.font.Bold,
    font: "normal normal bold 16px/22px Karla",
    letterSpacing: "0px",
    opacity: 1,
    marginLeft: "8px",
  },
  passedToday: {
    color: theme.colors.chartHeading,
    textAlign: "left",
    fontWeight: "normal",
    font: "normal normal normal 14px/36px Karla",
    letterSpacing: "0px",
    opacity: 0.5,
    paddingRight: theme.spacing.unit,
    marginLeftt: "31px",
  },
  container: { paddingRight: "31px", paddingLeft: "10px" },
  yellowDiv: {
    backgroundColor: "#00BBFF",
    width: "13px",
    height: "13px",
    borderRadius: theme.border.legentRadius,
  },
  greenDiv: {
    // marginLeft: "120px",
    backgroundColor: theme.colors.green,
    width: "13px",
    height: "13px",
    borderRadius: theme.border.legentRadius,
  },
  orangeDiv: {
    // marginLeft: "120px",
    backgroundColor: theme.colors.orange,
    width: "13px",
    height: "13px",
    borderRadius: theme.border.legentRadius,
  },
  redDiv: {
    // marginLeft: "120px",
    backgroundColor: theme.colors.red,
    width: "13px",
    height: "13px",
    borderRadius: theme.border.legentRadius,
  },
  blueDiv: {
    // marginLeft: "120px",
    fontFamily: theme.font.family,
    backgroundColor: theme.colors.blue,
    width: "13px",
    height: "13px",
    borderRadius: theme.border.legentRadius,
  },
  legentGrid: {
    padding: "13px",
    display: "flex",
  },
  devider: {
    height: "1px",
    border: "1px dashed #C5D4DF",
    opacity: 1,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
  },
  textDiv: {
    marginLeft: "7px",
    fontSize: "14px",
    textAlign: "left",
    color: theme.colors.chartHeading,
    letterSpacing: "0px",
    opacity: 1,
    fontFamily: theme.font.family,
  },
  legents: {
    marginTop: "20px",
    paddingLeft: "80px",
  },
  piechartGrid: { marginTop: "1px" },
  tooltips: {
    backgroundColor: theme.colors.chartHeading,
    color: theme.colors.whiteColor,
    fontFamily: theme.font.family,
    width: "40px",
    padding: "5px",
    textAlign: "center",
  },
  tooltipArrows: { color: theme.colors.chartHeading },
});
class DeclrativePie extends React.Component {
  constructor(props) {
    sessionStorage.setItem("pieChartError", false);
    super(props);
    this.state = {
      currentData: null,
      height: 270,
      width: 300,
      data: [],
      datas: [],
      colors: ["#FF9500", "#00BBFF", "#FF7B9C", "#28AC47", "#3E56DE"],
    };
    this.radius = Math.min(this.state.width, this.state.height) / 2;
    this.pie = d3
      .pie()
      .sort(null)
      .value((data) => data.value);
    this.arc = d3
      .arc()
      .innerRadius(this.radius - 35)
      .outerRadius(this.radius)
      .padAngle(0.06)
      .padRadius(100)
      .cornerRadius(0);
    this.color = d3.scaleOrdinal(d3.schemeCategory10);

    this.arc1 = d3
      .arc()
      .innerRadius(0)
      .outerRadius(85)
      .cornerRadius(0)
      .padAngle(0);
  }
  onMouseOver = (e, currentData) => {
    // this.setState({
    //   currentData,
    // });
    d3.select(e.target)
      .attr("stroke", "white")
      .transition()
      .duration(100)
      .attr("stroke-width", 7);
  };

  onMouseOut = (e) => {
    // this.setState({
    //   currentData: null,
    // });
    d3.select(e.target)
      .attr("stroke", "white")
      .transition()
      .delay(100)
      .attr("stroke-width", 0);
  };
  transformArcText = (d) => {
    var _d = this.arc.centroid(d);
    _d[0] *= 1.15; //multiply by a constant factor
    _d[1] *= 1.15; //multiply by a constant factor
    return "translate(" + _d + ")";
  };
  componentDidMount() {
    let params = [];
    if (
      this.props.locationData &&
      this.props.locationData.location === "All Camera"
    ) {
      params.push(-1);
      this.props.getAlertAndWarningCount(params);
    } else if (
      this.props.locationData &&
      this.props.locationData.cameraId !== undefined
    ) {
      this.props.getAlertAndWarningCount(this.props.locationData.cameraId);
    } else {
      params.push(-1);
      this.props.getAlertAndWarningCount(params);
    }
  }
  componentWillUnmount() {
    sessionStorage.setItem("pieChartError", false);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data != this.props.data) {
      this.setState({
        ...this.state,
        datas: this.props.data,
      });
    }
    // if (prevProps.locationData != this.props.locationData) {
    //   let params = [];
    //   if (this.props.locationData.location === "All Camera") {
    //     params.push(-1);
    //     this.props.getAlertAndWarningCount(params);
    //   } else {
    //     this.props.getAlertAndWarningCount(this.props.locationData.cameraId);
    //   }
    // }

    if (prevProps.overviewData != this.props.overviewData) {
      let data = [
        { type: "Alerts", value: this.props.overviewData.alert_count },
        { type: "Low_Alerts", value: this.props.overviewData.low_alert_count },
        { type: "Warnings", value: this.props.overviewData.warning_count },
        { type: "Verified", value: this.props.overviewData.verified_count },
        { type: "others", value: this.props.overviewData.other_vehicle_count },
      ];

      if (data[3].value !== undefined) {
        this.setState({
          data: data,
        });
      }
    }
  }

  render() {
    const { width, height, data, datas } = this.state;
    const pieHeight = height + margin.bottom + margin.top;
    const { classes } = this.props;
    return (
      <Paper
        className={this.props.showAppDrawer ? classes.openPaper : classes.paper}
        elevation={0}
      >
        {/* {data.length > 0 && ( */}
        <>
          <AppBar position="static" elevation={0} className={classes.appbar}>
            <Toolbar variant="dense" className={classes.root}>
              <Typography className={classes.tableTitle}>
                Alerts and Reviews
              </Typography>
              <div className={classes.spacer} />
              <Typography className={classes.passedToday}>
                Generated Today
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid xs={12} container className={classes.container}>
            <Grid item xs={6} className={classes.piechartGrid}>
              {data && data.length > 0 ? (
                <svg
                  width={this.props.showAppDrawer ? "113%" : "93%"}
                  height={this.props.showAppDrawer ? "120%" : "100%"}
                  preserveAspectRatio="xMinYMin"
                  viewBox={"0 0 " + width + " " + 313}
                  style={{
                    display: "block",
                    margin: "auto",
                    padding: "20px",
                    paddingLeft: "0px",
                    marginLeft: "12px",
                  }}
                >
                  <g
                    transform={`translate(${width / 2}, ${
                      (pieHeight - margin.top) / 2
                    })`}
                  >
                    {this.pie(data).map((d, i) => {
                      return (
                        <g>
                          <Tooltip
                            title={d.data.value}
                            arrow
                            classes={{
                              tooltip: classes.tooltips,
                              arrow: classes.tooltipArrows,
                            }}
                          >
                            <path
                              d={this.arc(d)}
                              fill={this.state.colors[i]}
                              onMouseOut={(e) => this.onMouseOut(e)}
                              onMouseOver={(e) => this.onMouseOver(e, d.data)}
                            />
                          </Tooltip>
                        </g>
                      );
                    })}
                  </g>
                  <g
                    transform={`translate(${width / 2}, ${
                      (pieHeight - margin.top) / 2
                    })`}
                  >
                    {this.pie(data).map((d, i) => {
                      return (
                        <g>
                          <path d={this.arc1(d)} fill="#F5F5FB" />
                        </g>
                      );
                    })}
                  </g>
                  <g>
                    <text
                      x={width / 2}
                      y={(pieHeight - margin.top) / 2}
                      textAnchor="middle"
                      className="circlecount"
                      style={{ fontFamily: "karla" }}
                    >
                      Total
                    </text>
                    <text
                      x={width / 2}
                      y={(pieHeight - margin.top) / 2 + 25}
                      textAnchor="middle"
                      className="circletext"
                      style={{ fontFamily: "karla" }}
                    >
                      {" "}
                      {/* {(this.props.overviewData.alert_count
                        ? this.props.overviewData.alert_count
                        : 0) +
                        (this.props.overviewData.low_alert_count
                          ? this.props.overviewData.low_alert_count
                          : 0) +
                        (this.props.overviewData.warning_count
                          ? this.props.overviewData.warning_count
                          : 0) +
                        (this.props.overviewData.verified_count
                          ? this.props.overviewData.verified_count
                          : 0) +
                        (this.props.overviewData.other_vehicle_count
                          ? this.props.overviewData.other_vehicle_count
                          : 0)} */}
                      {this.props.overviewData.vehicle_count
                        ? this.props.overviewData.vehicle_count
                        : 0}
                    </text>{" "}
                  </g>
                </svg>
              ) : datas && datas.length > 0 ? (
                <svg
                  width={this.props.showAppDrawer ? "113%" : "93%"}
                  height={this.props.showAppDrawer ? "120%" : "100%"}
                  preserveAspectRatio="xMinYMin"
                  viewBox={"0 0 " + width + " " + 313}
                  style={{
                    display: "block",
                    margin: "auto",
                    padding: "20px",
                    paddingLeft: "0px",
                    marginLeft: "12px",
                  }}
                >
                  <g
                    transform={`translate(${width / 2}, ${
                      (pieHeight - margin.top) / 2
                    })`}
                  >
                    {this.pie(datas).map((d, i) => {
                      return (
                        <g>
                          <Tooltip
                            title={d.value}
                            arrow
                            classes={{
                              tooltip: classes.tooltips,
                              arrow: classes.tooltipArrows,
                            }}
                          >
                            <path
                              d={this.arc(d)}
                              fill={this.state.colors[i]}
                              onMouseOut={(e) => this.onMouseOut(e)}
                              onMouseOver={(e) => this.onMouseOver(e, d.data)}
                            />
                          </Tooltip>
                        </g>
                      );
                    })}
                  </g>
                  <g
                    transform={`translate(${width / 2}, ${
                      (pieHeight - margin.top) / 2
                    })`}
                  >
                    {this.pie(datas).map((d, i) => {
                      return (
                        <g>
                          <path d={this.arc1(d)} fill="#F5F5FB" />
                        </g>
                      );
                    })}
                  </g>
                  <g>
                    <text
                      x={width / 2}
                      y={(pieHeight - margin.top) / 2}
                      textAnchor="middle"
                      className="circlecount"
                      style={{ fontFamily: "karla" }}
                    >
                      Total
                    </text>
                    <text
                      x={width / 2}
                      y={(pieHeight - margin.top) / 2 + 25}
                      textAnchor="middle"
                      className="circletext"
                      style={{ fontFamily: "karla" }}
                    >
                      {this.props.overviewData.vehicle_count
                        ? this.props.overviewData.vehicle_count
                        : 0}
                    </text>{" "}
                  </g>
                </svg>
              ) : sessionStorage.getItem("pieChartError") === "true" ? null : (
                <SkeletonScreen />
              )}
            </Grid>
            <Grid item xs={6} className={classes.legents}>
              <Divider className={classes.devider} />
              <Grid item className={classes.legentGrid}>
                <div className={classes.orangeDiv}></div>
                <div className={classes.textDiv}>Alerts</div>
              </Grid>{" "}
              <Divider className={classes.devider} />
              <Grid item className={classes.legentGrid}>
                <div className={classes.yellowDiv}></div>
                <div className={classes.textDiv}>Low Alerts</div>
              </Grid>{" "}
              <Divider className={classes.devider} />
              <Grid item className={classes.legentGrid}>
                <div className={classes.redDiv}></div>
                <div className={classes.textDiv}> Reviews</div>
              </Grid>{" "}
              <Divider className={classes.devider} />
              <Grid item className={classes.legentGrid}>
                <div className={classes.greenDiv}></div>
                <div className={classes.textDiv}> Verified</div>
              </Grid>{" "}
              <Divider className={classes.devider} />
              <Grid item className={classes.legentGrid}>
                <div className={classes.blueDiv}></div>
                <div className={classes.textDiv}> Others </div>
              </Grid>{" "}
              <Divider className={classes.devider} />
            </Grid>
          </Grid>
        </>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationData: state.locationSelector.filterParams,
    data: state.alertAndWarningCount.alertAndWarning,
    overviewData: state.overview.overviewData,
    showAppDrawer: state.drawer.showAppDrawer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAlertAndWarningCount: (params) =>
      dispatch(getAlertAndWarningCount(params)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DeclrativePie));
