import { connect } from "react-redux";
import { withRouter } from "react-router";
import DesignationMasterList from "./designationMasterList";
import { push } from "connected-react-router";
import { resetDelete } from "./data/action";
import { fetchDesignationMaster } from "./components/designationMaster_details/data/action";
import { snackbarActions } from "../../components/snackbar/data/action";
import { deleteDesignationMaster } from "./components/designationMaster_details/data/action";
import {
  toggleFilterWindow,
  setFilterParams,
  resetFilterParams,
} from "../../components/filter_form/data/action";
function mapStateToProps(state) {
  return {
    designationMaster: state.designationMaster.designationMaster,
    deleteStatus: state.designationMaster.deleteStatus,
    showDialogue: state.showDialogue,
    showFilterWindow: state.filter.showFilterWindow,
    filterParams: state.filter.filterParams,
    loading: state.progress.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteDesignationMaster: (id) => dispatch(deleteDesignationMaster(id)),
    navigateTo: (url) => dispatch(push(url)),
    fetchDesignationMaster: (page, rowsPerPage) =>
      dispatch(fetchDesignationMaster(page, rowsPerPage)),
    resetDelete: () => dispatch(resetDelete()),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
    resetFilterParams: () => dispatch(resetFilterParams()),
    setFilterParams: (filterParams) => dispatch(setFilterParams(filterParams)),
    toggleFilterWindow: () => dispatch(toggleFilterWindow()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DesignationMasterList)
);
