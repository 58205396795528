import { connect } from "react-redux";
import { withRouter } from "react-router";
import DutyList from "./dutyMasterList";
import { push } from "connected-react-router";
import {
  fetchDutyList,
  deleteDuty,
  // deleteLocation,
  resetDelete,
  fetchDistrict,
} from "./data/action";
import {
  toggleFilterWindow,
  resetFilterParams,
  setFilterParams,
  setValue,
} from "../../components/filterduty_form/data/action";
import { snackbarActions } from "components/snackbar/data/action";
import { dialogueWindowStatus, onFilterChange } from "./data/action";

import { fromUnixTime } from "date-fns";

function mapStateToProps(state) {
  return {
    dutyLists: state.dutyList.dutyLists,
    dutyCount: state.dutyList.dutyCount,
    deleteSuccessReposnse: state.dutyList.deleteSuccessMessage,
    deleteSuccess: state.dutyList.deleteSuccess,
    showFilterWindow: state.filter.showFilterWindow,
    filterParams: state.filter.filterParams,
    showDialogue: state.showDialogue,
    duty: state.dutyList.dutyListFilter,
    dist: state.dutyList.district,
    val: state.filter.val,
    loading: state.progress.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onFilterChange: (page, limit, squadname, district) =>
      dispatch(onFilterChange(page, limit, squadname, district)),
    fetchDutyList: (page, limit) => dispatch(fetchDutyList(page, limit)),
    fetchDistrict: () => dispatch(fetchDistrict()),
    navigateTo: (url) => dispatch(push(url)),
    deleteDuty: (id) => dispatch(deleteDuty(id)),
    resetDelete: () => dispatch(resetDelete()),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
    setValue: () => dispatch(setValue()),

    toggleFilterWindow: () => dispatch(toggleFilterWindow()),
    resetFilterParams: () => dispatch(resetFilterParams()),
    setFilterParams: (filterParams) => dispatch(setFilterParams(filterParams)),
    dialogueWindowStatus: () => dispatch(dialogueWindowStatus()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DutyList)
);
