import {
  FETCH_USERTYPE,
  FETCH_USERTYPE_FAILED,
  FETCH_ROLE,
  FETCH_ROLE_FAILED,
  FETCH_FIELD,
  FETCH_FIELD_FAILED,
  FETCH_ZONE,
  FETCH_ZONE_FAILED,
  FETCH_DISTRICT,
  FETCH_DISTRICT_FAILED,
  ADD_EDIT_USER_MASTERS,
  ADD_EDIT_USER_MASTERS_FAILED,
  RESET_USER_MASTER_STATE,
  FETCH_CAMERA,
  FETCH_CAMERA_FAILED,
  FETCH_OFFICE_LIST,
  FETCH_OFFICE_LIST_FAILED,
  FETCH_DESIGNATION_LIST,
  FETCH_DESIGNATION_LIST_FAILED,
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_FAILED,
  FETCH_EDIT_CAMERA,
  FETCH_EDIT_CAMERA_FAILED,
  CHECK_USER_NAME,
  CHECK_USER_NAME_FAILED,
} from "./constants";
const initialState = {
  fetchedOfficeList: [],
  fetchedDesignationList: [],
  fetchedUserDetails: {},
  editCameras: [],
  cameras: [],
  fetcheduserType: [],
  field: [],
  fetchedzone: [],
  role: [],
  district: [],
  error: "",
  saveSuccess: false,
  message: "",
  checkSuccess: false,
  statusmessage: "",
  saveStaus: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OFFICE_LIST:
      return {
        ...state,
        // fetchedOfficeList: action.data.data.rows,
        fetchedOfficeList: action.data.data,
      };
    case FETCH_OFFICE_LIST_FAILED:
      return {
        ...state,
        fetchedOfficeList: [],
      };
    case FETCH_DESIGNATION_LIST:
      return {
        ...state,
        fetchedDesignationList: action.data.data.rows,
      };
    case FETCH_DESIGNATION_LIST_FAILED:
      return {
        ...state,
        fetchedDesignationList: [],
      };

    case FETCH_USER_DETAILS:
      return {
        ...state,
        fetchedUserDetails: action.data.data,
      };

    case FETCH_USER_DETAILS_FAILED:
      return {
        ...state,
        fetchedUserDetails: {},
      };
    case FETCH_EDIT_CAMERA:
      return {
        ...state,
        editCameras: action.data.data,
      };
    case FETCH_EDIT_CAMERA_FAILED:
      return {
        ...state,
        editCameras: [],
      };
    case FETCH_USERTYPE:
      return {
        ...state,
        fetcheduserType: action.data.data,
      };
    case FETCH_USERTYPE_FAILED:
      return {
        ...state,
        fetcheduserType: [],
      };
    case FETCH_ROLE:
      return {
        ...state,
        role: action.data.data,
      };
    case FETCH_ROLE_FAILED:
      return {
        ...state,
        role: [],
      };

    case FETCH_CAMERA:
      return {
        ...state,
        cameras: action.data.data,
      };
    case FETCH_CAMERA_FAILED:
      return {
        ...state,
        cameras: [],
      };
    case FETCH_FIELD:
      return {
        ...state,
        field: action.data.data,
      };
    case FETCH_FIELD_FAILED:
      return {
        ...state,
        field: [],
      };
    case FETCH_ZONE:
      return {
        ...state,
        fetchedzone: action.data.data.rows,
      };
    case FETCH_ZONE_FAILED:
      return {
        ...state,
        fetchedzone: [],
      };
    case FETCH_DISTRICT:
      return {
        ...state,
        district: action.data.data,
      };
    case FETCH_DISTRICT_FAILED:
      return {
        ...state,
        district: [],
      };
    case ADD_EDIT_USER_MASTERS:
      return {
        ...state,
        saveSuccess: true,
        message: action.data.message,
        statusmessage: action.data.status,
        saveStaus: !state.saveStaus,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };

    case ADD_EDIT_USER_MASTERS_FAILED:
      return {
        ...state,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };

    case CHECK_USER_NAME:
      sessionStorage.setItem("isusernameexists", action.data.status);
      return {
        ...state,
        checkSuccess: action.data.status === "failed" ? false : true,
        message: action.data.message,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };

    case CHECK_USER_NAME_FAILED:
      return {
        ...state,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };
    case RESET_USER_MASTER_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
