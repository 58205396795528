import { push } from "connected-react-router";
import HistoryView from "./HistoryView";
import { connect } from "react-redux";
import { setPlateNumber } from "../../../camera/components/recent_activity/data/action";
import { fetchRecord, fetchNextandPrev } from "./data/action";
import { fetchHistoryList } from "../../data/action";

function mapStateToProps(state) {
  return {
    historyList: state.historyList.historyList,
    fetchedRecord: state.historyView.fetchedRecord,
    loading: state.progress.loading,
    filterParams: state.filterhistory.filterParams,
    event_timestamp: state.historyList.event_timestamp,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => dispatch(push(url)),
    setPlateNumber: (plate) => dispatch(setPlateNumber(plate)),
    fetchNextandPrev: (data) => dispatch(fetchNextandPrev(data)),
    fetchRecord: (id, event_timestamp) =>
      dispatch(fetchRecord(id, event_timestamp)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryView);
