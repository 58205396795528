import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Table, Grid, Divider } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
// import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as FilterListIcon } from "../../assets/Visual Assets/filter.svg";
import { ReactComponent as ViewIcon } from "../../assets/Visual Assets/action_icon.svg";
import { ReactComponent as SortUp } from "../../assets/Visual Assets/sort_up.svg";
import { toDateTime } from "util/date";
import FilterWarning from "../../components/filterwarning_form";
// import FirstPageIcon from "@material-ui/icons/FirstPage";
// import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
// import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// import LastPageIcon from "@material-ui/icons/LastPage";
import messageConstants from "../../services/validator/MsgConsts/messageConstants";
// import StorageHandler from "../../util/storage/index";
import "../userMaster/style.css";
import Pagination from "@material-ui/lab/Pagination";
import SkeltonScreen from "../../components/skelton_screen";
// const actionsStyles = (theme) => ({
//   root: {
//     flexShrink: 0,
//     color: theme.palette.text.secondary,
//     marginLeft: theme.spacing.unit * 2.5,
//   },
// });

// class TablePaginationActions extends React.Component {
//   handleFirstPageButtonClick = (event) => {
//     this.props.onChangePage(event, 0);
//   };

//   handleBackButtonClick = (event) => {
//     this.props.onChangePage(event, this.props.page - 1);
//   };

//   handleNextButtonClick = (event) => {
//     this.props.onChangePage(event, this.props.page + 1);
//   };

//   handleLastPageButtonClick = (event) => {
//     this.props.onChangePage(
//       event,
//       Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
//     );
//   };

//   render() {
//     const { classes, count, page, rowsPerPage, theme } = this.props;

//     return (
//       <div className={classes.root}>
//         <IconButton
//           onClick={this.handleFirstPageButtonClick}
//           disabled={page === 0}
//           aria-label="First Page"
//         >
//           {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
//         </IconButton>
//         <IconButton
//           onClick={this.handleBackButtonClick}
//           disabled={page === 0}
//           aria-label="Previous Page"
//         >
//           {theme.direction === "rtl" ? (
//             <KeyboardArrowRight />
//           ) : (
//             <KeyboardArrowLeft />
//           )}
//         </IconButton>
//         <IconButton
//           onClick={this.handleNextButtonClick}
//           disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//           aria-label="Next Page"
//         >
//           {theme.direction === "rtl" ? (
//             <KeyboardArrowLeft />
//           ) : (
//             <KeyboardArrowRight />
//           )}
//         </IconButton>
//         <IconButton
//           onClick={this.handleLastPageButtonClick}
//           disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//           aria-label="Last Page"
//         >
//           {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
//         </IconButton>
//       </div>
//     );
//   }
// }

// TablePaginationActions.propTypes = {
//   classes: PropTypes.object.isRequired,
//   count: PropTypes.number.isRequired,
//   onChangePage: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired,
//   theme: PropTypes.object.isRequired,
// };

// const TablePaginationActionsWrapped = withStyles(actionsStyles, {
//   withTheme: true,
// })(TablePaginationActions);

// let counter = 0;
// function createData(plate, event_timestamp, confidence, lane_id, camera_id) {
//   counter += 1;
//   return {
//     id: counter,
//     plate,
//     event_timestamp,
//     confidence,
//     lane_id,
//     camera_id,
//   };
// }

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: "plate",
    numeric: false,
    disablePadding: true,
    label: "Plate",
    active: true,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: true,
    label: "Location",
    active: false,
  },
  {
    id: "vehicle_category",
    numeric: false,
    disablePadding: false,
    label: "Category",
    active: false,
  },

  {
    id: "event_timestamp",
    numeric: false,
    disablePadding: false,
    label: "Date Time",
    active: false,
  },
  {
    id: "confidence",
    numeric: false,
    disablePadding: false,
    label: "Confidence",
    active: false,
  },
  {
    id: "lane_id",
    numeric: false,
    disablePadding: false,
    label: "Lane",
    active: false,
  },
  {
    id: "camera_id",
    numeric: false,
    disablePadding: false,
    label: "Camera",
    active: false,
  },
];
// const CustomTableCell = withStyles((theme) => ({
//   root: {
//     padding: "4px 56px 4px 10px",
//   },
// }))(TableCell);
const headerStyles = (theme) => ({
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px/43px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
    borderBottom: "1px solid #E7EBF4",
    paddingBottom: "2px",
    paddingTop: "2px",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 14px/43px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
    paddingBottom: "2px",
    paddingTop: "2px",
    backgroundColor: "white",
  },
});
class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;
    const { classes } = this.props;

    return (
      <TableHead>
        <TableRow className={classes.tableHead}>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                numeric="false"
                sortDirection={orderBy === row.id ? order : false}
                className={classes.tableTitle}
              >
                <Tooltip
                  title="Sort"
                  placement={"bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                    className={classes.tableTitle}
                    IconComponent={SortUp}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
          <TableCell className={classes.tableTitle}>Action</TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);

const toolbarStyles = (theme) => ({
  root: {
    // backgroundColor: theme.toolbar.backgroundColor,
    borderRadius: theme.border.radius,
    padding: "11px",
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    color: theme.colors.chartHeading,
    fontWeight: theme.font.Bold,
    textAlign: "left",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    opacity: 1,
    marginLeft: "23px",
  },
  filterIcon: {
    opacity: 1,
    color: theme.colors.filterIconColor,
  },
  divider: { border: "1px solid #E7EBF4", opacity: 1 },
});

let EnhancedTableToolbar = (props) => {
  const { classes, showFilterWindow } = props;

  return (
    <>
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          Reviews
        </Typography>

        <div className={classes.spacer} />
        <div className={classes.actions}>
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" onClick={showFilterWindow}>
              <FilterListIcon className={classes.filterIcon} />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>{" "}
      <Divider className={classes.divider} />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.border.radius,
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    paddingBottom: "24px",
  },
  grid: {
    marginTop: "21px",
    paddingTop: "22px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
  },
  roots: {
    borderRadius: theme.border.radius,
    padding: "11px",
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    // paddingLeft: "33px",
    paddingRight: "31px",
    // paddingTop: "6px",

    marginLeft: "33px",

    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "50vh",
      overflowY: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      minHeight: "12vh",
      maxHeight: "45vh",
      overflowY: "auto",
    },
    // /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    color: theme.colors.chartHeading,
    fontWeight: theme.font.Bold,
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    opacity: 1,
    marginLeft: "23px",
  },
  filterIcon: {
    color: theme.toolbar.iconColor,
  },
  refreshIcon: {
    color: theme.toolbar.iconColor,
  },
  notAddedStyle: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    marginLeft: 33,
    marginRight: 33,
    marginTop: 33,
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 55,
    fontWeight: theme.font.fontWeight,
    fontFamily: theme.font.family,
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "9px",
    paddingBottom: "9px",
    height: "57px",
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F1F4FB",
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgb(250 251 254)",
    },
  },
  divider: { border: "1px solid #E7EBF4", opacity: 1 },
  paginationText: {
    // textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingTop: "35px",
    paddingLeft: "33px",
  },
});

class WarningList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: "event_timestamp",
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 10,
      warningCount: -1,
      selectedDate: new Date(),
      filterParams: {},
      showWarningFilterWindow: false,
      // assignedDistrict: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.district_master
      //   : "",
      // assignedField: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.location_master
      //   : "",
      // assignedZone: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.zone_master
      //   : "",
    };
  }

  showFilterWindow = () => {
    this.setState({
      showWarningFilterWindow: this.state.showWarningFilterWindow
        ? false
        : true,
    });
  };

  componentDidMount() {
    // this.setState(
    //   {
    //     warningCount: -1,
    //   },
    //   () => {
    this.props.resetEventTimeStamp();
    let condition = localStorage.getItem("warning");
    if (condition === "false") {
      this.props.resetFilterParams();
      this.props.resetWarningPage();
      this.props.resetFromDate();
      this.props.resetToDate();
      this.props.resetPlateNo();
      this.props.resetZone();
      this.props.resetDistrict();
      this.props.resetField();
      this.props.resetCamera();
      this.props.resetConfidence();
      this.props.resetVehicle();
      this.props.resetLane();
      this.props.fetchWarningList(this.props.page, this.state.rowsPerPage, {});
    } else {
      // if (
      //   this.state.assignedZone &&
      //   this.state.assignedZone != null &&
      //   this.state.assignedZone != "" &&
      //   this.state.assignedDistrict &&
      //   this.state.assignedDistrict != null &&
      //   this.state.assignedDistrict != "" &&
      //   this.state.assignedField === null
      // ) {
      //   this.setState(
      //     {
      //       filterParams: {
      //         district: this.state.assignedDistrict.district_id,
      //       },
      //     },
      //     () => {
      //       this.props.setWarningFilterParams(this.state.filterParams);
      //     }
      //   );
      // } else if (
      //   this.state.assignedZone &&
      //   this.state.assignedZone != null &&
      //   this.state.assignedZone != "" &&
      //   this.state.assignedDistrict &&
      //   this.state.assignedDistrict != null &&
      //   this.state.assignedDistrict != "" &&
      //   this.state.assignedField &&
      //   this.state.assignedField != null &&
      //   this.state.assignedField != ""
      // ) {
      //   this.setState(
      //     {
      //       filterParams: { field: this.state.assignedField.location_id },
      //     },
      //     () => {
      //       this.props.setWarningFilterParams(this.state.filterParams);
      //     }
      //   );
      // } else if (
      //   this.state.assignedZone &&
      //   this.state.assignedZone != null &&
      //   this.state.assignedZone != "" &&
      //   this.state.assignedDistrict === null &&
      //   this.state.assignedField === null
      // ) {
      //   this.setState(
      //     {
      //       filterParams: { zone: this.state.assignedZone.zone_id },
      //     },
      //     () => {
      //       this.props.setWarningFilterParams(this.state.filterParams);
      //     }
      //   );
      // } else {
      //   this.setState({
      //     filterParams: {},
      //   });
      // }

      this.props.fetchWarningList(
        this.props.page,
        this.state.rowsPerPage,
        this.props.filterParams
      );
    }
    // }

    // );
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.props.warningList !== prevProps.warningList) {
    //   this.props.fetchWarningList(
    //     this.state.page,
    //     this.state.rowsPerPage,
    //     this.props.filterParams
    //   );
    // }
    if (this.props.error != prevProps.error) {
      this.setState({
        c: 1,
      });
    }
    if (prevProps.warningList !== this.props.warningList) {
      this.setState({
        data: this.props.warningList,
        warningCount: this.props.warningCount,
      });
    }

    if (prevProps.page != this.props.page) {
      this.props.fetchWarningList(
        this.props.page,
        this.state.rowsPerPage,
        this.props.filterParams
      );
    }
    // if (prevState.rowsPerPage != this.state.rowsPerPage) {
    //   this.props.fetchWarningList(
    //     this.props.page,
    //     this.state.rowsPerPage,
    //     this.props.filterParams
    //   );
    // }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "asc";

    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState((state) => ({ selected: state.data.map((n) => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, pagess) => {
    // this.setState({ page: pagess - 1 });
    this.props.setWarningPage(pagess - 1);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleViewWarningClick = (id, event_timestamp) => {
    this.props.setEventTimeStamp(event_timestamp);
    this.props.navigateTo(`/review/${id}`);
  };
  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  onBackButtonClick = () => {
    this.props.navigateTo("/history");
  };
  componentWillUnmount() {
    this.props.resetError();
  }
  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage } = this.state;
    // const emptyRows =
    //   rowsPerPage -
    //   Math.min(rowsPerPage, this.state.warningCount - page * rowsPerPage);
    return (
      <Grid className={classes.grid}>
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            showFilterWindow={this.showFilterWindow}
            onBackButtonClick={this.onBackButtonClick}
          />
          {/* {this.state.warningCount === 0 ? (
            <div className={classes.notAddedStyle}>
              {messageConstants.NOT_FOUND}
            </div>
          ) : ( */}
          {/* {this.state.warningCount === 0 ? (
            this.props.loading ? (
              <SkeltonScreen />
            ) : (
              <div className={classes.notAddedStyle}>
                {messageConstants.NOT_ADDED}
              </div>
            )
          ) : ( */}
          {this.props.loading === false && this.state.warningCount == 0 ? (
            <div className={classes.notAddedStyle}>
              {messageConstants.NOT_ADDED}
            </div>
          ) : (
            <>
              <div className={classes.tableWrapper}>
                {data && data.length > 0 ? (
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={this.state.warningCount}
                    />

                    <TableBody>
                      {stableSort(data, getSorting(order, orderBy))
                        //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((n) => {
                          const isSelected = this.isSelected(n.id);
                          return (
                            <TableRow
                              onClick={(event) => this.handleClick(event, n.id)}
                              role="checkbox"
                              aria-checked={isSelected}
                              tabIndex={-1}
                              key={n.id}
                              selected={isSelected}
                              className={classes.zebrafont}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.tableCell}
                                align="center"
                              >
                                {n.plate}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {n.location}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {n.vehicle_category}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {toDateTime(n.event_timestamp)}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {n.confidence}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {n.lane_id}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {n.camera_id}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableCell}
                              >
                                <IconButton
                                  color="inherit"
                                  onClick={() =>
                                    this.handleViewWarningClick(
                                      n.id,
                                      n.event_timestamp
                                    )
                                  }
                                >
                                  <ViewIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 35 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                    </TableBody>
                  </Table>
                ) : this.props.error == true ? (
                  <div className={classes.notAddedStyle}>
                    {messageConstants.NOT_ADDED}
                  </div>
                ) : (
                  <SkeltonScreen />
                )}
              </div>
              {data && data.length > 0 ? (
                <Grid container xs={12}>
                  <Grid item xs={4}>
                    <Typography className={classes.paginationText}>
                      {parseInt(this.props.page) * this.props.rowsPerPage +
                        1 +
                        " - " +
                        (parseInt(this.props.page) * this.props.rowsPerPage +
                          10 >
                        this.state.warningCount
                          ? this.state.warningCount
                          : parseInt(this.props.page) * this.props.rowsPerPage +
                            10) +
                        " of " +
                        +this.state.warningCount +
                        " Reviews"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{ float: "right", paddingRight: "43px" }}
                    xs={8}
                  >
                    <Pagination
                      count={Math.ceil(this.state.warningCount / rowsPerPage)}
                      shape="rounded"
                      // page={page + 1}
                      page={this.props.page + 1}
                      onChange={this.handleChangePage}
                      className="pagination"
                      color="secondary"
                    />{" "}
                  </Grid>
                </Grid>
              ) : null}
            </>
          )}
          <Grid style={{ paddingRight: "13px" }}>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              component="div"
              count={this.state.warningCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActionsWrapped}
            /> */}
            <FilterWarning
              // open={this.props.showFilterWindow}
              toggleWarningFilterWindow={this.showFilterWindow}
              showWarningFilterWindow={this.state.showWarningFilterWindow}
              searchMethod={this.props.fetchWarningList}
              page={this.state.page}
              rowsPerPage={this.state.rowsPerPage}
            />
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

WarningList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WarningList);
