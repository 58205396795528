import { forEach } from "lodash";
import isEmpty from "./isEmpty";
import isSame from "./isSame";
import isNumeric from "./isNumeric";
import isAlphaNumeric from "./isAlphaNumeric";
import isVehicleNumber from "./isVehicleNumber";
import isDateGreater from "./isDateGreater";
import isDateGreaterCurrent from "./isDateGreaterCurrent";

import {
  NOT_EMPTY,
  NOT_SAME,
  NUM_ONLY,
  ALPHANUMERIC_ONLY,
  VEHICLENUMBER,
  DATE_NOT_GREATER,
  DATE_GREATER_CURRENT,
} from "./errorMsgConst";

function runChecks(data, rule, value) {
  if (rule.required && isEmpty(value)) {
    return NOT_EMPTY;
  }

  if (rule.isSame && isSame(value, data[rule.isSame])) {
    return NOT_SAME(rule.isSame);
  }

  if (rule.number && value !== "" && isNumeric(value)) {
    return NUM_ONLY;
  }
  if (rule.alphanumeric && value !== "" && isAlphaNumeric(value)) {
    return ALPHANUMERIC_ONLY;
  }
  if (
    rule.isDateGreaterCurrent &&
    value !== "" &&
    isDateGreaterCurrent(value)
  ) {
    return DATE_GREATER_CURRENT;
  }
  if (
    rule.isDateGreater &&
    isDateGreater(
      value,
      data[rule.isDateGreater.key],
      rule.isDateGreater.canBeSame
    )
  ) {
    return DATE_NOT_GREATER(
      rule.isDateGreater.field,
      rule.isDateGreater.canBeSame
    );
  }
  // if (rule.required && isVehicleNumber(value)) {
  //   return VEHICLENUMBER;
  // }
  if (rule.vehicleNumber && value !== "" && isVehicleNumber(value)) {
    return VEHICLENUMBER;
  }
  return "";
}

export default function validator(rules) {
  return function validate(data) {
    const errors = {};

    forEach(data, (value, key) => {
      if (rules[key]) {
        errors[key] = runChecks(data, rules[key], value);
      }
    });

    return errors;
  };
}
