import { FETCH_RECORD_SUCCESS, FETCH_RECORD_FAILED } from "./constants";
import AlertService from "../../../../../service/AlertService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchRecord = (id, event_timestamp) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new AlertService().fetchRecord(id, event_timestamp).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_RECORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: FETCH_RECORD_FAILED, error };
  }
};

export const fetchAlertNextandPrev = (data) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new AlertService().alertnextprev(data).then((response) => {
      if (response.data) {
        if (response.data.status === "success")
          dispatch(success(response.data));
        else
          dispatch(
            snackbarActions.showSnackbar(response.data.message, "error")
          );
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
      }
      dispatch(progressActions.hideProgress());
    });
  };
  function success(data) {
    return { type: FETCH_RECORD_SUCCESS, data };
  }
};
