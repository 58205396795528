import APIRequest from "../util/api/APIRequest";

class HabitualOffendersService {
  addHabitualOffenders = (params) => {
    return APIRequest({
      url: `api/habitualOffender/`,
      data: params,
      method: "POST",
    });
  };

  fetchHabitualOffender = (id) => {
    return APIRequest({
      url: `api/habitualOffender/${id}`,
      method: "GET",
    });
  };

  editHabitualOffender = (params, id) => {
    return APIRequest({
      url: `api/habitualOffender/${id}`,
      data: params,
      method: "PUT",
    });
  };

  fetchHabitualOffendersList = (page, limit, filterParams) => {
    return APIRequest({
      url: `api/habitualOffender/`,
      params: {
        page,
        limit,
      },
      data: filterParams,
      method: "GET",
    });
  };

  deleteHabitualOffender = (id) => {
    return APIRequest({
      url: `api/habitualOffender/${id}`,
      method: "DELETE",
    });
  };
}

export default HabitualOffendersService;
