import {
  FETCH_DUTY_LIST,
  FETCH_DUTY_LIST_ERROR,
  DELETE_DUTY,
  DELETE_DUTY_ERROR,
  RESET_DELETE,
  FETCH_DISTRICT_SUCCESS,
  FETCH_DISTRICT_FAILED,
  DIALOGUE_WINDOW_STATUS,
  FILTER_CHANGE,
  FILTER_CHANGE_ERROR,
} from "./constants";

const initialState = {
  dutyListFilter: [],
  distList: [],
  dutyLists: [],
  district: [],
  dutyCount: 0,
  deleteSuccess: false,
  deleteSuccessMessage: "",
  showDialogue: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DUTY_LIST:
      return {
        ...state,
        dutyLists: action.data.data.rows,
        dutyCount: action.data.data.count,
      };
    case FETCH_DUTY_LIST_ERROR:
      sessionStorage.setItem("dutyMasterError", true);
      return {
        ...initialState,
      };
    case DELETE_DUTY:
      return {
        ...initialState,
        deleteSuccessMessage: action.data,
        error: action.data.message,
        deleteSuccess: true,
      };
    case DELETE_DUTY_ERROR:
      return {
        ...initialState,
      };
    case RESET_DELETE:
      return {
        ...initialState,
        deleteSuccess: false,
      };
    case FETCH_DISTRICT_SUCCESS:
      return {
        ...state,
        district: action.data.data,
      };

    case FETCH_DISTRICT_FAILED:
      return {
        ...state,
        district: [],
      };

    case DIALOGUE_WINDOW_STATUS:
      return {
        ...state,
        showDialogue: !state.showDialogue,
      };
    case FILTER_CHANGE:
      return {
        ...state,
        dutyListFilter: action.data.data,
      };
    case FILTER_CHANGE_ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
}
