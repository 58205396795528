const initialState = {
  layers: [],
  recordCount: 0,
  saveSuccess: false,
  deleteSuccess: false,
  layer: {}
};
export default function(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
