import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CameraList from "./components/camera_list/CameraList";
import { Typography } from "@material-ui/core";
import VehicleList from "./components/vehicle_list/";
import VehicleInfo from "./components/vehicle_info/";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  overallGrid: {
    height: "82vh",
    overflowY: "auto",
    overflowX: "hidden",
  },
});

class Camera extends React.Component {
  handleChange = (key) => (event, value) => {
    this.setState({
      [key]: value,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root} overallGrid>
        <Grid container spacing={2} className={classes.overallGrid}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <VehicleList />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <VehicleInfo />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Camera);
