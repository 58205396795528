import {
  SET_PLATE_NUMBER,
  FETCH_RECENT_ACTIVITY_SUCCESS,
  FETCH_RECENT_ACTIVITY_FAIL,
  RESET_PLATE_NUMBER,
} from "./constants";

const initialState = {
  recentActivities: [],
  plate: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PLATE_NUMBER:
      return {
        ...state,
        plate: action.plate,
      };
    case FETCH_RECENT_ACTIVITY_SUCCESS:
      return {
        ...state,
        recentActivities: action.data,
      };
    case FETCH_RECENT_ACTIVITY_FAIL:
      return {
        ...initialState,
      };
    case RESET_PLATE_NUMBER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
