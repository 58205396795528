import {
  TOGGLE_FILTER_WINDOW,
  SET_FILTER_PARAMS,
  RESET_FILTER_PARAMS,
  FETCH_SQUAD_NAME,
  FETCH_SQUAD_NAME_FAILED,
  FETCH_DISTRICT,
  FETCH_DISTRICT_FAILED,
  FETCH_OFFICE_NAME,
  FETCH_OFFICE_NAME_FAILED,
  CLOSE_WINDOW,
} from "./constants";
import FilterService from "service/FilterService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";
export const toggleFilterWindow = () => {
  return (dispatch) => {
    dispatch(toggleFilterWindow());
  };
  function toggleFilterWindow() {
    return { type: TOGGLE_FILTER_WINDOW };
  }
};
export const setValue = () => {
  return (dispatch) => {
    dispatch(setValue());
  };
  function setValue() {
    return { type: CLOSE_WINDOW };
  }
};

export const setFilterParams = (filterParams) => {
  return (dispatch) => {
    dispatch(setFilterParams(filterParams));
  };
  function setFilterParams(filterParams) {
    return { type: SET_FILTER_PARAMS, filterParams };
  }
};

export const resetFilterParams = () => {
  return (dispatch) => {
    dispatch(resetFilterParams());
  };
  function resetFilterParams() {
    return { type: RESET_FILTER_PARAMS };
  }
};

export const fetchSquadName = (type) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new FilterService().fetchSquadName(type).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_SQUAD_NAME, data };
  }

  function failure(error) {
    return { type: FETCH_SQUAD_NAME_FAILED, error };
  }
};

export const fetchDistrict = (type) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new FilterService().fetchDistrict(type).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_DISTRICT, data };
  }

  function failure(error) {
    return { type: FETCH_DISTRICT_FAILED, error };
  }
};
export const fetchOfficeName = (type) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new FilterService().fetchOfficeName(type).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_OFFICE_NAME, data };
  }

  function failure(error) {
    return { type: FETCH_OFFICE_NAME_FAILED, error };
  }
};
