import React from "react";
import classNames from "classnames";
import {
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  Table,
  TableBody,
  // TablePagination,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import ViewPermissions from "../viewRole/components";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import StorageHandler from "../../../../util/storage/index";
import { ReactComponent as Edit } from "../../../../assets/Visual Assets/edit.svg";
import { ReactComponent as Delete } from "../../../../assets/Visual Assets/delete.svg";
import { ReactComponent as RotateLeftIcon } from "../../../../assets/Visual Assets/clear.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/Visual Assets/search.svg";
import { ReactComponent as DownArrow } from "../../../../assets/SvgIcons/dropdown.svg";
import { ReactComponent as VisibilityIcon } from "../../../../assets/Visual Assets/action_icon.svg";
import Pagination from "@material-ui/lab/Pagination";
import SkeltonScreen from "../../../../components/skelton_screen/index";

const rows = [
  {
    id: "#",
    numeric: false,
    disablepadding: true,
    label: "Sl No",
    active: true,
  },

  {
    id: "Role Name",
    numeric: false,
    disablepadding: true,
    label: "Role Name",
    active: false,
  },
  {
    id: "User Type",
    numeric: false,
    disablepadding: true,
    label: "User Type",
    active: false,
  },
];
const headerStyles = (theme) => ({
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 14px",
    borderColor: "#E7EBF4",
    backgroundColor: "white",
  },
  tableh: {
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 24px",
    borderColor: "#E7EBF4",
    backgroundColor: "white",
  },
});
class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.render.props.onRequestSort(event, property);
  };
  render() {
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow className={classes.tableHead}>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                className={row.active ? classes.tableh : classes.tableHead}
              >
                {row.label}
              </TableCell>
            );
          }, this)}
          <TableCell
            className={classes.tableHead}
            style={{ textAlign: "right" }}
          >
            View
          </TableCell>
          {new StorageHandler().getAttributPermissions() &&
          new StorageHandler().getAttributPermissions().edit_status == true ? (
            <TableCell
              className={classes.tableHead}
              style={{ textAlign: "right" }}
            >
              Edit
            </TableCell>
          ) : null}
          {new StorageHandler().getAttributPermissions() &&
          new StorageHandler().getAttributPermissions().delete_status ==
            true ? (
            <TableCell
              className={classes.tableHead}
              style={{ textAlign: "right", paddingRight: "35px" }}
            >
              Delete
            </TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSeleceted: PropTypes.number.isRequired,
  onRequestsort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);
const toolbarStyles = (theme) => ({});

let EnhancedTableToolbar = (props) => {
  return null;
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.border.radius,
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    paddingBottom: "24px",
  },
  root1: {
    backgroundColor: theme.toolbar.backgroundColor,
  },
  spacer: {
    flex: "1 1 1%",
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: "33px",
    paddingRight: "31px",
    // paddingTop: "6px",
    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      minHeight: "12vh",
      maxHeight: "45vh",
      overflowY: "auto",
    },
    // /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
  },
  butt: {
    background: "white",
  },
  notAddedStyle: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 55,
    fontWeight: theme.font.fontWeight,
    marginLeft: 33,
    marginRight: 33,
    marginTop: 33,
    fontFamily: theme.font.family,
  },
  filterbutton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.palette.secondary.main,
    color: theme.colors.whiteColor,
  },
  filterclearButton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.BlueButton,
    color: theme.colors.whiteColor,
  },
  filtercloseButton: {
    fontWeight: theme.font.fontWeight,
    color: theme.colors.blackColor,
  },
  filterIcon: {
    width: "25px",
    height: "23px",
    color: "#A4AFC6",
    opacity: 1,
  },
  actions1: {
    marginTop: "8px",
    paddingRight: "16px",
  },
  textStyle: {
    color: "#ffffff",
  },
  root2: {
    color: "red",
    backgroundColor: "white",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "0px solid red",
    borderRadius: "5px",
    "& .MuiInputLabel-shrink": {
      paddingLeft: "7px",
      paddingTop: "5px",
    },
    "& MuiInputLabel-formControl ": {
      // paddingLeft: "20px",
      left: "10",
    },
    "& MuiInputLabel-root ": {
      paddingLeft: "50px",
    },
    "& MuiFormLabel-root ": {
      paddingLeft: "10px",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
  textField1: {
    marginTop: "19px",
    // paddingLeft: "14px",
    // paddingTop: "12px",
    // paddingBottom: "13px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    fontSize: theme.font.size3,
    fontFamily: theme.font.family,
    fontWeight: theme.font.regular,
    letterSpacing: "0px",
    color: "#797C82",
    height: "42px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    //  border: "1px solid #C4CCDC",
    borderRadius: "5px",
    opacity: 1,
  },
  textField2: {
    marginTop: "19px",
    // paddingLeft: "14px",
    // paddingTop: "12px",
    // paddingBottom: "13px",
    // width: "178px",
    height: "40px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // border: "1px solid #C4CCDC",
    borderRadius: "5px",
    opacity: 1,
    letterSpacing: "0px",
    color: "#797C82",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    fontSize: theme.font.size3,
    fontFamily: theme.font.family,
    fontWeight: theme.font.regular,
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },
  },
  buttons: {
    marginTop: "14px",
    marginRight: "9px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    width: "111px",
    height: "42px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    textTransform: "none",
  },
  btn: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    "&:hover": {
      background: "#E7EBF4 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginRight: "4px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  btns: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  tableTitle: {
    marginTop: "6px",
    marginLeft: "10px",
    width: "275px",
    height: "22px",
    textAlign: "left",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
  },
  grid: {
    marginTop: "26px",
    paddingTop: "17px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
  },
  divider: {
    marginTop: "18px",
    height: "0px",
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
  tableCell: {
    fontSize: theme.tableCell.fontSize,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "5px",
    paddingBottom: "4.5px",
    height: "57px",
  },

  dialogBox: {
    // width: "327px",
    height: "161px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: "20px",
  },
  dialogContent: {
    //width: "247px",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "11px",
    marginRight: "17px",
    marginLeft: "16px",

    font: "normal normal normal 16px Karla",
    // marginRight: "17px",
    // marginLeft: "16px",
  },
  dialogActions: {
    "&.MuiDialogActions-root": {
      justifyContent: "center",
    },
  },

  gridContainer: {
    display: "flex",
    flexDirection: "row",
  },
  actionIcon: {
    cursor: "pointer",
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textMenu: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "6px 16px",
  },
  dropIcon: {
    marginTop: "8px",
    marginRight: "8px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  notAddedStyle1: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 55,
    // marginLeft: 33,
    // marginRight: 33,
    marginTop: 33,
    font: "normal normal normal 24px Karla;",
  },

  paginationText: {
    // textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingTop: "35px",
    paddingLeft: "33px",
  },
});

class roleMaster extends React.Component {
  constructor(props) {
    sessionStorage.setItem("roleMasterError", false);
    super(props);
    this.state = {
      order: "asc",
      orderBy: "date_time",
      selected: [],
      data: [],
      modal: false,
      page: 0,
      rowsPerPage: 10,
      roleMasterCount: 0,
      offendersCount: 0,
      flammableGoosdId: "",
      vehicleNumber: "",
      open: false,
      roleMasterList: "",
      listRowCount: -1,
      delData: {
        role_name: "",
        role_id: "",
        role_type: "",
      },
      testView: 0,
      viewId: "",
      roleListSucess: "",
      pageVal: 0,
      role_id: "",
      userTypes: "",
      filter_type: "",
      filter_name: "",
      filter_status: false,
      limit: 10,
    };
  }

  componentDidMount() {
    this.props.fetchRollMasterList(this.state.page, this.state.rowsPerPage);
    this.props.fetchUserTypeList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.roleMasterList.roleMasterList !=
      this.props.roleMasterList.roleMasterList
    ) {
      this.setState({
        roleMasterList: this.props.roleMasterList.roleMasterList.rows,
        listRowCount: this.props.roleMasterList.roleMasterList.count,
        roleListSucess: true,
        roleMasterCount: this.props.roleMasterList.roleMasterList.count,
      });
    }
    if (this.props.deleteStatus) {
      this.props.resetDeleteStatus();
      this.props.fetchRollMasterList(this.state.page, this.state.rowsPerPage);
    }
    if (prevState.page != this.state.page) {
      this.props.onFilterChange(
        this.state.filter_type,
        this.state.filter_name,
        this.state.page,
        this.state.limit
      );
      // this.props.fetchRollMasterList(this.state.page, this.state.rowsPerPage);
    }
    if (prevState.rowsPerPage != this.state.rowsPerPage) {
      this.props.fetchRollMasterList(this.state.page, this.state.rowsPerPage);
    }
    if (prevProps.userTypeList != this.props.userTypeList) {
      this.setState({ userTypes: this.props.userTypeList });
    }
    if (prevProps.viewWindowStatus != this.props.viewWindowStatus) {
      this.setState({ pageVal: this.props.viewWindowStatus });
    }
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleChangePage = (event, pagess) => {
    this.setState({ page: pagess - 1 });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  performAction = () => {
    this.props.navigateTo(`role-master/add`);
  };

  handleOpen = (flammableGoosdId, vehicle_number) => {
    this.setState({
      flammableGoosdId: flammableGoosdId,
      vehicleNumber: vehicle_number,
      modal: !this.state.modal,
    });
  };
  viewPermission = (role_id) => {
    // this.setState({ pageVal: 1, role_id: role_id });
    this.props.navigateTo(`role-master/view/${role_id}`);
  };

  editRoleMaster = (role_id) => {
    this.props.navigateTo(`role-master/edit/${role_id}`);
  };
  changeDialCloseStatus = () => {
    this.props.dialogueWindowStatus();
  };
  changeDialCloseStatus = () => {
    this.props.toggleFilterWindow();
  };
  deleteRoleMaster = (id, name, type) => {
    this.setState({
      delData: {
        ...this.state.delData,
        role_name: name,
        role_id: id,
        role_type: type,
      },
    });
    this.props.dialogueWindowStatus();
  };

  deleteflammableGoods = (flammableGoosdId) => {
    this.setState({
      modal: !this.state.modal,
    });
    this.props.deleteflammableGoods(flammableGoosdId);
  };
  onUserTypeChange = (event) => {
    this.setState({ filter_type: event.target.value }, () => {
      this.props.onFilterChange(
        this.state.filter_type,
        this.state.filter_name,
        this.state.page,
        this.state.limit
      );
    });

    this.setState({ filter_status: true });
  };
  onUserNameChange = (event) => {
    this.setState({ filter_name: event.target.value }, () => {
      this.props.onFilterChange(
        this.state.filter_type,
        this.state.filter_name,
        this.state.page,
        this.state.limit
      );
    });

    this.setState({ filter_status: true });
  };
  onFilterChange = () => {
    // this.props.onFilterChange(this.state.filter_type);
    this.props.onFilterChange(
      this.state.filter_type,
      this.state.filter_name,
      this.state.page,
      this.state.limit
    );
    this.setState({ filter_status: true });
    // this.props.toggleFilterWindow();
  };
  onFilterReset = () => {
    this.setState({ filter_type: "", filter_name: "", page: 0 }, () => {
      this.props.fetchRollMasterList(this.state.page, this.state.rowsPerPage);
    });
  };
  closeWindow = () => {
    this.props.toggleFilterWindow();
    if (!this.props.val) {
      this.setState({ filter_type: "" });
      this.setState({ filter_name: "" });
    }
  };
  onDeleteHandler = (id) => {
    this.props.deleteRoleMaster(id);
    this.props.dialogueWindowStatus();
  };
  componentWillUnmount() {
    sessionStorage.setItem("roleMasterError", false);
  }
  render() {
    const { classes } = this.props;
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      roleMasterList,
    } = this.state;
    let firstPageNum = page * rowsPerPage + 1;
    return (
      <>
        {" "}
        <Grid className={classes.grid}>
          <Paper className={classes.root}>
            <Toolbar className={classNames(classes.roots)}>
              <Typography
                variant="h6"
                id="tableTitle"
                className={classes.tableTitle}
              >
                Role Master
              </Typography>
              <div className={classes.spacer} />
              <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={4}>
                  <>
                    <TextField
                      id="custom-css-standard-input"
                      label="&nbsp; Role Name"
                      variant="outlined"
                      className={classes.textField1}
                      fullWidth
                      margin="dense"
                      onChange={this.onUserNameChange}
                      value={this.state.filter_name}
                      InputLabelProps={{
                        classes: { root: classes.textLabel },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        classes: { root: classes.textValue },
                      }}
                    />
                  </>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="&nbsp; User Type"
                    margin="dense"
                    select="true"
                    onChange={this.onUserTypeChange}
                    value={this.state.filter_type}
                    className={classes.textField2}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    InputProps={{ classes: { root: classes.textValue } }}
                    SelectProps={{
                      IconComponent: DownArrow,
                      classes: {
                        icon: classes.dropIcon,
                        iconOpen: classes.dropIconOpen,
                      },
                    }}
                  >
                    <MenuItem value="" classes={{ root: classes.textMenu }}>
                      <em>None</em>
                    </MenuItem>
                    {this.state.userTypes !== ""
                      ? this.state.userTypes.map((data) => (
                          <MenuItem
                            value={data.id}
                            classes={{ root: classes.textMenu }}
                          >
                            {data.user_type}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>
                </Grid>
              </Grid>
              <div className={classes.actions1}>
                <Tooltip title="Clear fields">
                  <IconButton
                    aria-label="Filter list"
                    onClick={this.onFilterReset}
                  >
                    <RotateLeftIcon className={classes.filterIcon} />
                  </IconButton>
                </Tooltip>{" "}
              </div>
              <div className={classes.actions}>
                {new StorageHandler().getAttributPermissions() &&
                new StorageHandler().getAttributPermissions().add_status ==
                  true ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation={true}
                    className={classes.buttons}
                    onClick={() => this.performAction()}
                  >
                    Add Role
                  </Button>
                ) : null}{" "}
              </div>
            </Toolbar>
            <Divider className={classes.divider} />

            <EnhancedTableToolbar
              showFilterWindow={this.showFilterWindow}
            ></EnhancedTableToolbar>
            {this.state.pageVal === 0 ? (
              <>
                {/* <Grid item spacing={2}>
                      {""}
                      {new StorageHandler().getAttributPermissions() &&
                      new StorageHandler().getAttributPermissions()
                        .add_status == true ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.buttons}
                          onClick={() => this.performAction()}
                        >
                          ADD Role
                        </Button>
                      ) : (
                        <div style={{ paddingTop: "2%" }}></div>
                      )}
                    </Grid> */}
                {/* {this.state.listRowCount === 0 ||
                roleMasterList === undefined ? (
                  this.props.loading ? (
                    <SkeltonScreen />
                  ) : (
                    <div className={classes.notAddedStyle}>NOT YET ADDED</div>
                  )
                ) : null} */}
                {this.props.loading === false &&
                this.state.listRowCount == 0 ? (
                  <div className={classes.notAddedStyle}>NO RECORDS FOUND</div>
                ) : (
                  <>
                    <div className={classes.tableWrapper}>
                      {roleMasterList && roleMasterList.length > 0 ? (
                        <Table
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          align="center"
                          stickyHeader
                        >
                          <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            rowCount={this.state.offendersCount}
                          />
                          {roleMasterList === "" ||
                          roleMasterList === null ||
                          roleMasterList === undefined ? null : (
                            <TableBody>
                              {roleMasterList.map((n, i) => {
                                const isSelected = this.isSelected(n.id);
                                return (
                                  <TableRow
                                    // hover
                                    role="checkbox"
                                    aria-checked={isSelected}
                                    tabIndex={-1}
                                    key={n.id}
                                    selected={isSelected}
                                    className={classes.zebrafont}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="center"
                                      className={classes.tableCell}
                                      style={{ paddingLeft: "32px" }}
                                    >
                                      {firstPageNum + i}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {n.role_name}
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {n.userType.user_type}
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableCell}
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      <IconButton
                                        color="inherit"
                                        onClick={() =>
                                          this.viewPermission(n.role_id)
                                        }
                                      >
                                        <VisibilityIcon
                                          className={classes.actionIcon}
                                        />
                                      </IconButton>
                                    </TableCell>
                                    {new StorageHandler().getAttributPermissions() &&
                                    new StorageHandler().getAttributPermissions()
                                      .edit_status == true ? (
                                      <TableCell
                                        className={classes.tableCell}
                                        style={{
                                          textAlign: "right",
                                          paddingRight: "5px",
                                        }}
                                      >
                                        <IconButton
                                          color="inherit"
                                          onClick={() =>
                                            this.editRoleMaster(n.role_id)
                                          }
                                        >
                                          <Edit
                                            className={classes.actionIcon}
                                          />
                                        </IconButton>
                                      </TableCell>
                                    ) : null}
                                    {new StorageHandler().getAttributPermissions() &&
                                    new StorageHandler().getAttributPermissions()
                                      .delete_status == true ? (
                                      <TableCell
                                        className={classes.tableCell}
                                        style={{
                                          textAlign: "right",
                                          paddingRight: "30px",
                                        }}
                                      >
                                        <IconButton
                                          color="inherit"
                                          onClick={() =>
                                            this.deleteRoleMaster(
                                              n.role_id,
                                              n.role_name,
                                              n.userType.user_type
                                            )
                                          }
                                        >
                                          <Delete
                                            className={classes.actionIcon}
                                          />
                                        </IconButton>
                                      </TableCell>
                                    ) : null}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          )}
                        </Table>
                      ) : sessionStorage.getItem("roleMasterError") ===
                        "true" ? (
                        <div className={classes.notAddedStyle1}>
                          NO RECORDS FOUND
                        </div>
                      ) : (
                        <SkeltonScreen />
                      )}
                    </div>
                  </>
                )}
                {roleMasterList && roleMasterList.length > 0 ? (
                  <Grid container xs={12}>
                    <Grid item xs={4}>
                      <Typography className={classes.paginationText}>
                        {parseInt(page) * rowsPerPage +
                          1 +
                          " - " +
                          (parseInt(page) * rowsPerPage + 10 >
                          this.state.roleMasterCount
                            ? this.state.roleMasterCount
                            : parseInt(page) * rowsPerPage + 10) +
                          " of " +
                          +this.state.roleMasterCount +
                          " Roles"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      style={{ float: "right", paddingRight: "43px" }}
                      xs={8}
                    >
                      <Pagination
                        count={Math.ceil(
                          this.state.roleMasterCount / rowsPerPage
                        )}
                        shape="rounded"
                        page={page + 1}
                        onChange={this.handleChangePage}
                        className="pagination"
                        color="secondary"
                      />
                    </Grid>
                  </Grid>
                ) : null}
                {/* <TablePagination
                  component="div"
                  count={this.state.roleMasterCount}
                  rowsPerPageOptions={[5, 10, 15, 20]}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                /> */}
              </>
            ) : (
              <>
                <ViewPermissions data={this.state.role_id} />
              </>
            )}

            <Dialog
              open={this.props.showDialogue.showDialogue}
              // open={this.props.sh}
              onClose={this.handleClose}
              aria-labelledby="customized-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{
                paper: classes.dialogBox,
              }}
            >
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  className={classes.dialogContent}
                >
                  Are you sure want to delete {this.state.delData.role_name} ?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btn}
                  disableElevation={true}
                  onClick={() => this.props.dialogueWindowStatus()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btns}
                  disableElevation={true}
                  onClick={() =>
                    this.onDeleteHandler(this.state.delData.role_id)
                  }
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </>
    );
  }
}

roleMaster.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(roleMaster);
