import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./app/";
import * as serviceWorker from "./serviceWorker";
import "typeface-roboto";
import store, { history } from "./store/";
import SnackBar from "./components/snackbar/";
ReactDOM.render(
  <Provider store={store}>
    <Fragment>
      <App history={history} />
      <SnackBar />
    </Fragment>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
