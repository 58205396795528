import {
  FETCH_REPORT_DATA_SUCCESS,
  FETCH_REPORT_DATA_FAILED,
  RESET_REPORT_DATA,
  EXPORT_REPORT_SUCCESS,
  FETCH_ALERT_FAILED,
  FETCH_ALERT,
  FETCH_SQUAD_FAILED,
  FETCH_SQUAD,
  FETCH_OFFICER_FAILED,
  FETCH_OFFICER,
  RESET_IS_GENERATE,
  IS_GENERATE,
} from "./constants";
import ReportService from "../../../service/ReportService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";
import { toDateTime } from "util/date";

export const fetchReport = (data) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new ReportService().fetchReport(data).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_REPORT_DATA_SUCCESS, data };
  }
  function failure(error) {
    return { type: FETCH_REPORT_DATA_FAILED, error };
  }
};

export const resetReportData = () => {
  return (dispatch) => {
    dispatch(resetReport());
  };
  function resetReport() {
    return { type: RESET_REPORT_DATA };
  }
};
export const resetSetIsGenerate = () => {
  return (dispatch) => {
    dispatch(resetSetIsGenerat());
  };
  function resetSetIsGenerat() {
    return { type: RESET_IS_GENERATE };
  }
};
export const setIsGenerate = () => {
  return (dispatch) => {
    dispatch(setIsGenerat());
  };
  function setIsGenerat() {
    return { type: IS_GENERATE };
  }
};
export const exportReport = (data) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new ReportService().exportReport(data).then((response) => {
      if (response.data) {
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",
          "reports " + toDateTime(new Date().getTime()) + ".csv"
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        //dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: EXPORT_REPORT_SUCCESS, data };
  }

  /* function failure(error) {
    return { type: FETCH_REPORT_DATA_FAILED, error };
  } */
};

export const fetchAlert = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new ReportService().fetchAlert().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_ALERT, data };
  }

  function failure(error) {
    return { type: FETCH_ALERT_FAILED, error };
  }
};
export const fetchSquad = (id, fromdate, todate) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new ReportService().fetchSquad(id, fromdate, todate).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_SQUAD, data };
  }

  function failure(error) {
    return { type: FETCH_SQUAD_FAILED, error };
  }
};
export const fetchOfficer = (type, id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new ReportService().fetchOfficer(type, id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_OFFICER, data };
  }

  function failure(error) {
    return { type: FETCH_OFFICER_FAILED, error };
  }
};
