import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  ListItem,
  withStyles,
  Divider,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DefaultIcon from "@material-ui/icons/EqualizerOutlined";
import StorageHandler from "../../util/storage/";
import { ReactComponent as DashboardIcon } from "../../assets/Visual Assets/dashboard.svg";
import { ReactComponent as Settings } from "../../assets/Visual Assets/master_setup.svg";
import { ReactComponent as LiveDataIcon } from "../../assets/Visual Assets/live_data.svg";
import { ReactComponent as AlertIcon } from "../../assets/Visual Assets/alerts.svg";
import { ReactComponent as ReportIcon } from "../../assets/Visual Assets/eports.svg";
import { ReactComponent as HistoryIcon } from "../../assets/Visual Assets/history.svg";
import { ReactComponent as WarningIcon } from "../../assets/Visual Assets/warnings.svg";
import { ReactComponent as DashboardIconWhite } from "../../assets/Visual Assets/dashboard_white.svg";
import { ReactComponent as SettingsWhite } from "../../assets/Visual Assets/master_Setup_White.svg";
import { ReactComponent as LiveDataIconWhite } from "../../assets/Visual Assets/live_data_white.svg";
import { ReactComponent as AlertIconWhite } from "../../assets/Visual Assets/alerts_white.svg";
import { ReactComponent as ReportIconWhite } from "../../assets/Visual Assets/reports_white.svg";
import { ReactComponent as HistoryIconWhite } from "../../assets/Visual Assets/history_white.svg";
import { ReactComponent as WarningIconWhite } from "../../assets/Visual Assets/warnings_ white.svg";
import { ReactComponent as ReviewIcon } from "../../assets/SvgIcons/survey.svg";
import { ReactComponent as ReviewIconWhite } from "../../assets/SvgIcons/surveywhite.svg";
import { setValue } from "../filterhistory_form/data/action";
const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  selectedItem: {
    width: "254px",
    height: "74px",
    opacity: 1,
    borderRadius: "0px 10px 10px 0px",

    backgroundColor: theme.drawerSelectedItem.backgroundColor,
    color: theme.drawerSelectedItem.color,
  },
  selectedSubMenuItem: {
    backgroundColor: theme.drawerSelectedItem.noBg,
  },
  selectedSideIndicator: {
    width: "4px",
    height: "74px",
    background: theme.drawerSelectedItem.sideIndicator,
    opacity: 1,
    display: "block",
    marginLeft: "-14px",
  },

  selectedSubMenuSideIndicator: {
    width: "4px",
    height: "74px",
    background: theme.drawerSelectedItem.sideIndicator,
    opacity: 1,
    display: "block",
    marginLeft: "-14px",
  },
  selectedSubItem: {
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "Regular",
    fontFamily: "Karla",
    letterSpacing: "0px",
    color: "#1BB99A",
  },
  notSelectedSubItem: {
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "Regular",
    fontFamily: "Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
  },
  selectedItemText: {
    color: theme.drawerSelectedItemText.color,
    textAlign: "left",
    fontWeight: "bold",
    fontFamily: "Karla",
    fontSize: "14px",
    letterSpacing: "0px",
    opacity: 1,
    marginLeft: "-55%",
  },
  selectedSubItemText: {
    color: theme.drawerSelectedItemText.color,
    textAlign: "left",
    fontWeight: "bold",
    fontFamily: "Karla",
    fontSize: "14px",
    letterSpacing: "0px",
    opacity: 1,
    marginLeft: "-69%",
  },
  selectedItemIcon: {
    color: theme.drawerSelectedItemText.color,
    marginLeft: "9px",
  },
  selectedItemIconClose: {
    color: theme.drawerSelectedItemText.color,
    marginLeft: "15px",
  },
  textAlign: {
    fontWeight: "bold",
    fontFamily: "Karla",
    fontSize: "14px",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginLeft: "-55%",
  },
  textAlignSubmenu: {
    fontWeight: "bold",
    fontFamily: "Karla",
    fontSize: "14px",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginLeft: "-69%",
  },
  divider: {
    left: "-0.85498046875px",
    width: "245px",
    height: "0px",
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
  Closedivider: {
    left: "-0.85498046875px",
    width: "57px",
    height: "0px",
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
  listItem: {
    width: "254px",
    height: "74px",
    borderRadius: "0px 10px 10px 0px",
  },
  listItem1: {
    width: "64px",
    height: "74px",
    borderRadius: "0px 10px 10px 0px",
  },
  subList: {
    left: "0px",
    width: "254px",
    height: "359px",
    overflow: "auto",
    background: "#F5F5FB 0% 0% no-repeat padding-box",
    opacity: 1,
    borderRadius: "0px 0px 10px 0px",
  },

  dashboardIconStyle: {
    top: "145px",
    left: "16px",
    width: "27px",
    height: "16px",
    opacity: 1,
  },
  settingIconStyle: {
    top: "214px",
    left: "19px",
    width: "24px",
    height: "24px",
  },
  liveDataIconStyle: {
    top: "289px",
    left: "20px",
    width: "22px",
    height: "22px",
  },
  alertIconStyle: {
    top: "363px",
    left: "21px",
    width: "25px",
    height: "22px",
  },
  warningIconStyle: {
    top: "436px",
    left: "20px",
    width: "25px",
    height: "22px",
  },
  reportIconStyle: {
    top: "582px",
    left: "24px",
    width: "16px",
    height: "23px",
  },
  historyIconStyle: {
    top: "509px",
    left: "22px",
    width: "22px",
    height: "22px",
  },
});

class DrawerItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedItem: null,
      selectedItemUrl: this.props.pathname,
      selectedSubItemUrl: "",
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.appBarPath !== this.props.appBarPath)
      this.setState({ selectedItemUrl: this.props.appBarPath });
  }

  getIcon(text) {
    const { classes } = this.props;
    switch (text) {
      case "Dashboard":
        return <DashboardIcon className={classes.dashboardIconStyle} />;
      case "Master Setup":
        return <Settings className={classes.settingIconStyle} />;
      case "Live Data":
        return <LiveDataIcon className={classes.liveDataIconStyle} />;
      case "Alerts":
        return <AlertIcon className={classes.alertIconStyle} />;
      case "Warnings":
        return <WarningIcon className={classes.warningIconStyle} />;
      case "Reviews":
        return <ReviewIcon className={classes.warningIconStyle} />;
      case "Reports":
        return <ReportIcon className={classes.reportIconStyle} />;
      case "History":
        return <HistoryIcon className={classes.historyIconStyle} />;
      default:
        return <DefaultIcon />;
    }
  }
  getIconWhite(text) {
    const { classes } = this.props;
    switch (text) {
      case "Dashboard":
        return <DashboardIconWhite className={classes.dashboardIconStyle} />;
      case "Master Setup":
        return <SettingsWhite className={classes.settingIconStyle} />;
      case "Live Data":
        return <LiveDataIconWhite className={classes.liveDataIconStyle} />;
      case "Alerts":
        return <AlertIconWhite className={classes.alertIconStyle} />;
      case "Warnings":
        return <WarningIconWhite className={classes.warningIconStyle} />;
      case "Reviews":
        return <ReviewIconWhite className={classes.warningIconStyle} />;
      case "Reports":
        return <ReportIconWhite className={classes.reportIconStyle} />;
      case "History":
        return <HistoryIconWhite className={classes.historyIconStyle} />;
      default:
        return <DefaultIcon />;
    }
  }
  handleClick = (url, permissions) => {
    if (url && typeof url == "string") {
      this.props.navigateTo(url);
      this.setState({ selectedItemUrl: url, openedItem: "" });
      new StorageHandler().setAttributPermissions(permissions);
      if (this.state.selectedItemUrl !== "/history/") {
        let storageHandler = new StorageHandler();
        storageHandler.setHistoryFilterParams(false);
        this.props.setValue(false);
      }
      if (this.state.selectedItemUrl !== "/review/") {
        let storageHandler = new StorageHandler();
        storageHandler.setWarningFilterParams(false);
        this.props.setValue(false);
      }
      if (this.state.selectedItemUrl !== "/alerts/") {
        let storageHandler = new StorageHandler();
        storageHandler.setAlertFilterParams(false);
        this.props.setValue(false);
      }
      if (this.state.selectedItemUrl !== "/warnings/") {
        let storageHandler = new StorageHandler();
        storageHandler.setLowAlertFilterParams(false);
        this.props.setValue(false);
      }
    }
  };
  handleClickSub = (url, permissions) => {
    this.setState({ selectedSubItemUrl: url });
    if (url && typeof url == "string") {
      this.props.navigateTo(url);
      this.setState({ selectedItemUrl: url });
      new StorageHandler().setAttributPermissions(permissions);
    }
  };
  handleExpand = (openedItem) => {
    if (this.state.openedItem === openedItem) {
      this.setState({ openedItem: null });
    } else {
      this.setState({ openedItem: openedItem });
    }
    if (!this.props.showAppDrawer) this.props.drawerToggle();
  };
  render() {
    const { classes } = this.props;
    const { selectedItemUrl, selectedSubItemUrl } = this.state;
    const menus = new StorageHandler().getUserPermissions();

    return (
      <>
        <Fragment>
          {menus
            ? menus.map((item, index) => {
                if (
                  item.sub_menus.length == 0 &&
                  item.permissions.view_status === true
                )
                  return (
                    <Fragment key={index}>
                      <ListItem
                        button
                        onClick={() =>
                          this.handleClick(item.url, item.permissions)
                        }
                        selected={selectedItemUrl === item.url}
                        classes={{ selected: classes.selectedItem }}
                        className={
                          !this.props.showAppDrawer
                            ? classes.listItem1
                            : classes.listItem
                        }
                      >
                        <span
                          className={
                            selectedItemUrl === item.url
                              ? classes.selectedSideIndicator
                              : null
                          }
                        ></span>
                        <ListItemIcon
                          className={
                            selectedItemUrl === item.url
                              ? !this.props.showAppDrawer
                                ? classes.selectedItemIconClose
                                : classes.selectedItemIcon
                              : null
                          }
                        >
                          {selectedItemUrl === item.url
                            ? this.getIconWhite(item.title)
                            : this.getIcon(item.title)}
                        </ListItemIcon>
                        <ListItemText
                          inset
                          primary={item.title}
                          classes={
                            selectedItemUrl === item.url
                              ? { primary: classes.selectedItemText }
                              : { primary: classes.textAlign }
                          }
                        />
                      </ListItem>
                      <Divider
                        className={
                          !this.props.showAppDrawer
                            ? classes.Closedivider
                            : classes.divider
                        }
                      />
                    </Fragment>
                  );
                if (
                  item.sub_menus.length != 0 &&
                  item.permissions.view_status === true
                ) {
                  return (
                    <Fragment key={index}>
                      <ListItem
                        button
                        onClick={() => this.handleExpand(item.title)}
                        className={
                          !this.props.showAppDrawer
                            ? classes.listItem1
                            : classes.listItem
                        }
                        //selected={this.state.openedItem === item.title}
                        selected={
                          selectedItemUrl === selectedSubItemUrl &&
                          this.state.openedItem === item.title
                        }
                        classes={{ selected: classes.selectedItem }}
                      >
                        <span
                          className={
                            //this.state.openedItem === item.title
                            selectedItemUrl === selectedSubItemUrl &&
                            this.state.openedItem === item.title
                              ? classes.selectedSubMenuSideIndicator
                              : null
                          }
                        ></span>
                        <ListItemIcon
                          className={
                            ///this.state.openedItem === item.title
                            selectedItemUrl === selectedSubItemUrl &&
                            this.state.openedItem === item.title
                              ? !this.props.showAppDrawer
                                ? classes.selectedItemIconClose
                                : classes.selectedItemIcon
                              : null
                          }
                        >
                          {
                            //this.state.openedItem === item.title
                            selectedItemUrl === selectedSubItemUrl &&
                            this.state.openedItem === item.title
                              ? this.getIconWhite(item.title)
                              : this.getIcon(item.title)
                          }
                        </ListItemIcon>
                        <ListItemText
                          inset
                          primary={item.title}
                          classes={
                            // this.state.openedItem === item.title
                            selectedItemUrl === selectedSubItemUrl &&
                            this.state.openedItem === item.title
                              ? { primary: classes.selectedSubItemText }
                              : { primary: classes.textAlignSubmenu }
                          }
                        />
                        {this.state.openedItem === item.title ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItem>
                      <Collapse
                        in={
                          this.state.openedItem === item.title &&
                          this.props.showAppDrawer
                        }
                        timeout="auto"
                        unmountOnExit
                        className={classes.subList}
                      >
                        <List component="div" disablePadding>
                          {item.sub_menus.map((subMenu, subIndex) => {
                            return (
                              <Fragment key={subIndex}>
                                {subMenu.permissions.view_status === true ? (
                                  <ListItem
                                    button
                                    className={classes.nested}
                                    selected={selectedItemUrl === subMenu.url}
                                    onClick={() =>
                                      this.handleClickSub(
                                        subMenu.url,
                                        subMenu.permissions
                                      )
                                    }
                                    classes={{
                                      selected: classes.selectedSubMenuItem,
                                    }}
                                  >
                                    {/* <ListItemIcon
                                      className={
                                        selectedItemUrl === subMenu.url
                                          ? classes.selectedItem
                                          : null
                                      }
                                    >
                                      {this.getIcon(subMenu.title)}
                                    </ListItemIcon> */}
                                    <ListItemText
                                      inset
                                      primary={subMenu.title}
                                      classes={
                                        selectedItemUrl === subMenu.url
                                          ? {
                                              primary: classes.selectedSubItem,
                                            }
                                          : {
                                              primary:
                                                classes.notSelectedSubItem,
                                            }
                                      }
                                    />
                                  </ListItem>
                                ) : null}
                              </Fragment>
                            );
                          })}
                        </List>
                      </Collapse>
                      <Divider
                        className={
                          !this.props.showAppDrawer
                            ? classes.Closedivider
                            : classes.divider
                        }
                      />
                    </Fragment>
                  );
                }
              })
            : null}
        </Fragment>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    pathname: state.router.location.pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setValue: (data) => dispatch(setValue(data)),
  };
}

const DrawerItemStyled = withStyles(styles)(DrawerItem);
export default connect(mapStateToProps, mapDispatchToProps)(DrawerItemStyled);
// import React, { Fragment } from "react";
// import { connect } from "react-redux";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import DashboardIcon from "@material-ui/icons/DashboardOutlined";
// import CameraIcon from "@material-ui/icons/PhotoCameraOutlined";
// import AlertIcon from "@material-ui/icons/NotificationImportantOutlined";
// import ReportIcon from "@material-ui/icons/NotesOutlined";
// import HistoryIcon from "@material-ui/icons/HistoryOutlined";
// import {
//   // MenuItem,
//   Collapse,
//   List,
//   ListItem,
//   withStyles,
//   Divider,
// } from "@material-ui/core";
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
// import ViewIcon from "@material-ui/icons/ViewListOutlined";
// import ReviewIcon from "@material-ui/icons/SpellcheckOutlined";
// import DefaultIcon from "@material-ui/icons/EqualizerOutlined";
// import Settings from "@material-ui/icons/Settings";
// import BusIcon from "@material-ui/icons/DirectionsBus";
// import FlameIcon from "@material-ui/icons/Flare";
// import WarningIcon from "@material-ui/icons/Warning";
// import GroupIcon from "@material-ui/icons/Group";
// import PersonAddIcon from "@material-ui/icons/PersonAdd";
// import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
// import BusinessIcon from "@material-ui/icons/Business";
// import HomeIcon from "@material-ui/icons/Home";
// import EventSeatIcon from "@material-ui/icons/EventSeat";

// const styles = (theme) => ({
//   nested: {
//     paddingLeft: theme.spacing.unit * 5,
//   },
//   selectedItem: {
//     backgroundColor: theme.drawerSelectedItem.backgroundColor,
//     color: theme.drawerSelectedItem.color,
//     "border-radius": "1px solid red",
//   },
//   selectedItemText: {
//     color: theme.drawerSelectedItemText.color,
//     fontWeight: theme.font.fontWeight,
//   },
//   textAlign: {
//     float: "left",
//     marginLeft: "-30%",
//   },
// });

// // const permissions = new StorageHandler().getUserPermissions();
// const menus = [
//   { text: "Dashboard", url: "/dashboard", hasSubMenus: false },
//   {
//     text: "Master Setup",
//     hasSubMenus: true,
//     subMenus: [
//       // { text: "Location Master", url: "/location", hasSubMenus: false },
//       // { text: "Lane Master", url: "/lane", hasSubMenus: false },
//       // { text: "Camera Master", url: "/camera-master", hasSubMenus: false }

//       {
//         text: "Office Master",
//         url: "/master/office-master",
//         hasSubMenus: false,
//       },
//       {
//         text: "Designation Master",
//         url: "/designation-master",
//         hasSubMenus: false,
//       },
//       {
//         text: "Role master",
//         url: "/role-master",
//         hasSubMenus: false,
//       },
//       {
//         text: "User master",
//         url: "/master/user-master",
//         hasSubMenus: false,
//       },
//       {
//         text: "Squad master",
//         url: "/squad-master",
//         hasSubMenus: false,
//       },
//       {
//         text: "Duty master",
//         url: "/duty-master",
//         hasSubMenus: false,
//       },
//       {
//         text: "Habitual Offenders",
//         url: "/habitualOffender-master",
//         hasSubMenus: false,
//       },
//       {
//         text: "EPCG Vehicles",
//         url: "/flammableGoods-master",
//         hasSubMenus: false,
//       },
//     ],
//   },
//   { text: "Camera", url: "/camera", hasSubMenus: false },
//   // {
//   //   text: "Alerts",
//   //   hasSubMenus: true,
//   //   subMenus: [
//   //     { text: "View", url: "/alerts/", hasSubMenus: false },
//   //     // { text: "Generate", url: "/generate-alert/", hasSubMenus: false },
//   //     { text: "Review", url: "/review/", hasSubMenus: false }
//   //   ]
//   // },
//   { text: "Alerts", url: "/alerts/", hasSubMenus: false },
//   { text: "Warnings", url: "/review/", hasSubMenus: false },

//   { text: "History", url: "/history", hasSubMenus: false },
//   {
//     text: "Reports",
//     hasSubMenus: true,
//     subMenus: [
//       {
//         text: "Vehicle",
//         url: "/reports/vehicle-report",
//         hasSubMenus: false,
//       },
//       {
//         text: "Warning",
//         url: "/reports/warning-report",
//         hasSubMenus: false,
//       },
//       {
//         text: "Alert",
//         url: "/reports/alert-report",
//         hasSubMenus: false,
//       },
//       // {
//       //   text: "Past Vehicle Report",
//       //   url: "/reports/prior-report",
//       //   hasSubMenus: false
//       // },
//       {
//         text: "Discrepancy",
//         url: "/reports/eway-report",
//         hasSubMenus: false,
//       },
//       {
//         text: "Habitual Offender",
//         //Recidvist
//         url: "/reports/offender-report",
//         hasSubMenus: false,
//       },
//     ],
//   },
// ];
// class DrawerItem extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       openedItem: null,
//       selectedItemUrl: this.props.pathname,
//     };
//   }
//   getIcon(text) {
//     switch (text) {
//       case "Dashboard":
//         return <DashboardIcon />;
//       case "Master Setup":
//         return <Settings />;
//       // case "Camera Master":
//       //   return <Camera />;
//       // case "Location Master":
//       //   return <LocationOn />;
//       case "Camera":
//         return <CameraIcon />;
//       case "Alerts":
//         return <AlertIcon />;
//       case "Reports":
//         return <ReportIcon />;
//       case "History":
//         return <HistoryIcon />;
//       case "View":
//         return <ViewIcon />;
//       // case "Generate":
//       //   return <AddAlertIcon />;
//       case "Review":
//         return <ReviewIcon />;
//       case "Master Doc":
//         return <Settings />;
//       case "Role master":
//         return <GroupIcon />;
//       case "User master":
//         return <PersonAddIcon />;
//       case "Duty master":
//         return <VerifiedUserIcon />;
//       case "Habitual Offenders":
//         return <BusIcon />;
//       case "EPCG Vehicles":
//         return <FlameIcon />;
//       case "Office Master":
//         return <BusinessIcon />;
//       case "Squad master":
//         return <HomeIcon />;
//       case "Designation Master":
//         return <EventSeatIcon />;
//       case "Warnings":
//         return <WarningIcon />;
//       case "Role master":
//         return <GroupIcon />;
//       case "User master":
//         return <PersonAddIcon />;
//       case "Duty master":
//         return <VerifiedUserIcon />;
//       case "Habitual Offenders":
//         return <BusIcon />;
//       case "EPCG Vehicles":
//         return <FlameIcon />;
//       case "Office Master":
//         return <BusinessIcon />;
//       case "Squad master":
//         return <HomeIcon />;
//       case "Designation Master":
//         return <EventSeatIcon />;

//       default:
//         return <DefaultIcon />;
//     }
//   }
//   handleClick = (url) => {
//     console.log(this.props.pathname, url);
//     if (url && typeof url == "string") {
//       this.props.navigateTo(url);
//       this.setState({ selectedItemUrl: url });
//     }
//   };
//   handleExpand = (openedItem) => {
//     if (this.state.openedItem === openedItem) {
//       this.setState({ openedItem: null });
//     } else {
//       this.setState({ openedItem: openedItem });
//     }
//     if (!this.props.showAppDrawer) this.props.drawerToggle();
//   };
//   render() {
//     const { classes } = this.props;
//     const { selectedItemUrl } = this.state;
//     return (
//       <Fragment>
//         {menus.map((item, index) => {
//           if (!item.hasSubMenus)
//             return (
//               <Fragment key={index}>
//                 <ListItem
//                   button
//                   onClick={() => this.handleClick(item.url)}
//                   selected={selectedItemUrl === item.url}
//                   classes={{ selected: classes.selectedItem }}
//                 >
//                   <ListItemIcon
//                     className={
//                       selectedItemUrl === item.url ? classes.selectedItem : ""
//                     }
//                   >
//                     {this.getIcon(item.text)}
//                   </ListItemIcon>
//                   <ListItemText
//                     className={classes.textAlign}
//                     inset
//                     primary={item.text}
//                     classes={
//                       selectedItemUrl === item.url
//                         ? { primary: classes.selectedItemText }
//                         : null
//                     }
//                   />
//                 </ListItem>
//                 <Divider />
//               </Fragment>
//             );
//           if (item.hasSubMenus) {
//             return (
//               <Fragment key={index}>
//                 <ListItem button onClick={() => this.handleExpand(item.text)}>
//                   <ListItemIcon>{this.getIcon(item.text)}</ListItemIcon>
//                   <ListItemText
//                     inset
//                     primary={item.text}
//                     className={classes.textAlign}
//                   />
//                   {this.state.openedItem === item.text ? (
//                     <ExpandLess />
//                   ) : (
//                     <ExpandMore />
//                   )}
//                 </ListItem>
//                 <Divider />
//                 <Collapse
//                   in={
//                     this.state.openedItem === item.text &&
//                     this.props.showAppDrawer
//                   }
//                   timeout="auto"
//                   unmountOnExit
//                 >
//                   <List component="div" disablePadding>
//                     {item.subMenus.map((subMenu, subIndex) => {
//                       return (
//                         <Fragment key={subIndex}>
//                           <ListItem
//                             button
//                             className={classes.nested}
//                             selected={selectedItemUrl === subMenu.url}
//                             onClick={() => this.handleClick(subMenu.url)}
//                             classes={{ selected: classes.selectedItem }}
//                           >
//                             <ListItemIcon
//                               className={
//                                 selectedItemUrl === subMenu.url
//                                   ? classes.selectedItem
//                                   : null
//                               }
//                             >
//                               {this.getIcon(subMenu.text)}
//                             </ListItemIcon>
//                             <ListItemText
//                               className={classes.textAlign}
//                               inset
//                               primary={subMenu.text}
//                               classes={
//                                 selectedItemUrl === subMenu.url
//                                   ? { primary: classes.selectedItemText }
//                                   : null
//                               }
//                             />
//                           </ListItem>
//                         </Fragment>
//                       );
//                     })}
//                   </List>
//                 </Collapse>
//               </Fragment>
//             );
//           }
//         })}
//       </Fragment>
//     );
//   }
// }

// function mapStateToProps(state) {
//   return {
//     pathname: state.router.location.pathname,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {};
// }

// const DrawerItemStyled = withStyles(styles)(DrawerItem);
// export default connect(mapStateToProps, mapDispatchToProps)(DrawerItemStyled);
