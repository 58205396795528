import React from "react";
import {
  Paper,
  Divider,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { ReactComponent as Egg } from "../../../assets/PngIcons/egg.svg";
import { ReactComponent as Fish } from "../../../assets/PngIcons/fish.svg";
import { ReactComponent as Chicken } from "../../../assets/PngIcons/chicken.svg";
import { ReactComponent as Fruits } from "../../../assets/PngIcons/fruits.svg";
import { ReactComponent as Vegetable } from "../../../assets/PngIcons/vegetable.svg";
import { ReactComponent as Fuel } from "../../../assets/PngIcons/fuel.svg";
import { ReactComponent as Others } from "../../../assets/PngIcons/others.svg";
import { ReactComponent as Milk } from "../../../assets/PngIcons/cow.svg";
import { ReactComponent as Empty_Vehicle } from "../../../assets/PngIcons/empty_vehicle.svg";
import { ReactComponent as Taxi } from "../../../assets/PngIcons/taxi.svg";
import { ReactComponent as Below50 } from "../../../assets/PngIcons/below50.svg";

const styles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    backgroundColor: theme.toolbar.backgroundColor,
  },
  container: {
    paddingTop: "20px",
  },

  cardTitle: {
    fontSize: "16pt",
    fontWeight: "300",

    textAlign: "right",
  },
  cardSubTitle: {
    fontSize: "12pt",
    fontWeight: "300",
    textAlign: "right",
    textTransform: "capitalize",
    color: "#49B283",
  },

  cardText: {
    paddingRight: "5%",
  },
  container_1: {
    paddingTop: "27px",
  },

  tableTitle: {
    fontWeight: "300",
    color: "white",
  },
  spacer: {
    flex: "1 1 20%",
  },
  passedToday: {
    color: theme.toolbar.iconColor,
    fontWeight: theme.font.fontWeight,
    fontSize: theme.passedToday.fontSize,
    paddingRight: theme.spacing.unit,
  },

  paperTest: {
    // width: "1044px",
    // height: "346px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
    paddingTop: "35px",
    paddingLeft: "21px",
    paddingRight: "21px",
    paddingBottom: "37px",
  },
  titleHead: {
    font: "normal normal bold 16px/22px Karla",
    color: "#2B3D51",
  },
  titleSub: {
    textAlign: "right",
    font: "normal normal normal 14px/36px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: "0.5",
  },
  paperCard: {
    // backgroundColor: theme.palette.action.hover,
    height: "104px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 3px #0000001C",
    borderRadius: theme.border.radius,
    paddingTop: "17px",
    paddingBottom: "17px",
    paddingLeft: "5px",
    padding: "5px",
    "&:hover": {
      border: ".0001px solid #692495",
      boxShadow: "0px 0px 15px #00000036",
      cursor: "pointer",
    },
  },

  iconBase: {
    width: "70px",
    height: "70px",
    background: "#F5F5FB 0% 0% no-repeat padding-box",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardIcon: {
    width: "48px",
  },
  paperCardRight: {
    paddingLeft: "8px",
    paddingTop: "0px",
  },
  paperCardRightCount: {
    font: "normal normal bold 36px/50px Karla",
    color: "#2B3D51",
  },
  paperCardRightTitle: {
    font: "normal normal normal 14px/12px Karla",
    textTransform: "capitalize",
    color: "#2B3D51",
    opacity: "0.8",
    paddingTop: "5px",
  },
});
class VehicleDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overviewData: [],
      data: [],
    };
  }

  componentDidMount() {
    if (this.props.overviewData) {
      let data = this.dataFilter(this.props.overviewData);
      this.setState({ data: data });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.overviewData != this.props.overviewData) {
      if (this.props.overviewData.milk !== undefined) {
        let data = this.dataFilter(this.props.overviewData);
        this.setState({ data: data });
      }
    }
    if (prevProps.commodityData != this.props.commodityData) {
      let data = this.dataFilter(this.props.commodityData);
      this.setState({ data: data });
    }
  }

  dataFilter = (data) => {
    let baseData = [
      "egg",
      "chicken",
      "fish",
      "fruits",
      "vegetables",
      "milk",
      "petroleum_products",
      "empty_vehicles",
      "passenger_vehicles",
      "value_below_50k",
      "others",
    ];
    let tempData = [];

    baseData.map((resp) => {
      Object.entries(data).forEach(([key, value]) => {
        if (key == resp) {
          tempData.push({
            data: key,
            value: value,
          });
        }
      });
    });

    return tempData;
  };
  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.data.length > 0 ? (
          <Paper className={classes.paperTest}>
            <Grid container justify={"space-between"} alignItems={"center"}>
              <Grid item xs={6} md={3} className={classes.titleHead}>
                Vehicle Summary
              </Grid>
              <Grid item xs={6} md={3} className={classes.titleSub}>
                Generated Today
              </Grid>
            </Grid>
            <Grid className={classes.container_1} container spacing={2}>
              {this.state.data
                ? this.state.data.map((resp) => (
                    <Grid item xs={6} md={2}>
                      <Paper className={classes.paperCard}>
                        <Grid item xs={12} container alignItems="flex-start">
                          <Grid item className={classes.iconBase}>
                            {resp.data === "egg" ? (
                              <Egg className={classes.cardIcon} />
                            ) : resp.data === "chicken" ? (
                              <Chicken className={classes.cardIcon} />
                            ) : resp.data === "fish" ? (
                              <Fish className={classes.cardIcon} />
                            ) : resp.data === "fruits" ? (
                              <Fruits className={classes.cardIcon} />
                            ) : resp.data === "vegetables" ? (
                              <Vegetable className={classes.cardIcon} />
                            ) : resp.data === "milk" ? (
                              <Milk className={classes.cardIcon} />
                            ) : resp.data === "petroleum_products" ? (
                              <Fuel className={classes.cardIcon} />
                            ) : resp.data === "empty_vehicles" ? (
                              <Empty_Vehicle className={classes.cardIcon} />
                            ) : resp.data === "passenger_vehicles" ? (
                              <Taxi className={classes.cardIcon} />
                            ) : resp.data === "value_below_50k" ? (
                              <Below50 className={classes.cardIcon} />
                            ) : resp.data === "others" ? (
                              <Others className={classes.cardIcon} />
                            ) : null}
                          </Grid>
                          <Grid item xs={6} className={classes.paperCardRight}>
                            <Typography className={classes.paperCardRightCount}>
                              {resp.value ? resp.value : 0}
                            </Typography>
                            <Divider />
                            <Typography className={classes.paperCardRightTitle}>
                              {resp.data ? resp.data.split("_").join(" ") : 0}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))
                : null}
            </Grid>
          </Paper>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.vehicleTypeCount.vehicleTypeCount,
    vehicleCount: state.overview.overviewData.vehicle_count,
    overviewData: state.overview.overviewData,
    commodityData: state.overview.commodityData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // getVehicleTypeCount: () => dispatch(getVehicleTypeCount()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(VehicleDetails));
