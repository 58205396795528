import React, { Fragment } from "react";
import {
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  IconButton,
  Divider,
} from "@material-ui/core";
import classNames from "classnames";
import propTypes from "prop-types";
import validator from "../../../../services/validator";
import { find } from "lodash";
import { ReactComponent as BackIcon } from "../../../../assets/Visual Assets/back.svg";
// import Recaptcha from "react-google-invisible-recaptcha";
// import CaptchaSiteKeyMasters from "../../../../services/siteKeyMasters";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    marginTop: "43px",
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "17px",
    marginBottom: "15px",
  },
  iconButton: {
    paddingLeft: "0px",
  },
  divider: { border: "1px solid #E7EBF4", opacity: 1, marginTop: "7px" },
});

let EnhancedTableToolbar = (props) => {
  const { classes } = props;
  return (
    <>
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          <IconButton
            onClick={(e) => props.onBackButtonClick(e)}
            classes={{ root: classes.iconButton }}
          >
            <BackIcon />
          </IconButton>
          {props.header}
        </Typography>
      </Toolbar>
      <Divider className={classes.divider} />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: propTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#1BB99A",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#1BB99A",
    },
  },
});

// Inspired by blueprintjs
function StyledRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const styles = (theme) => ({
  outerGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "45px",
  },
  root: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    borderRadius: theme.border.radius,
    opacity: 1,
    marginLeft: "21px",
    marginRight: "17px",
    paddingLeft: "8px",
    height: "354px",
  },
  textField: {
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "45px",
    width: "90%",
  },
  textFielddes: {
    paddingTop: "22px",
    marginLeft: "10px",
    marginRight: "13px",
  },
  innerGrid: {
    paddingBottom: "11px",
    paddingRight: "4px",
  },
  formHelperText: {
    color: theme.colors.redColor,
  },
  cancelButton: {
    width: "30%",
    marginBottom: "2%",
    marginLeft: theme.spacing.unit * 2,
    fontWeight: theme.font.fontWeight,
    color: theme.colors.blackColor,
  },
  btns: {
    paddingRight: "25%",
    paddingTop: "2%",
  },
  btnGrid: {
    padding: 0,
    paddingLeft: "5px",
  },
  err: {
    paddingLeft: "1%",
  },
  dense: {
    marginTop: 16,
  },
  vldclr: {
    color: "#FF0000",
  },
  alignment: {
    flexDirection: "row",
  },
  radbtns: {
    paddingLeft: "5%",
  },
  formitems: {
    paddingTop: "6%",
  },
  typo: {
    textAlign: "left",
    font: "normal normal normal 14px/36px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingRight: "14px",
    marginTop: "-1%",
    paddingTop: "5%",
  },
  formLabel: {
    font: "normal normal normal 14px/36px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
  },
  radioButtons: {
    marginTop: "3%",
  },
  clearButton: {
    height: "42px",
    textTransform: "none",
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.colors.BlueButton,
    width: "121px",
    // paddingLeft: "38px",
    // paddingRight: "37px",
    // marginLeft: "10px",
  },
  button: {
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    opacity: 1,
    textTransform: "none",
    // paddingLeft: "38px",
    // paddingRight: "37px",
    height: "42px",
    width: "121px",
    // marginLeft: "23px",
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
});

class HabitualOffendersDetails extends React.Component {
  constructor(props) {
    super(props);
    this.onResolved = this.onResolved.bind(this);

    this.state = {
      n: 2,
      data: {
        vehicleNumber: "",
        captcha: "",
        status: "true",
      },
      alertType: "",
      alertVisible: false,
      alert: "",
      errors: {
        vehicleNumber: "",
      },
      rules: {
        vehicleNumber: {
          required: true,
        },
      },
    };
  }
  componentDidMount() {
    if (this.props.match.params.offender_id) {
      this.props.fetchHabitualOffender(this.props.match.params.offender_id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.offender_id &&
      this.props !== prevProps &&
      this.props.fetchedHabitualOffender.vehicle_number !==
        this.state.vehicle_number
    ) {
      this.setState({
        data: {
          vehicleNumber: this.props.fetchedHabitualOffender.vehicle_number,
          status: this.props.fetchedHabitualOffender.active.toString(),
          // status:
          //   this.props.fetchedHabitualOffender.active
          //     ? this.props.fetchedHabitualOffender.active.toString()
          //     : ""
        },
      });
    }

    if (prevProps.habitualOffenders != this.props.habitualOffenders) {
      if (this.props.habitualOffenders) {
        this.props.showSnackbar(
          this.props.habitualOffenders.message,
          "success"
        );
        this.onBackButtonClick();
      }
    }
    if (this.props.saveSuccess !== prevProps.saveSuccess && !this.props.error) {
      this.setState(
        {
          alertVisible: true,
          alertType: "success",
          alert: this.props.message,
        },
        () => {
          setTimeout(() => {
            this.props.resetHabitualOffenderDetail();
          }, 1000);
        }
      );
    }
    if (this.props.error && this.props.error !== this.state.alert) {
      this.setState({
        alert: this.props.error,
        alertType: "danger",
        alertVisible: true,
      });
    }
  }

  onBackButtonClick = () => {
    this.props.navigateTo("/habitualOffender-master");
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };

  validate = () => {
    const { rules, data } = this.state;
    const errors = validator(rules)(data);
    const hasErrors = find(errors, (error) => error !== "");
    this.setState({ errors });
    return !hasErrors;
  };
  onResolved = () => {
    // this.setState({
    //   captcha: this.recaptcha.getResponse(),
    // });
    let params = {
      vehicle_number: this.state.data.vehicleNumber,
      active: this.state.data.status,
      // captcha: this.state.captcha,
    };

    if (this.props.match.params.offender_id) {
      if (this.validate()) {
        this.props.editHabitualOffender(
          params,
          this.props.match.params.offender_id
        );
      }
    } else {
      if (this.validate()) {
        this.props.addHabitualOffender(params);
      }
    }
  };

  performAction = (e) => {
    let params = {
      vehicle_number: this.state.data.vehicleNumber,
      active: this.state.data.status,
    };

    if (this.props.match.params.offender_id) {
      if (this.validate()) {
        // this.recaptcha.execute();
        this.onResolved();
      }
    } else {
      if (this.validate()) {
        // this.recaptcha.execute();
        this.onResolved();
      }
    }
  };
  handleClear = () => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        vehicleNumber: "",
        status: "true",
      },
      errors: {
        ...this.state.errors,
        vehicleNumber: "",
      },
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root} elevation={0}>
        <EnhancedTableToolbar
          onBackButtonClick={this.onBackButtonClick}
          header={
            this.props.match.params.offender_id
              ? "Edit Manual Alerts"
              : "Add Manual Alerts"
          }
        />
        <div className={classes.textFielddes}>
          <Fragment>
            <Grid container className={classes.outerGrid}>
              <Grid item xs={12} md={4} className={classes.innerGrid}>
                <TextField
                  className={classes.textField}
                  label="Vehicle Number(eg.KL 12 AX 3242)"
                  name="vehicleNumber"
                  id="vehicleNumber"
                  value={this.state.data.vehicleNumber}
                  error={this.state.errors.vehicleNumber ? true : false}
                  onChange={(e) => this.handleChange(e)}
                  margin="dense"
                  variant="outlined"
                  InputLabelProps={{ classes: { root: classes.textLabel } }}
                  InputProps={{ classes: { root: classes.textValue } }}
                />
                {this.state.errors.vehicleNumber ? (
                  <FormHelperText className={classes.formHelperText}>
                    {this.state.errors.vehicleNumber}
                  </FormHelperText>
                ) : null}
              </Grid>

              <Grid item xs={12} md={4} className={classes.innerGrid}>
                <Grid direction="row">
                  <FormControl component="fieldset">
                    <Grid>
                      <RadioGroup
                        aria-label="Is active?"
                        name="status"
                        className={classes.alignment}
                        value={this.state.data.status}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <Typography className={classes.typo} variant="h6">
                          Status
                        </Typography>
                        &nbsp;
                        <FormControlLabel
                          classes={{ label: classes.formLabel }}
                          value="true"
                          control={<StyledRadio />}
                          label="Active"
                          className={classes.radioButtons}
                        />
                        <FormControlLabel
                          classes={{ label: classes.formLabel }}
                          value="false"
                          control={<StyledRadio />}
                          label="Inactive"
                          className={classes.radioButtons}
                        />
                      </RadioGroup>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} className={classes.btns} container>
              <Grid item xs={12} md={6}></Grid>
              <Grid
                item
                xs={12}
                md={2}
                // className={classes.btnGrid}
                style={{
                  textAlign: "right",
                }}
              >
                <Button
                  disableElevation={true}
                  variant="contained"
                  className={classes.clearButton}
                  onClick={() => this.handleClear()}
                >
                  Clear
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                style={{
                  paddingLeft: "12px",
                }}
              >
                <Button
                  disableElevation={true}
                  elevation={0}
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => this.performAction()}
                >
                  Submit
                </Button>
                <Grid item xs={12} md={1}></Grid>
                {/* <Recaptcha
                  ref={(ref) => (this.recaptcha = ref)}
                  sitekey={CaptchaSiteKeyMasters}
                  size="invisible"
                  onResolved={this.onResolved}
                /> */}
              </Grid>
            </Grid>
          </Fragment>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(HabitualOffendersDetails);
