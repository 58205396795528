import {
  GET_OVERVIEW_DATA,
  GET_MESSAGE_DATA,
  GET_VEHICLE_CATEGORY,
  GET_COMMODITY_DATA,
  RESET_DATA,
} from "./constants";

const initialState = {
  overviewData: {},
  messageData: {},
  vehicleTypeCount: [],
  commodityData: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OVERVIEW_DATA:
      return {
        ...state,
        overviewData: action.data,
      };
    case GET_COMMODITY_DATA:
      return {
        ...state,
        commodityData: action.data,
      };

    case GET_MESSAGE_DATA:
      return {
        ...state,
        messageData: action.data,
      };

    case GET_VEHICLE_CATEGORY:
      return {
        ...state,
        vehicleTypeCount: action.data,
      };
    case RESET_DATA:
      return {
        ...state,
        overviewData: {},
        messageData: {},
        vehicleTypeCount: [],
      };

    default:
      return state;
  }
}
