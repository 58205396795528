import {
  VEHICLE_LIST,
  VEHICLE_LIST_ERROR,
  RESET_VEHICLE_LIST,
} from "./constants";

const initialState = {
  vehicleList: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case VEHICLE_LIST:
      if (Object.keys(action.vehicle).length > 0) {
        return {
          ...state,
          vehicleList: [action.vehicle, ...state.vehicleList],
        };
      } else {
        return {
          ...state,
          vehicleList: [],
        };
      }

    case VEHICLE_LIST_ERROR:
      return {
        ...initialState,
      };
    case RESET_VEHICLE_LIST:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
