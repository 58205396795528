export const FETCH_FIELD = "FETCH_FIELD";
export const FETCH_FIELD_FAILED = "FETCH_FIELD_FAILED";

export const FETCH_ZONE = "FETCH_ZONE";
export const FETCH_ZONE_FAILED = "FETCH_ZONE_FAILED";

export const FETCH_DISTRICT = "FETCH_DISTRICT";
export const FETCH_DISTRICT_FAILED = "FETCH_DISTRICT_FAILED";

export const FETCH_ROLE = "FETCH_ROLE";
export const FETCH_ROLE_FAILED = "FETCH_ROLE_FAILED";

export const ADD_EDIT_OFFICE_MASTERS = "ADD_EDIT_OFFICE_MASTERS";
export const ADD_EDIT_OFFICE_MASTERS_FAILED = "ADD_EDIT_OFFICE_MASTERS_FAILED";

export const RESET_OFFICE_MASTER_STATE = "RESET_USER_MASTER_STATE";

export const FETCH_OFFICE_DETAILS = "FETCH_USER_DETAILS";
export const FETCH_OFFICE_DETAILS_FAILED = "FETCH_USER_DETAILS_FAILED";
