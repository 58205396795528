import { connect } from "react-redux";
import { withRouter } from "react-router";
import UserList from "./userList";
import { push } from "connected-react-router";
import {
  fetchUserList,
  deleteUser,
  // deleteLocation,
  resetDelete,
} from "./data/action";

import {
  toggleFilterWindow,
  resetFilterParams,
  setFilterParams,
  setValue,
} from "../../components/filteruser_form/data/action";
import {
  dialogueWindowStatus,
  onFilterChange,
  fetchRoles,
} from "./data/action";

import { snackbarActions } from "components/snackbar/data/action";

function mapStateToProps(state) {
  return {
    userLists: state.userList.userLists,
    usersCount: state.userList.usersCount,
    filterParams: state.filter.filterParams,
    deleteSuccessReposnse: state.userList.deleteSuccessMessage,
    deleteSuccess: state.userList.deleteSuccess,
    showFilterWindow: state.filter.showFilterWindow,
    showDialogue: state.showDialogue,
    user: state.userList.userListFilter,
    roles: state.userList.role,
    val: state.filter.val,
    loading: state.progress.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onFilterChange: (params) => dispatch(onFilterChange(params)),
    fetchUserList: (page, limit) => dispatch(fetchUserList(page, limit)),
    // fetchRole: (id) => dispatch(fetchRole(id)),
    fetchRoles: () => dispatch(fetchRoles()),
    navigateTo: (url) => dispatch(push(url)),
    // toggleFilterWindow: () => dispatch(toggleFilterWindow()),
    // resetFilterParams: () => dispatch(resetFilterParams()),
    deleteUser: (id) => dispatch(deleteUser(id)),
    resetDelete: () => dispatch(resetDelete()),
    toggleFilterWindow: () => dispatch(toggleFilterWindow()),
    resetFilterParams: () => dispatch(resetFilterParams()),
    setFilterParams: (filterParams) => dispatch(setFilterParams(filterParams)),
    dialogueWindowStatus: () => dispatch(dialogueWindowStatus()),
    setValue: () => dispatch(setValue()),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserList)
);
