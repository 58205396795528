import APIRequest from "../util/api/APIRequest";

class DropdownService {
  getDropdownData = (url) => {
    return APIRequest({
      url: "api" + url,
      method: "GET",
    });
  };
}

export default DropdownService;
