import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import "./style.css";
import { snackbarActions } from "components/snackbar/data/action";
import {
  DialogContent,
  Button,
  DialogActions,
  DialogTitle,
  TextField,
  withStyles,
  Grid,
  // FormControl,
  // InputLabel,
  // Select,
  // OutlinedInput,
  // MenuItem,
  FormControlLabel,
  Typography,
  Drawer,
  Divider,
  IconButton,
  RadioGroup,
  Slider,
  Switch,
} from "@material-ui/core";
import { getDropdownData } from "../../../../util/dropdown/data/action";
import { setReportFilterParams, resetReportFilterParams } from "./data/actions";
import {
  setIsGenerate,
  fetchReport,
  exportReport,
  fetchOfficer,
  resetReportData,
  fetchSquad,
  fetchAlert,
} from "../../data/action";
import {
  fetchZone,
  fetchDistrict,
  fetchField,
} from "../../../../components/filterhistory_form/data/action";
import { fetchOffice } from "../../../squadMaster/components/data/action";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// import StorageHandler from "../../../../util/storage/index";
import { ReactComponent as DateTime } from "../../../../assets/Visual Assets/date.svg";
import { ReactComponent as DownArrow } from "../../../../assets/SvgIcons/dropdown.svg";
import Radio from "../../../../components/filteralert_form/styledRadio";

const drawerWidth = 328;

const PrettoSlider = withStyles({
  root: {
    color: "#1BB99A",
    height: 8,
    marginTop: "10px",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginTop: theme.spacing.unit,
  },
  textField: {
    width: "100%",
  },
  formLabel: {
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
  },
  radioButtons: {
    marginTop: "5%",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 400,
    height: 300,
  },
  filterTitle: {
    font: "normal normal bold 18px Karla",
    color: "#2B3D51",
  },
  filterClearAll: {
    font: "normal normal normal 16px Karla",
    color: "#1BB99A",
    textTransform: "none",
  },
  filterSubHeading: {
    font: "normal normal bold 16px/22px Karla",
    color: "#2B3D51",
  },
  // vldclr: {
  // color: "#FF0000",
  // },
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    opacity: 1,
    textTransform: "none",
    paddingLeft: "38px",
    paddingRight: "37px",
    height: "42px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
  },
  clearButton: {
    textTransform: "none",
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.BlueButton,
    color: theme.colors.whiteColor,
    "&:hover": {
      backgroundColor: theme.colors.BlueButton,
    },
  },
  closeButton: {
    height: "42px",
    textTransform: "none",
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.colors.BlueButton,
    paddingLeft: "38px",
    paddingRight: "37px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
  },
  drawerAction: {
    height: "74px",
    backgroundColor: "#F9F9FE",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  divider: {
    border: "1px solid #E7EBF4",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  dialogContent: {
    paddingLeft: "22px",
    paddingRight: "15px",
  },

  formHelperText: {
    color: theme.colors.redColor,
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  textNoOptions: {
    letterSpacing: "0px",
    opacity: 1,
    fontSize: "18px",
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  popIcon: {
    marginTop: "6px",
    marginRight: "6px",
  },
  popIconOpen: {
    marginTop: "4px",
    marginRight: "6px",
  },
  dateIcon: {
    marginTop: "0px",
    marginRight: "-8px",
  },
  mainGrid: {
    paddingBottom: "2px",
  },
  switchStyle: {
    marginRight: "0px",
    marginLeft: "95px",
  },
});

class ReportFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterParams: {},
      // assignedDistrict: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.district_master
      //   : "",
      // assignedField: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.location_master
      //   : "",
      // assignedZone: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.zone_master
      //   : "",
      validationErrors: {},
      alertt: null,
      warnings: null,
      reportType: "alert",
      commodity: null,
      confidence: 0,
      offices: null,
      officer: null,
      plate: null,
      squad: null,
      fromDate: null,
      toDate: null,
      lane: null,
      vehicleCategory: null,
      eway: null,
      action: null,

      validationParms: { from_date: "", to_date: "" },
      reportData: {},
      page: 1,
      limit: 10,
      assignedfilterParams: {},
      showReportFilterWindow: false,
      extraFilterStatus: false,
      Field: [],
      Zone: [],
      District: [],
      Alert: [],
      Office: [],
      Squad: [],
      Officer: [],
      Warning: [
        { key: "Review", value: "warning" },
        { key: "Reduced review", value: "reduced_warning" },
      ],
      EwayBill: [
        { key: "Valid", value: "Valid" },
        { key: "Invalid", value: "Invalid" },
      ],
      ActionType: [
        { key: "No action", value: "no_action" },
        { key: "Exempted", value: "exempted" },
        { key: "For action", value: "for_action" },
      ],
      ReportType: [
        { key: "Alert", value: "alert" },
        { key: "Warning", value: "warning" },
      ],
      reportParams: {},
      camera: "",
      dropdownData: {},
      value: true,
      zoneType: "zone",
      districtType: "district",
      fieldType: "field",
      toggle: false,
      report_type: true,
    };
  }

  componentDidMount() {
    this.props.resetReportFilterParams();
    this.props.intialCall();
    if (this.props.filterFields && this.props.filterFields.length > 0) {
      this.props.filterFields.forEach((field) => {
        // var d1 = new Date();
        var d = new Date();
        d.setDate(d.getDate() - 7);
        this.setState((state) => ({
          ...state,
          filterParams:
            this.props.report === "alert-report"
              ? {
                  ...state.filterParams,
                  reportType: "alert",
                }
              : {
                  ...state.filterParams,
                  [field.name]: "",
                },
          assignedfilterParams: {
            ...state.assignedfilterParams,
            [field.name]: "",
          },
          validationErrors: {
            ...state.validationErrors,
            [field.name]: false,
          },
          reportParams: {},
          alertt: null,
          warnings: null,
          reportType: "alert",
          commodity: null,
          confidence: 0,
          offices: null,
          officer: null,
          plate: null,
          squad: null,
          fromDate: null,
          toDate: null,
          extraFilterStatus: false,
          lane: null,
          vehicleCategory: null,
          camera: null,
          toggle: false,
          report_type: true,
          // assignedDistrict: new StorageHandler().getUser().basicDetails
          //   .district_master,
          // assignedField: new StorageHandler().getUser().basicDetails
          //   .location_master,
          // assignedZone: new StorageHandler().getUser().basicDetails.zone_master,
        }));
        if (field.type === "select") {
          this.props.getDropdownData(field.dropdown);
        }
        if (field.type === "camera") {
          this.props.getDropdownData(field.dropdown);
        }
        if (field.type === "lane") {
          this.props.getDropdownData(field.dropdown);
        }
        if (field.type === "vehicle") {
          this.props.getDropdownData(field.dropdown);
        }
        if (field.type === "commodity") {
          this.props.getDropdownData(field.dropdown);
        }
      });
      this.props.fetchAlert();
      this.props.fetchZone(this.state.zoneType);
      // if (
      //   new StorageHandler().getUser() &&
      //   new StorageHandler().getUser().basicDetails.zone_master &&
      //   new StorageHandler().getUser().basicDetails.zone_master != null
      // ) {
      //   this.props.fetchDistrict(this.state.assignedZone.zone_id);
      //   this.props.fetchOfficer("zone", this.state.assignedZone.zone_id);
      // }

      // if (
      //   new StorageHandler().getUser() &&
      //   new StorageHandler().getUser().basicDetails.district_master &&
      //   new StorageHandler().getUser().basicDetails.district_master != null
      // ) {
      //   this.props.fetchField(this.state.assignedDistrict.district_id);
      //   this.props.fetchOfficer(
      //     "district",
      //     this.state.assignedDistrict.district_id
      //   );
      // }
      // if (
      //   new StorageHandler().getUser() &&
      //   new StorageHandler().getUser().basicDetails.location_master &&
      //   new StorageHandler().getUser().basicDetails.location_master != null
      // ) {
      //   this.props.fetchOffice(
      //     new StorageHandler().getUser().basicDetails.location_master
      //       .location_id
      //   );
      //   this.props.fetchOfficer(
      //     "field",
      //     new StorageHandler().getUser().basicDetails.location_master
      //       .location_id
      //   );
      // }
      // if (
      //   this.state.assignedZone &&
      //   this.state.assignedZone != null &&
      //   this.state.assignedZone != "" &&
      //   this.state.assignedDistrict &&
      //   this.state.assignedDistrict != null &&
      //   this.state.assignedDistrict != "" &&
      //   this.state.assignedField === null
      // ) {
      //   this.setState({
      //     searchParams: { district: this.state.assignedDistrict.district_id },
      //   });
      // } else if (
      //   this.state.assignedZone &&
      //   this.state.assignedZone != null &&
      //   this.state.assignedZone != "" &&
      //   this.state.assignedDistrict &&
      //   this.state.assignedDistrict != null &&
      //   this.state.assignedDistrict != "" &&
      //   this.state.assignedField &&
      //   this.state.assignedField != null &&
      //   this.state.assignedField != ""
      // ) {
      //   this.setState({
      //     searchParams: { field: this.state.assignedField.location_id },
      //   });
      // } else if (
      //   this.state.assignedZone &&
      //   this.state.assignedZone != null &&
      //   this.state.assignedZone != "" &&
      //   this.state.assignedDistrict === null &&
      //   this.state.assignedField === null
      // ) {
      //   this.setState({
      //     searchParams: { zone: this.state.assignedZone.zone_id },
      //   });
      // } else {
      //   this.setState({
      //     searchParams: {},
      //   });
      // }
    }
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.empty != this.props.empty) {
    //   this.resetReportFilterParams();
    // }

    if (prevProps.filterFields != this.props.filterFields) {
      this.props.resetReportFilterParams();

      this.props.intialCall();
      // var d1 = new Date();
      var d = new Date();
      d.setDate(d.getDate() - 7);
      this.setState({
        ...this.state,
        filterParams: {},
        assignedfilterParams: {},
        alertt: null,
        warnings: null,
        reportType: "alert",
        commodity: null,
        confidence: 0,
        offices: null,
        extraFilterStatus: false,
        officer: null,
        plate: null,
        squad: null,
        fromDate: null,
        toDate: null,
        lane: null,
        vehicleCategory: null,
        camera: null,
        toggle: false,
        report_type: true,
      });
      this.props.filterFields.forEach((field) => {
        this.setState((state) => ({
          ...state,
          filterParams:
            this.props.report === "alert-report"
              ? {
                  ...state.filterParams,
                  reportType: "alert",
                }
              : {
                  ...state.filterParams,
                  [field.name]: "",
                },
          assignedfilterParams: {
            ...state.assignedfilterParams,
            [field.name]: "",
          },
          validationErrors: {
            ...state.validationErrors,
            [field.name]: false,
          },
          reportParams: {},
          // assignedDistrict: new StorageHandler().getUser().basicDetails
          //   .district_master,
          // assignedField: new StorageHandler().getUser().basicDetails
          //   .location_master,
          // assignedZone: new StorageHandler().getUser().basicDetails.zone_master,
        }));
        if (field.type === "select") {
          this.props.getDropdownData(field.dropdown);
        }
        if (field.type === "camera") {
          this.props.getDropdownData(field.dropdown);
        }
        if (field.type === "lane") {
          this.props.getDropdownData(field.dropdown);
        }
        if (field.type === "vehicle") {
          this.props.getDropdownData(field.dropdown);
        }
        if (field.type === "commodity") {
          this.props.getDropdownData(field.dropdown);
        }
      });
      this.props.fetchAlert();
      this.props.fetchZone(this.state.zoneType);
      // if (
      //   new StorageHandler().getUser() &&
      //   new StorageHandler().getUser().basicDetails.zone_master &&
      //   new StorageHandler().getUser().basicDetails.zone_master != null
      // ) {
      //   this.props.fetchDistrict(this.state.assignedZone.zone_id);
      //   this.props.fetchOfficer("zone", this.state.assignedZone.zone_id);
      // }

      // if (
      //   new StorageHandler().getUser() &&
      //   new StorageHandler().getUser().basicDetails.district_master &&
      //   new StorageHandler().getUser().basicDetails.district_master != null
      // ) {
      //   this.props.fetchField(this.state.assignedDistrict.district_id);
      //   this.props.fetchOfficer(
      //     "district",
      //     this.state.assignedDistrict.district_id
      //   );
      // }
      // if (
      //   new StorageHandler().getUser() &&
      //   new StorageHandler().getUser().basicDetails.location_master &&
      //   new StorageHandler().getUser().basicDetails.location_master != null
      // ) {
      //   this.props.fetchOffice(
      //     new StorageHandler().getUser().basicDetails.location_master
      //       .location_id
      //   );
      //   this.props.fetchOfficer(
      //     "field",
      //     new StorageHandler().getUser().basicDetails.location_master
      //       .location_id
      //   );
      // }
      // if (
      //   this.state.assignedZone &&
      //   this.state.assignedZone != null &&
      //   this.state.assignedZone != "" &&
      //   this.state.assignedDistrict &&
      //   this.state.assignedDistrict != null &&
      //   this.state.assignedDistrict != "" &&
      //   this.state.assignedField === null
      // ) {
      //   this.setState({
      //     searchParams: { district: this.state.assignedDistrict.district_id },
      //   });
      // } else if (
      //   this.state.assignedZone &&
      //   this.state.assignedZone != null &&
      //   this.state.assignedZone != "" &&
      //   this.state.assignedDistrict &&
      //   this.state.assignedDistrict != null &&
      //   this.state.assignedDistrict != "" &&
      //   this.state.assignedField &&
      //   this.state.assignedField != null &&
      //   this.state.assignedField != ""
      // ) {
      //   this.setState({
      //     searchParams: { field: this.state.assignedField.location_id },
      //   });
      // } else if (
      //   this.state.assignedZone &&
      //   this.state.assignedZone != null &&
      //   this.state.assignedZone != "" &&
      //   this.state.assignedDistrict === null &&
      //   this.state.assignedField === null
      // ) {
      //   this.setState({
      //     searchParams: { zone: this.state.assignedZone.zone_id },
      //   });
      // } else {
      //   this.setState({
      //     searchParams: {},
      //   });
      // }
    }

    if (prevProps.dropdownData !== this.props.dropdownData) {
      this.setState({
        ...this.state.dropdownData,
        dropdownData: this.props.dropdownData,
      });
    }
    if (prevProps.reportFilterParams !== this.props.reportFilterParams) {
      this.filterReport();
    }
    if (prevProps.fetchedzone != this.props.fetchedzone) {
      this.setState({
        ...this.state,
        Zone: this.props.fetchedzone,
      });
    }
    if (prevProps.fetchedAlert != this.props.fetchedAlert) {
      this.setState({
        ...this.state,
        Alert: this.props.fetchedAlert,
      });
    }
    if (prevProps.squad != this.props.squad) {
      this.setState({
        Squad: this.props.squad,
      });
    }
    if (prevProps.office != this.props.office) {
      this.setState({
        Office: this.props.office,
      });
    }
    if (prevProps.officer != this.props.officer) {
      this.setState({
        Officer: this.props.officer,
      });
    }
    if (prevProps.district != this.props.district) {
      this.setState({
        ...this.state,
        District: this.props.district,
      });
    }
    if (prevProps.field != this.props.field) {
      this.setState({
        ...this.state,
        Field: this.props.field,
      });
    }
  }
  validate = () => {
    let generationFields = {
      from_date: this.state.filterParams.from_date,
      to_date: this.state.filterParams.to_date,
    };

    var validationSuccess = true;
    let validationErrors = {};
    Object.keys(generationFields).map((field) => {
      if (generationFields[field] === "" || generationFields[field] == null) {
        validationErrors[field] = true;
        validationSuccess = false;
      } else {
        validationErrors[field] = false;
      }
    });
    this.setState({
      validationErrors,
    });

    return validationSuccess;
  };
  setReportFilterParams = () => {
    if (this.validate()) {
      let param = {
        ...this.state.filterParams,
        ...this.state.searchParams,
      };
      this.setState(
        {
          value: false,
        },
        () => {
          this.props.setReportFilterParams(param);
          this.props.toggleFilterWindow();
          // this.props.applyFilter();
        }
      );
    } else {
      this.props.showSnackbar("Please enter valid dates..!", "info");
    }
  };

  resetReportFilterParams = () => {
    this.props.resetReportFilterParams();
    // this.props.applyFilter();
    // this.props.resetReportData();
    // var d1 = new Date();
    var d = new Date();
    d.setDate(d.getDate() - 7);
    this.setState(
      {
        filterParams: {},
        Officer: [],
        value: true,

        searchParams: {},
        assignedfilterParams: {},
        alertt: null,
        warnings: null,
        reportType: "alert",
        commodity: null,
        extraFilterStatus: false,
        confidence: 0,
        offices: null,
        officer: null,
        plate: null,
        squad: null,
        fromDate: null,
        toDate: null,
        lane: null,
        vehicleCategory: null,
        eway: null,
        action: null,
        camera: "",
        toggle: false,
        report_type: true,
      },
      () => {
        this.props.filterFields.forEach((field) => {
          this.setState(
            {
              ...this.state,
              filterParams:
                this.props.report === "alert-report"
                  ? {
                      ...this.state.filterParams,
                      reportType: "alert",
                    }
                  : {
                      ...this.state.filterParams,
                      [field.name]: "",
                    },
              assignedfilterParams: {
                ...this.state.assignedfilterParams,
                [field.name]: "",
              },
              assignedZone: {},
              assignedDistrict: {},
              assignedField: {},
              District: [],
              Field: [],
              Office: [],
              Squad: [],
            }
            // () => {
            //   if (
            //     new StorageHandler().getUser().basicDetails.zone_master == null
            //   ) {
            //     this.setState({
            //       assignedZone: {},
            //       District: [],
            //       Field: [],
            //       Office: [],
            //       Squad: [],
            //     });
            //   } else {
            //     this.props.fetchDistrict(
            //       new StorageHandler().getUser().basicDetails.zone_master
            //         .zone_id
            //     );
            //     this.props.fetchOfficer(
            //       "zone",
            //       new StorageHandler().getUser().basicDetails.zone_master
            //         .zone_id
            //     );
            //     this.setState({
            //       Field: [],
            //       Office: [],
            //       Squad: [],
            //       searchParams: {
            //         zone: new StorageHandler().getUser().basicDetails
            //           .zone_master.zone_id,
            //       },
            //     });
            //   }
            //   if (
            //     new StorageHandler().getUser().basicDetails.district_master ==
            //     null
            //   ) {
            //     this.setState({
            //       assignedDistrict: {},
            //       Field: [],
            //       Office: [],
            //       Squad: [],
            //     });
            //   } else {
            //     this.props.fetchField(
            //       new StorageHandler().getUser().basicDetails.district_master
            //         .district_id
            //     );
            //     this.props.fetchOfficer(
            //       "district",
            //       new StorageHandler().getUser().basicDetails.district_master
            //         .district_id
            //     );
            //     this.setState({
            //       Office: [],
            //       Squad: [],
            //       searchParams: {
            //         district: new StorageHandler().getUser().basicDetails
            //           .district_master.district_id,
            //       },
            //     });
            //   }
            //   if (
            //     new StorageHandler().getUser().basicDetails.location_master ==
            //     null
            //   ) {
            //     this.setState({
            //       assignedField: {},
            //       Office: [],
            //       Squad: [],
            //     });
            //   } else {
            //     this.props.fetchOffice(
            //       new StorageHandler().getUser().basicDetails.location_master
            //         .location_id
            //     );
            //     this.props.fetchOfficer(
            //       "field",
            //       new StorageHandler().getUser().basicDetails.location_master
            //         .location_id
            //     );
            //     this.setState({
            //       Squad: [],
            //       searchParams: {
            //         field: new StorageHandler().getUser().basicDetails
            //           .location_master.location_id,
            //       },
            //     });
            //   }
            // }
          );
        });
      }
    );
  };
  closeButton = () => {
    this.props.toggleFilterWindow();
    if (this.state.value == true) {
      // var d1 = new Date();
      var d = new Date();
      d.setDate(d.getDate() - 7);
      this.setState(
        {
          filterParams: {},
          camera: "",
          searchParams: {},
          assignedfilterParams: {},
          Officer: [],
          alertt: null,
          warnings: null,
          reportType: "alert",
          commodity: null,
          confidence: 0,
          offices: null,
          officer: null,
          plate: null,
          squad: null,
          fromDate: null,
          toDate: null,
          lane: null,
          eway: null,
          action: null,
          vehicleCategory: null,
          extraFilterStatus: false,
          toggle: false,
          report_type: true,
        },
        () => {
          this.props.filterFields.forEach((field) => {
            this.setState(
              {
                ...this.state,
                filterParams:
                  this.props.report === "alert-report"
                    ? {
                        ...this.state.filterParams,
                        reportType: this.state.reportType,
                      }
                    : {
                        ...this.state.filterParams,
                        [field.name]: "",
                      },
                assignedfilterParams: {
                  ...this.state.assignedfilterParams,
                  [field.name]: "",
                },
                assignedZone: {},
                assignedDistrict: {},
                assignedField: {},
                District: [],
                Field: [],
                Office: [],
                Squad: [],
              }
              // () => {
              //   if (
              //     new StorageHandler().getUser().basicDetails.zone_master ==
              //     null
              //   ) {
              //     this.setState({
              //       assignedZone: {},
              //       District: [],
              //       Field: [],
              //       Office: [],
              //       Squad: [],
              //     });
              //   } else {
              //     this.props.fetchDistrict(
              //       new StorageHandler().getUser().basicDetails.zone_master
              //         .zone_id
              //     );
              //     this.props.fetchOfficer(
              //       "zone",
              //       new StorageHandler().getUser().basicDetails.zone_master
              //         .zone_id
              //     );
              //     this.setState({
              //       Field: [],
              //       Office: [],
              //       Squad: [],
              //       searchParams: {
              //         zone: new StorageHandler().getUser().basicDetails
              //           .zone_master.zone_id,
              //       },
              //     });
              //   }
              //   if (
              //     new StorageHandler().getUser().basicDetails.district_master ==
              //     null
              //   ) {
              //     this.setState({
              //       assignedDistrict: {},
              //       Field: [],
              //       Office: [],
              //       Squad: [],
              //     });
              //   } else {
              //     this.props.fetchField(
              //       new StorageHandler().getUser().basicDetails.district_master
              //         .district_id
              //     );
              //     this.props.fetchOfficer(
              //       "district",
              //       new StorageHandler().getUser().basicDetails.district_master
              //         .district_id
              //     );
              //     this.setState({
              //       Office: [],
              //       Squad: [],
              //       searchParams: {
              //         district: new StorageHandler().getUser().basicDetails
              //           .district_master.district_id,
              //       },
              //     });
              //   }
              //   if (
              //     new StorageHandler().getUser().basicDetails.location_master ==
              //     null
              //   ) {
              //     this.setState({
              //       assignedField: {},
              //       Office: [],
              //       Squad: [],
              //     });
              //   } else {
              //     this.props.fetchOffice(
              //       new StorageHandler().getUser().basicDetails.location_master
              //         .location_id
              //     );
              //     this.props.fetchOfficer(
              //       "field",
              //       new StorageHandler().getUser().basicDetails.location_master
              //         .location_id
              //     );
              //     this.setState({
              //       Squad: [],
              //       searchParams: {
              //         field: new StorageHandler().getUser().basicDetails
              //           .location_master.location_id,
              //       },
              //     });
              //   }
              // }
            );
          });
        }
      );
    }
  };
  // componentWillUnmount() {
  //   this.props.onRef(undefined);
  // }
  getFilterFields = () => {
    var d5f = new Date();
    d5f.setFullYear(d5f.getFullYear() - 1);
    const { filterFields, classes } = this.props;
    if (this.props.filterFields && this.props.filterFields.length > 0) {
      return filterFields.map((field, index) => {
        switch (field.type) {
          case "datepicker":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                {/* <Typography variant="h6">From Date</Typography> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    fullWidth
                    label={field.label}
                    name={field.name}
                    inputVariant="outlined"
                    format="dd-MM-yyyy hh:mm a"
                    minDate={d5f}
                    disableFuture
                    margin="dense"
                    value={
                      this.state.fromDate == "" ||
                      this.state.fromDate == undefined
                        ? null
                        : this.state.fromDate
                    }
                    onChange={(date) => {
                      this.setState({
                        filterParams: {
                          ...this.state.filterParams,
                          [field.name]:
                            date == "Invalid Date" ||
                            date == null ||
                            date == "Invalid date"
                              ? null
                              : new Date(date).getTime(),
                        },
                        fromDate: date,
                      });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    InputProps={{ classes: { root: classes.textValue } }}
                    keyboardIcon={<DateTime className={classes.dateIcon} />}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            );

          case "datepickers":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                {/* <Typography variant="h6">To Date</Typography> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    fullWidth
                    label={field.label}
                    name={field.name}
                    // disableToolbar
                    minDate={d5f}
                    disableFuture
                    inputVariant="outlined"
                    format="dd-MM-yyyy hh:mm a"
                    margin="dense"
                    value={
                      this.state.toDate == "" || this.state.toDate == undefined
                        ? null
                        : this.state.toDate
                    }
                    onChange={(date) => {
                      this.setState({
                        filterParams: {
                          ...this.state.filterParams,
                          [field.name]:
                            date == "Invalid Date" ||
                            date == null ||
                            date == "Invalid date"
                              ? null
                              : new Date(date).getTime(),
                        },
                        toDate: date,
                      });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    InputProps={{ classes: { root: classes.textValue } }}
                    keyboardIcon={<DateTime className={classes.dateIcon} />}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            );
          case "warningtype":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
                style={{ paddingTop: "5px" }}
              >
                <Typography className={classes.filterSubHeading}>
                  Review Type
                </Typography>
                <RadioGroup
                  style={{ display: "flex" }}
                  id="review_type"
                  select
                  label="Review Type"
                  name="review_type"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={this.state.warnings}
                  onChange={(event) => {
                    this.setState({
                      filterParams: {
                        ...this.state.filterParams,
                        [field.name]: event.target.value,
                      },
                      warnings: event.target.value,
                    });
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                >
                  <Grid container item xs={12}>
                    {this.state.Warning &&
                      this.state.Warning.map((option, i) => (
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={<Radio />}
                            value={option.value}
                            label={option.key}
                            classes={{ label: classes.formLabel }}
                          >
                            {option.key}
                          </FormControlLabel>
                        </Grid>
                      ))}
                  </Grid>
                </RadioGroup>
                <br></br>
              </Grid>
            );
          case "reporttype":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
                style={{ paddingTop: "0px", marginTop: "-8px" }}
              >
                <Typography className={classes.filterSubHeading}>
                  Report Type
                </Typography>
                <RadioGroup
                  style={{ display: "flex" }}
                  id="report_type"
                  select
                  label="Report Type"
                  name="reportType"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={this.state.reportType}
                  onChange={(event) => {
                    this.setState({
                      filterParams: {
                        ...this.state.filterParams,
                        [field.name]: event.target.value,
                      },
                      reportType: event.target.value,
                    });
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                >
                  <Grid container item xs={12}>
                    {this.state.ReportType &&
                      this.state.ReportType.map((option, i) => (
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={<Radio />}
                            value={option.value}
                            label={option.key}
                            classes={{ label: classes.formLabel }}
                          >
                            {option.key}
                          </FormControlLabel>
                        </Grid>
                      ))}
                  </Grid>
                </RadioGroup>
                <br></br>
              </Grid>
            );
          case "alerttype":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                {/* <Typography variant="h6">Alert Type</Typography> */}
                {this.state.Alert ? (
                  <Autocomplete
                    disableClearable="true"
                    disableOpenOnFocus="true"
                    options={this.state.Alert}
                    value={this.state.alertt}
                    name={field.key}
                    getOptionLabel={(option) => (option.key ? option.key : "")}
                    onChange={(event, value) => {
                      this.setState({
                        assignedfilterParams: {
                          ...this.state.assignedfilterParams,
                          [field.name]: value,
                        },
                        filterParams: {
                          ...this.state.filterParams,
                          [field.name]: value.value,
                        },
                        alertt: value,
                      });
                    }}
                    popupIcon={<DownArrow />}
                    classes={{
                      input: classes.textValue,
                      option: classes.textValue,
                      popupIndicatorOpen: classes.popIconOpen,
                      popupIndicator: classes.popIcon,
                      noOptions: classes.textNoOptions,
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label={field.label}
                        name={field.name}
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                      ></TextField>
                    )}
                  />
                ) : null}
                <br></br>
              </Grid>
            );
          case "textfield1":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                {/* <Typography variant="h6">Vehicle Number</Typography> */}
                <TextField
                  label="Vehicle Number"
                  placeholder="eg.KL12AX3242"
                  name={field.name}
                  variant="outlined"
                  margin="dense"
                  InputLabelProps={{ classes: { root: classes.textLabel } }}
                  InputProps={{ classes: { root: classes.textValue } }}
                  fullWidth
                  value={this.state.plate == undefined ? "" : this.state.plate}
                  onChange={(event) => {
                    this.setState({
                      filterParams: {
                        ...this.state.filterParams,
                        [field.name]: event.target.value,
                      },
                      plate: event.target.value,
                    });
                  }}
                />
                <br></br>
                <br></br>
              </Grid>
            );
          case "zone":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                {/* <Typography variant="h6">Zone</Typography> */}
                {this.state.Zone ? (
                  <Autocomplete
                    disableClearable="true"
                    disableOpenOnFocus="true"
                    // disabled={
                    //   new StorageHandler().getUser() &&
                    //   new StorageHandler().getUser().basicDetails &&
                    //   new StorageHandler().getUser().basicDetails.zone_id
                    //     ? true
                    //     : false
                    // }
                    options={this.state.Zone}
                    value={
                      this.state.assignedZone &&
                      this.state.assignedZone !== null
                        ? this.state.assignedZone
                        : ""
                    }
                    name={field.name}
                    getOptionLabel={(option) =>
                      option.zone_name ? option.zone_name : ""
                    }
                    onChange={(event, value) => {
                      this.setState(
                        {
                          ...this.state,
                          assignedZone: value,
                          assignedDistrict: {},
                          assignedField: {},
                          assignedfilterParams: {
                            ...this.state.assignedfilterParams,
                            [field.name]: value,
                            squad: "",
                            office: "",
                            officer: "",
                          },
                          squad: "",
                          offices: "",
                          officer: "",
                          toggle: false,
                          searchParams: {
                            [field.name]: value.zone_id,
                          },
                        },
                        () => {
                          this.props.fetchDistrict(
                            this.state.districtType,
                            this.state.assignedZone.zone_id
                          );
                          this.props.fetchOfficer(
                            this.state.zoneType,
                            this.state.assignedZone.zone_id
                          );
                          this.setState({
                            Field: [],
                            Office: [],
                            Squad: [],
                          });
                        }
                      );
                    }}
                    popupIcon={<DownArrow />}
                    classes={{
                      input: classes.textValue,
                      option: classes.textValue,
                      popupIndicatorOpen: classes.popIconOpen,
                      popupIndicator: classes.popIcon,
                      noOptions: classes.textNoOptions,
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label={field.label}
                        name={field.name}
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                      ></TextField>
                    )}
                  />
                ) : null}
              </Grid>
            );
          case "district":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                {/* <Typography variant="h6">District</Typography> */}
                {this.state.District ? (
                  <Autocomplete
                    disableClearable="true"
                    disableOpenOnFocus="true"
                    options={this.state.District}
                    value={
                      this.state.assignedDistrict &&
                      this.state.assignedDistrict !== null
                        ? this.state.assignedDistrict
                        : ""
                    }
                    // disabled={
                    //   new StorageHandler().getUser() &&
                    //   new StorageHandler().getUser().basicDetails &&
                    //   new StorageHandler().getUser().basicDetails.district_id
                    //     ? true
                    //     : false
                    // }
                    name={field.name}
                    getOptionLabel={(option) =>
                      option.district_name ? option.district_name : ""
                    }
                    onChange={(event, value) => {
                      this.setState(
                        {
                          ...this.state,
                          assignedDistrict: value,
                          assignedfilterParams: {
                            ...this.state.assignedfilterParams,
                            [field.name]: value,
                            squad: "",
                            office: "",
                            officer: "",
                          },
                          squad: "",
                          offices: "",
                          officer: "",
                          toggle: false,
                          assignedField: {},
                          searchParams: {
                            [field.name]: value.district_id,
                          },
                        },
                        () => {
                          this.props.fetchField(
                            this.state.fieldType,
                            this.state.assignedDistrict.district_id
                          );
                          this.props.fetchOfficer(
                            this.state.districtType,
                            this.state.assignedDistrict.district_id
                          );
                        }
                      );
                    }}
                    popupIcon={<DownArrow />}
                    classes={{
                      input: classes.textValue,
                      option: classes.textValue,
                      popupIndicatorOpen: classes.popIconOpen,
                      popupIndicator: classes.popIcon,
                      noOptions: classes.textNoOptions,
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label={field.label}
                        name={field.name}
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                      ></TextField>
                    )}
                  />
                ) : null}
              </Grid>
            );
          case "field":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                {/* <Typography variant="h6">Field</Typography> */}
                {this.state.Field ? (
                  <Autocomplete
                    disableClearable="true"
                    disableOpenOnFocus="true"
                    options={this.state.Field}
                    value={
                      this.state.assignedField &&
                      this.state.assignedField !== null
                        ? this.state.assignedField
                        : ""
                    }
                    // disabled={
                    //   new StorageHandler().getUser() &&
                    //   new StorageHandler().getUser().basicDetails &&
                    //   new StorageHandler().getUser().basicDetails.location_id
                    //     ? true
                    //     : false
                    // }
                    name={field.name}
                    getOptionLabel={(option) =>
                      option.place_name ? option.place_name : ""
                    }
                    onChange={(event, value) => {
                      this.setState(
                        {
                          ...this.state,
                          assignedField: value,
                          assignedfilterParams: {
                            ...this.state.assignedfilterParams,
                            [field.name]: value,
                            squad: "",
                            office: "",
                            officer: "",
                          },
                          squad: "",
                          offices: "",
                          officer: "",
                          toggle: false,
                          searchParams: { [field.name]: value.location_id },
                        },
                        () => {
                          this.props.fetchOffice(value.location_id);
                          this.props.fetchOfficer(
                            this.state.fieldType,
                            value.location_id
                          );
                          this.setState({
                            Squad: [],
                          });
                        }
                      );
                    }}
                    popupIcon={<DownArrow />}
                    classes={{
                      input: classes.textValue,
                      option: classes.textValue,
                      popupIndicatorOpen: classes.popIconOpen,
                      popupIndicator: classes.popIcon,
                      noOptions: classes.textNoOptions,
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label={field.label}
                        name={field.name}
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                      ></TextField>
                    )}
                  />
                ) : null}
              </Grid>
            );
          // case "office":
          //   return (
          //     <Grid
          //       xs={12}
          //       md={12}
          //       lg={12}
          //       item
          //       key={index}
          //       classes={{ root: classes.mainGrid }}
          //     >
          //       {/* <Typography variant="h6">Office</Typography> */}
          //       {this.state.Office ? (
          //         <Autocomplete
          //           disableClearable="true"
          //           disableOpenOnFocus="true"
          //           options={this.state.Office}
          //           value={this.state.offices}
          //           name={field.name}
          //           getOptionLabel={(option) =>
          //             option.office ? option.office : ""
          //           }
          //           onChange={(event, value) => {
          //             if (this.validate()) {
          //               this.setState(
          //                 {
          //                   assignedfilterParams: {
          //                     ...this.state.assignedfilterParams,
          //                     [field.name]: value,
          //                     squad: "",
          //                   },
          //                   offices: value,
          //                   officer: "",
          //                   squad: "",
          //                   searchParams: { [field.name]: value.officeId },
          //                 },
          //                 () => {
          //                   this.props.fetchSquad(
          //                     value.officeId,
          //                     this.state.filterParams.from_date,
          //                     this.state.filterParams.to_date
          //                   );
          //                 }
          //               );
          //             } else {
          //               this.props.showSnackbar(
          //                 "Please enter valid from and to dates..!",
          //                 "info"
          //               );
          //             }
          //           }}
          //           popupIcon={<DownArrow />}
          //           classes={{
          //             input: classes.textValue,
          //             option: classes.textValue,
          //             popupIndicatorOpen: classes.popIconOpen,
          //             popupIndicator: classes.popIcon,
          //             noOptions: classes.textNoOptions,
          //           }}
          //           renderInput={(params) => (
          //             <TextField
          //               fullWidth
          //               label={field.label}
          //               name={field.name}
          //               id="outlined-margin-dense"
          //               margin="dense"
          //               variant="outlined"
          //               {...params}
          //               InputLabelProps={{
          //                 classes: { root: classes.textLabel },
          //               }}
          //             ></TextField>
          //           )}
          //         />
          //       ) : null}
          //     </Grid>
          //   );
          // case "squad":
          //   return (
          //     <Grid
          //       xs={12}
          //       md={12}
          //       lg={12}
          //       item
          //       key={index}
          //       classes={{ root: classes.mainGrid }}
          //     >
          //       {/* <Typography variant="h6">Squad</Typography> */}
          //       {this.state.Squad ? (
          //         <Autocomplete
          //           disableClearable="true"
          //           disableOpenOnFocus="true"
          //           options={this.state.Squad}
          //           value={this.state.sqaud}
          //           name={field.name}
          //           getOptionLabel={(option) =>
          //             option.squad_name ? option.squad_name : ""
          //           }
          //           onChange={(event, value) => {
          //             this.setState(
          //               {
          //                 assignedfilterParams: {
          //                   ...this.state.assignedfilterParams,
          //                   [field.name]: value,
          //                   officer: "",
          //                 },
          //                 sqaud: value,
          //                 officer: "",
          //                 searchParams: { [field.name]: value.squad_id },
          //               },
          //               () => {
          //                 this.props.fetchOfficer("squad", value.squad_id);
          //               }
          //             );
          //           }}
          //           popupIcon={<DownArrow />}
          //           classes={{
          //             input: classes.textValue,
          //             option: classes.textValue,
          //             popupIndicatorOpen: classes.popIconOpen,
          //             popupIndicator: classes.popIcon,
          //             noOptions: classes.textNoOptions,
          //           }}
          //           renderInput={(params) => (
          //             <TextField
          //               fullWidth
          //               label={field.label}
          //               name={field.name}
          //               id="outlined-margin-dense"
          //               margin="dense"
          //               variant="outlined"
          //               {...params}
          //               InputLabelProps={{
          //                 classes: { root: classes.textLabel },
          //               }}
          //             ></TextField>
          //           )}
          //         />
          //       ) : null}
          //     </Grid>
          //   );
          // case "officer":
          //   return (
          //     <Grid
          //       xs={12}
          //       md={12}
          //       item
          //       key={index}
          //       classes={{ root: classes.mainGrid }}
          //     >
          //       {/* <Typography variant="h6">Officer</Typography> */}
          //       {this.state.Officer ? (
          //         <Autocomplete
          //           disableClearable="true"
          //           disableOpenOnFocus="true"
          //           options={this.state.Officer}
          //           value={this.state.officer}
          //           name={field.name}
          //           getOptionLabel={(option) =>
          //             option.name ? option.name : ""
          //           }
          //           onChange={(event, value) => {
          //             this.setState({
          //               assignedfilterParams: {
          //                 ...this.state.assignedfilterParams,
          //                 [field.name]: value,
          //               },
          //               officer: value,
          //               searchParams: { [field.name]: value.userId },
          //             });
          //           }}
          //           popupIcon={<DownArrow />}
          //           classes={{
          //             input: classes.textValue,
          //             option: classes.textValue,
          //             popupIndicatorOpen: classes.popIconOpen,
          //             popupIndicator: classes.popIcon,
          //             noOptions: classes.textNoOptions,
          //           }}
          //           renderInput={(params) => (
          //             <TextField
          //               fullWidth
          //               label={field.label}
          //               name={field.name}
          //               id="outlined-margin-dense"
          //               margin="dense"
          //               variant="outlined"
          //               {...params}
          //               InputLabelProps={{
          //                 classes: { root: classes.textLabel },
          //               }}
          //             ></TextField>
          //           )}
          //         />
          //       ) : null}
          //     </Grid>
          //   );
        }
      });
    }
  };
  getAdditionalFilterFields = () => {
    const { filterFields, classes } = this.props;
    if (this.props.filterFields && this.props.filterFields.length > 0) {
      return filterFields.map((field, index) => {
        switch (field.type) {
          case "textfield":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                <Typography className={classes.filterSubHeading}>
                  Confidence
                </Typography>
                <br></br>
                <div className={classes.root}>
                  <PrettoSlider
                    value={
                      this.state.confidence == undefined
                        ? 0
                        : parseInt(this.state.confidence)
                    }
                    onChange={(event, value) => {
                      this.setState({
                        filterParams: {
                          ...this.state.filterParams,
                          [field.name]: value,
                        },
                        confidence: value,
                      });
                    }}
                    valueLabelDisplay="on"
                    aria-label="pretto slider"
                  />
                </div>
              </Grid>
            );
          case "vehicle":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                <Typography className={classes.filterSubHeading}>
                  Vehicle Category
                </Typography>
                <RadioGroup
                  style={{ display: "flex" }}
                  id="vehicle_category"
                  select
                  label="Vehicle Category"
                  //className={classes.textField}
                  name="vehicle_category"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={this.state.vehicleCategory}
                  onChange={(event) => {
                    this.setState({
                      filterParams: {
                        ...this.state.filterParams,
                        [field.name]: event.target.value,
                      },
                      vehicleCategory: event.target.value,
                    });
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                >
                  <Grid container item xs={12}>
                    {this.state.dropdownData[field.dropdownData] &&
                      this.state.dropdownData[field.dropdownData].map(
                        (option, i) => (
                          <Grid item xs={12} md={6}>
                            <FormControlLabel
                              control={<Radio />}
                              value={option.key}
                              label={option.value}
                              classes={{ label: classes.formLabel }}
                            >
                              {option.value}
                            </FormControlLabel>
                          </Grid>
                        )
                      )}
                  </Grid>
                </RadioGroup>
              </Grid>
            );
          case "lane":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                <Typography className={classes.filterSubHeading}>
                  Lane
                </Typography>
                <RadioGroup
                  style={{ display: "flex" }}
                  id="vehicle_category"
                  select
                  label="Vehicle Category"
                  //className={classes.textField}
                  name="vehicle_category"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={parseInt(this.state.lane)}
                  onChange={(event) => {
                    this.setState({
                      filterParams: {
                        ...this.state.filterParams,
                        [field.name]: event.target.value,
                      },
                      lane: event.target.value,
                    });
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                >
                  <Grid container item xs={12}>
                    {this.state.dropdownData[field.dropdownData] &&
                      this.state.dropdownData[field.dropdownData].map(
                        (option, i) => (
                          <Grid item xs={12} md={6}>
                            <FormControlLabel
                              control={<Radio />}
                              value={parseInt(option.value)}
                              label={option.value}
                              classes={{ label: classes.formLabel }}
                            >
                              {option.value}
                            </FormControlLabel>
                          </Grid>
                        )
                      )}
                  </Grid>
                </RadioGroup>
              </Grid>
            );
          case "camera":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                {/* <Typography variant="h6">Camera</Typography> */}
                {this.state.dropdownData[field.dropdownData] ? (
                  <Autocomplete
                    disableClearable="true"
                    disableOpenOnFocus="true"
                    options={this.state.dropdownData[field.dropdownData]}
                    value={this.state.camera}
                    name={field.name}
                    getOptionLabel={(option) =>
                      option.field
                        ? option.camera_id + "-" + " " + option.field
                        : ""
                    }
                    onChange={(event, value) => {
                      this.setState({
                        camera: value,
                        filterParams: {
                          ...this.state.filterParams,
                          [field.name]: value.camera_id,
                        },
                      });
                    }}
                    popupIcon={<DownArrow />}
                    classes={{
                      input: classes.textValue,
                      option: classes.textValue,
                      popupIndicatorOpen: classes.popIconOpen,
                      popupIndicator: classes.popIcon,
                      noOptions: classes.textNoOptions,
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label="Camera"
                        name="camera"
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                      ></TextField>
                    )}
                  />
                ) : null}
              </Grid>
            );
          case "commodity":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                {this.state.action == "exempted" ||
                this.state.action == "" ||
                this.state.action == null ? (
                  <>
                    {this.state.dropdownData[field.dropdownData] ? (
                      <Autocomplete
                        disableClearable="true"
                        disableOpenOnFocus="true"
                        options={this.state.dropdownData[field.dropdownData]}
                        value={this.state.commodity}
                        name={field.key}
                        getOptionLabel={(option) =>
                          option.nt_category ? option.nt_category : ""
                        }
                        onChange={(event, value) => {
                          this.setState({
                            filterParams: {
                              ...this.state.filterParams,
                              [field.name]: value.nt_category,
                            },
                            commodity: value,
                          });
                        }}
                        popupIcon={<DownArrow />}
                        classes={{
                          input: classes.textValue,
                          option: classes.textValue,
                          popupIndicatorOpen: classes.popIconOpen,
                          popupIndicator: classes.popIcon,
                          noOptions: classes.textNoOptions,
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            label={field.label}
                            name={field.name}
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            {...params}
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                          ></TextField>
                        )}
                      />
                    ) : null}
                    {/* <FormControl variant="outlined" fullWidth margin="dense">
                      <InputLabel
                        ref={(ref) => {
                          this.InputLabelRef = ref;
                        }}
                        htmlFor="outlined-age-simple"
                      >
                        {field.label}
                      </InputLabel>
                      <Select
                        fullWidth
                        value={this.state.commodity}
                        onChange={(event) => {
                          this.setState({
                            filterParams: {
                              ...this.state.filterParams,
                              [field.name]: event.target.value,
                            },
                            commodity: event.target.value,
                          });
                        }}
                        input={
                          <OutlinedInput
                            labelWidth={field.label.length * 8}
                            name={field.name}
                            id="outlined-age-simple"
                          />
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {this.state.dropdownData[field.dropdownData] &&
                          this.state.dropdownData[field.dropdownData].map(
                            (option, i) => (
                              <MenuItem value={option.nt_category} key={i}>
                                {option.nt_category}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl> */}
                  </>
                ) : null}
              </Grid>
            );

          case "ewaybill status":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                <Typography className={classes.filterSubHeading}>
                  Eway Bill Status
                </Typography>
                <RadioGroup
                  style={{ display: "flex" }}
                  id="eway_bill_status"
                  select
                  label="Eway Bill Status"
                  name="eway_bill_status"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={this.state.eway}
                  onChange={(event) => {
                    this.setState({
                      filterParams: {
                        ...this.state.filterParams,
                        [field.name]: event.target.value,
                      },
                      eway: event.target.value,
                    });
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                >
                  <Grid container item xs={12}>
                    {this.state.EwayBill &&
                      this.state.EwayBill.map((option, i) => (
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={<Radio />}
                            value={option.value}
                            label={option.key}
                            classes={{ label: classes.formLabel }}
                          >
                            {option.key}
                          </FormControlLabel>
                        </Grid>
                      ))}
                  </Grid>
                </RadioGroup>
              </Grid>
            );
          case "action type":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                <Typography className={classes.filterSubHeading}>
                  Action Type
                </Typography>
                <RadioGroup
                  style={{ display: "flex" }}
                  id="action_type"
                  select
                  label="Action Type"
                  name="action_type"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={this.state.action}
                  onChange={(event) => {
                    this.setState({
                      filterParams: {
                        ...this.state.filterParams,
                        [field.name]: event.target.value,
                      },
                      action: event.target.value,
                    });
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                >
                  <Grid container item xs={12}>
                    {this.state.ActionType &&
                      this.state.ActionType.map((option, i) => (
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={<Radio />}
                            value={option.value}
                            label={option.key}
                            classes={{ label: classes.formLabel }}
                          >
                            {option.key}
                          </FormControlLabel>
                        </Grid>
                      ))}
                  </Grid>
                </RadioGroup>
              </Grid>
            );
          case "office":
            return (
              <Grid
                xs={12}
                md={12}
                lg={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                {/* <Typography variant="h6">Office</Typography> */}
                {this.state.Office ? (
                  <Autocomplete
                    disableClearable="true"
                    disableOpenOnFocus="true"
                    options={this.state.Office}
                    value={this.state.offices}
                    name={field.name}
                    getOptionLabel={(option) =>
                      option.office ? option.office : ""
                    }
                    onChange={(event, value) => {
                      if (this.validate()) {
                        this.setState(
                          {
                            assignedfilterParams: {
                              ...this.state.assignedfilterParams,
                              [field.name]: value,
                              squad: "",
                            },
                            offices: value,
                            officer: "",
                            squad: "",
                            toggle: false,
                            searchParams: { [field.name]: value.officeId },
                          },
                          () => {
                            this.props.fetchSquad(
                              value.officeId,
                              this.state.filterParams.from_date,
                              this.state.filterParams.to_date
                            );
                            this.setState({
                              Officer: [],
                            });
                          }
                        );
                      } else {
                        this.props.showSnackbar(
                          "Please enter valid from and to dates..!",
                          "info"
                        );
                      }
                    }}
                    popupIcon={<DownArrow />}
                    classes={{
                      input: classes.textValue,
                      option: classes.textValue,
                      popupIndicatorOpen: classes.popIconOpen,
                      popupIndicator: classes.popIcon,
                      noOptions: classes.textNoOptions,
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label={field.label}
                        name={field.name}
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                      ></TextField>
                    )}
                  />
                ) : null}
              </Grid>
            );
          case "squad":
            return (
              <Grid
                xs={12}
                md={12}
                lg={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                {/* <Typography variant="h6">Squad</Typography> */}
                {this.state.Squad ? (
                  <Autocomplete
                    disableClearable="true"
                    disableOpenOnFocus="true"
                    options={this.state.Squad}
                    value={this.state.squad}
                    name={field.name}
                    getOptionLabel={(option) =>
                      option.squad_name ? option.squad_name : ""
                    }
                    onChange={(event, value) => {
                      this.setState(
                        {
                          assignedfilterParams: {
                            ...this.state.assignedfilterParams,
                            [field.name]: value,
                            officer: "",
                          },
                          squad: value,
                          officer: "",
                          toggle: false,
                          searchParams: { [field.name]: value.squad_id },
                        },
                        () => {
                          this.props.fetchOfficer("squad", value.squad_id);
                        }
                      );
                    }}
                    popupIcon={<DownArrow />}
                    classes={{
                      input: classes.textValue,
                      option: classes.textValue,
                      popupIndicatorOpen: classes.popIconOpen,
                      popupIndicator: classes.popIcon,
                      noOptions: classes.textNoOptions,
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label={field.label}
                        name={field.name}
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                      ></TextField>
                    )}
                  />
                ) : null}
              </Grid>
            );
          case "officer":
            return (
              <Grid
                xs={12}
                md={12}
                item
                key={index}
                classes={{ root: classes.mainGrid }}
              >
                {/* <Typography variant="h6">Officer</Typography> */}
                {this.state.Officer ? (
                  <Autocomplete
                    disableClearable="true"
                    disableOpenOnFocus="true"
                    options={this.state.Officer}
                    value={this.state.officer}
                    name={field.name}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    onChange={(event, value) => {
                      this.setState(
                        {
                          assignedfilterParams: {
                            ...this.state.assignedfilterParams,
                            [field.name]: value,
                          },
                          officer: value,
                          searchParams: { [field.name]: value.userId },
                          toggle: true,
                        },
                        () => {
                          this.state.report_type
                            ? this.setState({
                                filterParams: {
                                  ...this.state.filterParams,
                                  report_type: "verified",
                                },
                              })
                            : this.setState({
                                filterParams: {
                                  ...this.state.filterParams,
                                  report_type: "",
                                },
                              });
                        }
                      );
                    }}
                    popupIcon={<DownArrow />}
                    classes={{
                      input: classes.textValue,
                      option: classes.textValue,
                      popupIndicatorOpen: classes.popIconOpen,
                      popupIndicator: classes.popIcon,
                      noOptions: classes.textNoOptions,
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label={field.label}
                        name={field.name}
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                      ></TextField>
                    )}
                  />
                ) : null}
                {this.state.toggle ? (
                  <>
                    <Typography className={classes.filterSubHeading}>
                      Report Type
                      <FormControlLabel
                        classes={{
                          label: classes.formLabel,
                          color: "#1BB99A",
                          root: classes.switchStyle,
                        }}
                        control={
                          <Switch
                            checked={this.state.report_type ? true : false}
                            // onChange={(event) => {
                            //   this.setState(
                            //     {
                            //       [event.target.name]: event.target.checked,
                            //       assignedfilterParams: {
                            //         ...this.state.assignedfilterParams,
                            //         report_type: event.target.checked,
                            //       },
                            //     },
                            //     () => {
                            //       this.state.report_type
                            //         ? this.setState({
                            //             filterParams: {
                            //               ...this.state.filterParams,
                            //               report_type: "verified",
                            //             },
                            //           })
                            //         : this.setState({
                            //             filterParams: {
                            //               ...this.state.filterParams,
                            //               report_type: "",
                            //             },
                            //           });
                            //     }
                            //   );
                            // }}
                            name="report_type"
                            // disabled
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label={
                          this.state.report_type ? "Verified" : "Not Verified"
                        }
                      />
                    </Typography>
                  </>
                ) : null}
              </Grid>
            );
        }
      });
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          anchor="right"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={this.props.showReportFilterWindow}
          onClose={this.props.toggleFilterWindow}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title">
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={6} md={6}>
                <span className={classes.filterTitle}>Filter</span>
              </Grid>
              <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                <Button
                  disableElevation={true}
                  onClick={this.resetReportFilterParams}
                  className={classes.filterClearAll}
                >
                  Clear All
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider className={classes.divider} />
          <DialogContent
            className="DrawerMain"
            classes={{ root: classes.dialogContent }}
          >
            <Grid container>
              {this.getFilterFields()}
              <div style={{ height: "20px" }}>&nbsp;</div>

              <Grid
                item
                xs={12}
                container
                justify={"space-between"}
                alignItems={"center"}
              >
                <Grid item xs={6} md={6}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.filterSubHeading}
                  >
                    Advanced Filter
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{ textAlign: "right", cursor: "pointer" }}
                  onClick={(e) => {
                    this.setState({
                      extraFilterStatus: !this.state.extraFilterStatus,
                    });
                  }}
                >
                  <IconButton color="inherit">
                    {this.state.extraFilterStatus ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
              {this.state.extraFilterStatus
                ? this.getAdditionalFilterFields()
                : null}
            </Grid>
          </DialogContent>
          <DialogActions className={classes.drawerAction}>
            <Grid container xs={12} justify={"center"} spacing={2}>
              <Grid item xs={1} md={1}></Grid>
              <Grid item xs={5} md={5}>
                <Button
                  onClick={this.closeButton}
                  className={classes.closeButton}
                  fullWidth
                  disableElevation={true}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={5} md={5}>
                <Button
                  onClick={this.setReportFilterParams}
                  color="secondary"
                  autoFocus
                  variant="contained"
                  className={classes.button}
                  fullWidth
                  disableElevation={true}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={1} md={1}></Grid>
            </Grid>
          </DialogActions>
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    report: ownProps.match.params.report_type,
    dropdownData: state.dropdown,
    isGenerate: state.reports.isGenerate,
    field: state.filterhistory.field,
    fetchedzone: state.filterhistory.fetchedzone,
    district: state.filterhistory.district,
    fetchedAlert: state.reports.fetchedAlert,
    office: state.squadDetails.office,
    squad: state.reports.squad,
    officer: state.reports.officer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDropdownData: (type) => dispatch(getDropdownData(type)),
    setReportFilterParams: (params) => dispatch(setReportFilterParams(params)),
    resetReportFilterParams: () => dispatch(resetReportFilterParams()),
    setIsGenerate: () => dispatch(setIsGenerate()),
    fetchSquad: (id, fromdate, todate) =>
      dispatch(fetchSquad(id, fromdate, todate)),
    fetchOffice: (id) => dispatch(fetchOffice(id)),
    fetchAlert: () => dispatch(fetchAlert()),
    fetchZone: (type) => dispatch(fetchZone(type)),
    fetchDistrict: (type, id) => dispatch(fetchDistrict(type, id)),
    fetchField: (type, id) => dispatch(fetchField(type, id)),
    fetchReport: (data) => dispatch(fetchReport(data)),
    exportReport: (data) => dispatch(exportReport(data)),
    fetchOfficer: (type, id) => dispatch(fetchOfficer(type, id)),
    resetReportData: () => dispatch(resetReportData()),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
  };
}

const StylerReport = withStyles(styles)(ReportFilter);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StylerReport)
);
