import { connect } from "react-redux";
import FilterWarning from "./Filter";
import {
  fetchLane,
  // fetchCamera,
  fetchVehicle,
  fetchAlert,
  setConfidence,
  resetConfidence,
} from "./data/action";
import {
  fetchZone,
  fetchDistrict,
  fetchField,
  setFromDate,
  resetFromDate,
  setToDate,
  resetToDate,
  setPlateNo,
  resetPlateNo,
  setZone,
  resetZone,
  setDistrict,
  resetDistrict,
  setField,
  resetField,
  setCamera,
  resetCamera,
  setVehicle,
  resetVehicle,
  setLane,
  resetLane,
} from "../filterhistory_form/data/action";
import {
  fetchStateCamera,
  fetchZoneCamera,
  fetchDistrictCamera,
  fetchFieldCamera,
} from "../../screens/camera/cameraSelector/data/actions.js";
import { resetWarningPage } from "../../screens/review/data/action";

import {
  setWarningFilterParams,
  resetFilterParams,
  setValue,
} from "./data/action";

function mapStateToProps(state) {
  return {
    //filterParams: state.filter.filterParams,
    fetchLanes: state.filterwarning.fetchLanes,
    fetchCameras: state.cameraReducer.cameraList,
    // fetchCameras: state.filterwarning.fetchCameras,
    fetchVehicles: state.filterwarning.fetchVehicles,
    fetchedAlert: state.filterwarning.fetchedAlert,
    field: state.filterhistory.field,
    fetchedzone: state.filterhistory.fetchedzone,
    district: state.filterhistory.district,
    val: state.filterwarning.val,

    //filterParams
    selectedFromDate: state.filterhistory.selectedFromDate,
    selectedToDate: state.filterhistory.selectedToDate,
    filterParams: state.filterwarning.filterParams,
    plate: state.filterhistory.plate,
    assignedZone: state.filterhistory.assignedZone,
    assignedDistrict: state.filterhistory.assignedDistrict,
    assignedField: state.filterhistory.assignedField,
    camera: state.filterhistory.camera,
    vehicle_category: state.filterhistory.vehicle_category,
    lane: state.filterhistory.lane,
    confidence: state.filterwarning.confidence,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetWarningPage: () => dispatch(resetWarningPage()),
    setWarningFilterParams: (filterWarningParams) =>
      dispatch(setWarningFilterParams(filterWarningParams)),
    resetFilterParams: () => dispatch(resetFilterParams()),
    fetchLane: (type) => dispatch(fetchLane(type)),
    // fetchCamera: () => dispatch(fetchCamera()),
    fetchStateCamera: () => dispatch(fetchStateCamera()),
    fetchZoneCamera: (zone) => dispatch(fetchZoneCamera(zone)),
    fetchDistrictCamera: (district) => dispatch(fetchDistrictCamera(district)),
    fetchFieldCamera: (field) => dispatch(fetchFieldCamera(field)),

    fetchAlert: () => dispatch(fetchAlert()),
    fetchVehicle: (type) => dispatch(fetchVehicle(type)),
    fetchZone: (type) => dispatch(fetchZone(type)),
    fetchDistrict: (type, id) => dispatch(fetchDistrict(type, id)),
    fetchField: (type, id) => dispatch(fetchField(type, id)),
    setValue: (data) => dispatch(setValue(data)),

    //filterParams
    setFromDate: (date) => dispatch(setFromDate(date)),
    resetFromDate: () => dispatch(resetFromDate()),
    setToDate: (date) => dispatch(setToDate(date)),
    resetToDate: () => dispatch(resetToDate()),
    setPlateNo: (plate) => dispatch(setPlateNo(plate)),
    resetPlateNo: () => dispatch(resetPlateNo()),
    setZone: (data) => dispatch(setZone(data)),
    resetZone: () => dispatch(resetZone()),
    setDistrict: (data) => dispatch(setDistrict(data)),
    resetDistrict: () => dispatch(resetDistrict()),
    setField: (data) => dispatch(setField(data)),
    resetField: () => dispatch(resetField()),
    setCamera: (data) => dispatch(setCamera(data)),
    resetCamera: () => dispatch(resetCamera()),
    setVehicle: (data) => dispatch(setVehicle(data)),
    resetVehicle: () => dispatch(resetVehicle()),
    setLane: (data) => dispatch(setLane(data)),
    resetLane: () => dispatch(resetLane()),
    setConfidence: (data) => dispatch(setConfidence(data)),
    resetConfidence: () => dispatch(resetConfidence()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterWarning);
