import {
  FETCH_ZONE,
  FETCH_ZONE_FAILED,
  FETCH_DISTRICT,
  FETCH_DISTRICT_FAILED,
  FETCH_FIELD,
  FETCH_FIELD_FAILED,
  FETCH_ZONE_CAMERA,
  FETCH_ZONE_CAMERA_FAILED,
  FETCH_DISTRICT_CAMERA,
  FETCH_DISTRICT_CAMERA_FAILED,
  FETCH_FIELD_CAMERA,
  FETCH_FIELD_CAMERA_FAILED,
  GET_CAMERA_DATA,
  FETCH_STATE_CAMERA,
  FETCH_STATE_CAMERA_FAILED,
} from "./constants";

const initialState = {
  field: [],
  zone: [],
  role: [],
  district: [],
  cameraList: [],
  overviewData: {},
  status: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ZONE:
      return {
        ...state,
        zone: action.data.data.rows,
      };
    case FETCH_ZONE_FAILED:
      return {
        ...state,
        zone: [],
      };
    case FETCH_DISTRICT:
      return {
        ...state,
        district: action.data.data,
      };
    case FETCH_DISTRICT_FAILED:
      return {
        ...state,
        district: [],
      };
    case FETCH_FIELD:
      return {
        ...state,
        field: action.data.data,
      };
    case FETCH_FIELD_FAILED:
      return {
        ...state,
        field: [],
      };
    case FETCH_STATE_CAMERA:
      return {
        ...state,
        cameraList: action.data.data,
      };
    case FETCH_STATE_CAMERA_FAILED:
      return {
        ...state,
        cameraList: [],
      };
    case FETCH_ZONE_CAMERA:
      return {
        ...state,
        cameraList: action.data.data,
      };
    case FETCH_ZONE_CAMERA_FAILED:
      return {
        ...state,
        cameraList: [],
      };
    case FETCH_DISTRICT_CAMERA:
      return {
        ...state,
        cameraList: action.data,
      };
    case FETCH_DISTRICT_CAMERA_FAILED:
      return {
        ...state,
        cameraList: [],
      };
    case FETCH_FIELD_CAMERA:
      return {
        ...state,
        cameraList: action.data.data,
      };
    case FETCH_FIELD_CAMERA_FAILED:
      return {
        ...state,
        cameraList: [],
      };
    case GET_CAMERA_DATA:
      return {
        ...state,
        overviewData: action.data,
        status: true,
      };
    default:
      return state;
  }
}
