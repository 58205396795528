import {
  SET_PLATE_NUMBER,
  FETCH_RECENT_ACTIVITY_SUCCESS,
  FETCH_RECENT_ACTIVITY_FAIL,
  RESET_PLATE_NUMBER,
} from "./constants";
import VehicleService from "service/VehicleService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const setPlateNumber = (plate) => {
  return (dispatch) => {
    dispatch(setPlateNumber(plate));
  };
  function setPlateNumber(plate) {
    return { type: SET_PLATE_NUMBER, plate };
  }
};
export const resetPlateNumber = () => {
  return (dispatch) => {
    dispatch(resetPlateNumber());
  };
  function resetPlateNumber() {
    return { type: RESET_PLATE_NUMBER };
  }
};

export const fetchRecentActivity = (plate) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new VehicleService().fetchRecentActivity(plate).then((response) => {
      if (response.data) {
        dispatch(success(response.data.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };
  function success(data) {
    return { type: FETCH_RECENT_ACTIVITY_SUCCESS, data };
  }
  function failure(error) {
    return { type: FETCH_RECENT_ACTIVITY_FAIL, error };
  }
};
