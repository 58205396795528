import { SET_LOCATION_ID, RESET_LOCATION_ID } from "./constants";
const initialState = {
  filterParams: "",
  cameraId: [],
  oldLocationData: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOCATION_ID:
      let tempOld = action.data.oldData === null ? "" : action.data.oldData;
      return {
        ...state,
        filterParams: action.data.newData,
        cameraList: action.data.newData.cameraId,
        oldLocationData: tempOld,
      };
    case RESET_LOCATION_ID:
      return {
        ...state,
        filterParams: {},
      };
    default:
      return state;
  }
}
