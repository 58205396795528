import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
  TableBody,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  Divider,
} from "@material-ui/core";
import ViewIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import NotificationImportantOutlinedIcon from "@material-ui/icons/NotificationImportantOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import { toDateTime } from "../../../../util/date";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import Skelton from "../../../../components/skelton_screen/index";
const styles = (theme) => ({
  viewIcon: {
    marginTop: "-10px",
  },
  heading: {
    fontWeight: theme.font.fontWeight,
    color: "#000000",
    fontSize: theme.list.fontsize,
  },
  list: {
    paddingTop: "10px",
    paddingLeft: "0px",
    paddingRight: "25px",
    paddingBottum: "10px",
  },
  listItem: { paddingTop: "2px" },
  listIcon: { fontSize: theme.listIcon.fontSize },
  root: {
    minHeight: "100%",
  },
  table: {
    minWidth: "100%",
  },
  tableHeadRow: { height: "10px" },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  headColumn: {
    font: "normal normal bold 14px/43px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    paddingBottom: "2px",
    paddingTop: "2px",
    paddingRight: "1px",
    border: "1px solid #E7EBF4",
    opacity: 1,
    paddingTop: "5px",
    paddingBottom: "4.5px",
    backgroundColor: "white",
  },
  toolbar: {
    paddingRight: theme.spacing.unit,
    backgroundColor: theme.toolbar.backgroundColor,
  },
  tableTitle: {
    color: theme.toolbar.iconColor,
    fontWeight: theme.font.fontWeight,
    fontSize: theme.tableHeader.fontsize,
  },
  scroller: {
    margin: "0 auto",
    // width: "420px",
    overflow: "auto",
  },
  typo: {
    fontWeight: theme.font.fontWeight,
    fontSize: theme.tableHeader.fontsize,
  },
  toolbar: {
    padding: "2px",
    backgroundColor: theme.colors.whiteColor,
  },
  tableWrapper: {
    overflowX: "auto",
    // height: "190px",
    // paddingLeft: "23px",
    marginLeft: "23px",
    paddingRight: "23px",
    // paddingTop: "6px",

    paddingBottom: "23px",

    minHeight: "12vh",

    "@media screen and (max-width : 1280px)": {
      maxHeight: "33vh",
      overflowY: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      maxHeight: "35vh",
      overflowY: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      maxHeight: "34vh",
      overflowY: "auto",
    },
    // /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      maxHeight: "30vh",
      overflowY: "auto",
    },
    "@media screen and (min-width: 1900px)": {
      // height: "350px",
      maxHeight: "34vh",
      overflowY: "auto",
    },
    "@media screen and (min-width: 2000px)": {
      // height: "550px",
      maxHeight: "35vh",
      overflowY: "auto",
    },
  },
  tableTitle: {
    color: theme.colors.chartHeading,
    fontWeight: theme.font.fontWeight,
    textAlign: "left",
    fontWeight: "bold",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    opacity: 1,
    marginLeft: "20px",
  },
  divider: { border: "1px solid #E7EBF4", opacity: 1 },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F1F4FB",
      opacity: 1,
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0px 0px 15px #00000036",
      },
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgb(250 251 254)",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0px 0px 15px #00000036",
      },
    },
  },
  alertDetails: {
    paddingLeft: "12.5px",
    paddingBottom: "12px",
  },
  title: {
    textAlign: "left",
    font: "normal normal normal 16px/36px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
  },
  subTitle: {
    textAlign: "left",
    font: "normal normal bold 16px/17px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
  },
  firstAlertGrid: {
    paddingBottom: "24px",
    paddingLeft: "18px",
    paddingRight: "18px",
    paddingTop: "23px",
  },
});

const CustomTableCell = withStyles((theme) => ({
  root: {
    minWidth: "10px",
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    minWidth: "10px",
    "padding-left": "10px",
    "padding-right": "5px",
  },
}))(TableCell);
class RecentAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      status: "",
      type: "",
      defect_found_flag: "",
      remarks: "",
      level: "",
    };
  }
  componentDidMount() {
    this.props.fetchRecentAlerts(this.props.plate);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.plate !== this.props.plate) {
      this.props.fetchRecentAlerts(this.props.plate);
    }
  }
  handleDialogOpen = (
    alert_status,
    remark,
    alert_type,
    defect_found_flag,
    alert_level
  ) => {
    this.setState({
      ...this.state,
      dialogOpen: true,
      status: alert_status,
      type: alert_type,
      defect_found_flag: defect_found_flag,
      remarks: remark,
      level: alert_level,
    });
  };
  handleDialogClose = () => {
    this.setState({
      ...this.state,
      dialogOpen: false,
    });
  };
  render() {
    const { classes } = this.props;
    const {
      dialogOpen,
      status,
      remarks,
      type,
      defect_found_flag,
      level,
    } = this.state;
    return (
      <div className={classes.scroller}>
        <div className={classes.root}>
          <AppBar position="static" elevation={0}>
            <Toolbar variant="dense" className={classes.toolbar}>
              <Typography
                className={classes.tableTitle}
                variant="h6"
                id="tableTitle"
              >
                Recent Alerts
              </Typography>
            </Toolbar>
          </AppBar>
          <Divider className={classes.divider} />
          <div>
            <Dialog
              open={dialogOpen}
              onClose={this.handleDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-labelledby="draggable-dialog-title"
            >
              <DialogContent style={{ padding: "0" }}>
                <DialogContentText id="alert-dialog-description">
                  <AppBar position="static" elevation={0}>
                    <Toolbar variant="dense" className={classes.toolbar}>
                      <Typography
                        className={classes.tableTitle}
                        variant="h6"
                        id="tableTitle"
                      >
                        Recent alert details
                      </Typography>
                    </Toolbar>
                  </AppBar>{" "}
                  <Divider className={classes.divider} />
                  <Grid container xs={12} className={classes.firstAlertGrid}>
                    <Grid item xs={12} className={classes.alertDetails}>
                      <Typography className={classes.title}>
                        Alert Status
                      </Typography>
                      <Typography className={classes.subTitle}>
                        {status != "" && status != null ? status : "-"}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.alertDetails}>
                      <Typography className={classes.title}>
                        Alert Type
                      </Typography>
                      <Typography className={classes.subTitle}>
                        {type != "" && type != null ? type : "-"}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.alertDetails}>
                      <Typography className={classes.title}>
                        Alert Level
                      </Typography>
                      <Typography className={classes.subTitle}>
                        {level != "" && level != null ? level : "-"}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.alertDetails}>
                      <Typography className={classes.title}>Remarks</Typography>
                      <Typography className={classes.subTitle}>
                        {defect_found_flag == "Y" ? remarks : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </div>
          {this.props.loading ? (
            <Skelton />
          ) : (
            <div className={classes.tableWrapper}>
              <Table className={classes.table} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headColumn}>
                      Location
                    </TableCell>
                    <TableCell className={classes.headColumn}>Camera</TableCell>
                    <TableCell className={classes.headColumn}>
                      Date & Time
                    </TableCell>
                    <TableCell className={classes.headColumn}>Lane</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.recentAlerts.length > 0 &&
                    this.props.recentAlerts.map((row, index) => {
                      return (
                        <TableRow
                          className={classes.zebrafont}
                          key={index}
                          onClick={(e) =>
                            this.handleDialogOpen(
                              row.alert_status,
                              row.remark,
                              row.alert_type,
                              row.defect_found_flag,
                              row.alert_level
                            )
                          }
                        >
                          <TableCell className={classes.tableCell}>
                            {row.location}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.camera_id}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {toDateTime(row.event_timestamp)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.lane_id}
                          </TableCell>
                          {/* <TableCell className={classes.tableCell} align="center">
                        <ViewIcon
                          onClick={(e) =>
                            this.handleDialogOpen(
                              row.alert_status,
                              row.remark,
                              row.alert_type,
                              row.defect_found_flag
                            )
                          }
                        />
                      </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
          )}{" "}
          <br></br>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(RecentAlerts);
