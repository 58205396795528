export default (isPhoneValid) => {
  if (isPhoneValid.match(/\d+$/)) {
    if (isPhoneValid.replace(/[^0-9]/g, "").length === 7) {
      return !/^\d{7}$/.test(isPhoneValid);
    } else if (isPhoneValid.replace(/[^0-9]/g, "").length === 11) {
      return !/^[0-9]{4}[- ]{0,1}[0-9]{7}$/.test(isPhoneValid);
    } else if (isPhoneValid.replace(/[^0-9]/g, "").length === 12) {
      // console.log("12");
      return !/^[+ ]{1}[0-9]{5}[- ]{0,1}[0-9]{7}$/.test(isPhoneValid);
    } else if (
      isPhoneValid.replace(/[^0-9]/g, "").length !== 12 &&
      isPhoneValid.replace(/[^0-9]/g, "").length !== 7 &&
      isPhoneValid.replace(/[^0-9]/g, "").length !== 11
    ) {
      // console.log("else");
      return true;
    }
  }
};
