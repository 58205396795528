import {
  FETCH_RECENT_ALERTS_SUCCESS,
  FETCH_RECENT_ALERTS_FAIL
} from "./constants";
import VehicleService from "../../../../../service/VehicleService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchRecentAlerts = plate => {
  return dispatch => {
    dispatch(progressActions.showProgress());
    new VehicleService().fetchRecentAlerts(plate).then(response => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };
  function success(data) {
    return { type: FETCH_RECENT_ALERTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: FETCH_RECENT_ALERTS_FAIL, error };
  }
};
