import {
  TOGGLE_FILTER_WINDOW,
  SET_HISTORY_FILTER_PARAMS,
  RESET_HISTORY_FILTER_PARAMS,
  FETCH_LANE,
  FETCH_LANE_FAILED,
  FETCH_CAMERA,
  FETCH_CAMERA_FAILED,
  FETCH_VEHICLE,
  FETCH_VEHICLE_FAILED,
  FETCH_ALERT_FAILED,
  FETCH_ALERT,
  FETCH_WARNING,
  FETCH_WARNING_FAILED,
  CLOSE_WINDOW,
  GET_ZONE,
  GET_ZONE_FAILED,
  GET_DISTRICT,
  GET_DISTRICT_FAILED,
  GET_FIELD,
  GET_FIELD_FAILED,
  SET_FROMDATE,
  RESET_FROMDATE,
  SET_TODATE,
  RESET_TODATE,
  SET_PLATE,
  RESET_PLATE,
  SET_ALERTTYPE,
  RESET_ALERTTYPE,
  SET_ZONE,
  RESET_ZONE,
  SET_DISTRICT,
  RESET_DISTRICT,
  SET_FIELD,
  RESET_FIELD,
  SET_CAMERA,
  RESET_CAMERA,
  SET_COMMODITY,
  RESET_COMMODITY,
  SET_OFFENDER,
  RESET_OFFENDER,
  SET_VEHICLE,
  RESET_VEHICLE,
  SET_LANE,
  RESET_LANE,
  SET_WARNINGTYPE,
  RESET_WARNINGTYPE,
} from "./constants";
import { SET_PAGE } from "../../../screens/history/data/constants";
const initialState = {
  showFilterWindow: false,
  val: false,

  filterParams: {},
  fetchLanes: [],
  fetchCameras: [],
  fetchVehicles: [],
  fetchedAlert: [],
  fetchedWarning: [],
  fetchedzone: [],
  district: [],
  field: [],

  selectedFromDate: null,
  selectedToDate: null,
  plate: "",
  alert_type: "",
  assignedZone: {},
  assignedDistrict: {},
  assignedField: {},
  camera: "",
  commodity_category: "",
  offender: "",
  vehicle_category: "",
  lane: "",
  alert_status: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FILTER_WINDOW:
      return {
        ...state,
        showFilterWindow: !state.showFilterWindow,
      };
    case CLOSE_WINDOW:
      return {
        ...state,
        // val: !state.val,
        val: action.data,
      };
    case SET_HISTORY_FILTER_PARAMS:
      return {
        ...state,

        filterParams: action.filterHistoryParams,
      };
    case RESET_HISTORY_FILTER_PARAMS:
      return {
        ...state,

        filterParams: {},
      };
    case FETCH_LANE:
      return {
        ...state,
        fetchLanes: action.data.data,
      };
    case FETCH_LANE_FAILED:
      return {
        ...initialState,
      };
    case FETCH_CAMERA:
      return {
        ...state,
        fetchCameras: action.data.data,
      };
    case FETCH_CAMERA_FAILED:
      return {
        ...initialState,
      };
    case FETCH_VEHICLE:
      return {
        ...state,
        fetchVehicles: action.data.data,
      };
    case FETCH_VEHICLE_FAILED:
      return {
        ...initialState,
      };
    case FETCH_ALERT:
      return {
        ...state,
        fetchedAlert: action.data.data,
      };
    case FETCH_ALERT_FAILED:
      return {
        ...state,
        fetchedAlert: [],
      };
    case FETCH_WARNING:
      return {
        ...state,
        fetchedWarning: action.data.data,
      };
    case FETCH_WARNING_FAILED:
      return {
        ...state,
        fetchedWarning: [],
      };
    case GET_ZONE:
      return {
        ...state,
        fetchedzone: action.data.data,
      };
    case GET_ZONE_FAILED:
      return {
        ...state,
        fetchedzone: [],
      };
    case GET_DISTRICT:
      return {
        ...state,
        district: action.data.data,
      };
    case GET_DISTRICT_FAILED:
      return {
        ...state,
        district: [],
      };
    case GET_FIELD:
      return {
        ...state,
        field: action.data.data,
      };
    case GET_FIELD_FAILED:
      return {
        ...state,
        field: [],
      };
    case SET_FROMDATE:
      return {
        ...state,
        selectedFromDate: action.date,
      };
    case RESET_FROMDATE:
      return {
        ...state,
        selectedFromDate: null,
      };
    case SET_TODATE:
      return {
        ...state,
        selectedToDate: action.date,
      };
    case RESET_TODATE:
      return {
        ...state,
        selectedToDate: null,
      };
    case SET_PLATE:
      return {
        ...state,
        plate: action.plate,
      };
    case RESET_PLATE:
      return {
        ...state,
        plate: "",
      };
    case SET_ALERTTYPE:
      return {
        ...state,
        alert_type: action.data,
      };
    case RESET_ALERTTYPE:
      return {
        ...state,
        alert_type: "",
      };
    case SET_ZONE:
      return {
        ...state,
        assignedZone: action.data,
      };
    case RESET_ZONE:
      return {
        ...state,
        assignedZone: {},
      };
    case SET_DISTRICT:
      return {
        ...state,
        assignedDistrict: action.data,
      };
    case RESET_DISTRICT:
      return {
        ...state,
        assignedDistrict: {},
      };
    case SET_FIELD:
      return {
        ...state,
        assignedField: action.data,
      };
    case RESET_FIELD:
      return {
        ...state,
        assignedField: {},
      };
    case SET_CAMERA:
      return {
        ...state,
        camera: action.data,
      };
    case RESET_CAMERA:
      return {
        ...state,
        camera: "",
      };
    case SET_COMMODITY:
      return {
        ...state,
        commodity_category: action.data,
      };
    case RESET_COMMODITY:
      return {
        ...state,
        commodity_category: "",
      };
    case SET_OFFENDER:
      return {
        ...state,
        offender: action.data,
      };
    case RESET_OFFENDER:
      return {
        ...state,
        offender: "",
      };
    case SET_VEHICLE:
      return {
        ...state,
        vehicle_category: action.data,
      };
    case RESET_VEHICLE:
      return {
        ...state,
        vehicle_category: "",
      };
    case SET_LANE:
      return {
        ...state,
        lane: action.data,
      };
    case RESET_LANE:
      return {
        ...state,
        lane: "",
      };
    case SET_WARNINGTYPE:
      return {
        ...state,
        alert_status: action.data,
      };
    case RESET_WARNINGTYPE:
      return {
        ...state,
        alert_status: "",
      };
    default:
      return state;
  }
}
