import {
  RESET_REPORT_FILTER_PARAMS,
  SET_REPORT_FILTER_PARAMS,
} from "./constants";

export const setReportFilterParams = (params) => {
  return (dispatch) => {
    dispatch(setReportFilterParams(params));
  };
  function setReportFilterParams(params) {
    return { type: SET_REPORT_FILTER_PARAMS, params };
  }
};

export const resetReportFilterParams = () => {
  return (dispatch) => {
    dispatch(resetReportFilterParams());
  };
  function resetReportFilterParams() {
    return { type: RESET_REPORT_FILTER_PARAMS };
  }
};
