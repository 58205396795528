import { TOGGLE_APP_DRAWER, CHANGE_TO_APPBAR_PATH } from "./constants";
const initialState = {
  showAppDrawer: true,
  appBarPath: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_APP_DRAWER:
      return {
        ...state,
        showAppDrawer: !state.showAppDrawer,
      };
    case CHANGE_TO_APPBAR_PATH:
      return {
        ...state,
        appBarPath: action.data,
      };

    default:
      return state;
  }
}
