import {
  ADD_EDIT_FLAMMABLE_GOODS,
  ADD_EDIT_FLAMMABLE_GOODS_FAILED,
  FETCH_FLAMMABLE_GOODS_SUCCESS,
  FETCH_FLAMMABLE_GOODS_FAILED,
  RESET_FLAMMABLE_GOODS_STATE,
} from "./constants";

const initialState = {
  fetchedFlammableGoods: {},
  flammableGoods: "",
  error: "",
  saveSuccess: false,
  message: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_FLAMMABLE_GOODS_SUCCESS:
      return {
        ...initialState,
        fetchedFlammableGoods: action.data.data,
      };

    case FETCH_FLAMMABLE_GOODS_FAILED:
      return {
        ...initialState,
      };

    case ADD_EDIT_FLAMMABLE_GOODS:
      return {
        ...state,
        flammableGoods: action.data,
        saveSuccess: true,
        message: action.data.message,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };

    case ADD_EDIT_FLAMMABLE_GOODS_FAILED:
      return {
        ...initialState,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };
    case RESET_FLAMMABLE_GOODS_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
