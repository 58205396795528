import {
  ADD_EDIT_FLAMMABLE_GOODS,
  ADD_EDIT_FLAMMABLE_GOODS_FAILED,
  RESET_FLAMMABLE_GOODS_STATE,
  FETCH_FLAMMABLE_GOODS_SUCCESS,
  FETCH_FLAMMABLE_GOODS_FAILED
} from "./constants";
import FlammableGoodsService from "service/FlammableGoodsService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";
//import { fail } from "assert";

export const addFlammableGoods = params => {
  return dispatch => {
    dispatch(progressActions.showProgress());
    new FlammableGoodsService().addFlammableGood(params).then(response => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: ADD_EDIT_FLAMMABLE_GOODS, data };
  }
  function failure(error) {
    return { type: ADD_EDIT_FLAMMABLE_GOODS_FAILED, error };
  }
};

export const fetchFlammableGood = id => {
  return dispatch => {
    dispatch(progressActions.showProgress());
    new FlammableGoodsService().fetchFlammableGoods(id).then(response => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_FLAMMABLE_GOODS_SUCCESS, data };
  }
  function failure(error) {
    return { type: FETCH_FLAMMABLE_GOODS_FAILED, error };
  }
};

export const editFlammableGoods = (params, id) => {
  return dispatch => {
    dispatch(progressActions.showProgress());
    new FlammableGoodsService()
      .editFlammableGoods(params, id)
      .then(response => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: ADD_EDIT_FLAMMABLE_GOODS, data };
  }
  function failure(error) {
    return { type: ADD_EDIT_FLAMMABLE_GOODS_FAILED, error };
  }
};

export function resetFlammableGoodsDetail() {
  return {
    type: RESET_FLAMMABLE_GOODS_STATE
  };
}
