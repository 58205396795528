import { EDIT_USER, EDIT_USER_FAILED, RESET_USER_STATE } from "./constants";
import UserService from "service/UserService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";
//import { fail } from "assert";

export const editUserPassword = (params) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserService().updatePassword(params).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        if (response.response.data) {
          dispatch(
            snackbarActions.showSnackbar(
              response.response.data.message,
              "error"
            )
          );
        }

        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: EDIT_USER, data };
  }
  function failure(error) {
    return { type: EDIT_USER_FAILED, error };
  }
};
export function resetUserDetail() {
  return {
    type: RESET_USER_STATE,
  };
}
