import { connect } from "react-redux";
import { withRouter } from "react-router";
import Overrview from "./Overrview";
import { getOverviewData, getVehicleCount } from "./data/action";
import { changetoAppbarPath } from "../../../components/drawer/data/actions";

function mapStateToProps(state) {
  return {
    overviewData: state.overview.overviewData,
    messageData: state.overview.messageData,
    locationId: state.locationSelector.filterParams,
    oldLocationData: state.locationSelector.oldLocationData,
    vehicleTypeCount: state.vehicleTypeCount.vehicleTypeCount,
    showAppDrawer: state.drawer.showAppDrawer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changetoAppbarPath: (data) => dispatch(changetoAppbarPath(data)),
    getOverviewData: (socket, newData, oldData) =>
      dispatch(getOverviewData(socket, newData, oldData)),
    getVehicleCount: (params) => dispatch(getVehicleCount(params)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Overrview)
);
