import { connect } from "react-redux";
import { withRouter } from "react-router";
import EditRole from "./editRole";
import { updateRoleMaster, fetchPermissionList } from "../addRole/data/action";
import { push } from "connected-react-router";

function mapStateToProps(state) {
  return {
    updateStatus: state.saveSuccess.updateStatus,
    permissionList: state.permissionList.permissionList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPermissionList: (id) => dispatch(fetchPermissionList(id)),
    updateRoleMaster: (params) => dispatch(updateRoleMaster(params)),
    navigateTo: (url) => dispatch(push(url)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditRole)
);
