import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import { ReactComponent as TotalCount } from "../../../assets/SvgIcons/total vehicle.svg";
import { ReactComponent as CommercialCount } from "../../../assets/SvgIcons/commercial_vehicles.svg";
import { ReactComponent as AlertCount } from "../../../assets/SvgIcons/alerts_warnings.svg";
import { ReactComponent as EwaybillCount } from "../../../assets/SvgIcons/e_way_bill.svg";
import OverviewCard from "./OverviewCard";

const styles = (theme) => ({
  container: {
    paddingTop: "37px",
    paddingLeft: "14px",
  },
  openContainer: {
    paddingTop: "37px",
    paddingLeft: "16px",
    paddingRight: "6px",
  },
  cardIcon: {
    width: "34px",
    height: "26px",
  },
  cardIcon1: {
    width: "34px",
    height: "26px",
    fill: "#28AC47",
  },
});
class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overviewData: {},
    };
  }
  componentDidMount() {
    this.setState({ overviewData: this.props.overviewData });
    let params = [];
    if (this.props.locationId.location === "All Camera") {
      params.push(-1);
      this.props.getVehicleCount(params);
    } else if (this.props.locationId.cameraId !== undefined) {
      this.props.getVehicleCount(this.props.locationId.cameraId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.overviewData != this.props.overviewData) {
      this.setState({ overviewData: this.props.overviewData });
    }
    if (prevProps.vehicleTypeCount != this.props.vehicleTypeCount) {
    }
    if (prevProps.locationId != this.props.locationId) {
      let params = [];
      if (this.props.locationId.location === "All Camera") {
        params.push(-1);
        this.props.getVehicleCount(params);
      } else if (this.props.locationId.cameraId !== undefined) {
        this.props.getVehicleCount(this.props.locationId.cameraId);
      }
    }
  }
  componentWillUnmount() {
    let routeUrl = "";
    this.props.changetoAppbarPath(routeUrl);
  }
  render() {
    const { overviewData } = this.state;
    const { classes } = this.props;

    return (
      <Grid
        container
        className={
          this.props.showAppDrawer ? classes.openContainer : classes.container
        }
      >
        <OverviewCard
          cardTitle="Total Vehicles"
          cardTitleNumeric={0}
          showDrawer={this.props.showAppDrawer}
          cardTitleNumeric={
            overviewData.vehicle_count ? overviewData.vehicle_count : 0
            //  === null ? 0
          }
          footerText="Passed today"
          cardIcon={<TotalCount />}
          //footerIcon={<InfoIcon />}
        />

        <OverviewCard
          cardTitle="Commercial vehicles"
          showDrawer={this.props.showAppDrawer}
          cardTitleNumeric={
            overviewData.commercial_vehicle_count
              ? overviewData.commercial_vehicle_count
              : 0
          }
          footerText="Passed Today"
          cardIcon={<CommercialCount />}
          // footerIcon={<InfoIcon />}
        />
        <OverviewCard
          cardTitle="Alerts"
          showDrawer={this.props.showAppDrawer}
          cardTitleNumeric={
            overviewData.alert_count ? overviewData.alert_count : 0
          }
          footerText="Generated today"
          cardIcon={<AlertCount />}
          //footerIcon={<InfoIcon />}
        />

        <OverviewCard
          cardTitle="E-way bill Valid/Invalid"
          showDrawer={this.props.showAppDrawer}
          cardTitleNumeric={
            (overviewData.validEwayBillCount
              ? overviewData.validEwayBillCount
              : 0) +
            "/" +
            (overviewData.invalidEwayBillCount
              ? overviewData.invalidEwayBillCount
              : 0)
          }
          footerText="Count for today"
          cardIcon={<EwaybillCount />}
          //footerIcon={<InfoIcon />}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(Overview);
