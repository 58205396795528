import {
  GET_DROPDOWN_DATA_FAILURE,
  GET_VEHICLE_CATEGORY_DROPDOWN,
  GET_CONFIDENCE_DROPDOWN,
  GET_LANE_DROPDOWN,
  GET_CAMERA_DROPDOWN,
  GET_COMMODITY_DROPDOWN,
} from "./constants";

const initialState = {
  vehicleCategories: [],
  confidences: [],
  lane: [],
  camera: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_CATEGORY_DROPDOWN:
      return {
        ...state,
        vehicleCategories: action.data.data,
      };
    case GET_COMMODITY_DROPDOWN:
      return {
        ...state,
        commodityCategories: action.data.data,
      };
    case GET_CONFIDENCE_DROPDOWN:
      return {
        ...state,
        confidences: action.data.data,
      };
    case GET_LANE_DROPDOWN:
      return {
        ...state,
        lane: action.data.data,
      };
    case GET_CAMERA_DROPDOWN:
      return {
        ...state,
        camera: action.data.data,
      };
    case GET_DROPDOWN_DATA_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
}
