import React from "react";
import * as d3 from "d3";
import { axisBottom, axisLeft } from "d3-axis";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { getVehicleTypeCount } from "../overview/data/action";
import "./styles.css";
import moment from "moment";
import {
  Toolbar,
  Typography,
  withStyles,
  AppBar,
  Tooltip,
} from "@material-ui/core";
import classNames from "classnames";

const margin = { top: 33, right: 1, bottom: 20, left: 50 };
const styles = (theme) => ({
  paper: {
    // width: "607px",
    height: "346px",
    [theme.breakpoints.up("xl")]: {
      height: "470px",
    },
    "@media screen and (min-width: 1900px)": {
      minHeight: "40vh",
    },
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
  },
  openPaper: {
    // width: "607px",
    height: "346px",
    [theme.breakpoints.up("xl")]: {
      height: "470px",
    },
    "@media screen and (min-width: 1900px)": {
      minHeight: "43vh",
    },
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
  },
  appbar: {
    backgroundColor: "#FFFFFF",
    borderRadius: "15px",
    // width: "607px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 0px",
    opacity: 1,
    paddingRight: "14px",
    paddingTop: "8px",
  },
  root: {
    borderRadius: theme.border.radius,
    paddingRight: theme.spacing.unit,
    backgroundColor: theme.colors.whiteColor,
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },

  spacer: {
    flex: "1 1 20%",
  },
  tableTitle: {
    color: theme.colors.chartHeading,
    textAlign: "left",
    fontWeight: theme.font.Bold,
    font: "normal normal bold 16px/22px Karla",
    letterSpacing: "0px",
    opacity: 1,
    marginLeft: "8px",
  },
  passedToday: {
    color: theme.colors.chartHeading,
    textAlign: "left",
    fontWeight: "normal",
    font: "normal normal normal 14px/36px Karla",
    letterSpacing: "0px",
    opacity: 0.5,
    paddingRight: theme.spacing.unit,
    marginLeftt: "31px",
  },
  barChart: {
    display: "block",
    paddingLeft: "9px",
    paddingTop: "8px",
    paddingRight: "29px",
  },
  openbarChart: {
    display: "block",
    paddingLeft: "9px",
    paddingTop: "25px",
    paddingRight: "29px",
  },
  axisLabels: {
    fontSize: "12px",
    fontFamily: theme.font.family,
    opacity: 1,
    color: theme.colors.chartHeading,
  },
  tooltips: {
    backgroundColor: theme.colors.chartHeading,
    color: theme.colors.whiteColor,
    fontFamily: theme.font.family,
    minWidth: "40px",
    padding: "5px",
    textAlign: "center",
  },
  tooltipArrows: { color: theme.colors.chartHeading },
});
class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: null,
      currentData: null,
      height: 280,
      width: 600,
      event: null,
      data: [],
    };
    this.yAxisRef = React.createRef();
    this.xAxisRef = React.createRef();
    this.gridRef = React.createRef();

    this.xScale = d3
      .scaleBand()
      .range([margin.left, this.state.width - margin.right])
      .padding(0.8);

    this.yScale = d3
      .scaleLinear()
      .range([this.state.height - margin.bottom, margin.top]);

    this.grid = d3
      .axisLeft()
      .scale(this.yScale)
      .tickSize(-this.state.width + margin.left, 0, 0, 0)
      .tickFormat("");
  }

  componentDidMount() {
    let params = [];
    if (this.props.locationData.location === "All Camera") {
      params.push(-1);
      this.props.getVehicleTypeCount(params);
    } else if (this.props.locationData.cameraId !== undefined) {
      this.props.getVehicleTypeCount(this.props.locationData.cameraId);
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.locationData != this.props.locationData) {
      let params = [];
      if (this.props.locationData.location === "All Camera") {
        params.push(-1);
        this.props.getVehicleTypeCount(params);
      } else {
        this.props.getVehicleTypeCount(this.props.locationData.cameraId);
      }
    }

    if (prevProps.data != this.props.data) {
      let data = "";
      data = this.props.data;

      let final = [];
      if (data.length) {
        this.setState({
          data: this.props.data,
        });
        final = data;
      } else {
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            final.push({
              type: key,
              value: data[key],
            });
          }
        }
        this.setState({
          data: final,
        });
      }

      this.xScale.domain(final.map((a) => a.type));

      //this.yScale.domain([0, Math.max(...final.map(a => a.value))]);

      {
        this.props.vehicleCount > 0 || this.props.vehicleCount === undefined
          ? this.yScale.domain([0, Math.max(...final.map((a) => a.value)) + 10])
          : this.yScale.domain([]);
      }

      this.xAxis = axisBottom(this.xScale);
      this.yAxis = axisLeft(this.yScale);
      d3.select(this.xAxisRef.current).call(this.xAxis);
      d3.select(this.yAxisRef.current).call(this.yAxis);
      d3.select(this.gridRef.current).call(this.grid);
    }
  }

  highlightBar = (event, currentIndex, currentData) => {
    if (event) {
      event.persist();
      this.setState({
        event,
      });
      d3.select(event.target)
        .transition()
        .duration(300)
        .attr("x", this.xScale(currentData.type))
        .attr("width", this.xScale.bandwidth());
    }
    this.setState({
      currentIndex,
      currentData,
    });
  };

  onMouseOut = (event) => {
    d3.select(event.target)
      .transition()
      .duration(300)
      .attr("x", this.xScale(this.state.currentData.type))
      .attr("width", this.xScale.bandwidth());
    this.highlightBar(null);
  };
  arc = (r, sign) =>
    r
      ? `a${r * sign[0]},${r * sign[1]} 0 0 1 ${r * sign[2]},${r * sign[3]}`
      : "";
  roundedRect = (x, y, width, height, r) => {
    r = [
      Math.min(r[0], height, width),
      Math.min(r[1], height, width),
      Math.min(r[2], height, width),
      Math.min(r[3], height, width),
    ];

    return `M${x + r[0]},${y}h${width - r[0] - r[1]}${this.arc(r[1], [
      1,
      1,
      1,
      1,
    ])}v${height - r[1] - r[2]}${this.arc(r[2], [1, 1, -1, 1])}h${-width + r[2] + r[3]
      }${this.arc(r[3], [1, 1, -1, -1])}v${-height + r[3] + r[0]}${this.arc(
        r[0],
        [1, 1, 1, -1]
      )}z`;
  };
  render() {
    const { height, width, data } = this.state;
    const { classes } = this.props;

    return (
      <Paper
        className={this.props.showAppDrawer ? classes.openPaper : classes.paper}
        elevation={0}
      >
        <AppBar position="static" elevation={0} className={classes.appbar}>
          <Toolbar variant="dense" className={classes.root}>
            <Typography className={classes.tableTitle}>
              Vehicle Type Count
            </Typography>
            <div className={classes.spacer} />
            <Typography className={classes.passedToday}>
              Generated Today
            </Typography>
          </Toolbar>
        </AppBar>
        <svg
          width="100%"
          height="85%"
          preserveAspectRatio="xMinYMin"
          viewBox={"0 0 " + width + " " + 300}
          className={
            this.props.showAppDrawer ? classes.openbarChart : classes.barChart
          }
        >
          <g
            strokeWidth="0"
            ref={this.xAxisRef}
            strokeWidth="0"
            transform={`translate(0, ${height - margin.bottom})`}
            className={classes.axisLabels}
          />
          <g
            strokeWidth="0"
            ref={this.yAxisRef}
            transform={`translate(${margin.left}, 0)`}
            className={classes.axisLabels}
          />
          <g
            ref={this.gridRef}
            className="grid"
            transform={`translate(${margin.left}, 0)`}
          />
          <g>
            {data.map((d, i) => {
              return d.value != 0 ? (
                <>
                  <Tooltip
                    title={d.value}
                    arrow
                    placement="top"
                    classes={{
                      tooltip: classes.tooltips,
                      arrow: classes.tooltipArrows,
                    }}
                  >
                    <g>
                      <rect
                        x={this.xScale(d.type)}
                        y={this.yScale(d.value)}
                        width={14}
                        height={height - margin.bottom - this.yScale(d.value)}
                        style={{
                          fill:
                            i === this.state.currentIndex
                              ? "#1BB99A"
                              : "#DFE4EF",
                        }}
                        onMouseOut={(e) => this.onMouseOut(e)}
                        onMouseOver={(e) => this.highlightBar(e, i, d)}
                        key={i}
                      />
                      {/* <path
                        d={this.roundedRect(
                          this.xScale(d.type),
                          this.yScale(d.value),
                          this.xScale.bandwidth(),
                          this.yScale(0) - this.yScale(d.value),
                          [10, 10, 0, 0]
                        )}
                        style={{
                          fill:
                            i === this.state.currentIndex
                              ? "#1BB99A"
                              : "#DFE4EF",
                        }}
                        onMouseOut={(e) => this.onMouseOut(e)}
                        onMouseOver={(e) => this.highlightBar(e, i, d)}
                      /> */}
                    </g>
                  </Tooltip>
                </>
              ) : null;
            })}
          </g>
          {data.map((d, i) => {
            return d.value != 0 ? (
              <g key={i}>
                {/* <rect
                  x={this.xScale(d.type) + this.xScale.bandwidth() / 2 - 23}
                  y={this.yScale(d.value) - 32}
                  rx={4}
                  width={49}
                  height={22}
                  style={{
                    fill: "#C4CCDC",
                  }}
                  key={i}
                /> */}
                <text
                  x={this.xScale(d.type) + this.xScale.bandwidth() / 2}
                  y={this.yScale(d.value) - 17}
                  key={i}
                  textAnchor="middle"
                  fontSize="12px"
                  fontFamily="Roboto"
                  fill="black"
                >
                  {d.value}
                </text>
              </g>
            ) : null;
          })}
        </svg>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationData: state.locationSelector.filterParams,
    data: state.vehicleTypeCount.vehicleTypeCount,
    // data_from_api: state.vehicleTypeCount_from_api.vehicleTypeCount,
    vehicleCount: state.overview.overviewData.vehicle_count,
    showAppDrawer: state.drawer.showAppDrawer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getVehicleTypeCount: (params) => dispatch(getVehicleTypeCount(params)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BarChart));
// import React from "react";
// import * as d3 from "d3";
// import { axisBottom, axisLeft } from "d3-axis";
// import { Paper } from "@material-ui/core";
// import { connect } from "react-redux";
// import { getVehicleTypeCount } from "../overview/data/action";
// import Tooltip from "./Tooltip";
// import "./styles.css";
// import moment from "moment";
// import { Toolbar, Typography, withStyles, AppBar } from "@material-ui/core";
// import classNames from "classnames";

// const margin = { top: 30, right: 5, bottom: 20, left: 50 };
// const styles = (theme) => ({
//   root: {
//     paddingRight: theme.spacing.unit,
//     backgroundColor: theme.toolbar.backgroundColor,
//   },
//   actions: {
//     paddingRight: theme.spacing.unit,
//   },
//   title: {
//     flex: "0 0 auto",
//   },
//   tableTitle: {
//     color: theme.toolbar.iconColor,
//     fontWeight: theme.font.fontWeight,
//   },
//   spacer: {
//     flex: "1 1 20%",
//   },
//   passedToday: {
//     color: theme.toolbar.iconColor,
//     fontWeight: theme.font.fontWeight,
//     fontSize: theme.passedToday.fontSize,
//     paddingRight: theme.spacing.unit,
//   },
// });
// class BarChart extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       currentIndex: null,
//       currentData: null,
//       height: 300,
//       width: 600,
//       event: null,
//       data: [],
//     };
//     this.yAxisRef = React.createRef();
//     this.xAxisRef = React.createRef();
//     this.gridRef = React.createRef();

//     this.xScale = d3
//       .scaleBand()
//       .range([margin.left, this.state.width - margin.right])
//       .padding(0.3);

//     this.yScale = d3
//       .scaleLinear()
//       .range([this.state.height - margin.bottom, margin.top]);

//     this.grid = d3
//       .axisLeft()
//       .scale(this.yScale)
//       .tickSize(-this.state.width + margin.left, 0, 0, 0)
//       .tickFormat("");
//   }

//   componentDidMount() {
//     let params = [];
//     if (this.props.locationData.location === "All Camera") {
//       params.push(-1);
//       this.props.getVehicleTypeCount(params);
//     } else if (this.props.locationData.cameraId !== undefined) {
//       this.props.getVehicleTypeCount(this.props.locationData.cameraId);
//     }
//   }
//   componentDidUpdate(prevProps) {
//     if (prevProps.locationData != this.props.locationData) {
//       let params = [];
//       if (this.props.locationData.location === "All Camera") {
//         params.push(-1);
//         this.props.getVehicleTypeCount(params);
//       } else {
//         this.props.getVehicleTypeCount(this.props.locationData.cameraId);
//       }
//     }

//     if (prevProps.data != this.props.data) {
//       let data = "";
//       data = this.props.data;

//       let final = [];
//       if (data.length) {
//         this.setState({
//           data: this.props.data,
//         });
//         final = data;
//       } else {
//         for (var key in data) {
//           if (data.hasOwnProperty(key)) {
//             final.push({
//               type: key,
//               value: data[key],
//             });
//           }
//         }
//         this.setState({
//           data: final,
//         });
//       }

//       this.xScale.domain(final.map((a) => a.type));

//       //this.yScale.domain([0, Math.max(...final.map(a => a.value))]);

//       {
//         this.props.vehicleCount > 0 || this.props.vehicleCount === undefined
//           ? this.yScale.domain([0, Math.max(...final.map((a) => a.value))])
//           : this.yScale.domain([]);
//       }

//       this.xAxis = axisBottom(this.xScale);
//       this.yAxis = axisLeft(this.yScale);
//       d3.select(this.xAxisRef.current).call(this.xAxis);
//       d3.select(this.yAxisRef.current).call(this.yAxis);
//       d3.select(this.gridRef.current).call(this.grid);
//     }
//   }

//   highlightBar = (event, currentIndex, currentData) => {
//     if (event) {
//       event.persist();
//       this.setState({
//         event,
//       });
//       d3.select(event.target)
//         .transition()
//         .duration(300)
//         .attr("x", this.xScale(currentData.type) - 5)
//         .attr("width", this.xScale.bandwidth() + 10);
//     }
//     this.setState({
//       currentIndex,
//       currentData,
//     });
//   };

//   onMouseOut = (event) => {
//     d3.select(event.target)
//       .transition()
//       .duration(300)
//       .attr("x", this.xScale(this.state.currentData.type))
//       .attr("width", this.xScale.bandwidth());
//     this.highlightBar(null);
//   };
//   render() {
//     const { height, width, data } = this.state;
//     const { classes } = this.props;

//     return (
//       <Paper>
//         <AppBar position="static" elevation={0}>
//           <Toolbar variant="dense" className={classes.root}>
//             <Typography
//               variant="h6"
//               id="tableTitle"
//               className={classes.tableTitle}
//             >
//               Vehicle Type Count
//             </Typography>
//             <div className={classes.spacer} />
//             <Typography
//               variant="h6"
//               id="tableTitle"
//               className={classes.passedToday}
//             >
//               Generated Today
//             </Typography>{" "}
//           </Toolbar>
//         </AppBar>
//         <svg
//           width="90%"
//           height="80%"
//           preserveAspectRatio="xMinYMin"
//           viewBox={"0 0 " + width + " " + 319}
//           style={{ display: "block", padding: "20px" }}
//         >
//           <g
//             ref={this.xAxisRef}
//             transform={`translate(0, ${height - margin.bottom})`}
//           />
//           <g ref={this.yAxisRef} transform={`translate(${margin.left}, 0)`} />
//           <g
//             ref={this.gridRef}
//             className="grid"
//             transform={`translate(${margin.left}, 0)`}
//           />
//           <g>
//             {data.map((d, i) => {
//               return d.value != 0 ? (
//                 <rect
//                   x={this.xScale(d.type)}
//                   y={this.yScale(d.value)}
//                   width={this.xScale.bandwidth()}
//                   height={height - margin.bottom - this.yScale(d.value)}
//                   style={{
//                     fill:
//                       i === this.state.currentIndex
//                         ? "rgb(251, 140, 0)"
//                         : "#2F4A6D",
//                   }}
//                   key={i}
//                   onMouseOut={(e) => this.onMouseOut(e)}
//                   onMouseOver={(e) => this.highlightBar(e, i, d)}
//                 />
//               ) : null;
//             })}
//           </g>
//           {/* <text x={(width - 100) / 2} y={margin.top - 10}>
//             Vehicle type count
//           </text> */}

//           <text x={width / 2} y={height + margin.bottom + 20}>
//             Vehicle Type
//           </text>
//           {/* <text
//             x={width - 105}
//             y={margin.bottom + 20}
//             style={{ fill: "grey", fontSize: "1rem" }}
//           > */}
//           {/* {moment().format("DD MMM YYYY")} */}
//           {/* {new Date().toLocaleDateString()} */}
//           {/* on date */}
//           {/* Passed today
//           </text> */}
//           <text
//             x={margin.left}
//             y={height / 2 - 35}
//             transform={`rotate(-90,${margin.left},${height / 2})`}
//           >
//             Count
//           </text>
//           {this.state.currentData ? (
//             <line
//               x1={margin.left}
//               y1={this.yScale(this.state.currentData.value)}
//               x2={width}
//               y2={this.yScale(this.state.currentData.value)}
//               stroke="#212B35"
//               strokeWidth={3}
//               strokeDasharray="3 6"
//             />
//           ) : null}
//           {data.map((d, i) => {
//             return d.value != 0 ? (
//               <text
//                 x={this.xScale(d.type) + this.xScale.bandwidth() / 2}
//                 y={this.yScale(d.value) + 15}
//                 key={i}
//                 textAnchor="middle"
//                 fontSize="12px"
//                 fill="#FFF"
//               >
//                 {d.value}
//               </text>
//             ) : null;
//           })}
//         </svg>

//         {this.state.currentData ? (
//           <Tooltip
//             currentData={this.state.currentData}
//             event={this.state.event}
//           />
//         ) : null}
//       </Paper>
//     );
//   }
// }

// function mapStateToProps(state) {
//   return {
//     locationData: state.locationSelector.filterParams,
//     data: state.vehicleTypeCount.vehicleTypeCount,
//     // data_from_api: state.vehicleTypeCount_from_api.vehicleTypeCount,
//     vehicleCount: state.overview.overviewData.vehicle_count,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     getVehicleTypeCount: (params) => dispatch(getVehicleTypeCount(params)),
//   };
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withStyles(styles)(BarChart));
