import { connect } from "react-redux";
import PhotoStepper from "./PhotoStepper";

function mapStateToProps(state) {
  return {
    record: state.vehicleInfo.record
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotoStepper);
