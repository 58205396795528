import { connect } from "react-redux";
import Filter from "./Filter";
import {
  setAlertFilterParams,
  resetFilterParams,
  setValue,
  fetchLane,
  // fetchCamera,
  fetchVehicle,
  fetchAlert,
} from "./data/action";
import {
  fetchZone,
  fetchDistrict,
  fetchField,
  setFromDate,
  resetFromDate,
  setToDate,
  resetToDate,
  setPlateNo,
  resetPlateNo,
  setAlertType,
  resetAlertType,
  setZone,
  resetZone,
  setDistrict,
  resetDistrict,
  setField,
  resetField,
  setCamera,
  resetCamera,
  setOffender,
  resetOffender,
  setVehicle,
  resetVehicle,
  setLane,
  resetLane,
} from "../filterhistory_form/data/action.js";
import {
  fetchStateCamera,
  fetchZoneCamera,
  fetchDistrictCamera,
  fetchFieldCamera,
} from "../../screens/camera/cameraSelector/data/actions.js";
import { resetAlertPage } from "../../screens/alerts/data/action";
function mapStateToProps(state) {
  return {
    //filterParams: state.filter.filterParams,
    fetchLanes: state.filteralert.fetchLanes,
    fetchCameras: state.cameraReducer.cameraList,
    // fetchCameras: state.filteralert.fetchCameras,
    fetchVehicles: state.filteralert.fetchVehicles,
    fetchedAlert: state.filteralert.fetchedAlert,
    field: state.filterhistory.field,
    fetchedzone: state.filterhistory.fetchedzone,
    district: state.filterhistory.district,
    val: state.filteralert.val,

    //filterparams
    selectedFromDate: state.filterhistory.selectedFromDate,
    selectedToDate: state.filterhistory.selectedToDate,
    filterParams: state.filteralert.filterParams,
    plate: state.filterhistory.plate,
    alert_type: state.filterhistory.alert_type,
    assignedZone: state.filterhistory.assignedZone,
    assignedDistrict: state.filterhistory.assignedDistrict,
    assignedField: state.filterhistory.assignedField,
    camera: state.filterhistory.camera,
    offender: state.filterhistory.offender,
    vehicle_category: state.filterhistory.vehicle_category,
    lane: state.filterhistory.lane,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetAlertPage: () => dispatch(resetAlertPage()),
    setAlertFilterParams: (filterAlertParams) =>
      dispatch(setAlertFilterParams(filterAlertParams)),
    resetFilterParams: () => dispatch(resetFilterParams()),
    fetchLane: (type) => dispatch(fetchLane(type)),
    // fetchCamera: () => dispatch(fetchCamera()),
    fetchStateCamera: () => dispatch(fetchStateCamera()),
    fetchZoneCamera: (zone) => dispatch(fetchZoneCamera(zone)),
    fetchDistrictCamera: (district) => dispatch(fetchDistrictCamera(district)),
    fetchFieldCamera: (field) => dispatch(fetchFieldCamera(field)),

    fetchAlert: () => dispatch(fetchAlert()),
    fetchVehicle: (type) => dispatch(fetchVehicle(type)),
    fetchZone: (type) => dispatch(fetchZone(type)),
    fetchDistrict: (type, id) => dispatch(fetchDistrict(type, id)),
    fetchField: (type, id) => dispatch(fetchField(type, id)),
    setValue: (data) => dispatch(setValue(data)),

    //filterParams
    setFromDate: (date) => dispatch(setFromDate(date)),
    resetFromDate: () => dispatch(resetFromDate()),
    setToDate: (date) => dispatch(setToDate(date)),
    resetToDate: () => dispatch(resetToDate()),
    setPlateNo: (plate) => dispatch(setPlateNo(plate)),
    resetPlateNo: () => dispatch(resetPlateNo()),
    setAlertType: (data) => dispatch(setAlertType(data)),
    resetAlertType: () => dispatch(resetAlertType()),
    setZone: (data) => dispatch(setZone(data)),
    resetZone: () => dispatch(resetZone()),
    setDistrict: (data) => dispatch(setDistrict(data)),
    resetDistrict: () => dispatch(resetDistrict()),
    setField: (data) => dispatch(setField(data)),
    resetField: () => dispatch(resetField()),
    setCamera: (data) => dispatch(setCamera(data)),
    resetCamera: () => dispatch(resetCamera()),
    setOffender: (data) => dispatch(setOffender(data)),
    resetOffender: () => dispatch(resetOffender()),
    setVehicle: (data) => dispatch(setVehicle(data)),
    resetVehicle: () => dispatch(resetVehicle()),
    setLane: (data) => dispatch(setLane(data)),
    resetLane: () => dispatch(resetLane()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
