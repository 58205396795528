import { push } from "connected-react-router";
import { withRouter } from "react-router";
import UserInfo from "./UserInfo";
import { connect } from "react-redux";
import { editUserPassword, resetUserDetail } from "./data/action";
import { snackbarActions } from "components/snackbar/data/action";

function mapStateToProps(state) {
  return {
    saveSuccess: state.user.saveSuccess,
    message: state.user.message,
    error: state.user.error
  };
}

function mapDispatchToProps(dispach) {
  return {
    navigateTo: url => dispach(push(url)),
    editUserPassword: data => dispach(editUserPassword(data)),
    showSnackbar: (message, type) =>
      dispach(snackbarActions.showSnackbar(message, type)),
    resetUserDetail: () => dispach(resetUserDetail())
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserInfo)
);
