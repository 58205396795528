import {
  TOGGLE_FILTER_WINDOW,
  SET_FILTER_PARAMS,
  RESET_FILTER_PARAMS,
  FETCH_LANE,
  FETCH_LANE_FAILED,
  FETCH_CAMERA,
  FETCH_CAMERA_FAILED,
  FETCH_VEHICLE,
  FETCH_VEHICLE_FAILED,
  FETCH_ALERT,
  FETCH_ALERT_FAILED,
  CLOSE_WINDOW,
} from "./constants";
import FilterService from "service/FilterService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";
import ReportService from "../../../service/ReportService";
export const toggleFilterWindow = () => {
  return (dispatch) => {
    dispatch(toggleFilterWindow());
  };
  function toggleFilterWindow() {
    return { type: TOGGLE_FILTER_WINDOW };
  }
};
export const setValue = () => {
  return (dispatch) => {
    dispatch(setValue());
  };
  function setValue() {
    return { type: CLOSE_WINDOW };
  }
};

export const setFilterParams = (filterParams) => {
  return (dispatch) => {
    dispatch(setFilterParams(filterParams));
  };
  function setFilterParams(filterParams) {
    return { type: SET_FILTER_PARAMS, filterParams };
  }
};

export const resetFilterParams = () => {
  return (dispatch) => {
    dispatch(resetFilterParams());
  };
  function resetFilterParams() {
    return { type: RESET_FILTER_PARAMS };
  }
};

export const fetchLane = (type) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new FilterService().fetchLane(type).then((response) => {
      if (response.data) {
        // console.log("dataaa", response.data);
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_LANE, data };
  }

  function failure(error) {
    return { type: FETCH_LANE_FAILED, error };
  }
};

export const fetchCamera = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new FilterService().fetchCamera().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_CAMERA, data };
  }

  function failure(error) {
    return { type: FETCH_CAMERA_FAILED, error };
  }
};

export const fetchVehicle = (type) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new FilterService().fetchVehicle(type).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_VEHICLE, data };
  }

  function failure(error) {
    return { type: FETCH_VEHICLE_FAILED, error };
  }
};
export const fetchAlert = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new ReportService().fetchAlert().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_ALERT, data };
  }

  function failure(error) {
    return { type: FETCH_ALERT_FAILED, error };
  }
};
