import React from "react";
import {
  Paper,
  withStyles,
  Grid,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import DashboardModal from "../components/dashboard_modal/DashboardModal";

const styles = (theme) => ({
  cardGrid: {
    padding: "6px",
    marginBottom: "10px",
    paddingRight: "21px",
  },
  openCardGrid: {
    padding: "6px",
    marginBottom: "10px",
    paddingRight: "12px",
  },
  card: {
    color: theme.dashboardStyle.cardColor,
    // width: "290px",
    // height: "136px",
    fontSize: ".875rem",
    borderRadius: theme.border.radius,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
  },
  openCard: {
    color: theme.dashboardStyle.cardColor,
    // width: "247px",
    // height: "136px",
    fontSize: ".875rem",
    borderRadius: theme.border.radius,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
  },
  cardHeaderContainer: {
    textAlign: "right",
    paddingTop: "23px",
    // margin: "0 21px", aas per design give, due to resposive issue changed
    margin: "0 18px",
  },
  cardIconContainer: {
    // padding: "10px",
    paddingTop: "0px",
    paddingLeft: "0px",
    borderRadius: "50%",
    height: "52px",
    width: "52px",
    float: "left",
  },

  cardTitle: {
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    textAlign: "right",
    marginBottom: "-5px",
    marginTop: "-5px",
    whiteSpace: "nowrap",
    overFlow: "hidden",
    display: "block",
    textOverFlow: "ellipsis",
  },
  cardTitleNumeric: {
    fontSize: theme.font.size5,
    fontWeight: theme.font.bold,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    textAlign: "right",
    marginTop: "0px",
    whiteSpace: "nowrap",
    overFlow: "hidden",
    display: "block",
    textOverFlow: "ellipsis",
  },
  cardFooter: {
    margin: "0px 10px 10px",
    justifyContent: "space-between",
    padding: "5px",
    paddingBottom: "10px",
    display: "flex",
  },
  footerIcon: {
    padding: 3,
  },
  footerText: {
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 0.5,
  },
});
class OverviewCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  toggleFilterWindow = () => {
    this.setState({
      open: this.state.open ? false : true,
    });
  };
  render() {
    const { classes } = this.props;

    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={3}
        xl={3}
        className={
          this.props.showDrawer ? classes.openCardGrid : classes.cardGrid
        }
      >
        <Paper
          className={this.props.showDrawer ? classes.openCard : classes.card}
          elevation={0}
        >
          <div className={classes.cardHeaderContainer}>
            <Paper elevation={0} className={classes.cardIconContainer}>
              {this.props.cardIcon}
            </Paper>
            <Typography
              variant="subtitle1"
              gutterBottom
              color="textSecondary"
              classes={{
                root: classes.cardTitle,
              }}
            >
              {this.props.cardTitle}
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              color="primary"
              classes={{
                root: classes.cardTitleNumeric,
              }}
            >
              {this.props.cardTitleNumeric}
            </Typography>
            <Divider />
          </div>
          <div className={classes.cardFooter}>
            <IconButton
              onClick={this.toggleFilterWindow}
              className={classes.footerIcon}
            >
              {this.props.footerIcon}
            </IconButton>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.footerText}
            >
              {this.props.footerText}
            </Typography>
          </div>
        </Paper>
        {/* <DashboardModal
          open={this.state.open}
          toggleFilterWindow={this.toggleFilterWindow}
        /> */}
      </Grid>
    );
  }
}

export default withStyles(styles)(OverviewCard);
