class Storagehandler {
  setUser = (response) => {
    localStorage.setItem("user", JSON.stringify(response));
  };

  getUser = () => {
    return localStorage["user"]
      ? JSON.parse(localStorage.getItem("user"))
      : null;
  };

  isAuthenticated = () => {
    return this.getUser() === null ? false : true;
  };

  removeUser = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("attribute_permissions");
    localStorage.clear();
  };

  getToken = () => {
    return this.getUser() === null ? null : this.getUser().accessToken;
  };

  getUserCameraPermissions = () => {
    return this.getUser() === null
      ? null
      : this.getUser().basicDetails.camera
      ? this.getUser().basicDetails.camera
      : "";
  };

  getUserType = () => {
    return this.getUser() === null
      ? null
      : this.getUser().basicDetails.role
      ? this.getUser().basicDetails.role.userType
      : "";
  };

  getUserPermissions = () => {
    return this.getUser() === null
      ? null
      : this.getUser().basicDetails.role
      ? this.getUser().basicDetails.role.permissions
      : "";
  };

  setAttributPermissions = (permissions) => {
    localStorage.setItem("attribute_permissions", JSON.stringify(permissions));
  };
  getAttributPermissions = () => {
    return localStorage["attribute_permissions"]
      ? JSON.parse(localStorage.getItem("attribute_permissions"))
      : null;
  };

  // setLocationBasedCameraList = (cameraList) => {
  //   localStorage.setItem("locationBasedCameraList", JSON.stringify(cameraList));
  //   console.log("cam.....", cameraList);
  // };
  // getLocationBasedCameraList = () => {
  //   return localStorage["locationBasedCameraList"]
  //     ? JSON.parse(localStorage.getItem("locationBasedCameraList"))
  //     : null;
  // };
  setHistoryFilterParams = (value) => {
    localStorage.setItem("history", value);
  };
  getHistoryFilterParams = () => {
    return localStorage["history"] ? localStorage.getItem("history") : null;
  };
  setWarningFilterParams = (value) => {
    localStorage.setItem("warning", value);
  };
  getWarningFilterParams = () => {
    return localStorage["warning"] ? localStorage.getItem("warning") : null;
  };
  setAlertFilterParams = (value) => {
    localStorage.setItem("alert", value);
  };
  getAlertFilterParams = () => {
    return localStorage["alert"] ? localStorage.getItem("alert") : null;
  };
  setLowAlertFilterParams = (value) => {
    localStorage.setItem("noewaybillwarning", value);
  };
  getLowAlertFilterParams = () => {
    return localStorage["noewaybillwarning"]
      ? localStorage.getItem("noewaybillwarning")
      : null;
  };
}

export default Storagehandler;
