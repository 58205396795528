import {
  RESET_REPORT_FILTER_PARAMS,
  SET_REPORT_FILTER_PARAMS,
} from "./constants";

const initialState = {
  reportFilterParams: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_REPORT_FILTER_PARAMS:
      return {
        ...state,
        reportFilterParams: action.params,
      };
    case RESET_REPORT_FILTER_PARAMS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
