/** @format */

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.border.radius,
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    paddingBottom: "24px",
  },
  fullscrnroot: {
    top: 0,
    left: 0,
    position: "fixed",
    zIndex: 9999,
    height: "100%",
    width: "100%",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    paddingBottom: "24px",
  },
  grid: {
    marginTop: "21px",
    paddingTop: "22px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
  },
  roots: {
    borderRadius: theme.border.radius,
    padding: "11px",
  },
  topList: {
    paddingTop: "24px",
    paddingRight: "32.5px",
  },
  textDiv: {
    marginLeft: "20px",
    color: "2B3D51",
    marginTop: "-14px",
    font: "normal normal normal 14px Karla",
  },
  greenDiv: {
    backgroundColor: "#28AC47",
    borderRadius: "2px",
    width: "13px",
    height: "13px",
    position: "relative",
    marginTop: "2px",
  },
  orangeDiv: {
    background: "#FF7B9C 0% 0% no-repeat padding-box",
    borderRadius: "2px",
    width: "13px",
    height: "13px",
    position: "relative",
    marginTop: "2px",
  },
  redDiv: {
    background: "#FF9600 0% 0% no-repeat padding-box",
    borderRadius: "2px",
    width: "13px",
    height: "13px",
    position: "relative",
    marginTop: "2px",
  },
  blueDiv: {
    background: "#00BBFF 0% 0% no-repeat padding-box",
    borderRadius: "2px",
    width: "13px",
    height: "13px",
    position: "relative",
    marginTop: "2px",
  },
  greyDiv: {
    background: "#3E56DE 0% 0% no-repeat padding-box",
    borderRadius: "2px",
    width: "13px",
    height: "13px",
    position: "relative",
    marginTop: "2px",
  },
  tableTitle: {
    color: theme.colors.chartHeading,
    textAlign: "left",
    fontWeight: "bold",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    opacity: 1,
    marginLeft: "23px",
    backgroundColor: "white",
  },
  btnAlignCenter: {
    textAlign: "center",
  },
  btnAlignLeft: {
    textAlign: "left",
  },
  btnAlignRight: {
    textAlign: "right",
  },

  table: {
    minWidth: "100%",
    // borderTop: "2px solid #F1F4FB",
  },
  tableWrapper: {
    height: "280px",
    overflowX: "auto",
    paddingLeft: "33px",
    paddingRight: "31px",
    paddingTop: "0px",
    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      height: "280px",
    },
    /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      height: "34vh",
    },
    /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      height: "280px",
    },
    /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      height: "280px",
    },
    /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      height: "37vh",
    },
  },
  tableWrapperFullScrn: {
    height: "80vh",
    overflowX: "auto",
    paddingLeft: "33px",
    paddingRight: "31px",
    paddingTop: "0px",
    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      height: "80vh",
    },
    /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      height: "86vh",
    },
    /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      height: "82vh",
    },
    /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      height: "82vh",
    },
    /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      height: "86vh",
    },
  },

  tableHead: {},
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "21px",
    paddingBottom: "21px",
    paddingLeft: "0px",
    height: "41px",
  },
  selectedText: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.whiteColor,
    opacity: 1,
    border: "none",
    paddingTop: "21px",
    paddingBottom: "21px",
    paddingLeft: "0px",
    height: "41px",
  },
  zebrafont: {
    "&:hover": {
      backgroundColor: "#1BB99A !important",
      cursor: "pointer",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
  textFielddes: {
    marginLeft: "10px",
    marginRight: "13px",
  },
  stepperRoot: {
    paddingBottom: "22px",
    paddingTop: "16px",
    paddingLeft: "197px",
    paddingRight: "202px",
  },
  textField: {
    // width: "95%",
    font: "normal normal normal 14px/30px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "50px",
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  instructions: {
    height: "291px",
    overflow: "auto",
  },
  Headroot: {
    paddingRight: theme.spacing.unit,
    backgroundColor: theme.toolbar.backgroundColor,
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },

  innerGrid: {
    paddingBottom: "6px",
    paddingRight: "4px",
  },
  innerGridEdit: {
    paddingBottom: "16px",
    paddingRight: "4px",
  },
  outerGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "15px",
  },
  button: {
    width: "121px",
    marginBottom: "3px",
    fontWeight: theme.font.fontWeight,
    backgroundColor: "#1BB99A",
    color: theme.colors.whiteColor,
    textTransform: "none",
    font: "normal normal normal 14px/30px Karla",
  },

  closeButton: {
    width: "121px",
    marginBottom: "3px",
    fontWeight: theme.font.fontWeight,
    backgroundColor: "#E7EBF4",
    color: "#797C82",
    textTransform: "none",
    font: "normal normal normal 14px/30px Karla",
  },
  clearButton: {
    width: "100%",
    marginBottom: "3px",
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.BlueButton,
    color: theme.colors.whiteColor,
    textTransform: "none",
    font: "normal normal normal 14px/30px Karla",
  },
  backButton: {
    width: "100%",
    marginBottom: "3px",
    marginLeft: "10%",
    fontWeight: theme.font.fontWeight,
    backgroundColor: "#E7EBF4",
    color: "#797C82",
    font: "normal normal normal 14px/30px Karla",
  },

  title: {
    flex: "0 0 auto",
  },
  filterIcon: {
    color: theme.toolbar.iconColor,
  },
  listTop: {
    paddingTop: "20px",
  },
  fabBtn: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 30,
    left: "auto",
    position: "fixed",
  },
  btns: {
    paddingTop: "131px",
    paddingRight: "22%",
    paddingBottom: "2px",
  },
  featureList: {
    paddingTop: "3%",
    paddingLeft: "2%",
  },
  textMenu: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "6px 16px",
  },
  dropIcon: {
    marginTop: "8px",
    marginRight: "8px",
  },
  notAddedStyle: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    marginLeft: 33,
    marginRight: 33,
    marginTop: 33,
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 55,
    fontWeight: theme.font.fontWeight,
    fontFamily: theme.font.family,
  },
  greenPlate: {
    backgroundColor: "#28AC47",
  },
  orangePlate: {
    background: "#FF7B9C 0% 0% no-repeat padding-box",
  },
  redPlate: {
    background: "#FF9600 0% 0% no-repeat padding-box",
  },
  bluePlate: {
    background: "#00BBFF 0% 0% no-repeat padding-box",
  },
  greyPlate: {
    background: "#3E56DE 0% 0% no-repeat padding-box",
  },
});

export default styles;
