import { SHOW_PROGRESS, HIDE_PROGRESS } from "./constants";
const initialState = {
  loading: false
};
export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_PROGRESS:
      return {
        ...state,
        loading: true
      };
    case HIDE_PROGRESS:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
