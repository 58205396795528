import {
  FETCH_WARNING_LIST,
  FETCH_WARNING_LIST_ERROR,
  SET_ACTUALWARNING_PAGE,
  RESET_ACTUALWARNING_PAGE,
  RESET_ERROR_PAGE,
} from "./constants";

const initialState = {
  actualwarningList: [],
  recordsCount: 0,
  rowsPerPage: 10,
  page: 0,
  error: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_WARNING_LIST:
      return {
        ...state,
        actualwarningList: action.data.data,
        recordsCount: action.data.recordCount,
      };
    case FETCH_WARNING_LIST_ERROR:
      return {
        ...initialState,
        error: true,
      };

    case SET_ACTUALWARNING_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case RESET_ACTUALWARNING_PAGE:
      return {
        ...state,
        page: 0,
      };
    case RESET_ERROR_PAGE:
      return {
        ...state,
        error: false,
      };
    default:
      return state;
  }
}
