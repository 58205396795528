/** @format */

export const FETCH_SQUAD_SUCCESS = "FETCH_SQUARD_SUCCESS";
export const FETCH_SQUAD_FAILED = "FETCH_SQUARD_FAILED";
export const FETCH_DISTRICT_SUCCESS = "FETCH_DISTRICT_SUCCESS";
export const FETCH_DISTRICT_FAILED = "FETCH_DISTRICT_FAILED";
export const FETCH_SQUAD_DETAILS_SUCCESS = "FETCH_SQUAD_DETAILS_SUCCESS";
export const FETCH_SQUAD_DETAILS_FAILED = "FETCH_SQUAD_DETAILS_FAILED";
export const ASSIGN_DUTY = "ASSIGN_DUTY";
export const ASSIGN_DUTY_FAILED = "ASSIGN_DUTY_FAILED";
export const FETCH_DUTY_SUCCESS = "FETCH_DUTY_SUCCESS";
export const FETCH_DUTY_FAILED = "FETCH_DUTY_FAILED";
export const EDIT_DUTIES = "EDIT_DUTIES";
export const EDIT_DUTIES_FAILED = "EDIT_DUTIES_FAILED";
export const RESET_DUTIES = "RESET_DUTIES";
export const FILTER_CHANGE = "FILTER_CHANGE";
export const FILTER_CHANGE_ERROR = "FILTER_CHANGE_ERROR";
export const RESET_SQUAD_FIELD = "RESET_SQUAD_FIELD";

// export const FETCH_PEN_SUCCESS = "FETCH_PEN_SUCCESS";
// export const FETCH_PEN_FAILED = "FETCH_PEN_FAILED";
// export const FETCH_PEN_PEN_BASED_DETAILS_SUCCESS =
//   "FETCH_PEN_PEN_BASED_DETAILS_SUCCESS";
// export const FETCH_PEN_PEN_BASED_DETAILS_FAILED =
//   "FETCH_PEN_PEN_BASED_DETAILS_FAILED";
// export const ASSIGN_DUTY = "ASSIGN_DUTY";
// export const ASSIGN_DUTY_FAILED = "ASSIGN_DUTY_FAILED";
// export const FETCH_DUTY_SUCCESS = "FETCH_DUTY_SUCCESS";
// export const FETCH_DUTY_FAILED = "FETCH_DUTY_FAILED";
// export const EDIT_DUTIES = "EDIT_DUTIES";
// export const EDIT_DUTIES_FAILED = "EDIT_DUTIES_FAILED";
// export const RESET_DUTIES = "RESET_DUTIES";
