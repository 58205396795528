import logger from "redux-logger";
import thunk from "redux-thunk";
// import { createBrowserHistory } from "history";
import { createHashHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./rootReducer";

export const history = createHashHistory();
const middlewares = [thunk, routerMiddleware(history)];
const initialState = {};

// if (process.env.NODE_ENV === `development`) {
//   middlewares.push(logger);
// }

const store = createStore(
  createRootReducer(history),
  initialState,
  compose(applyMiddleware(...middlewares))
);
export default store;
