import React, { Fragment } from "react";
import * as d3 from "d3";
import { axisBottom, axisLeft } from "d3-axis";
import { Paper } from "@material-ui/core";
import Tooltip from "./Tooltip";
//import "./styles.css";
import { line as d3Line, curveBasis } from "d3-shape";

const margin = { top: 30, right: 5, bottom: 20, left: 80 };
class LineGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: null,
      currentData: null,
      height: 300,
      width: 1200,
      event: null,
      data: [
        {
          type: "No-eway bill",
          value: 17,
        },
        {
          type: "Multi-eway bill",
          value: 10,
        },
        {
          type: "Duplicate Transportation",
          value: 30,
        },
        {
          type: "Taxpayer watchlist",
          value: 55,
        },
        {
          type: "Vehicle  watchlist",
          value: 50,
        },
        {
          type: "Transporter  watchlist",
          value: 31,
        },
        {
          type: "Evasion Prone Commodity",
          value: 50,
        },
      ],
    };
    this.yAxisRef = React.createRef();
    this.xAxisRef = React.createRef();
    this.gridRef = React.createRef();

    this.xScale = d3
      .scaleBand()
      .range([margin.left, this.state.width - margin.right])
      .domain(this.state.data.map((a) => a.type))
      .padding(1);

    this.yScale = d3
      .scaleLinear()
      .range([this.state.height - margin.bottom, margin.top])
      .domain([0, d3.max(this.state.data.map((a) => a.value))]);

    this.xAxis = axisBottom(this.xScale);
    this.yAxis = axisLeft(this.yScale);
    this.grid = d3
      .axisLeft()
      .scale(this.yScale)
      .tickSize(-this.state.width + margin.left, 0, 0)
      .tickFormat("");

    this.line = d3
      .line()
      .defined((d) => {
        return d !== null;
      })
      .x((d) => this.xScale(d.type))
      .y((d) => this.yScale(d.value))
      .curve(d3.curveMonotoneX);
  }

  componentDidMount() {
    d3.select(this.xAxisRef.current).call(this.xAxis);
    d3.select(this.yAxisRef.current).call(this.yAxis);
    d3.select(this.gridRef.current).call(this.grid);
  }
  componentDidUpdate(prevProps) {}
  highlightBar = (event, currentIndex, currentData) => {
    if (event) {
      event.persist();
      this.setState({
        event,
      });
      d3.select(event.target)
        .transition()
        .duration(300)
        .attr("x", this.xScale(currentData.type) - 5)
        .attr("width", this.xScale.bandwidth() + 10);
    }
    this.setState({
      currentIndex,
      currentData,
    });
  };

  onMouseOut = (e) => {
    d3.select(e.target)
      .transition()
      .duration(300)
      .attr("x", this.xScale(this.state.currentData.type))
      .attr("width", this.xScale.bandwidth());
    this.highlightBar(null);
  };
  render() {
    const { height, width, data } = this.state;

    return (
      <Paper style={{ overflow: "auto" }}>
        <svg
          width="100%"
          height="100%"
          preserveAspectRatio="xMinYMin"
          viewBox={"0 0 " + width + " " + 350}
          style={{ display: "block", margin: "auto", padding: "20px" }}
        >
          <g
            ref={this.xAxisRef}
            transform={`translate(0, ${height - margin.bottom})`}
          />
          <g ref={this.yAxisRef} transform={`translate(${margin.left}, 0)`} />
          {/* <g
            ref={this.gridRef}
            className="grid"
            transform={`translate(${margin.left}, 0)`}
          /> */}
          <g>
            <path
              d={this.line(data)}
              style={{
                stroke: "#2260A0",
                fill: "none",
                strokeLinejoin: "round",
                strokeWidth: "2px",
              }}
            />
          </g>
          {data.map((item, i) => {
            return (
              <circle
                cx={this.xScale(item.type)}
                cy={this.yScale(item.value)}
                r="4"
                fill="#2260A0"
                stroke="#2260A0"
                strokeWidth="2px"
                onMouseOver={(e) => this.highlightBar(e, i, item)}
                onMouseOut={(e) => this.onMouseOut(e)}
                //onMouseMove={(e) => this.mousemove(e)}
              ></circle>
            );
          })}

          <text x={width / 2} y={margin.top - 10}>
            Alert Type and Count
          </text>
          <text x={width / 2} y={height + margin.bottom}>
            Alert Types
          </text>
          <text
            x={margin.left}
            y={height / 2 - margin.left / 2}
            transform={`rotate(-90,${margin.left},${height / 2})`}
          >
            Count
          </text>
          {this.state.currentData ? (
            <line
              x1={this.xScale(this.state.currentData.type)}
              y1={this.yScale(this.state.currentData.value)}
              x2={this.xScale(this.state.currentData.type)}
              y2={height - margin.bottom}
              stroke="#212B35"
              strokeWidth={3}
              strokeDasharray="3 6"
            />
          ) : null}
          {this.state.currentData ? (
            <line
              x1={margin.left}
              y1={this.yScale(this.state.currentData.value)}
              x2={this.xScale(this.state.currentData.type)}
              y2={this.yScale(this.state.currentData.value)}
              stroke="#212B35"
              strokeWidth={3}
              strokeDasharray="3 6"
            />
          ) : null}
        </svg>
        {this.state.currentData ? (
          <Tooltip
            currentData={this.state.currentData}
            event={this.state.event}
          />
        ) : null}
      </Paper>
    );
  }
}

export default LineGraph;
