import {
  FETCH_OFFICE_LIST,
  DELETE_OFFICE,
  RESET_DELETE,
  FETCH_OFFICE_LIST_ERROR,
  DELETE_OFFICE_ERROR,
  FETCH_ZONE,
  FETCH_ZONE_FAILED,
  FETCH_DISTRICT,
  FETCH_DISTRICT_FAILED,
  DIALOGUE_WINDOW_STATUS,
  FILTER_CHANGE,
  FILTER_CHANGE_ERROR,
} from "./constants";
import OfficeMasterService from "../../../service/OfficeMasterService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchOfficeList = (page, limit) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new OfficeMasterService().fetchOfficeList(page, limit).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };
  function success(data) {
    return { type: FETCH_OFFICE_LIST, data };
  }
  function failure(error) {
    return { type: FETCH_OFFICE_LIST_ERROR, error };
  }
};
export const fetchZone = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new OfficeMasterService().fetchZone().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_ZONE, data };
  }

  function failure(error) {
    return { type: FETCH_ZONE_FAILED, error };
  }
};
export const fetchDistricts = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new OfficeMasterService().fetchDistricts(id).then((response) => {
      // console.log("dist offce action", response.data);
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_DISTRICT, data };
  }

  function failure(error) {
    return { type: FETCH_DISTRICT_FAILED, error };
  }
};

export const dialogueWindowStatus = () => {
  return (dispatch) => {
    dispatch(dialogueWindowStatus());
  };
  function dialogueWindowStatus() {
    return { type: DIALOGUE_WINDOW_STATUS };
  }
};
export const onFilterChange = (params) => {
  let officeMasterService = new OfficeMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    officeMasterService.onFilterChange(params).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FILTER_CHANGE, data };
  }
  function failure(error) {
    return { type: FILTER_CHANGE_ERROR, error };
  }
};

export const deleteOffice = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new OfficeMasterService().deleteOffice(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: DELETE_OFFICE, data };
  }
  function failure(error) {
    return { type: DELETE_OFFICE_ERROR, error };
  }
};

export function resetDelete() {
  return {
    type: RESET_DELETE,
  };
}
