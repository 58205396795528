import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "connected-react-router";
import { getVehicleList } from "./data/actions";
import { setSelectedRecord } from "../vehicle_info/data/actions";
import { setPlateNumber } from "../recent_activity/data/action";
import {
  fetchVehicle,
  fetchAlert,
} from "../../../../components/filterhistory_form/data/action";
import VehicleList from "./VehicleList";

function mapStateToProps(state) {
  return {
    vehicleList: state.vehicleList.vehicleList,
    messageData: state.overview.messageData,
    locationId: state.locationSelector.filterParams,
    fetchVehicles: state.filterhistory.fetchVehicles,
    fetchedAlert: state.filterhistory.fetchedAlert,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => dispatch(push(url)),
    getVehicleList: (data) => dispatch(getVehicleList(data)),
    setSelectedRecord: (record) => dispatch(setSelectedRecord(record)),
    setPlateNumber: (plate) => dispatch(setPlateNumber(plate)),
    fetchVehicle: (type) => dispatch(fetchVehicle(type)),
    fetchAlert: () => dispatch(fetchAlert()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VehicleList)
);
