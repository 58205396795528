import {
  FETCH_FLAMMABLE_GOODS_LIST,
  DELETE_FLAMMABLE_GOOD,
  RESET_DELETE,
  FETCH_FLAMMABLE_GOODS_LIST_ERROR,
  DELETE_FLAMMABLE_GOOD_ERROR
} from "./constants";
import FlammableGoodsService from "../../../service/FlammableGoodsService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchFlammableGoodsList = (page, limit, filterParams) => {
  return dispatch => {
    dispatch(progressActions.showProgress());
    new FlammableGoodsService()
      .fetchFlammableGoodsList(page, limit, filterParams)
      .then(response => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };
  function success(data) {
    return { type: FETCH_FLAMMABLE_GOODS_LIST, data };
  }
  function failure(error) {
    return { type: FETCH_FLAMMABLE_GOODS_LIST_ERROR, error };
  }
};

export const deleteflammableGoods = id => {
  return dispatch => {
    dispatch(progressActions.showProgress());
    new FlammableGoodsService().deleteFlammableGood(id).then(response => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: DELETE_FLAMMABLE_GOOD, data };
  }
  function failure(error) {
    return { type: DELETE_FLAMMABLE_GOOD_ERROR, error };
  }
};

export function resetDelete() {
  return {
    type: RESET_DELETE
  };
}
