export const FETCH_USER_LIST = "FETCH_USER_LIST";
export const DELETE_USER = "DELETE_USER";
export const RESET_DELETE = "RESET_DELETE";
export const FETCH_USER_LIST_ERROR = "FETCH_USER_LIST_ERROR ";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const FETCH_ROLE = "FETCH_ROLE";
export const FETCH_ROLE_FAILED = "FETCH_ROLE_FAILED";
export const DIALOGUE_WINDOW_STATUS = "DIALOGUE_WINDOW_STATUS";
export const FILTER_CHANGE = "FILTER_CHANGE";
export const FILTER_CHANGE_ERROR = "FILTER_CHANGE_ERROR";
