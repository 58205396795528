import React, { Component, Fragment } from "react";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./styles";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";
import Divider from "@material-ui/core/Divider";
import {
  Table,
  TableBody,
  TableHead,
  IconButton,
  TableRow,
  TableCell,
} from "@material-ui/core";

import { ReactComponent as BackIcon } from "../../../../../assets/SvgIcons/back.svg";
import { ReactComponent as DashIcon } from "../../../../../assets/Visual Assets/dashboard.svg";
import { ReactComponent as MastIcon } from "../../../../../assets/Visual Assets/master_setup.svg";
import { ReactComponent as LiveIcon } from "../../../../../assets/Visual Assets/live_data.svg";
import { ReactComponent as AlertIcon } from "../../../../../assets/Visual Assets/alerts.svg";
import { ReactComponent as WarnIcon } from "../../../../../assets/Visual Assets/warnings.svg";
import { ReactComponent as HistIcon } from "../../../../../assets/Visual Assets/history.svg";
import { ReactComponent as RepoIcon } from "../../../../../assets/Visual Assets/eports.svg";
import { ReactComponent as ReviewIcon } from "../../../../../assets/SvgIcons/survey.svg";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    marginTop: "43px",
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "17px",
    marginBottom: "15px",
  },
  iconButton: {
    paddingLeft: "0px",
  },
});
let EnhancedTableToolbar = (props) => {
  const { classes } = props;
  return (
    <>
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          <IconButton
            onClick={(e) => props.onBackButtonClick(e)}
            classes={{ root: classes.iconButton }}
          >
            <BackIcon />
          </IconButton>
          {props.header}
        </Typography>
      </Toolbar>
      <Divider />
    </>
  );
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);
const headerStyles = (theme) => ({
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "12px 14px",
    borderColor: "#E7EBF4",
  },
});

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.render.props.onRequestSort(event, property);
  };
  render() {
    const { classes } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell key={0} className={classes.tableHead}>
            Features
          </TableCell>
          <TableCell key={1} className={classes.tableHead}>
            Add
          </TableCell>
          <TableCell key={2} className={classes.tableHead}>
            Edit
          </TableCell>
          <TableCell key={3} className={classes.tableHead}>
            Delete
          </TableCell>
          <TableCell key={4} className={classes.tableHead}>
            View
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);
class viewPermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      baseData: "",
    };
  }

  componentDidMount() {
    if (this.props.match.params.role_id) {
      this.props.fetchPermissionList(this.props.match.params.role_id);
    }
    // console.log("test per ddkdsd:");
  }
  onCancel = () => {
    this.props.toggleViewWindow();
  };
  onBackButtonClick = () => {
    this.props.navigateTo("/role-master");
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.permissionList.permissionList !=
      this.props.permissionList.permissionList
    ) {
      let temp = this.props.permissionList.permissionList;
      // console.log("test per:", temp);
      this.setState({ data: temp.permissions, baseData: temp }, () => {
        // console.log("role name:", this.state.baseData.userType.user_type);
      });
    }
  }
  render() {
    const { classes } = this.props;
    const printData = (data, i) => (
      <>
        <TableCell component="th" scope="row" className={classes.tableCell}>
          {data.title === "Dashboard" ? (
            <DashIcon />
          ) : data.title === "Master Setup" ? (
            <MastIcon />
          ) : data.title === "Live Data" ? (
            <LiveIcon />
          ) : data.title === "Alerts" ? (
            <AlertIcon />
          ) : data.title === "Warnings" ? (
            <WarnIcon />
          ) : data.title === "Reviews" ? (
            <ReviewIcon />
          ) : data.title === "History" ? (
            <HistIcon />
          ) : data.title === "Reports" ? (
            <RepoIcon />
          ) : null}
          &nbsp; &nbsp; &nbsp;
          {data.title}
        </TableCell>

        <TableCell className={classes.tableCell}>
          {data.permissions.add_status == true ? (
            <CheckIcon color={"secondary"} />
          ) : (
            <CloseIcon color={"error"} />
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {data.permissions.edit_status == true ? (
            <CheckIcon color={"secondary"} />
          ) : (
            <CloseIcon color={"error"} />
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {data.permissions.delete_status == true ? (
            <CheckIcon color={"secondary"} />
          ) : (
            <CloseIcon color={"error"} />
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {data.permissions.view_status == true ? (
            <CheckIcon color={"secondary"} />
          ) : (
            <CloseIcon color={"error"} />
          )}
        </TableCell>
      </>
    );
    const printSubMenu = (data, i, j) => (
      <>
        <TableCell
          style={{ paddingLeft: "82px" }}
          className={classes.tableCell}
        >
          {data.title}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {data.permissions.add_status == true ? (
            <CheckIcon color={"secondary"} />
          ) : (
            <CloseIcon color={"error"} />
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {data.permissions.edit_status == true ? (
            <CheckIcon color={"secondary"} />
          ) : (
            <CloseIcon color={"error"} />
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {data.permissions.delete_status == true ? (
            <CheckIcon color={"secondary"} />
          ) : (
            <CloseIcon color={"error"} />
          )}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {data.permissions.view_status == true ? (
            <CheckIcon color={"secondary"} />
          ) : (
            <CloseIcon color={"error"} />
          )}
        </TableCell>
      </>
    );
    return (
      <Grid className={classes.grid}>
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            onBackButtonClick={this.onBackButtonClick}
            header={"View Role Master"}
          />

          <div className={classes.textFielddes}>
            <Fragment>
              <span className={classes.subTitleHead}>
                Role Name:&nbsp;&nbsp;
                <Typography variant="h6" className={classes.subTitleContent}>
                  {this.state.baseData && this.state.baseData.role_name
                    ? this.state.baseData.role_name
                    : null}
                </Typography>
                <span style={{ paddingLeft: "23.5px" }} />
                |
                <span style={{ paddingLeft: "23.5px" }} />
                User Type:&nbsp;&nbsp;
                <Typography variant="h6" className={classes.subTitleContent}>
                  {this.state.baseData && this.state.baseData.userType
                    ? this.state.baseData.userType.user_type
                    : null}
                </Typography>
              </span>
              <div style={{ paddingBottom: "37px" }}>
                <div className={classes.instructions}>
                  <div className={classes.tableWrapper}>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      align="center"
                    >
                      <EnhancedTableHead rowCount={this.state.rowCount} />
                      <TableBody>
                        {this.state.data !== ""
                          ? this.state.data.map((data, i) => (
                              <>
                                {data.sub_menus.length === 0 ? (
                                  <TableRow
                                    key={i}
                                    className={classes.zebrafont}
                                  >
                                    {printData(data, i)}
                                  </TableRow>
                                ) : (
                                  <>
                                    <TableRow
                                      key={i}
                                      className={classes.zebrafont}
                                    >
                                      {printData(data, i)}
                                    </TableRow>

                                    {data.sub_menus.map((subData, j) =>
                                      subData.title === "Habitual Offender" ||
                                      subData.title === "Discrepancy" ? null : (
                                        <TableRow
                                          key={i}
                                          className={classes.zebrafont}
                                        >
                                          {printSubMenu(
                                            subData,
                                            j,
                                            i,
                                            data.title
                                          )}
                                        </TableRow>
                                      )
                                    )}
                                  </>
                                )}
                              </>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
            </Fragment>
          </div>
        </Paper>
      </Grid>
    );
  }
}

export default withStyles(styles)(viewPermissions);
