import {
  TOGGLE_FILTER_WINDOW,
  SET_FILTER_PARAMS,
  RESET_FILTER_PARAMS,
  FETCH_USER_NAME,
  FETCH_USER_NAME_FAILED,
  FETCH_STATUS,
  FETCH_STATUS_FAILED,
  FETCH_ROLE,
  FETCH_ROLE_FAILED,
  CLOSE_WINDOW,
} from "./constants";

const initialState = {
  showFilterWindow: false,
  filterParams: {},
  fetchUsers: [],
  fetchRoles: [],
  fetchStatus: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FILTER_WINDOW:
      return {
        ...state,
        showFilterWindow: !state.showFilterWindow,
      };
    case CLOSE_WINDOW:
      return {
        ...state,
        val: !state.val,
      };

    case SET_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.filterParams,
      };
    case RESET_FILTER_PARAMS:
      return {
        ...state,
        filterParams: {},
      };
    case FETCH_USER_NAME:
      return {
        ...state,
        fetchUsers: action.data.data,
      };
    case FETCH_USER_NAME_FAILED:
      return {
        ...initialState,
      };
    case FETCH_ROLE:
      return {
        ...state,
        fetchRoles: action.data.data,
      };
    case FETCH_ROLE_FAILED:
      return {
        ...initialState,
      };
    case FETCH_STATUS:
      return {
        ...state,
        fetchStatus: action.data.data,
      };
    case FETCH_STATUS_FAILED:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
