export const FETCH_USERTYPE = "FETCH_USERTYPE";
export const FETCH_USERTYPE_FAILED = "FETCH_USERTYPE_FAILED";

export const FETCH_FIELD = "FETCH_FIELD";
export const FETCH_FIELD_FAILED = "FETCH_FIELD_FAILED";

export const FETCH_ZONE = "FETCH_ZONE";
export const FETCH_ZONE_FAILED = "FETCH_ZONE_FAILED";

export const FETCH_DISTRICT = "FETCH_DISTRICT";
export const FETCH_DISTRICT_FAILED = "FETCH_DISTRICT_FAILED";

export const FETCH_ROLE = "FETCH_ROLE";
export const FETCH_ROLE_FAILED = "FETCH_ROLE_FAILED";

export const ADD_EDIT_USER_MASTERS = "ADD_EDIT_USER_MASTERS";
export const ADD_EDIT_USER_MASTERS_FAILED = "ADD_EDIT_USER_MASTERS_FAILED";

export const RESET_USER_MASTER_STATE = "RESET_USER_MASTER_STATE";

export const FETCH_CAMERA = "FETCH_CAMERA";
export const FETCH_CAMERA_FAILED = "FETCH_CAMERA_FAILED";

export const FETCH_OFFICE_LIST = "FETCH_OFFICE_LIST";
export const FETCH_OFFICE_LIST_FAILED = "FETCH_OFFICE_LIST_FAILED";

export const FETCH_DESIGNATION_LIST = "FETCH_DESIGNATION_LIST";
export const FETCH_DESIGNATION_LIST_FAILED = "FETCH_DESIGNATION_LIST_FAILED";

export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
export const FETCH_USER_DETAILS_FAILED = "FETCH_USER_DETAILS_FAILED";

export const FETCH_EDIT_CAMERA = "FETCH_EDIT_CAMERA";
export const FETCH_EDIT_CAMERA_FAILED = "FETCH_EDIT_CAMERA_FAILED";

export const CHECK_USER_NAME = " CHECK_USER_NAME";
export const CHECK_USER_NAME_FAILED = " CHECK_USER_NAME_FAILED";
