export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const GET_CAPTCHA_SUCCESS = "GET_CAPTCHA_SUCCESS";
export const GET_CAPTCHA_FAILURE = "GET_CAPTCHA_FAILURE";
export const VERIFY_CAPTCHA = "VERIFY_CAPTCHA";
export const AUTH_STATUS = "AUTH_STATUS";
export const USER_INPUT = "USER_INPUT";
export const RESET_USER_INPUT = "RESET_USER_INPUT";
