import {
  FETCH_HABITUAL_OFFENDER_LIST,
  FETCH_HABITUAL_OFFENDER_LIST_ERROR,
  DELETE_HABITUAL_OFFENDER,
  DELETE_HABITUAL_OFFENDER_ERROR,
  RESET_DELETE,
} from "./constants";

const initialState = {
  habitualOffendersLists: [],
  habitualOffendersCount: 0,
  deleteSuccess: false,
  deleteSuccessMessage: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_HABITUAL_OFFENDER_LIST:
      return {
        ...state,
        habitualOffendersLists: action.data.data.rows,
        habitualOffendersCount: action.data.data.count,
      };
    case FETCH_HABITUAL_OFFENDER_LIST_ERROR:
      sessionStorage.setItem("manualMasterError", true);
      return {
        ...initialState,
      };
    case DELETE_HABITUAL_OFFENDER:
      return {
        ...initialState,
        deleteSuccessMessage: action.data,
        error: action.data.message,
        deleteSuccess: true,
      };
    case DELETE_HABITUAL_OFFENDER_ERROR:
      return {
        ...initialState,
      };
    case RESET_DELETE:
      return {
        ...initialState,
        deleteSuccess: false,
      };

    default:
      return state;
  }
}
