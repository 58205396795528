import React, { Fragment } from "react";
import {
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Divider,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import propTypes from "prop-types";
import validator from "../../../../services/validator/validator/";
import { find } from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import Recaptcha from "react-google-invisible-recaptcha";
// import CaptchaSiteKeyMasters from "../../../../services/siteKeyMasters";
import { ReactComponent as BackIcon } from "../../../../assets/SvgIcons/back.svg";
import { ReactComponent as DownArrow } from "../../../../assets/SvgIcons/dropdown.svg";
import StyledRadio from "../../../../components/filteralert_form/styledRadio";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    marginTop: "43px",
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "17px",
    marginBottom: "15px",
  },
  iconButton: {
    paddingLeft: "0px",
  },
});

let EnhancedTableToolbar = (props) => {
  const { classes } = props;
  return (
    <>
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          <IconButton
            onClick={(e) => props.onBackButtonClick(e)}
            classes={{ root: classes.iconButton }}
          >
            <BackIcon />
          </IconButton>
          {props.header}
        </Typography>
      </Toolbar>
      <Divider />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: propTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  outerGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "15px",
    paddingBottom: "50px",
  },
  root: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    borderRadius: theme.border.radius,
    opacity: 1,
    marginLeft: "21px",
    marginRight: "17px",
    paddingLeft: "8px",
    paddingBottom: "32px",
    // height: "354px",
  },
  root1: {
    width: "100%",
  },

  textField: {
    width: "95%",
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "45px",
  },

  textFielddes: {
    paddingTop: "22px",
    marginLeft: "10px",
    marginRight: "13px",
  },
  innerGrid: {
    // paddingBottom: "16px",
    paddingBottom: "6px",
    paddingRight: "4px",
  },
  innerGridEdit: {
    paddingBottom: "16px",
    paddingRight: "4px",
  },

  innerGridEdit1: {
    paddingRight: "4px",
    marginTop: "-5px",
  },
  button: {
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    opacity: 1,
    textTransform: "none",
    // paddingLeft: "38px",
    // paddingRight: "37px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    height: "42px",
    width: "121px",
  },

  clearButton: {
    width: "121px",
    height: "42px",
    // marginRight: "10px",
    textTransform: "none",
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.colors.BlueButton,
    textAlign: "center",
    width: "121px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
  },
  btns: {
    paddingTop: "5px",
    paddingRight: "25%",
  },
  btnsEdit: {
    paddingRight: "25%",
    marginTop: "-3px",
  },
  btnGrid: {
    padding: 0,
    paddingLeft: "12px",
  },
  err: {
    paddingLeft: "1%",
  },
  dense: {
    marginTop: 16,
  },
  vldclr: {
    color: "#FF0000",
  },
  alignment: {
    flexDirection: "row",
  },
  typo: {
    textAlign: "left",
    font: "normal normal normal 14px/36px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingRight: "14px",
  },
  formLabel: {
    font: "normal normal normal 14px/36px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  textNoOptions: {
    letterSpacing: "0px",

    opacity: 1,
    fontSize: "18px",
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  paper: {
    height: "100px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  formHelperText: {
    color: theme.colors.redColor,
  },
  popIcon: {
    marginTop: "6px",
    marginRight: "6px",
  },
  popIconOpen: {
    marginTop: "4px",
    marginRight: "6px",
  },
});

class DesignationMasterDetails extends React.Component {
  constructor(props) {
    super(props);
    this.onResolved = this.onResolved.bind(this);

    this.state = {
      isVerified: false,

      designation: "",
      captcha: "",
      data: "",
      urlParam: false,
      errors: {
        designation: "",
      },
      rules: {
        designation: {
          required: true,
          minLength: 8,
          maxLength: 30,
          alphanumericwithspecialchar: true,
        },
      },
    };
  }
  componentDidMount() {
    if (this.props.match.params.designation_id) {
      this.props.fetchDesignationDetails(
        this.props.match.params.designation_id
      );
      this.setState({ urlParam: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.singleDesignation != this.props.singleDesignation) {
      this.setState({ designation: this.props.singleDesignation.designation });
    }
    if (this.props.saveSuccess) {
      if (this.props.match.params.designation_id) {
        this.props.showSnackbar("Designation Updated Successfully", "success");
      } else {
        this.props.showSnackbar("Designation Added Successfully", "success");
      }
      this.props.navigateTo("/designation-master");
    }
  }

  onBackButtonClick = () => {
    this.props.navigateTo("/designation-master");
  };

  handleChange = (event) => {
    this.setState({ designation: event.target.value });
  };

  onResolved = () => {
    // this.setState({
    //   captcha: this.recaptcha.getResponse(),
    // });
    // console.log (this.state.captcha);

    let params = {
      designation: this.state.designation,
      // captcha: this.state.captcha,
    };
    // console.log (params);

    if (this.props.match.params.designation_id) {
      this.props.editDesignationMaster(
        params,
        this.props.match.params.designation_id
      );
    } else {
      this.props.addDesignationMaster(params);
    }
  };
  performAction = (e) => {
    let params = {
      designation: this.state.designation,
    };
    if (this.validate(this.state.rules, params)) {
      // this.recaptcha.execute();
      this.onResolved();
    }
  };
  handleClear = () => {
    this.setState({
      ...this.state,

      designation: "",
      errors: {
        ...this.state.errors,
        designation: "",
      },
    });
  };

  validate = (rules, data) => {
    const errors = validator(rules)(data);
    const hasErrors = find(errors, (error) => error !== "");
    this.setState({ errors });
    return !hasErrors;
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root} elevation={0}>
        <EnhancedTableToolbar
          onBackButtonClick={this.onBackButtonClick}
          header={
            this.props.match.params.designation_id
              ? "Edit Designtation"
              : "Add Designation"
          }
        />
        <div className={classes.textFielddes}>
          <Fragment>
            <Grid container className={classes.outerGrid}>
              <Grid xs={4} item></Grid>
              <Grid xs={4} item>
                <FormControl error={true} className={classes.textField}>
                  <TextField
                    label="Enter The Designation"
                    name="designation"
                    id="designation"
                    value={this.state.designation}
                    invalid={this.state.errors.designation ? true : false}
                    onChange={(e) => this.handleChange(e)}
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ classes: { root: classes.textLabel } }}
                    InputProps={{ classes: { root: classes.textValue } }}
                  />
                  <FormHelperText>
                    {this.state.errors.designation}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid xs={4} item></Grid>
            </Grid>
            {/* <Grid
              container
              justify={"center"}
              style={{
                paddingTop: "2%",
                paddingBottom: "2%",
                paddingRight: "5%",
              }}
            >
              <Grid xs={12} item container justify={"center"} spacing={3}>
                <Grid xs={12} md={2} item>
                  <Button
                    className={classes.btn}
                    style={{ backgroundColor: "#01A8EB", color: "#FFF" }}
                    variant="contained"
                    // color="secondary"
                    onClick={() => this.onBackButtonClick()}
                    fullWidth={true}
                  >
                    CLOSE
                  </Button>
                </Grid>
                <Grid xs={12} md={2} item>
                  <Button
                    className={classes.btn}
                    variant="contained"
                    color="secondary"
                    onClick={() => this.performAction()}
                    fullWidth={true}
                  >
                    SUBMIT
                  </Button>
                  <Recaptcha
                    ref={ ref => this.recaptcha = ref }
                    sitekey={CaptchaSiteKeyMasters}
                    size="invisible"
                    onResolved={ this.onResolved } 
                    
                  />
                </Grid>
                
                
              </Grid>

            </Grid> */}
          </Fragment>
          <Grid
            xs={12}
            style={{
              paddingRight: "27%",
              paddingTop: "32px",
              // paddingBottom:"20px"
            }}
            container
          >
            <Grid item xs={12} md={6}></Grid>
            <Grid
              item
              xs={12}
              md={2}
              style={{
                textAlign: "right",
                //   "&.SquadDetails-timeGrid-271":{
                //     paddingBottom: "15px",
                // }
              }}
            >
              <Button
                disableElevation={true}
                variant="contained"
                className={classes.clearButton}
                onClick={() => this.handleClear()}
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={12} md={3} className={classes.btnGrid}>
              <Button
                disableElevation={true}
                elevation={0}
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => this.performAction()}
              >
                Submit
              </Button>
              {/* <Recaptcha
                ref={(ref) => (this.recaptcha = ref)}
                sitekey={CaptchaSiteKeyMasters}
                size="invisible"
                onResolved={this.onResolved}
              /> */}
            </Grid>
          </Grid>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(DesignationMasterDetails);
