import React, { Component } from "react";
import {
  Paper,
  Typography,
  Grid,
  withStyles,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import ReportTitle from "./components/ReportTitle";
import reportList from "./reportConfig";
import ReportFilter from "./components/report_filter/ReportFilter";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PropTypes from "prop-types";
import messageConstants from "../../services/validator/MsgConsts/messageConstants";
import "../userMaster/style.css";
import Pagination from "@material-ui/lab/Pagination";
import SkeltonScreen from "../../components/skelton_screen/reportSkelton";
// const actionsStyles = (theme) => ({
//   root: {
//     flexShrink: 0,
//     color: theme.palette.text.secondary,
//     marginLeft: theme.spacing.unit * 2.5,
//   },
// });

class TablePaginations extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginations.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

// const TablePaginationActionsWrapped = withStyles(actionsStyles, {
//   withTheme: true,
// })(TablePaginations);

const styles = (theme) => ({
  // container: {
  //   padding: theme.spacing.unit,
  //   // maxHeight: "48vh",
  //   minHeight: "48vh",
  //   overflow: "auto",
  // },
  container: {
    // padding: theme.spacing.unit,
    overflow: "auto",
    maxHeight: "43vh",
    // minHeight: "46vh",

    // maxWidth: "70vw",
    minWidth: "-webkit-fill-available",

    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflow: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflow: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflow: "auto",
    },
    // /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      minHeight: "12vh",
      maxHeight: "45vh",
      overflow: "auto",
    },
    // /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflow: "auto",
    },
  },
  notAddedStyle: {
    // backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    padding: 55,
    marginLeft: 33,
    marginRight: 33,
    // marginTop: 13,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    font: "normal normal normal 24px Karla;",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
  },
  reportPaper: {
    // padding: "10px",
    boxShadow: "0px 0px 4px #0000000D",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    paddingLeft: "26px",
    paddingRight: "23px",
    marginLeft: "22px",
    marginRight: "18px",
    paddingBottom: "24px",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  },
  reportTitle: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: theme.font.fontWeight,
  },
  emptyReport: {
    minHeight: "60vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    font: "normal normal bold 34px Karla;",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
  },

  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px Karla;",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "16px 14px",
    whiteSpace: "nowrap",
    overFlow: "hidden",
    borderColor: "#E7EBF4",
    backgroundColor: "white",
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    padding: "20px 14px",
    border: "0px",
    whiteSpace: "nowrap",
    overFlow: "hidden",
  },
  paginationText: {
    // textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingTop: "35px",
    paddingLeft: "10px",
  },
  clear: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.BlueButton,
    color: theme.colors.whiteColor,
    float: "right",
  },
  buttonGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  firstRow: {
    background: "#F1F4FB 0% 0% no-repeat padding-box",
    opacity: 1,
  },
  secondRow: {
    background: "#FAFBFE 0% 0% no-repeat padding-box",
    opacity: 1,
  },
});
class Reports extends Component {
  constructor(props, context) {
    super(props, context);
    this.myRef = React.createRef();
    this.state = {
      reportDetails: {},
      page: 1,
      limit: 10,
      showReportFilterWindow: false,
    };
  }
  componentDidMount() {
    this.props.resetReportData();
    let currentReport = reportList.filter(
      (item) => item.id == this.props.report
    );

    this.setState({
      reportDetails: currentReport[0],
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.report != this.props.report) {
      // this.props.resetSetIsGenerate();

      this.props.resetReportData();

      let currentReport = reportList.filter(
        (item) => item.id == this.props.report
      );

      if (currentReport[0].generationFields) {
        this.setState({
          ...this.state,
          reportDetails: {},
          generationFields: {},
          dropdownData: {},
          validationErrors: {},
          showReportFilterWindow: false,
        });
        currentReport[0].generationFields.forEach((field) => {
          this.setState((state) => ({
            ...state,
            generationFields: {
              ...state.generationFields,
              [field.name]: "",
            },
            validationErrors: {
              ...state.validationErrors,
              [field.name]: false,
            },

            generationFields1: {},
            generationFields2: {},
            reportParams: {},
            Field: [],
            Zone: [],
            District: [],
            Office: [],
            Squad: [],
            Officer: [],
            searchParams: {},
          }));
        });
      }
      if (currentReport[0].generationFields1) {
        this.setState({
          ...this.state,
          reportDetails: {},
          generationFields1: {},
          dropdownData: {},
          validationErrors: {},
          showReportFilterWindow: false,
        });
      }

      this.setState({
        reportDetails: currentReport[0],
        showReportFilterWindow: false,
      });
    }

    if (prevProps.dropdownData !== this.props.dropdownData) {
      this.setState({
        ...this.state.dropdownData,
        dropdownData: this.props.dropdownData,
      });
    }

    if (prevProps.reportFilterParams !== this.props.reportFilterParams) {
      if (Object.keys(this.props.reportFilterParams).length == 0) {
        this.initialCall();
      } else {
        this.generateReport();
      }
    }
  }

  toggleFilterWindow = () => {
    this.setState({
      showReportFilterWindow: this.state.showReportFilterWindow ? false : true,
    });
  };

  exportReport = () => {
    const { reportDetails } = this.state;
    const { reportFilterParams } = this.props;
    if (Object.keys(reportFilterParams).length == 0) {
      this.initialExport();
    } else {
      let exportParams = {
        ...reportFilterParams,
      };
      let data = {
        exportUrl: reportDetails.exportUrl,
        exportParams: exportParams,
      };
      this.props.exportReport(data);
    }
  };
  initialExport = () => {
    const { reportDetails } = this.state;
    var d1 = new Date();
    var d = new Date();
    d.setDate(d.getDate() - 7);
    let exportParams =
      this.props.report === "alert-report"
        ? {
            from_date: new Date(d.getTime()).setHours(0, 0, 0, 0),
            to_date: new Date(d1.getTime()).setHours(23, 59, 59),
            reportType: "alert",
          }
        : {
            from_date: new Date(d.getTime()).setHours(0, 0, 0, 0),
            to_date: new Date(d1.getTime()).setHours(23, 59, 59),
          };
    let data = {
      exportUrl: reportDetails.exportUrl,
      exportParams: exportParams,
    };
    this.props.exportReport(data);
  };
  generateReport = () => {
    const { reportFilterParams } = this.props;

    Object.keys(reportFilterParams).map((item) => {
      if (
        reportFilterParams[item] === "" ||
        reportFilterParams[item] == null ||
        reportFilterParams[item] == undefined
      ) {
        delete reportFilterParams[item];
      }
    });
    let generateParams = {
      ...this.props.reportFilterParams,
      page: this.state.page,
      limit: this.state.limit,
    };
    let data = {
      generateUrl: this.state.reportDetails.generateUrl,
      generateParams: generateParams,
    };
    this.props.fetchReport(data);
  };
  initialCall = () => {
    var d1 = new Date();
    var d = new Date();
    d.setDate(d.getDate() - 8);
    // d.setMonth(d.getMonth() - 2);
    let generateParams =
      this.props.report === "alert-report"
        ? {
            from_date: new Date(d.getTime()).setHours(0, 0, 0, 0),
            to_date: new Date(d1.getTime()).setHours(23, 59, 59),
            page: this.state.page,
            limit: this.state.limit,
            reportType: "alert",
          }
        : {
            from_date: new Date(d.getTime()).setHours(0, 0, 0, 0),
            to_date: new Date(d1.getTime()).setHours(23, 59, 59),
            page: this.state.page,
            limit: this.state.limit,
          };
    let data = {
      generateUrl: this.state.reportDetails.generateUrl,
      generateParams: generateParams,
    };
    this.props.fetchReport(data);
  };
  initialChangePage(page) {
    var d1 = new Date();
    var d = new Date();
    d.setDate(d.getDate() - 7);
    // d.setMonth(d.getMonth() - 2);
    let generateParams =
      this.props.report === "alert-report"
        ? {
            from_date: new Date(d.getTime()).setHours(0, 0, 0, 0),
            to_date: new Date(d1.getTime()).setHours(23, 59, 59),
            page: page,
            limit: this.state.limit,
            reportType: "alert",
          }
        : {
            from_date: new Date(d.getTime()).setHours(0, 0, 0, 0),
            to_date: new Date(d1.getTime()).setHours(23, 59, 59),
            page: page,
            limit: this.state.limit,
          };
    let data = {
      generateUrl: this.state.reportDetails.generateUrl,
      generateParams: generateParams,
    };
    this.props.fetchReport(data);
  }
  handleChangePage = (event, page) => {
    const { reportDetails } = this.state;
    const { reportFilterParams } = this.props;
    if (Object.keys(reportFilterParams).length == 0) {
      this.initialChangePage(page);
    } else {
      let generateParams = {
        ...reportFilterParams,
        page: page,
        limit: this.state.limit,
      };
      let data = {
        generateUrl: reportDetails.generateUrl,
        generateParams: generateParams,
      };
      this.props.fetchReport(data);
    }
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        ...this.state,
        limit: event.target.value,
      },
      () => {
        const { reportDetails } = this.state;
        const { reportData, reportFilterParams } = this.props;

        let generateParams = {
          ...reportFilterParams,
          page: reportData.page_no,
          limit: event.target.value,
        };
        let data = {
          generateUrl: reportDetails.generateUrl,
          generateParams: generateParams,
        };
        this.props.fetchReport(data);
      }
    );
  };

  clear = () => {
    this.setState({
      empty: Math.random(),
    });
  };
  render() {
    const { classes } = this.props;
    const { reportDetails } = this.state;
    const { reportData } = this.props;
    return (
      <React.Fragment>
        <ReportTitle
          data={reportDetails}
          title={reportDetails.title}
          toggleFilterWindow={this.toggleFilterWindow}
          exportReport={this.exportReport}
          showDownloadIcon={reportData.result && reportData.result.length > 0}
          showToolbarIcons={!(Object.keys(reportData).length === 0)}
        />
        <Paper className={classes.reportPaper} elevation={0}>
          {this.props.loading == false &&
          parseInt(reportData.total_count) - 1 == -2 ? (
            <div className={classes.notAddedStyle}>
              {messageConstants.NO_REPORTS_FOUND}
            </div>
          ) : (
            <React.Fragment>
              {reportData.result && reportData.result.length > 0 ? (
                <>
                  <div className={classes.container}>
                    <Table aria-label="sticky table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          {reportData.headers.map((row, i) => {
                            return (
                              <TableCell
                                key={i}
                                classes={{ root: classes.tableHead }}
                              >
                                {row}
                              </TableCell>
                            );
                          }, this)}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reportData.result.map((row, index) => (
                          <TableRow
                            className={
                              index % 2 == 0
                                ? classes.firstRow
                                : classes.secondRow
                            }
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            {row.map((cell, j) => (
                              <TableCell
                                key={j}
                                component="th"
                                scope="row"
                                classes={{ root: classes.tableCell }}
                              >
                                {cell}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                  <Grid container xs={12}>
                    <Grid item xs={4}>
                      <Typography className={classes.paginationText}>
                        {/* {"Showing " + ((parseInt(reportData.page_no) * this.state.limit) - 9) + " to " + (parseInt(reportData.page_no) * this.state.limit > reportData.total_count ? reportData.total_count : parseInt(reportData.page_no) * this.state.limit) + " of " + +reportData.total_count + " Vehicles"} */}
                        {parseInt(reportData.page_no) * this.state.limit -
                          9 +
                          " - " +
                          (parseInt(reportData.page_no) * this.state.limit >
                          reportData.total_count
                            ? reportData.total_count
                            : parseInt(reportData.page_no) * this.state.limit) +
                          " of " +
                          reportData.total_count +
                          " Vehicles"}
                      </Typography>
                    </Grid>
                    <Grid item style={{ float: "right" }} xs={8}>
                      <Pagination
                        count={Math.ceil(
                          reportData.total_count / this.state.limit
                        )}
                        shape="rounded"
                        page={parseInt(reportData.page_no)}
                        onChange={this.handleChangePage}
                        className="pagination"
                        color="secondary"
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  {sessionStorage.getItem("reportsMasterError") === "true" ? (
                    <div className={classes.notAddedStyle}>
                      {messageConstants.NO_REPORTS_FOUND}
                    </div>
                  ) : reportData.result && reportData.result.length == 0 ? (
                    <div className={classes.notAddedStyle}>
                      {messageConstants.NO_REPORTS_FOUND}
                    </div>
                  ) : (
                    <SkeltonScreen />
                  )}
                </>
              )}
            </React.Fragment>
          )}
        </Paper>
        {reportDetails.filterFields && (
          <>
            <ReportFilter
              filterFields={reportDetails.filterFields}
              toggleFilterWindow={this.toggleFilterWindow}
              showReportFilterWindow={this.state.showReportFilterWindow}
              empty={this.state.empty}
              applyFilter={this.generateReport}
              intialCall={this.initialCall}
              report={this.props.report}
            />
          </>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Reports);

// import React, { Component } from "react";
// import {
//   Paper,
//   Typography,
//   Grid,
//   withStyles,
//   Button,
//   TextField,
//   Table,
//   TableCell,
//   TableHead,
//   TableRow,
//   TableBody,
//   TablePagination,
// } from "@material-ui/core";
// import ReportTitle from "./components/ReportTitle";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import reportList from "./reportConfig";
// import ReportFilter from "./components/report_filter/ReportFilter";
// import IconButton from "@material-ui/core/IconButton";
// import FirstPageIcon from "@material-ui/icons/FirstPage";
// import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
// import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// import LastPageIcon from "@material-ui/icons/LastPage";
// import PropTypes from "prop-types";
// import StorageHandler from "../../util/storage/index";
// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
// import messageConstants from "../../services/validator/MsgConsts/messageConstants";
// import "../userMaster/style.css";
// import Pagination from "@material-ui/lab/Pagination";
// import SkeltonScreen from "../../components/skelton_screen/reportSkelton";
// const actionsStyles = (theme) => ({
//   root: {
//     flexShrink: 0,
//     color: theme.palette.text.secondary,
//     marginLeft: theme.spacing.unit * 2.5,
//   },
// });

// class TablePaginations extends React.Component {
//   handleFirstPageButtonClick = (event) => {
//     this.props.onChangePage(event, 0);
//   };

//   handleBackButtonClick = (event) => {
//     this.props.onChangePage(event, this.props.page - 1);
//   };

//   handleNextButtonClick = (event) => {
//     this.props.onChangePage(event, this.props.page + 1);
//   };

//   handleLastPageButtonClick = (event) => {
//     this.props.onChangePage(
//       event,
//       Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
//     );
//   };

//   render() {
//     const { classes, count, page, rowsPerPage, theme } = this.props;

//     return (
//       <div className={classes.root}>
//         <IconButton
//           onClick={this.handleFirstPageButtonClick}
//           disabled={page === 0}
//           aria-label="First Page"
//         >
//           {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
//         </IconButton>
//         <IconButton
//           onClick={this.handleBackButtonClick}
//           disabled={page === 0}
//           aria-label="Previous Page"
//         >
//           {theme.direction === "rtl" ? (
//             <KeyboardArrowRight />
//           ) : (
//             <KeyboardArrowLeft />
//           )}
//         </IconButton>
//         <IconButton
//           onClick={this.handleNextButtonClick}
//           disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//           aria-label="Next Page"
//         >
//           {theme.direction === "rtl" ? (
//             <KeyboardArrowLeft />
//           ) : (
//             <KeyboardArrowRight />
//           )}
//         </IconButton>
//         <IconButton
//           onClick={this.handleLastPageButtonClick}
//           disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//           aria-label="Last Page"
//         >
//           {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
//         </IconButton>
//       </div>
//     );
//   }
// }

// TablePaginations.propTypes = {
//   classes: PropTypes.object.isRequired,
//   count: PropTypes.number.isRequired,
//   onChangePage: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired,
//   theme: PropTypes.object.isRequired,
// };

// const TablePaginationActionsWrapped = withStyles(actionsStyles, {
//   withTheme: true,
// })(TablePaginations);

// const styles = (theme) => ({
//   // container: {
//   //   padding: theme.spacing.unit,
//   //   // maxHeight: "48vh",
//   //   minHeight: "48vh",
//   //   overflow: "auto",
//   // },
//   container: {
//     padding: theme.spacing.unit,
//     overflow: "auto",
//     minHeight: "48vh",
//     maxWidth: "70vw",
//     minWidth: "-webkit-fill-available",
//   },
//   notAddedStyle: {
//     // backgroundColor: theme.notAddedStyle.backgroundColor,
//     textAlign: "center",

//     padding: 55,

//     marginLeft: 33,
//     marginRight: 33,
//     // marginTop: 13,
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     font: "normal normal normal 24px Karla;",
//     letterSpacing: "0px",
//     color: "#797C82",
//     opacity: 1,
//   },
//   reportPaper: {
//     // padding: "10px",
//     boxShadow: "0px 0px 4px #0000000D",
//     background: "#FFFFFF 0% 0% no-repeat padding-box",
//     paddingLeft: "26px",
//     paddingRight: "23px",
//     marginLeft: "22px",
//     marginRight: "18px",
//     paddingBottom: "24px",
//     borderTopLeftRadius: "0px",
//     borderTopRightRadius: "0px",
//     borderBottomLeftRadius: "15px",
//     borderBottomRightRadius: "15px",
//   },
//   reportTitle: {
//     height: "50px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     fontWeight: theme.font.fontWeight,
//   },
//   emptyReport: {
//     minHeight: "60vh",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     font: "normal normal bold 34px Karla;",
//     letterSpacing: "0px",
//     color: "#797C82",
//     opacity: 1,
//   },

//   tableHead: {
//     textAlign: "left",
//     font: "normal normal bold 14px Karla;",
//     letterSpacing: "0px",
//     color: "#797C82",
//     opacity: 1,
//     padding: "16px 14px",
//     whiteSpace: "nowrap",
//     overFlow: "hidden",
//     borderColor: "#E7EBF4",
//   },
//   tableCell: {
//     textAlign: "left",
//     font: "normal normal normal 14px Karla",
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//     padding: "20px 14px",
//     border: "0px",
//     whiteSpace: "nowrap",
//     overFlow: "hidden",
//   },

//   clear: {
//     fontWeight: theme.font.fontWeight,
//     backgroundColor: theme.colors.BlueButton,
//     color: theme.colors.whiteColor,
//     float: "right",
//   },
//   buttonGrid: {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "space-between",
//   },
//   firstRow: {
//     background: "#F1F4FB 0% 0% no-repeat padding-box",
//     opacity: 1,
//   },
//   secondRow: {
//     background: "#FAFBFE 0% 0% no-repeat padding-box",
//     opacity: 1,
//   },
// });
// class Reports extends Component {
//   constructor(props, context) {
//     super(props, context);
//     this.myRef = React.createRef();
//     this.state = {
//       reportDetails: {},

//       page: 1,
//       limit: 10,
//       showReportFilterWindow: false,
//     };
//   }
//   componentDidMount() {
//     this.props.resetReportData();
//     let currentReport = reportList.filter(
//       (item) => item.id == this.props.report
//     );

//     this.setState({
//       reportDetails: currentReport[0],
//     });
//   }
//   componentDidUpdate(prevProps, prevState) {
//     if (prevProps.report != this.props.report) {
//       // this.props.resetSetIsGenerate();

//       this.props.resetReportData();

//       let currentReport = reportList.filter(
//         (item) => item.id == this.props.report
//       );

//       if (currentReport[0].generationFields) {
//         this.setState({
//           ...this.state,
//           reportDetails: {},
//           generationFields: {},
//           dropdownData: {},
//           validationErrors: {},
//           showReportFilterWindow: false,
//         });
//         currentReport[0].generationFields.forEach((field) => {
//           this.setState((state) => ({
//             ...state,
//             generationFields: {
//               ...state.generationFields,
//               [field.name]: "",
//             },
//             validationErrors: {
//               ...state.validationErrors,
//               [field.name]: false,
//             },

//             generationFields1: {},
//             generationFields2: {},
//             reportParams: {},
//             Field: [],
//             Zone: [],
//             District: [],
//             Office: [],
//             Squad: [],
//             Officer: [],
//             searchParams: {},
//           }));
//         });
//       }
//       if (currentReport[0].generationFields1) {
//         this.setState({
//           ...this.state,
//           reportDetails: {},
//           generationFields1: {},
//           dropdownData: {},
//           validationErrors: {},
//           showReportFilterWindow: false,
//         });
//       }

//       this.setState({
//         reportDetails: currentReport[0],
//         showReportFilterWindow: false,
//       });
//     }

//     if (prevProps.dropdownData !== this.props.dropdownData) {
//       this.setState({
//         ...this.state.dropdownData,
//         dropdownData: this.props.dropdownData,
//       });
//     }

//     if (prevProps.reportFilterParams !== this.props.reportFilterParams) {
//       // this.filterReport();
//       this.generateReport();
//     }
//   }

//   toggleFilterWindow = () => {
//     this.setState({
//       showReportFilterWindow: this.state.showReportFilterWindow ? false : true,
//     });
//   };

//   exportReport = () => {
//     const { reportDetails } = this.state;
//     const { reportFilterParams } = this.props;

//     let exportParams = {
//       ...reportFilterParams,
//     };
//     let data = {
//       exportUrl: reportDetails.exportUrl,
//       exportParams: exportParams,
//     };
//     this.props.exportReport(data);
//   };
//   generateReport = () => {
//     const { reportData, reportFilterParams } = this.props;
//     Object.keys(reportFilterParams).map((item) => {
//       if (
//         reportFilterParams[item] === "" ||
//         reportFilterParams[item] == null ||
//         reportFilterParams[item] == undefined
//       ) {
//         delete reportFilterParams[item];
//       }
//     });
//     let generateParams = {
//       ...this.props.reportFilterParams,
//       page: this.state.page,
//       limit: this.state.limit,
//     };
//     let data = {
//       generateUrl: this.state.reportDetails.generateUrl,
//       generateParams: generateParams,
//     };
//     this.props.fetchReport(data);
//   };

//   handleChangePage = (event, page) => {
//     const { reportDetails } = this.state;
//     const { reportData, reportFilterParams } = this.props;

//     let generateParams = {
//       ...reportFilterParams,
//       page: page,
//       limit: this.state.limit,
//     };
//     let data = {
//       generateUrl: reportDetails.generateUrl,
//       generateParams: generateParams,
//     };
//     this.props.fetchReport(data);
//   };

//   handleChangeRowsPerPage = (event) => {
//     this.setState(
//       {
//         ...this.state,
//         limit: event.target.value,
//       },
//       () => {
//         const { reportDetails } = this.state;
//         const { reportData, reportFilterParams } = this.props;

//         let generateParams = {
//           ...reportFilterParams,
//           page: reportData.page_no,
//           limit: event.target.value,
//         };
//         let data = {
//           generateUrl: reportDetails.generateUrl,
//           generateParams: generateParams,
//         };
//         this.props.fetchReport(data);
//       }
//     );
//   };

//   clear = () => {
//     this.setState({
//       empty: Math.random(),
//     });
//   };
//   render() {
//     const { classes } = this.props;
//     const { reportDetails } = this.state;
//     const { reportData } = this.props;
//     return (
//       <React.Fragment>
//         <ReportTitle
//           data={reportDetails}
//           title={reportDetails.title}
//           toggleFilterWindow={this.toggleFilterWindow}
//           exportReport={this.exportReport}
//           showToolbarIcons={!(Object.keys(reportData).length === 0)}
//         />
//         <Paper className={classes.reportPaper} elevation={0}>
//           {reportData.title ? (
//             <React.Fragment>
//               {reportData.result && reportData.result.length > 0 ? (
//                 <>
//                   <div className={classes.container}>
//                     <Table aria-labelledby="tableTitle">
//                       <TableHead>
//                         <TableRow>
//                           {reportData.headers.map((row, i) => {
//                             return (
//                               <TableCell
//                                 key={i}
//                                 // className={classes.tableHead}
//                                 classes={{ root: classes.tableHead }}
//                               >
//                                 {row}
//                               </TableCell>
//                             );
//                           }, this)}
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         {reportData.result.map((row, index) => (
//                           <TableRow
//                             // hover
//                             className={
//                               index % 2 == 0
//                                 ? classes.firstRow
//                                 : classes.secondRow
//                             }
//                             role="checkbox"
//                             tabIndex={-1}
//                             key={index}
//                           >
//                             {row.map((cell, j) => (
//                               <TableCell
//                                 key={j}
//                                 component="th"
//                                 scope="row"
//                                 // className={classes.tableCell}
//                                 classes={{ root: classes.tableCell }}
//                                 // align="center"
//                               >
//                                 {cell}
//                               </TableCell>
//                             ))}
//                           </TableRow>
//                         ))}
//                       </TableBody>
//                     </Table>
//                   </div>

//                   {/* <TablePagination
//                     rowsPerPageOptions={[5, 10, 20, 50, 100]}
//                     component="div"
//                     count={reportData.total_count}
//                     rowsPerPage={parseInt(reportData.per_page)}
//                     page={reportData.page_no - 1}
//                     onChangePage={this.handleChangePage}
//                     onChangeRowsPerPage={this.handleChangeRowsPerPage}
//                     ActionsComponent={TablePaginationActionsWrapped}
//                   /> */}
//                   <Pagination
//                     count={Math.ceil(reportData.total_count / this.state.limit)}
//                     shape="rounded"
//                     page={parseInt(reportData.page_no)}
//                     onChange={this.handleChangePage}
//                     className="pagination"
//                     color="secondary"
//                   />
//                 </>
//               ) : (
//                 <>
//                   {this.props.loading ? (
//                     <SkeltonScreen />
//                   ) : (
//                     <div className={classes.notAddedStyle}>
//                       {messageConstants.CLICK_GENERATE_TO_VIEW_REPORT}
//                     </div>
//                   )}
//                 </>
//               )}
//             </React.Fragment>
//           ) : this.props.loading ? (
//             <SkeltonScreen />
//           ) : (
//             <div className={classes.notAddedStyle}>
//               {messageConstants.CLICK_GENERATE_TO_VIEW_REPORT}
//             </div>
//           )}
//         </Paper>
//         {reportDetails.filterFields && (
//           <>
//             <ReportFilter
//               filterFields={reportDetails.filterFields}
//               toggleFilterWindow={this.toggleFilterWindow}
//               showReportFilterWindow={this.state.showReportFilterWindow}
//               empty={this.state.empty}
//               applyFilter={this.generateReport}
//             />
//           </>
//         )}
//       </React.Fragment>
//     );
//   }
// }

// export default withStyles(styles)(Reports);
