import React, { Fragment } from "react";
import {
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  TextField,
  FormGroup,
  Divider,
} from "@material-ui/core";
import classNames from "classnames";
import propTypes from "prop-types";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    marginTop: "43px",
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "17px",
    marginBottom: "15px",
  },
  divde: {
    border: "1px solid #E7EBF4",
    opacity: 1,
    marginLeft: "-8px",
  },
});

let EnhancedTableToolbar = (props) => {
  const { classes } = props;
  return (
    <>
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          Change Password
        </Typography>
      </Toolbar>
      <Divider className={classes.divde} />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: propTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    borderRadius: theme.border.radius,
    opacity: 1,
    marginLeft: "21px",
    marginRight: "17px",
    paddingLeft: "8px",
    paddingBottom: "32px",
    // height: "354px",
  },

  textField: {
    width: "95%",
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "45px",
  },

  button: {
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    opacity: 1,
    textTransform: "none",
    // paddingLeft: "38px",
    // paddingRight: "37px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    height: "42px",
    width: "121px",
  },

  clearButton: {
    width: "121px",
    height: "42px",
    // marginRight: "10px",
    textTransform: "none",
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.colors.BlueButton,
    textAlign: "center",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
  },

  err: {
    paddingLeft: "1%",
  },
  dense: {
    marginTop: 16,
  },
  textFielddes: {
    paddingTop: "22px",
    marginLeft: "10px",
    marginRight: "13px",
  },
  btnGrid: {
    padding: 0,
    paddingLeft: "12px",
  },
  btns: {
    paddingRight: "25%",
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  innerGrid: {
    // paddingBottom: "16px",
    paddingBottom: "20px",
    paddingRight: "4px",
  },
  outerGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "15px",
  },
});

class UserInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      n: 2,
      data: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      alertType: "",
      alertVisible: false,
      alert: "",
      confirmPasswordError: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.saveSuccess !== prevProps.saveSuccess && !this.props.error) {
      this.props.showSnackbar("password changed successfully", "success");
      this.props.resetUserDetail();
      this.props.navigateTo("/dashboard");
    }
    if (this.props.error && this.props.error !== this.state.alert) {
      this.setState({
        alert: this.props.error,
        alertType: "danger",
        alertVisible: true,
      });
    }
  }

  onBackButtonClick = () => {
    this.props.navigateTo("/dashboard");
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };

  performAction = (e) => {
    if (this.state.data.newPassword !== this.state.data.confirmPassword) {
      this.props.showSnackbar("Please confirm your password", "error");
      this.setState({ confirmPasswordError: true });
    } else {
      let params = {
        currentPassword: this.state.data.currentPassword,
        password: this.state.data.newPassword,
        confirmPassword: this.state.data.confirmPassword,
      };
      this.props.editUserPassword(params);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root} elevation={0}>
        <EnhancedTableToolbar onBackButtonClick={this.onBackButtonClick} />
        <div className={classes.textFielddes}>
          <Fragment>
            <Grid container className={classes.outerGrid}>
              <Grid xs={12} md={4} item className={classes.innerGrid}>
                <FormGroup row>
                  <TextField
                    label="Current Password"
                    className={classes.textField}
                    name="currentPassword"
                    id="currentPassword"
                    value={this.state.data.currentPassword}
                    onChange={(e) => this.handleChange(e)}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{ classes: { root: classes.textLabel } }}
                    InputProps={{ classes: { root: classes.textValue } }}
                    variant="outlined"
                  />
                </FormGroup>
              </Grid>
              <Grid xs={12} md={4} item className={classes.innerGrid}>
                <FormGroup row>
                  <TextField
                    label="New password"
                    className={classes.textField}
                    name="newPassword"
                    id="newPassword"
                    value={this.state.data.newPassword}
                    onChange={(e) => this.handleChange(e)}
                    margin="dense"
                    fullWidth
                    InputLabelProps={{ classes: { root: classes.textLabel } }}
                    InputProps={{ classes: { root: classes.textValue } }}
                    variant="outlined"
                  />
                </FormGroup>
              </Grid>
              <Grid xs={12} md={4} item className={classes.innerGrid}>
                <FormGroup row>
                  <TextField
                    label="Confirm Password"
                    className={classes.textField}
                    name="confirmPassword"
                    id="confirmPassword"
                    value={this.state.data.confirmPassword}
                    onChange={(e) => this.handleChange(e)}
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ classes: { root: classes.textLabel } }}
                    InputProps={{ classes: { root: classes.textValue } }}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            {/* <Grid xs={12} className={classes.btns} container spacing={3}>
              <Grid item>
                <Button
                  className={classes.clearButton}
                  onClick={() => this.onBackButtonClick()}
                >
                  CLOSE
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => this.performAction()}
                >
                  SAVE
                </Button>
              </Grid>
            </Grid> */}
            <Grid xs={12} className={classes.btns} container>
              <Grid item xs={12} md={6}></Grid>
              <Grid
                item
                xs={12}
                md={2}
                style={{
                  textAlign: "right",
                }}
              >
                <Button
                  disableElevation={true}
                  variant="contained"
                  className={classes.clearButton}
                  onClick={() => this.onBackButtonClick()}
                >
                  Close
                </Button>
              </Grid>
              <Grid item xs={12} md={3} className={classes.btnGrid}>
                <Button
                  disableElevation={true}
                  elevation={0}
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => this.performAction()}
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={12} md={1}></Grid>
            </Grid>
          </Fragment>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(UserInfo);
