import moment from "moment";
export const toDateTime = (milliSecond) => {
  // var today = new Date(parseInt(milliSecond));
  // var dd = today.getDate();
  // var mm = today.getMonth() + 1; //January is 0!

  // var yyyy = today.getFullYear();
  // if (dd < 10) {
  //   dd = "0" + dd;
  // }
  // if (mm < 10) {
  //   mm = "0" + mm;
  // }
  // var date = dd + "/" + mm + "/" + yyyy;
  // var time = today.toLocaleTimeString();
  // return date.concat(" ").concat(time);
  return moment(milliSecond, "x").format("DD/MM/YYYY hh:mm:ss A");
};
