const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.border.radius,
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
  },
  grid: {
    marginTop: "0px",
    paddingTop: "0px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "83vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "83vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "86vh",
      overflowX: "hidden",
      overflowY: "auto",
    },

    // /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "80vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
  },
  subTitleHead: {
    font: "normal normal normal 16px/10px Karla",
    color: "#797C82",
    display: "flex",
    marginBottom: "28.5px",
  },
  subTitleContent: {
    font: "normal normal bold 16px/10px Karla",
    color: "#2B3D51",
    textTransform: "capitalize",
  },
  textFielddes: {
    marginLeft: "33px",
    marginRight: "13px",
    paddingTop: "25px",
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
  instructions: {
    height: "415px",
    overflow: "auto",
  },
  table: {
    minWidth: "100%",
    borderTop: "2px solid #F1F4FB",
  },
  tableWrapper: {
    overflowX: "auto",
    // paddingLeft: "33px",
    paddingRight: "10px",
    // paddingTop: "6px",
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "0px",
    paddingBottom: "0px",
    height: "41px",
  },
});
export default styles;
