import {
  FETCH_FIELD,
  FETCH_FIELD_FAILED,
  FETCH_ZONE,
  FETCH_ZONE_FAILED,
  FETCH_DISTRICT,
  FETCH_DISTRICT_FAILED,
  FETCH_OFFICE,
  FETCH_OFFICE_FAILED,
  FETCH_LEADER,
  FETCH_LEADER_FAILED,
  ADD_EDIT_SQUAD_MASTERS,
  ADD_EDIT_SQUAD_MASTERS_FAILED,
  RESET_SQUAD_DETAIL,
  FETCH_SQUAD_DETAILS,
  FETCH_SQUAD_DETAILS_FAILED,
  FETCH_MEMBER_DATA,
  FETCH_MEMBER_DATA_FAILED,
  FILTER_CHANGE_ERROR,
  FILTER_CHANGE,
} from "./constants";
import SquadMasterService from "../../../../service/SquadService";
// import FilterService from "../../../../../service/FilterService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchField = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SquadMasterService().fetchField(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_FIELD, data };
  }

  function failure(error) {
    return { type: FETCH_FIELD_FAILED, error };
  }
};

export const fetchMemberData = (id,district_id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SquadMasterService().fetchMemberData(id,district_id).then((response) => {
      if (response.data) {
        if (response.data.data === null) {
          dispatch(snackbarActions.showSnackbar(" Invalid PEN or From other District ", "error"));
        }
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_MEMBER_DATA, data };
  }

  function failure(error) {
    return { type: FETCH_MEMBER_DATA_FAILED, error };
  }
};

export const fetchZone = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SquadMasterService().fetchZone().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_ZONE, data };
  }

  function failure(error) {
    return { type: FETCH_ZONE_FAILED, error };
  }
};
export const fetchDistrict = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SquadMasterService().fetchDistrict(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_DISTRICT, data };
  }

  function failure(error) {
    return { type: FETCH_DISTRICT_FAILED, error };
  }
};
export const fetchOffice = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SquadMasterService().fetchOffice(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_OFFICE, data };
  }

  function failure(error) {
    return { type: FETCH_OFFICE_FAILED, error };
  }
};

export const fetchLeader = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SquadMasterService().fetchLeader(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_LEADER, data };
  }

  function failure(error) {
    return { type: FETCH_LEADER_FAILED, error };
  }
};

export const addSquad = (params) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SquadMasterService().addSquad(params).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
    function success(data) {
      return { type: ADD_EDIT_SQUAD_MASTERS, data };
    }
    function failure(error) {
      return { type: ADD_EDIT_SQUAD_MASTERS_FAILED, error };
    }
  };
};

export const fetchSquadDetails = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SquadMasterService().fetchSquadDetails(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_SQUAD_DETAILS, data };
  }
  function failure(error) {
    return { type: FETCH_SQUAD_DETAILS_FAILED, error };
  }
};

export const editSquad = (params, id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new SquadMasterService().editSquad(params, id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: ADD_EDIT_SQUAD_MASTERS, data };
  }
  function failure(error) {
    return { type: ADD_EDIT_SQUAD_MASTERS_FAILED, error };
  }
};

export function resetSquadDetail() {
  return {
    type: RESET_SQUAD_DETAIL,
  };
}
export const onFilterChange = (page, limit, squadname, district) => {
  let squadMasterService = new SquadMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    squadMasterService
      .onFilterChange(page, limit, squadname, district)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: FILTER_CHANGE, data };
  }
  function failure(error) {
    return { type: FILTER_CHANGE_ERROR, error };
  }
};
