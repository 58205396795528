import {
  SELECTED_RECORD,
  FETCH_RECORD_FAIL,
  FETCH_RECORD_SUCCESS,
  RESET_RECORD,
} from "./constants";
import vehicleService from "../../../../../service/VehicleService";

export const setSelectedRecord = (record) => {
  return (dispatch) => {
    dispatch(setSelectedRecord(record));
  };
  function setSelectedRecord(record) {
    return { type: SELECTED_RECORD, record };
  }
};

export const resetSelectedRecord = () => {
  return (dispatch) => {
    dispatch(resetSelectedRecord());
  };
  function resetSelectedRecord() {
    return { type: RESET_RECORD };
  }
};

export const fetchRecord = (recordID) => {
  return (dispatch) => {
    vehicleService.fetchRecord(recordID).then((response) => {
      if (response.data) dispatch(success(response.data));
      else dispatch(failure(response));
    });
  };
  function success(data) {
    return { type: FETCH_RECORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: FETCH_RECORD_FAIL, error };
  }
};
