import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { ReactComponent as MenuIcon } from "../../assets/Visual Assets/side.svg";
import DrawerItem from "./DrawerItem";

const styles = (theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    marginLeft: "26px",
    marginTop: "20px",
    justifyContent: "space-around",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: "relative",
    width: "267px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    top: "0px",
    left: "-2px",

    height: "100vh",
    overflowY: "auto",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #0000000D",
    opacity: 1,
    borderTopRightRadius: "15px",
    borderBottomRightRadius: "15px",
    border: "none",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    top: "0px",
    left: "-2px",
    width: "70px",
    height: "100vh",
    overflowY: "auto",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #0000000D",
    opacity: 1,
    borderTopRightRadius: "15px",
    borderBottomRightRadius: "15px",
    border: "none",
  },
  drawerHeadIcon: {
    color: "#1BB99A",
    borderRadius: 1,
    opacity: 1,
    cursor: "pointer",
  },
  drawerIcon: {
    top: "13px",
    width: "19px",
    height: "50px",
    cursor: "pointer",
  },
  appName: {
    height: "12px",
    textAlign: "left",
    font: "normal normal",
    letterSpacing: "0px",
    color: "#1BB99A",
    textTransform: "uppercase",
    opacity: 1,
    fontFamily: "Karla",
    fontSize: "24px",
    fontWeight: "bold",
  },
  appName2: {
    width: "125px",
    height: "12px",
    textAlign: "left",
    font: "normal normal",
    fontWeight: "bold",
    letterSpacing: "0px",
    color: "#2B3D51",
    textTransform: "none",
    fontFamily: "Karla",
    fontSize: "20px",
    opacity: 1,
  },
  divider: {
    marginTop: "31px",
    left: "-0.85498046875px",
    // width: "254px",
    width: "245px",
    height: "0px",
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
  Closedivider: {
    marginTop: "31px",
    left: "-0.85498046875px",
    width: "57px",
    height: "0px",
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
});

class SideNavBar extends React.Component {
  handleClick = () => {
    this.props.drawerToggle();
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Drawer
          elevation={0}
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.props.showAppDrawer && classes.drawerPaperClose
            ),
          }}
          open={this.props.showAppDrawer}
        >
          <div className={classes.toolbarIcon}>
            <MenuIcon
              onClick={this.handleClick}
              className={classes.drawerIcon}
            />
            {/* <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleClick}
              className={classes.drawerIcon}
            >
              <MenuIcon className={classes.drawerHeadIcon} />
            </IconButton> */}
            {/* {this.props.showAppDrawer && (
              <Typography className={classes.appName}>
                {"     "} <span className={classes.appName2}>{"    "}</span>
              </Typography>
            )} */}
          </div>

          <Divider
            className={
              !this.props.showAppDrawer ? classes.Closedivider : classes.divider
            }
          />
          {/* <List className={classes.appDrawer}> */}
          <DrawerItem
            navigateTo={this.props.navigateTo}
            pathname={this.props.pathname}
            showAppDrawer={this.props.showAppDrawer}
            drawerToggle={this.props.drawerToggle}
            appBarPath={this.props.appBarPath}
          />
          {/* </List> */}
          {/* <br></br> */}
          {/* {this.props.showAppDrawer && <AppFooter />} */}
        </Drawer>
      </>
    );
  }
}
export default withStyles(styles)(SideNavBar);
