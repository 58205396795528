import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { Typography, Paper } from "@material-ui/core";
import tileData from "./tileData";

const styles = (theme) => ({
  paper: {
    height: "45vh",
    overflowY: "auto",
    overflowX: "hidden",
  },
  paperTitle: {
    fontSize: 15,
    fontWeight: theme.font.fontWeight,
  },
  gridList: {},
});

function CameraList(props) {
  const { classes } = props;

  return (
    <Fragment>
      <Typography variant="h6" component="h3" className={classes.paperTitle}>
        Camera
      </Typography>
      <Paper className={classes.paper}>
        <GridList cellHeight={100} className={classes.gridList}>
          {tileData.map((tile, index) => (
            <GridListTile key={index}>
              <img src={tile.img} alt={tile.title} />
              <GridListTileBar title={tile.title} />
            </GridListTile>
          ))}
        </GridList>
      </Paper>
    </Fragment>
  );
}

CameraList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CameraList);
