import {
  FETCH_ALERT_AND_WARNING_COUNT,
  FETCH_ALERT_AND_WARNING_COUNT_ERROR,
} from "./constants";

const initialState = {
  alertAndWarning: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALERT_AND_WARNING_COUNT:
      return {
        ...state,
        alertAndWarning: action.data.data,
      };
    case FETCH_ALERT_AND_WARNING_COUNT_ERROR:
      sessionStorage.setItem("pieChartError", true);
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
