import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import validator from "../../../../services/validator/validator";
import classNames from "classnames";
import { find } from "lodash";
import {
  FormHelperText,
  Table,
  TableBody,
  TableHead,
  Typography,
  IconButton,
  Divider,
  TableRow,
  TableCell,
} from "@material-ui/core";
// import Recaptcha from "react-google-invisible-recaptcha";
// import CaptchaSiteKeyMasters from "../../../../services/siteKeyMasters";
import propTypes from "prop-types";
import { ReactComponent as BackIcon } from "../../../../assets/SvgIcons/back.svg";
import { ReactComponent as UserIcon } from "../../../../assets/SvgIcons/basic_info_white.svg";
import { ReactComponent as FeatureIcon } from "../../../../assets/SvgIcons/select_features_white.svg";
import { ReactComponent as DashIcon } from "../../../../assets/Visual Assets/dashboard.svg";
import { ReactComponent as MastIcon } from "../../../../assets/Visual Assets/master_setup.svg";
import { ReactComponent as LiveIcon } from "../../../../assets/Visual Assets/live_data.svg";
import { ReactComponent as AlertIcon } from "../../../../assets/Visual Assets/alerts.svg";
import { ReactComponent as WarnIcon } from "../../../../assets/Visual Assets/warnings.svg";
import { ReactComponent as HistIcon } from "../../../../assets/Visual Assets/history.svg";
import { ReactComponent as RepoIcon } from "../../../../assets/Visual Assets/eports.svg";
import { ReactComponent as DownArrow } from "../../../../assets/SvgIcons/dropdown.svg";
import { ReactComponent as ReviewIcon } from "../../../../assets/SvgIcons/survey.svg";
// import CameraAltIcon from "@material-ui/icons/CameraAlt";
import clsx from "clsx";

const headerStyles = (theme) => ({
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "12px 14px",
    borderColor: "#E7EBF4",
  },
});

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.render.props.onRequestSort(event, property);
  };
  render() {
    const { classes } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell key={0} className={classes.tableHead}>
            Features
          </TableCell>
          <TableCell key={1} className={classes.tableHead}>
            Add
          </TableCell>
          <TableCell key={2} className={classes.tableHead}>
            Edit
          </TableCell>
          <TableCell key={3} className={classes.tableHead}>
            Delete
          </TableCell>
          <TableCell key={4} className={classes.tableHead}>
            View
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);
const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    marginTop: "43px",
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "17px",
    marginBottom: "15px",
  },
  iconButton: {
    paddingLeft: "0px",
  },
});

let EnhancedTableToolbar = (props) => {
  const { classes } = props;
  return (
    <>
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          <IconButton
            onClick={(e) => props.onBackButtonClick(e)}
            classes={{ root: classes.iconButton }}
          >
            <BackIcon />
          </IconButton>
          {props.header}
        </Typography>
      </Toolbar>
      <Divider />
    </>
  );
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
    left: "calc(-50% + 20px)",
    right: "calc(50% + 20px)",
  },
  active: {
    "& $line": {
      borderTop: "2px solid #1BB99A",
    },
  },
  completed: {
    "& $line": {
      borderTop: "2px solid #1BB99A",
    },
  },
  line: {
    height: 0,
    border: 0,
    borderTop: "2px dashed #D9DEE9",
  },
})(StepConnector);
const useColorlibStepIconStyles = makeStyles({
  roots: {
    backgroundColor: "#ccc",
    // zIndex: 1,
    color: "white",
    width: "41px",
    height: "41px",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    background: "#1BB99A 0% 0% no-repeat padding-box",
  },
  completed: {
    background: "#1BB99A 0% 0% no-repeat padding-box",
  },
});
function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <UserIcon />,
    2: <FeatureIcon />,
  };

  return (
    <div
      className={clsx(classes.roots, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: propTypes.bool,
  completed: propTypes.bool,
  icon: propTypes.node,
};

class addRole extends Component {
  constructor(props) {
    super(props);
    // this.onResolved = this.onResolved.bind(this);

    this.state = {
      activeStep: 0,
      tempPermissions: [],
      permissions: [],
      data: {
        captcha: "",
        role_name: "",
        userTypeId: {
          id: "",
        },
        permissions: [],
      },
      userTypeList: [],
      user_type: "",
      subMenuStatus: false,
      menu_id: "",
      roleStatus: "",
      errors: {
        role_name: "",
        user_type: "",
      },
      rules: {
        role_name: {
          required: true,
          minLength: 8,
          maxLength: 30,
          alphanumericwithspecialchar: true,
        },
        user_type: {
          required: true,
        },
      },
    };
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }
  componentDidMount() {
    this.props.fetchUserType();
    this.props.fetchFeatures();
  }
  onBackButtonClick = () => {
    this.props.navigateTo("/role-master");
  };
  componentWillUnmount() {
    this.props.resetUserTypeList();
    this.props.resetFeatures();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.roleStatus !== this.props.roleStatus) {
      if (this.props.roleStatus === true) {
        this.setState(
          {
            ...this.state,
            rules: {
              ...this.state.rules,
              role_name: {
                ...this.state.rules.role_name,
                isRoleExist: true,
              },
            },
          },
          () => {
            this.setState({
              ...this.state,
              errors: {
                ...this.state.errors,
                role_name: "Role Name Already Exist !!!",
              },
            });
          }
        );
      } else if (this.props.roleStatus === false) {
        this.setState({
          ...this.state,
          rules: {
            ...this.state.rules,
            role_name: {
              ...this.state.rules.role_name,
              isRoleExist: true,
            },
          },
          errors: {
            ...this.state.errors,
            role_name: "",
          },
        });
        this.setState({ activeStep: this.state.activeStep + 1 });
      }
    }
    if (prevProps.userTypeList !== this.props.userTypeList) {
      this.setState({ userTypeList: this.props.userTypeList.userTypeList });
    }
    if (prevProps.featureList !== this.props.featureList) {
      this.setState({
        data: {
          ...this.state.data,
          permissions: this.props.featureList.featureList,
        },
      });
    }
    if (prevProps.saveSuccess !== this.props.saveSuccess) {
      if (this.props.saveSuccess.saveSuccess) {
        // console.log("save success");
        this.props.resetUserTypeList();
        this.props.resetFeatures();
        this.props.navigateTo("/role-master");
      }
    }
  }

  onRoleNameChange = (event) => {
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          role_name: event.target.value,
        },
        errors: {
          ...this.state.errors,
          role_name: "",
        },
      },
      () => {
        this.props.resetRoleStatus();
      }
    );
  };

  onUserTypeChange = (event) => {
    // this.recaptcha.execute();
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          userTypeId: {
            ...this.state.data.userTypeId,
            id: event.target.value,
          },
        },
        errors: {
          ...this.state.errors,
          user_type: "",
        },
      },
      () => {
        this.props.resetRoleStatus();
      }
    );
  };
  handleCheckbox(event, featureId) {
    // this.recaptcha.execute();
    let index = this.state.data.permissions.findIndex(
      (feature) => feature.menu_id === featureId
    );
    let permissions = [...this.state.data.permissions];

    switch ([event.target.name][0]) {
      case "addStatus":
        permissions[index].permissions.add_status =
          event.target.value === "true";
        if (permissions[index].permissions.view_status === false) {
          permissions[index].permissions.view_status = true;
        }
        if (
          permissions[index].permissions.add_status === true &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.add_status = true;
            permissions[index].sub_menus[id].permissions.view_status = true;
          });
        }
        if (
          permissions[index].permissions.add_status === false &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.add_status = false;
          });
        }
        break;
      case "editStatus":
        permissions[index].permissions.edit_status =
          event.target.value === "true";
        if (permissions[index].permissions.view_status === false) {
          permissions[index].permissions.view_status = true;
        }
        if (
          permissions[index].permissions.edit_status === true &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.edit_status = true;
            permissions[index].sub_menus[id].permissions.view_status = true;
          });
        }
        if (
          permissions[index].permissions.edit_status === false &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.edit_status = false;
          });
        }
        break;
      case "deleteStatus":
        permissions[index].permissions.delete_status =
          event.target.value === "true";
        if (permissions[index].permissions.view_status === false) {
          permissions[index].permissions.view_status = true;
        }
        if (
          permissions[index].permissions.delete_status === true &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.delete_status = true;
            permissions[index].sub_menus[id].permissions.view_status = true;
          });
        }
        if (
          permissions[index].permissions.delete_status === false &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.delete_status = false;
          });
        }
        break;
      case "viewStatus":
        permissions[index].permissions.view_status =
          event.target.value === "true";
        if (permissions[index].permissions.view_status === false) {
          permissions[index].permissions.add_status = false;
          permissions[index].permissions.edit_status = false;
          permissions[index].permissions.delete_status = false;
        }
        if (
          permissions[index].permissions.view_status === true &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.view_status = true;
          });
        }
        if (
          permissions[index].permissions.view_status === false &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.add_status = false;
            permissions[index].sub_menus[id].permissions.edit_status = false;
            permissions[index].sub_menus[id].permissions.delete_status = false;
            permissions[index].sub_menus[id].permissions.view_status = false;
          });
        }
        break;
      default:
        break;
    }

    this.setState({
      permissions,
    });
  }

  handleCheckboxSubMenu(event, featureId, i, j) {
    let index = this.state.data.permissions[j].sub_menus.findIndex(
      (feature) => feature.menu_id === featureId
    );

    let permissions = [...this.state.data.permissions[j].sub_menus];
    let tempMain = [...this.state.data.permissions];
    switch ([event.target.name][0]) {
      case "addStatus":
        permissions[index].permissions.add_status =
          event.target.value === "true";
        if (
          permissions[index].permissions.add_status === true &&
          permissions[index].permissions.view_status === false
        ) {
          permissions[index].permissions.view_status = true;
        }
        if (
          this.state.data.permissions[j].permissions.add_status === false &&
          permissions[index].permissions.add_status === true
        ) {
          tempMain[j].permissions.add_status = true;
          if (tempMain[j].permissions.view_status === false) {
            tempMain[j].permissions.view_status = true;
          }

          this.setState(tempMain);
        }
        break;
      case "editStatus":
        permissions[index].permissions.edit_status =
          event.target.value === "true";

        if (
          permissions[index].permissions.edit_status === true &&
          permissions[index].permissions.view_status === false
        ) {
          permissions[index].permissions.view_status = true;
        }

        if (
          this.state.data.permissions[j].permissions.edit_status === false &&
          permissions[index].permissions.edit_status === true
        ) {
          tempMain[j].permissions.edit_status = true;
          if (tempMain[j].permissions.view_status === false) {
            tempMain[j].permissions.view_status = true;
          }
          this.setState(tempMain);
        }
        break;
      case "deleteStatus":
        permissions[index].permissions.delete_status =
          event.target.value === "true";
        if (
          permissions[index].permissions.delete_status === true &&
          permissions[index].permissions.view_status === false
        ) {
          permissions[index].permissions.view_status = true;
        }
        if (
          this.state.data.permissions[j].permissions.delete_status === false &&
          permissions[index].permissions.delete_status === true
        ) {
          tempMain[j].permissions.delete_status = true;
          if (tempMain[j].permissions.view_status === false) {
            tempMain[j].permissions.view_status = true;
          }
          this.setState(tempMain);
        }
        break;
      case "viewStatus":
        permissions[index].permissions.view_status =
          event.target.value === "true";
        if (
          this.state.data.permissions[j].permissions.view_status === false &&
          permissions[index].permissions.view_status === true
        ) {
          tempMain[j].permissions.view_status = true;
          this.setState(tempMain);
        }
        if (permissions[index].permissions.view_status === false) {
          permissions[index].permissions.add_status = false;
          permissions[index].permissions.edit_status = false;
          permissions[index].permissions.delete_status = false;
        }
        break;
    }
    this.setState({
      permissions,
    });
  }

  handleCancel = () => {
    this.props.resetUserTypeList();
    this.props.resetFeatures();
    this.props.navigateTo("/role-master");
  };
  // onResolved = () => {
  //   this.setState({
  //     ...this.state,
  //     data: {
  //       ...this.state.data,
  //       captcha: this.recaptcha.getResponse(),
  //     },
  //   });
  // };
  handleNext = () => {
    if (this.state.activeStep === 0) {
      let params = {
        role_name: this.state.data.role_name,
        user_type: this.state.data.userTypeId.id,
        // captcha: this.state.data.captcha,
      };

      let params_for_role_status = {
        rolename: this.state.data.role_name,
        usertypeid: this.state.data.userTypeId.id,
      };
      //please change the code. bcz this is not working properly

      if (this.validate(this.state.rules, params)) {
        if (this.props.roleStatus === false) {
          this.props.fetchRoleStatus(params_for_role_status);
        } else if (this.props.roleStatus === true) {
          this.setState({
            ...this.state,
            errors: {
              ...this.state.errors,
              role_name: "Role Name Already Exist !!!",
            },
          });
          this.props.fetchRoleStatus(params_for_role_status);
        } else {
          this.props.fetchRoleStatus(params_for_role_status);
        }
      }
    } else if (this.state.activeStep === 1) {
      // this.recaptcha.execute();
      let params = this.state.data;
      this.props.addRoleMaster(params);
    }
  };

  validate = (rules, data) => {
    const errors = validator(rules)(data);
    const hasErrors = find(errors, (error) => error !== "");
    this.setState({ errors });
    return !hasErrors;
  };
  handleonBlur = () => {
    let params = {
      rolename: this.state.data.role_name,
      usertypeid: this.state.data.userTypeId.id,
    };
    if (params.rolename !== "" && params.usertypeid != "") {
    }
    // this.props.fetchRoleStatus(params_for_role_status);
  };

  handleClearAllBase = () => {
    if (this.state.activeStep == 0) {
      this.setState({
        ...this.state,
        data: {
          ...this.state.data,
          role_name: "",
          userTypeId: {
            ...this.state.data.userTypeId,
            id: "",
          },
        },
        errors: {
          role_name: "",
          user_type: "",
        },
        roleStatus: "",
      });
    } else {
      this.handleClearPermissions();
    }
  };
  handleClearPermissions = () => {
    this.props.fetchFeatures();
  };
  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
    this.props.resetRoleStatus();
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  getSteps() {
    return ["Basic Info", "Select Features"];
  }

  getStepContent(stepIndex) {
    const { classes } = this.props;

    switch (stepIndex) {
      case 0:
        return (
          <Grid
            container
            item
            xs={12}
            direction={"row"}
            justify={"center"}
            spacing={2}
          >
            <Grid item xs={10} md={4}>
              <TextField
                label="Role Name"
                className={classes.textField}
                onChange={this.onRoleNameChange}
                value={this.state.data.role_name}
                fullWidth
                variant="outlined"
                margin="dense"
                error={this.state.errors.role_name ? true : false}
                InputLabelProps={{ classes: { root: classes.textLabel } }}
                InputProps={{ classes: { root: classes.textValue } }}
              />
              <FormHelperText style={{ color: "red" }}>
                {this.state.errors.role_name}
              </FormHelperText>
            </Grid>
            <Grid item xs={10} md={4}>
              <TextField
                id="outlined-basic"
                label="User Type"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                className={classes.textField}
                select="true"
                onChange={this.onUserTypeChange}
                value={this.state.data.userTypeId.id}
                error={this.state.errors.user_type ? true : false}
                InputLabelProps={{
                  classes: { root: classes.textLabel },
                }}
                InputProps={{ classes: { root: classes.textValue } }}
                SelectProps={{
                  IconComponent: DownArrow,
                  classes: {
                    icon: classes.dropIcon,
                    iconOpen: classes.dropIconOpen,
                  },
                }}
              >
                <MenuItem value="" classes={{ root: classes.textMenu }}>
                  <em>None</em>
                </MenuItem>
                {this.state.userTypeList !== "" ||
                this.state.userTypeList !== undefined
                  ? this.state.userTypeList.map((data) => (
                      <MenuItem
                        value={data.id}
                        classes={{ root: classes.textMenu }}
                      >
                        {data.user_type}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
              <FormHelperText style={{ color: "red" }}>
                {this.state.errors.user_type}
              </FormHelperText>
            </Grid>
          </Grid>
        );
      case 1:
        const printMainMenu = (data, i) => (
          <>
            <TableCell component="th" scope="row" className={classes.tableCell}>
              {data.title === "Dashboard" ? (
                <DashIcon />
              ) : data.title === "Master Setup" ? (
                <MastIcon />
              ) : data.title === "Live Data" ? (
                <LiveIcon />
              ) : data.title === "Alerts" ? (
                <AlertIcon />
              ) : data.title === "Warnings" ? (
                <WarnIcon />
              ) : data.title === "Reviews" ? (
                <ReviewIcon />
              ) : data.title === "History" ? (
                <HistIcon />
              ) : data.title === "Reports" ? (
                <RepoIcon />
              ) : null}
              &nbsp; &nbsp; &nbsp;
              {data.title}
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Checkbox
                name="addStatus"
                id="addStatus"
                checked={this.state.data.permissions[i].permissions.add_status}
                value={!this.state.data.permissions[i].permissions.add_status}
                onChange={(value) => {
                  this.handleCheckbox(value, data.menu_id);
                }}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Checkbox
                name="editStatus"
                id="editStatus"
                checked={this.state.data.permissions[i].permissions.edit_status}
                value={!this.state.data.permissions[i].permissions.edit_status}
                onChange={(value) => {
                  this.handleCheckbox(value, data.menu_id);
                }}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Checkbox
                // checked={this.state.checkedA}
                name="deleteStatus"
                id="deleteStatus"
                checked={
                  this.state.data.permissions[i].permissions.delete_status
                }
                value={
                  !this.state.data.permissions[i].permissions.delete_status
                }
                onChange={(value) => {
                  this.handleCheckbox(value, data.menu_id);
                }}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Checkbox
                name="viewStatus"
                id="viewStatus"
                checked={this.state.data.permissions[i].permissions.view_status}
                value={!this.state.data.permissions[i].permissions.view_status}
                onChange={(value) => {
                  this.handleCheckbox(value, data.menu_id);
                }}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </TableCell>
          </>
        );
        const printSubMenu = (data, i, j, menu_id) => (
          <>
            <TableCell
              style={{ paddingLeft: "82px" }}
              className={classes.tableCell}
            >
              {data.title}
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Checkbox
                name="addStatus"
                id="addStatus"
                checked={
                  this.state.data.permissions[j].sub_menus[i].permissions
                    .add_status
                }
                value={
                  !this.state.data.permissions[j].sub_menus[i].permissions
                    .add_status
                }
                onChange={(value) => {
                  this.handleCheckboxSubMenu(value, data.menu_id, i, j);
                }}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Checkbox
                name="editStatus"
                id="editStatus"
                checked={
                  this.state.data.permissions[j].sub_menus[i].permissions
                    .edit_status
                }
                value={
                  !this.state.data.permissions[j].sub_menus[i].permissions
                    .edit_status
                }
                onChange={(value) => {
                  this.handleCheckboxSubMenu(value, data.menu_id, i, j);
                }}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Checkbox
                // checked={this.state.checkedA}
                name="deleteStatus"
                id="deleteStatus"
                checked={
                  this.state.data.permissions[j].sub_menus[i].permissions
                    .delete_status
                }
                value={
                  !this.state.data.permissions[j].sub_menus[i].permissions
                    .delete_status
                }
                onChange={(value) => {
                  this.handleCheckboxSubMenu(value, data.menu_id, i, j);
                }}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Checkbox
                name="viewStatus"
                id="viewStatus"
                checked={
                  this.state.data.permissions[j].sub_menus[i].permissions
                    .view_status
                }
                value={
                  !this.state.data.permissions[j].sub_menus[i].permissions
                    .view_status
                }
                onChange={(value) => {
                  this.handleCheckboxSubMenu(value, data.menu_id, i, j);
                }}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </TableCell>
          </>
        );
        return (
          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              align="center"
            >
              <EnhancedTableHead rowCount={10} />

              {/* {this.state.data.permissions.map((data, i) => printData(data, i))} */}
              <TableBody>
                {this.state.data.permissions.map((data, i) => (
                  <>
                    {data.sub_menus.length === 0 ? (
                      <TableRow key={i} className={classes.zebrafont}>
                        {printMainMenu(data, i)}
                      </TableRow>
                    ) : (
                      <>
                        <TableRow
                          tabIndex={-1}
                          key={i}
                          className={classes.zebrafont}
                        >
                          {printMainMenu(data, i)}
                        </TableRow>

                        {data.sub_menus.map((subData, j) => (
                          <>
                            <TableRow
                              tabIndex={-1}
                              key={i}
                              className={classes.zebrafont}
                            >
                              {printSubMenu(subData, j, i, data.title)}
                            </TableRow>
                          </>
                        ))}
                      </>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
          </div>
        );
      case 2:
        return (
          <>
            {this.props.error.message === "Role already exist" ? (
              <Grid container justify={"center"}>
                <Grid item>
                  <h2 style={{ color: "red" }}>Role already exist !!!!</h2>
                </Grid>
              </Grid>
            ) : (
              <Grid container justify={"center"}>
                <Grid item>
                  <h2 style={{ color: "Green" }}>Successfully Added</h2>
                </Grid>
              </Grid>
            )}
          </>
        );
      default:
        return "Unknown stepIndex";
    }
  }
  render() {
    const { classes } = this.props;
    const steps = this.getSteps();

    return (
      <Grid className={classes.grid}>
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            onBackButtonClick={this.onBackButtonClick}
            header={"Add Role Master"}
          />
          <div className={classes.textFielddes}>
            <Fragment>
              <div className={classes.stepperDiv}>
                <Grid container spacing={24}>
                  <Grid item xs={12}>
                    <Stepper
                      activeStep={this.state.activeStep}
                      alternativeLabel
                      connector={<ColorlibConnector />}
                      classes={{ root: classes.stepperRoot }}
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel StepIconComponent={ColorlibStepIcon}>
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                </Grid>
              </div>

              {this.state.activeStep === steps.length ? (
                this.props.navigateTo("/role-master")
              ) : (
                <div style={{ paddingBottom: "32px" }}>
                  <div className={classes.instructions}>
                    {this.getStepContent(this.state.activeStep)}
                  </div>
                  <Grid
                    container
                    item
                    xs={12}
                    direction={"row"}
                    justify={"center"}
                    spacing={2}
                    style={{ paddingTop: "30px" }}
                  >
                    <Grid
                      item
                      xs={4}
                      md={5}
                      container
                      justify="space-between"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={10}
                        md={this.state.activeStep == 0 ? 6 : 4}
                        className={
                          this.state.activeStep == 0
                            ? classes.btnAlignRight
                            : classes.btnAlignCenter
                        }
                      >
                        <Button
                          disableElevation={true}
                          elevation={0}
                          className={classes.closeButton}
                          onClick={this.handleClearAllBase}
                        >
                          Clear
                        </Button>
                      </Grid>
                      {this.state.activeStep == 1 ? (
                        <Grid
                          item
                          xs={12}
                          md={this.state.activeStep == 0 ? 6 : 4}
                          style={{ textAlign: "center" }}
                        >
                          <Button
                            className={classes.closeButton}
                            disabled={this.state.activeStep === 0}
                            onClick={this.handleBack}
                          >
                            Back
                          </Button>
                        </Grid>
                      ) : null}

                      <Grid
                        item
                        xs={10}
                        md={this.state.activeStep == 0 ? 6 : 4}
                        className={
                          this.state.activeStep == 0
                            ? classes.btnAlignLeft
                            : classes.btnAlignCenter
                        }
                      >
                        <Button
                          disableElevation={true}
                          elevation={0}
                          className={classes.button}
                          variant="contained"
                          color="secondary"
                          onClick={this.handleNext}
                        >
                          {this.state.activeStep === steps.length - 1
                            ? "Submit"
                            : "Next"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* </div> */}
                  {/* <Recaptcha
                    ref={(ref) => (this.recaptcha = ref)}
                    sitekey={CaptchaSiteKeyMasters}
                    size="invisible"
                    onResolved={this.onResolved}
                  /> */}
                </div>
              )}
            </Fragment>
          </div>
        </Paper>
      </Grid>
    );
  }
}
export default withStyles(styles)(addRole);
