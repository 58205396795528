import APIRequest from "../util/api/APIRequest";

class SvgCaptchaService {
  getSvgCaptcha = () => {
    return APIRequest({
      url: `captcha`,
      method: "GET",
    });
  };
}

export default SvgCaptchaService;
