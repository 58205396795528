import { push } from "connected-react-router";
import { withRouter } from "react-router";
import DesignationMasterDetails from "./designationMasterDetails";
import { connect } from "react-redux";
import {
  addDesignationMaster,
  fetchDesignationMaster,
  editDesignationMaster,
  resetDesignationMasterDetail,
  fetchDesignationDetails,
  deleteDesignationMaster,
} from "./data/action";
import { snackbarActions } from "components/snackbar/data/action";

function mapStateToProps(state) {
  return {
    singleDesignation: state.designationMaster.singleDesignation,
    saveSuccess: state.designationMaster.saveSuccess,
    // saveSuccess: state.designationMasterDetails.saveSuccess,
    // message: state.designationMasterDetails.message,
    // error: state.designationMasterDetails.error,
    // designationMasters: state.designationMasterDetails.designationMasters,
    // fetchDesignationMaster:
    //   state.designationMasterDetails.fetchDesignationMaster,
  };
}

function mapDispatchToProps(dispach) {
  return {
    navigateTo: (url) => dispach(push(url)),
    addDesignationMaster: (data) => dispach(addDesignationMaster(data)),
    fetchDesignationDetails: (id) => dispach(fetchDesignationDetails(id)),
    deleteDesignationMaster: (id) => dispach(deleteDesignationMaster(id)),
    fetchDesignationMaster: () => dispach(fetchDesignationMaster()),
    editDesignationMaster: (data, id) =>
      dispach(editDesignationMaster(data, id)),

    resetDesignationMasterDetail: () => dispach(resetDesignationMasterDetail()),
    showSnackbar: (message, type) =>
      dispach(snackbarActions.showSnackbar(message, type)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DesignationMasterDetails)
);
