import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { BASE_URL } from "../../../../apiConfig";
import DummyImg from "./dummy_image.jpg";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    background: "FFFFFF 0% 0% no-repeat padding-box",
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: "107px",
    overflow: "hidden",
    display: "flex",
    width: "100%",
    margin: "auto",
    // background: "#110F1D 0% 0% no-repeat padding-box",
  },
  mobileStepper: {
    // marginTop: "-10px"
    paddingTop: "0px",
    paddingBottom: "0px",
    backgroundColor: "black",
  },
});

class PhotoStepper extends React.Component {
  state = {
    activeStep: 0,
  };

  componentDidMount() {
    /* setTimeout(() => {
      this.setState({ activeStep: this.state.activeStep == 1 ? 0 : 1 });
    }, 5000); */
  }
  componentDidUpdate() {
    /* setTimeout(() => {
      this.setState({ activeStep: this.state.activeStep == 1 ? 0 : 1 });
    }, 5000); */
  }
  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  render() {
    const { classes, theme } = this.props;
    const { activeStep } = this.state;
    const tutorialSteps = [
      {
        label: "full_image",
        imgPath: this.props.record.full_image,
      },
      {
        label: "cropped_image",
        imgPath: this.props.record.cropped_image,
      },
      {
        label: "evidence_image",
        imgPath: this.props.record.evidence_image,
      },
    ];
    const maxSteps = tutorialSteps.length;
    return (
      <div className={classes.root}>
        <img
          className={classes.img}
          src={tutorialSteps[activeStep].imgPath}
          alt={tutorialSteps[activeStep].label}
        />
        <MobileStepper
          steps={maxSteps}
          variant="dots"
          steps={3}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={
            <Button
              variant="fab"
              mini
              aria-label="Add"
              onClick={this.handleNext}
              className={classes.button}
              disabled={activeStep === maxSteps - 1}
              style={{ color: "#ffff" }}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              variant="fab"
              mini
              aria-label="Add"
              onClick={this.handleBack}
              className={classes.button}
              disabled={activeStep === 0}
              style={{ color: "#ffff" }}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
          }
        />
      </div>
    );
  }
}

PhotoStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PhotoStepper);
