import {
  GET_WARNING_DATA,
  GET_MULTIPLE_EWAYBILL,
  GET_FLAMMABLE_GOODS,
  GET_CAMERA_LOCATION,
  GET_MESSAGE_DATA,
  GET_OVERVIEW_DATA,
} from "./constants";

export const getCameraLocation = () => {
  return (dispatch) => {
    dispatch(getCameraLocation());
  };
  function getCameraLocation() {
    return { type: GET_CAMERA_LOCATION };
  }
};

export const getWarningCount = (socket) => {
  return (dispatch) => {
    socket.on("habitualOffendersCount", (res) => {
      dispatch(vehicle(res));
    });
  };
  function vehicle(res) {
    return { type: GET_WARNING_DATA, data: res };
  }
};

export const getMultipleEwaybillCount = (socket) => {
  return (dispatch) => {
    socket.on("multipleEwaybillCount_prod", (res) => {
      dispatch(vehicle(res));
    });
  };
  function vehicle(res) {
    return { type: GET_MULTIPLE_EWAYBILL, data: res };
  }
};

export const getFlammableGoodsCount = (socket) => {
  return (dispatch) => {
    socket.on("flammableVehiclesCount", (res) => {
      dispatch(vehicle(res));
    });
  };
  function vehicle(res) {
    return { type: GET_FLAMMABLE_GOODS, data: res };
  }
};
