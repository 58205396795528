import React, { Fragment } from "react";
import {
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  TextField,
  FormControl,
  FormHelperText,
  IconButton,
  Divider,
} from "@material-ui/core";
import classNames from "classnames";
import propTypes from "prop-types";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import validator from "../../../services/validator";
import { find } from "lodash";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { editDuty, fetchDuty, resetDuties } from "./data/action";
import { snackbarActions } from "components/snackbar/data/action";
// import Recaptcha from "react-google-invisible-recaptcha";
// import CaptchaSiteKeyMasters from "../../../services/siteKeyMasters";
import { ReactComponent as DateTime } from "../../../assets/Visual Assets/date.svg";
import { ReactComponent as BackIcon } from "../../../assets/SvgIcons/back.svg";
const toolbarStyles = (theme) => ({
  root: {
    marginTop: "11px",
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "2px",
    marginBottom: "13px",
    marginLeft: "9px",
  },
  iconButton: {
    paddingLeft: "0px",
  },
  divider: {
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
});

let EnhancedTableToolbar = (props) => {
  const { classes } = props;
  return (
    <>
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          {" "}
          <IconButton
            onClick={(e) => props.onBackButtonClick(e)}
            classes={{ root: classes.iconButton }}
          >
            <BackIcon />
          </IconButton>
          Update Duty Master
        </Typography>
        <div className={classes.spacer} />
      </Toolbar>{" "}
      <Divider className={classes.divider} />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: propTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  grid: {
    marginTop: "26px",
    paddingTop: "17px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
    minHeight: "12vh",
    maxHeight: "75vh",
    overflowY: "auto",
  },
  root: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
    width: "100%",
    overflow: "hidden",
    paddingBottom: "34px",
  },

  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  textField: {
    width: "94%",
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "45px",
  },
  errorTextField: {
    width: "94%",
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "80px",
  },
  paperStyle: {
    paddingLeft: "33px",
    paddingRight: "14px",
    paddingBottom: "8px",
  },
  textFielddes: {
    paddingTop: "1%",
  },
  subHeading: {
    textAlign: "left",
    font: "normal normal bold 16px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    paddingBottom: "9px",
    paddingTop: "4px",
  },
  button: {
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    opacity: 1,
    textTransform: "none",
    // paddingLeft: "38px",
    // paddingRight: "37px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    height: "42px",
    width: "121px",
  },
  clearButton: {
    width: "121px",
    height: "42px",
    // marginRight: "10px",
    textTransform: "none",
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.colors.BlueButton,
    textAlign: "center",
    width: "121px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
  },
  cancelButton: {
    width: "120px",
    marginBottom: "6%",
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.whiteColor,
  },
  btnGrid: {
    padding: 0,
    paddingLeft: "12px",
  },
  btns: {
    paddingTop: "5px",
    paddingRight: "25%",
  },
  err: {
    paddingLeft: "1%",
  },
  dense: {
    marginTop: 16,
  },
  alignment: {
    flexDirection: "row",
  },
  radbtns: {
    paddingLeft: "5%",
  },
  formitems: {
    paddingTop: "6%",
  },
  menu: {
    width: 200,
    height: 300,
  },
  timeGrid: {
    paddingBottom: "65px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  popIconOpen: {
    marginTop: "4px",
    marginRight: "6px",
  },
  popIcon: {
    marginTop: "6px",
    marginRight: "6px",
  },
  textNoOptions: {
    letterSpacing: "0px",
    opacity: 1,
    fontSize: "18px",
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  formHelperText: {
    color: theme.colors.redColor,
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  subGrid: {
    paddingBottom: "6px",
  },
  subDateGrid: {
    paddingBottom: "18px",
  },
  mainGrid: { paddingBottom: "15px" },
  dateIcon: {
    marginTop: "0px",
    marginRight: "-8px",
  },
});
class UpdateDuty extends React.Component {
  constructor(props) {
    super(props);
    this.onResolved = this.onResolved.bind(this);

    this.state = {
      isVerified: false,
      captcha: "",

      dutyDetails: {},
      data: {
        district: "",
        squad: "",
        field: "",
        zone: "",
        from_date: null,
        to_date: null,
        in_time: null,
        out_time: null,
      },
      errors: {
        from_date: null,
        to_date: null,
        in_time: null,
        out_time: null,
      },
      rules: {
        from_date: {
          required: true,
          isDateGreaterCurrent: true,
          // isDateGreater: true,
        },
        to_date: {
          required: true,
          isDateGreaterCurrent: true,
          // isDateGreater: true,
        },
        in_time: {
          required: true,
        },
        out_time: {
          required: true,
        },
      },
    };
  }
  componentDidMount() {
    if (this.props.match.params.duty_id) {
      this.props.fetchDuty(this.props.match.params.duty_id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.duty_id &&
      this.props !== prevProps &&
      this.props.dutyDetails !== this.state.data.dutyDetails
    ) {
      const { dutyDetails } = this.props;

      this.setState(
        {
          dutyDetails: dutyDetails,
          data: {
            ...this.state.data,
            district: dutyDetails.squad_master
              ? dutyDetails.squad_master
                ? dutyDetails.squad_master.district_master
                  ? dutyDetails.squad_master.district_master.district_name
                  : null
                : null
              : "",
            squad: dutyDetails.squad_master
              ? dutyDetails.squad_master.squad_name
              : "",
            squad_id: dutyDetails.squad_master
              ? dutyDetails.squad_master.squad_id
              : "",
            zone: dutyDetails.squad_master
              ? dutyDetails.squad_master.zone_master
                ? dutyDetails.squad_master.zone_master.zone_name
                : ""
              : null,
            field: dutyDetails.squad_master
              ? dutyDetails.squad_master.location_master
                ? dutyDetails.squad_master.location_master.place
                : ""
              : null,

            from_date: moment(dutyDetails.from_date).format("YYYY-MM-DD"),
            to_date: moment(dutyDetails.to_date).format("YYYY-MM-DD"),
            in_time: dutyDetails.in_time,
            out_time: dutyDetails.out_time,
          },
        },
        () => {
          let in_time = moment(this.state.data.in_time, "HH:mm:ss");
          let out_time = moment(this.state.data.out_time, "HH:mm:ss");
          this.setState({
            data: {
              ...this.state.data,
              in_time: in_time,
              out_time: out_time,
            },
          });
        }
      );
    }

    if (
      prevProps.duties != this.props.duties &&
      this.props.saveSuccess &&
      this.props.statusmessage !== prevProps.statusmessage &&
      !this.props.error &&
      this.props.statusmessage !== "failed"
    ) {
      setTimeout(() => {
        this.props.showSnackbar(this.props.message, "success");
      }, 1000);
      this.props.navigateTo("/duty-master");
    }

    if (
      this.props.saveSuccess &&
      !this.props.error &&
      this.props.saveStaus !== prevProps.saveStaus &&
      this.props.statusmessage === "failed"
    ) {
      this.props.showSnackbar(this.props.message, "error");
    }
    if (this.props.error && this.props.error !== this.state.alert) {
      this.props.showSnackbar(this.props.message, "error");
    }
  }
  componentWillUnmount() {
    this.props.resetDuties();

    this.setState({
      ...this.state,
      dutyDetails: {},
    });
  }

  resetDutyParams = () => {
    this.setState({
      data: {
        ...this.state.data,
        captcha: this.state.captcha,
        from_date: null,
        to_date: null,
        in_time: null,
        out_time: null,
      },
      errors: {
        from_date: null,
        to_date: null,
        in_time: null,
        out_time: null,
      },
    });
    // this.props.resetDuties();
  };
  onBackButtonClick = () => {
    this.props.navigateTo("/duty-master");
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };
  handleChangeTime = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };
  validateDate = () => {
    let formIsValid = true;
    let from_date = moment(this.state.data.from_date).format("YYYY-MM-DD");
    let to_date = moment(this.state.data.to_date).format("YYYY-MM-DD");
    let current_date = moment(new Date()).format("YYYY-MM-DD");
    let in_time = this.state.data.in_time;
    let out_time = this.state.data.out_time;
    let current_time = moment(new Date()).format("HH:mm:ss");

    if (moment(from_date).isAfter(to_date)) {
      formIsValid = false;
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          to_date: "Date should be after from date",
        },
      });
    }
    if (
      moment(from_date).isSame(current_date) &&
      moment(in_time).format("HH:mm:ss") < current_time
    ) {
      formIsValid = false;
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          in_time: "Time should not less than current time",
        },
      });
    }

    if (
      moment(from_date).isSame(to_date) &&
      moment(in_time).isAfter(out_time)
    ) {
      formIsValid = false;
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          out_time: "Time should be after In time",
        },
      });
    }
    return formIsValid;
  };

  validate = () => {
    const { rules, data } = this.state;
    const errors = validator(rules)(data);
    const hasErrors = find(errors, (error) => error !== "");
    this.setState({ errors });
    return !hasErrors;
  };

  performAction = (event) => {
    // event.preventDefault();

    if (this.validate()) {
      if (this.validateDate()) {
        // this.recaptcha.execute();

        // this.setState({
        //   captcha: this.recaptcha.getResponse(),
        // });
        this.onResolved();
      }
    }
  };
  onResolved = (event) => {
    // this.setState({
    //   captcha: this.recaptcha.getResponse(),
    // });
    // event.preventDefault();
    let duty_id = this.props.match.params.duty_id;
    let params = {
      // captcha: this.state.captcha,
      from_date: moment(this.state.data.from_date).format("YYYY-MM-DD"),
      to_date: moment(this.state.data.to_date).format("YYYY-MM-DD"),
      in_time: moment(this.state.data.in_time).format("HH:mm:ss"),
      out_time: moment(this.state.data.out_time).format("HH:mm:ss"),
    };
    // console.log(params);

    this.props.editDuty(params, duty_id);
  };

  render() {
    const { data } = this.state;
    const { classes } = this.props;
    return (
      <Grid className={classes.grid}>
        <Paper className={classes.root}>
          <EnhancedTableToolbar onBackButtonClick={this.onBackButtonClick} />
          <div className={classes.textFielddes}>
            <Fragment>
              <div>
                <Paper elevation={0} className={classes.paperStyle}>
                  <Typography className={classes.subHeading}>
                    Basic Information
                  </Typography>
                  <Grid container className={classes.mainGrid}>
                    <Grid xs={12} md={4} item className={classes.subGrid}>
                      <TextField
                        label="District"
                        className={classes.textField}
                        value={data.district}
                        inputProps={{ readOnly: true }}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                        InputProps={{ classes: { root: classes.textValue } }}
                      />
                    </Grid>
                    <Grid xs={12} md={4} item className={classes.subGrid}>
                      <TextField
                        label="Squad "
                        className={classes.textField}
                        inputProps={{ readOnly: true }}
                        name="pen_number"
                        value={data.squad}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                        InputProps={{ classes: { root: classes.textValue } }}
                      />
                    </Grid>
                    <Grid xs={12} md={4} item className={classes.subGrid}>
                      <TextField
                        label="Zone"
                        className={classes.textField}
                        name="pen_number"
                        value={this.state.data.zone}
                        inputProps={{ readOnly: true }}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                        InputProps={{ classes: { root: classes.textValue } }}
                      />
                    </Grid>
                    <Grid xs={12} md={4} item>
                      <TextField
                        label="Field"
                        className={classes.textField}
                        name="pen_number"
                        value={this.state.data.field}
                        inputProps={{ readOnly: true }}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                        InputProps={{ classes: { root: classes.textValue } }}
                      />
                    </Grid>{" "}
                  </Grid>
                </Paper>
                <Paper elevation={0} className={classes.paperStyle}>
                  <Typography className={classes.subHeading}>
                    Duty Time
                  </Typography>
                  <Grid container className={classes.timeGrid}>
                    <Grid xs={12} md={4} item className={classes.subDateGrid}>
                      <FormControl error={true} className={classes.textField}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            fullWidth
                            required
                            label="From Date"
                            name="from_date"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="dense"
                            value={this.state.data.from_date}
                            onChange={(event) => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  from_date: event,
                                },
                                errors: {
                                  ...this.state.errors,
                                  from_date: "",
                                },
                              });
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                            keyboardIcon={
                              <DateTime className={classes.dateIcon} />
                            }
                            InputProps={{
                              classes: { root: classes.textValue },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <FormHelperText>
                          {this.state.errors.from_date}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={4} item className={classes.subDateGrid}>
                      <FormControl error={true} className={classes.textField}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          {" "}
                          <KeyboardTimePicker
                            margin="dense"
                            required
                            id="time-picker"
                            label="In Time"
                            inputVariant="outlined"
                            value={this.state.data.in_time}
                            onChange={(time) => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  in_time: time,
                                },
                                errors: {
                                  ...this.state.errors,
                                  in_time: "",
                                },
                              });
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change time",
                            }}
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                            keyboardIcon={
                              <DateTime className={classes.dateIcon} />
                            }
                            InputProps={{
                              classes: { root: classes.textValue },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <FormHelperText>
                          {this.state.errors.in_time}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={4} item className={classes.subDateGrid}>
                      <FormControl error={true} className={classes.textField}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            fullWidth
                            required
                            label="To Date"
                            name="to_date"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="dense"
                            value={this.state.data.to_date}
                            onChange={(event) => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  to_date: event,
                                },
                                errors: {
                                  ...this.state.errors,
                                  to_date: "",
                                },
                              });
                            }}
                            className={classes.textField}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                            keyboardIcon={
                              <DateTime className={classes.dateIcon} />
                            }
                            InputProps={{
                              classes: { root: classes.textValue },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <FormHelperText>
                          {this.state.errors.to_date}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={4} item>
                      <FormControl error={true} className={classes.textField}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          {" "}
                          <KeyboardTimePicker
                            margin="dense"
                            required
                            id="Out Time"
                            label="Out Time"
                            inputVariant="outlined"
                            value={this.state.data.out_time}
                            onChange={(time) => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  out_time: time,
                                },
                                errors: {
                                  ...this.state.errors,
                                  out_time: "",
                                },
                              });
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change time",
                            }}
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                            keyboardIcon={
                              <DateTime className={classes.dateIcon} />
                            }
                            InputProps={{
                              classes: { root: classes.textValue },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <FormHelperText>
                          {this.state.errors.out_time}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>{" "}
                </Paper>

                <Grid xs={12} className={classes.btns} container>
                  {/* <Grid item xs={12} md={2} className={classes.btnGrid}>
                    <Button
                      className={classes.cancelButton}
                      onClick={() => this.onBackButtonClick()}
                    >
                      CLOSE
                    </Button>
                  </Grid>{" "} */}
                  <Grid item xs={12} md={6}></Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <Button
                      disableElevation={true}
                      variant="contained"
                      className={classes.clearButton}
                      onClick={() => this.resetDutyParams()}
                    >
                      Clear
                    </Button>
                  </Grid>{" "}
                  <Grid item xs={12} md={3} className={classes.btnGrid}>
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation={true}
                      className={classes.button}
                      onClick={(e) => this.performAction(e)}
                    >
                      Update
                    </Button>
                    {/* <Recaptcha
                      ref={(ref) => (this.recaptcha = ref)}
                      sitekey={CaptchaSiteKeyMasters}
                      size="invisible"
                      onResolved={this.onResolved}
                    /> */}
                  </Grid>
                </Grid>
              </div>
            </Fragment>
          </div>
        </Paper>
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  return {
    dutyDetails: state.AssignDuty.dutyDetails,
    from_date: state.AssignDuty.from_date,
    to_date: state.AssignDuty.to_date,
    in_time: state.AssignDuty.in_time,
    out_time: state.AssignDuty.out_time,
    saveSuccess: state.AssignDuty.saveSuccess,
    message: state.AssignDuty.message,
    error: state.AssignDuty.error,
    duties: state.AssignDuty.duties,
    statusmessage: state.AssignDuty.statusmessage,
    saveStaus: state.AssignDuty.saveStaus,
  };
}

function mapDispatchToProps(dispach) {
  return {
    navigateTo: (url) => dispach(push(url)),
    fetchDuty: (id) => dispach(fetchDuty(id)),
    editDuty: (params, id) => dispach(editDuty(params, id)),
    resetDuties: () => dispach(resetDuties()),
    showSnackbar: (message, type) =>
      dispach(snackbarActions.showSnackbar(message, type)),
  };
}
const styledUpdateDuty = withStyles(styles)(UpdateDuty);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(styledUpdateDuty)
);

// import React, { Fragment } from "react";
// import {
//   Toolbar,
//   Typography,
//   withStyles,
//   Paper,
//   Grid,
//   Button,
//   TextField,
//   FormControl,
//   FormHelperText,
//   IconButton,
//   Divider,
// } from "@material-ui/core";
// import classNames from "classnames";
// import propTypes from "prop-types";
// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
// } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
// import moment from "moment";
// import validator from "../../../services/validator";
// import { find } from "lodash";
// import { push } from "connected-react-router";
// import { connect } from "react-redux";
// import { withRouter } from "react-router";
// import { editDuty, fetchDuty, resetDuties } from "./data/action";
// import { snackbarActions } from "components/snackbar/data/action";
// import Recaptcha from "react-google-invisible-recaptcha";
// import CaptchaSiteKeyMasters from "../../../services/siteKeyMasters";
// import { ReactComponent as DateTime } from "../../../assets/Visual Assets/date.svg";
// import { ReactComponent as BackIcon } from "../../../assets/SvgIcons/back.svg";
// const toolbarStyles = (theme) => ({
//   root: {
//     marginTop: "11px",
//   },
//   spacer: {
//     flex: "1 1 20%",
//   },
//   actions: {
//     paddingRight: theme.spacing.unit,
//   },
//   title: {
//     flex: "0 0 auto",
//   },
//   tableTitle: {
//     textAlign: "left",
//     font: "normal normal bold 18px Karla",
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//     marginTop: "2px",
//     marginBottom: "13px",
//     marginLeft: "9px",
//   },
//   iconButton: {
//     paddingLeft: "0px",
//   },
//   divider: {
//     border: "1px solid #E7EBF4",
//     opacity: 1,
//   },
// });

// let EnhancedTableToolbar = (props) => {
//   const { classes } = props;
//   return (
//     <>
//       <Toolbar className={classNames(classes.root)}>
//         <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
//           {" "}
//           <IconButton
//             onClick={(e) => props.onBackButtonClick(e)}
//             classes={{ root: classes.iconButton }}
//           >
//             <BackIcon />
//           </IconButton>
//           Update Duty Master
//         </Typography>
//         <div className={classes.spacer} />
//       </Toolbar>{" "}
//       <Divider className={classes.divider} />
//     </>
//   );
// };

// EnhancedTableToolbar.propTypes = {
//   classes: propTypes.object.isRequired,
// };

// EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

// const styles = (theme) => ({
//   grid: {
//     marginTop: "26px",
//     paddingTop: "17px",
//     paddingRight: "18px",
//     paddingLeft: "22px",
//     borderRadius: theme.border.radius,
//   },
//   root: {
//     background: "#FFFFFF 0% 0% no-repeat padding-box",
//     boxShadow: "0px 0px 4px #0000000D",
//     opacity: 1,
//     borderRadius: theme.border.radius,
//     width: "100%",
//     overflow: "hidden",
//     paddingBottom: "34px",
//   },

//   table: {
//     minWidth: 1020,
//   },
//   tableWrapper: {
//     overflowX: "auto",
//   },
//   textField: {
//     width: "94%",
//     textAlign: "left",
//     font: "normal normal normal 16px Karla",
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//     height: "45px",
//   },
//   errorTextField: {
//     width: "94%",
//     textAlign: "left",
//     font: "normal normal normal 16px Karla",
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//     height: "80px",
//   },
//   paperStyle: {
//     paddingLeft: "33px",
//     paddingRight: "14px",
//     paddingBottom: "8px",
//   },
//   textFielddes: {
//     paddingTop: "1%",
//   },
//   subHeading: {
//     textAlign: "left",
//     font: "normal normal bold 16px Karla",
//     letterSpacing: "0px",
//     color: theme.colors.chartHeading,
//     opacity: 1,
//     paddingBottom: "9px",
//     paddingTop: "4px",
//   },
//   button: {
//     fontSize: theme.font.size3,
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//     letterSpacing: "0px",
//     backgroundColor: theme.palette.secondary.main,
//     color: "white",
//     opacity: 1,
//     textTransform: "none",
//     // paddingLeft: "38px",
//     // paddingRight: "37px",
//     background: "#1BB99A 0% 0% no-repeat padding-box",
//     height: "42px",
//     width: "121px",
//   },
//   clearButton: {
//     width: "121px",
//     height: "42px",
//     // marginRight: "10px",
//     textTransform: "none",
//     fontSize: theme.font.size3,
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//     letterSpacing: "0px",
//     backgroundColor: theme.colors.BlueButton,
//     textAlign: "center",
//     width: "121px",
//     background: "#E7EBF4 0% 0% no-repeat padding-box",
//   },
//   cancelButton: {
//     width: "120px",
//     marginBottom: "6%",
//     fontWeight: theme.font.fontWeight,
//     backgroundColor: theme.colors.whiteColor,
//   },
//   btnGrid: {
//     padding: 0,
//     paddingLeft: "12px",
//   },
//   btns: {
//     paddingTop: "5px",
//     paddingRight: "25%",
//   },
//   err: {
//     paddingLeft: "1%",
//   },
//   dense: {
//     marginTop: 16,
//   },
//   alignment: {
//     flexDirection: "row",
//   },
//   radbtns: {
//     paddingLeft: "5%",
//   },
//   formitems: {
//     paddingTop: "6%",
//   },
//   menu: {
//     width: 200,
//     height: 300,
//   },
//   timeGrid: {
//     paddingBottom: "65px",
//   },
//   textValue: {
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//     fontSize: theme.font.size1,
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//   },
//   popIconOpen: {
//     marginTop: "4px",
//     marginRight: "6px",
//   },
//   popIcon: {
//     marginTop: "6px",
//     marginRight: "6px",
//   },
//   textNoOptions: {
//     letterSpacing: "0px",
//     opacity: 1,
//     fontSize: "18px",
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//   },
//   formHelperText: {
//     color: theme.colors.redColor,
//   },
//   textLabel: {
//     letterSpacing: "0px",
//     color: "#797C82",
//     opacity: 1,
//     fontSize: theme.font.size3,
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//     padding: "0px",
//     margin: "0px",
//   },
//   subGrid: {
//     paddingBottom: "6px",
//   },
//   subDateGrid: {
//     paddingBottom: "18px",
//   },
//   mainGrid: { paddingBottom: "15px" },
//   dateIcon: {
//     marginTop: "0px",
//     marginRight: "-8px",
//   },
// });

// class UpdateDuty extends React.Component {
//   constructor(props) {
//     super(props);
//     this.onResolved = this.onResolved.bind(this);

//     this.state = {
//       isVerified: true,
//       captcha: "",

//       dutyDetails: {},
//       data: {
//         district: "",
//         squad: "",
//         field: "",
//         zone: "",
//         from_date: null,
//         to_date: null,
//         in_time: null,
//         out_time: null,
//       },
//       errors: {
//         from_date: null,
//         to_date: null,
//         in_time: null,
//         out_time: null,
//       },
//       rules: {
//         from_date: {
//           required: true,
//           isDateGreaterCurrent: true,
//           // isDateGreater: true,
//         },
//         to_date: {
//           required: true,
//           isDateGreaterCurrent: true,
//           // isDateGreater: true,
//         },
//         in_time: {
//           required: true,
//         },
//         out_time: {
//           required: true,
//         },
//       },
//     };
//   }
//   componentDidMount() {
//     if (this.props.match.params.duty_id) {
//       this.props.fetchDuty(this.props.match.params.duty_id);
//     }
//   }

//   componentDidUpdate(prevProps) {
//     // this.recaptcha.execute();

//     if (
//       this.props.match.params.duty_id &&
//       this.props !== prevProps &&
//       this.props.dutyDetails !== this.state.data.dutyDetails
//     ) {
//       const { dutyDetails } = this.props;

//       this.setState(
//         {
//           dutyDetails: dutyDetails,
//           data: {
//             ...this.state.data,
//             district: dutyDetails.squad_master
//               ? dutyDetails.squad_master
//                 ? dutyDetails.squad_master.district_master
//                   ? dutyDetails.squad_master.district_master.district_name
//                   : null
//                 : null
//               : "",
//             squad: dutyDetails.squad_master
//               ? dutyDetails.squad_master.squad_name
//               : "",
//             squad_id: dutyDetails.squad_master
//               ? dutyDetails.squad_master.squad_id
//               : "",
//             zone: dutyDetails.squad_master
//               ? dutyDetails.squad_master.zone_master
//                 ? dutyDetails.squad_master.zone_master.zone_name
//                 : ""
//               : null,
//             field: dutyDetails.squad_master
//               ? dutyDetails.squad_master.location_master
//                 ? dutyDetails.squad_master.location_master.place
//                 : ""
//               : null,

//             from_date: moment(dutyDetails.from_date).format("YYYY-MM-DD"),
//             to_date: moment(dutyDetails.to_date).format("YYYY-MM-DD"),
//             in_time: dutyDetails.in_time,
//             out_time: dutyDetails.out_time,
//           },
//         },
//         () => {
//           let in_time = moment(this.state.data.in_time, "HH:mm:ss");
//           let out_time = moment(this.state.data.out_time, "HH:mm:ss");
//           this.setState({
//             data: {
//               ...this.state.data,
//               in_time: in_time,
//               out_time: out_time,
//             },
//           });
//         }
//       );
//     }

//     if (
//       prevProps.duties != this.props.duties &&
//       this.props.saveSuccess &&
//       this.props.statusmessage !== prevProps.statusmessage &&
//       !this.props.error &&
//       this.props.statusmessage !== "failed"
//     ) {
//       setTimeout(() => {
//         this.props.showSnackbar(this.props.message, "success");
//       }, 1000);
//       this.props.navigateTo("/duty-master");
//     }

//     if (
//       this.props.saveSuccess &&
//       !this.props.error &&
//       this.props.saveStaus !== prevProps.saveStaus &&
//       this.props.statusmessage === "failed"
//     ) {
//       this.props.showSnackbar(this.props.message, "error");
//     }
//     if (this.props.error && this.props.error !== this.state.alert) {
//       this.props.showSnackbar(this.props.message, "error");
//     }
//   }
//   componentWillUnmount() {
//     this.props.resetDuties();

//     this.setState({
//       ...this.state,
//       dutyDetails: {},
//     });
//   }
//   onResolved = () => {
//     this.setState({
//       isVerified: true,
//       captcha: this.recaptcha.getResponse(),
//     });
//   };
//   resetDutyParams = () => {
//     this.setState({
//       data: {
//         ...this.state.data,
//         captcha: this.state.captcha,
//         from_date: null,
//         to_date: null,
//         in_time: null,
//         out_time: null,
//       },
//       errors: {
//         from_date: null,
//         to_date: null,
//         in_time: null,
//         out_time: null,
//       },
//     });
//     // this.props.resetDuties();
//   };
//   onBackButtonClick = () => {
//     this.props.navigateTo("/duty-master");
//   };

//   handleChange = (event) => {
//     this.setState({
//       data: { ...this.state.data, [event.target.name]: event.target.value },
//       errors: {
//         ...this.state.errors,
//         [event.target.name]: "",
//       },
//     });
//   };
//   handleChangeTime = (event) => {
//     this.setState({
//       data: { ...this.state.data, [event.target.name]: event.target.value },
//       errors: {
//         ...this.state.errors,
//         [event.target.name]: "",
//       },
//     });
//   };
//   validateDate = () => {
//     let formIsValid = true;
//     let from_date = moment(this.state.data.from_date).format("YYYY-MM-DD");
//     let to_date = moment(this.state.data.to_date).format("YYYY-MM-DD");
//     let current_date = moment(new Date()).format("YYYY-MM-DD");
//     let in_time = this.state.data.in_time;
//     let out_time = this.state.data.out_time;
//     let current_time = moment(new Date()).format("HH:mm:ss");

//     if (moment(from_date).isAfter(to_date)) {
//       formIsValid = false;
//       this.setState({
//         ...this.state,
//         errors: {
//           ...this.state.errors,
//           to_date: "Date should be after from date",
//         },
//       });
//     }
//     if (
//       moment(from_date).isSame(current_date) &&
//       moment(in_time).format("HH:mm:ss") < current_time
//     ) {
//       formIsValid = false;
//       this.setState({
//         ...this.state,
//         errors: {
//           ...this.state.errors,
//           in_time: "Time should not less than current time",
//         },
//       });
//     }

//     if (
//       moment(from_date).isSame(to_date) &&
//       moment(in_time).isAfter(out_time)
//     ) {
//       formIsValid = false;
//       this.setState({
//         ...this.state,
//         errors: {
//           ...this.state.errors,
//           out_time: "Time should be after In time",
//         },
//       });
//     }
//     return formIsValid;
//   };

//   validate = () => {
//     const { rules, data } = this.state;
//     const errors = validator(rules)(data);
//     const hasErrors = find(errors, (error) => error !== "");
//     this.setState({ errors });
//     return !hasErrors;
//   };

//   performAction = (event) => {
//     this.recaptcha.execute();

//     if (this.state.isVerified) {
//       event.preventDefault();
//       let duty_id = this.props.match.params.duty_id;
//       let params = {
//         captcha: this.state.captcha,
//         from_date: moment(this.state.data.from_date).format("YYYY-MM-DD"),
//         to_date: moment(this.state.data.to_date).format("YYYY-MM-DD"),
//         in_time: moment(this.state.data.in_time).format("HH:mm:ss"),
//         out_time: moment(this.state.data.out_time).format("HH:mm:ss"),
//       };
//       if (this.validate()) {
//         if (this.validateDate()) {
//           this.props.editDuty(params, duty_id);
//         }
//       }
//     } else {
//       this.recaptcha.execute();
//     }
//   };

//   render() {
//     const { data, errors } = this.state;
//     const { classes } = this.props;
//     return (
//       <Grid className={classes.grid}>
//         <Paper className={classes.root}>
//           <EnhancedTableToolbar onBackButtonClick={this.onBackButtonClick} />
//           <div className={classes.textFielddes}>
//             <Fragment>
//               <div>
//                 <Paper elevation={0} className={classes.paperStyle}>
//                   <Typography className={classes.subHeading}>
//                     Basic Information
//                   </Typography>
//                   <Grid container className={classes.mainGrid}>
//                     <Grid xs={12} md={4} item className={classes.subGrid}>
//                       <TextField
//                         label="District"
//                         className={classes.textField}
//                         value={data.district}
//                         inputProps={{ readOnly: true }}
//                         margin="dense"
//                         fullWidth
//                         variant="outlined"
//                         InputLabelProps={{
//                           classes: { root: classes.textLabel },
//                         }}
//                         InputProps={{ classes: { root: classes.textValue } }}
//                       />
//                     </Grid>
//                     <Grid xs={12} md={4} item className={classes.subGrid}>
//                       <TextField
//                         label="Squad "
//                         className={classes.textField}
//                         inputProps={{ readOnly: true }}
//                         name="pen_number"
//                         value={data.squad}
//                         margin="dense"
//                         fullWidth
//                         variant="outlined"
//                         InputLabelProps={{
//                           classes: { root: classes.textLabel },
//                         }}
//                         InputProps={{ classes: { root: classes.textValue } }}
//                       />
//                     </Grid>
//                     <Grid xs={12} md={4} item className={classes.subGrid}>
//                       <TextField
//                         label="Zone"
//                         className={classes.textField}
//                         name="pen_number"
//                         value={this.state.data.zone}
//                         inputProps={{ readOnly: true }}
//                         margin="dense"
//                         fullWidth
//                         variant="outlined"
//                         InputLabelProps={{
//                           classes: { root: classes.textLabel },
//                         }}
//                         InputProps={{ classes: { root: classes.textValue } }}
//                       />
//                     </Grid>
//                     <Grid xs={12} md={4} item>
//                       <TextField
//                         label="Field"
//                         className={classes.textField}
//                         name="pen_number"
//                         value={this.state.data.field}
//                         inputProps={{ readOnly: true }}
//                         margin="dense"
//                         fullWidth
//                         variant="outlined"
//                         InputLabelProps={{
//                           classes: { root: classes.textLabel },
//                         }}
//                         InputProps={{ classes: { root: classes.textValue } }}
//                       />
//                     </Grid>{" "}
//                   </Grid>
//                 </Paper>
//                 <Paper elevation={0} className={classes.paperStyle}>
//                   <Typography className={classes.subHeading}>
//                     Duty Time
//                   </Typography>
//                   <Grid container className={classes.timeGrid}>
//                     <Grid xs={12} md={4} item>
//                       <FormControl
//                         error={true}
//                         className={
//                           errors.from_date
//                             ? classes.errorTextField
//                             : classes.textField
//                         }
//                       >
//                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                           <KeyboardDatePicker
//                             fullWidth
//                             required
//                             label="From Date"
//                             name="from_date"
//                             inputVariant="outlined"
//                             format="dd/MM/yyyy"
//                             margin="dense"
//                             value={this.state.data.from_date}
//                             error={errors.from_date ? true : false}
//                             onChange={(event) => {
//                               this.setState({
//                                 data: {
//                                   ...this.state.data,
//                                   from_date: event,
//                                 },
//                                 errors: {
//                                   ...this.state.errors,
//                                   from_date: "",
//                                 },
//                               });
//                             }}
//                             KeyboardButtonProps={{
//                               "aria-label": "change date",
//                             }}
//                             InputLabelProps={{
//                               classes: { root: classes.textLabel },
//                             }}
//                             keyboardIcon={
//                               <DateTime className={classes.dateIcon} />
//                             }
//                             InputProps={{
//                               classes: { root: classes.textValue },
//                             }}
//                           />
//                         </MuiPickersUtilsProvider>
//                         <FormHelperText>
//                           {this.state.errors.from_date}
//                         </FormHelperText>
//                       </FormControl>
//                     </Grid>
//                     <Grid xs={12} md={4} item className={classes.subDateGrid}>
//                       <FormControl error={true} className={classes.textField}>
//                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                           {" "}
//                           <KeyboardTimePicker
//                             margin="dense"
//                             required
//                             id="time-picker"
//                             label="In Time"
//                             inputVariant="outlined"
//                             value={this.state.data.in_time}
//                             error={errors.in_time ? true : false}
//                             onChange={(time) => {
//                               this.setState({
//                                 data: {
//                                   ...this.state.data,
//                                   in_time: time,
//                                 },
//                                 errors: {
//                                   ...this.state.errors,
//                                   in_time: "",
//                                 },
//                               });
//                             }}
//                             KeyboardButtonProps={{
//                               "aria-label": "change time",
//                             }}
//                             InputLabelProps={{
//                               classes: { root: classes.textLabel },
//                             }}
//                             keyboardIcon={
//                               <DateTime className={classes.dateIcon} />
//                             }
//                             InputProps={{
//                               classes: { root: classes.textValue },
//                             }}
//                           />
//                         </MuiPickersUtilsProvider>
//                         <FormHelperText>
//                           {this.state.errors.in_time}
//                         </FormHelperText>
//                       </FormControl>
//                     </Grid>
//                     <Grid xs={12} md={4} item className={classes.subDateGrid}>
//                       <FormControl error={true} className={classes.textField}>
//                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                           <KeyboardDatePicker
//                             fullWidth
//                             required
//                             label="To Date"
//                             name="to_date"
//                             inputVariant="outlined"
//                             format="dd/MM/yyyy"
//                             margin="dense"
//                             value={this.state.data.to_date}
//                             error={errors.to_date ? true : false}
//                             onChange={(event) => {
//                               this.setState({
//                                 data: {
//                                   ...this.state.data,
//                                   to_date: event,
//                                 },
//                                 errors: {
//                                   ...this.state.errors,
//                                   to_date: "",
//                                 },
//                               });
//                             }}
//                             className={classes.textField}
//                             KeyboardButtonProps={{
//                               "aria-label": "change date",
//                             }}
//                             InputLabelProps={{
//                               classes: { root: classes.textLabel },
//                             }}
//                             keyboardIcon={
//                               <DateTime className={classes.dateIcon} />
//                             }
//                             InputProps={{
//                               classes: { root: classes.textValue },
//                             }}
//                           />
//                         </MuiPickersUtilsProvider>
//                         <FormHelperText>
//                           {this.state.errors.to_date}
//                         </FormHelperText>
//                       </FormControl>
//                     </Grid>

//                     <Grid xs={12} md={4} item>
//                       <FormControl error={true} className={classes.textField}>
//                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                           {" "}
//                           <KeyboardTimePicker
//                             margin="dense"
//                             required
//                             id="Out Time"
//                             label="Out Time"
//                             inputVariant="outlined"
//                             value={this.state.data.out_time}
//                             error={errors.out_time ? true : false}
//                             onChange={(time) => {
//                               this.setState({
//                                 data: {
//                                   ...this.state.data,
//                                   out_time: time,
//                                 },
//                                 errors: {
//                                   ...this.state.errors,
//                                   out_time: "",
//                                 },
//                               });
//                             }}
//                             KeyboardButtonProps={{
//                               "aria-label": "change time",
//                             }}
//                             InputLabelProps={{
//                               classes: { root: classes.textLabel },
//                             }}
//                             keyboardIcon={
//                               <DateTime className={classes.dateIcon} />
//                             }
//                             InputProps={{
//                               classes: { root: classes.textValue },
//                             }}
//                           />
//                         </MuiPickersUtilsProvider>
//                         <FormHelperText>
//                           {this.state.errors.out_time}
//                         </FormHelperText>
//                       </FormControl>
//                     </Grid>
//                   </Grid>{" "}
//                 </Paper>

//                 <Grid xs={12} className={classes.btns} container>
//                   {/* <Grid item xs={12} md={2} className={classes.btnGrid}>
//                     <Button
//                       className={classes.cancelButton}
//                       onClick={() => this.onBackButtonClick()}
//                     >
//                       CLOSE
//                     </Button>
//                   </Grid>{" "} */}
//                   <Grid item xs={12} md={6}></Grid>
//                   <Grid
//                     item
//                     xs={12}
//                     md={2}
//                     style={{
//                       textAlign: "right",
//                     }}
//                   >
//                     <Button
//                       disableElevation={true}
//                       variant="contained"
//                       className={classes.clearButton}
//                       onClick={() => this.resetDutyParams()}
//                     >
//                       Clear
//                     </Button>
//                   </Grid>{" "}
//                   <Grid item xs={12} md={3} className={classes.btnGrid}>
//                     <Button
//                       variant="contained"
//                       color="secondary"
//                       disableElevation={true}
//                       className={classes.button}
//                       onClick={(e) => this.performAction(e)}
//                     >
//                       Update
//                     </Button>
//                     <Recaptcha
//                       ref={(ref) => (this.recaptcha = ref)}
//                       sitekey={CaptchaSiteKeyMasters}
//                       size="invisible"
//                       onResolved={this.onResolved}
//                     />
//                   </Grid>
//                 </Grid>
//               </div>
//             </Fragment>
//           </div>
//         </Paper>
//       </Grid>
//     );
//   }
// }
// function mapStateToProps(state) {
//   return {
//     dutyDetails: state.AssignDuty.dutyDetails,
//     from_date: state.AssignDuty.from_date,
//     to_date: state.AssignDuty.to_date,
//     in_time: state.AssignDuty.in_time,
//     out_time: state.AssignDuty.out_time,
//     saveSuccess: state.AssignDuty.saveSuccess,
//     message: state.AssignDuty.message,
//     error: state.AssignDuty.error,
//     duties: state.AssignDuty.duties,
//     statusmessage: state.AssignDuty.statusmessage,
//     saveStaus: state.AssignDuty.saveStaus,
//   };
// }

// function mapDispatchToProps(dispach) {
//   return {
//     navigateTo: (url) => dispach(push(url)),
//     fetchDuty: (id) => dispach(fetchDuty(id)),
//     editDuty: (params, id) => dispach(editDuty(params, id)),
//     resetDuties: () => dispach(resetDuties()),
//     showSnackbar: (message, type) =>
//       dispach(snackbarActions.showSnackbar(message, type)),
//   };
// }
// const styledUpdateDuty = withStyles(styles)(UpdateDuty);
// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(styledUpdateDuty)
// );
