import APIRequest from "../util/api/APIRequest";

class FlammableGoodsService {
  addFlammableGood = params => {
    return APIRequest({
      url: `api/flammableVehicle/`,
      data: params,
      method: "POST"
    });
  };

  fetchFlammableGoods = id => {
    return APIRequest({
      url: `api/flammableVehicle/${id}`,
      method: "GET"
    });
  };

  editFlammableGoods = (params, id) => {
    return APIRequest({
      url: `api/flammableVehicle/${id}`,
      data: params,
      method: "PUT"
    });
  };

  fetchFlammableGoodsList = (page, limit, filterParams) => {
    return APIRequest({
      url: `api/flammableVehicle/`,
      params: {
        page,
        limit
      },
      data: filterParams,
      method: "GET"
    });
  };

  deleteFlammableGood = id => {
    return APIRequest({
      url: `api/flammableVehicle/${id}`,
      method: "DELETE"
    });
  };
}

export default FlammableGoodsService;
