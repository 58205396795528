import React, { Fragment } from "react";
import classNames from "classnames";
import {
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  Table,
  TableBody,
  TablePagination,
  Modal,
  Divider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { ReactComponent as Edit } from "../../assets/Visual Assets/edit.svg";
import { ReactComponent as Delete } from "../../assets/Visual Assets/delete.svg";
// import { confirmAlert } from "react-confirm-alert";
// import "react-confirm-alert/src/react-confirm-alert.css";
import messageConstants from "../../services/validator/MsgConsts/messageConstants";
import StorageHandler from "../../util/storage/";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import AddIcon from "@material-ui/icons/Add";
import { ReactComponent as SortArrow } from "../../assets/Visual Assets/sort_down.svg";
import Pagination from "@material-ui/lab/Pagination";
import "../userMaster/style.css";
import SkeltonScreen from "../../components/skelton_screen";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const rows = [
  {
    id: "slno",
    numeric: false,
    disablepadding: true,
    label: "Sl No",
  },

  {
    id: "vehicle_no",
    numeric: false,
    disablepadding: true,
    label: "Vehicle Number",
  },
  { id: "status", numeric: false, disablepadding: true, label: "Status" },
];

const headerStyles = (theme) => ({
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px/43px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
    borderBottom: "1px solid #E7EBF4",
    paddingBottom: "2px",
    paddingTop: "2px",
    backgroundColor: "white"
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 14px/43px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
    paddingBottom: "2px",
    paddingTop: "2px",
    backgroundColor: "white"
  },
});

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };
  render() {
    const { order, orderBy, classes } = this.props;

    return (
      <TableHead>
        <TableRow className={classes.tableHead}>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                sortDirection={orderBy === row.id ? order : false}
                className={classes.tableTitle}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                    className={classes.tableTitle}
                    IconComponent={SortArrow}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
          {new StorageHandler().getAttributPermissions() &&
            new StorageHandler().getAttributPermissions().edit_status == true ? (
              <TableCell
                className={classes.tableTitle}
                style={{ textAlign: "right" }}
              >
                Edit
              </TableCell>
            ) : null}
          {new StorageHandler().getAttributPermissions() &&
            new StorageHandler().getAttributPermissions().delete_status ==
            true ? (
              <TableCell
                className={classes.tableTitle}
                style={{ textAlign: "right", paddingRight: "35px" }}
              >
                Delete
              </TableCell>
            ) : null}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSeleceted: PropTypes.number.isRequired,
  onRequestsort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);
const toolbarStyles = (theme) => ({
  root: {
    borderRadius: theme.border.radius,
    padding: "11px",
  },
  spacer: {
    flex: "1 1 1%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    color: theme.colors.chartHeading,
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    opacity: 1,
    marginLeft: "22px",
    marginTop: "-6px",
  },
  buttons: {
    marginTop: "8px",
    marginRight: "14px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    width: "111px",
    height: "42px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    textTransform: "none",
  },
  divider: { border: "1px solid #E7EBF4", opacity: 1, marginTop: "8px" },
});

let EnhancedTableToolbar = (props) => {
  const { classes, addVehicle } = props;

  return (
    <>
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          Manual Alerts
        </Typography>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {new StorageHandler().getAttributPermissions() &&
            new StorageHandler().getAttributPermissions().add_status == true ? (
              <Button
                variant="contained"
                disableElevation={true}
                color="secondary"
                className={classes.buttons}
                onClick={addVehicle}
              >
                Add Vehicle
              </Button>
            ) : null}
        </div>
      </Toolbar>
      <Divider className={classes.divider} />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
  },
  grid: {
    marginTop: "21px",
    paddingTop: "22px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: "33px",
    paddingRight: "31px",
    // paddingTop: "6px",
    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      minHeight: "12vh",
      maxHeight: "45vh",
      overflowY: "auto",
    },
    // /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
  },
  notAddedStyle: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 55,
    fontWeight: theme.font.fontWeight,
    marginLeft: 33,
    marginRight: 33,
    marginTop: 33,
    fontFamily: theme.font.family,
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
    paddingBottom: "24px",
  },
  btn: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    "&:hover": {
      background: "#E7EBF4 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginRight: "4px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  btns: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "5px",
    paddingBottom: "4.5px",
    height: "57px",
  },
  cursor: {
    cursor: "pointer",
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
  dialogContent: {
    //width: "247px",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "11px",
    // marginRight: "17px",
    // marginLeft: "16px",
  },
  dialogActions: {
    "&.MuiDialogActions-root": {
      justifyContent: "center",
    },
  },
  dialogBox: {
    //width: "327px",
    height: "161px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: "20px",
  },
  notAddedStyle1: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 55,
    // marginLeft: 33,
    // marginRight: 33,
    marginTop: 33,
    font: "normal normal normal 24px Karla;",
  },
  paginationGrid: {
    paddingLeft: "25px",
    paddingRight: "38px",
  },
  paginationText: {
    font: "normal normal normal 14px Karla",
    fontFamily: "Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingTop: "35px",
    paddingLeft: "10px",
  },
});

class HabitualOffendersList extends React.Component {
  constructor(props) {
    sessionStorage.setItem("manualMasterError", false);
    super(props);
    this.state = {
      order: "desc",
      orderBy: "vehicle_no",
      selected: [],
      data: [],
      modal: false,
      page: 0,
      rowsPerPage: 10,
      offendersCount: -1,
      habitualOffenderId: "",
      vehicleNumber: "",
      open: false,
    };
  }

  componentDidMount() {
    this.props.resetFilterParams();
    this.props.fetchHabitualOffendersList(
      this.state.page,
      this.state.rowsPerPage,
      this.props.filterParams
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.habitualOffendersLists != this.props.habitualOffendersLists) {
      this.setState({
        data: this.props.habitualOffendersLists,
        offendersCount: this.props.habitualOffendersCount,
      });
    }
    if (prevState.page != this.state.page) {
      this.props.fetchHabitualOffendersList(
        this.state.page,
        this.state.rowsPerPage,
        this.props.filterParams
      );
    }
    if (prevState.rowsPerPage != this.state.rowsPerPage) {
      this.props.fetchHabitualOffendersList(
        this.state.page,
        this.state.rowsPerPage,
        this.props.filterParams
      );
    }
    if (this.props.deleteSuccess) {
      this.props.resetDelete();
      let params = {
        Page: this.state.page,
        rowsPerPage: this.state.rowsPerPage,
        filterParams: this.state.filterParams,
      };

      this.props.fetchHabitualOffendersList(
        this.state.page,
        this.state.rowsPerPage,
        this.state.filterParams
      );
      this.props.showSnackbar(
        this.props.deleteSuccessReposnse.message,
        "success"
      );
    }
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "asc";

    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, pagess) => {
    this.setState({ page: pagess - 1 });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  performAction = () => {
    this.props.navigateTo(`/habitualOffender-master/add`);
  };

  editHabitualOffender = (offender_id) => {
    this.props.navigateTo(`habitualOffender-master/edit/${offender_id}`);
  };

  handleOpen = (habitualOffenderId, vehicle_number) => {
    this.setState({
      habitualOffenderId: habitualOffenderId,
      vehicleNumber: vehicle_number,
      modal: !this.state.modal,
    });
  };

  deleteHabitualOffender = (habitualOffenderId) => {
    this.setState({
      modal: !this.state.modal,
    });
    this.props.deleteHabitualOffender(habitualOffenderId);
  };
  componentWillUnmount() {
    sessionStorage.setItem("manualMasterError", false);
  }
  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, this.state.offendersCount - page * rowsPerPage);
    let firstPageNum = page * rowsPerPage + 1;

    return (
      <Grid className={classes.grid}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar addVehicle={this.performAction} />

          {/* {this.state.offendersCount === 0 ? (
            <>
              <div className={classes.notAddedStyle}>
                {messageConstants.NOT_ADDED}{" "}
              </div>
              <div style={{ height: "24px" }}></div>
            </>
          ) : ( */}
          {/* {this.state.offendersCount === 0 ? (
            this.props.loading ? (
              <SkeltonScreen />
            ) : (
              <>
                <div className={classes.notAddedStyle}>
                  {messageConstants.NOT_ADDED}
                </div>
                <div style={{ height: "24px" }}></div>
              </>
            )
          ) : ( */}
          {this.props.loading === false && this.state.offendersCount == 0 ? (
            <div className={classes.notAddedStyle}>
              {messageConstants.NOT_ADDED}
            </div>
          ) : (
              <>
                <div className={classes.tableWrapper}>
                  {data && data.length > 0 ? (
                    <Table className={classes.table} aria-labelledby="tableTitle" stickyHeader>
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        rowCount={this.state.offendersCount}
                      />
                      <TableBody>
                        {data.map((n, i) => {
                          const isSelected = this.isSelected(n.id);
                          return (
                            <TableRow
                              role="checkbox"
                              aria-checked={isSelected}
                              tabIndex={-1}
                              key={n.id}
                              selected={isSelected}
                              className={classes.zebrafont}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ paddingLeft: "32px" }}
                                className={classes.tableCell}
                              >
                                {firstPageNum + i}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {n.vehicle_number}
                              </TableCell>
                              {n.active == true ? (
                                <TableCell className={classes.tableCell}>
                                  Active
                                </TableCell>
                              ) : (
                                  <TableCell className={classNames.tableCell}>
                                    Inactive
                                  </TableCell>
                                )}
                              {new StorageHandler().getAttributPermissions() &&
                                new StorageHandler().getAttributPermissions()
                                  .edit_status == true ? (
                                  <TableCell
                                    className={classes.tableCell}
                                    style={{ textAlign: "right" }}
                                  >
                                    <i
                                      onClick={() =>
                                        this.editHabitualOffender(n.id)
                                      }
                                    >
                                      <Edit className={classes.cursor} />
                                    </i>
                                  </TableCell>
                                ) : null}
                              {new StorageHandler().getAttributPermissions() &&
                                new StorageHandler().getAttributPermissions()
                                  .delete_status == true ? (
                                  <TableCell
                                    className={classes.tableCell}
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "45px",
                                    }}
                                  >
                                    <i
                                      onClick={() =>
                                        this.handleOpen(n.id, n.vehicle_number)
                                      }
                                    >
                                      <Delete className={classes.cursor} />
                                    </i>
                                  </TableCell>
                                ) : null}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : sessionStorage.getItem("manualMasterError") === "true" ? (
                    <div className={classes.notAddedStyle1}>
                      {messageConstants.NOT_ADDED}
                    </div>
                  ) : (
                        <SkeltonScreen />
                      )}
                </div>
                {data && data.length > 0 ? (
                  <Grid container xs={12} className={classes.paginationGrid}>
                    <Grid item xs={4}>
                      <Typography className={classes.paginationText}>
                        {parseInt(firstPageNum) +
                          " - " +
                          (parseInt(page + 1) * rowsPerPage >
                            this.state.offendersCount
                            ? this.state.offendersCount
                            : parseInt(page + 1) * rowsPerPage) +
                          " of " +
                          this.state.offendersCount +
                          " Manual Alerts"}
                      </Typography>
                    </Grid>
                    <Grid item style={{ float: "right" }} xs={8}>
                      <Pagination
                        count={Math.ceil(this.state.offendersCount / rowsPerPage)}
                        shape="rounded"
                        page={page + 1}
                        onChange={this.handleChangePage}
                        className="pagination"
                        color="secondary"
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </>
            )}
          {/* <TablePagination
            component="div"
            count={this.state.offendersCount}
            rowsPerPageOptions={[5, 10, 15]}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          /> */}
          {/* <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.modal}
          >
            <div style={getModalStyle()} className={classes.paper}>
              <Typography variant="subtitle1" id="simple-modal-description">
                Are you sure you want to delete {this.state.vehicleNumber}?{" "}
              </Typography>
              <Grid>
                {" "}
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btn}
                  onClick={() => this.handleOpen()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btns}
                  onClick={() =>
                    this.deleteHabitualOffender(this.state.habitualOffenderId)
                  }
                >
                  OK
                </Button>
              </Grid>
            </div>
          </Modal> */}
          <Dialog
            open={this.state.modal}
            aria-labelledby="customized-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
              paper: classes.dialogBox,
            }}
          >
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className={classes.dialogContent}
              >
                Are you sure want to delete {this.state.vehicleNumber}?
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.btn}
                onClick={() => this.handleOpen()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.btns}
                onClick={() =>
                  this.deleteHabitualOffender(this.state.habitualOffenderId)
                }
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Grid>
    );
  }
}

HabitualOffendersList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(HabitualOffendersList);
