import React, { Fragment } from "react";
import {
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Divider,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import propTypes from "prop-types";
import validator from "../../../../services/validator/validator/";
import { find } from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import Recaptcha from "react-google-invisible-recaptcha";
// import CaptchaSiteKeyMasters from "../../../../services/siteKeyMasters";
import { ReactComponent as BackIcon } from "../../../../assets/SvgIcons/back.svg";
import { ReactComponent as DownArrow } from "../../../../assets/SvgIcons/dropdown.svg";
import StyledRadio from "../../../../components/filteralert_form/styledRadio";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    marginTop: "43px",
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "17px",
    marginBottom: "15px",
  },
  iconButton: {
    paddingLeft: "0px",
  },
  divde: {
    border: "1px solid #E7EBF4",
    opacity: 1,
    marginLeft: "-8px",
  },
});

let EnhancedTableToolbar = (props) => {
  const { classes } = props;
  return (
    <>
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          <IconButton
            onClick={(e) => props.onBackButtonClick(e)}
            classes={{ root: classes.iconButton }}
          >
            <BackIcon />
          </IconButton>
          {props.header}
        </Typography>
      </Toolbar>
      <Divider className={classes.divde} />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: propTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

// const useStyles = makeStyles({
//   root: {
//     "&:hover": {
//       backgroundColor: "transparent",
//     },
//   },
//   icon: {
//     borderRadius: "50%",
//     width: 16,
//     height: 16,
//     boxShadow:
//       "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
//     backgroundColor: "#f5f8fa",
//     backgroundImage:
//       "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
//     "$root.Mui-focusVisible &": {
//       outline: "2px auto rgba(19,124,189,.6)",
//       outlineOffset: 2,
//     },
//     "input:hover ~ &": {
//       backgroundColor: "#ebf1f5",
//     },
//     "input:disabled ~ &": {
//       boxShadow: "none",
//       background: "rgba(206,217,224,.5)",
//     },
//   },
//   checkedIcon: {
//     backgroundColor: "#1BB99A",
//     backgroundImage:
//       "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
//     "&:before": {
//       display: "block",
//       width: 16,
//       height: 16,
//       backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
//       content: '""',
//     },
//     "input:hover ~ &": {
//       backgroundColor: "#1BB99A",
//     },
//   },
// });

// // Inspired by blueprintjs
// function StyledRadio(props: RadioProps) {
//   const classes = useStyles();

//   return (
//     <Radio
//       className={classes.root}
//       disableRipple
//       color="default"
//       checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
//       icon={<span className={classes.icon} />}
//       {...props}
//     />
//   );
// }

const styles = (theme) => ({
  outerGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "15px",
  },
  root: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    borderRadius: theme.border.radius,
    opacity: 1,
    marginLeft: "21px",
    marginRight: "17px",
    paddingLeft: "8px",
    paddingBottom: "32px",
    // height: "354px",
  },
  root1: {
    width: "100%",
  },

  textField: {
    width: "95%",
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "45px",
  },

  textFielddes: {
    paddingTop: "22px",
    marginLeft: "10px",
    marginRight: "13px",
  },
  innerGrid: {
    // paddingBottom: "16px",
    paddingBottom: "6px",
    paddingRight: "4px",
  },
  innerGridEdit: {
    paddingBottom: "16px",
    paddingRight: "4px",
  },

  innerGridEdit1: {
    paddingRight: "4px",
    marginTop: "-5px",
  },
  button: {
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    opacity: 1,
    textTransform: "none",
    // paddingLeft: "38px",
    // paddingRight: "37px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    height: "42px",
    width: "121px",
  },

  clearButton: {
    width: "121px",
    height: "42px",
    // marginRight: "10px",
    textTransform: "none",
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.colors.BlueButton,
    textAlign: "center",
    width: "121px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
  },
  btns: {
    paddingTop: "5px",
    paddingRight: "25%",
  },
  btnsEdit: {
    paddingRight: "25%",
    marginTop: "-3px",
  },
  btnGrid: {
    padding: 0,
    paddingLeft: "12px",
  },
  err: {
    paddingLeft: "1%",
  },
  dense: {
    marginTop: 16,
  },
  vldclr: {
    color: "#FF0000",
  },
  alignment: {
    flexDirection: "row",
  },
  typo: {
    textAlign: "left",
    font: "normal normal normal 14px/36px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingRight: "14px",
  },
  formLabel: {
    font: "normal normal normal 14px/36px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  textNoOptions: {
    letterSpacing: "0px",

    opacity: 1,
    fontSize: "18px",
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  paper: {
    height: "100px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  formHelperText: {
    color: theme.colors.redColor,
  },
  popIcon: {
    marginTop: "6px",
    marginRight: "6px",
  },
  popIconOpen: {
    marginTop: "4px",
    marginRight: "6px",
  },
});

class OfficeDetails extends React.Component {
  constructor(props) {
    super(props);
    this.onResolved = this.onResolved.bind(this);
    this.state = {
      data: {
        name: "",
        emailID: "",
        phone_no: "",
        zone: "",
        district: "",
        field: "",
        status: "true",
        captcha: "",
      },
      errors: {
        name: "",
        emailID: "",
        phone_no: "",
        zone: "",
        district: "",
        field: "",
      },
      rules: {
        name: {
          required: true,
          minLength: 5,
          maxLength: 20,
          alphanumericwithspecialchar: true,
        },
        emailID: {
          required: false,
          email: true,
          minLength: 8,
          maxLength: 40,
        },
        phone_no: {
          required: false,
          minLength: 7,
          maxLength: 14,
          isValidPhoneNumber: true,
        },
        field: {
          required: true,
        },
        zone: {
          required: true,
        },
        district: {
          required: true,
        },
      },
      Field: [],
      Zone: [],
      District: [],
    };
  }

  componentDidMount() {
    this.props.fetchZone();
    if (this.props.match.params.office_id) {
      this.props.fetchOfficeDeatils(this.props.match.params.office_id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.office_id &&
      this.props !== prevProps &&
      this.props.fetchedOfficeDetails !== prevProps.fetchedOfficeDetails
    ) {
      const { fetchedOfficeDetails } = this.props;
      this.setState({
        ...this.state,
        fetchedOfficeDetails: fetchedOfficeDetails,
        data: {
          name: fetchedOfficeDetails.office,
          emailID: fetchedOfficeDetails.email,
          phone_no: fetchedOfficeDetails.telephone,
          office: fetchedOfficeDetails.office,
          field: fetchedOfficeDetails.location_master,
          zone: fetchedOfficeDetails.zone_master,
          district: fetchedOfficeDetails.district_master,
          status:
            fetchedOfficeDetails.status != undefined
              ? fetchedOfficeDetails.status.toString()
              : "",
        },
      });
    }

    if (prevProps.field != this.props.field) {
      this.setState({
        Field: this.props.field,
      });
    }
    if (prevProps.zone != this.props.zone) {
      this.setState({
        Zone: this.props.zone,
      });
    }
    if (prevProps.district != this.props.district) {
      this.setState({
        District: this.props.district,
      });
    }
    if (
      this.props.saveSuccess &&
      this.props.statusmessage !== prevProps.statusmessage &&
      !this.props.error &&
      this.props.statusmessage !== "failed"
    ) {
      setTimeout(() => {
        this.props.showSnackbar(this.props.message, "success");
      }, 1000);
      this.props.resetOfficeDetail();
      this.props.navigateTo("/master/office-master");
    }

    if (
      this.props.saveSuccess &&
      !this.props.error &&
      this.props.saveStaus !== prevProps.saveStaus &&
      this.props.statusmessage === "failed"
    ) {
      this.props.showSnackbar(this.props.message, "error");
    }
    if (this.props.error && this.props.error !== this.state.alert) {
      this.props.showSnackbar(this.props.message, "error");
    }
  }
  onBackButtonClick = () => {
    this.props.navigateTo("/master/office-master");
  };
  validate = (rules, data) => {
    const errors = validator(rules)(data);
    const hasErrors = find(errors, (error) => error !== "");

    this.setState({ errors });
    return !hasErrors;
  };
  handleSubmit = () => {
    if (this.validate(this.state.rules, this.state.data)) {
      // this.recaptcha.execute();
      this.onResolved();
    }
  };
  onResolved = () => {
    // this.setState({
    //   captcha: this.recaptcha.getResponse(),
    // });
    let params = {
      // captcha: this.state.captcha,
      office: this.state.data.name,
      status: this.state.data.status,
      location_id: this.state.data.field.location_id,
      district_id: this.state.data.district.district_id,
      zone_id: this.state.data.zone.zone_id,
      telephone: this.state.data.phone_no,
      email: this.state.data.emailID,
    };

    if (this.props.match.params.office_id) {
      this.props.editOffice(params, this.props.match.params.office_id);
    } else {
      this.props.addOffice(params);
    }
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errors: {
        ...this.state.errors,
        [e.target.name]: "",
      },
    });
  };

  handleZone = (e, value) => {
    e.preventDefault();

    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          zone: value,
        },
        errors: {
          ...this.state.errors,
          zone: "",
        },
      },
      () => {
        this.props.fetchDistrict(this.state.data.zone.zone_id);
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            district: "",
            field: "",
          },
        });
      }
    );
  };
  handleDistrict = (e, value) => {
    e.preventDefault();
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,

          district: value,
        },
        errors: {
          ...this.state.errors,
          district: "",
        },
      },
      () => {
        this.props.fetchField(this.state.data.district.district_id);
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            field: "",
          },
        });
      }
    );
  };
  handleField = (e, value) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,

        field: value,
      },
      errors: {
        ...this.state.errors,
        field: "",
      },
    });
  };

  handleClear = () => {
    this.setState({
      ...this.state,
      Field: [],
      District: [],
      data: {
        ...this.state.data,
        name: "",
        emailID: "",
        phone_no: "",
        zone: "",
        district: "",
        field: "",
        status: "true",
      },
      errors: {
        ...this.state.errors,
        name: "",
        emailID: "",
        phone_no: "",
        zone: "",
        district: "",
        field: "",
      },
    });
  };
  render() {
    const { classes } = this.props;
    const { data, errors, Field, Zone, District } = this.state;

    return (
      <Paper className={classes.root} elevation={0}>
        <EnhancedTableToolbar
          onBackButtonClick={this.onBackButtonClick}
          header={
            this.props.match.params.office_id ? "Edit Office" : "Add Office"
          }
        />
        <div className={classes.textFielddes}>
          <Fragment>
            <Grid container className={classes.outerGrid}>
              <Grid
                item
                xs={12}
                md={4}
                className={
                  this.props.match.params.office_id
                    ? classes.innerGridEdit
                    : classes.innerGrid
                }
              >
                <Autocomplete
                  disableClearable="true"
                  disableOpenOnFocus="true"
                  options={Zone}
                  getOptionLabel={(option) =>
                    option.zone_name ? option.zone_name : ""
                  }
                  onChange={this.handleZone}
                  value={data.zone}
                  popupIcon={<DownArrow />}
                  classes={{
                    input: classes.textValue,
                    option: classes.textValue,
                    popupIndicatorOpen: classes.popIconOpen,
                    popupIndicator: classes.popIcon,
                    noOptions: classes.textNoOptions,
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      className={classes.textField}
                      label="Choose Zone"
                      id="outlined-margin-dense"
                      margin="dense"
                      variant="outlined"
                      required
                      name="zone"
                      error={errors.zone ? true : false}
                      {...params}
                      InputLabelProps={{
                        classes: { root: classes.textLabel },
                      }}
                    ></TextField>
                  )}
                />

                {errors.zone ? (
                  <FormHelperText className={classes.formHelperText}>
                    {errors.zone}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className={
                  this.props.match.params.office_id
                    ? classes.innerGridEdit
                    : classes.innerGrid
                }
              >
                <Autocomplete
                  disableClearable="true"
                  disableOpenOnFocus="true"
                  disabled={this.state.zone_enabled === "true" ? true : false}
                  options={District}
                  getOptionLabel={(option) =>
                    option.district_name ? option.district_name : ""
                  }
                  onChange={this.handleDistrict}
                  value={data.district}
                  popupIcon={<DownArrow />}
                  classes={{
                    input: classes.textValue,
                    option: classes.textValue,
                    popupIndicatorOpen: classes.popIconOpen,
                    popupIndicator: classes.popIcon,
                    noOptions: classes.textNoOptions,
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      className={classes.textField}
                      label="Choose District"
                      id="outlined-margin-dense"
                      margin="dense"
                      variant="outlined"
                      required
                      name="district"
                      error={errors.district ? true : false}
                      {...params}
                      InputLabelProps={{ classes: { root: classes.textLabel } }}
                    ></TextField>
                  )}
                />

                {errors.district ? (
                  <FormHelperText className={classes.formHelperText}>
                    {errors.district}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className={
                  this.props.match.params.office_id
                    ? classes.innerGridEdit
                    : classes.innerGrid
                }
              >
                <Autocomplete
                  disableClearable="true"
                  disableOpenOnFocus="true"
                  disabled={
                    this.state.zone_enabled ||
                    this.state.district_enabled === "true"
                      ? true
                      : false
                  }
                  options={Field}
                  getOptionLabel={(option) =>
                    option.place ? option.place : ""
                  }
                  onChange={this.handleField}
                  value={data.field}
                  popupIcon={<DownArrow />}
                  classes={{
                    input: classes.textValue,
                    option: classes.textValue,
                    popupIndicatorOpen: classes.popIconOpen,
                    popupIndicator: classes.popIcon,
                    noOptions: classes.textNoOptions,
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      className={classes.textField}
                      label="Choose field"
                      name="field"
                      id="outlined-margin-dense"
                      margin="dense"
                      variant="outlined"
                      required
                      error={errors.field ? true : false}
                      {...params}
                      InputLabelProps={{ classes: { root: classes.textLabel } }}
                    ></TextField>
                  )}
                />

                {errors.field ? (
                  <FormHelperText className={classes.formHelperText}>
                    {errors.field}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className={
                  this.props.match.params.office_id
                    ? classes.innerGridEdit
                    : classes.innerGrid
                }
              >
                <TextField
                  fullWidth
                  inputProps={{
                    maxLength: 20,
                  }}
                  className={classes.textField}
                  label="Enter Office Name"
                  id="outlined-margin-dense"
                  margin="dense"
                  name="name"
                  value={data.name}
                  error={errors.name ? true : false}
                  onChange={(e) => this.handleChange(e)}
                  variant="outlined"
                  required
                  InputLabelProps={{ classes: { root: classes.textLabel } }}
                  InputProps={{ classes: { root: classes.textValue } }}
                ></TextField>

                {errors.name ? (
                  <FormHelperText className={classes.formHelperText}>
                    {errors.name}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className={
                  this.props.match.params.office_id
                    ? classes.innerGridEdit
                    : classes.innerGrid
                }
              >
                <TextField
                  fullWidth
                  inputProps={{
                    maxLength: 40,
                  }}
                  className={classes.textField}
                  label="Enter Office Email ID"
                  id="outlined-margin-dense"
                  margin="dense"
                  name="emailID"
                  value={data.emailID}
                  onChange={(e) => this.handleChange(e)}
                  error={errors.emailID ? true : false}
                  variant="outlined"
                  InputLabelProps={{ classes: { root: classes.textLabel } }}
                  InputProps={{ classes: { root: classes.textValue } }}
                ></TextField>
                {errors.emailID ? (
                  <FormHelperText className={classes.formHelperText}>
                    {errors.emailID}
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className={
                  this.props.match.params.office_id
                    ? classes.innerGridEdit
                    : classes.innerGrid
                }
              >
                <TextField
                  fullWidth
                  inputProps={{
                    maxLength: 14,
                  }}
                  className={classes.textField}
                  label="Enter Office Telephone Number"
                  id="outlined-margin-dense"
                  margin="dense"
                  name="phone_no"
                  value={data.phone_no}
                  onChange={(e) => this.handleChange(e)}
                  error={errors.phone_no ? true : false}
                  InputLabelProps={{ classes: { root: classes.textLabel } }}
                  InputProps={{ classes: { root: classes.textValue } }}
                  // classes={{ label: classes.formLabel }}
                  variant="outlined"
                  required
                ></TextField>
                {errors.phone_no ? (
                  <FormHelperText className={classes.formHelperText}>
                    {errors.phone_no}
                  </FormHelperText>
                ) : null}
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                className={
                  this.props.match.params.office_id
                    ? classes.innerGridEdit1
                    : classes.innerGrid
                }
              >
                <Grid direction="row">
                  <FormControl component="fieldset">
                    <Grid>
                      <RadioGroup
                        aria-label="Is active?"
                        name="status"
                        className={classes.alignment}
                        value={data.status}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <Typography className={classes.typo} variant="h6">
                          Status
                        </Typography>
                        &nbsp;
                        <FormControlLabel
                          classes={{ label: classes.formLabel }}
                          value="true"
                          control={<StyledRadio />}
                          label="Active"
                        />
                        <FormControlLabel
                          classes={{ label: classes.formLabel }}
                          value="false"
                          control={<StyledRadio />}
                          label="Inactive"
                        />
                      </RadioGroup>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={4}></Grid>
            </Grid>
            <Grid
              xs={12}
              className={
                this.props.match.params.office_id
                  ? classes.btnsEdit
                  : classes.btns
              }
              container
            >
              <Grid item xs={12} md={6}></Grid>
              <Grid
                item
                xs={12}
                md={2}
                style={{
                  textAlign: "right",
                }}
              >
                <Button
                  disableElevation={true}
                  variant="contained"
                  className={classes.clearButton}
                  onClick={() => this.handleClear()}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item xs={12} md={3} className={classes.btnGrid}>
                <Button
                  disableElevation={true}
                  elevation={0}
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => this.handleSubmit()}
                >
                  Submit
                </Button>
                <Grid item xs={12} md={1}></Grid>
                {/* <Recaptcha
                  ref={(ref) => (this.recaptcha = ref)}
                  sitekey={CaptchaSiteKeyMasters}
                  size="invisible"
                  onResolved={this.onResolved}
                /> */}
              </Grid>
            </Grid>
          </Fragment>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(OfficeDetails);
