import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Tooltip,
  IconButton,
  Paper,
  Typography,
  Toolbar,
  Table,
  Grid,
  Divider,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { ReactComponent as FilterListIcon } from "../../assets/Visual Assets/filter.svg";
import { ReactComponent as ViewIcon } from "../../assets/Visual Assets/action_icon.svg";
import { ReactComponent as SortUp } from "../../assets/Visual Assets/sort_up.svg";
import { toDateTime } from "util/date";
import WarningFilter from "../../components/warningfilter/";
import messageConstants from "../../services/validator/MsgConsts/messageConstants";
import "../userMaster/style.css";
import Pagination from "@material-ui/lab/Pagination";
import SkeltonScreen from "../../components/skelton_screen";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: "plate",
    numeric: false,
    disablePadding: true,
    label: "Plate",
    active: true,
  },
  {
    id: "location",
    numeric: false,
    disablePadding: true,
    label: "Location",
    active: false,
  },
  {
    id: "vehicle_category",
    numeric: false,
    disablePadding: false,
    label: "Category",
    active: false,
  },

  {
    id: "event_timestamp",
    numeric: false,
    disablePadding: false,
    label: "Date Time",
    active: false,
  },
  {
    id: "confidence",
    numeric: false,
    disablePadding: false,
    label: "Confidence",
    active: false,
  },
  {
    id: "lane_id",
    numeric: false,
    disablePadding: false,
    label: "Lane",
    active: false,
  },
  {
    id: "camera_id",
    numeric: false,
    disablePadding: false,
    label: "Camera",
    active: false,
  },
];
const headerStyles = (theme) => ({
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px/43px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
    borderBottom: "1px solid #E7EBF4",
    paddingBottom: "2px",
    paddingTop: "2px",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 14px/43px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
    paddingBottom: "2px",
    paddingTop: "2px",
    backgroundColor: "white",
  },
});
class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;
    const { classes } = this.props;

    return (
      <TableHead>
        <TableRow className={classes.tableHead}>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                numeric="false"
                sortDirection={orderBy === row.id ? order : false}
                className={classes.tableTitle}
              >
                <Tooltip
                  title="Sort"
                  placement={"bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                    className={classes.tableTitle}
                    IconComponent={SortUp}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
          <TableCell className={classes.tableTitle}>Action</TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);

const toolbarStyles = (theme) => ({
  root: {
    borderRadius: theme.border.radius,
    padding: "11px",
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    color: theme.colors.chartHeading,
    fontWeight: theme.font.Bold,
    textAlign: "left",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    opacity: 1,
    marginLeft: "23px",
  },
  filterIcon: {
    opacity: 1,
    color: theme.colors.filterIconColor,
  },
  divider: { border: "1px solid #E7EBF4", opacity: 1 },
});

let EnhancedTableToolbar = (props) => {
  const { classes, showFilterWindow } = props;

  return (
    <>
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          Warnings
        </Typography>

        <div className={classes.spacer} />
        <div className={classes.actions}>
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" onClick={showFilterWindow}>
              <FilterListIcon className={classes.filterIcon} />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>{" "}
      <Divider className={classes.divider} />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.border.radius,
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    paddingBottom: "24px",
  },
  grid: {
    marginTop: "21px",
    paddingTop: "22px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
  },
  roots: {
    borderRadius: theme.border.radius,
    padding: "11px",
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    paddingRight: "31px",
    marginLeft: "33px",

    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "50vh",
      overflowY: "auto",
    },
    /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
    /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      minHeight: "12vh",
      maxHeight: "45vh",
      overflowY: "auto",
    },
    /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    color: theme.colors.chartHeading,
    fontWeight: theme.font.Bold,
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    opacity: 1,
    marginLeft: "23px",
  },
  filterIcon: {
    color: theme.toolbar.iconColor,
  },
  refreshIcon: {
    color: theme.toolbar.iconColor,
  },
  notAddedStyle: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    marginLeft: 33,
    marginRight: 33,
    marginTop: 33,
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 55,
    fontWeight: theme.font.fontWeight,
    fontFamily: theme.font.family,
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "9px",
    paddingBottom: "9px",
    height: "57px",
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F1F4FB",
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgb(250 251 254)",
    },
  },
  divider: { border: "1px solid #E7EBF4", opacity: 1 },
  paginationText: {
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingTop: "35px",
    paddingLeft: "33px",
  },
});

class NoEwayBillWarningList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: "event_timestamp",
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 10,
      recordsCount: -1,
      selectedDate: new Date(),
      filterParams: {},
      showActualWarningFilterWindow: false,
      type: "warning",
    };
  }

  showFilterDialog = () => {
    this.setState({
      showActualWarningFilterWindow: this.state.showActualWarningFilterWindow
        ? false
        : true,
    });
  };

  componentDidMount() {
    this.props.resetEventTimeStamp();
    let condition = localStorage.getItem("noewaybillwarning");
    console.log(condition);
    if (condition === "false") {
      this.props.resetFilterParams();
      this.props.resetActualWarningPage();
      this.props.resetFromDate();
      this.props.resetToDate();
      this.props.resetPlateNo();
      this.props.resetZone();
      this.props.resetDistrict();
      this.props.resetField();
      this.props.resetCamera();
      this.props.resetConfidence();
      this.props.resetVehicle();
      this.props.resetLane();
      this.props.fetchActualWarningList(
        this.props.page,
        this.state.rowsPerPage,
        this.state.type,
        {}
      );
    } else {
      this.props.fetchActualWarningList(
        this.props.page,
        this.state.rowsPerPage,
        this.state.type,
        this.props.filterParams
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error != prevProps.error) {
      this.setState({
        c: 1,
      });
    }
    if (prevProps.actualwarningList !== this.props.actualwarningList) {
      this.setState({
        data: this.props.actualwarningList,
        recordsCount: this.props.recordsCount,
      });
    }

    if (prevProps.page != this.props.page) {
      this.props.fetchActualWarningList(
        this.props.page,
        this.state.rowsPerPage,
        this.state.type,
        this.props.filterParams
      );
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "asc";

    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }

    this.setState({ order, orderBy });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, pagess) => {
    this.props.setActualWarningPage(pagess - 1);
  };

  handleViewWarningClick = (id, event_timestamp) => {
    this.props.setEventTimeStamp(event_timestamp);
    this.props.navigateTo(`/warnings/${id}`);
  };
  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  componentWillUnmount() {
    this.props.resetError();
  }
  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage } = this.state;

    return (
      <Grid className={classes.grid}>
        <Paper className={classes.root}>
          <EnhancedTableToolbar showFilterWindow={this.showFilterDialog} />

          {this.props.loading === false && this.state.recordsCount == 0 ? (
            <div className={classes.notAddedStyle}>
              {messageConstants.NOT_ADDED}
            </div>
          ) : (
            <>
              <div className={classes.tableWrapper}>
                {data && data.length > 0 ? (
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                    />

                    <TableBody>
                      {stableSort(data, getSorting(order, orderBy)).map((n) => {
                        const isSelected = this.isSelected(n.id);
                        return (
                          <TableRow
                            onClick={(event) => this.handleClick(event, n.id)}
                            role="checkbox"
                            aria-checked={isSelected}
                            tabIndex={-1}
                            key={n.id}
                            selected={isSelected}
                            className={classes.zebrafont}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableCell}
                              align="center"
                            >
                              {n.plate}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {n.location}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {n.vehicle_category}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {toDateTime(n.event_timestamp)}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {n.confidence}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {n.lane_id}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {n.camera_id}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              <IconButton
                                color="inherit"
                                onClick={() =>
                                  this.handleViewWarningClick(
                                    n.id,
                                    n.event_timestamp
                                  )
                                }
                              >
                                <ViewIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : this.props.error == true ? (
                  <div className={classes.notAddedStyle}>
                    {messageConstants.NOT_ADDED}
                  </div>
                ) : (
                  <SkeltonScreen />
                )}
              </div>
              {data && data.length > 0 ? (
                <Grid container xs={12}>
                  <Grid item xs={4}>
                    <Typography className={classes.paginationText}>
                      {parseInt(this.props.page) * this.props.rowsPerPage +
                        1 +
                        " - " +
                        (parseInt(this.props.page) * this.props.rowsPerPage +
                          10 >
                        this.state.recordsCount
                          ? this.state.recordsCount
                          : parseInt(this.props.page) * this.props.rowsPerPage +
                            10) +
                        " of " +
                        +this.state.recordsCount +
                        " Warnings"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{ float: "right", paddingRight: "43px" }}
                    xs={8}
                  >
                    <Pagination
                      count={Math.ceil(this.state.recordsCount / rowsPerPage)}
                      shape="rounded"
                      page={this.props.page + 1}
                      onChange={this.handleChangePage}
                      className="pagination"
                      color="secondary"
                    />{" "}
                  </Grid>
                </Grid>
              ) : null}
            </>
          )}
          <Grid style={{ paddingRight: "13px" }}>
            <WarningFilter
              toggleActualWarningFilterWindow={this.showFilterDialog}
              showActualWarningFilterWindow={
                this.state.showActualWarningFilterWindow
              }
              searchMethod={this.props.fetchActualWarningList}
              page={this.props.page}
              rowsPerPage={this.props.rowsPerPage}
              type={this.state.type}
            />
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

NoEwayBillWarningList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NoEwayBillWarningList);
