import {
  FETCH_FIELD,
  FETCH_FIELD_FAILED,
  FETCH_ZONE,
  FETCH_ZONE_FAILED,
  FETCH_DISTRICT,
  FETCH_DISTRICT_FAILED,
  ADD_EDIT_OFFICE_MASTERS,
  ADD_EDIT_OFFICE_MASTERS_FAILED,
  RESET_OFFICE_MASTER_STATE,
  FETCH_OFFICE_DETAILS,
  FETCH_OFFICE_DETAILS_FAILED,
} from "./constants";
const initialState = {
  fetchedOfficeDetails: {},
  field: [],
  zone: [],
  district: [],
  error: "",
  saveSuccess: false,
  statusmessage: "",
  message: "",
  saveStaus: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_OFFICE_DETAILS:
      return {
        ...state,
        fetchedOfficeDetails: action.data.data,
      };

    case FETCH_OFFICE_DETAILS_FAILED:
      return {
        ...state,
        fetchedOfficeDetails: {},
      };

    case FETCH_FIELD:
      return {
        ...state,
        field: action.data.data,
      };
    case FETCH_FIELD_FAILED:
      return {
        ...state,
        field: [],
      };
    case FETCH_ZONE:
      return {
        ...state,
        zone: action.data.data.rows,
      };
    case FETCH_ZONE_FAILED:
      return {
        ...state,
        zone: [],
      };
    case FETCH_DISTRICT:
      return {
        ...state,
        district: action.data.data,
      };
    case FETCH_DISTRICT_FAILED:
      return {
        ...state,
        district: [],
      };
    case ADD_EDIT_OFFICE_MASTERS:
      return {
        ...state,
        saveSuccess: true,
        message: action.data.message,
        statusmessage: action.data.status,
        saveStaus: !state.saveStaus,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };

    case ADD_EDIT_OFFICE_MASTERS_FAILED:
      return {
        ...state,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };
    case RESET_OFFICE_MASTER_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
