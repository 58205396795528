import { connect } from "react-redux";
import { withRouter } from "react-router";
import HistoryList from "./HistoryList";
import {
  fetchHistoryList,
  setPage,
  setRowsPerPage,
  resetPage,
  resetError,
  resetEventTimeStamp,
  setEventTimeStamp,
} from "./data/action";
import { push } from "connected-react-router";
import {
  resetFilterParams,
  setHistoryFilterParams,
  resetFromDate,
  resetToDate,
  resetPlateNo,
  resetAlertType,
  resetZone,
  resetDistrict,
  resetField,
  resetCamera,
  resetCommodity,
  resetOffender,
  resetVehicle,
  resetLane,
  resetWarningType,
} from "../../components/filterhistory_form/data/action";

function mapStateToProps(state) {
  return {
    historyList: state.historyList.historyList,
    historyCount: state.historyList.historyCount,
    filterParams: state.filterhistory.filterParams,
    page: state.historyList.page,
    rowsPerPage: state.historyList.rowsPerPage,
    loading: state.progress.loading,
    error: state.historyList.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchHistoryList: (page, limit, filterParams) =>
      dispatch(fetchHistoryList(page, limit, filterParams)),
    resetFilterParams: () => dispatch(resetFilterParams()),
    setPage: (page) => dispatch(setPage(page)),
    setRowsPerPage: (rowsPerPage) => dispatch(setRowsPerPage(rowsPerPage)),
    setHistoryFilterParams: (filterHistoryParams) =>
      dispatch(setHistoryFilterParams(filterHistoryParams)),
    resetPage: () => dispatch(resetPage()),
    navigateTo: (url) => dispatch(push(url)),
    resetError: () => dispatch(resetError()),
    setEventTimeStamp: (time) => dispatch(setEventTimeStamp(time)),
    resetEventTimeStamp: () => dispatch(resetEventTimeStamp()),

    //filterparams
    resetFromDate: () => dispatch(resetFromDate()),
    resetToDate: () => dispatch(resetToDate()),
    resetPlateNo: () => dispatch(resetPlateNo()),
    resetAlertType: () => dispatch(resetAlertType()),
    resetZone: () => dispatch(resetZone()),
    resetDistrict: () => dispatch(resetDistrict()),
    resetField: () => dispatch(resetField()),
    resetCamera: () => dispatch(resetCamera()),
    resetCommodity: () => dispatch(resetCommodity()),
    resetOffender: () => dispatch(resetOffender()),
    resetVehicle: () => dispatch(resetVehicle()),
    resetLane: () => dispatch(resetLane()),
    resetWarningType: () => dispatch(resetWarningType()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HistoryList)
);
