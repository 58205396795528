import { connect } from "react-redux";
import { withRouter } from "react-router";
import Reports from "./Reports";
import { getDropdownData } from "../../util/dropdown/data/action";
import {
  fetchReport,
  resetReportData,
  exportReport,
  fetchAlert,
  fetchSquad,
  fetchOfficer,
  resetSetIsGenerate,
  setIsGenerate,
} from "./data/action";
import { fetchOffice } from "../squadMaster/components/data/action";
import { snackbarActions } from "components/snackbar/data/action";
import { resetReportFilterParams } from "./components/report_filter/data/actions";
import {
  fetchZone,
  fetchDistrict,
  fetchField,
} from "../../components/filterhistory_form/data/action";

function mapStateToProps(state, ownProps) {
  return {
    report: ownProps.match.params.report_type,
    dropdownData: state.dropdown,
    reportData: state.reports.reportData,
    reportFilterParams: state.reportFilter.reportFilterParams,
    field: state.filterhistory.field,
    fetchedzone: state.filterhistory.fetchedzone,
    district: state.filterhistory.district,
    fetchedAlert: state.reports.fetchedAlert,
    office: state.squadDetails.office,
    squad: state.reports.squad,
    loading: state.progress.loading,
    officer: state.reports.officer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSquad: (id, fromdate, todate) =>
      dispatch(fetchSquad(id, fromdate, todate)),
    fetchOffice: (id) => dispatch(fetchOffice(id)),
    fetchAlert: () => dispatch(fetchAlert()),
    fetchZone: (type) => dispatch(fetchZone(type)),
    fetchDistrict: (type, id) => dispatch(fetchDistrict(type, id)),
    fetchField: (type, id) => dispatch(fetchField(type, id)),
    getDropdownData: (type) => dispatch(getDropdownData(type)),
    fetchReport: (data) => dispatch(fetchReport(data)),
    exportReport: (data) => dispatch(exportReport(data)),
    fetchOfficer: (type, id) => dispatch(fetchOfficer(type, id)),
    resetReportData: () => dispatch(resetReportData()),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
    resetReportFilterParams: () => dispatch(resetReportFilterParams()),
    resetSetIsGenerate: () => dispatch(resetSetIsGenerate()),
    setIsGenerate: () => dispatch(setIsGenerate()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Reports)
);
