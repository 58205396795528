import {
  FETCH_SQUAD_SUCCESS,
  FETCH_SQUAD_FAILED,
  FETCH_DISTRICT_SUCCESS,
  FETCH_DISTRICT_FAILED,
  FETCH_SQUAD_DETAILS_SUCCESS,
  FETCH_SQUAD_DETAILS_FAILED,
  ASSIGN_DUTY,
  ASSIGN_DUTY_FAILED,
  FETCH_DUTY_SUCCESS,
  FETCH_DUTY_FAILED,
  EDIT_DUTIES,
  EDIT_DUTIES_FAILED,
  RESET_DUTIES,
  RESET_SQUAD_FIELD,
} from "./constants";

const initialState = {
  squardList: [],
  districtList: [],
  district: {},
  squad: {},
  field: "",
  zone: "",
  from_date: null,
  to_date: null,
  in_time: null,
  out_time: null,
  dutyDetails: {},
  fetchedFlammableGoods: {},
  duties: {},
  error: "",
  saveSuccess: false,
  message: "",
  statusmessage: "",
  saveStaus: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_SQUAD_FIELD:
      return {
        ...state,
        field: "",
        zone: "",
      };
    case FETCH_DISTRICT_SUCCESS:
      return {
        ...state,
        districtList: action.data.data.rows,
      };

    case FETCH_DISTRICT_FAILED:
      return {
        ...state,
        districtList: [],
      };
    case FETCH_SQUAD_SUCCESS:
      return {
        ...state,
        squardList: action.data.data,
      };

    case FETCH_SQUAD_FAILED:
      return {
        ...state,
        squardList: [],
      };
    case FETCH_SQUAD_DETAILS_SUCCESS:
      return {
        ...state,
        field: action.data.data.location_master.place,
        zone: action.data.data.zone_master.zone_name,
      };

    case FETCH_SQUAD_DETAILS_FAILED:
      return {
        ...state,
      };
    case ASSIGN_DUTY:
      return {
        ...state,
        duties: action.data.data,
        saveSuccess: true,
        message: action.data.message,
        statusmessage: action.data.status,
        saveStaus: !state.saveStaus,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };

    case ASSIGN_DUTY_FAILED:
      return {
        ...state,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };

    case FETCH_DUTY_SUCCESS:
      return {
        ...state,
        dutyDetails: action.data.data,
        from_date: action.data.data.from_date,
        to_date: action.data.data.to_date,
        in_time: action.data.data.in_time,
        out_time: action.data.data.out_time,
      };

    case FETCH_DUTY_FAILED:
      return {
        ...state,
      };
    case EDIT_DUTIES:
      return {
        ...state,
        duties: action.data,
        saveSuccess: true,
        message: action.data.message,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
        statusmessage: action.data.status,
        saveStaus: !state.saveStaus,
      };

    case EDIT_DUTIES_FAILED:
      return {
        ...state,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };
    case RESET_DUTIES:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
