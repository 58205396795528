import {
  FETCH_HISTORY_LIST,
  FETCH_HISTORY_LIST_ERROR,
  SET_PAGE,
  SET_ROWSPERPAGE,
  SET_HISTORY_FILTER,
  RESET_PAGE,
  RESET_ERROR_PAGE,
  SET_TIME,
  RESET_TIME,
} from "./constants";
import HistoryService from "../../../service/HistoryService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchHistoryList = (page, limit, filterParams) => {
  let historyService = new HistoryService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    historyService
      .fetchHistoryList(page, limit, filterParams)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: FETCH_HISTORY_LIST, data };
  }
  function failure(error) {
    return { type: FETCH_HISTORY_LIST_ERROR, error };
  }
};

export const setRowsPerPage = (rowsPerPage) => {
  return (dispatch) => {
    dispatch(setRowsPerPage(rowsPerPage));
  };
  function setRowsPerPage(rowsPerPage) {
    return { type: SET_ROWSPERPAGE, rowsPerPage };
  }
};

export const setPage = (page) => {
  return (dispatch) => {
    dispatch(setPage(page));
  };
  function setPage(page) {
    return { type: SET_PAGE, page };
  }
};

export const resetPage = () => {
  return (dispatch) => {
    dispatch(resetPage());
  };
  function resetPage() {
    return { type: RESET_PAGE };
  }
};
export const resetError = () => {
  return (dispatch) => {
    dispatch(resetError());
  };
  function resetError() {
    return { type: RESET_ERROR_PAGE };
  }
};

export const setEventTimeStamp = (time) => {
  return (dispatch) => {
    dispatch(setEventTimeStamp(time));
  };
  function setEventTimeStamp(time) {
    return { type: SET_TIME, time };
  }
};

export const resetEventTimeStamp = () => {
  return (dispatch) => {
    dispatch(resetEventTimeStamp());
  };
  function resetEventTimeStamp() {
    return { type: RESET_TIME };
  }
};
