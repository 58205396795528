import { connect } from "react-redux";
import { withRouter } from "react-router";
import OfficeList from "./officeList";
import { push } from "connected-react-router";

import { snackbarActions } from "components/snackbar/data/action";
import {
  toggleFilterWindow,
  resetFilterParams,
  setFilterParams,
  setValue,
} from "../../components/filteroffice_form/data/action";
import {
  dialogueWindowStatus,
  onFilterChange,
  fetchZone,
  fetchDistricts,
  fetchOfficeList,
  deleteOffice,
  resetDelete,
} from "./data/action";

function mapStateToProps(state) {
  return {
    officeLists: state.officeList.officeLists,
    error: state.officeList.error,
    officesCount: state.officeList.officesCount,
    filterParams: state.filter.filterParams,
    deleteSuccessReposnse: state.officeList.deleteSuccessMessage,
    deleteSuccess: state.officeList.deleteSuccess,
    showDialogue: state.showDialogue,
    showFilterWindow: state.filter.showFilterWindow,
    zones: state.officeList.zone,
    districts: state.officeList.district,
    office: state.officeList.officeListFilter,
    val: state.officeList.val,
    loading: state.progress.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onFilterChange: (params) => dispatch(onFilterChange(params)),
    fetchOfficeList: (page, limit) => dispatch(fetchOfficeList(page, limit)),
    fetchZone: () => dispatch(fetchZone()),
    fetchDistricts: (id) => dispatch(fetchDistricts(id)),
    navigateTo: (url) => dispatch(push(url)),
    toggleFilterWindow: () => dispatch(toggleFilterWindow()),
    resetFilterParams: () => dispatch(resetFilterParams()),
    setFilterParams: (filterParams) => dispatch(setFilterParams(filterParams)),
    deleteOffice: (id) => dispatch(deleteOffice(id)),
    resetDelete: () => dispatch(resetDelete()),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
    dialogueWindowStatus: () => dispatch(dialogueWindowStatus()),

    setValue: () => dispatch(setValue()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfficeList)
);
