import {
  FETCH_USER_TYPE,
  FETCH_USER_TYPE_ERROR,
  DIALOGUE_WINDOW_STATUS,
  // FILTER_CHANGE,
  // FILTER_CHANGE_ERROR,
  OPEN_CLOSE_VIEW_WINDOW,
} from "./constants";

const initialState = {
  userTypeList: [],
  showDialogue: false,
  roleMasterList: [],
  viewWindowStatus: 1,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_TYPE:
      return {
        ...state,
        userTypeList: action.data.data,
      };
    case FETCH_USER_TYPE_ERROR:
      return {
        ...initialState,
      };
    case DIALOGUE_WINDOW_STATUS:
      return {
        ...state,
        showDialogue: !state.showDialogue,
      };
    case OPEN_CLOSE_VIEW_WINDOW:
      let data = 0;
      if (state.viewWindowStatus === 0) {
        data = 1;
      }
      return {
        ...state,
        viewWindowStatus: data,
      };

    // case FILTER_CHANGE:
    //   return {
    //     ...state,
    //     roleMasterList: action.data.data,
    //   };
    // case FILTER_CHANGE_ERROR:
    //   return {
    //     ...state,
    //   };
    default:
      return state;
  }
}
