import React, { Component, Fragment } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "./styles";
import Divider from "@material-ui/core/Divider";
// import Recaptcha from "react-google-invisible-recaptcha";
import classNames from "classnames";
// import CaptchaSiteKeyMasters from "../../../../services/siteKeyMasters";
import { ReactComponent as BackIcon } from "../../../../assets/SvgIcons/back.svg";
import { ReactComponent as DashIcon } from "../../../../assets/Visual Assets/dashboard.svg";
import { ReactComponent as MastIcon } from "../../../../assets/Visual Assets/master_setup.svg";
import { ReactComponent as LiveIcon } from "../../../../assets/Visual Assets/live_data.svg";
import { ReactComponent as AlertIcon } from "../../../../assets/Visual Assets/alerts.svg";
import { ReactComponent as WarnIcon } from "../../../../assets/Visual Assets/warnings.svg";
import { ReactComponent as HistIcon } from "../../../../assets/Visual Assets/history.svg";
import { ReactComponent as RepoIcon } from "../../../../assets/Visual Assets/eports.svg";
import { ReactComponent as ReviewIcon } from "../../../../assets/SvgIcons/survey.svg";

import {
  Table,
  TableBody,
  TableHead,
  IconButton,
  TableRow,
  TableCell,
} from "@material-ui/core";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
    marginTop: "43px",
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "17px",
    marginBottom: "15px",
  },
  iconButton: {
    paddingLeft: "0px",
  },
});
let EnhancedTableToolbar = (props) => {
  const { classes } = props;
  return (
    <>
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          <IconButton
            onClick={(e) => props.onBackButtonClick(e)}
            classes={{ root: classes.iconButton }}
          >
            <BackIcon />
          </IconButton>
          {props.header}
        </Typography>
      </Toolbar>
      <Divider />
    </>
  );
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);
const headerStyles = (theme) => ({
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "12px 14px",
    borderColor: "#E7EBF4",
  },
});

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.render.props.onRequestSort(event, property);
  };
  render() {
    const { classes } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell key={0} className={classes.tableHead}>
            Features
          </TableCell>
          <TableCell key={1} className={classes.tableHead}>
            Add
          </TableCell>
          <TableCell key={2} className={classes.tableHead}>
            Edit
          </TableCell>
          <TableCell key={3} className={classes.tableHead}>
            Delete
          </TableCell>
          <TableCell key={4} className={classes.tableHead}>
            View
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);
class editRole extends Component {
  constructor(props) {
    super(props);
    // this.onResolved = this.onResolved.bind(this);

    this.state = {
      isVerified: false,
      dlgOpen: false,
      role_id: "",
      baseData: "",
      data: {
        captcha: "",

        role_id: "",
        role_name: "",
        permissions: [],
        userType: {
          id: "",
          user_type: "",
        },
      },
      finalData: {
        captcha: "",

        roleId: "",
        role_name: "",
        permissions: [],
        userTypeId: {
          id: "",
        },
      },
      user_type: "",
    };
  }
  componentDidMount() {
    if (this.props.match.params.role_id) {
      this.setState({ role_id: this.props.match.params.role_id });
      this.props.fetchPermissionList(this.props.match.params.role_id);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permissionList !== this.props.permissionList) {
      let temp = this.props.permissionList;
      this.setState({
        data: temp,
      });

      // this.setState({ user_type: temp.userType.user_type });
    }
  }

  onBackButtonClick = () => {
    this.props.navigateTo("/role-master");
  };
  handleClickOpen = () => {
    // setOpen(true);
    this.setState({ dlgOpen: true });
  };

  handleClose = () => {
    // setOpen(false);
    this.setState({ dlgOpen: false });
  };
  handleCheckbox(event, featureId) {
    let index = this.state.data.permissions.findIndex(
      (feature) => feature.menu_id === featureId
    );

    let permissions = [...this.state.data.permissions];

    switch ([event.target.name][0]) {
      case "addStatus":
        permissions[index].permissions.add_status =
          event.target.value === "true";
        if (permissions[index].permissions.view_status === false) {
          permissions[index].permissions.view_status = true;
        }
        if (
          permissions[index].permissions.add_status === true &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.add_status = true;
            permissions[index].sub_menus[id].permissions.view_status = true;
          });
        }
        if (
          permissions[index].permissions.add_status === false &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.add_status = false;
          });
        }
        break;
      case "editStatus":
        permissions[index].permissions.edit_status =
          event.target.value === "true";
        if (permissions[index].permissions.view_status === false) {
          permissions[index].permissions.view_status = true;
        }
        if (
          permissions[index].permissions.edit_status === true &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.edit_status = true;
            permissions[index].sub_menus[id].permissions.view_status = true;
          });
        }
        if (
          permissions[index].permissions.edit_status === false &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.edit_status = false;
          });
        }
        break;
      case "deleteStatus":
        permissions[index].permissions.delete_status =
          event.target.value === "true";
        if (permissions[index].permissions.view_status === false) {
          permissions[index].permissions.view_status = true;
        }
        if (
          permissions[index].permissions.delete_status === true &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.delete_status = true;
            permissions[index].sub_menus[id].permissions.view_status = true;
          });
        }
        if (
          permissions[index].permissions.delete_status === false &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.delete_status = false;
          });
        }
        break;
      case "viewStatus":
        permissions[index].permissions.view_status =
          event.target.value === "true";
        if (permissions[index].permissions.view_status === false) {
          permissions[index].permissions.add_status = false;
          permissions[index].permissions.edit_status = false;
          permissions[index].permissions.delete_status = false;
        }
        if (
          permissions[index].permissions.view_status === true &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.view_status = true;
          });
        }
        if (
          permissions[index].permissions.view_status === false &&
          permissions[index].sub_menus.length > 0
        ) {
          permissions[index].sub_menus.map((resp, id) => {
            permissions[index].sub_menus[id].permissions.add_status = false;
            permissions[index].sub_menus[id].permissions.edit_status = false;
            permissions[index].sub_menus[id].permissions.delete_status = false;
            permissions[index].sub_menus[id].permissions.view_status = false;
          });
        }
        break;
      default:
        break;
    }

    this.setState({
      permissions,
    });
  }

  handleCheckboxSubMenu(event, featureId, i, j) {
    let index = this.state.data.permissions[j].sub_menus.findIndex(
      (feature) => feature.menu_id === featureId
    );

    let permissions = [...this.state.data.permissions[j].sub_menus];
    let tempMain = [...this.state.data.permissions];
    switch ([event.target.name][0]) {
      case "addStatus":
        permissions[index].permissions.add_status =
          event.target.value === "true";
        if (
          permissions[index].permissions.add_status === true &&
          permissions[index].permissions.view_status === false
        ) {
          permissions[index].permissions.view_status = true;
        }
        if (
          this.state.data.permissions[j].permissions.add_status === false &&
          permissions[index].permissions.add_status === true
        ) {
          tempMain[j].permissions.add_status = true;
          if (tempMain[j].permissions.view_status === false) {
            tempMain[j].permissions.view_status = true;
          }

          this.setState(tempMain);
        }
        break;
      case "editStatus":
        permissions[index].permissions.edit_status =
          event.target.value === "true";

        if (
          permissions[index].permissions.edit_status === true &&
          permissions[index].permissions.view_status === false
        ) {
          permissions[index].permissions.view_status = true;
        }

        if (
          this.state.data.permissions[j].permissions.edit_status === false &&
          permissions[index].permissions.edit_status === true
        ) {
          tempMain[j].permissions.edit_status = true;
          if (tempMain[j].permissions.view_status === false) {
            tempMain[j].permissions.view_status = true;
          }
          this.setState(tempMain);
        }
        break;
      case "deleteStatus":
        permissions[index].permissions.delete_status =
          event.target.value === "true";
        if (
          permissions[index].permissions.delete_status === true &&
          permissions[index].permissions.view_status === false
        ) {
          permissions[index].permissions.view_status = true;
        }
        if (
          this.state.data.permissions[j].permissions.delete_status === false &&
          permissions[index].permissions.delete_status === true
        ) {
          tempMain[j].permissions.delete_status = true;
          if (tempMain[j].permissions.view_status === false) {
            tempMain[j].permissions.view_status = true;
          }
          this.setState(tempMain);
        }
        break;
      case "viewStatus":
        permissions[index].permissions.view_status =
          event.target.value === "true";
        if (
          this.state.data.permissions[j].permissions.view_status === false &&
          permissions[index].permissions.view_status === true
        ) {
          tempMain[j].permissions.view_status = true;
          this.setState(tempMain);
        }
        if (permissions[index].permissions.view_status === false) {
          permissions[index].permissions.add_status = false;
          permissions[index].permissions.edit_status = false;
          permissions[index].permissions.delete_status = false;
        }
        break;
    }
    this.setState({
      permissions,
    });
  }
  // onResolved = () => {
  //   this.setState(
  //     {
  //       finalData: {
  //         ...this.state.finalData,
  //         captcha: this.recaptcha.getResponse(),
  //         roleId: this.state.data.role_id,
  //         role_name: this.state.data.role_name,
  //         permissions: this.state.data.permissions,
  //         userTypeId: {
  //           ...this.state.data.userType,
  //           id: this.state.data.userType.id,
  //         },
  //       },
  //     },
  //     () => {
  //       this.props.updateRoleMaster(this.state.finalData);
  //       this.props.navigateTo("/role-master");
  //     }
  //   );
  // };
  onChange = () => {
    // this.recaptcha.execute();

    this.setState(
      {
        finalData: {
          ...this.state.finalData,
          // captcha: this.recaptcha.getResponse(),
          roleId: this.state.data.role_id,
          role_name: this.state.data.role_name,
          permissions: this.state.data.permissions,
          userTypeId: {
            ...this.state.data.userType,
            id: this.state.data.userType.id,
          },
        },
      },
      () => {
        this.props.updateRoleMaster(this.state.finalData);
        this.props.navigateTo("/role-master");
      }
    );
  };
  onCancel = () => {
    this.props.navigateTo("/role-master");
  };

  render() {
    const { classes } = this.props;
    // const data = this.state.testFeatures;

    // const heading = (
    //   <>
    //     <h2>Edit Role Master</h2>
    //   </>
    // );

    const printMainMenu = (data, i) => (
      <>
        <TableCell component="th" scope="row" className={classes.tableCell}>
          {data.title === "Dashboard" ? (
            <DashIcon />
          ) : data.title === "Master Setup" ? (
            <MastIcon />
          ) : data.title === "Live Data" ? (
            <LiveIcon />
          ) : data.title === "Alerts" ? (
            <AlertIcon />
          ) : data.title === "Warnings" ? (
            <WarnIcon />
          ) : data.title === "Reviews" ? (
            <ReviewIcon />
          ) : data.title === "History" ? (
            <HistIcon />
          ) : data.title === "Reports" ? (
            <RepoIcon />
          ) : null}
          &nbsp; &nbsp; &nbsp;
          {data.title}
        </TableCell>

        <TableCell className={classes.tableCell}>
          <Checkbox
            name="addStatus"
            id="addStatus"
            checked={this.state.data.permissions[i].permissions.add_status}
            value={!this.state.data.permissions[i].permissions.add_status}
            onChange={(value) => {
              this.handleCheckbox(value, data.menu_id);
            }}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Checkbox
            name="editStatus"
            id="editStatus"
            checked={this.state.data.permissions[i].permissions.edit_status}
            value={!this.state.data.permissions[i].permissions.edit_status}
            onChange={(value) => {
              this.handleCheckbox(value, data.menu_id);
            }}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Checkbox
            // checked={this.state.checkedA}
            name="deleteStatus"
            id="deleteStatus"
            checked={this.state.data.permissions[i].permissions.delete_status}
            value={!this.state.data.permissions[i].permissions.delete_status}
            onChange={(value) => {
              this.handleCheckbox(value, data.menu_id);
            }}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Checkbox
            name="viewStatus"
            id="viewStatus"
            checked={this.state.data.permissions[i].permissions.view_status}
            value={!this.state.data.permissions[i].permissions.view_status}
            onChange={(value) => {
              this.handleCheckbox(value, data.menu_id);
            }}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </TableCell>
      </>
    );
    const printSubMenu = (data, i, j, menu_id) => (
      <>
        <TableCell
          style={{ paddingLeft: "82px" }}
          className={classes.tableCell}
        >
          {data.title}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Checkbox
            name="addStatus"
            id="addStatus"
            checked={
              this.state.data.permissions[j].sub_menus[i].permissions.add_status
            }
            value={
              !this.state.data.permissions[j].sub_menus[i].permissions
                .add_status
            }
            onChange={(value) => {
              this.handleCheckboxSubMenu(value, data.menu_id, i, j);
            }}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Checkbox
            name="editStatus"
            id="editStatus"
            checked={
              this.state.data.permissions[j].sub_menus[i].permissions
                .edit_status
            }
            value={
              !this.state.data.permissions[j].sub_menus[i].permissions
                .edit_status
            }
            onChange={(value) => {
              this.handleCheckboxSubMenu(value, data.menu_id, i, j);
            }}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Checkbox
            // checked={this.state.checkedA}
            name="deleteStatus"
            id="deleteStatus"
            checked={
              this.state.data.permissions[j].sub_menus[i].permissions
                .delete_status
            }
            value={
              !this.state.data.permissions[j].sub_menus[i].permissions
                .delete_status
            }
            onChange={(value) => {
              this.handleCheckboxSubMenu(value, data.menu_id, i, j);
            }}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Checkbox
            name="viewStatus"
            id="viewStatus"
            checked={
              this.state.data.permissions[j].sub_menus[i].permissions
                .view_status
            }
            value={
              !this.state.data.permissions[j].sub_menus[i].permissions
                .view_status
            }
            onChange={(value) => {
              this.handleCheckboxSubMenu(value, data.menu_id, i, j);
            }}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </TableCell>
      </>
    );
    const Permissions = (
      <>
        {this.state.data == ""
          ? null
          : this.state.data.permissions.map((data, i) => (
              <>
                {data.sub_menus.length === 0 ? (
                  <TableRow key={i} className={classes.zebrafont}>
                    {printMainMenu(data, i)}
                  </TableRow>
                ) : (
                  <>
                    <TableRow key={i} className={classes.zebrafont}>
                      {printMainMenu(data, i)}
                    </TableRow>
                    {data.sub_menus.map((subData, j) =>
                      subData.title === "Habitual Offender" ||
                      subData.title === "Discrepancy" ? null : (
                        <>
                          <TableRow key={i} className={classes.zebrafont}>
                            {printSubMenu(subData, j, i, data.title)}
                          </TableRow>
                        </>
                      )
                    )}
                  </>
                )}
              </>
            ))}
      </>
    );
    return (
      <>
        {this.state.data !== "" ? (
          <Grid className={classes.grid}>
            <Paper className={classes.root}>
              <EnhancedTableToolbar
                onBackButtonClick={this.onBackButtonClick}
                header={"Edit Role Master"}
              />
              <div className={classes.textFielddes}>
                <Fragment>
                  <span className={classes.subTitleHead}>
                    Role Name:&nbsp;&nbsp;
                    <Typography
                      variant="h6"
                      className={classes.subTitleContent}
                    >
                      {this.state.data && this.state.data.role_name
                        ? this.state.data.role_name
                        : null}
                    </Typography>
                    <span style={{ paddingLeft: "23.5px" }} />
                    |
                    <span style={{ paddingLeft: "23.5px" }} />
                    User Type:&nbsp;&nbsp;
                    <Typography
                      variant="h6"
                      className={classes.subTitleContent}
                    >
                      {this.state.data && this.state.data.userType.user_type
                        ? this.state.data.userType.user_type
                        : null}
                    </Typography>
                  </span>
                  <div className={classes.instructions}>
                    <div className={classes.tableWrapper}>
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        align="center"
                      >
                        <EnhancedTableHead rowCount={this.state.rowCount} />
                        <TableBody>{Permissions}</TableBody>
                      </Table>
                    </div>
                  </div>
                  <Grid
                    container
                    item
                    xs={12}
                    direction={"row"}
                    justify={"center"}
                    spacing={2}
                    style={{ paddingTop: "30px", paddingBottom: "23px" }}
                  >
                    <Grid
                      item
                      xs={4}
                      md={5}
                      container
                      justify="space-between"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={10}
                        md={6}
                        className={classes.btnAlignRight}
                      >
                        <Button
                          disableElevation={true}
                          elevation={0}
                          className={classes.closeButton}
                          onClick={this.onCancel}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        md={6}
                        className={classes.btnAlignLeft}
                      >
                        <Button
                          disableElevation={true}
                          elevation={0}
                          className={classes.button}
                          variant="contained"
                          color="secondary"
                          onClick={this.onChange}
                        >
                          Update
                        </Button>
                      </Grid>
                      {/* <Recaptcha
                        ref={(ref) => (this.recaptcha = ref)}
                        sitekey={CaptchaSiteKeyMasters}
                        size="invisible"
                        onResolved={this.onResolved}
                      /> */}
                    </Grid>
                  </Grid>
                </Fragment>
              </div>
            </Paper>
          </Grid>
        ) : null}
      </>
    );
  }
}

export default withStyles(styles)(editRole);
