export const TOGGLE_FILTER_WINDOW = "TOGGLE_FILTER_WINDOW";
export const SET_FILTER_PARAMS = "SET_FILTER_PARAMS";
export const RESET_FILTER_PARAMS = "RESET_FILTER_PARAMS";
export const FETCH_USER_NAME = "FETCH_USER_NAME";
export const FETCH_USER_NAME_FAILED = "FETCH_USER_NAME_FAILED";
export const FETCH_ROLE = "FETCH_ROLE";
export const FETCH_ROLE_FAILED = "FETCH_ROLE_FAILED";
export const FETCH_STATUS = "FETCH_STATUS";
export const FETCH_STATUS_FAILED = "FETCH_STATUS_FAILED";
export const CLOSE_WINDOW = "CLOSE_WINDOW";
