import {
  FETCH_USERTYPE,
  FETCH_USERTYPE_FAILED,
  FETCH_ROLE,
  FETCH_ROLE_FAILED,
  FETCH_FIELD,
  FETCH_FIELD_FAILED,
  FETCH_ZONE,
  FETCH_ZONE_FAILED,
  FETCH_DISTRICT,
  FETCH_DISTRICT_FAILED,
  ADD_EDIT_USER_MASTERS,
  ADD_EDIT_USER_MASTERS_FAILED,
  RESET_USER_MASTER_STATE,
  FETCH_CAMERA,
  FETCH_CAMERA_FAILED,
  FETCH_OFFICE_LIST,
  FETCH_OFFICE_LIST_FAILED,
  FETCH_DESIGNATION_LIST,
  FETCH_DESIGNATION_LIST_FAILED,
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_FAILED,
  FETCH_EDIT_CAMERA,
  FETCH_EDIT_CAMERA_FAILED,
  CHECK_USER_NAME,
  CHECK_USER_NAME_FAILED,
} from "./constants";
import UserMasterService from "../../../../../service/UserMasterService";
import FilterService from "../../../../../service/FilterService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchCameraAccess = (userTypeId, id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserMasterService()
      .fetchCameraAccess(userTypeId, id)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: FETCH_CAMERA, data };
  }

  function failure(error) {
    return { type: FETCH_CAMERA_FAILED, error };
  }
};

export const fetchEditCameraAccess = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserMasterService().fetchEditCameraAccess(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_EDIT_CAMERA, data };
  }

  function failure(error) {
    return { type: FETCH_EDIT_CAMERA_FAILED, error };
  }
};
export const fetchUserType = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserMasterService().fetchUserType().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_USERTYPE, data };
  }

  function failure(error) {
    return { type: FETCH_USERTYPE_FAILED, error };
  }
};
export const fetchRole = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserMasterService().fetchRole(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_ROLE, data };
  }

  function failure(error) {
    return { type: FETCH_ROLE_FAILED, error };
  }
};
export const fetchField = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserMasterService().fetchField(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_FIELD, data };
  }

  function failure(error) {
    return { type: FETCH_FIELD_FAILED, error };
  }
};
export const fetchZone = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserMasterService().fetchZone().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_ZONE, data };
  }

  function failure(error) {
    return { type: FETCH_ZONE_FAILED, error };
  }
};
export const fetchDistrict = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserMasterService().fetchDistrict(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_DISTRICT, data };
  }

  function failure(error) {
    return { type: FETCH_DISTRICT_FAILED, error };
  }
};
export const fetchDesignationList = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new FilterService().fetchDesignationList().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_DESIGNATION_LIST, data };
  }

  function failure(error) {
    return { type: FETCH_DESIGNATION_LIST_FAILED, error };
  }
};

// export const fetchOfficeList = () => {
//   return (dispatch) => {
//     dispatch(progressActions.showProgress());
//     new FilterService().fetchOfficeList().then((response) => {
//       if (response.data) {
//         dispatch(success(response.data));
//       } else {
//         dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
//         dispatch(failure(response));
//       }
//       dispatch(progressActions.hideProgress());
//     });
//   };
export const fetchOfficeList = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new FilterService().fetchOfficeList(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };
  function success(data) {
    return { type: FETCH_OFFICE_LIST, data };
  }

  function failure(error) {
    return { type: FETCH_OFFICE_LIST_FAILED, error };
  }
};

export const addUser = (params) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserMasterService().addUser(params).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
    function success(data) {
      return { type: ADD_EDIT_USER_MASTERS, data };
    }
    function failure(error) {
      return { type: ADD_EDIT_USER_MASTERS_FAILED, error };
    }
  };
};

export const fetchUserDeatils = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserMasterService().fetchUserDeatils(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_USER_DETAILS, data };
  }
  function failure(error) {
    return { type: FETCH_USER_DETAILS_FAILED, error };
  }
};

export const editUser = (params, id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserMasterService().editUser(params, id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: ADD_EDIT_USER_MASTERS, data };
  }
  function failure(error) {
    return { type: ADD_EDIT_USER_MASTERS_FAILED, error };
  }
};

export const checkUsername = (name) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new UserMasterService().checkUsername(name).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: CHECK_USER_NAME, data };
  }
  function failure(error) {
    return { type: CHECK_USER_NAME_FAILED, error };
  }
};
export function resetUserDetail() {
  return {
    type: RESET_USER_MASTER_STATE,
  };
}
