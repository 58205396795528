import { GET_DROPDOWN_DATA_FAILURE } from "./constants";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";
import DropDownService from "../../../service/DropdownService";
import { getActionByComboType } from "../dropdown";

export const getDropdownData = (type) => {
  let dropdown = getActionByComboType(type);
  return (dispatch) => {
    dispatch(progressActions.showProgress());

    new DropDownService().getDropdownData(dropdown.url).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };
  function success(data) {
    return { type: dropdown.action, data };
  }
  function failure(error) {
    return { type: GET_DROPDOWN_DATA_FAILURE, error };
  }
};
