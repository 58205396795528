import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "connected-react-router";
import Layout from "./Layout";
import { drawerToggle } from "../../components/drawer/data/actions";

function mapStateToProps(state) {
  return {
    isAuthenticated: state.authentication.isAuthenticated,
    showAppDrawer: state.drawer.showAppDrawer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: url => dispatch(push(url)),
    drawerToggle: () => dispatch(drawerToggle())
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Layout)
);
