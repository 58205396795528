import React from "react";
import { ConnectedRouter } from "connected-react-router";
import Routes from "./routes";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../util/theme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const App = ({ history }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default App;
