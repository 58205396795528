import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
const styles = (theme) => ({
  notAddedStyleSkleton: {
    textAlign: "center",
    color: theme.notAddedStyle.color,
    fontSize: 25,
    // padding: 20,
    fontWeight: theme.font.fontWeight,
    marginLeft: 0,
    marginRight: 20,
    marginTop: 0,
    fontFamily: theme.font.family,
  },
});
const ImageSkelton = (props) => {
  const { classes } = props;
  return (
    <SkeletonTheme color="#F1F4FB" highlightColor="#FAFBFE">
      <div className={classes.notAddedStyleSkleton}>
        <Skeleton count={1} height={100} />
      </div>
    </SkeletonTheme>
  );
};
export default withStyles(styles)(ImageSkelton);
