import React, { Component, Fragment } from "react";
import {
  Typography,
  withStyles,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Divider,
} from "@material-ui/core";
import moment from "moment";
import messageConstants from "../../../../services/validator/MsgConsts/messageConstants";

const styles = (theme) => ({
  container: {
    overflow: "auto",
    // maxWidth: "70vw",
    // minWidth: "-webkit-fill-available",
    paddingBottom: "12px",
    marginLeft: "7px",
    marginRight: "8px",
  },
  reportTitle: {
    height: "19px",
    display: "flex",
    font: "normal normal bold 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    textAlign: "left",
    paddingLeft: "5px",
    paddingTop: "11.5px",
  },
  emptyReport: {
    minHeight: "60vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    font: "normal normal bold 34px Karla;",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
  },
  typo: {
    fontWeight: theme.font.fontWeight,
  },
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px Karla;",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    paddingTop: "12px",
    paddingBottom: "13px",
    //whiteSpace: "nowrap",
    overflow: "hidden",
    backgroundColor: "white",
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    padding: "20px 14px",
    border: "0px",
    whiteSpace: "nowrap",
    overFlow: "hidden",
    height: "57px",
  },

  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
  tabledivider: {
    border: "1px solid #E7EBF4",
    opacity: 1,
    marginTop: "31px",
    marginLeft: "7px",
    marginRight: "9px",
  },
});
class ActionSummaryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportFilterParams: {},
      fromDate: moment(new Date().setDate(new Date().getDate() - 7)).format(
        "DD-MM-YYYY"
      ),
      toDate: moment(new Date()).format("DD-MM-YYYY"),
    };
  }
  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.reportFilterParams !== this.props.reportFilterParams) {
      const { reportFilterParams } = this.props;
      return reportFilterParams.from_date !== null &&
        reportFilterParams.from_date !== undefined &&
        reportFilterParams.from_date !== "" &&
        reportFilterParams.to_date !== null &&
        reportFilterParams.to_date !== undefined &&
        reportFilterParams.to_date !== ""
        ? this.setState({
            ...this.state,
            fromDate: moment(reportFilterParams.from_date).format("DD-MM-YYYY"),
            toDate: moment(reportFilterParams.to_date).format("DD-MM-YYYY"),
          })
        : this.setState({
            ...this.state,
            fromDate: moment(
              new Date().setDate(new Date().getDate() - 7)
            ).format("DD-MM-YYYY"),
            toDate: moment(new Date()).format("DD-MM-YYYY"),
          });
    }
  }

  render() {
    const { classes } = this.props;
    const { overallData } = this.props;
    const { fromDate, toDate } = this.state;

    return (
      <>
        {overallData.title ? (
          <React.Fragment>
            <div className={classes.reportTitle}>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.reportTitle}
              >
                {overallData.title}
              </Typography>
              {fromDate && toDate ? (
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.reportTitle}
                >
                  {"(" + fromDate + " to " + toDate + ")"}
                </Typography>
              ) : null}
            </div>
            <Divider className={classes.tabledivider} />
            {overallData.result && overallData.result.length > 0 ? (
              <>
                <div className={classes.container}>
                  <Table aria-label="sticky table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        {overallData.headers.map((row, i) => {
                          return (
                            <TableCell
                              key={i}
                              classes={{ root: classes.tableHead }}
                            >
                              {row}
                            </TableCell>
                          );
                        }, this)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {overallData.result.map((row, index) => (
                        <TableRow
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          className={classes.zebrafont}
                        >
                          {row.map((cell, j) => (
                            <TableCell
                              key={j}
                              component="th"
                              scope="row"
                              classes={{ root: classes.tableCell }}
                            >
                              {cell}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </>
            ) : (
              <>
                {" "}
                <div className={classes.emptyReport}>
                  <Typography
                    variant="h5"
                    align="center"
                    color="textSecondary"
                    className={classes.typo}
                  >
                    {messageConstants.NO_REPORTS_FOUND}
                  </Typography>
                </div>
              </>
            )}
          </React.Fragment>
        ) : null}
      </>
    );
  }
}

export default withStyles(styles)(ActionSummaryReport);
