/** @format */

import {
  FETCH_USER_TYPE,
  FETCH_USER_TYPE_ERROR,
  FETCH_FEATURES,
  FETCH_FEATURES_ERROR,
  ADD_ROLE_MASTER,
  ADD_ROLE_MASTER_ERROR,
  FETCH_ROLE_MASTER_LIST,
  FETCH_ROLE_MASTER_LIST_ERROR,
  FETCH_PERMISSION_LIST,
  // FETCH_PERMISSION_LIST_ERROR,
  DELETE_ROLE_MASTER,
  DELETE_ROLE_MASTER_ERROR,
  UPDATE_ROLE_MASTER,
  UPDATE_ROLE_MASTER_ERROR,
  RESET_PERMISSION_LIST,
  RESET_USER_TYPE_LIST,
  RESET_FEATURES,
  FILTER_CHANGE,
  FILTER_CHANGE_ERROR,
  FETCH_ROLE_STATUS,
  FETCH_ROLE_STATUS_ERROR,
  RESET_DELETE_STATUS,
  RESET_ROLE_STATUS,
} from "./constants";
import RoleMasterService from "../../../../../service/RoleMasterService";
import { snackbarActions } from "../../../../../components/snackbar/data/action";
import { progressActions } from "../../../../../components/progressbar/data/action";

export const fetchRoleStatus = (params) => {
  let roleMasterService = new RoleMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    roleMasterService.fetchRoleStatus(params).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_ROLE_STATUS, data };
  }
  function failure(error) {
    return { type: FETCH_ROLE_STATUS_ERROR, error };
  }
};

export const fetchUserType = () => {
  let roleMasterService = new RoleMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    roleMasterService.fetchUserTypeList().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_USER_TYPE, data };
  }
  function failure(error) {
    return { type: FETCH_USER_TYPE_ERROR, error };
  }
};

export const fetchFeatures = () => {
  let roleMasterService = new RoleMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    roleMasterService.fetchFeatureList().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_FEATURES, data };
  }
  function failure(error) {
    return { type: FETCH_FEATURES_ERROR, error };
  }
};

export const addRoleMaster = (params) => {
  let roleMasterService = new RoleMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    roleMasterService.addRoleMaster(params).then((response) => {
      if (response.data) {
        if (response.data.message === "Role already exist") {
          dispatch(failure(response));
          dispatch(
            snackbarActions.showSnackbar(response.data.message, "error")
          );
        }
        dispatch(
          snackbarActions.showSnackbar(response.data.message, "success")
        );
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: ADD_ROLE_MASTER, data };
  }
  function failure(error) {
    return { type: ADD_ROLE_MASTER_ERROR, error };
  }
};

export const fetchRollMasterList = (page, rowsPerPage) => {
  let roleMasterService = new RoleMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    roleMasterService
      .fetchRollMasterList(page, rowsPerPage)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: FETCH_ROLE_MASTER_LIST, data };
  }
  function failure(error) {
    return { type: FETCH_ROLE_MASTER_LIST_ERROR, error };
  }
};
export const onFilterChange = (type, rolename, page, limit) => {
  let roleMasterService = new RoleMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    roleMasterService
      .onFilterChange(type, rolename, page, limit)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: FILTER_CHANGE, data };
  }
  function failure(error) {
    return { type: FILTER_CHANGE_ERROR, error };
  }
};

export const fetchPermissionList = (id) => {
  let roleMasterService = new RoleMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    roleMasterService.fetchPermissionList(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        // dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_PERMISSION_LIST, data };
  }
  // function failure(error) {
  //   return { type: FETCH_PERMISSION_LIST_ERROR, error };
  // }
};

export const resetPermissionList = () => {
  return (dispatch) => {
    // dispatch(progressActions.showProgress());
    dispatch(resetPermissionList());
  };
  function resetPermissionList() {
    return { type: RESET_PERMISSION_LIST };
  }
};

export const resetUserTypeList = () => {
  return (dispatch) => {
    // dispatch(progressActions.showProgress());
    dispatch(resetUserTypeList());
  };
  function resetUserTypeList() {
    return { type: RESET_USER_TYPE_LIST };
  }
};

export const resetFeatures = () => {
  return (dispatch) => {
    // dispatch(progressActions.showProgress());
    dispatch(resetFeatures());
  };
  function resetFeatures() {
    return { type: RESET_FEATURES };
  }
};

export const deleteRoleMaster = (id) => {
  let roleMasterService = new RoleMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    roleMasterService.deleteRoleMaster(id).then((response) => {
      if (response.data) {
        dispatch(
          snackbarActions.showSnackbar(response.data.message, "success")
        );
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: DELETE_ROLE_MASTER, data };
  }
  function failure(error) {
    return { type: DELETE_ROLE_MASTER_ERROR, error };
  }
};

export const updateRoleMaster = (params) => {
  let roleMasterService = new RoleMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    roleMasterService.updateRoleMaster(params).then((response) => {
      if (response.data) {
        dispatch(
          snackbarActions.showSnackbar(response.data.message, "success")
        );
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: UPDATE_ROLE_MASTER, data };
  }
  function failure(error) {
    return { type: UPDATE_ROLE_MASTER_ERROR, error };
  }
};

// export const onFilterChange = (id) => {
//   let roleMasterService = new RoleMasterService();
//   return (dispatch) => {
//     dispatch(progressActions.showProgress());
//     roleMasterService.onFilterChange(id).then((response) => {
//       if (response.data) {
//
//         dispatch(success(response.data));
//       } else {
//         dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
//         dispatch(failure(response));
//       }
//       dispatch(progressActions.hideProgress());
//     });
//   };

//   function success(data) {
//     return { type: FILTER_CHANGE, data };
//   }
//   function failure(error) {
//     return { type: FILTER_CHANGE_ERROR, error };
//   }
// };
export function resetDeleteStatus() {
  return {
    type: RESET_DELETE_STATUS,
  };
}

export const resetRoleStatus = () => {
  return (dispatch) => {
    dispatch(resetRoleStatus());
  };
  function resetRoleStatus() {
    return { type: RESET_ROLE_STATUS };
  }
};
