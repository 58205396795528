/** @format */

import {
  FETCH_USER_TYPE,
  FETCH_USER_TYPE_ERROR,
  FETCH_FEATURES,
  FETCH_FEATURES_ERROR,
  ADD_ROLE_MASTER,
  ADD_ROLE_MASTER_ERROR,
  FETCH_ROLE_MASTER_LIST,
  FETCH_ROLE_MASTER_LIST_ERROR,
  FETCH_PERMISSION_LIST,
  FETCH_PERMISSION_LIST_ERROR,
  DELETE_ROLE_MASTER,
  DELETE_ROLE_MASTER_ERROR,
  UPDATE_ROLE_MASTER,
  UPDATE_ROLE_MASTER_ERROR,
  RESET_PERMISSION_LIST,
  FILTER_CHANGE,
  FILTER_CHANGE_ERROR,
  RESET_FEATURES,
  RESET_USER_TYPE_LIST,
  FETCH_ROLE_STATUS,
  FETCH_ROLE_STATUS_ERROR,
  RESET_DELETE_STATUS,
  RESET_ROLE_STATUS,
} from "./constants";

const initialState = {
  userTypeList: [],
  featureList: [],
  error: "",
  saveSuccess: false,
  message: "",
  roleMasterList: [],
  permissionList: [],
  deleteStatus: false,
  updateStatus: "",
  roleStatus: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ROLE_STATUS:
      return {
        ...state,
        roleStatus: action.data.data,
      };
    case FETCH_ROLE_STATUS_ERROR:
      return {
        ...state,
        roleStatus: "",
      };
    case RESET_ROLE_STATUS:
      return {
        ...state,
        roleStatus: "",
      };

    case FETCH_USER_TYPE:
      return {
        ...state,
        userTypeList: action.data.data,
      };
    case FETCH_USER_TYPE_ERROR:
      return {
        ...state,
        userTypeList: [],
      };
    case RESET_USER_TYPE_LIST:
      return {
        ...state,
        userTypeList: [],
      };
    case FETCH_FEATURES:
      return {
        ...state,
        featureList: action.data,
      };
    case FETCH_FEATURES_ERROR:
      return {
        ...state,
        featureList: [],
      };
    case RESET_FEATURES:
      return {
        ...state,
        featureList: [],
        saveSuccess: false,
        roleStatus: "",
      };
    case ADD_ROLE_MASTER:
      return {
        roleMasterList: action.data,
        saveSuccess: true,
        message: action.data.message,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };
    case ADD_ROLE_MASTER_ERROR:
      return {
        ...initialState,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };
    case FILTER_CHANGE:
      // console.log("working");
      return {
        ...state,
        roleMasterList: action.data.data,
      };
    case FILTER_CHANGE_ERROR:
      return {
        ...state,
      };
    case FETCH_ROLE_MASTER_LIST:
      return {
        ...state,
        roleMasterList: action.data.data,
      };
    case FETCH_ROLE_MASTER_LIST_ERROR:
      return {
        ...state,
        roleMasterList: [],
      };
    case FETCH_PERMISSION_LIST:
      return {
        ...state,
        permissionList: action.data.data,
      };
    case FETCH_PERMISSION_LIST_ERROR:
      return {
        ...state,
        permissionList: [],
      };
    case RESET_PERMISSION_LIST:
      return {
        ...state,
        permissionList: [],
      };

    case DELETE_ROLE_MASTER:
      return {
        ...state,
        deleteStatus: true,
      };
    case RESET_DELETE_STATUS:
      return {
        ...state,
        deleteStatus: false,
      };
    case DELETE_ROLE_MASTER_ERROR:
      return {
        ...state,
        deleteStatus: false,
      };
    case UPDATE_ROLE_MASTER:
      return {
        ...state,
        updateStatus: action.data.status,
      };
    case UPDATE_ROLE_MASTER_ERROR:
      return {
        ...state,
        updateStatus: "",
      };

    default:
      return state;
  }
}
