/** @format */

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.border.radius,
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
  },
  btnAlignCenter: {
    textAlign: "center",
  },
  btnAlignLeft: {
    textAlign: "left",
  },
  btnAlignRight: {
    textAlign: "right",
  },
  grid: {
    marginTop: "0px",
    paddingTop: "0px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,

    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "83vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
    /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "83vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
    /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "86vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
    /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "80vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
  },

  table: {
    minWidth: "100%",
    borderTop: "2px solid #F1F4FB",
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: "33px",
    paddingRight: "31px",
    paddingTop: "6px",
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "0px",
    paddingBottom: "0px",
    height: "41px",
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
  textFielddes: {
    marginLeft: "10px",
    marginRight: "13px",
  },
  stepperRoot: {
    paddingBottom: "22px",
    paddingTop: "16px",
    paddingLeft: "197px",
    paddingRight: "202px",
  },
  textField: {
    // width: "95%",
    font: "normal normal normal 14px/30px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "50px",
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  instructions: {
    height: "291px",
    overflow: "auto",
  },

  notAddedStyle: {
    backgroundColor: "rgb(193, 191, 191)",
    textAlign: "center",
    margin: 15,
    color: "#fff",
    fontSize: 25,
    padding: 50,
  },

  Headroot: {
    paddingRight: theme.spacing.unit,
    backgroundColor: theme.toolbar.backgroundColor,
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },

  innerGrid: {
    paddingBottom: "6px",
    paddingRight: "4px",
  },
  innerGridEdit: {
    paddingBottom: "16px",
    paddingRight: "4px",
  },
  outerGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "15px",
  },
  button: {
    width: "121px",
    marginBottom: "3px",
    fontWeight: theme.font.fontWeight,
    backgroundColor: "#1BB99A",
    color: theme.colors.whiteColor,
    textTransform: "none",
    font: "normal normal normal 14px/30px Karla",
  },

  closeButton: {
    width: "121px",
    marginBottom: "3px",
    fontWeight: theme.font.fontWeight,
    backgroundColor: "#E7EBF4",
    color: "#797C82",
    textTransform: "none",
    font: "normal normal normal 14px/30px Karla",
  },
  clearButton: {
    width: "100%",
    marginBottom: "3px",
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.BlueButton,
    color: theme.colors.whiteColor,
    textTransform: "none",
    font: "normal normal normal 14px/30px Karla",
  },
  backButton: {
    width: "100%",
    marginBottom: "3px",
    marginLeft: "10%",
    fontWeight: theme.font.fontWeight,
    backgroundColor: "#E7EBF4",
    color: "#797C82",
    font: "normal normal normal 14px/30px Karla",
  },

  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    color: theme.toolbar.iconColor,
    fontWeight: "300",
  },
  filterIcon: {
    color: theme.toolbar.iconColor,
  },
  listTop: {
    paddingTop: "20px",
  },
  fabBtn: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 30,
    left: "auto",
    position: "fixed",
  },
  btns: {
    paddingTop: "131px",
    paddingRight: "22%",
    paddingBottom: "2px",
  },
  featureList: {
    paddingTop: "3%",
    paddingLeft: "2%",
  },
  textMenu: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "6px 16px",
  },
  dropIcon: {
    marginTop: "8px",
    marginRight: "8px",
  },
});

export default styles;
