import APIRequest from "../util/api/APIRequest";

const login = userDeatails => {
  return APIRequest({
    url: "auth/login",
    method: "POST",
    data: userDeatails
  });
};

const logout = () => {
  return APIRequest({
    url: "auth/logout",
    method: "POST"
  });
};

const AuthenticationService = {
  login,
  logout
};
export default AuthenticationService;
