import { push } from "connected-react-router";
import { withRouter } from "react-router";
import UserDetails from "./userDetails";
import { connect } from "react-redux";
import {
  fetchDesignationList,
  fetchOfficeList,
  fetchUserDeatils,
  fetchUserType,
  fetchRole,
  fetchCameraAccess,
  fetchField,
  fetchZone,
  fetchDistrict,
  resetUserDetail,
  addUser,
  editUser,
  fetchEditCameraAccess,
  checkUsername,
} from "./data/actions";
import { snackbarActions } from "../../../../components/snackbar/data/action";
function mapStateToProps(state) {
  return {
    saveSuccess: state.userDetails.saveSuccess,
    message: state.userDetails.message,
    error: state.userDetails.error,
    fetcheduserType: state.userDetails.fetcheduserType,
    cameras: state.userDetails.cameras,
    role: state.userDetails.role,
    field: state.userDetails.field,
    fetchedzone: state.userDetails.fetchedzone,
    district: state.userDetails.district,
    fetchedOfficeList: state.userDetails.fetchedOfficeList,
    fetchedDesignationList: state.userDetails.fetchedDesignationList,
    fetchedUserDetails: state.userDetails.fetchedUserDetails,
    editCameras: state.userDetails.editCameras,
    checkSuccess: state.userDetails.checkSuccess,
    statusmessage: state.userDetails.statusmessage,
    saveStaus: state.userDetails.saveStaus,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    checkUsername: (name) => dispatch(checkUsername(name)),
    navigateTo: (url) => dispatch(push(url)),
    //fetchOfficeList: () => dispatch(fetchOfficeList()),
    fetchOfficeList: (id) => dispatch(fetchOfficeList(id)),
    fetchDesignationList: () => dispatch(fetchDesignationList()),
    fetchUserDeatils: (id) => dispatch(fetchUserDeatils(id)),
    fetchUserType: () => dispatch(fetchUserType()),
    fetchRole: (id) => dispatch(fetchRole(id)),
    fetchZone: () => dispatch(fetchZone()),
    fetchDistrict: (id) => dispatch(fetchDistrict(id)),
    fetchField: (id) => dispatch(fetchField(id)),
    fetchCameraAccess: (userTypeId, id) =>
      dispatch(fetchCameraAccess(userTypeId, id)),
    addUser: (params) => dispatch(addUser(params)),
    editUser: (params, id) => dispatch(editUser(params, id)),
    fetchEditCameraAccess: (id) => dispatch(fetchEditCameraAccess(id)),
    resetUserDetail: () => dispatch(resetUserDetail()),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserDetails)
);
