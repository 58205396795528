import React, { Fragment } from "react";
import {
  Typography,
  Paper,
  withStyles,
  Grid,
  IconButton,
  Toolbar,
  AppBar,
  Divider,
  Tooltip,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { ReactComponent as ArrowBackIcon } from "../../../../assets/Visual Assets/back.svg";
import RecentActivity from "../../../camera/components/recent_activity";
import { toDateTime } from "../../../../util/date";
import RecentAlerts from "../../../alerts/components/recent_alerts/";
import SkeltonText from "../../../../components/skelton_screen/textfield";
import SkeltonImage from "../../../../components/skelton_screen/image";

const styles = (theme) => ({
  root: {
    height: "85vh",
  },
  imageStyle: {
    display: "flex",
    width: "100%",
    height: "174px",
    padding: "9.5px",
    borderRadius: theme.border.radius,
  },
  detailsStyle: {
    padding: "16px",
    borderRadius: theme.border.radius,
    width: "100%",
    paddingBottom: "28px",
    boxShadow: "0px 0px 4px #0000000D",
  },
  scroller: {
    margin: "0 auto",
    height: "43vh",
    [theme.breakpoints.down("md")]: {
      height: "42vh",
    },
    overflow: "auto",
    borderRadius: theme.border.radius,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
  },
  toolbar: {
    padding: "2px",
    backgroundColor: theme.colors.whiteColor,
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    marginLeft: "11px",
  },
  heading: {
    textAlign: "left",
    font: "normal normal bold 20px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginLeft: "4px",
    marginTop: "37px",
  },
  alertDetailsGrid: {
    paddingLeft: "28px",
    paddingRight: "27px",
    paddingTop: "16px",
  },
  nextPrev: {
    font: "normal normal bold 14px Karla",
    color: "#2B3D51",
    paddingRight: "27px",
    paddingTop: "36px",
  },
  rightLeftArrow: {
    paddingTop: "36px",
  },
  detailsPaper: {
    paddingTop: "22px",
    borderRadius: theme.border.radius,
    width: "100%",
    paddingBottom: "28px",
    boxShadow: "0px 0px 4px #0000000D",
  },
  alertDetailsTitle: {
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    marginLeft: "28px",
    paddingBottom: "21px",
  },
  divider: { border: "1px solid #E7EBF4", opacity: 1 },
  firstAlertGrid: {
    paddingBottom: "24px",
    height: "120px",
    paddingLeft: "18px",
    paddingRight: "18px",
    paddingTop: "23px",
  },
  firstAlertDetails: {
    paddingLeft: "12px",
  },

  title: {
    textAlign: "left",
    font: "normal normal normal 16px/36px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
  },
  subTitle: {
    textAlign: "left",
    font: "normal normal bold 16px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
  },
  alertDetails: {
    borderLeft: "1px solid #E7EBF4",
    paddingLeft: "12.5px",
  },
  secondAlertGrid: {
    paddingLeft: "18px",
    paddingRight: "18px",
    minHeight: "75px",
    overflow: "auto",
    marginTop: "14px",
  },
  imageGrid: { paddingLeft: "28px", paddingRight: "27px", paddingTop: "21px" },
  tableGrid: { paddingLeft: "8px", paddingRight: "7px", paddingTop: "17px" },
  activityGrid: { padding: "12px" },
  alertGrid: { padding: "12px" },
  overallGrid: {
    minHeight: "12vh",
    maxHeight: "70vh",
    overflowX: "hidden",
    overflowY: "auto",
    "@media screen and (min-width: 1900px)": {
      maxHeight: "80vh",
      minHeight: "12vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
    /* devices (width:1280px) */
    "@media screen and (width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "76vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
    /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "73vh",
      overflowX: "hidden",
      overflowY: "auto",
    },
  },
});

class AlertView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchedRecord: {},
      listIds: [],
      listRange: "",
      itemIndex: "",
      itemIndex_1: "",
    };
  }
  componentDidMount() {
    if (this.props.match.params.record_id) {
      this.props.fetchRecord(
        this.props.match.params.record_id,
        this.props.event_timestamp
      );
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.fetchedRecord != this.props.fetchedRecord) {
      this.props.setPlateNumber(this.props.fetchedRecord.plate);
      this.setState({
        fetchedRecord: this.props.fetchedRecord,
      });
    }
  }
  onPrevItem = () => {
    const event_timestamp = this.state.fetchedRecord.event_timestamp;
    // const data = {
    //   search_timestamp: event_timestamp,
    //   type: "prev",
    // };
    var data = this.props.filterParams;
    var newObject = {
      search_timestamp: event_timestamp,
      type: "prev",
    };
    data = { ...newObject, ...data };
    this.props.fetchNextandPrev(data);
  };
  onNextItem = () => {
    const event_timestamp = this.state.fetchedRecord.event_timestamp;
    // const data = {
    //   search_timestamp: event_timestamp,
    //   type: "next",
    // };
    var data = this.props.filterParams;
    var newObject = {
      search_timestamp: event_timestamp,
      type: "next",
    };
    data = { ...newObject, ...data };

    this.props.fetchNextandPrev(data);
  };

  onBackButtonClick = (e) => {
    e.preventDefault();
    this.props.navigateTo("/history/");
  };
  render() {
    const { classes, loading } = this.props;
    return (
      <Fragment>
        <Grid container justify={"space-between"}>
          <Grid item>
            <Typography className={classes.heading}>
              <IconButton onClick={(e) => this.onBackButtonClick(e)}>
                <ArrowBackIcon />
              </IconButton>
              View History
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.nextPrev}>
              <span className={classes.rightLeftArrow}>
                <IconButton onClick={(e) => this.onPrevItem()}>
                  <ChevronLeftIcon />
                </IconButton>
                <IconButton onClick={(e) => this.onNextItem()}>
                  <ChevronRightIcon />
                </IconButton>
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.overallGrid}>
          <Grid container spacing={2}>
            <Grid xs={12} className={classes.alertDetailsGrid}>
              <Paper className={classes.detailsPaper}>
                <AppBar position="static" elevation={0}>
                  <Toolbar variant="dense" className={classes.toolbar}>
                    <Typography
                      id="tableTitle"
                      className={classes.alertDetailsTitle}
                    >
                      History Details
                    </Typography>
                  </Toolbar>
                </AppBar>
                <Divider className={classes.divider} />
                <Grid container xs={12} className={classes.firstAlertGrid}>
                  <Grid item xs={2} className={classes.firstAlertDetails}>
                    <Typography className={classes.title}>
                      Alert Type
                    </Typography>
                    <Typography className={classes.subTitle}>
                      {/* {this.state.fetchedRecord &&
                    this.state.fetchedRecord.alert_type != null &&
                    this.state.fetchedRecord.alert_type != ""
                      ? this.state.fetchedRecord.alert_type
                      : "-"} */}
                      {loading ? (
                        <SkeltonText />
                      ) : this.state.fetchedRecord &&
                        this.state.fetchedRecord.alert_type != null &&
                        this.state.fetchedRecord.alert_type != "" ? (
                        this.state.fetchedRecord.alert_type
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.alertDetails}>
                    <Typography className={classes.title}>
                      Alert Level
                    </Typography>
                    <Typography className={classes.subTitle}>
                      {/* {this.state.fetchedRecord &&
                    this.state.fetchedRecord.alert_level != null &&
                    this.state.fetchedRecord.alert_level != ""
                      ? this.state.fetchedRecord.alert_level
                      : "-"} */}
                      {loading ? (
                        <SkeltonText />
                      ) : this.state.fetchedRecord &&
                        this.state.fetchedRecord.alert_level != null &&
                        this.state.fetchedRecord.alert_level != "" ? (
                        this.state.fetchedRecord.alert_level
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.alertDetails}>
                    <Typography className={classes.title}>Plate</Typography>
                    <Typography className={classes.subTitle}>
                      {/* {this.state.fetchedRecord &&
                    this.state.fetchedRecord.plate != ""
                      ? this.state.fetchedRecord.plate
                      : "-"} */}
                      {loading ? (
                        <SkeltonText />
                      ) : this.state.fetchedRecord &&
                        this.state.fetchedRecord.plate != "" ? (
                        this.state.fetchedRecord.plate
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.alertDetails}>
                    <Typography className={classes.title}>
                      Vehicle Category
                    </Typography>
                    <Typography className={classes.subTitle}>
                      {/* {this.state.fetchedRecord &&
                    this.state.fetchedRecord.vehicle_category != ""
                      ? this.state.fetchedRecord.vehicle_category
                      : "-"} */}
                      {loading ? (
                        <SkeltonText />
                      ) : this.state.fetchedRecord &&
                        this.state.fetchedRecord.vehicle_category != "" ? (
                        this.state.fetchedRecord.vehicle_category
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.alertDetails}>
                    <Typography className={classes.title}>
                      Date and Time
                    </Typography>
                    <Typography className={classes.subTitle}>
                      {/* {this.state.fetchedRecord &&
                    this.state.fetchedRecord.event_timestamp != ""
                      ? toDateTime(this.state.fetchedRecord.event_timestamp)
                      : "-"} */}
                      {loading ? (
                        <SkeltonText />
                      ) : this.state.fetchedRecord &&
                        this.state.fetchedRecord.event_timestamp != "" ? (
                        toDateTime(this.state.fetchedRecord.event_timestamp)
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.alertDetails}>
                    <Typography className={classes.title}>
                      Confidence
                    </Typography>
                    <Typography className={classes.subTitle}>
                      {/* {this.state.fetchedRecord &&
                    this.state.fetchedRecord.confidence != ""
                      ? this.state.fetchedRecord.confidence
                      : "-"} */}
                      {loading ? (
                        <SkeltonText />
                      ) : this.state.fetchedRecord &&
                        this.state.fetchedRecord.confidence != "" ? (
                        this.state.fetchedRecord.confidence
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container xs={12} className={classes.secondAlertGrid}>
                  <Grid item xs={2} className={classes.firstAlertDetails}>
                    <Typography className={classes.title}>Location</Typography>
                    <Typography className={classes.subTitle}>
                      {/* {this.state.fetchedRecord &&
                    this.state.fetchedRecord.location != null &&
                    this.state.fetchedRecord.location != ""
                      ? this.state.fetchedRecord.location
                      : "-"} */}
                      {loading ? (
                        <SkeltonText />
                      ) : this.state.fetchedRecord &&
                        this.state.fetchedRecord.location != null &&
                        this.state.fetchedRecord.location != "" ? (
                        this.state.fetchedRecord.location.length > 14 ? (
                          <Tooltip
                            title={this.state.fetchedRecord.location}
                            placement="bottom"
                          >
                            <b>
                              {this.state.fetchedRecord.location.substring(
                                0,
                                12
                              ) + "..)"}
                            </b>
                          </Tooltip>
                        ) : (
                          this.state.fetchedRecord.location
                        )
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.alertDetails}>
                    <Typography className={classes.title}>Camera</Typography>
                    <Typography className={classes.subTitle}>
                      {/* {this.state.fetchedRecord &&
                      this.state.fetchedRecord.camera_id != ""
                        ? this.state.fetchedRecord.camera_id
                        : "-"} */}
                      {loading ? (
                        <SkeltonText />
                      ) : this.state.fetchedRecord &&
                        this.state.fetchedRecord.camera_id != "" ? (
                        this.state.fetchedRecord.camera_id
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.alertDetails}>
                    <Typography className={classes.title}>Lane</Typography>
                    <Typography className={classes.subTitle}>
                      {/* {this.state.fetchedRecord &&
                    this.state.fetchedRecord.lane_id != ""
                      ? this.state.fetchedRecord.lane_id
                      : "-"} */}
                      {loading ? (
                        <SkeltonText />
                      ) : this.state.fetchedRecord &&
                        this.state.fetchedRecord.lane_id != "" ? (
                        this.state.fetchedRecord.lane_id
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>

                  {this.state.fetchedRecord.vehicle_category === "truck" ||
                  this.state.fetchedRecord.vehicle_category === "mini-truck" ||
                  this.state.fetchedRecord.vehicle_category ===
                    "tempo-traveller" ? (
                    <>
                      <Grid item xs={2} className={classes.alertDetails}>
                        <Typography className={classes.title}>
                          E-Way Bill Status
                        </Typography>
                        <Typography className={classes.subTitle}>
                          {/* {this.state.fetchedRecord.alert_status.toString() ===
                        "verified"
                          ? "Valid"
                          : "Not Valid"} */}
                          {loading ? (
                            <SkeltonText />
                          ) : this.state.fetchedRecord.alert_status.toString() ===
                            "verified" ? (
                            "Valid"
                          ) : (
                            "Not Valid"
                          )}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                  <Grid item xs={2} className={classes.alertDetails}>
                    <Typography className={classes.title}>Status</Typography>
                    <Typography className={classes.subTitle}>
                      {/* {this.state.fetchedRecord &&
                    this.state.fetchedRecord.alert_status != "" &&
                    this.state.fetchedRecord.alert_status != null
                      ? this.state.fetchedRecord.alert_status
                      : "-"} */}
                      {loading ? (
                        <SkeltonText />
                      ) : this.state.fetchedRecord &&
                        this.state.fetchedRecord.alert_status != "" &&
                        this.state.fetchedRecord.alert_status != null ? (
                        this.state.fetchedRecord.alert_status
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Grid>
                  {(this.state.fetchedRecord.defect_found_flag &&
                    this.state.fetchedRecord.defect_found_flag === "Y") ||
                  (this.state.fetchedRecord.defect_found_flag &&
                    this.state.fetchedRecord.defect_found_flag === "y") ? (
                    <>
                      <Grid item xs={2} className={classes.alertDetails}>
                        <Typography className={classes.title}>
                          Remark
                        </Typography>
                        <Typography className={classes.subTitle}>
                          {/* {this.state.fetchedRecord.remark.toString()} */}
                          {loading ? (
                            <SkeltonText />
                          ) : (
                            this.state.fetchedRecord.remark.toString()
                          )}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                  {(this.state.fetchedRecord.defect_found_flag &&
                    this.state.fetchedRecord.defect_found_flag === "N") ||
                  (this.state.fetchedRecord.defect_found_flag &&
                    this.state.fetchedRecord.defect_found_flag === "n") ? (
                    <>
                      <Grid item xs={2} className={classes.alertDetails}>
                        <Typography className={classes.title}>
                          Commodity Category
                        </Typography>
                        <Typography className={classes.subTitle}>
                          {/* {this.state.fetchedRecord.commodity_category.toString()} */}
                          {loading ? (
                            <SkeltonText />
                          ) : (
                            this.state.fetchedRecord.commodity_category.toString()
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} className={classes.alertDetails}>
                        <Typography className={classes.title}>
                          Remark
                        </Typography>
                        <Typography className={classes.subTitle}>
                          {/* {this.state.fetchedRecord.remark.toString()} */}
                          {loading ? (
                            <SkeltonText />
                          ) : (
                            this.state.fetchedRecord.remark.toString()
                          )}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                  {this.state.fetchedRecord.defect_found_flag &&
                  this.state.fetchedRecord.defect_found_flag === "N/A" ? (
                    <>
                      <Grid item xs={2} className={classes.alertDetails}>
                        <Typography className={classes.title}>
                          Remark
                        </Typography>
                        <Typography className={classes.subTitle}>-</Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Paper>
            </Grid>
            <Grid xs={12} className={classes.imageGrid}>
              <Paper className={classes.detailsStyle}>
                <AppBar
                  position="static"
                  elevation={0}
                  className={classes.toolbar}
                >
                  <Typography className={classes.tableTitle}>Images</Typography>
                </AppBar>
                <Grid xs={12} container>
                  <Grid md={4} xs={12} item>
                    {loading ? (
                      <SkeltonImage />
                    ) : (
                      <img
                        className={classes.imageStyle}
                        src={this.state.fetchedRecord.cropped_image}
                        alt="Cropped_Image"
                      />
                    )}
                    {/* <img
                    className={classes.imageStyle}
                    src={this.state.fetchedRecord.cropped_image}
                    alt="Cropped Image"
                  /> */}
                  </Grid>
                  <Grid xs={12} md={4} item>
                    {loading ? (
                      <SkeltonImage />
                    ) : (
                      <img
                        className={classes.imageStyle}
                        src={this.state.fetchedRecord.full_image}
                        alt="Full_Image"
                      />
                    )}
                    {/* <img
                    className={classes.imageStyle}
                    src={this.state.fetchedRecord.full_image}
                    alt="Full Image"
                  /> */}
                  </Grid>
                  <Grid xs={12} md={4} item>
                    {loading ? (
                      <SkeltonImage />
                    ) : (
                      <img
                        className={classes.imageStyle}
                        src={this.state.fetchedRecord.evidence_image}
                        alt="Evidence_Image"
                      />
                    )}
                    {/* <img
                    className={classes.imageStyle}
                    src={this.state.fetchedRecord.evidence_image}
                    alt="Evidence Image"
                  /> */}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Grid container className={classes.tableGrid}>
            <Grid item md={6} className={classes.activityGrid}>
              <Paper className={classes.scroller}>
                <RecentActivity />
              </Paper>
            </Grid>

            <Grid item md={6} className={classes.alertGrid}>
              <Paper className={classes.scroller}>
                <RecentAlerts />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
export default withStyles(styles)(AlertView);
