import APIRequest from "../util/api/APIRequest";

class SummaryReportService {
  fetchReport = (datas) => {
    return APIRequest({
      url: datas.generateUrl,
      method: "POST",
      params: datas.generateParams,
      data: datas.generatedata,
    });
  };
  exportReport = (datas) => {
    return APIRequest({
      url: datas.exportUrl,
      params: datas.exportParams,
      data: datas.exportdata,
      method: "POST",
    });
  };
}
export default SummaryReportService;
