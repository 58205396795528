import { VEHICLE_LIST, RESET_VEHICLE_LIST } from "./constants";
export const getVehicleList = (data) => {
  return (dispatch) => {
    if (data != undefined) {
      dispatch(vehicle(data));
    }
  };

  function vehicle(res) {
    return { type: VEHICLE_LIST, vehicle: res };
  }
};

export const resetVehicleList = () => {
  return (dispatch) => {
    dispatch(vehicle());
  };

  function vehicle() {
    return { type: RESET_VEHICLE_LIST };
  }
};
