import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./styles";
import messageConstants from "../../../../services/validator/MsgConsts/messageConstants";
import {
  Toolbar,
  TableRow,
  Typography,
  withStyles,
  Paper,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  Tooltip,
  IconButton,
  Popper,
  TextField,
  Chip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { toDateTime } from "../../../../util/date";
import { ReactComponent as SortArrow } from "../../../../assets/Visual Assets/sort_down.svg";
import { ReactComponent as DropIcon } from "../../../../assets/SvgIcons/dropdown.svg";
import { ReactComponent as ClearIcon } from "../../../../assets/Visual Assets/clear.svg";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
const toolbarStyles = (theme) => ({
  root: {
    borderRadius: theme.border.radius,
    padding: "11px",
  },
  spacer: {
    flex: "1 1 1%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    color: theme.colors.chartHeading,
    textAlign: "left",
    fontWeight: "bold",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    opacity: 1,
    marginTop: "6px",
    marginLeft: "10px",
    width: "275px",
    height: "22px",
  },
  filterIcon: {
    opacity: 1,
    color: theme.colors.filterIconColor,
    width: "30px",
    height: "30px",
  },
  clearIcon: {
    width: "22px",
    height: "23px",
    color: "#A4AFC6",
    opacity: 1,
  },
  divider: { border: "1px solid #E7EBF4", opacity: 1 },
  textMenu: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "6px 16px",
  },

  textField: {
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "40px",
    marginLeft: "-2px",
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    "&.MuiAutocomplete-input": {
      minWidth: 0,
    },
  },
  inputRoot: {
    "&.MuiAutocomplete-inputRoot": {
      minWidth: 0,
    },
  },
  inputBox: {
    "&.MuiAutocomplete-hasPopupIcon": {
      minWidth: 0,
      paddingRight: 0,
    },
    "&.MuiOutlinedInput-root": {
      minWidth: 0,
      paddingRight: 0,
    },
  },
  dropIcon: {
    marginTop: "8px",
    marginRight: "8px",
  },
  popIcon: {
    marginTop: "6px",
    marginRight: "6px",
  },
  popIconOpen: {
    marginTop: "4px",
    marginRight: "6px",
  },
  textNoOptions: {
    letterSpacing: "0px",
    opacity: 1,
    fontSize: "18px",
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  gridContainer: {
    display: "flex",
    flexDirection: "row",
  },
  popper: {
    width: "100%",
    borderRadius: "9px",
    paddingTop: "0px",
    paddingBottom: "0px",
    overflow: "auto",
  },
});
const options = [
  { key: "Valid", value: "Valid" },
  { key: "Invalid", value: "Invalid" },
  { key: "N/A", value: "N/A" },
];

const CustomPopper = function (props) {
  return <Popper {...props} placement="bottom" disablePortal transition />;
};

class EnhancedTableToolbar extends React.Component {
  render() {
    const {
      classes,
      showFullScreenWindow,
      onChangeFullScreen,
      vehicles,
      handleChangeVehicle,
      selectedVehicles,
      handleStatusChange,
      selectedStatus,
      alerts,
      handleChangeAlertType,
      selectedAlertType,
      onFilterReset,
    } = this.props;

    return (
      <>
        <Toolbar className={classNames(classes.root)}>
          <Typography
            variant="h6"
            id="tableTitle"
            className={classes.tableTitle}
          >
            Live Data
          </Typography>
          <div className={classes.spacer} />
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                size="small"
                disableCloseOnSelect
                limitTags={1}
                disableClearable="true"
                disableOpenOnFocus="true"
                options={vehicles}
                value={selectedVehicles}
                name="vehicle_category"
                getOptionLabel={(option) => (option.value ? option.value : "")}
                onChange={(e, value) => handleChangeVehicle(e, value)}
                popupIcon={<DropIcon />}
                PopperComponent={CustomPopper}
                classes={{
                  input: classes.textValue,
                  option: classes.textValue,
                  popupIndicatorOpen: classes.popIconOpen,
                  popupIndicator: classes.popIcon,
                  noOptions: classes.textNoOptions,
                  hasPopupIcon: classes.inputBox,
                  inputRoot: classes.inputRoot,
                  popper: classes.popper,
                }}
                renderTags={() =>
                  selectedVehicles.length > 2 ? (
                    <>
                      <Chip
                        elevation={0}
                        variant="default"
                        label={
                          selectedVehicles[0].value.length > 15
                            ? selectedVehicles[0].value.substring(0, 10) + "..."
                            : selectedVehicles[0].value
                        }
                        size="small"
                      />
                      <Typography> + more</Typography>
                    </>
                  ) : selectedVehicles.length === 1 ? (
                    selectedVehicles.map((i) => {
                      return (
                        <Chip
                          elevation={0}
                          variant="default"
                          label={i.value}
                          size="small"
                        />
                      );
                    })
                  ) : (
                    selectedVehicles.map((i) => {
                      return (
                        <Chip
                          elevation={0}
                          variant="default"
                          label={
                            i.value.length > 12
                              ? i.value.substring(0, 8) + "..."
                              : i.value
                          }
                          size="small"
                        />
                      );
                    })
                  )
                }
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    className={classes.textField}
                    label="Vehicle Category"
                    name="vehicle_category"
                    id="outlined-margin-dense"
                    margin="dense"
                    variant="outlined"
                    {...params}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                  ></TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                size="small"
                disableCloseOnSelect
                limitTags={1}
                disableClearable="true"
                disableOpenOnFocus="true"
                options={options}
                value={selectedStatus}
                name="status_of_eway_bill"
                getOptionLabel={(option) => (option.key ? option.key : "")}
                onChange={(e, value) => handleStatusChange(e, value)}
                popupIcon={<DropIcon />}
                PopperComponent={CustomPopper}
                classes={{
                  input: classes.textValue,
                  option: classes.textValue,
                  popupIndicatorOpen: classes.popIconOpen,
                  popupIndicator: classes.popIcon,
                  noOptions: classes.textNoOptions,
                  hasPopupIcon: classes.inputBox,
                  inputRoot: classes.inputRoot,
                }}
                renderTags={() =>
                  selectedStatus.map((i) => {
                    return (
                      <Chip
                        elevation={0}
                        variant="default"
                        label={i.value}
                        size="small"
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    className={classes.textField}
                    label="Status of E-way Bill"
                    name="status_of_eway_bill"
                    id="outlined-margin-dense"
                    margin="dense"
                    variant="outlined"
                    {...params}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                  ></TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                multiple
                size="small"
                disableCloseOnSelect
                limitTags={1}
                disableClearable="true"
                disableOpenOnFocus="true"
                options={alerts}
                value={selectedAlertType}
                name="alert_type"
                getOptionLabel={(option) => (option.key ? option.key : "")}
                onChange={(e, value) => handleChangeAlertType(e, value)}
                popupIcon={<DropIcon />}
                PopperComponent={CustomPopper}
                classes={{
                  input: classes.textValue,
                  option: classes.textValue,
                  popupIndicatorOpen: classes.popIconOpen,
                  popupIndicator: classes.popIcon,
                  noOptions: classes.textNoOptions,
                  hasPopupIcon: classes.inputBox,
                  inputRoot: classes.inputRoot,
                }}
                renderTags={() =>
                  selectedAlertType.length > 1 ? (
                    <>
                      <Chip
                        elevation={0}
                        variant="default"
                        label={
                          selectedAlertType[0].value.length > 13
                            ? selectedAlertType[0].value.substring(0, 13) +
                              "..."
                            : selectedAlertType[0].value
                        }
                        size="small"
                      />
                      <Typography> + more</Typography>
                    </>
                  ) : (
                    selectedAlertType.map((i) => {
                      return (
                        <Chip
                          elevation={0}
                          variant="default"
                          label={
                            i.value.length > 31
                              ? i.value.substring(0, 31) + "..."
                              : i.value
                          }
                          size="small"
                        />
                      );
                    })
                  )
                }
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    className={classes.textField}
                    label="Alert Type"
                    name="alert_type"
                    id="outlined-margin-dense"
                    margin="dense"
                    variant="outlined"
                    {...params}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                  ></TextField>
                )}
              />
            </Grid>
          </Grid>
          <div className={classes.actions1}>
            <Tooltip
              title="Clear fields"
              PopperProps={{
                disablePortal: true,
              }}
              disableFocusListener
              disableTouchListener
            >
              <IconButton
                aria-label="Filter list"
                onClick={(e) => onFilterReset(e)}
              >
                <ClearIcon className={classes.clearIcon} />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.actions}>
            <Tooltip
              title={showFullScreenWindow ? "Exit Full screen" : "Full Screen"}
              PopperProps={{
                disablePortal: true,
              }}
              disableFocusListener
              disableTouchListener
            >
              <IconButton aria-label="Full screen" onClick={onChangeFullScreen}>
                {showFullScreenWindow ? (
                  <FullscreenExitIcon className={classes.filterIcon} />
                ) : (
                  <FullscreenIcon className={classes.filterIcon} />
                )}
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
        <Divider className={classes.divider} />
      </>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const headerStyles = (theme) => ({
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px/43px Karla",
    letterSpacing: "0px",
    opacity: 1,
    borderBottom: "1px solid #E7EBF4",
    paddingBottom: "2px",
    paddingTop: "2px",
    backgroundColor: "white",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
    paddingBottom: "20px",
    paddingLeft: "0px",
    paddingTop: "15px",
    paddingRight: "0px",
    backgroundColor: "white",
  },
});
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, classes } = this.props;

    return (
      <TableHead>
        <TableRow className={classes.tableHead}>
          <TableCell
            className={classes.tableTitle}
            style={{
              paddingLeft: "10px",
            }}
          >
            Camera & Location
          </TableCell>
          <TableCell className={classes.tableTitle}>
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
              PopperProps={{
                disablePortal: true,
              }}
              disableFocusListener
              disableTouchListener
            >
              <TableSortLabel
                active={true}
                direction={order}
                onClick={this.createSortHandler("event_timestamp")}
                IconComponent={SortArrow}
              >
                Date & Time
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell className={classes.tableTitle}>Vehicle No.</TableCell>
          <TableCell className={classes.tableTitle}>Vehicle Category</TableCell>
          <TableCell className={classes.tableTitle}>
            Status of E-way Bill
          </TableCell>
          <TableCell
            className={classes.tableTitle}
            style={{
              paddingLeft: "10px",
            }}
          >
            Alert Type
          </TableCell>
          <TableCell className={classes.tableTitle}>Alert Level</TableCell>
          <TableCell className={classes.tableTitle}>Vehicle History</TableCell>
        </TableRow>
      </TableHead>
    );
  }
}
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};
EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);

const multiFilter = (arr: Object[], filters: Object) => {
  const filterKeys = Object.keys(filters);
  return arr.filter((eachObj) => {
    return filterKeys.every((eachKey) => {
      if (!filters[eachKey].length) {
        return true; // passing an empty filter means that filter is ignored.
      }
      return filters[eachKey].includes(eachObj[eachKey]);
    });
  });
};
class VehicleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: "event_timestamp",
      selected: null,
      camera: "",
      errors: { camera: "" },
      selectedId: 0,
      showFullScreenWindow: false,
      types: "vehicleCategory",
      Alert: [],
      selectedVehicles: [],
      selectedStatus: [],
      selectedAlertType: [],
      filterParams: {},
    };
  }
  componentDidMount() {
    this.props.fetchVehicle(this.state.types);
    this.props.fetchAlert();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.fetchVehicles !== this.props.fetchVehicles) {
      this.setState({
        data: this.props.fetchVehicles,
      });
    }
    if (prevProps.fetchedAlert !== this.props.fetchedAlert) {
      this.setState({
        ...this.state,
        Alert: this.props.fetchedAlert,
      });
    }
  }

  componentWillUnmount() {
    // this.socket.disconnect(true);
    // this.socket = null;
  }

  handleClick = (event, record) => {
    this.setState({
      ...this.state,
      showFullScreenWindow: false,
      selected: record,
    });
    this.props.setSelectedRecord(record);
    this.props.setPlateNumber(record.plate);
  };
  isSelected = (record) => this.state.selected === record;

  handleMouseOver = (id) => {
    this.setState({ ...this.state, selectedId: id });
  };

  handleMouseOut = () => {
    this.setState({ ...this.state, selectedId: 0 });
  };

  handleSelectCamera = (event, value) => {
    this.setState(
      {
        ...this.state,
        camera: value,
        errors: {
          ...this.state.errors,
          [event.target.name]: "",
        },
      }
      // () => {
      //   let params = this.state.data.camera.id;
      //   this.props.fetchCameralist(params);
      // }
    );
  };
  chooseColor = (data, classes) => {
    if (data.alert_status === "verified" && data.eway_bill_status === "Valid") {
      return classes.greenPlate;
    } else if (
      data.alertFlag === true &&
      data.alert_status === "alert" &&
      data.alert_level !== "High"
    ) {
      return classes.bluePlate;
    } else if (
      data.alertFlag === true &&
      data.alert_status === "alert" &&
      data.alert_level === "High"
    ) {
      return classes.redPlate;
    } else if (data.alertFlag === true && data.alert_status === "warning") {
      return classes.orangePlate;
    } else {
      return classes.greyPlate;
    }
  };
  chooseSideColor = (data, classes) => {
    if (data.alert_status === "verified" && data.eway_bill_status === "Valid") {
      return classes.alertIndicatorGreen;
    } else if (
      data.alertFlag === true &&
      data.alert_status === "alert" &&
      data.alert_level !== "High"
    ) {
      return classes.alertIndicatorBlue;
    } else if (
      data.alertFlag === true &&
      data.alert_status === "alert" &&
      data.alert_level === "High"
    ) {
      return classes.alertIndicatorRed;
    } else if (data.alertFlag === true && data.alert_status === "warning") {
      return classes.alertIndicatorOrange;
    } else {
      return classes.alertIndicatorGrey;
    }
  };
  selectCamera = () => {
    this.props.navigateTo("/selectCamera");
  };
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "asc";

    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }

    this.setState({ order, orderBy });
  };
  onChangeFullScreen = () => {
    this.setState({
      ...this.state,
      showFullScreenWindow: !this.state.showFullScreenWindow,
    });
  };

  handleChangeVehicle = (event, value) => {
    this.setState(
      {
        ...this.state,
        selectedVehicles: value,
      },
      () => {
        this.setState({
          ...this.state,
          filterParams: {
            ...this.state.filterParams,
            vehicle_category: this.state.selectedVehicles.map((i) => {
              return i.key;
            }),
          },
        });
      }
    );
  };

  handleStatusChange = (event, value) => {
    this.setState(
      {
        ...this.state,
        selectedStatus: value,
      },
      () => {
        this.setState({
          ...this.state,
          filterParams: {
            ...this.state.filterParams,
            eway_bill_status: this.state.selectedStatus.map((i) => {
              return i.key;
            }),
          },
        });
      }
    );
  };

  handleChangeAlertType = (event, value) => {
    this.setState(
      {
        ...this.state,
        selectedAlertType: value,
      },
      () => {
        this.setState({
          ...this.state,
          filterParams: {
            ...this.state.filterParams,
            alert_type: this.state.selectedAlertType.map((i) => {
              return i.key;
            }),
          },
        });
      }
    );
  };
  onFilterReset = (event) => {
    this.setState({
      ...this.state,
      selectedVehicles: [],
      selectedAlertType: [],
      selectedStatus: [],
      filterParams: {},
    });
  };
  render() {
    const { classes } = this.props;
    const { order, orderBy, showFullScreenWindow, filterParams } = this.state;
    const colorLegends = (
      <>
        <Grid container spacing={2} justify="flex-end" alignItems="center">
          <Grid item>
            <div className={classes.greenDiv}></div>
            <div className={classes.textDiv}>With E-way Bill</div>
          </Grid>
          <Grid item>
            <div className={classes.orangeDiv}></div>
            <div className={classes.textDiv}> Review</div>
          </Grid>
          <Grid item>
            <div className={classes.redDiv}></div>
            <div className={classes.textDiv}> Alert</div>
          </Grid>
          <Grid item>
            <div className={classes.blueDiv}></div>
            <div className={classes.textDiv}> Reduced Alert</div>
          </Grid>
          <Grid item>
            <div className={classes.greyDiv}></div>
            <div className={classes.textDiv}> Others</div>
          </Grid>
        </Grid>
      </>
    );

    return (
      <Grid className={classes.grid}>
        <Paper
          className={showFullScreenWindow ? classes.fullscrnroot : classes.root}
        >
          <div>
            {" "}
            <EnhancedTableToolbar
              showFullScreenWindow={showFullScreenWindow}
              onChangeFullScreen={this.onChangeFullScreen}
              vehicles={this.props.fetchVehicles}
              alerts={this.state.Alert}
              selectedVehicles={this.state.selectedVehicles}
              selectedAlertType={this.state.selectedAlertType}
              selectedStatus={this.state.selectedStatus}
              handleChangeAlertType={this.handleChangeAlertType}
              handleChangeVehicle={this.handleChangeVehicle}
              handleStatusChange={this.handleStatusChange}
              onFilterReset={this.onFilterReset}
            />
          </div>

          <div className={classes.topList}>{colorLegends}</div>
          {this.props.vehicleList && this.props.vehicleList.length === 0 ? (
            <div className={classes.notAddedStyle}>
              {messageConstants.NOT_FOUND}
            </div>
          ) : stableSort(
              multiFilter(this.props.vehicleList, filterParams),
              getSorting(order, orderBy)
            ).length === 0 ? (
            <div className={classes.notAddedStyle}>
              {messageConstants.NOT_FOUND}
            </div>
          ) : (
            <>
              <div
                className={
                  showFullScreenWindow
                    ? classes.tableWrapperFullScrn
                    : classes.tableWrapper
                }
              >
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  stickyHeader
                >
                  <EnhancedTableHead
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.state.warningCount}
                    order={order}
                  />
                  <TableBody>
                    {this.props.vehicleList &&
                      stableSort(
                        multiFilter(this.props.vehicleList, filterParams),
                        getSorting(order, orderBy)
                      ).map((row) => {
                        const isSelected = this.isSelected(row.message);
                        return (
                          <TableRow
                            hover
                            className={classes.zebrafont}
                            onClick={(event) => this.handleClick(event, row)}
                            aria-checked={isSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isSelected}
                            onMouseOver={() => this.handleMouseOver(row.id)}
                            onMouseOut={this.handleMouseOut}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={
                                this.state.selectedId === row.id
                                  ? classes.selectedText
                                  : classes.tableCell
                              }
                              style={{
                                paddingLeft: "10px",
                              }}
                            >
                              {row.location.length > 15
                                ? row.location.substring(0, 15) + "..."
                                : row.location}
                            </TableCell>
                            <TableCell
                              className={
                                this.state.selectedId === row.id
                                  ? classes.selectedText
                                  : classes.tableCell
                              }
                            >
                              {toDateTime(row.event_timestamp)}
                            </TableCell>
                            <TableCell
                              className={
                                this.state.selectedId === row.id
                                  ? classes.selectedText
                                  : classes.tableCell
                              }
                            >
                              <Paper
                                className={classNames(
                                  this.chooseColor(row, classes)
                                )}
                                style={{
                                  borderRadius: "4px",
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  paddingTop: "7px",
                                  paddingBottom: "7px",
                                  color: "white",
                                  minWidth: "100px",
                                  display: "inline-block",
                                  textTransform: "uppercase",
                                }}
                              >
                                {row.plate}
                              </Paper>
                            </TableCell>
                            <TableCell
                              className={
                                this.state.selectedId === row.id
                                  ? classes.selectedText
                                  : classes.tableCell
                              }
                            >
                              {" "}
                              {row.vehicle_category}
                            </TableCell>
                            <TableCell
                              className={
                                this.state.selectedId === row.id
                                  ? classes.selectedText
                                  : classes.tableCell
                              }
                            >
                              {row.eway_bill_status
                                ? row.eway_bill_status
                                : "---"}
                            </TableCell>
                            <TableCell
                              className={
                                this.state.selectedId === row.id
                                  ? classes.selectedText
                                  : classes.tableCell
                              }
                              style={{ paddingLeft: "10px" }}
                            >
                              {row.alert_type && row.alert_type.length > 10
                                ? row.alert_type.substring(0, 10) + "..."
                                : "---"}
                            </TableCell>
                            <TableCell
                              className={
                                this.state.selectedId === row.id
                                  ? classes.selectedText
                                  : classes.tableCell
                              }
                            >
                              {row.alert_level ? row.alert_level : "---"}
                            </TableCell>
                            <TableCell
                              className={
                                this.state.selectedId === row.id
                                  ? classes.selectedText
                                  : classes.tableCell
                              }
                            >
                              {row.vehicle_history
                                ? row.vehicle_history
                                : "---"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
        </Paper>
      </Grid>
    );
  }
}

VehicleList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VehicleList);
