import React from "react";
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  withStyles,
  Divider,
} from "@material-ui/core";
// import DownloadIcon from "@material-ui/icons/SaveAlt";
import { ReactComponent as DownloadIcon } from "../../../assets/SvgIcons/download.svg";
import { ReactComponent as FilterListIcon } from "../../../assets/SvgIcons/filter.svg";

const toolbarStyles = (theme) => ({
  root: {
    padding: "12px 35px",
    paddingRight: "16px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    marginTop: "43px",
    marginRight: "18px",
    marginLeft: "22px",
    borderTopLeftRadius: theme.border.radius,
    borderTopRightRadius: theme.border.radius,
  },
  divider: {
    marginRight: "18px",
    marginLeft: "22px",
    border: "1px solid #E7EBF4",
  },
  spacer: {
    flex: "1 1 20%",
  },
  // actions: {
  //   paddingRight: theme.spacing.unit,
  // },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px/22px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
  },
  filterIcon: {
    color: "#A4AFC6",
  },
});
const ReportTitle = (props) => {
  const { classes, showToolbarIcons, showDownloadIcon } = props;

  return (
    <>
      <Toolbar className={classes.root}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          {props.title}
        </Typography>

        <div className={classes.spacer} />

        <div className={classes.actions}>
          <Tooltip title="Filter list">
            <IconButton
              aria-label="Filter list"
              onClick={props.toggleFilterWindow}
            >
              <FilterListIcon className={classes.filterIcon} />
            </IconButton>
          </Tooltip>
          {showToolbarIcons && showDownloadIcon ? (
            <Tooltip title="Download Report">
              <IconButton aria-label="Download Report">
                <DownloadIcon
                  className={classes.filterIcon}
                  onClick={props.exportReport}
                />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </Toolbar>
      <Divider className={classes.divider} />
    </>
  );
};

export default withStyles(toolbarStyles)(ReportTitle);
