import React, { Fragment } from "react";
import classNames from "classnames";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  Table,
  TableBody,
  TablePagination,
  Modal,
  IconButton,
  Tooltip,
  MenuItem,
  Divider,
  InputBase,
  FormControl,
  Select,
  InputLabel,
  InputAdornment,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { ReactComponent as Edit } from "../../assets/Visual Assets/edit.svg";
import { ReactComponent as Delete } from "../../assets/Visual Assets/delete.svg";
import { ReactComponent as ClearIcon } from "../../assets/Visual Assets/clear.svg";
import { ReactComponent as SearchIcon } from "../../assets/Visual Assets/search.svg";
import messageConstants from "../../services/validator/MsgConsts/messageConstants";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import StorageHandler from "../../util/storage/index";
import clsx from "clsx";
import { ReactComponent as DownArrow } from "../../assets/SvgIcons/dropdown.svg";
import Pagination from "@material-ui/lab/Pagination";
import "../userMaster/style.css";
import SkeltonScreen from "../../components/skelton_screen";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const rows = [
  {
    id: "slno",
    numeric: false,
    disablepadding: true,
    label: "Sl No",
    active: true,
  },

  {
    id: "office_name",
    numeric: false,
    disablepadding: true,
    label: "Office Name",
    active: false,
  },
  {
    id: "zone",
    numeric: false,
    disablepadding: true,
    label: "Zone",
    active: false,
  },
  {
    id: "district",
    numeric: false,
    disablepadding: true,
    label: "District",
    active: false,
  },
];
const headerStyles = (theme) => ({
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 14px",
    borderColor: "#E7EBF4",
    backgroundColor: "white"
  },
  tableHead1: {
    textAlign: "right",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 14px",
    borderColor: "#E7EBF4",
    backgroundColor: "white"
    // marginLeft: "100px",
  },
  tableh: {
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 24px",
    borderColor: "#E7EBF4",
    backgroundColor: "white"
  },
});
class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.render.props.onRequestSort(event, property);
  };
  render() {
    const { order, orderBy } = this.props;
    const { classes } = this.props;
    const attribute_permissions = new StorageHandler().getAttributPermissions();
    return (
      <TableHead>
        <TableRow>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                sortDirection={orderBy === row.id ? order : false}
                className={row.active ? classes.tableh : classes.tableHead}
              >
                {row.label}
              </TableCell>
            );
          }, this)}

          {attribute_permissions.edit_status == true ? (
            <TableCell className={classes.tableHead1}>Edit</TableCell>
          ) : (
              ""
            )}
          {attribute_permissions.delete_status == true ? (
            <TableCell
              className={classes.tableHead1}
              style={{ paddingRight: "35px" }}
            >
              Delete
            </TableCell>
          ) : (
              ""
            )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSeleceted: PropTypes.number.isRequired,
  onRequestsort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);
const toolbarStyles = (theme) => ({});

let EnhancedTableToolbar = (props) => {
  const { classes, showFilterWindow } = props;

  return null;
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.border.radius,
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
  },
  root1: {
    backgroundColor: theme.toolbar.backgroundColor,
  },
  spacer: {
    flex: "1 1 1%",
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: "33px",
    paddingRight: "31px",
    // paddingTop: "6px",

    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      minHeight: "12vh",
      maxHeight: "45vh",
      overflowY: "auto",
    },
    // /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
  },
  butt: {
    background: "white",
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "5px",
    paddingBottom: "4.5px",
    height: "57px",
  },
  cursor: {
    cursor: "pointer",
  },
  notAddedStyle: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 55,
    marginLeft: 33,
    marginRight: 33,
    marginTop: 33,
    font: "normal normal normal 24px Karla;",
  },
  notAddedStyle1: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 55,
    // marginLeft: 33,
    // marginRight: 33,
    marginTop: 33,
    font: "normal normal normal 24px Karla;",
  },
  filterbutton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.palette.secondary.main,
    color: theme.colors.whiteColor,
  },
  filterclearButton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.BlueButton,
    color: theme.colors.whiteColor,
  },
  filtercloseButton: {
    fontWeight: theme.font.fontWeight,
    color: theme.colors.blackColor,
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  buttons: {
    marginTop: "14px",
    marginRight: "9px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    width: "111px",
    height: "42px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    textTransform: "none",
  },
  btn: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    "&:hover": {
      background: "#E7EBF4 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginRight: "4px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  btns: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  tableTitle: {
    marginTop: "6px",
    marginLeft: "10px",
    width: "275px",
    height: "22px",
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
  },
  grid: {
    marginTop: "21px",
    paddingTop: "22px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
    paddingBottom: "24px",
  },
  divider: {
    marginTop: "15px",
    height: "0px",
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
  filterIcon: {
    width: "25px",
    height: "23px",
    color: "#A4AFC6",
    opacity: 1,
  },
  actions1: {
    marginTop: "9px",
    paddingRight: "16px",
  },
  textStyle: {
    color: "#ffffff",
  },
  root2: {
    color: "red",
    backgroundColor: "white",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "0px solid red",
    borderRadius: "5px",
    "& .MuiInputLabel-shrink": {
      paddingLeft: "7px",
      paddingTop: "5px",
    },
    "& MuiInputLabel-formControl ": {
      // paddingLeft: "20px",
      left: "10",
    },
    "& MuiInputLabel-root ": {
      paddingLeft: "50px",
    },
    "& MuiFormLabel-root ": {
      paddingLeft: "10px",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
  textField1: {
    marginTop: "19px",
    // paddingLeft: "14px",
    // paddingTop: "12px",
    // paddingBottom: "13px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    height: "42px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    //  border: "1px solid #C4CCDC",
    borderRadius: "5px",
    opacity: 1,
  },
  paginationGrid: {
    paddingLeft: "25px",
    paddingRight: "38px",
  },
  paginationText: {
    // textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingTop: "35px",
    paddingLeft: "10px"
  },
  textField2: {
    marginTop: "19px",
    // paddingLeft: "14px",
    // paddingTop: "12px",
    // paddingBottom: "13px",
    // width: "178px",
    height: "40px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // border: "1px solid #C4CCDC",
    borderRadius: "5px",
    opacity: 1,
    letterSpacing: "0px",
    color: "#797C82",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },
  },
  dialogBox: {
    // width: "327px",
    height: "161px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: "20px",
  },
  dialogContent: {
    //width: "247px",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "11px",

    marginRight: "17px",
    marginLeft: "16px",

    font: "normal normal normal 16px Karla",
    // marginRight: "17px",
    // marginLeft: "16px",
  },
  dialogActions: {
    "&.MuiDialogActions-root": {
      justifyContent: "center",
    },
  },
  gridContainer: {
    display: "flex",
    flexDirection: "row",
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textMenu: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "6px 16px",
  },
  dropIcon: {
    marginTop: "8px",
    marginRight: "8px",
  },
});

class OfficeList extends React.Component {
  constructor(props) {
    sessionStorage.setItem("officeError", false);
    super(props);
    this.state = {
      order: "asc",
      orderBy: "date_time",
      selected: [],
      data: [],
      modal: false,
      page: 0,
      rowsPerPage: 10,
      officesCount: -1,
      open: false,
      zones: "",
      districts: "",
      office: "",
      filter_type: "",
      filter_location: "",
      filter_office: "",
      filter_zone: "",
      filter_district: "",
      filter_status: false,
      isTrue: false,
    };
  }

  componentDidMount() {
    this.props.fetchOfficeList(
      this.state.page,
      this.state.rowsPerPage,
      this.props.filterParams
    );
    this.props.fetchZone();
    if (this.state.filter_zone === "") {
      this.props.fetchDistricts(0);
    } else {
      this.props.fetchDistricts(this.state.filter_zone);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.officeLists != this.props.officeLists) {
      this.setState({
        data: this.props.officeLists,
        officesCount: this.props.officesCount,
      });
    }
    if (prevState.page != this.state.page) {
      let params = {
        page: this.state.page,
        limit: this.state.rowsPerPage,
        office:
          this.state.filter_office == "" ? null : this.state.filter_office,
        location:
          this.state.filter_location == "" ? null : this.state.filter_location,
        zone: this.state.filter_zone == "" ? null : this.state.filter_zone,
        district:
          this.state.filter_district == "" ? null : this.state.filter_district,
      };
      this.props.onFilterChange(params);
      // this.props.fetchOfficeList(this.state.page, this.state.rowsPerPage);
    }
    if (prevState.rowsPerPage != this.state.rowsPerPage) {
      this.props.fetchOfficeList(this.state.page, this.state.rowsPerPage);
    }
    if (this.props.deleteSuccess) {
      this.props.resetDelete();
      let params = {
        Page: this.state.page,
        rowsPerPage: this.state.rowsPerPage,
        filterParams: this.state.filterParams,
      };
      this.props.fetchOfficeList(this.state.page, this.state.rowsPerPage);
      this.props.showSnackbar(
        this.props.deleteSuccessReposnse.message,
        "success"
      );
    }
    if (prevProps.zones != this.props.zones) {
      this.setState({ zones: this.props.zones });
    }
    if (prevProps.districts != this.props.districts) {
      this.setState({ districts: this.props.districts });
    }
    if (prevProps.office != this.props.office) {
      this.setState({
        data: this.props.office.rows,
        officesCount: this.props.office.count ? this.props.office.count : 0,
      });
    }
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleChangePage = (event, pagess) => {
    this.setState({ page: pagess - 1 });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  performAction = () => {
    this.props.navigateTo(`/master/office-master/add`);
  };
  editOffice = (office_id) => {
    this.props.navigateTo(`/master/office-master/edit/${office_id}`);
  };
  handleOpen = (officeId, officeName) => {
    this.setState({
      officeId: officeId,
      officeName: officeName,
      modal: !this.state.modal,
    });
  };

  deleteOffice = (officeId) => {
    this.setState({
      modal: !this.state.modal,
    });
    this.props.deleteOffice(officeId);
  };

  onOfficeNameChange = (event) => {
    this.setState({ filter_office: event.target.value, page: 0 }, () => {
      let params = {
        page: this.state.page,
        limit: this.state.rowsPerPage,
        office:
          this.state.filter_office == "" ? null : this.state.filter_office,
        location:
          this.state.filter_location == "" ? null : this.state.filter_location,
        zone: this.state.filter_zone == "" ? null : this.state.filter_zone,
        district:
          this.state.filter_district == "" ? null : this.state.filter_district,
      };

      this.props.onFilterChange(params);
      this.setState({ filter_status: true });
    });
  };
  onZoneNameChange = (event) => {
    this.setState({ filter_zone: event.target.value, page: 0 }, () => {
      this.setState({ districts: "" });
      this.props.fetchDistricts(this.state.filter_zone);
      let params = {
        page: this.state.page,
        limit: this.state.rowsPerPage,
        office:
          this.state.filter_office == "" ? null : this.state.filter_office,
        location:
          this.state.filter_location == "" ? null : this.state.filter_location,
        zone: this.state.filter_zone == "" ? null : this.state.filter_zone,
        filter_district: "",
      };

      this.props.onFilterChange(params);
      this.setState({ filter_status: true });
    });
  };
  onDistrictNameChange = (event) => {
    this.setState({ filter_district: event.target.value, page: 0 }, () => {
      let params = {
        page: this.state.page,
        limit: this.state.rowsPerPage,
        office:
          this.state.filter_office == "" ? null : this.state.filter_office,
        location:
          this.state.filter_location == "" ? null : this.state.filter_location,
        zone: this.state.filter_zone == "" ? null : this.state.filter_zone,
        district:
          this.state.filter_district == "" ? null : this.state.filter_district,
      };
      this.props.onFilterChange(params);
    });
  };

  onFilterReset = () => {
    this.setState({
      filter_status: true,
      page: 0,
      filter_office: "",
      filter_zone: "",
      filter_district: "",
      districts: "",
    });
    this.props.fetchDistricts(0);
    let params = {
      page: 0,
      limit: this.state.rowsPerPage,
    };
    this.props.onFilterChange(params);
  };
  componentWillUnmount() {
    sessionStorage.setItem("officeError", false);
  }
  render() {
    const { classes } = this.props;

    EnhancedTableToolbar.propTypes = {
      classes: PropTypes.object.isRequired,
    };

    EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, this.state.offendersCount - page * rowsPerPage);
    let firstPageNum = page * rowsPerPage + 1;
    const attribute_permissions = new StorageHandler().getAttributPermissions();

    return (
      <>
        <Grid className={classes.grid}>
          <Paper className={classes.paper}>
            <Toolbar className={classNames(classes.roots)}>
              <Typography
                variant="h6"
                id="tableTitle"
                className={classes.tableTitle}
              >
                Office Master
              </Typography>
              <div className={classes.spacer} />
              <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="&nbsp; Search Office"
                    className={classes.textField1}
                    onChange={this.onOfficeNameChange}
                    value={this.state.filter_office}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      classes: { root: classes.textValue },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  {" "}
                  <TextField
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={this.onZoneNameChange}
                    value={this.state.filter_zone}
                    label="&nbsp; Select Zone"
                    fullWidth
                    select
                    variant="outlined"
                    margin="dense"
                    className={classes.textField2}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    InputProps={{ classes: { root: classes.textValue } }}
                    SelectProps={{
                      IconComponent: DownArrow,
                      classes: {
                        icon: classes.dropIcon,
                        iconOpen: classes.dropIconOpen,
                      },
                    }}
                  >
                    <MenuItem value="" classes={{ root: classes.textMenu }}>
                      <em>None</em>
                    </MenuItem>
                    {this.state.zones !== "" && this.state.zones.rows
                      ? this.state.zones.rows.map((data) => (
                        <MenuItem
                          value={data.zone_id}
                          classes={{ root: classes.textMenu }}
                        >
                          {data.zone_name}
                        </MenuItem>
                      ))
                      : null}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={this.onDistrictNameChange}
                    value={this.state.filter_district}
                    label="&nbsp; Select District"
                    fullWidth
                    select
                    variant="outlined"
                    margin="dense"
                    className={classes.textField2}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    InputProps={{ classes: { root: classes.textValue } }}
                    SelectProps={{
                      IconComponent: DownArrow,
                      classes: {
                        icon: classes.dropIcon,
                        iconOpen: classes.dropIconOpen,
                      },
                    }}
                  >
                    <MenuItem value="" classes={{ root: classes.textMenu }}>
                      <em>None</em>
                    </MenuItem>
                    {this.state.districts !== ""
                      ? this.state.districts.rows
                        ? this.state.districts.rows.map((data) => (
                          <MenuItem
                            value={data.district_id}
                            classes={{ root: classes.textMenu }}
                          >
                            {data.district_name}
                          </MenuItem>
                        ))
                        : this.state.districts.map((data) => (
                          <MenuItem
                            value={data.district_id}
                            classes={{ root: classes.textMenu }}
                          >
                            {data.district_name}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>
                </Grid>
              </Grid>
              <div className={classes.actions1}>
                <Tooltip title="Clear fields">
                  <IconButton
                    aria-label="Filter list"
                    onClick={this.onFilterReset}
                  >
                    <ClearIcon className={classes.filterIcon} />
                  </IconButton>
                </Tooltip>
              </div>
              {new StorageHandler().getAttributPermissions() &&
                new StorageHandler().getAttributPermissions().add_status ==
                true ? (
                  <div className={classes.actions}>
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation={true}
                      className={classes.buttons}
                      onClick={() => this.performAction()}
                    >
                      Add Office
                  </Button>
                  </div>
                ) : (
                  ""
                )}
            </Toolbar>
            <Divider className={classes.divider} />

            {/* {this.props.loading && this.state.officesCount === -1 ? (
              <SkeltonScreen />
            ) : (
              console.log("**************************************")
            )} */}
            {this.props.loading === false && this.state.officesCount == 0 ? (
              <div className={classes.notAddedStyle}>
                {messageConstants.NOT_ADDED}
              </div>
            ) : (
                // ) : (
                <>
                  <div className={classes.tableWrapper}>
                    {data && data.length > 0 ? (
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        align="center"
                        stickyHeader
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={this.handleRequestSort}
                          rowCount={this.state.officesCount}
                        />
                        <TableBody>
                          {data.map((n, i) => {
                            const isSelected = this.isSelected(n.id);
                            return (
                              <TableRow
                                role="checkbox"
                                aria-checked={isSelected}
                                tabIndex={-1}
                                key={n.id}
                                selected={isSelected}
                                className={classes.zebrafont}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ paddingLeft: "32px" }}
                                  className={classes.tableCell}
                                >
                                  {firstPageNum + i}
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  {n.office}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {n.zone_master ? n.zone_master.zone_name : ""}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {n.district_master
                                    ? n.district_master.district_name
                                    : ""}
                                </TableCell>
                                {attribute_permissions.edit_status == true ? (
                                  <TableCell
                                    className={classes.tableCell}
                                    style={{ textAlign: "right" }}
                                  >
                                    <i
                                      onClick={() => this.editOffice(n.officeId)}
                                    >
                                      <Edit className={classes.cursor} />
                                    </i>
                                  </TableCell>
                                ) : (
                                    ""
                                  )}

                                {attribute_permissions.delete_status == true ? (
                                  <TableCell
                                    className={classes.tableCell}
                                    style={{
                                      // paddingLeft: "25px",
                                      paddingRight: "50px",
                                      textAlign: "right",
                                    }}
                                  >
                                    <i
                                      onClick={() =>
                                        this.handleOpen(n.officeId, n.office)
                                      }
                                    >
                                      <Delete className={classes.cursor} />
                                    </i>
                                  </TableCell>
                                ) : (
                                    ""
                                  )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    ) : sessionStorage.getItem("officeError") === "true" ? (
                      <div className={classes.notAddedStyle1}>
                        {messageConstants.NOT_ADDED}
                      </div>
                    ) : (
                          <SkeltonScreen />
                        )}
                  </div>
                  {data && data.length > 0 ? (
                    <Grid container xs={12} className={classes.paginationGrid}>
                      <Grid item xs={4} >
                        <Typography className={classes.paginationText}>
                          {/* {"Showing " + ((parseInt(reportData.page_no) * this.state.limit) - 9) + " to " + (parseInt(reportData.page_no) * this.state.limit > reportData.total_count ? reportData.total_count : parseInt(reportData.page_no) * this.state.limit) + " of " + +reportData.total_count + " Vehicles"} */}
                          {((parseInt(firstPageNum))) + " - " + (parseInt(page + 1) * rowsPerPage > this.state.officesCount ? this.state.officesCount : parseInt(page + 1) * rowsPerPage) + " of " + this.state.officesCount + " Offices"}
                        </Typography>
                      </Grid>
                      <Grid item style={{ float: "right" }} xs={8}>
                        <Pagination
                          count={Math.ceil(this.state.officesCount / rowsPerPage)}
                          shape="rounded"
                          page={page + 1}
                          onChange={this.handleChangePage}
                          className="pagination"
                          color="secondary"
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </>
              )}
            {/* <TablePagination
              component="div"
              count={this.state.officesCount}
              rowsPerPageOptions={[5, 10, 15]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            /> */}
            <Dialog
              open={this.state.modal}
              aria-labelledby="customized-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{
                paper: classes.dialogBox,
              }}
            >
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  className={classes.dialogContent}
                >
                  Are you sure want to delete {this.state.officeName}?
                  {/* Are you sure want to delete this ? */}
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btn}
                  disableElevation={true}
                  onClick={() => this.handleOpen()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btns}
                  disableElevation={true}
                  onClick={() => this.deleteOffice(this.state.officeId)}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </>
    );
  }
}

OfficeList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(OfficeList);
