import { connect } from "react-redux";
import { withRouter } from "react-router";
import OfficeDetails from "./officeDetails";
import { push } from "connected-react-router";

import {
  fetchOfficeDeatils,
  fetchZone,
  fetchDistrict,
  fetchField,
  addOffice,
  editOffice,
  resetOfficeDetail,
} from "./data/action";
import { snackbarActions } from "components/snackbar/data/action";

function mapStateToProps(state) {
  return {
    saveStaus: state.officeDetails.saveStaus,
    saveSuccess: state.officeDetails.saveSuccess,
    statusmessage: state.officeDetails.statusmessage,
    message: state.officeDetails.message,
    error: state.officeDetails.error,
    field: state.officeDetails.field,
    zone: state.officeDetails.zone,
    district: state.officeDetails.district,
    fetchedOfficeDetails: state.officeDetails.fetchedOfficeDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => dispatch(push(url)),
    fetchOfficeDeatils: (id) => dispatch(fetchOfficeDeatils(id)),
    fetchZone: (type) => dispatch(fetchZone(type)),
    fetchDistrict: (id) => dispatch(fetchDistrict(id)),
    fetchField: (id) => dispatch(fetchField(id)),
    addOffice: (params) => dispatch(addOffice(params)),
    editOffice: (params, id) => dispatch(editOffice(params, id)),
    resetOfficeDetail: () => dispatch(resetOfficeDetail()),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfficeDetails)
);
