import React, { Fragment } from "react";
import {
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  IconButton,
  Divider,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import classNames from "classnames";
import propTypes from "prop-types";
import validator from "../../../../services/validator/validator/";
import { find } from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import Recaptcha from "react-google-invisible-recaptcha";
// import CaptchaSiteKeyMasters from "../../../../services/siteKeyMasters";
import { ReactComponent as BackIcon } from "../../../../assets/SvgIcons/back.svg";
import { ReactComponent as DownArrow } from "../../../../assets/SvgIcons/dropdown.svg";
import StyledRadio from "../../../../components/filteralert_form/styledRadio";
import Box from "@material-ui/core/Box";

const toolbarStyles = (theme) => ({
  root: {
    marginTop: "11px",
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "2px",
    marginBottom: "13px",
    marginLeft: "9px",
  },
  iconButton: {
    paddingLeft: "0px",
  },
  divider: {
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
});

let EnhancedTableToolbar = (props) => {
  const { classes } = props;
  return (
    <>
      {/* <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          {" "}
          <IconButton
            onClick={(e) => props.onBackButtonClick(e)}
            classes={{ root: classes.iconButton }}
          >
            <BackIcon />
          </IconButton>
          Assign Squad Master
        </Typography>
        <div className={classes.spacer} />
      </Toolbar>{" "} */}
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          <IconButton
            onClick={(e) => props.onBackButtonClick(e)}
            classes={{ root: classes.iconButton }}
          >
            <BackIcon />
          </IconButton>
          {props.header}
        </Typography>
      </Toolbar>
      <Divider className={classes.divider} />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: propTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  grid: {
    marginTop: "26px",
    paddingTop: "17px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
  },
  root: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
    // width: "100%",
    overflow: "hidden",
    // paddingBottom: "34px",
    // height:"516px"
  },

  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  textField: {
    width: "94%",
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "45px",
  },
  textField3: {
    width: "76%",
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    // height: "45px"
  },
  paperStyle: {
    paddingLeft: "33px",
    paddingRight: "14px",
    paddingBottom: "8px",
  },
  textFielddes: {
    paddingTop: "1%",
  },
  subHeading: {
    textAlign: "left",
    font: "normal normal bold 16px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    paddingBottom: "9px",
    paddingTop: "4px",
  },
  button: {
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    opacity: 1,
    textTransform: "none",
    // paddingLeft: "38px",
    // paddingRight: "37px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    height: "42px",
    width: "121px",
  },
  clearButton: {
    width: "121px",
    height: "42px",
    // marginRight: "10px",
    textTransform: "none",
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.colors.BlueButton,
    textAlign: "center",
    width: "121px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
  },
  cancelButton: {
    width: "120px",
    marginBottom: "6%",
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.whiteColor,
  },
  btns: {
    paddingRight: "25%",
  },
  btnGrid: {
    padding: 0,
    paddingLeft: "12px",
  },
  err: {
    paddingLeft: "1%",
  },
  dense: {
    marginTop: 16,
  },

  alignment2: {
    // flexDirection: "row",

    paddingTop: "100px",
    paddingLeft: "10px",
  },

  alignment: {
    flexDirection: "row",

    paddingTop: "10px",
    paddingLeft: "10px",
  },

  typo: {
    textAlign: "left",
    font: "normal normal normal 14px/36px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingRight: "14px",
  },
  formLabel: {
    font: "normal normal normal 14px/36px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
  },
  radbtns: {
    paddingLeft: "5%",
  },
  formitems: {
    paddingTop: "6%",
  },
  menu: {
    width: 200,
    height: 300,
  },
  timeGrid: {
    paddingBottom: "65px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  popIconOpen: {
    marginTop: "4px",
    marginRight: "6px",
  },
  popIcon: {
    marginTop: "6px",
    marginRight: "6px",
  },
  textNoOptions: {
    letterSpacing: "0px",
    opacity: 1,
    fontSize: "18px",
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  formHelperText: {
    color: theme.colors.redColor,
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  subGrid: {
    paddingBottom: "6px",
  },
  subGrid: {
    // paddingBottom: "18px",
    // paddingTop: "10px",
  },
  mainGrid: { paddingBottom: "15px" },
  dateIcon: {
    marginTop: "0px",
    marginRight: "-8px",
  },
  AddMemButton: {
    backgroundColor: "#E7EBF4",

    height: "42px",
    width: "88px",

    textTransform: "none",

    fontSize: "13px",
    fontFamily: "Karla",
  },
});

class SquadDetails extends React.Component {
  constructor(props) {
    super(props);
    this.onResolved = this.onResolved.bind(this);

    this.state = {
      data: {
        captcha: "",

        zone: "",
        district: "",
        field: "",
        office: "",
        squad: "",
        leader: "",
        status: "true",
        members: [],
        pen: "",
        squad_name: "",
      },
      errors: {
        zone: "",
        district: "",
        field: "",
        office: "",
        squad: "",
        leader: "",
        // status: "true",
        members: "",
        pen: "",
        squad_name: "",
      },
      rules: {
        pen: {
          number: true,
        },
        squad_name: {
          required: true,
          minLength: 7,
          maxLength: 20,
          alphanumericwithspecialchar: true,
        },

        field: {
          required: true,
        },
        zone: {
          required: true,
        },
        district: {
          required: true,
        },
        office: {
          required: true,
        },
        leader: {
          required: true,
        },
        members: {
          required: false,
        },
      },
      Field: [],
      Zone: [],
      District: [],
      Office: [],
      Leader: [],
      newMemberList: [],
    };
  }

  componentDidMount() {
    if (this.props.match.params.squad_id) {
      this.props.fetchSquadDeatils(this.props.match.params.squad_id);
    }
    this.props.fetchZone();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.squad_id &&
      this.props !== prevProps &&
      this.props.squadDetails !== prevProps.squadDetails
    ) {
      const { squadDetails } = this.props;
      this.props.fetchDistrict(squadDetails.zone_master.zone_id);
      this.props.fetchField(squadDetails.district_master.district_id);
      this.props.fetchOffice(squadDetails.location_master.location_id);
      this.props.fetchLeader(squadDetails.office.officeId);
      this.setState({
        data: {
          ...this.state.data,
          squad_name: this.props.squadDetails.squad_name,
          zone: this.props.squadDetails.zone_master,
          district: this.props.squadDetails.district_master,
          office: this.props.squadDetails.office,
          field: this.props.squadDetails.location_master,
          leader: this.props.squadDetails.leader,
          squad: this.props.squadDetails.squad_name,
          members: this.props.squadDetails.member,
          status:
            this.props.squadDetails.status != undefined
              ? this.props.squadDetails.status.toString()
              : "",
        },
      });
    }

    if (prevProps.zone != this.props.zone) {
      this.setState({
        Zone: this.props.zone,
      });
    }

    if (prevProps.memberData != this.props.memberData) {
      let temp = this.state.Leader;
      if (this.props.memberData === null) {
        // this.props.showSnackbar("invalid PEN", "error");
      } else {
        let NewMemb = this.props.memberData;
        let penAdd = this.state.newMemberList;
        penAdd.push(NewMemb.pen);
        temp.push(NewMemb);
        this.setState(
          {
            newMemberList: penAdd,
            Leader: temp,
            data: {
              ...this.state.data,
              pen: "",
            },
          },
          () => {}
        );
        this.props.showSnackbar("New Member Added", "info");
      }
    }

    if (prevProps.district != this.props.district) {
      this.setState({
        District: this.props.district,
      });
    }
    if (prevProps.field != this.props.field) {
      this.setState({
        Field: this.props.field,
      });
    }
    if (prevProps.office != this.props.office) {
      this.setState({
        Office: this.props.office,
      });
    }
    if (prevProps.leader != this.props.leader) {
      this.setState({
        Leader: this.props.leader,
      });
    }
    if (
      this.props.saveSuccess &&
      this.props.statusmessage !== prevProps.statusmessage &&
      !this.props.error &&
      this.props.statusmessage !== "failed"
    ) {
      setTimeout(() => {
        this.props.showSnackbar(this.props.message, "success");
      }, 1000);
      this.props.resetSquadDetail();
      this.props.navigateTo("/squad-master");
    }

    if (
      this.props.saveSuccess &&
      // this.props.statusmessage !== prevProps.statusmessage &&
      !this.props.error &&
      this.props.saveStaus !== prevProps.saveStaus &&
      this.props.statusmessage === "failed"
    ) {
      this.props.showSnackbar(this.props.message, "error");
    }
    if (this.props.error && this.props.error !== this.state.alert) {
      this.props.showSnackbar(this.props.message, "error");
    }
  }
  onBackButtonClick = () => {
    this.props.navigateTo("/squad-master");
  };
  validate = (rules, data) => {
    const errors = validator(rules)(data);
    const hasErrors = find(errors, (error) => error !== "");

    this.setState({ errors });
    return !hasErrors;
  };

  handleSubmit = () => {
    if (this.validate(this.state.rules, this.state.data)) {
      // this.recaptcha.execute();
      this.onResolved();
    }
  };
  onResolved = () => {
    // this.setState({
    //   captcha: this.recaptcha.getResponse(),
    // });

    let tempMember = [];
    this.state.data.members.map((obj) => {
      tempMember.push(obj.userId);
    });
    let params = {
      // captcha: this.state.captcha,
      squad_name: this.state.data.squad_name,
      squad_leader: this.state.data.leader.name,
      status: this.state.data.status,
      location_id: this.state.data.field.location_id,
      district_id: this.state.data.district.district_id,
      zone_id: this.state.data.zone.zone_id,
      office_id: this.state.data.office.officeId,
      leader_id: this.state.data.leader.userId,
      member: tempMember,
    };

    if (this.props.match.params.squad_id) {
      this.props.editSquad(params, this.props.match.params.squad_id);
    } else {
      this.props.addSquad(params);
    }
  };

  handleZone = (e, value) => {
    e.preventDefault();
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          zone: value,
        },
        errors: {
          ...this.state.errors,
          zone: "",
        },
      },
      () => {
        this.props.fetchDistrict(this.state.data.zone.zone_id);
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            district: "",
            field: "",
            office: "",
            leader: "",
            members: [],
          },
        });
      }
    );
  };

  handleDistrict = (e, value) => {
    e.preventDefault();
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,

          district: value,
        },
        errors: {
          ...this.state.errors,
          district: "",
        },
      },
      () => {
        this.props.fetchField(this.state.data.district.district_id);
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            field: "",
            office: "",
            leader: "",
            members: [],
          },
        });
      }
    );
  };

  handleField = (e, value) => {
    e.preventDefault();
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          field: value,
        },
        errors: {
          ...this.state.errors,
          field: "",
        },
      },
      () => {
        this.props.fetchOffice(this.state.data.field.location_id);
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            office: "",
            leader: "",
            members: [],
          },
        });
      }
    );
  };
  handleOffice = (e, value) => {
    e.preventDefault();
    this.setState(
      {
        ...this.state,
        data: {
          ...this.state.data,
          office: value,
        },
        errors: {
          ...this.state.errors,
          office: "",
        },
      },
      () => {
        this.props.fetchLeader(this.state.data.office.officeId);
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            leader: "",
            members: [],
          },
        });
      }
    );
  };
  handleLeader = (e, value) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        leader: value,
        members: [],
      },
      errors: {
        ...this.state.errors,
        leader: "",
      },
    });
  };
  handleSelectMembers = (e, value) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        members: value,
      },
      errors: {
        ...this.state.errors,
        members: "",
      },
    });
  };
  handleClear = () => {
    this.setState({
      ...this.state,
      Leader: [],
      data: {
        ...this.state.data,
        name: "",
        squad_leader: "",
        squad_name: "",
        zone: "",
        district: "",
        field: "",
        office: "",
        leader: "",
        members: "",
        pen: "",
      },
      errors: {
        ...this.state.error,
        name: "",
        squad_leader: "",
        squad_name: "",
        emailID: "",
        phone_no: "",
        zone: "",
        district: "",
        field: "",
        office: "",
        members: "",
        leader: "",
      },
    });
  };
  handleChange = (e) => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [e.target.name]: e.target.value },
      errors: {
        ...this.state.errors,
        [e.target.name]: "",
      },
    });
  };
  handlePenMember = () => {
    let flag = false;
    let district_id = this.state.data.district.district_id;

    let params = {
      pen: this.state.data.pen,
    };
    let rule = {
      pen: {
        required: true,
        number: true,
      },
    };
    this.state.Leader.map((resp) => {
      if (this.state.data.pen === resp.pen) {
        this.props.showSnackbar("Member Already Exist in the Squad", "info");
        flag = true;
      }
    });
    if (this.validate(rule, params)) {
      if (this.state.data.pen.length > 4 && this.state.data.pen.length < 8) {
        if (!flag) {
          this.props.fetchMemberData(this.state.data.pen, district_id);
        }
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            pen: "PEN length should be 5 - 7 ",
          },
        });
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { data, errors, Field, Zone, District, Office, Leader } = this.state;
    return (
      <Grid className={classes.grid}>
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            onBackButtonClick={this.onBackButtonClick}
            header={
              this.props.match.params.squad_id
                ? "Edit Squad Master Details"
                : "Add Squad Master Details"
            }
          />
          <div className={classes.textFielddes}>
            <Fragment>
              <div>
                <Paper elevation={0} className={classes.paperStyle}>
                  <Typography className={classes.subHeading}>
                    Basic Information
                  </Typography>
                  <Grid container className={classes.mainGrid}>
                    <Grid xs={12} md={4} item className={classes.subGrid}>
                      <Autocomplete
                        disableClearable="true"
                        disableOpenOnFocus="true"
                        options={Zone}
                        getOptionLabel={(option) =>
                          option.zone_name ? option.zone_name : ""
                        }
                        onChange={this.handleZone}
                        value={data.zone}
                        popupIcon={<DownArrow />}
                        classes={{
                          input: classes.textValue,
                          option: classes.textValue,
                          popupIndicatorOpen: classes.popIconOpen,
                          popupIndicator: classes.popIcon,
                          noOptions: classes.textNoOptions,
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            className={classes.textField}
                            label="Choose Zone"
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            required
                            name="zone"
                            error={errors.zone ? true : false}
                            {...params}
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                          ></TextField>
                        )}
                      />

                      {errors.zone ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.zone}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>{" "}
                    <Grid xs={12} md={4} item className={classes.subGrid}>
                      <Autocomplete
                        disableClearable="true"
                        disableOpenOnFocus="true"
                        disabled={
                          this.state.zone_enabled === "true" ? true : false
                        }
                        options={District}
                        getOptionLabel={(option) =>
                          option.district_name ? option.district_name : ""
                        }
                        onChange={this.handleDistrict}
                        value={data.district}
                        popupIcon={<DownArrow />}
                        classes={{
                          input: classes.textValue,
                          option: classes.textValue,
                          popupIndicatorOpen: classes.popIconOpen,
                          popupIndicator: classes.popIcon,
                          noOptions: classes.textNoOptions,
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            className={classes.textField}
                            label="Choose District"
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            required
                            name="district"
                            error={errors.district ? true : false}
                            {...params}
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                          ></TextField>
                        )}
                      />

                      {errors.district ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.district}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>{" "}
                    <Grid xs={12} md={4} item className={classes.subGrid}>
                      <Autocomplete
                        disableClearable="true"
                        disableOpenOnFocus="true"
                        disabled={
                          this.state.zone_enabled ||
                          this.state.district_enabled === "true"
                            ? true
                            : false
                        }
                        options={Field}
                        getOptionLabel={(option) =>
                          option.place ? option.place : ""
                        }
                        onChange={this.handleField}
                        value={data.field}
                        popupIcon={<DownArrow />}
                        classes={{
                          input: classes.textValue,
                          option: classes.textValue,
                          popupIndicatorOpen: classes.popIconOpen,
                          popupIndicator: classes.popIcon,
                          noOptions: classes.textNoOptions,
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            className={classes.textField}
                            label="Choose field"
                            name="field"
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            required
                            error={errors.field ? true : false}
                            {...params}
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                          ></TextField>
                        )}
                      />

                      {errors.field ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.field}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid xs={12} md={4} item>
                      <Autocomplete
                        disableClearable="true"
                        disableOpenOnFocus="true"
                        options={Office}
                        getOptionLabel={(option) =>
                          option.office ? option.office : ""
                        }
                        onChange={this.handleOffice}
                        value={data.office}
                        popupIcon={<DownArrow />}
                        classes={{
                          input: classes.textValue,
                          option: classes.textValue,
                          popupIndicatorOpen: classes.popIconOpen,
                          popupIndicator: classes.popIcon,
                          noOptions: classes.textNoOptions,
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            className={classes.textField}
                            label="Choose Office"
                            name="office"
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            required
                            error={errors.office ? true : false}
                            {...params}
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                          ></TextField>
                        )}
                      />

                      {errors.office ? (
                        <FormHelperText className={classes.formHelperText}>
                          {errors.office}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid xs={12} md={4} item className={classes.subDateGrid}>
                      <TextField
                        fullWidth
                        inputProps={{
                          maxLength: 40,
                        }}
                        className={classes.textField}
                        label="Enter Squad Name"
                        id="outlined-margin-dense"
                        margin="dense"
                        name="squad_name"
                        value={data.squad_name}
                        onChange={(e) => this.handleChange(e)}
                        error={errors.squad_name ? true : false}
                        variant="outlined"
                        required
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                        InputProps={{ classes: { root: classes.textValue } }}
                      ></TextField>

                      {errors.squad_name ? (
                        <FormHelperText
                          className={classes.formHelperText}
                          style={{ color: "red" }}
                        >
                          {errors.squad_name}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid xs={12} md={4} item>
                      {Leader ? (
                        <Autocomplete
                          disableClearable="true"
                          disableOpenOnFocus="true"
                          options={Leader.filter((obj) => {
                            if (!this.state.newMemberList.includes(obj.pen)) {
                              return obj;
                            }
                          })}
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          onChange={this.handleLeader}
                          value={data.leader}
                          popupIcon={<DownArrow />}
                          classes={{
                            input: classes.textValue,
                            option: classes.textValue,
                            popupIndicatorOpen: classes.popIconOpen,
                            popupIndicator: classes.popIcon,
                            noOptions: classes.textNoOptions,
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              className={classes.textField}
                              label="Squad Leader"
                              id="outlined-margin-dense"
                              margin="dense"
                              variant="outlined"
                              required
                              name="leader"
                              error={errors.leader ? true : false}
                              {...params}
                              InputLabelProps={{
                                classes: { root: classes.textLabel },
                              }}
                            ></TextField>
                          )}
                        />
                      ) : null}
                      <FormHelperText style={{ color: "red" }}>
                        {this.state.errors.leader}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper elevation={0} className={classes.paperStyle}>
                  <Typography className={classes.subHeading}>
                    Assign Squad Members
                  </Typography>
                  <Grid container className={classes.timeGrid}>
                    <Grid xs={12} md={4} item>
                      {Leader ? (
                        <Autocomplete
                          multiple
                          // disableClearable="true"
                          // disableOpenOnFocus="true"
                          id="size-small-outlined-multi"
                          size="small"
                          options={Leader.filter((obj) => {
                            if (obj.userId !== this.state.data.leader.userId) {
                              return obj;
                            }
                          })}
                          getOptionLabel={(option) => option.name}
                          onChange={this.handleSelectMembers}
                          value={data.members}
                          popupIcon={<DownArrow />}
                          classes={{
                            input: classes.textValue,
                            option: classes.textValue,
                            popupIndicatorOpen: classes.popIconOpen,
                            popupIndicator: classes.popIcon,
                            noOptions: classes.textNoOptions,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              className={classes.textField}
                              label="Select Member Name as per Office"
                              placeholder=" "
                              id="outlined-margin-dense"
                              margin="dense"
                              variant="outlined"
                              // required
                              // name=""
                              InputLabelProps={{
                                classes: { root: classes.textLabel },
                              }}
                            />
                          )}
                        />
                      ) : null}
                      <FormHelperText style={{ color: "red" }}>
                        {this.state.errors.members}
                      </FormHelperText>
                    </Grid>

                    <Grid
                      xs={12}
                      md={5}
                      item
                      // className={classes.subDateGrid}
                      direction="row"
                    >
                      <Box
                        style={{
                          backgroundColor: "#F5F8FF",
                          paddingLeft: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        <Grid
                          item
                          container
                          // spacing={20}
                        >
                          <Tooltip
                            title="To add members from other office teams please add PEN "
                            placement={"top-end"}
                            enterDelay={300}
                          >
                            {/* <span style={{color:"#F5F8FF" }}> */}
                            <TextField
                              style={{ backgroundColor: "#FFFFFF" }}
                              // fullWidth
                              inputProps={{
                                minLength: 5,
                                maxLength: 7,
                              }}
                              className={classes.textField3}
                              label="Enter PEN"
                              id="outlined-margin-dense"
                              margin="dense"
                              name="pen"
                              value={data.pen}
                              onChange={(e) => this.handleChange(e)}
                              error={errors.squadName ? true : false}
                              variant="outlined"
                              InputLabelProps={{
                                classes: { root: classes.textLabel },
                              }}
                              InputProps={{
                                classes: { root: classes.textValue },
                              }}
                            ></TextField>
                            {/* </span> */}
                          </Tooltip>
                          <Grid
                            style={{
                              paddingLeft: "9px",
                              paddingTop: "6px",
                              fontFamily: "Karla",
                            }}
                          >
                            <Button
                              // style={}
                              variant="contained"
                              color="#E7EBF4"
                              fontColor="#797C82"
                              onClick={() => this.handlePenMember()}
                              className={classes.AddMemButton}
                              disableElevation={true}
                            >
                              Add
                            </Button>
                          </Grid>

                          <Grid>
                            <FormHelperText style={{ color: "red" }}>
                              {this.state.errors.pen}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>

                    <Grid xs={12} md={4} item className={classes.subGrid}>
                      <Grid direction="row">
                        <FormControl component="fieldset">
                          <Grid>
                            <RadioGroup
                              aria-label="Is active?"
                              name="status"
                              className={classes.alignment}
                              value={data.status}
                              onChange={(e) => this.handleChange(e)}
                            >
                              <Typography className={classes.typo} variant="h6">
                                Status
                              </Typography>
                              &nbsp;
                              <FormControlLabel
                                classes={{ label: classes.formLabel }}
                                value="true"
                                control={<StyledRadio />}
                                label="Active"
                              />
                              <FormControlLabel
                                classes={{ label: classes.formLabel }}
                                value="false"
                                control={<StyledRadio />}
                                label="Inactive"
                              />
                            </RadioGroup>
                          </Grid>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      xs={12}
                      style={{
                        paddingRight: "25%",
                        paddingTop: "32px",
                        // paddingBottom:"20px"
                      }}
                      container
                    >
                      <Grid item xs={12} md={6}></Grid>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        style={{
                          textAlign: "right",
                          //   "&.SquadDetails-timeGrid-271":{
                          //     paddingBottom: "15px",
                          // }
                        }}
                      >
                        <Button
                          disableElevation={true}
                          variant="contained"
                          className={classes.clearButton}
                          onClick={() => this.handleClear()}
                        >
                          Clear
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={3} className={classes.btnGrid}>
                        <Button
                          disableElevation={true}
                          elevation={0}
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                          onClick={() => this.handleSubmit()}
                        >
                          ASSIGN
                        </Button>
                        <Grid item xs={12} md={1}></Grid>
                        {/* <Recaptcha
                          ref={(ref) => (this.recaptcha = ref)}
                          sitekey={CaptchaSiteKeyMasters}
                          size="invisible"
                          onResolved={this.onResolved}
                        /> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </Fragment>
          </div>
        </Paper>
      </Grid>
    );
  }
}

export default withStyles(styles)(SquadDetails);
