import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
class DashboardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.toggleFilterWindow}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Filter"}</DialogTitle>
        <DialogContent>{"Hiii"}</DialogContent>
        <DialogActions>
          <Button onClick={this.props.toggleFilterWindow} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DashboardModal;
