import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "connected-react-router";
import { doLogout } from "../../screens/login/data/actions";
import AppBar from "./AppBar";
import {
  getWarningCount,
  getMultipleEwaybillCount,
  setOffender,
  getFlammableGoodsCount,
  getCameraLocation,
} from "./data/action";
import {
  getOverviewData,
  getOverviewDatas,
} from "../../screens/dashboard/overview/data/action";
import { setLocationId } from "../../screens/locationSelector/data/action";
import { snackbarActions } from "components/snackbar/data/action";
import { changetoAppbarPath } from "../drawer/data/actions";
import {
  resetVehicleList,
  getVehicleList,
} from "../../screens/camera/components/vehicle_list/data/actions";
import { resetSelectedRecord } from "../../screens/camera/components/vehicle_info/data/actions";
import { resetPlateNumber } from "../../screens/camera/components/recent_activity/data/action";
function mapStateToProps(state) {
  return {
    isAuthenticated: state.authentication.isAuthenticated,
    vehicleList: state.vehicleList.vehicleList,
    showAppDrawer: state.drawer.showAppDrawer,
    warningCount: state.warningDetails.warningCount,
    multipleEwayBillCount: state.warningDetails.multipleEwayBillCount,
    flammableGoodsCount: state.warningDetails.flammableGoodsCount,
    camera_location: state.warningDetails.camera_location,
    locationData: state.locationSelector.filterParams,
    oldLocationData: state.locationSelector.oldLocationData,
    overviewData: state.overview.overviewData,
    messageData: state.overview.messageData,
    appBarPath: state.drawer.appBarPath,
    pathname: state.router.location.pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetPlateNumber: () => dispatch(resetPlateNumber()),
    resetSelectedRecord: () => dispatch(resetSelectedRecord()),
    resetVehicleList: () => dispatch(resetVehicleList()),
    changetoAppbarPath: (data) => dispatch(changetoAppbarPath(data)),
    getOverviewData: (socket, newData, oldData) =>
      dispatch(getOverviewData(socket, newData, oldData)),
    getOverviewDatas: (socket, newData, oldData) =>
      dispatch(getOverviewDatas(socket, newData, oldData)),
    navigateTo: (url) => dispatch(push(url)),
    doLogout: () => dispatch(doLogout()),
    setLocationId: (data, oldData) => dispatch(setLocationId(data, oldData)),
    getCameraLocation: () => dispatch(getCameraLocation()),
    getWarningCount: (socket) => dispatch(getWarningCount(socket)),
    getMultipleEwaybillCount: (socket) =>
      dispatch(getMultipleEwaybillCount(socket)),
    getFlammableGoodsCount: (socket) =>
      dispatch(getFlammableGoodsCount(socket)),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
    getVehicleList: (data) => dispatch(getVehicleList(data)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppBar));
