import {
  FETCH_USER_LIST,
  FETCH_USER_LIST_ERROR,
  DELETE_USER,
  DELETE_USER_ERROR,
  RESET_DELETE,
  DIALOGUE_WINDOW_STATUS,
  FILTER_CHANGE,
  FILTER_CHANGE_ERROR,
  FETCH_ROLE,
  FETCH_ROLE_FAILED,
} from "./constants";

const initialState = {
  userLists: [],
  role: [],
  userListFilter: [],

  usersCount: 0,
  deleteSuccess: false,
  deleteSuccessMessage: "",
  showDialogue: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_LIST:
      return {
        ...state,
        userLists: action.data.data.rows,
        usersCount: action.data.data.count,
      };
    case FETCH_USER_LIST_ERROR:
      sessionStorage.setItem("usermasterError", true);
      return {
        ...initialState,
      };
    case DELETE_USER:
      return {
        ...initialState,
        deleteSuccessMessage: action.data,
        error: action.data.message,
        deleteSuccess: true,
      };
    case DELETE_USER_ERROR:
      return {
        ...initialState,
      };
    case RESET_DELETE:
      return {
        ...initialState,
        deleteSuccess: false,
      };
    case FETCH_ROLE:
      return {
        ...state,
        role: action.data.data,
      };
    case FETCH_ROLE_FAILED:
      return {
        ...state,
        role: [],
      };
    case DIALOGUE_WINDOW_STATUS:
      return {
        ...state,
        showDialogue: !state.showDialogue,
      };
    case FILTER_CHANGE:
      return {
        ...state,
        userListFilter: action.data.data,
      };
    case FILTER_CHANGE_ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
}
