/** @format */

import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import { onRequest, onRequestError } from "./interceptors/requestInterceptor";
import {
  onResponse,
  onResponseError,
} from "./interceptors/responseInterceptor";
const API = () => {
  const defaultOptions = {
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  };
  const instance = axios.create(defaultOptions);
  instance.interceptors.request.use(
    (config) => onRequest(config),
    (error) => onRequestError(error)
  );
  instance.interceptors.response.use(
    (response) => onResponse(response),
    (error) => onResponseError(error)
  );
  return instance;
};
export default API();
