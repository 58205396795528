import {
  FETCH_USER_TYPE,
  FETCH_USER_TYPE_ERROR,
  DIALOGUE_WINDOW_STATUS,
  FILTER_CHANGE,
  FILTER_CHANGE_ERROR,
  OPEN_CLOSE_VIEW_WINDOW,
} from "./constants";
import RoleMasterService from "../../../../../service/RoleMasterService";
import { snackbarActions } from "../../../../../components/snackbar/data/action";
import { progressActions } from "../../../../../components/progressbar/data/action";

export const toggleViewWindow = () => {
  return (dispatch) => {
    dispatch(toggleViewWindow());
  };
  function toggleViewWindow() {
    return { type: OPEN_CLOSE_VIEW_WINDOW };
  }
};

export const fetchUserTypeList = () => {
  let roleMasterService = new RoleMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    roleMasterService.fetchUserTypeList().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_USER_TYPE, data };
  }
  function failure(error) {
    return { type: FETCH_USER_TYPE_ERROR, error };
  }
};

export const dialogueWindowStatus = () => {
  return (dispatch) => {
    // dispatch(progressActions.showProgress());
    dispatch(dialogueWindowStatus());
  };
  function dialogueWindowStatus() {
    return { type: DIALOGUE_WINDOW_STATUS };
  }
};

export const onFilterChange = (type) => {
  let roleMasterService = new RoleMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    roleMasterService.onFilterChange(type).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FILTER_CHANGE, data };
  }
  function failure(error) {
    return { type: FILTER_CHANGE_ERROR, error };
  }
};
