import React, { Fragment } from "react";
import classNames from "classnames";
import {
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  Table,
  TableBody,
  TablePagination,
  Modal,
  IconButton,
  FormHelperText,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import PropTypes from "prop-types";
import messageConstants from "../../services/validator/MsgConsts/messageConstants";
import Filter from "../../components/filteruser_form/Filter";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import StorageHandler from "../../util/storage/index";
import { ReactComponent as Edit } from "../../assets/Visual Assets/edit.svg";
import { ReactComponent as Delete } from "../../assets/Visual Assets/delete.svg";
import { ReactComponent as ClearIcon } from "../../assets/Visual Assets/clear.svg";
import { ReactComponent as SearchIcon } from "../../assets/Visual Assets/search.svg";
import { ReactComponent as DownArrow } from "../../assets/SvgIcons/dropdown.svg";
import Pagination from "@material-ui/lab/Pagination";
import "./style.css";
import SkeltonScreen from "../../components/skelton_screen";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const rows = [
  {
    id: "slno",
    numeric: false,
    disablepadding: true,
    label: "Sl No",
    active: true,
  },

  {
    id: "fullname",
    numeric: false,
    disablepadding: true,
    label: "Full Name",
    active: false,
  },
  {
    id: "username",
    numeric: false,
    disablepadding: true,
    label: "User Name",
    active: false,
  },
  {
    id: "role",
    numeric: false,
    disablepadding: true,
    label: "Role",
    active: false,
  },
  {
    id: "status",
    numeric: false,
    disablepadding: true,
    label: "Status",
    active: false,
  },
];
const headerStyles = (theme) => ({
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 14px",
    borderColor: "#E7EBF4",
    backgroundColor: "white"
  },
  tableh: {
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 24px",
    borderColor: "#E7EBF4",
    backgroundColor: "white"
  },
  tableHead1: {
    textAlign: "right",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 14px",
    borderColor: "#E7EBF4",
    backgroundColor: "white"
    // marginLeft: "100px",
  },
});
class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.render.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;
    const { classes } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                sortDirection={orderBy === row.id ? order : false}
                className={row.active ? classes.tableh : classes.tableHead}
              >
                {row.label}
              </TableCell>
            );
          }, this)}
          {new StorageHandler().getAttributPermissions() &&
            new StorageHandler().getAttributPermissions().edit_status == true ? (
              <TableCell className={classes.tableHead1}>Edit</TableCell>
            ) : null}
          {new StorageHandler().getAttributPermissions() &&
            new StorageHandler().getAttributPermissions().delete_status ==
            true ? (
              <TableCell
                className={classes.tableHead1}
                style={{ paddingRight: "35px" }}
              >
                Delete
              </TableCell>
            ) : null}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSeleceted: PropTypes.number.isRequired,
  onRequestsort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);
const toolbarStyles = (theme) => ({});

let EnhancedTableToolbar = (props) => {
  const { classes, showFilterWindow } = props;

  return null;
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.border.radius,
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
  },
  root1: {
    backgroundColor: theme.toolbar.backgroundColor,
  },
  spacer: {
    flex: "1 1 1%",
  },
  table: {
    minWidth: "100%",
  },
  paginationGrid: {
    paddingLeft: "25px",
    paddingRight: "38px",
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: "33px",
    paddingRight: "31px",
    // paddingTop: "6px",

    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      minHeight: "12vh",
      maxHeight: "45vh",
      overflowY: "auto",
    },
    // /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
  },
  butt: {
    background: "white",
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "5px",
    paddingBottom: "4.5px",
    height: "57px",
  },
  notAddedStyle: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,

    padding: 55,

    marginLeft: 33,
    marginRight: 33,
    marginTop: 33,
    font: "normal normal normal 24px Karla;",
  },
  filterbutton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.palette.secondary.main,
    color: theme.colors.whiteColor,
  },
  filterclearButton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.BlueButton,
    color: theme.colors.whiteColor,
  },
  filtercloseButton: {
    fontWeight: theme.font.fontWeight,
    color: theme.colors.blackColor,
  },

  buttons: {
    marginTop: "14px",
    marginRight: "9px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    width: "111px",
    height: "42px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    textTransform: "none",
  },
  btn: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    "&:hover": {
      background: "#E7EBF4 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginRight: "4px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  btns: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  tableTitle: {
    marginTop: "6px",
    marginLeft: "10px",
    width: "275px",
    height: "22px",
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
  },
  grid: {
    marginTop: "21px",
    paddingTop: "22px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
    paddingBottom: "24px",
  },
  divider: {
    marginTop: "15px",
    height: "0px",
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
  filterIcon: {
    width: "25px",
    height: "23px",
    color: "#A4AFC6",
    opacity: 1,
  },
  actions1: {
    marginTop: "8px",
  },
  textStyle: {
    color: "#ffffff",
  },
  root2: {
    color: "red",
    backgroundColor: "white",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "0px solid red",
    borderRadius: "5px",
    "& .MuiInputLabel-shrink": {
      paddingLeft: "7px",
      paddingTop: "5px",
    },
    "& MuiInputLabel-formControl ": {
      // paddingLeft: "20px",
      left: "10",
    },
    "& MuiInputLabel-root ": {
      paddingLeft: "50px",
    },
    "& MuiFormLabel-root ": {
      paddingLeft: "10px",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
  textField1: {
    marginTop: "19px",
    // paddingLeft: "14px",
    // paddingTop: "12px",
    // paddingBottom: "13px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    fontSize: theme.font.size3,
    fontFamily: theme.font.family,
    fontWeight: theme.font.regular,
    letterSpacing: "0px",
    color: "#797C82",
    height: "42px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    //  border: "1px solid #C4CCDC",
    borderRadius: "5px",
    opacity: 1,
  },
  textField2: {
    marginTop: "19px",
    // paddingLeft: "14px",
    // paddingTop: "12px",
    // paddingBottom: "13px",
    // width: "178px",
    height: "40px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // border: "1px solid #C4CCDC",
    borderRadius: "5px",
    opacity: 1,
    letterSpacing: "0px",
    color: "#797C82",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    fontSize: theme.font.size3,
    fontFamily: theme.font.family,
    fontWeight: theme.font.regular,
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },
  },
  dialogBox: {
    //width: "327px",
    height: "161px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: "20px",
  },
  notAddedStyle1: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 55,
    // marginLeft: 33,
    // marginRight: 33,
    marginTop: 33,
    font: "normal normal normal 24px Karla;",
  },
  dialogContent: {
    //width: "247px",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    font: "normal normal normal 16px Karla",
    marginTop: "11px",
    // marginRight: "17px",
    // marginLeft: "16px",
  },
  dialogActions: {
    "&.MuiDialogActions-root": {
      justifyContent: "center",
    },
  },
  gridContainer: {
    display: "flex",
    flexDirection: "row",
  },
  actionIcon: {
    cursor: "pointer",
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  textMenu: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "6px 16px",
  },
  dropIcon: {
    marginTop: "8px",
    marginRight: "8px",
  },
  paginationText: {
    // textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingTop: "35px",
    paddingLeft: "10px",
  },
});

class UserList extends React.Component {
  constructor(props) {
    sessionStorage.setItem("usermasterError", false);
    super(props);
    this.state = {
      order: "asc",
      orderBy: "date_time",
      selected: [],
      data: [],
      modal: false,
      page: 0,
      rowsPerPage: 10,
      usersCount: -1,
      open: false,
      roles: "",
      user: "",
      userFilterList: "",
      filter_name: "",
      filter_role: "",
      filter_stat: "",
      filter_status: false,
    };
  }

  componentDidMount() {
    this.props.resetFilterParams();
    this.props.fetchUserList(
      this.state.page,
      this.state.rowsPerPage,
      this.props.filterParams
    );
    this.props.fetchRoles();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userLists != this.props.userLists) {
      this.setState({
        data: this.props.userLists,
        usersCount: this.props.usersCount,
      });
    }
    if (prevProps.role != this.props.role) {
      this.setState({
        data: this.props.role.rows,
        usersCount: this.props.role.count,
      });
    }

    if (prevState.page != this.state.page) {
      let params = {
        page: this.state.page,
        limit: this.state.rowsPerPage,
        username: this.state.filter_name == "" ? null : this.state.filter_name,
        role: this.state.filter_role == "" ? null : this.state.filter_role,
        status: this.state.filter_stat == "" ? null : this.state.filter_stat,
      };
      this.props.onFilterChange(params);
      // this.props.fetchUserList(this.state.page, this.state.rowsPerPage);
    }
    if (prevState.rowsPerPage != this.state.rowsPerPage) {
      this.props.fetchUserList(this.state.page, this.state.rowsPerPage);
    }
    if (this.props.deleteSuccess) {
      this.props.resetDelete();
      let params = {
        Page: this.state.page,
        rowsPerPage: this.state.rowsPerPage,
      };

      this.props.fetchUserList(this.state.page, this.state.rowsPerPage);
      this.props.showSnackbar(
        this.props.deleteSuccessReposnse.message,
        "success"
      );
    }
    if (prevProps.roles != this.props.roles) {
      this.setState({ roles: this.props.roles });
    }

    if (prevProps.user != this.props.user) {
      this.setState({
        data: this.props.user.rows,
        usersCount: this.props.user.count ? this.props.user.count : 0,
      });
    }
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleChangePage = (event, pagess) => {
    this.setState({ page: pagess - 1 });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  performAction = () => {
    this.props.navigateTo(`/master/user-master/add/0`);
  };

  editUser = (user_id) => {
    this.props.navigateTo(`/master/user-master/edit/${user_id}/0`);
  };

  handleOpen = (userId, username) => {
    this.setState({
      userId: userId,
      username: username,
      modal: !this.state.modal,
    });
  };

  deleteUser = (userId) => {
    this.setState({
      modal: !this.state.modal,
    });
    this.props.deleteUser(userId);
  };

  hangeDialCloseStatus = () => {
    this.props.dialogueWindowStatus();
  };
  changeDialCloseStatus = () => {
    this.props.toggleFilterWindow();
  };
  onStatusChange = (event) => {
    this.setState({ filter_stat: event.target.value, page: 0 }, () => {
      let params = {
        page: this.state.page,
        limit: this.state.rowsPerPage,
        username: this.state.filter_name == "" ? null : this.state.filter_name,
        role: this.state.filter_role == "" ? null : this.state.filter_role,
        status: this.state.filter_stat == "" ? null : this.state.filter_stat,
      };
      this.props.onFilterChange(params);
    });
  };
  onRoleNameChange = (event) => {
    this.setState({ filter_role: event.target.value, page: 0 }, () => {
      let params = {
        page: this.state.page,
        limit: this.state.rowsPerPage,
        username: this.state.filter_name == "" ? null : this.state.filter_name,
        role: this.state.filter_role == "" ? null : this.state.filter_role,
        status: this.state.filter_stat == "" ? null : this.state.filter_stat,
      };
      this.props.onFilterChange(params);
    });
  };
  onUserNameChange = (event) => {
    this.setState({ filter_name: event.target.value, page: 0 }, () => {
      let params = {
        page: this.state.page,
        limit: this.state.rowsPerPage,
        username: this.state.filter_name == "" ? null : this.state.filter_name,
        role: this.state.filter_role == "" ? null : this.state.filter_role,
        status: this.state.filter_stat == "" ? null : this.state.filter_stat,
      };
      this.props.onFilterChange(params);
    });
  };
  onFilterChange = () => {
    this.props.setValue();
    this.setState({
      ...this.state,
      val: true,
    });
    let params = {
      page: this.state.page,
      limit: this.state.rowsPerPage,
      username: this.state.filter_name == "" ? null : this.state.filter_name,
      role: this.state.filter_role == "" ? null : this.state.filter_role,
      status: this.state.filter_stat == "" ? null : this.state.filter_stat,
    };
    this.props.onFilterChange(params);
    this.setState({ filter_status: true });
  };

  onFilterReset = () => {
    this.setState({
      ...this.state,
      val: false,
    });
    this.setState({ filter_status: true, page: 0 });
    this.setState({ filter_stat: "" });
    this.setState({ filter_role: "" });
    this.setState({ filter_name: "" });
    let params = {
      page: 0,
      limit: this.state.rowsPerPage,
    };
    this.props.onFilterChange(params);
  };

  closeWindow = () => {
    this.props.toggleFilterWindow();
    if (!this.state.val) {
      this.setState({ filter_stat: "" });
      this.setState({ filter_role: "" });
      this.setState({ filter_name: "" });
    }
  };
  componentWillUnmount() {
    sessionStorage.setItem("usermasterError", false);
  }

  render() {
    const { classes, showFilterWindow } = this.props;
    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      Role,
      errors,
    } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, this.state.offendersCount - page * rowsPerPage);
    let firstPageNum = page * rowsPerPage + 1;

    return (
      <>
        <Grid className={classes.grid}>
          <Paper className={classes.paper}>
            <Toolbar className={classNames(classes.roots)}>
              <Typography
                variant="h6"
                id="tableTitle"
                className={classes.tableTitle}
              >
                User Master
              </Typography>
              <div className={classes.spacer} />
              <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="custom-css-standard-input"
                    label="&nbsp; User Name"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    className={classes.textField1}
                    onChange={this.onUserNameChange}
                    value={this.state.filter_name}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      classes: { root: classes.textValue },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="custom-css-standard-input"
                    label="&nbsp; Role"
                    fullWidth
                    select
                    variant="outlined"
                    margin="dense"
                    className={classes.textField2}
                    onChange={this.onRoleNameChange}
                    value={this.state.filter_role}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    InputProps={{ classes: { root: classes.textValue } }}
                    SelectProps={{
                      IconComponent: DownArrow,
                      classes: {
                        icon: classes.dropIcon,
                        iconOpen: classes.dropIconOpen,
                      },
                    }}
                  >
                    <MenuItem value="" classes={{ root: classes.textMenu }}>
                      <em>None</em>
                    </MenuItem>
                    {this.state.roles !== "" && this.state.roles.rows
                      ? this.state.roles.rows.map((data) => (
                        <MenuItem
                          value={data.role_id}
                          classes={{ root: classes.textMenu }}
                        >
                          {data.role_name}
                        </MenuItem>
                      ))
                      : null}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    margin="dense"
                    className={classes.textField2}
                    id="custom-css-standard-input"
                    label="&nbsp; Status"
                    onChange={this.onStatusChange}
                    value={this.state.filter_stat}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    InputProps={{ classes: { root: classes.textValue } }}
                    SelectProps={{
                      IconComponent: DownArrow,
                      classes: {
                        icon: classes.dropIcon,
                        iconOpen: classes.dropIconOpen,
                      },
                    }}
                  >
                    <MenuItem value="" classes={{ root: classes.textMenu }}>
                      <em>None</em>
                    </MenuItem>

                    <MenuItem value="true" classes={{ root: classes.textMenu }}>
                      Active
                    </MenuItem>
                    <MenuItem
                      value="false"
                      classes={{ root: classes.textMenu }}
                    >
                      Inactive
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <div className={classes.actions1}>
                <Tooltip title="Clear fields">
                  <IconButton
                    aria-label="Filter list"
                    onClick={this.onFilterReset}
                  >
                    <ClearIcon className={classes.filterIcon} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.actions}>
                {new StorageHandler().getAttributPermissions() &&
                  new StorageHandler().getAttributPermissions().add_status ==
                  true ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.buttons}
                      disableElevation={true}
                      onClick={() => this.performAction()}
                    >
                      Add User
                    </Button>
                  ) : null}
              </div>
            </Toolbar>
            <Divider className={classes.divider} />
            {/* {this.state.usersCount === 0 ? (
              <div className={classes.notAddedStyle}>
                {messageConstants.NOT_ADDED}
              </div>
            ) : ( */}

            {/* {this.state.usersCount === 0 ? (
              this.props.loading ? (
                <SkeltonScreen />
              ) : (
                <div className={classes.notAddedStyle}>
                  {messageConstants.NOT_ADDED}
                </div>
              )
            ) : ( */}
            {this.props.loading === false && this.state.usersCount == 0 ? (
              <div className={classes.notAddedStyle}>
                {messageConstants.NOT_ADDED}
              </div>
            ) : (
                <>
                  <div className={classes.tableWrapper}>
                    {data && data.length > 0 ? (
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        align="center"
                        stickyHeader
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={this.handleRequestSort}
                          rowCount={this.state.offendersCount}
                        />
                        <TableBody>
                          {data.map((n, i) => {
                            const isSelected = this.isSelected(n.id);
                            return (
                              <TableRow
                                role="checkbox"
                                aria-checked={isSelected}
                                tabIndex={-1}
                                key={n.id}
                                selected={isSelected}
                                className={classes.zebrafont}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ paddingLeft: "32px" }}
                                  className={classes.tableCell}
                                >
                                  {firstPageNum + i}
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  {n.name}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {n.login ? n.login.username : ""}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {n.role ? n.role.role_name : ""}
                                </TableCell>
                                {n.status == true ? (
                                  <TableCell className={classes.tableCell}>
                                    Active
                                  </TableCell>
                                ) : (
                                    <TableCell className={classes.tableCell}>
                                      Inactive
                                    </TableCell>
                                  )}
                                {new StorageHandler().getAttributPermissions() &&
                                  new StorageHandler().getAttributPermissions()
                                    .edit_status == true ? (
                                    <TableCell
                                      className={classes.tableCell}
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      <i onClick={() => this.editUser(n.userId)}>
                                        <Edit className={classes.actionIcon} />
                                      </i>
                                    </TableCell>
                                  ) : null}
                                {new StorageHandler().getAttributPermissions() &&
                                  new StorageHandler().getAttributPermissions()
                                    .delete_status ? (
                                    <TableCell
                                      className={classes.tableCell}
                                      style={{
                                        // paddingLeft: "25px",
                                        paddingRight: "45px",
                                        textAlign: "right",
                                      }}
                                    >
                                      <i
                                        onClick={() =>
                                          this.handleOpen(n.userId, n.name)
                                        }
                                      >
                                        <Delete className={classes.actionIcon} />
                                      </i>
                                    </TableCell>
                                  ) : null}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    ) : sessionStorage.getItem("usermasterError") === "true" ? (
                      <div className={classes.notAddedStyle1}>
                        {messageConstants.NOT_ADDED}
                      </div>
                    ) : (
                          <SkeltonScreen />
                        )}
                  </div>
                  {data && data.length > 0 ? (
                    <Grid container xs={12} className={classes.paginationGrid}>
                      <Grid item xs={4}>
                        <Typography className={classes.paginationText}>
                          {parseInt(firstPageNum) +
                            " - " +
                            (parseInt(page + 1) * rowsPerPage >
                              this.state.usersCount
                              ? this.state.usersCount
                              : parseInt(page + 1) * rowsPerPage) +
                            " of " +
                            this.state.usersCount +
                            " Users"}
                        </Typography>
                      </Grid>
                      <Grid item style={{ float: "right" }} xs={8}>
                        <Pagination
                          count={Math.ceil(this.state.usersCount / rowsPerPage)}
                          shape="rounded"
                          page={page + 1}
                          onChange={this.handleChangePage}
                          color="secondary"
                          className="pagination"
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </>
              )}
            {/* <TablePagination
              component="div"
              count={this.state.usersCount}
              rowsPerPageOptions={[5, 10, 15]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            /> */}

            <Dialog
              open={this.state.modal}
              aria-labelledby="customized-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{
                paper: classes.dialogBox,
              }}
            >
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  className={classes.dialogContent}
                >
                  Are you sure want to delete {this.state.username}?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btn}
                  disableElevation={true}
                  onClick={() => this.handleOpen()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btns}
                  disableElevation={true}
                  onClick={() => this.deleteUser(this.state.userId)}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </>
    );
  }
}

UserList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(UserList);

// {/* <>
//         <Toolbar className={classNames(classes.root1)}>
//           <Grid xs={12} container justify="space-between">
//             <Grid item xs={4} md={4} alignItems={"center"}>
//               <Typography
//                 variant="h6"
//                 id="tableTitle"
//                 className={classes.tableTitle}
//               >
//                 User Master
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               className={classes.spacer}
//               container
//               xs={8}
//               md={8}
//               spacing={2}
//             >
//               <Grid item xs={12} md={3}>
//                 <>
//                   <TextField
//                     id="custom-css-standard-input"
//                     label="&nbsp; User Name"
//                     className={classes.root2}
//                     fullWidth
//                     onChange={this.onUserNameChange}
//                     value={this.state.filter_name}
//                   />
//                 </>
//               </Grid>
//               <Grid item xs={12} md={3}>
//                 <TextField
//                   className={classes.margin}
//                   id="custom-css-standard-input"
//                   label="&nbsp; Role"
//                   className={classes.root2}
//                   select="true"
//                   fullWidth={true}
//                   onChange={this.onRoleNameChange}
//                   value={this.state.filter_role}
//                 >
//                   <MenuItem value="">
//                     <em>None</em>
//                   </MenuItem>
//                   {this.state.roles !== "" && this.state.roles.rows
//                     ? this.state.roles.rows.map((data) => (
//                         <MenuItem value={data.role_id}>
//                           {data.role_name}
//                         </MenuItem>
//                       ))
//                     : null}
//                 </TextField>
//               </Grid>
//               <Grid item xs={12} md={3}>
//                 <TextField
//                   className={classes.margin}
//                   id="custom-css-standard-input"
//                   label="&nbsp; Status"
//                   className={classes.root2}
//                   select="true"
//                   fullWidth={true}
//                   onChange={this.onStatusChange}
//                   value={this.state.filter_stat}
//                 >
//                   <MenuItem value="">
//                     <em>None</em>
//                   </MenuItem>

//                   <MenuItem value={true}>
//                     <em>Active</em>
//                   </MenuItem>
//                   <MenuItem value={false}>
//                     <em>Inactive</em>
//                   </MenuItem>
//                 </TextField>
//               </Grid>

//               <div className={classes.actions1}>
//                 <Tooltip title="Clear fields">
//                   <IconButton
//                     aria-label="Filter list"
//                     onClick={this.onFilterReset}
//                   >
//                     <RotateLeftIcon className={classes.filterIcon} />
//                   </IconButton>
//                 </Tooltip>
//               </div>
//             </Grid>
//           </Grid>
//         </Toolbar>

//         <Fragment>
//           <Paper className={classes.root}>
//             <EnhancedTableToolbar
//               showFilterWindow={this.showFilterWindow}
//             ></EnhancedTableToolbar>
//             <Grid item spacing={16}>
//               {new StorageHandler().getAttributPermissions() &&
//               new StorageHandler().getAttributPermissions().add_status ==
//                 true ? (
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   className={classes.buttons}
//                   onClick={() => this.performAction()}
//                 >
//                   ADD USER
//                 </Button>
//               ) : null}
//             </Grid>
//             {this.state.usersCount === 0 ? (
//               <div className={classes.notAddedStyle}>
//                 {messageConstants.NOT_ADDED}
//               </div>
//             ) : (
//               <TableContainer className={classes.tableWrapper}>
//                 {data && data.length > 0 ? (
//                   <Table className={classes.table} aria-labelledby="tableTitle">
//                     <EnhancedTableHead
//                       numSelected={selected.length}
//                       order={order}
//                       orderBy={orderBy}
//                       onRequestSort={this.handleRequestSort}
//                       rowCount={this.state.offendersCount}
//                     />
//                     <TableBody>
//                       {data.map((n, i) => {
//                         const isSelected = this.isSelected(n.id);

//                         return (
//                           <TableRow
//                             role="checkbox"
//                             aria-checked={isSelected}
//                             tabIndex={-1}
//                             key={n.id}
//                             selected={isSelected}
//                             className={classes.zebrafont}
//                           >
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               className={classes.tableCell}
//                               align="center"
//                             >
//                               {firstPageNum + i}
//                             </TableCell>

//                             <TableCell
//                               className={classes.tableCell}
//                               align="center"
//                             >
//                               {n.name}
//                             </TableCell>
//                             <TableCell
//                               className={classes.tableCell}
//                               align="center"
//                             >
//                               {n.login ? n.login.username : ""}
//                             </TableCell>
//                             <TableCell
//                               className={classes.tableCell}
//                               align="center"
//                             >
//                               {n.role ? n.role.role_name : ""}
//                             </TableCell>
//                             {n.status == true ? (
//                               <TableCell
//                                 className={classes.tableCell}
//                                 align="center"
//                               >
//                                 Active
//                               </TableCell>
//                             ) : (
//                               <TableCell
//                                 className={classes.tableCell}
//                                 align="center"
//                               >
//                                 Inactive
//                               </TableCell>
//                             )}
//                             {new StorageHandler().getAttributPermissions() &&
//                             new StorageHandler().getAttributPermissions()
//                               .edit_status == true ? (
//                               <TableCell align="center">
//                                 <i onClick={() => this.editUser(n.userId)}>
//                                   <Edit />
//                                 </i>
//                               </TableCell>
//                             ) : null}
//                             {new StorageHandler().getAttributPermissions() &&
//                             new StorageHandler().getAttributPermissions()
//                               .delete_status ? (
//                               <TableCell align="center">
//                                 <i
//                                   onClick={() =>
//                                     this.handleOpen(n.userId, n.name)
//                                   }
//                                 >
//                                   <Delete />
//                                 </i>
//                               </TableCell>
//                             ) : null}
//                           </TableRow>
//                         );
//                       })}
//                     </TableBody>
//                   </Table>
//                 ) : null}
//               </TableContainer>
//             )}
//             <TablePagination
//               component="div"
//               count={this.state.usersCount}
//               rowsPerPageOptions={[5, 10, 15]}
//               rowsPerPage={rowsPerPage}
//               page={page}
//               backIconButtonProps={{
//                 "aria-label": "Previous Page",
//               }}
//               nextIconButtonProps={{
//                 "aria-label": "Next Page",
//               }}
//               onChangePage={this.handleChangePage}
//               onChangeRowsPerPage={this.handleChangeRowsPerPage}
//             />
//             <Dialog
//               open={this.props.showFilterWindow}
//               onClose={this.handleClose}
//               aria-labelledby="alert-dialog-title"
//               aria-describedby="alert-dialog-description"
//               fullWidth={true}
//             >
//               <DialogTitle id="form-dialog-title">
//                 <Grid container justify={"space-between"}>
//                   <Grid item>Filter</Grid>
//                   <Grid item></Grid>
//                 </Grid>
//               </DialogTitle>
//               <DialogContent>
//                 <>
//                   <Grid xs={12} container spacing={1}>
//                     <Grid item xs={12} md={6}>
//                       <TextField
//                         className={classes.textField}
//                         id="outlined-basic"
//                         label="User Name"
//                         variant="outlined"
//                         margin="dense"
//                         fullWidth={true}
//                         onChange={this.onUserNameChange}
//                         value={this.state.filter_name}
//                       ></TextField>
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                       <TextField
//                         className={classes.textField}
//                         id="outlined-basic"
//                         label="Role"
//                         variant="outlined"
//                         fullWidth={true}
//                         select="true"
//                         margin="dense"
//                         onChange={this.onRoleNameChange}
//                         value={this.state.filter_role}
//                       >
//                         <MenuItem value="">
//                           <em>None</em>
//                         </MenuItem>
//                         {this.state.roles !== "" && this.state.roles.rows
//                           ? this.state.roles.rows.map((data) => (
//                               <MenuItem value={data.role_id}>
//                                 {data.role_name}
//                               </MenuItem>
//                             ))
//                           : null}
//                       </TextField>
//                     </Grid>
//                     <Grid item xs={12} md={6}>
//                       <TextField
//                         className={classes.textField}
//                         id="outlined-basic"
//                         label="Status"
//                         variant="outlined"
//                         fullWidth={true}
//                         select="true"
//                         margin="dense"
//                         onChange={this.onStatusChange}
//                         value={this.state.filter_stat}
//                       >
//                         <MenuItem value="">
//                           <em>None</em>
//                         </MenuItem>

//                         <MenuItem value={true}>
//                           <em>Active</em>
//                         </MenuItem>
//                         <MenuItem value={false}>
//                           <em>Inactive</em>
//                         </MenuItem>
//                       </TextField>
//                     </Grid>
//                   </Grid>
//                 </>
//               </DialogContent>
//               <DialogActions>
//                 <Button
//                   onClick={this.closeWindow}
//                   className={classes.filtercloseButton}
//                 >
//                   Close
//                 </Button>
//                 <Button
//                   onClick={this.onFilterReset}
//                   className={classes.filterclearButton}
//                   variant="contained"
//                 >
//                   Clear
//                 </Button>
//                 <Button
//                   onClick={this.onFilterChange}
//                   className={classes.filterbutton}
//                   color="secondary"
//                   autoFocus
//                   variant="contained"
//                 >
//                   Apply Filter
//                 </Button>
//               </DialogActions>
//             </Dialog>

//             {/* <Modal
//             aria-labelledby="simple-modal-title"
//             aria-describedby="simple-modal-description"
//             open={this.state.modal}
//           >
//             <div style={getModalStyle()} className={classes.paper}>
//               <Typography variant="subtitle1" id="simple-modal-description">
//                 Are you sure you want to delete {this.state.username}?{" "}
//               </Typography>
//               <Grid>
//                 {" "}
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   className={classes.btn}
//                   onClick={() => this.handleOpen()}
//                 >
//                   Cancel
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   className={classes.btns}
//                   onClick={() => this.deleteUser(this.state.userId)}
//                 >
//                   OK
//                 </Button>
//               </Grid>
//             </div>
//           </Modal> */}
//             <Dialog
//               open={this.state.modal}
//               aria-labelledby="alert-dialog-title"
//               aria-describedby="alert-dialog-description"
//             >
//               <DialogContent>
//                 <DialogContentText id="alert-dialog-description">
//                   Are you sure you want to delete {this.state.username}?{" "}
//                 </DialogContentText>
//               </DialogContent>
//               <DialogActions>
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   className={classes.btn}
//                   onClick={() => this.handleOpen()}
//                 >
//                   Cancel
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   className={classes.btns}
//                   onClick={() => this.deleteUser(this.state.userId)}
//                 >
//                   OK
//                 </Button>
//               </DialogActions>
//             </Dialog>
//           </Paper>
//         </Fragment>
//       </> */}
