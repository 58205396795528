import React, { Component, Fragment } from "react";
import {
  Paper,
  Typography,
  Grid,
  withStyles,
  Button,
  TextField,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Toolbar,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  InputBase,
  MenuItem,
  TableContainer,
  makeStyles,
  RadioGroup,
  Drawer,
  FormControlLabel,
  Divider,
} from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
// import DownloadIcon from "@material-ui/icons/SaveAlt";
import ReportTitle from "../reports/components/ReportTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StorageHandler from "../../util/storage/index";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../reports/components/report_filter/style.css";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import messageConstants from "../../services/validator/MsgConsts/messageConstants";
import { ReactComponent as DateTime } from "../../assets/SvgIcons/date.svg";
import { ReactComponent as DownArrow } from "../../assets/SvgIcons/dropdown.svg";
import { ReactComponent as FilterListIcon } from "../../assets/SvgIcons/filter.svg";
import SkeltonScreen from "../../components/skelton_screen/reportSkelton";
import { ReactComponent as DownloadIcon } from "../../assets/SvgIcons/download.svg";
import Radio from "../../components/filteralert_form/styledRadio";

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginations extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginations.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginations);
const drawerWidth = 328;

const styles = (theme) => ({
  root: {
    padding: "12px 35px",
    paddingRight: "16px",
    backgroundColor: "#ffff",
    marginTop: "43px",
    marginRight: "18px",
    marginLeft: "22px",
    borderTopLeftRadius: theme.border.radius,
    borderTopRightRadius: theme.border.radius,
  },
  divider: {
    marginRight: "18px",
    marginLeft: "22px",
    border: "1px solid #E7EBF4",
  },
  spacer: {
    flex: "1 1 20%",
  },
  // actions: {
  //   paddingRight: theme.spacing.unit,
  // },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
  },
  filterIcon: {
    color: "#A4AFC6",
  },

  drawerPaper: {
    width: drawerWidth,
  },

  container: {
    // padding: theme.spacing.unit,
    maxHeight: "43vh",
    overflow: "auto",
    // maxWidth: "70vw",
    minWidth: "-webkit-fill-available",
    paddingBottom: "20px",
    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflow: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "75vh",
      overflow: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflow: "auto",
    },
    // /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      minHeight: "12vh",
      maxHeight: "45vh",
      overflow: "auto",
    },
    // /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "75vh",
      overflow: "auto",
    },
  },
  reportPaper: {
    // padding: "10px",
    boxShadow: "0px 0px 4px #0000000D",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    paddingLeft: "26px",
    paddingRight: "23px",
    marginLeft: "22px",
    marginRight: "18px",
    paddingBottom: "20px",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  },
  reportTitle: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: theme.font.fontWeight,
  },
  emptyReport: {
    minHeight: "60vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    font: "normal normal bold 34px Karla;",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
  },
  firstRow: {
    background: "#F1F4FB 0% 0% no-repeat padding-box",
    opacity: 1,
  },
  secondRow: {
    background: "#FAFBFE 0% 0% no-repeat padding-box",
    opacity: 1,
  },

  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px Karla;",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "6px 14px",
    borderColor: "#E7EBF4",
    backgroundColor: "white",
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    padding: "7px 14px",
    border: "0px",
  },

  buttonGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  datetableCell: {
    width: "110px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    padding: "7px 14px",
    paddingLeft: "7px",
    border: "0px",
  },
  datetableCell1: {
    width: "200px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    padding: "7px 14px",
    paddingLeft: "7px",
    border: "0px",
  },
  button: {
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    opacity: 1,
    textTransform: "none",
    paddingLeft: "38px",
    paddingRight: "37px",
    height: "42px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
  },
  clearButton: {
    textTransform: "none",
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.BlueButton,
    color: theme.colors.whiteColor,
    "&:hover": {
      backgroundColor: theme.colors.BlueButton,
    },
  },
  closeButton: {
    height: "42px",
    textTransform: "none",
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    letterSpacing: "0px",
    backgroundColor: theme.colors.BlueButton,
    paddingLeft: "38px",
    paddingRight: "37px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
  },
  drawerAction: {
    height: "74px",
    backgroundColor: "#F9F9FE",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  divider1: {
    backgroundColor: "#E7EBF4",
  },
  filterTitle: {
    font: "normal normal bold 18px Karla",
    color: "#2B3D51",
  },
  filterClearAll: {
    font: "normal normal normal 16px Karla",
    color: "#1BB99A",
    textTransform: "none",
  },
  filterSubHeading: {
    font: "normal normal bold 16px/22px Karla",
    color: "#2B3D51",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  dialogContent: {
    paddingLeft: "22px",
    paddingRight: "15px",
  },

  formHelperText: {
    color: theme.colors.redColor,
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  textNoOptions: {
    letterSpacing: "0px",
    opacity: 1,
    fontSize: "18px",
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  formLabel: {
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
  },
  popIcon: {
    marginTop: "6px",
    marginRight: "6px",
  },
  popIconOpen: {
    marginTop: "4px",
    marginRight: "6px",
  },
  dateIcon: {
    marginTop: "0px",
    marginRight: "-8px",
  },
  mainGrid: {
    paddingBottom: "2px",
  },
  notAddedStyle: {
    // backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    padding: 55,
    marginLeft: 33,
    marginRight: 33,
    // marginTop: 13,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    font: "normal normal normal 24px Karla;",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
  },
});
class Summary extends Component {
  constructor(props) {
    sessionStorage.setItem("summaryreportsMasterError", false);
    super(props);
    this.state = {
      // assignedDistrict: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.district_master
      //   : "",
      // assignedField: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.location_master
      //   : "",
      // assignedZone: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.zone_master
      //   : "",
      zoneType: "zone",
      districtType: "district",
      fieldType: "field",
      validationErrors: {},
      validationParms: { from_date: "", to_date: "" },
      Field: [],
      Zone: [],
      District: [],
      Timeperiod: [
        { name: "Last One Week", value: "1" },
        { name: "Last One Month", value: "2" },
        { name: "Last Three Month", value: "3" },
        { name: "Last Six Month", value: "4" },
        { name: "Last Nine Month", value: "5" },
        { name: "Last One Year", value: "6" },
      ],
      Camera: [],
      value: false,
      reportParams: {},
      searchParams: { camera_id: [] },
      filsearchParams: { camera_id: [] },
      reportType: null,
      cameras: {},
      rows: [],
      page: 0,
      limit: 10,
      paramsCategory: {},
      reportFilterParams: { type: "" },
      generationFields1: {
        from_date: "",
        to_date: "",
        zone: "",
        district: "",
        camera: "",
        field: "",
        timePeriod: "",
      },
      generationFields: {
        from_date: "",
        to_date: "",
      },
      designationList: [
        { zone_name: "dss", value: "1" },
        { zone_name: "88s", value: "2" },
      ],
    };
  }
  componentDidMount() {
    this.props.resetReportData();
    this.props.fetchZone(this.state.zoneType);
    this.props.fetchStateCamera();
    this.initialCall();
    // if (
    //   new StorageHandler().getUser() &&
    //   new StorageHandler().getUser().basicDetails.zone_master &&
    //   new StorageHandler().getUser().basicDetails.zone_master != null
    // ) {
    //   this.props.fetchDistrict(this.state.assignedZone.zone_id);
    //   this.props.fetchZoneCamera(
    //     new StorageHandler().getUser().basicDetails.zone_master.zone_id
    //   );
    // }

    // if (
    //   new StorageHandler().getUser() &&
    //   new StorageHandler().getUser().basicDetails.district_master &&
    //   new StorageHandler().getUser().basicDetails.district_master != null
    // ) {
    //   this.props.fetchField(this.state.assignedDistrict.district_id);
    //   this.props.fetchDistrictCamera(
    //     new StorageHandler().getUser().basicDetails.district_master.district_id
    //   );
    // }
    // if (
    //   new StorageHandler().getUser() &&
    //   new StorageHandler().getUser().basicDetails.location_master &&
    //   new StorageHandler().getUser().basicDetails.location_master != null
    // ) {
    //   this.props.fetchFieldCamera(
    //     new StorageHandler().getUser().basicDetails.location_master.location_id
    //   );
    // }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.fetchedzone != this.props.fetchedzone) {
      this.setState({
        ...this.state,
        Zone: this.props.fetchedzone,
      });
    }

    if (prevProps.district != this.props.district) {
      this.setState({
        ...this.state,
        District: this.props.district,
      });
    }
    if (prevProps.field != this.props.field) {
      this.setState({
        ...this.state,
        Field: this.props.field,
      });
    }
    if (prevProps.Camera != this.props.Camera) {
      this.setState({
        ...this.state,
        Camera: this.props.Camera,
      });
    }
  }
  handleTimePeriod = (e, value) => {
    if (
      this.state.generationFields1.from_date != "" ||
      this.state.generationFields1.to_date != ""
    ) {
      this.props.showSnackbar(
        "Your Selected From and To date will change accordingly",
        "info"
      );
    }
    if (value.value == 1) {
      var d1 = new Date();
      var d = new Date();
      d.setDate(d.getDate() - 7);
      this.setState({
        generationFields1: {
          ...this.state.generationFields1,
          timePeriod: value,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
        reportParams: {
          ...this.state.reportParams,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
        generationFields: {
          ...this.state.generationFields,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
        paramsCategory: {
          type: "daily",
        },
      });
    }
    if (value.value == 2) {
      var d1 = new Date();
      var d = new Date();
      d.setMonth(d.getMonth() - 1);
      this.setState({
        generationFields1: {
          ...this.state.generationFields1,
          timePeriod: value,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
        reportParams: {
          ...this.state.reportParams,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
        paramsCategory: {
          type: "monthly",
          interval: "1",
        },
        generationFields: {
          ...this.state.generationFields,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
      });
    }
    if (value.value == 3) {
      var d1 = new Date();
      var d = new Date();
      d.setMonth(d.getMonth() - 3);
      this.setState({
        generationFields1: {
          ...this.state.generationFields1,
          timePeriod: value,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
        reportParams: {
          ...this.state.reportParams,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
        paramsCategory: {
          type: "monthly",
          interval: "3",
        },
        generationFields: {
          ...this.state.generationFields,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
      });
    }
    if (value.value == 4) {
      var d1 = new Date();
      var d = new Date();

      d.setMonth(d.getMonth() - 6);

      this.setState({
        generationFields1: {
          ...this.state.generationFields1,
          timePeriod: value,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
        paramsCategory: {
          type: "monthly",
          interval: "6",
        },
        reportParams: {
          ...this.state.reportParams,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
        generationFields: {
          ...this.state.generationFields,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
      });
    }
    if (value.value == 5) {
      var d1 = new Date();
      var d = new Date();

      d.setMonth(d.getMonth() - 9);

      this.setState({
        generationFields1: {
          ...this.state.generationFields1,
          timePeriod: value,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
        reportParams: {
          ...this.state.reportParams,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
        paramsCategory: {
          type: "monthly",
          interval: "9",
        },
        generationFields: {
          ...this.state.generationFields,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
      });
    }
    if (value.value == 6) {
      var d1 = new Date();
      var d = new Date();
      d.setMonth(d.getMonth() - 12);
      this.setState({
        reportParams: {
          ...this.state.reportParams,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
        paramsCategory: {
          type: "monthly",
          interval: "12",
        },
        generationFields: {
          ...this.state.generationFields,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
        generationFields1: {
          ...this.state.generationFields1,
          timePeriod: value,
          from_date: moment(d).format("YYYY-MM-DD"),
          to_date: moment(d1).format("YYYY-MM-DD"),
        },
      });
    }
  };
  validate = () => {
    const { generationFields } = this.state;
    var validationSuccess = true;
    let validationErrors = {};
    Object.keys(generationFields).map((field) => {
      if (
        generationFields[field] === "" ||
        generationFields[field] == null ||
        generationFields[field] === "Invalid date" ||
        generationFields[field] === "Invalid Date"
      ) {
        validationErrors[field] = true;
        validationSuccess = false;
      } else {
        validationErrors[field] = false;
      }
    });
    this.setState({
      validationErrors,
    });
    return validationSuccess;
  };
  initialCall = () => {
    var d1 = new Date();
    var d = new Date();
    d.setDate(d.getDate() - 7);
    let test = [];
    if (
      new StorageHandler().getUser() &&
      new StorageHandler().getUser().basicDetails &&
      new StorageHandler().getUser().basicDetails.camera
    ) {
      new StorageHandler().getUser().basicDetails.camera.map((row, i) => {
        test.push(row.camera_id);
      });
    }
    let data = {
      generateUrl: "/api/reports/dashboardSummary",
      generateParams: {
        type: "daily",
        from_date: moment(d).format("YYYY-MM-DD"),
        to_date: moment(d1).format("YYYY-MM-DD"),
      },
      generatedata: test,
    };
    this.props.fetchReport(data);
  };
  generateReport = () => {
    if (this.validate()) {
      // this.resetReportFilter()
      this.toggleFilterWindow();
      const { reportParams, searchParams, paramsCategory } = this.state;
      if (!(this.state.searchParams && this.state.cameras.camera)) {
        const camera_id = [];
        this.setState(
          {
            rows: [],
          },
          () => {
            this.state.Camera.map((row, i) => {
              let test = [...this.state.rows];
              test.push(row.camera_id);
              this.state.rows = test;
            });
            this.state.searchParams.camera_id = this.state.rows;
          }
        );
      } else {
        this.state.searchParams.camera_id = this.state.filsearchParams.camera_id;
      }

      Object.keys(reportParams).map((item) => {
        if (
          reportParams[item] === "" ||
          reportParams[item] == null ||
          reportParams[item] == undefined
        ) {
          delete reportParams[item];
        }
      });
      Object.keys(searchParams).map((item) => {
        if (
          searchParams[item] === "" ||
          searchParams[item] == null ||
          searchParams[item] == undefined
        ) {
          delete searchParams[item];
        }
      });
      this.setState(
        {
          type: paramsCategory.type,
          type1: paramsCategory.type,
          value: true,
        },
        () => {
          let generateParams = {
            ...reportParams,
            ...paramsCategory,
            // page: 0,
            // limit: 10,
          };
          let data = {
            generateUrl: "/api/reports/dashboardSummary",
            generateParams: generateParams,
            generatedata: searchParams,
          };
          this.props.fetchReport(data);
        }
      );
    } else {
      this.props.showSnackbar(
        "Please enter valid dates or Time Period..!",
        "info"
      );
    }
  };
  resetReportFilter = () => {
    this.setState({
      reportFilterParams: {
        type: "",
      },
      generationFields2: {
        type: "",
      },
    });
  };
  initialExport = () => {
    var d1 = new Date();
    var d = new Date();
    d.setDate(d.getDate() - 7);
    let test = [];
    if (
      new StorageHandler().getUser() &&
      new StorageHandler().getUser().basicDetails &&
      new StorageHandler().getUser().basicDetails.camera
    ) {
      new StorageHandler().getUser().basicDetails.camera.map((row, i) => {
        test.push(row.camera_id);
      });
    }
    let data = {
      exportUrl: "/api/reports/dashboardSummary-report-print",
      exportParams: {
        type: "daily",
        from_date: moment(d).format("YYYY-MM-DD"),
        to_date: moment(d1).format("YYYY-MM-DD"),
      },
      exportdata: test,
    };
    this.props.exportReport(data);
  };
  exportReport = () => {
    const {
      generationFields,
      reportParams,
      searchParams,
      reportFilterParams,
      paramsCategory,
    } = this.state;
    if (
      Object.keys(reportParams).length == 0 &&
      Object.keys(paramsCategory).length == 0
    ) {
      this.initialExport();
    } else {
      let exportParams = {
        ...reportParams,
        ...paramsCategory,
      };
      let data = {
        exportUrl: "/api/reports/dashboardSummary-report-print",
        exportParams: exportParams,
        exportdata: searchParams,
      };
      this.props.exportReport(data);
    }
  };

  // handleChangePage = (event, page) => {
  //   this.setState({ page: page }, () => {
  //     const {
  //       reportParams,
  //       searchParams,
  //       reportFilterParams,
  //       paramsCategory,
  //     } = this.state;
  //     Object.keys(reportParams).map((item) => {
  //       if (
  //         reportParams[item] === "" ||
  //         reportParams[item] == null ||
  //         reportParams[item] == undefined
  //       ) {
  //         delete reportParams[item];
  //       }
  //     });
  //     Object.keys(searchParams).map((item) => {
  //       if (
  //         searchParams[item] === "" ||
  //         searchParams[item] == null ||
  //         searchParams[item] == undefined
  //       ) {
  //         delete searchParams[item];
  //       }
  //     });
  //     Object.keys(reportFilterParams).map((item) => {
  //       if (
  //         reportFilterParams[item] === "" ||
  //         reportFilterParams[item] == null ||
  //         reportFilterParams[item] == undefined
  //       ) {
  //         delete reportFilterParams[item];
  //       }
  //     });

  //     if (reportFilterParams.from_date) {
  //       let generateParams = {
  //         ...reportFilterParams,
  //         // page: page,
  //         // limit: this.state.limit,
  //       };
  //       let data = {
  //         generateUrl: "/api/reports/dashboardSummary",
  //         generateParams: generateParams,
  //         generatedata: searchParams,
  //       };
  //       this.props.fetchReport(data);
  //     } else {
  //       let generateParams = {
  //         ...reportParams,
  //         ...paramsCategory,
  //         // page: this.state.page,
  //         // limit: this.state.limit,
  //       };
  //       let data = {
  //         generateUrl: "/api/reports/dashboardSummary",
  //         generateParams: generateParams,
  //         generatedata: searchParams,
  //       };
  //       this.props.fetchReport(data);
  //     }
  //   });
  // };

  // handleChangeRowsPerPage = (event) => {
  //   this.setState(
  //     {
  //       ...this.state,
  //       limit: event.target.value,
  //     },
  //     () => {
  //       const {
  //         generationFields,
  //         reportFilterParams,
  //         reportParams,
  //         searchParams,
  //         paramsCategory,
  //       } = this.state;

  //       Object.keys(reportParams).map((item) => {
  //         if (
  //           reportParams[item] === "" ||
  //           reportParams[item] == null ||
  //           reportParams[item] == undefined
  //         ) {
  //           delete reportParams[item];
  //         }
  //       });
  //       Object.keys(searchParams).map((item) => {
  //         if (
  //           searchParams[item] === "" ||
  //           searchParams[item] == null ||
  //           searchParams[item] == undefined
  //         ) {
  //           delete searchParams[item];
  //         }
  //       });
  //       Object.keys(reportFilterParams).map((item) => {
  //         if (
  //           reportFilterParams[item] === "" ||
  //           reportFilterParams[item] == null ||
  //           reportFilterParams[item] == undefined
  //         ) {
  //           delete reportFilterParams[item];
  //         }
  //       });
  //       if (reportFilterParams.from_date) {
  //         let generateParams = {
  //           ...reportFilterParams,
  //           // page: this.state.page,
  //           // limit: event.target.value,
  //         };
  //         let data = {
  //           generateUrl: "/api/reports/dashboardSummary",
  //           generateParams: generateParams,
  //           generatedata: searchParams,
  //         };
  //         this.props.fetchReport(data);
  //       } else {
  //         let generateParams = {
  //           ...reportParams,
  //           ...paramsCategory,
  //           // page: this.state.page,
  //           // limit: event.target.value,
  //         };
  //         let data = {
  //           generateUrl: "/api/reports/dashboardSummary",
  //           generateParams: generateParams,
  //           generatedata: searchParams,
  //         };
  //         this.props.fetchReport(data);
  //       }
  //     }
  //   );
  // };
  clearAll = () => {
    // this.props.resetReportData();
    this.props.fetchStateCamera();
    this.resetReportFilterParams();
    this.initialCall();
    this.setState(
      {
        reportParams: {},
        searchParams: {},
        filsearchParams: {},
        // paramsCategory: {},
        value: false,
        paramsCategory: { type: "" },
        generationFields: { from_date: "", to_date: "" },
        cameras: {},
        reportType: null,
        generationFields1: {
          from_date: "",
          to_date: "",
          camera: "",
          timePeriod: "",
        },
        assignedZone: {},
        assignedDistrict: {},
        assignedField: {},
        District: [],
        Field: [],
      }
      // () => {
      //   if (new StorageHandler().getUser().basicDetails.zone_master == null) {
      //     this.setState({
      //       assignedZone: {},
      //       District: [],
      //       Field: [],
      //     });
      //   } else {
      //     this.props.fetchDistrict(
      //       new StorageHandler().getUser().basicDetails.zone_master.zone_id
      //     );
      //     this.props.fetchZoneCamera(
      //       new StorageHandler().getUser().basicDetails.zone_master.zone_id
      //     );
      //     this.setState({
      //       Field: [],
      //     });
      //   }
      //   if (
      //     new StorageHandler().getUser().basicDetails.district_master == null
      //   ) {
      //     this.setState({
      //       assignedDistrict: {},
      //       Field: [],
      //     });
      //   } else {
      //     this.props.fetchField(
      //       new StorageHandler().getUser().basicDetails.district_master
      //         .district_id
      //     );
      //     this.props.fetchDistrictCamera(
      //       new StorageHandler().getUser().basicDetails.district_master
      //         .district_id
      //     );
      //   }
      //   if (
      //     new StorageHandler().getUser().basicDetails.location_master == null
      //   ) {
      //     this.setState({
      //       assignedField: {},
      //     });
      //   } else {
      //     this.props.fetchFieldCamera(
      //       new StorageHandler().getUser().basicDetails.location_master
      //         .location_id
      //     );
      //   }
      // }
    );
  };
  CloseFilter = () => {
    this.toggleFilterWindow();
    if (this.state.value == false) {
      this.clearAll();
    }
  };
  toggleFilterWindow = () => {
    this.setState({
      showReportFilterWindow: this.state.showReportFilterWindow ? false : true,
    });
  };
  resetReportFilterParams = () => {
    this.setState({
      value: false,
      type1: "",
      reportFilterParams: {
        type: "",
      },
      generationFields2: {
        type: "",
      },
    });
  };
  componentWillUnmount() {
    sessionStorage.setItem("summaryreportsMasterError", false);
  }
  render() {
    var d5f = new Date();
    d5f.setFullYear(d5f.getFullYear() - 1);
    const { classes } = this.props;
    const { reportData } = this.props;
    const reportTableHeader = [
      "Date",
      "Vehicles",
      "Alerts",
      "Warnings",
      "Reviews",
      "Valid EwayBill",
      "Invalid EwayBill",
      "Commercial Vehicle",
      "Other Vehicle",
      "Verified",
      "Total Exempted Goods",
      "Vegetables",
      "Fish",
      "Eggs",
      "Chicken",
      "Fruits",
      "Milk",
      "Petroleum Products",
      "Value below 50k",
      "Empty Vehicles",
      "Passenger Vehicles",
      "Other Commodity",
    ];
    return (
      <>
        <Drawer
          className={classes.drawer}
          // variant="persistent"
          anchor="right"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={this.state.showReportFilterWindow}
          onClose={this.toggleFilterWindow}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title">
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={6} md={6}>
                <span className={classes.filterTitle}>Filter</span>
              </Grid>
              <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                <Button
                  disableElevation={true}
                  onClick={this.clearAll}
                  className={classes.filterClearAll}
                >
                  Clear All
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider className={classes.divider1} />
          <DialogContent
            className="DrawerMain"
            classes={{ root: classes.dialogContent }}
          >
            <Grid container>
              <Grid xs={12} md={12} item classes={{ root: classes.mainGrid }}>
                {/* <Typography variant="h6">From Date</Typography> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    label="From Date"
                    name="from_date"
                    // disableToolbar
                    minDate={d5f}
                    disableFuture
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    margin="dense"
                    value={
                      this.state.generationFields1.from_date == ""
                        ? null
                        : this.state.generationFields1.from_date
                    }
                    onChange={(date) => {
                      this.setState({
                        generationFields1: {
                          ...this.state.generationFields1,
                          timePeriod: "",
                          from_date: moment(date).format("YYYY-MM-DD"),
                        },
                        reportParams: {
                          ...this.state.reportParams,
                          from_date: moment(date).format("YYYY-MM-DD"),
                        },
                        paramsCategory: {
                          type: "daily",
                        },
                        generationFields: {
                          ...this.state.generationFields,
                          from_date: moment(date).format("YYYY-MM-DD"),
                        },
                      });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    InputProps={{ classes: { root: classes.textValue } }}
                    keyboardIcon={<DateTime className={classes.dateIcon} />}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid xs={12} md={12} item classes={{ root: classes.mainGrid }}>
                {/* <Typography variant="h6">To Date</Typography> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    label="To Date"
                    name="to_date"
                    // disableToolbar
                    minDate={d5f}
                    disableFuture
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    margin="dense"
                    value={
                      this.state.generationFields1.to_date == ""
                        ? null
                        : this.state.generationFields1.to_date
                    }
                    onChange={(date) => {
                      this.setState({
                        generationFields1: {
                          ...this.state.generationFields1,
                          timePeriod: "",
                          to_date: moment(date).format("YYYY-MM-DD"),
                        },
                        reportParams: {
                          ...this.state.reportParams,
                          to_date: moment(date).format("YYYY-MM-DD"),
                        },
                        paramsCategory: {
                          type: "daily",
                        },
                        generationFields: {
                          ...this.state.generationFields,
                          to_date: moment(date).format("YYYY-MM-DD"),
                        },
                      });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    InputProps={{ classes: { root: classes.textValue } }}
                    keyboardIcon={<DateTime className={classes.dateIcon} />}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid
                xs={12}
                md={12}
                lg={12}
                item
                style={{ textAlign: "center" }}
                classes={{ root: classes.mainGrid }}
              >
                <Typography style={{ display: "inline-block" }}>
                  <b>OR</b>
                </Typography>
              </Grid>
              <Grid
                xs={12}
                md={12}
                lg={12}
                item
                classes={{ root: classes.mainGrid }}
                style={{ paddingBottom: "20px" }}
              >
                {/* <Typography variant="h6">Time Period</Typography> */}
                {this.state.Timeperiod ? (
                  <Autocomplete
                    disableClearable="true"
                    disableOpenOnFocus="true"
                    options={this.state.Timeperiod}
                    value={this.state.generationFields1.timePeriod}
                    name="camera"
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    onChange={this.handleTimePeriod}
                    popupIcon={<DownArrow />}
                    classes={{
                      input: classes.textValue,
                      option: classes.textValue,
                      popupIndicatorOpen: classes.popIconOpen,
                      popupIndicator: classes.popIcon,
                      noOptions: classes.textNoOptions,
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label="Time Period"
                        name="timePeriod"
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                      ></TextField>
                    )}
                  />
                ) : null}
              </Grid>

              <Grid
                xs={12}
                md={12}
                lg={12}
                item
                classes={{ root: classes.mainGrid }}
              >
                {/* <Typography variant="h6">Zone</Typography> */}
                {this.state.Zone ? (
                  <Autocomplete
                    disableClearable="true"
                    disableOpenOnFocus="true"
                    // disabled={
                    //   new StorageHandler().getUser() &&
                    //   new StorageHandler().getUser().basicDetails &&
                    //   new StorageHandler().getUser().basicDetails.zone_id
                    //     ? true
                    //     : false
                    // }
                    options={this.state.Zone}
                    value={
                      this.state.assignedZone &&
                      this.state.assignedZone !== null
                        ? this.state.assignedZone
                        : ""
                    }
                    name="zone"
                    getOptionLabel={(option) =>
                      option.zone_name ? option.zone_name : ""
                    }
                    onChange={(event, value) => {
                      this.setState(
                        {
                          ...this.state,
                          assignedZone: value,
                          camers: {},
                          assignedDistrict: {},
                          assignedField: {},
                          generationFields1: {
                            ...this.state.generationFields1,
                            zone: value,
                            camera: "",
                          },
                        },
                        () => {
                          this.props.fetchDistrict(
                            this.state.districtType,
                            this.state.assignedZone.zone_id
                          );
                          this.props.fetchZoneCamera(
                            this.state.assignedZone.zone_id
                          );
                          this.setState({
                            Field: [],
                          });
                        }
                      );
                    }}
                    popupIcon={<DownArrow />}
                    classes={{
                      input: classes.textValue,
                      option: classes.textValue,
                      popupIndicatorOpen: classes.popIconOpen,
                      popupIndicator: classes.popIcon,
                      noOptions: classes.textNoOptions,
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label="Zone"
                        name="zone"
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                      ></TextField>
                    )}
                  />
                ) : null}
              </Grid>

              <Grid
                xs={12}
                md={12}
                lg={12}
                item
                classes={{ root: classes.mainGrid }}
              >
                {/* <Typography variant="h6">District</Typography> */}
                {this.state.District ? (
                  <Autocomplete
                    disableClearable="true"
                    disableOpenOnFocus="true"
                    options={this.state.District}
                    value={
                      this.state.assignedDistrict &&
                      this.state.assignedDistrict !== null
                        ? this.state.assignedDistrict
                        : ""
                    }
                    // disabled={
                    //   new StorageHandler().getUser() &&
                    //   new StorageHandler().getUser().basicDetails &&
                    //   new StorageHandler().getUser().basicDetails.district_id
                    //     ? true
                    //     : false
                    // }
                    name="district"
                    getOptionLabel={(option) =>
                      option.district_name ? option.district_name : ""
                    }
                    onChange={(event, value) => {
                      this.setState(
                        {
                          ...this.state,
                          assignedDistrict: value,
                          cameras: {},
                          assignedField: {},
                          generationFields1: {
                            ...this.state.generationFields1,
                            district: value,
                            camera: "",
                          },
                        },
                        () => {
                          this.props.fetchField(
                            this.state.fieldType,
                            this.state.assignedDistrict.district_id
                          );
                          this.props.fetchDistrictCamera(
                            this.state.assignedDistrict.district_id
                          );
                        }
                      );
                    }}
                    popupIcon={<DownArrow />}
                    classes={{
                      input: classes.textValue,
                      option: classes.textValue,
                      popupIndicatorOpen: classes.popIconOpen,
                      popupIndicator: classes.popIcon,
                      noOptions: classes.textNoOptions,
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label="District"
                        name="district"
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                      ></TextField>
                    )}
                  />
                ) : null}
              </Grid>

              <Grid
                xs={12}
                md={12}
                lg={12}
                item
                classes={{ root: classes.mainGrid }}
              >
                {/* <Typography variant="h6">Field</Typography> */}
                {this.state.Field ? (
                  <Autocomplete
                    disableClearable="true"
                    disableOpenOnFocus="true"
                    options={this.state.Field}
                    value={
                      this.state.assignedField &&
                      this.state.assignedField !== null
                        ? this.state.assignedField
                        : ""
                    }
                    // disabled={
                    //   new StorageHandler().getUser() &&
                    //   new StorageHandler().getUser().basicDetails &&
                    //   new StorageHandler().getUser().basicDetails.location_id
                    //     ? true
                    //     : false
                    // }
                    name="field"
                    getOptionLabel={(option) =>
                      option.place_name ? option.place_name : ""
                    }
                    onChange={(event, value) => {
                      this.setState(
                        {
                          ...this.state,
                          cameras: {},
                          assignedField: value,
                          generationFields1: {
                            ...this.state.generationFields1,
                            field: value,
                            camera: "",
                          },
                        },
                        () => {
                          this.props.fetchFieldCamera(value.location_id);
                        }
                      );
                    }}
                    popupIcon={<DownArrow />}
                    classes={{
                      input: classes.textValue,
                      option: classes.textValue,
                      popupIndicatorOpen: classes.popIconOpen,
                      popupIndicator: classes.popIcon,
                      noOptions: classes.textNoOptions,
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label="Field"
                        name="field"
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                      ></TextField>
                    )}
                  />
                ) : null}
              </Grid>
              <Grid
                xs={12}
                md={12}
                lg={12}
                item
                classes={{ root: classes.mainGrid }}
                style={{ paddingBottom: "20px" }}
              >
                {/* <Typography variant="h6">Camera</Typography> */}
                {this.state.Camera ? (
                  <Autocomplete
                    disableClearable="true"
                    disableOpenOnFocus="true"
                    options={this.state.Camera}
                    value={this.state.generationFields1.camera}
                    name="camera"
                    getOptionLabel={(option) =>
                      option.field
                        ? option.camera_id + "-" + " " + option.field
                        : ""
                    }
                    onChange={(event, value) => {
                      this.setState({
                        generationFields1: {
                          ...this.state.generationFields1,
                          camera: value,
                        },
                        cameras: { camera: value.camera_id },
                      });
                      let test = [];
                      test.push(value.camera_id);
                      this.state.filsearchParams.camera_id = test;
                    }}
                    popupIcon={<DownArrow />}
                    classes={{
                      input: classes.textValue,
                      option: classes.textValue,
                      popupIndicatorOpen: classes.popIconOpen,
                      popupIndicator: classes.popIcon,
                      noOptions: classes.textNoOptions,
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label="Camera"
                        name="camera"
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                      ></TextField>
                    )}
                  />
                ) : null}
              </Grid>
              <Grid
                xs={12}
                md={12}
                lg={12}
                item
                classes={{ root: classes.mainGrid }}
              >
                <Typography className={classes.filterSubHeading}>
                  Report Type
                </Typography>
                <RadioGroup
                  style={{ display: "flex" }}
                  id="report_type"
                  select
                  label="Report Type"
                  //className={classes.textField}
                  name="report_type"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={
                    this.state.reportType == "" ? "" : this.state.reportType
                  }
                  onChange={(event) => {
                    this.setState({
                      paramsCategory: {
                        type: event.target.value,
                      },
                      reportType: event.target.value,
                    });
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                >
                  <Grid container item xs={12}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={<Radio />}
                        label="Weekly"
                        value="weekly"
                        classes={{ label: classes.formLabel }}
                      >
                        Weekly
                      </FormControlLabel>
                      <FormControlLabel
                        control={<Radio />}
                        label="Daily"
                        value="daily"
                        classes={{ label: classes.formLabel }}
                      >
                        Daily
                      </FormControlLabel>
                    </Grid>
                  </Grid>
                </RadioGroup>
                {/* <FormControl variant="outlined" fullWidth margin="dense">
                  <InputLabel
                    ref={(ref) => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-age-simple"
                  >
                    Report Format
                  </InputLabel>

                  <Select
                    fullWidth
                    value={
                      this.state.paramsCategory.type == ""
                        ? ""
                        : this.state.paramsCategory.type
                    }
                    onChange={(event) => {
                      this.setState({
                        paramsCategory: {
                          type: event.target.value,
                        },
                      });
                    }}
                    input={
                      <OutlinedInput
                        labelWidth={17 * 8}
                        name="type"
                        id="outlined-age-simple"
                      />
                    }
                  >
                    <MenuItem value="weekly">
                      <em>Weekly</em>
                    </MenuItem>
                    <MenuItem value="daily">
                      <em>Daily</em>
                    </MenuItem>
                  </Select>
                </FormControl> */}
              </Grid>
            </Grid>
            <br></br>
          </DialogContent>

          <DialogActions className={classes.drawerAction}>
            <Grid container xs={12} justify={"center"} spacing={2}>
              <Grid item xs={1} md={1}></Grid>
              <Grid item xs={5} md={5}>
                <Button
                  onClick={this.CloseFilter}
                  className={classes.closeButton}
                  fullWidth
                  disableElevation={true}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={5} md={5}>
                <Button
                  onClick={this.generateReport}
                  color="secondary"
                  autoFocus
                  variant="contained"
                  className={classes.button}
                  fullWidth
                  disableElevation={true}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={1} md={1}></Grid>
            </Grid>
          </DialogActions>
        </Drawer>
        <Fragment>
          <Toolbar className={classes.root}>
            <Typography
              variant="h6"
              id="tableTitle"
              className={classes.tableTitle}
            >
              Summary Report
            </Typography>
            <div className={classes.spacer} />

            <div className={classes.actions}>
              <Tooltip title="Filter list">
                <IconButton
                  aria-label="Filter list"
                  onClick={this.toggleFilterWindow}
                >
                  <FilterListIcon className={classes.filterIcon} />
                </IconButton>
              </Tooltip>
              {reportData && Object.keys(reportData).length === 0 ? (
                ""
              ) : (
                <Tooltip title="Download Report">
                  <IconButton aria-label="Download Report">
                    <DownloadIcon
                      className={classes.filterIcon}
                      onClick={this.exportReport}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Toolbar>
          <Divider className={classes.divider} />
          {/* {console.log(reportData)} */}
          <Paper className={classes.reportPaper} elevation={0}>
            {this.props.loading == false &&
            parseInt(reportData.length) - 1 == -2 ? (
              <div className={classes.notAddedStyle}>
                {messageConstants.NO_REPORTS_FOUND}
              </div>
            ) : (
              <React.Fragment>
                {reportData && reportData.length > 0 ? (
                  <>
                    <div className={classes.container}>
                      {/* <TableContainer> */}
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            {reportTableHeader.map((row, i) => {
                              return (
                                <TableCell
                                  key={i}
                                  classes={{ root: classes.tableHead }}
                                >
                                  {row}
                                </TableCell>
                              );
                            }, this)}
                          </TableRow>
                        </TableHead>
                        {this.props.types == true ? (
                          <TableBody>
                            {reportData.map((row, index) => (
                              <TableRow
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                                className={
                                  index % 2 == 0
                                    ? classes.firstRow
                                    : classes.secondRow
                                }
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.datetableCell1 }}
                                >
                                  <InputBase
                                    className={classes.datetableCell1}
                                    value={
                                      row.monthly != null ? row.monthly : "-"
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.vehicle_count != null
                                    ? row.vehicle_count
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.alert_count != null
                                    ? row.alert_count
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.low_alert_warning_count != null
                                    ? row.low_alert_warning_count
                                    : "-"}
                                </TableCell>

                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.warning_count != null
                                    ? row.warning_count
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.validewaybillcount != null
                                    ? row.validewaybillcount
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.invalidewaybillcount != null
                                    ? row.invalidewaybillcount
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.commercial_vehicle_count != null
                                    ? row.commercial_vehicle_count
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.other_vehicle_count != null
                                    ? row.other_vehicle_count
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.verified_count != null
                                    ? row.verified_count
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.total_exempted_goods != null
                                    ? row.total_exempted_goods
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.vegetables != null
                                    ? row.vegetables
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.fish != null ? row.fish : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.egg != null ? row.egg : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.chicken != null ? row.chicken : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.fruits != null ? row.fruits : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.milk != null ? row.milk : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.petroleum_products != null
                                    ? row.petroleum_products
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.value_below_50k != null
                                    ? row.value_below_50k
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.empty_vehicles != null
                                    ? row.empty_vehicles
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.passenger_vehicles != null
                                    ? row.passenger_vehicles
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  classes={{ root: classes.tableCell }}
                                >
                                  {row.other_commodity != null
                                    ? row.other_commodity
                                    : "-"}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          <TableBody>
                            {reportData.map((row, index) => (
                              <TableRow
                                role="checkbox"
                                className={
                                  index % 2 == 0
                                    ? classes.firstRow
                                    : classes.secondRow
                                }
                                tabIndex={-1}
                                key={index}
                              >
                                {Object.keys(reportData[0]).map((cell, j) =>
                                  cell === "event_date" || cell === "weekly" ? (
                                    <TableCell
                                      key={j}
                                      component="th"
                                      scope="row"
                                      classes={{ root: classes.datetableCell1 }}
                                    >
                                      {cell === "event_date" ? (
                                        <InputBase
                                          className={classes.datetableCell}
                                          value={moment(row[cell]).format(
                                            "YYYY-MM-DD"
                                          )}
                                        />
                                      ) : (
                                        <InputBase
                                          className={classes.datetableCell1}
                                          value={
                                            row[cell] == null || row[cell] == ""
                                              ? "-"
                                              : row[cell]
                                          }
                                        />
                                      )}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      key={j}
                                      component="th"
                                      scope="row"
                                      classes={{ root: classes.tableCell }}
                                    >
                                      {row[cell] == null || row[cell] == ""
                                        ? "-"
                                        : row[cell]}
                                    </TableCell>
                                  )
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        )}
                      </Table>
                    </div>
                  </>
                ) : (
                  // <>
                  //   {this.props.loading ? (
                  //     <SkeltonScreen />
                  //   ) : (
                  //     <div className={classes.notAddedStyle}>
                  //       {messageConstants.CLICK_GENERATE_TO_VIEW_REPORT}
                  //     </div>
                  //   )}
                  // </>
                  <>
                    {sessionStorage.getItem("summaryreportsMasterError") ===
                    "true" ? (
                      <div className={classes.notAddedStyle}>
                        {messageConstants.NO_REPORTS_FOUND}
                      </div>
                    ) : (
                      <SkeltonScreen />
                    )}
                  </>
                )}
              </React.Fragment>
            )}
          </Paper>
        </Fragment>
      </>
    );
  }
}

export default withStyles(styles)(Summary);

// import React, { Component, Fragment } from "react";
// import {
//   Paper,
//   Typography,
//   Grid,
//   withStyles,
//   Button,
//   TextField,
//   Table,
//   TableCell,
//   TableHead,
//   TableRow,
//   TableBody,
//   TablePagination,
//   Toolbar,
//   Tooltip,
//   FormControl,
//   InputLabel,
//   Select,
//   OutlinedInput,
//   InputBase,
//   MenuItem,
//   TableContainer,
//   makeStyles,
//   RadioGroup,
//   Drawer,
//   FormControlLabel,
//   Divider,
// } from "@material-ui/core";
// import moment from "moment";
// import PropTypes from "prop-types";
// // import DownloadIcon from "@material-ui/icons/SaveAlt";
// import ReportTitle from "../reports/components/ReportTitle";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import StorageHandler from "../../util/storage/index";
// import IconButton from "@material-ui/core/IconButton";
// import FirstPageIcon from "@material-ui/icons/FirstPage";
// import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
// import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// import LastPageIcon from "@material-ui/icons/LastPage";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import "../reports/components/report_filter/style.css";
// import {
//   KeyboardDatePicker,
//   MuiPickersUtilsProvider,
// } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
// import messageConstants from "../../services/validator/MsgConsts/messageConstants";
// import { ReactComponent as DateTime } from "../../assets/SvgIcons/date.svg";
// import { ReactComponent as DownArrow } from "../../assets/SvgIcons/dropdown.svg";
// import { ReactComponent as FilterListIcon } from "../../assets/SvgIcons/filter.svg";
// import SkeltonScreen from "../../components/skelton_screen/reportSkelton";
// import { ReactComponent as DownloadIcon } from "../../assets/SvgIcons/download.svg";
// import Radio from "../../components/filteralert_form/styledRadio";

// const actionsStyles = (theme) => ({
//   root: {
//     flexShrink: 0,
//     color: theme.palette.text.secondary,
//     marginLeft: theme.spacing.unit * 2.5,
//   },
// });

// class TablePaginations extends React.Component {
//   handleFirstPageButtonClick = (event) => {
//     this.props.onChangePage(event, 0);
//   };

//   handleBackButtonClick = (event) => {
//     this.props.onChangePage(event, this.props.page - 1);
//   };

//   handleNextButtonClick = (event) => {
//     this.props.onChangePage(event, this.props.page + 1);
//   };

//   handleLastPageButtonClick = (event) => {
//     this.props.onChangePage(
//       event,
//       Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
//     );
//   };

//   render() {
//     const { classes, count, page, rowsPerPage, theme } = this.props;

//     return (
//       <div className={classes.root}>
//         <IconButton
//           onClick={this.handleFirstPageButtonClick}
//           disabled={page === 0}
//           aria-label="First Page"
//         >
//           {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
//         </IconButton>
//         <IconButton
//           onClick={this.handleBackButtonClick}
//           disabled={page === 0}
//           aria-label="Previous Page"
//         >
//           {theme.direction === "rtl" ? (
//             <KeyboardArrowRight />
//           ) : (
//             <KeyboardArrowLeft />
//           )}
//         </IconButton>
//         <IconButton
//           onClick={this.handleNextButtonClick}
//           disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//           aria-label="Next Page"
//         >
//           {theme.direction === "rtl" ? (
//             <KeyboardArrowLeft />
//           ) : (
//             <KeyboardArrowRight />
//           )}
//         </IconButton>
//         <IconButton
//           onClick={this.handleLastPageButtonClick}
//           disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//           aria-label="Last Page"
//         >
//           {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
//         </IconButton>
//       </div>
//     );
//   }
// }

// TablePaginations.propTypes = {
//   classes: PropTypes.object.isRequired,
//   count: PropTypes.number.isRequired,
//   onChangePage: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired,
//   theme: PropTypes.object.isRequired,
// };

// const TablePaginationActionsWrapped = withStyles(actionsStyles, {
//   withTheme: true,
// })(TablePaginations);
// const drawerWidth = 328;

// const styles = (theme) => ({
//   root: {
//     padding: "12px 35px",
//     paddingRight: "16px",
//     backgroundColor: "#ffff",
//     marginTop: "43px",
//     marginRight: "18px",
//     marginLeft: "22px",
//     borderTopLeftRadius: theme.border.radius,
//     borderTopRightRadius: theme.border.radius,
//   },
//   divider: {
//     marginRight: "18px",
//     marginLeft: "22px",
//     border: "1px solid #E7EBF4",
//   },
//   spacer: {
//     flex: "1 1 20%",
//   },
//   // actions: {
//   //   paddingRight: theme.spacing.unit,
//   // },
//   title: {
//     flex: "0 0 auto",
//   },
//   tableTitle: {
//     textAlign: "left",
//     font: "normal normal bold 18px Karla",
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//   },
//   filterIcon: {
//     color: "#A4AFC6",
//   },

//   drawerPaper: {
//     width: drawerWidth,
//   },
//   container: {
//     // padding: theme.spacing.unit,
//     overflow: "auto",
//     maxWidth: "70vw",
//     minWidth: "-webkit-fill-available",
//     paddingBottom: "20px",
//   },
//   reportPaper: {
//     // padding: "10px",
//     boxShadow: "0px 0px 4px #0000000D",
//     background: "#FFFFFF 0% 0% no-repeat padding-box",
//     paddingLeft: "26px",
//     paddingRight: "23px",
//     marginLeft: "22px",
//     marginRight: "18px",
//     paddingBottom: "20px",
//     borderTopLeftRadius: "0px",
//     borderTopRightRadius: "0px",
//     borderBottomLeftRadius: "15px",
//     borderBottomRightRadius: "15px",
//   },
//   reportTitle: {
//     height: "50px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     fontWeight: theme.font.fontWeight,
//   },
//   emptyReport: {
//     minHeight: "60vh",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     font: "normal normal bold 34px Karla;",
//     letterSpacing: "0px",
//     color: "#797C82",
//     opacity: 1,
//   },
//   firstRow: {
//     background: "#F1F4FB 0% 0% no-repeat padding-box",
//     opacity: 1,
//   },
//   secondRow: {
//     background: "#FAFBFE 0% 0% no-repeat padding-box",
//     opacity: 1,
//   },

//   tableHead: {
//     textAlign: "left",
//     font: "normal normal bold 14px Karla;",
//     letterSpacing: "0px",
//     color: "#797C82",
//     opacity: 1,
//     padding: "6px 14px",
//     borderColor: "#E7EBF4",
//   },
//   tableCell: {
//     textAlign: "left",
//     font: "normal normal normal 14px Karla",
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//     padding: "7px 14px",
//     border: "0px",
//   },

//   buttonGrid: {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "space-between",
//   },
//   datetableCell: {
//     width: "110px",
//     textAlign: "left",
//     font: "normal normal normal 14px Karla",
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//     padding: "7px 14px",
//     paddingLeft: "7px",
//     border: "0px",
//   },
//   datetableCell1: {
//     width: "200px",
//     textAlign: "left",
//     font: "normal normal normal 14px Karla",
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//     padding: "7px 14px",
//     paddingLeft: "7px",
//     border: "0px",
//   },
//   button: {
//     fontSize: theme.font.size3,
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//     letterSpacing: "0px",
//     backgroundColor: theme.palette.secondary.main,
//     color: "white",
//     opacity: 1,
//     textTransform: "none",
//     paddingLeft: "38px",
//     paddingRight: "37px",
//     height: "42px",
//     background: "#1BB99A 0% 0% no-repeat padding-box",
//   },
//   clearButton: {
//     textTransform: "none",
//     fontWeight: theme.font.fontWeight,
//     backgroundColor: theme.colors.BlueButton,
//     color: theme.colors.whiteColor,
//     "&:hover": {
//       backgroundColor: theme.colors.BlueButton,
//     },
//   },
//   closeButton: {
//     height: "42px",
//     textTransform: "none",
//     fontSize: theme.font.size3,
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//     letterSpacing: "0px",
//     backgroundColor: theme.colors.BlueButton,
//     paddingLeft: "38px",
//     paddingRight: "37px",
//     background: "#E7EBF4 0% 0% no-repeat padding-box",
//   },
//   drawerAction: {
//     height: "74px",
//     backgroundColor: "#F9F9FE",
//     paddingTop: "16px",
//     paddingBottom: "16px",
//   },
//   divider1: {
//     backgroundColor: "#E7EBF4",
//   },
//   filterTitle: {
//     font: "normal normal bold 18px Karla",
//     color: "#2B3D51",
//   },
//   filterClearAll: {
//     font: "normal normal normal 16px Karla",
//     color: "#1BB99A",
//     textTransform: "none",
//   },
//   filterSubHeading: {
//     font: "normal normal bold 16px/22px Karla",
//     color: "#2B3D51",
//   },
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//   },

//   dialogContent: {
//     paddingLeft: "22px",
//     paddingRight: "15px",
//   },

//   formHelperText: {
//     color: theme.colors.redColor,
//   },
//   textLabel: {
//     letterSpacing: "0px",
//     color: "#797C82",
//     opacity: 1,
//     fontSize: theme.font.size3,
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//     padding: "0px",
//     margin: "0px",
//   },
//   textValue: {
//     letterSpacing: "0px",
//     color: "#2B3D51",
//     opacity: 1,
//     fontSize: theme.font.size1,
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//   },
//   textNoOptions: {
//     letterSpacing: "0px",
//     opacity: 1,
//     fontSize: "18px",
//     fontWeight: theme.font.regular,
//     fontFamily: theme.font.family,
//   },
//   formLabel: {
//     font: "normal normal normal 14px Karla",
//     letterSpacing: "0px",
//     color: "#797C82",
//     opacity: 1,
//   },
//   popIcon: {
//     marginTop: "6px",
//     marginRight: "6px",
//   },
//   popIconOpen: {
//     marginTop: "4px",
//     marginRight: "6px",
//   },
//   dateIcon: {
//     marginTop: "0px",
//     marginRight: "-8px",
//   },
//   mainGrid: {
//     paddingBottom: "2px",
//   },
//   notAddedStyle: {
//     // backgroundColor: theme.notAddedStyle.backgroundColor,
//     textAlign: "center",
//     padding: 55,
//     marginLeft: 33,
//     marginRight: 33,
//     // marginTop: 13,
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     font: "normal normal normal 24px Karla;",
//     letterSpacing: "0px",
//     color: "#797C82",
//     opacity: 1,
//   },
// });
// class Summary extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       assignedDistrict: new StorageHandler().getUser()
//         ? new StorageHandler().getUser().basicDetails.district_master
//         : "",
//       assignedField: new StorageHandler().getUser()
//         ? new StorageHandler().getUser().basicDetails.location_master
//         : "",
//       assignedZone: new StorageHandler().getUser()
//         ? new StorageHandler().getUser().basicDetails.zone_master
//         : "",
//       validationErrors: {},
//       validationParms: { from_date: "", to_date: "" },
//       Field: [],
//       Zone: [],
//       District: [],
//       Timeperiod: [
//         { name: "Last One Week", value: "1" },
//         { name: "Last One Month", value: "2" },
//         { name: "Last Three Month", value: "3" },
//         { name: "Last Six Month", value: "4" },
//         { name: "Last Nine Month", value: "5" },
//         { name: "Last One Year", value: "6" },
//       ],
//       Camera: [],
//       value: false,
//       reportParams: {},
//       searchParams: { camera_id: [] },
//       filsearchParams: { camera_id: [] },
//       reportType: null,
//       cameras: {},
//       rows: [],
//       page: 0,
//       limit: 10,
//       paramsCategory: {},
//       reportFilterParams: { type: "" },
//       generationFields1: {
//         from_date: "",
//         to_date: "",
//         zone: "",
//         district: "",
//         camera: "",
//         field: "",
//         timePeriod: "",
//       },
//       generationFields: {
//         from_date: "",
//         to_date: "",
//       },
//       designationList: [
//         { zone_name: "dss", value: "1" },
//         { zone_name: "88s", value: "2" },
//       ],
//     };
//   }
//   componentDidMount() {
//     this.props.resetReportData();
//     this.props.fetchZone();
//     this.props.fetchStateCamera();
//     this.initialCall();
//     if (
//       new StorageHandler().getUser() &&
//       new StorageHandler().getUser().basicDetails.zone_master &&
//       new StorageHandler().getUser().basicDetails.zone_master != null
//     ) {
//       this.props.fetchDistrict(this.state.assignedZone.zone_id);
//       this.props.fetchZoneCamera(this.state.assignedZone.zone_id);
//     }

//     if (
//       new StorageHandler().getUser() &&
//       new StorageHandler().getUser().basicDetails.district_master &&
//       new StorageHandler().getUser().basicDetails.district_master != null
//     ) {
//       this.props.fetchField(this.state.assignedDistrict.district_id);
//       this.props.fetchDistrictCamera(this.state.assignedDistrict.district_id);
//     }
//     if (
//       new StorageHandler().getUser() &&
//       new StorageHandler().getUser().basicDetails.location_master &&
//       new StorageHandler().getUser().basicDetails.location_master != null
//     ) {
//       this.props.fetchFieldCamera(
//         new StorageHandler().getUser().basicDetails.location_master.location_id
//       );
//     }
//   }
//   componentDidUpdate(prevProps, prevState) {
//     if (prevProps.fetchedzone != this.props.fetchedzone) {
//       this.setState({
//         ...this.state,
//         Zone: this.props.fetchedzone,
//       });
//     }

//     if (prevProps.district != this.props.district) {
//       this.setState({
//         ...this.state,
//         District: this.props.district,
//       });
//     }
//     if (prevProps.field != this.props.field) {
//       this.setState({
//         ...this.state,
//         Field: this.props.field,
//       });
//     }
//     if (prevProps.Camera != this.props.Camera) {
//       this.setState({
//         ...this.state,
//         Camera: this.props.Camera,
//       });
//     }
//   }
//   handleTimePeriod = (e, value) => {
//     if (
//       this.state.generationFields1.from_date != "" ||
//       this.state.generationFields1.to_date != ""
//     ) {
//       this.props.showSnackbar(
//         "Your Selected From and To date will change accordingly",
//         "info"
//       );
//     }
//     if (value.value == 1) {
//       var d1 = new Date();
//       var d = new Date();
//       d.setDate(d.getDate() - 7);
//       this.setState({
//         generationFields1: {
//           ...this.state.generationFields1,
//           timePeriod: value,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//         reportParams: {
//           ...this.state.reportParams,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//         generationFields: {
//           ...this.state.generationFields,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//         paramsCategory: {
//           type: "daily",
//         },
//       });
//     }
//     if (value.value == 2) {
//       var d1 = new Date();
//       var d = new Date();
//       d.setMonth(d.getMonth() - 1);
//       this.setState({
//         generationFields1: {
//           ...this.state.generationFields1,
//           timePeriod: value,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//         reportParams: {
//           ...this.state.reportParams,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//         paramsCategory: {
//           type: "monthly",
//           interval: "1",
//         },
//         generationFields: {
//           ...this.state.generationFields,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//       });
//     }
//     if (value.value == 3) {
//       var d1 = new Date();
//       var d = new Date();
//       d.setMonth(d.getMonth() - 3);
//       this.setState({
//         generationFields1: {
//           ...this.state.generationFields1,
//           timePeriod: value,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//         reportParams: {
//           ...this.state.reportParams,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//         paramsCategory: {
//           type: "monthly",
//           interval: "3",
//         },
//         generationFields: {
//           ...this.state.generationFields,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//       });
//     }
//     if (value.value == 4) {
//       var d1 = new Date();
//       var d = new Date();

//       d.setMonth(d.getMonth() - 6);

//       this.setState({
//         generationFields1: {
//           ...this.state.generationFields1,
//           timePeriod: value,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//         paramsCategory: {
//           type: "monthly",
//           interval: "6",
//         },
//         reportParams: {
//           ...this.state.reportParams,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//         generationFields: {
//           ...this.state.generationFields,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//       });
//     }
//     if (value.value == 5) {
//       var d1 = new Date();
//       var d = new Date();

//       d.setMonth(d.getMonth() - 9);

//       this.setState({
//         generationFields1: {
//           ...this.state.generationFields1,
//           timePeriod: value,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//         reportParams: {
//           ...this.state.reportParams,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//         paramsCategory: {
//           type: "monthly",
//           interval: "9",
//         },
//         generationFields: {
//           ...this.state.generationFields,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//       });
//     }
//     if (value.value == 6) {
//       var d1 = new Date();
//       var d = new Date();
//       d.setMonth(d.getMonth() - 12);
//       this.setState({
//         reportParams: {
//           ...this.state.reportParams,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//         paramsCategory: {
//           type: "monthly",
//           interval: "12",
//         },
//         generationFields: {
//           ...this.state.generationFields,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//         generationFields1: {
//           ...this.state.generationFields1,
//           timePeriod: value,
//           from_date: moment(d).format("YYYY-MM-DD"),
//           to_date: moment(d1).format("YYYY-MM-DD"),
//         },
//       });
//     }
//   };
//   validate = () => {
//     const { generationFields } = this.state;
//     var validationSuccess = true;
//     let validationErrors = {};
//     Object.keys(generationFields).map((field) => {
//       if (
//         generationFields[field] === "" ||
//         generationFields[field] == null ||
//         generationFields[field] === "Invalid date" ||
//         generationFields[field] === "Invalid Date"
//       ) {
//         validationErrors[field] = true;
//         validationSuccess = false;
//       } else {
//         validationErrors[field] = false;
//       }
//     });
//     this.setState({
//       validationErrors,
//     });
//     return validationSuccess;
//   };
//   initialCall = () => {
//     var d1 = new Date();
//     var d = new Date();
//     d.setDate(d.getDate() - 7);
//     let test = [];
//     if (
//       new StorageHandler().getUser() &&
//       new StorageHandler().getUser().basicDetails &&
//       new StorageHandler().getUser().basicDetails.camera
//     ) {
//       new StorageHandler().getUser().basicDetails.camera.map((row, i) => {
//         test.push(row.camera_id);
//       });
//     }
//     let data = {
//       generateUrl: "/api/reports/dashboardSummary",
//       generateParams: {
//         type: "daily",
//         from_date: moment(d).format("YYYY-MM-DD"),
//         to_date: moment(d1).format("YYYY-MM-DD"),
//       },
//       generatedata: test,
//     };
//     this.props.fetchReport(data);
//   };
//   generateReport = () => {
//     if (this.validate()) {
//       // this.resetReportFilter()
//       this.toggleFilterWindow();
//       const { reportParams, searchParams, paramsCategory } = this.state;
//       if (!(this.state.searchParams && this.state.cameras.camera)) {
//         const camera_id = [];
//         this.setState(
//           {
//             rows: [],
//           },
//           () => {
//             this.state.Camera.map((row, i) => {
//               let test = [...this.state.rows];
//               test.push(row.camera_id);
//               this.state.rows = test;
//             });
//             this.state.searchParams.camera_id = this.state.rows;
//           }
//         );
//       } else {
//         this.state.searchParams.camera_id = this.state.filsearchParams.camera_id;
//       }

//       Object.keys(reportParams).map((item) => {
//         if (
//           reportParams[item] === "" ||
//           reportParams[item] == null ||
//           reportParams[item] == undefined
//         ) {
//           delete reportParams[item];
//         }
//       });
//       Object.keys(searchParams).map((item) => {
//         if (
//           searchParams[item] === "" ||
//           searchParams[item] == null ||
//           searchParams[item] == undefined
//         ) {
//           delete searchParams[item];
//         }
//       });
//       this.setState(
//         {
//           type: paramsCategory.type,
//           type1: paramsCategory.type,
//           value: true,
//         },
//         () => {
//           let generateParams = {
//             ...reportParams,
//             ...paramsCategory,
//             // page: 0,
//             // limit: 10,
//           };
//           let data = {
//             generateUrl: "/api/reports/dashboardSummary",
//             generateParams: generateParams,
//             generatedata: searchParams,
//           };
//           this.props.fetchReport(data);
//         }
//       );
//     } else {
//       this.props.showSnackbar(
//         "Please enter valid dates or Time Period..!",
//         "info"
//       );
//     }
//   };
//   resetReportFilter = () => {
//     this.setState({
//       reportFilterParams: {
//         type: "",
//       },
//       generationFields2: {
//         type: "",
//       },
//     });
//   };
//   initialExport = () => {
//     var d1 = new Date();
//     var d = new Date();
//     d.setDate(d.getDate() - 7);
//     let test = [];
//     if (
//       new StorageHandler().getUser() &&
//       new StorageHandler().getUser().basicDetails &&
//       new StorageHandler().getUser().basicDetails.camera
//     ) {
//       new StorageHandler().getUser().basicDetails.camera.map((row, i) => {
//         test.push(row.camera_id);
//       });
//     }
//     let data = {
//       exportUrl: "/api/reports/dashboardSummary-report-print",
//       exportParams: {
//         type: "daily",
//         from_date: moment(d).format("YYYY-MM-DD"),
//         to_date: moment(d1).format("YYYY-MM-DD"),
//       },
//       exportdata: test,
//     };
//     this.props.exportReport(data);
//   };
//   exportReport = () => {
//     const {
//       generationFields,
//       reportParams,
//       searchParams,
//       reportFilterParams,
//       paramsCategory,
//     } = this.state;
//     if (
//       Object.keys(reportParams).length == 0 &&
//       Object.keys(paramsCategory).length == 0
//     ) {
//       this.initialExport();
//     } else {
//       let exportParams = {
//         ...reportParams,
//         ...paramsCategory,
//       };
//       let data = {
//         exportUrl: "/api/reports/dashboardSummary-report-print",
//         exportParams: exportParams,
//         exportdata: searchParams,
//       };
//       this.props.exportReport(data);
//     }
//   };

//   // handleChangePage = (event, page) => {
//   //   this.setState({ page: page }, () => {
//   //     const {
//   //       reportParams,
//   //       searchParams,
//   //       reportFilterParams,
//   //       paramsCategory,
//   //     } = this.state;
//   //     Object.keys(reportParams).map((item) => {
//   //       if (
//   //         reportParams[item] === "" ||
//   //         reportParams[item] == null ||
//   //         reportParams[item] == undefined
//   //       ) {
//   //         delete reportParams[item];
//   //       }
//   //     });
//   //     Object.keys(searchParams).map((item) => {
//   //       if (
//   //         searchParams[item] === "" ||
//   //         searchParams[item] == null ||
//   //         searchParams[item] == undefined
//   //       ) {
//   //         delete searchParams[item];
//   //       }
//   //     });
//   //     Object.keys(reportFilterParams).map((item) => {
//   //       if (
//   //         reportFilterParams[item] === "" ||
//   //         reportFilterParams[item] == null ||
//   //         reportFilterParams[item] == undefined
//   //       ) {
//   //         delete reportFilterParams[item];
//   //       }
//   //     });

//   //     if (reportFilterParams.from_date) {
//   //       let generateParams = {
//   //         ...reportFilterParams,
//   //         // page: page,
//   //         // limit: this.state.limit,
//   //       };
//   //       let data = {
//   //         generateUrl: "/api/reports/dashboardSummary",
//   //         generateParams: generateParams,
//   //         generatedata: searchParams,
//   //       };
//   //       this.props.fetchReport(data);
//   //     } else {
//   //       let generateParams = {
//   //         ...reportParams,
//   //         ...paramsCategory,
//   //         // page: this.state.page,
//   //         // limit: this.state.limit,
//   //       };
//   //       let data = {
//   //         generateUrl: "/api/reports/dashboardSummary",
//   //         generateParams: generateParams,
//   //         generatedata: searchParams,
//   //       };
//   //       this.props.fetchReport(data);
//   //     }
//   //   });
//   // };

//   // handleChangeRowsPerPage = (event) => {
//   //   this.setState(
//   //     {
//   //       ...this.state,
//   //       limit: event.target.value,
//   //     },
//   //     () => {
//   //       const {
//   //         generationFields,
//   //         reportFilterParams,
//   //         reportParams,
//   //         searchParams,
//   //         paramsCategory,
//   //       } = this.state;

//   //       Object.keys(reportParams).map((item) => {
//   //         if (
//   //           reportParams[item] === "" ||
//   //           reportParams[item] == null ||
//   //           reportParams[item] == undefined
//   //         ) {
//   //           delete reportParams[item];
//   //         }
//   //       });
//   //       Object.keys(searchParams).map((item) => {
//   //         if (
//   //           searchParams[item] === "" ||
//   //           searchParams[item] == null ||
//   //           searchParams[item] == undefined
//   //         ) {
//   //           delete searchParams[item];
//   //         }
//   //       });
//   //       Object.keys(reportFilterParams).map((item) => {
//   //         if (
//   //           reportFilterParams[item] === "" ||
//   //           reportFilterParams[item] == null ||
//   //           reportFilterParams[item] == undefined
//   //         ) {
//   //           delete reportFilterParams[item];
//   //         }
//   //       });
//   //       if (reportFilterParams.from_date) {
//   //         let generateParams = {
//   //           ...reportFilterParams,
//   //           // page: this.state.page,
//   //           // limit: event.target.value,
//   //         };
//   //         let data = {
//   //           generateUrl: "/api/reports/dashboardSummary",
//   //           generateParams: generateParams,
//   //           generatedata: searchParams,
//   //         };
//   //         this.props.fetchReport(data);
//   //       } else {
//   //         let generateParams = {
//   //           ...reportParams,
//   //           ...paramsCategory,
//   //           // page: this.state.page,
//   //           // limit: event.target.value,
//   //         };
//   //         let data = {
//   //           generateUrl: "/api/reports/dashboardSummary",
//   //           generateParams: generateParams,
//   //           generatedata: searchParams,
//   //         };
//   //         this.props.fetchReport(data);
//   //       }
//   //     }
//   //   );
//   // };
//   clearAll = () => {
//     // this.props.resetReportData();
//     this.props.fetchStateCamera();
//     this.resetReportFilterParams();
//     this.initialCall();
//     this.setState(
//       {
//         reportParams: {},
//         searchParams: {},
//         filsearchParams: {},
//         // paramsCategory: {},
//         value: false,
//         paramsCategory: { type: "" },
//         generationFields: { from_date: "", to_date: "" },
//         cameras: {},
//         reportType: null,
//         generationFields1: {
//           from_date: "",
//           to_date: "",
//           camera: "",
//           timePeriod: "",
//         },
//       },
//       () => {
//         if (new StorageHandler().getUser().basicDetails.zone_master == null) {
//           this.setState({
//             assignedZone: {},
//             District: [],
//             Field: [],
//           });
//         } else {
//           this.props.fetchDistrict(
//             new StorageHandler().getUser().basicDetails.zone_master.zone_id
//           );
//           this.props.fetchZoneCamera(
//             new StorageHandler().getUser().basicDetails.zone_master.zone_id
//           );
//           this.setState({
//             Field: [],
//           });
//         }
//         if (
//           new StorageHandler().getUser().basicDetails.district_master == null
//         ) {
//           this.setState({
//             assignedDistrict: {},
//             Field: [],
//           });
//         } else {
//           this.props.fetchField(
//             new StorageHandler().getUser().basicDetails.district_master
//               .district_id
//           );
//           this.props.fetchDistrictCamera(
//             new StorageHandler().getUser().basicDetails.district_master
//               .district_id
//           );
//         }
//         if (
//           new StorageHandler().getUser().basicDetails.location_master == null
//         ) {
//           this.setState({
//             assignedField: {},
//           });
//         } else {
//           this.props.fetchFieldCamera(
//             new StorageHandler().getUser().basicDetails.location_master
//               .location_id
//           );
//         }
//       }
//     );
//   };
//   CloseFilter = () => {
//     this.toggleFilterWindow();
//     if (this.state.value == false) {
//       this.clearAll();
//     }
//   };
//   toggleFilterWindow = () => {
//     this.setState({
//       showReportFilterWindow: this.state.showReportFilterWindow ? false : true,
//     });
//   };
//   resetReportFilterParams = () => {
//     this.setState({
//       value: false,
//       type1: "",
//       reportFilterParams: {
//         type: "",
//       },
//       generationFields2: {
//         type: "",
//       },
//     });
//   };
//   render() {
//     const { classes } = this.props;
//     const { reportData } = this.props;
//     const reportTableHeader = [
//       "Date",
//       "Vehicles",
//       "Alerts",
//       "Warnings",
//       "Valid EwayBill",
//       "Invalid EwayBill",
//       "Commercial Vehicle",
//       "Other Vehicle",
//       "Verified",
//       "Total Exempted Goods",
//       "Vegetables",
//       "Fish",
//       "Eggs",
//       "Chicken",
//       "Fruits",
//       "Milk",
//       "Petroleum Products",
//       "Value below 50k",
//       "Empty Vehicles",
//       "Passenger Vehicles",
//       "Other Commodity",
//     ];
//     return (
//       <>
//         <Drawer
//           className={classes.drawer}
//           // variant="persistent"
//           anchor="right"
//           classes={{
//             paper: classes.drawerPaper,
//           }}
//           open={this.state.showReportFilterWindow}
//           onClose={this.toggleFilterWindow}
//           aria-labelledby="alert-dialog-title"
//           aria-describedby="alert-dialog-description"
//         >
//           <DialogTitle id="form-dialog-title">
//             <Grid container justify="space-between" alignItems="center">
//               <Grid item xs={6} md={6}>
//                 <span className={classes.filterTitle}>Filter</span>
//               </Grid>
//               <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
//                 <Button
//                   disableElevation={true}
//                   onClick={this.clearAll}
//                   className={classes.filterClearAll}
//                 >
//                   Clear All
//                 </Button>
//               </Grid>
//             </Grid>
//           </DialogTitle>
//           <Divider className={classes.divider1} />
//           <DialogContent
//             className="DrawerMain"
//             classes={{ root: classes.dialogContent }}
//           >
//             <Grid container>
//               <Grid xs={12} md={12} item classes={{ root: classes.mainGrid }}>
//                 {/* <Typography variant="h6">From Date</Typography> */}
//                 <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                   <KeyboardDatePicker
//                     fullWidth
//                     label="From Date"
//                     name="from_date"
//                     // disableToolbar
//                     inputVariant="outlined"
//                     format="dd/MM/yyyy"
//                     margin="dense"
//                     value={
//                       this.state.generationFields1.from_date == ""
//                         ? null
//                         : this.state.generationFields1.from_date
//                     }
//                     onChange={(date) => {
//                       this.setState({
//                         generationFields1: {
//                           ...this.state.generationFields1,
//                           timePeriod: "",
//                           from_date: moment(date).format("YYYY-MM-DD"),
//                         },
//                         reportParams: {
//                           ...this.state.reportParams,
//                           from_date: moment(date).format("YYYY-MM-DD"),
//                         },
//                         paramsCategory: {
//                           type: "daily",
//                         },
//                         generationFields: {
//                           ...this.state.generationFields,
//                           from_date: moment(date).format("YYYY-MM-DD"),
//                         },
//                       });
//                     }}
//                     KeyboardButtonProps={{
//                       "aria-label": "change date",
//                     }}
//                     InputLabelProps={{
//                       classes: { root: classes.textLabel },
//                     }}
//                     InputProps={{ classes: { root: classes.textValue } }}
//                     keyboardIcon={<DateTime className={classes.dateIcon} />}
//                   />
//                 </MuiPickersUtilsProvider>
//               </Grid>

//               <Grid xs={12} md={12} item classes={{ root: classes.mainGrid }}>
//                 {/* <Typography variant="h6">To Date</Typography> */}
//                 <MuiPickersUtilsProvider utils={DateFnsUtils}>
//                   <KeyboardDatePicker
//                     fullWidth
//                     label="To Date"
//                     name="to_date"
//                     // disableToolbar
//                     inputVariant="outlined"
//                     format="dd/MM/yyyy"
//                     margin="dense"
//                     value={
//                       this.state.generationFields1.to_date == ""
//                         ? null
//                         : this.state.generationFields1.to_date
//                     }
//                     onChange={(date) => {
//                       this.setState({
//                         generationFields1: {
//                           ...this.state.generationFields1,
//                           timePeriod: "",
//                           to_date: moment(date).format("YYYY-MM-DD"),
//                         },
//                         reportParams: {
//                           ...this.state.reportParams,
//                           to_date: moment(date).format("YYYY-MM-DD"),
//                         },
//                         paramsCategory: {
//                           type: "daily",
//                         },
//                         generationFields: {
//                           ...this.state.generationFields,
//                           to_date: moment(date).format("YYYY-MM-DD"),
//                         },
//                       });
//                     }}
//                     KeyboardButtonProps={{
//                       "aria-label": "change date",
//                     }}
//                     InputLabelProps={{
//                       classes: { root: classes.textLabel },
//                     }}
//                     InputProps={{ classes: { root: classes.textValue } }}
//                     keyboardIcon={<DateTime className={classes.dateIcon} />}
//                   />
//                 </MuiPickersUtilsProvider>
//               </Grid>
//               <Grid
//                 xs={12}
//                 md={12}
//                 lg={12}
//                 item
//                 style={{ textAlign: "center" }}
//                 classes={{ root: classes.mainGrid }}
//               >
//                 <Typography style={{ display: "inline-block" }}>
//                   <b>OR</b>
//                 </Typography>
//               </Grid>
//               <Grid
//                 xs={12}
//                 md={12}
//                 lg={12}
//                 item
//                 classes={{ root: classes.mainGrid }}
//                 style={{ paddingBottom: "20px" }}
//               >
//                 {/* <Typography variant="h6">Time Period</Typography> */}
//                 {this.state.designationList ? (
//                   <Autocomplete
//                     disableClearable="true"
//                     disableOpenOnFocus="true"
//                     options={this.state.Timeperiod}
//                     value={this.state.generationFields1.timePeriod}
//                     name="camera"
//                     getOptionLabel={(option) =>
//                       option.name ? option.name : ""
//                     }
//                     onChange={this.handleTimePeriod}
//                     popupIcon={<DownArrow />}
//                     classes={{
//                       input: classes.textValue,
//                       option: classes.textValue,
//                       popupIndicatorOpen: classes.popIconOpen,
//                       popupIndicator: classes.popIcon,
//                       noOptions: classes.textNoOptions,
//                     }}
//                     renderInput={(params) => (
//                       <TextField
//                         fullWidth
//                         label="Time Period"
//                         name="timePeriod"
//                         id="outlined-margin-dense"
//                         margin="dense"
//                         variant="outlined"
//                         {...params}
//                         InputLabelProps={{
//                           classes: { root: classes.textLabel },
//                         }}
//                       ></TextField>
//                     )}
//                   />
//                 ) : null}
//               </Grid>

//               <Grid
//                 xs={12}
//                 md={12}
//                 lg={12}
//                 item
//                 classes={{ root: classes.mainGrid }}
//               >
//                 {/* <Typography variant="h6">Zone</Typography> */}
//                 {this.state.designationList ? (
//                   <Autocomplete
//                     disableClearable="true"
//                     disableOpenOnFocus="true"
//                     disabled={
//                       new StorageHandler().getUser() &&
//                       new StorageHandler().getUser().basicDetails &&
//                       new StorageHandler().getUser().basicDetails.zone_id
//                         ? true
//                         : false
//                     }
//                     options={this.state.Zone}
//                     value={
//                       this.state.assignedZone &&
//                       this.state.assignedZone !== null
//                         ? this.state.assignedZone
//                         : ""
//                     }
//                     name="zone"
//                     getOptionLabel={(option) =>
//                       option.zone_name ? option.zone_name : ""
//                     }
//                     onChange={(event, value) => {
//                       this.setState(
//                         {
//                           ...this.state,
//                           assignedZone: value,
//                           camers: {},
//                           assignedDistrict: {},
//                           assignedField: {},
//                           generationFields1: {
//                             ...this.state.generationFields1,
//                             zone: value,
//                             camera: "",
//                           },
//                         },
//                         () => {
//                           this.props.fetchDistrict(
//                             this.state.assignedZone.zone_id
//                           );
//                           this.props.fetchZoneCamera(
//                             this.state.assignedZone.zone_id
//                           );
//                         }
//                       );
//                     }}
//                     popupIcon={<DownArrow />}
//                     classes={{
//                       input: classes.textValue,
//                       option: classes.textValue,
//                       popupIndicatorOpen: classes.popIconOpen,
//                       popupIndicator: classes.popIcon,
//                       noOptions: classes.textNoOptions,
//                     }}
//                     renderInput={(params) => (
//                       <TextField
//                         fullWidth
//                         label="Zone"
//                         name="zone"
//                         id="outlined-margin-dense"
//                         margin="dense"
//                         variant="outlined"
//                         {...params}
//                         InputLabelProps={{
//                           classes: { root: classes.textLabel },
//                         }}
//                       ></TextField>
//                     )}
//                   />
//                 ) : null}
//               </Grid>

//               <Grid
//                 xs={12}
//                 md={12}
//                 lg={12}
//                 item
//                 classes={{ root: classes.mainGrid }}
//               >
//                 {/* <Typography variant="h6">District</Typography> */}
//                 {this.state.designationList ? (
//                   <Autocomplete
//                     disableClearable="true"
//                     disableOpenOnFocus="true"
//                     options={this.state.District}
//                     value={
//                       this.state.assignedDistrict &&
//                       this.state.assignedDistrict !== null
//                         ? this.state.assignedDistrict
//                         : ""
//                     }
//                     disabled={
//                       new StorageHandler().getUser() &&
//                       new StorageHandler().getUser().basicDetails &&
//                       new StorageHandler().getUser().basicDetails.district_id
//                         ? true
//                         : false
//                     }
//                     name="district"
//                     getOptionLabel={(option) =>
//                       option.district_name ? option.district_name : ""
//                     }
//                     onChange={(event, value) => {
//                       this.setState(
//                         {
//                           ...this.state,
//                           assignedDistrict: value,
//                           cameras: {},
//                           assignedField: {},
//                           generationFields1: {
//                             ...this.state.generationFields1,
//                             district: value,
//                             camera: "",
//                           },
//                         },
//                         () => {
//                           this.props.fetchField(
//                             this.state.assignedDistrict.district_id
//                           );
//                           this.props.fetchDistrictCamera(
//                             this.state.assignedDistrict.district_id
//                           );
//                         }
//                       );
//                     }}
//                     popupIcon={<DownArrow />}
//                     classes={{
//                       input: classes.textValue,
//                       option: classes.textValue,
//                       popupIndicatorOpen: classes.popIconOpen,
//                       popupIndicator: classes.popIcon,
//                       noOptions: classes.textNoOptions,
//                     }}
//                     renderInput={(params) => (
//                       <TextField
//                         fullWidth
//                         label="District"
//                         name="district"
//                         id="outlined-margin-dense"
//                         margin="dense"
//                         variant="outlined"
//                         {...params}
//                         InputLabelProps={{
//                           classes: { root: classes.textLabel },
//                         }}
//                       ></TextField>
//                     )}
//                   />
//                 ) : null}
//               </Grid>

//               <Grid
//                 xs={12}
//                 md={12}
//                 lg={12}
//                 item
//                 classes={{ root: classes.mainGrid }}
//               >
//                 {/* <Typography variant="h6">Field</Typography> */}
//                 {this.state.designationList ? (
//                   <Autocomplete
//                     disableClearable="true"
//                     disableOpenOnFocus="true"
//                     options={this.state.Field}
//                     value={
//                       this.state.assignedField &&
//                       this.state.assignedField !== null
//                         ? this.state.assignedField
//                         : ""
//                     }
//                     disabled={
//                       new StorageHandler().getUser() &&
//                       new StorageHandler().getUser().basicDetails &&
//                       new StorageHandler().getUser().basicDetails.location_id
//                         ? true
//                         : false
//                     }
//                     name="field"
//                     getOptionLabel={(option) =>
//                       option.place ? option.place : ""
//                     }
//                     onChange={(event, value) => {
//                       this.setState(
//                         {
//                           ...this.state,
//                           cameras: {},
//                           assignedField: value,
//                           generationFields1: {
//                             ...this.state.generationFields1,
//                             field: value,
//                             camera: "",
//                           },
//                         },
//                         () => {
//                           this.props.fetchFieldCamera(value.location_id);
//                         }
//                       );
//                     }}
//                     popupIcon={<DownArrow />}
//                     classes={{
//                       input: classes.textValue,
//                       option: classes.textValue,
//                       popupIndicatorOpen: classes.popIconOpen,
//                       popupIndicator: classes.popIcon,
//                       noOptions: classes.textNoOptions,
//                     }}
//                     renderInput={(params) => (
//                       <TextField
//                         fullWidth
//                         label="Field"
//                         name="field"
//                         id="outlined-margin-dense"
//                         margin="dense"
//                         variant="outlined"
//                         {...params}
//                         InputLabelProps={{
//                           classes: { root: classes.textLabel },
//                         }}
//                       ></TextField>
//                     )}
//                   />
//                 ) : null}
//               </Grid>
//               <Grid
//                 xs={12}
//                 md={12}
//                 lg={12}
//                 item
//                 classes={{ root: classes.mainGrid }}
//                 style={{ paddingBottom: "20px" }}
//               >
//                 {/* <Typography variant="h6">Camera</Typography> */}
//                 {this.state.designationList ? (
//                   <Autocomplete
//                     disableClearable="true"
//                     disableOpenOnFocus="true"
//                     options={this.state.Camera}
//                     value={this.state.generationFields1.camera}
//                     name="camera"
//                     getOptionLabel={(option) =>
//                       option.field
//                         ? option.camera_id + "-" + " " + option.field
//                         : ""
//                     }
//                     onChange={(event, value) => {
//                       this.setState({
//                         generationFields1: {
//                           ...this.state.generationFields1,
//                           camera: value,
//                         },
//                         cameras: { camera: value.camera_id },
//                       });
//                       let test = [];
//                       test.push(value.camera_id);
//                       this.state.filsearchParams.camera_id = test;
//                     }}
//                     popupIcon={<DownArrow />}
//                     classes={{
//                       input: classes.textValue,
//                       option: classes.textValue,
//                       popupIndicatorOpen: classes.popIconOpen,
//                       popupIndicator: classes.popIcon,
//                       noOptions: classes.textNoOptions,
//                     }}
//                     renderInput={(params) => (
//                       <TextField
//                         fullWidth
//                         label="Camera"
//                         name="camera"
//                         id="outlined-margin-dense"
//                         margin="dense"
//                         variant="outlined"
//                         {...params}
//                         InputLabelProps={{
//                           classes: { root: classes.textLabel },
//                         }}
//                       ></TextField>
//                     )}
//                   />
//                 ) : null}
//               </Grid>
//               <Grid
//                 xs={12}
//                 md={12}
//                 lg={12}
//                 item
//                 classes={{ root: classes.mainGrid }}
//               >
//                 <Typography className={classes.filterSubHeading}>
//                   Report Type
//                 </Typography>
//                 <RadioGroup
//                   style={{ display: "flex" }}
//                   id="report_type"
//                   select
//                   label="Report Type"
//                   //className={classes.textField}
//                   name="report_type"
//                   fullWidth
//                   variant="outlined"
//                   margin="dense"
//                   value={
//                     this.state.reportType == "" ? "" : this.state.reportType
//                   }
//                   onChange={(event) => {
//                     this.setState({
//                       paramsCategory: {
//                         type: event.target.value,
//                       },
//                       reportType: event.target.value,
//                     });
//                   }}
//                   SelectProps={{
//                     MenuProps: {
//                       className: classes.menu,
//                     },
//                   }}
//                 >
//                   <Grid container item xs={12}>
//                     <Grid item xs={12} md={6}>
//                       <FormControlLabel
//                         control={<Radio />}
//                         label="Weekly"
//                         value="weekly"
//                         classes={{ label: classes.formLabel }}
//                       >
//                         Weekly
//                       </FormControlLabel>
//                       <FormControlLabel
//                         control={<Radio />}
//                         label="Daily"
//                         value="daily"
//                         classes={{ label: classes.formLabel }}
//                       >
//                         Daily
//                       </FormControlLabel>
//                     </Grid>
//                   </Grid>
//                 </RadioGroup>
//                 {/* <FormControl variant="outlined" fullWidth margin="dense">
//                   <InputLabel
//                     ref={(ref) => {
//                       this.InputLabelRef = ref;
//                     }}
//                     htmlFor="outlined-age-simple"
//                   >
//                     Report Format
//                   </InputLabel>

//                   <Select
//                     fullWidth
//                     value={
//                       this.state.paramsCategory.type == ""
//                         ? ""
//                         : this.state.paramsCategory.type
//                     }
//                     onChange={(event) => {
//                       this.setState({
//                         paramsCategory: {
//                           type: event.target.value,
//                         },
//                       });
//                     }}
//                     input={
//                       <OutlinedInput
//                         labelWidth={17 * 8}
//                         name="type"
//                         id="outlined-age-simple"
//                       />
//                     }
//                   >
//                     <MenuItem value="weekly">
//                       <em>Weekly</em>
//                     </MenuItem>
//                     <MenuItem value="daily">
//                       <em>Daily</em>
//                     </MenuItem>
//                   </Select>
//                 </FormControl> */}
//               </Grid>
//             </Grid>
//             <br></br>
//           </DialogContent>

//           <DialogActions className={classes.drawerAction}>
//             <Grid container xs={12} justify={"center"} spacing={2}>
//               <Grid item xs={1} md={1}></Grid>
//               <Grid item xs={5} md={5}>
//                 <Button
//                   onClick={this.CloseFilter}
//                   className={classes.closeButton}
//                   fullWidth
//                   disableElevation={true}
//                 >
//                   Cancel
//                 </Button>
//               </Grid>
//               <Grid item xs={5} md={5}>
//                 <Button
//                   onClick={this.generateReport}
//                   color="secondary"
//                   autoFocus
//                   variant="contained"
//                   className={classes.button}
//                   fullWidth
//                   disableElevation={true}
//                 >
//                   Apply
//                 </Button>
//               </Grid>
//               <Grid item xs={1} md={1}></Grid>
//             </Grid>
//           </DialogActions>
//         </Drawer>
//         <Fragment>
//           <Toolbar className={classes.root}>
//             <Typography
//               variant="h6"
//               id="tableTitle"
//               className={classes.tableTitle}
//             >
//               Summary Report
//             </Typography>
//             <div className={classes.spacer} />

//             <div className={classes.actions}>
//               <Tooltip title="Filter list">
//                 <IconButton
//                   aria-label="Filter list"
//                   onClick={this.toggleFilterWindow}
//                 >
//                   <FilterListIcon className={classes.filterIcon} />
//                 </IconButton>
//               </Tooltip>
//               {reportData && Object.keys(reportData).length === 0 ? (
//                 ""
//               ) : (
//                 <Tooltip title="Download Report">
//                   <IconButton aria-label="Download Report">
//                     <DownloadIcon
//                       className={classes.filterIcon}
//                       onClick={this.exportReport}
//                     />
//                   </IconButton>
//                 </Tooltip>
//               )}
//             </div>
//           </Toolbar>
//           <Divider className={classes.divider} />
//           <Paper className={classes.reportPaper} elevation={0}>
//             {reportData && reportData.length > 0 ? (
//               <>
//                 <div className={classes.container}>
//                   {/* <TableContainer> */}
//                   <Table>
//                     <TableHead>
//                       <TableRow>
//                         {reportTableHeader.map((row, i) => {
//                           return (
//                             <TableCell
//                               key={i}
//                               classes={{ root: classes.tableHead }}
//                             >
//                               {row}
//                             </TableCell>
//                           );
//                         }, this)}
//                       </TableRow>
//                     </TableHead>
//                     {this.props.types == true ? (
//                       <TableBody>
//                         {reportData.map((row, index) => (
//                           <TableRow
//                             role="checkbox"
//                             tabIndex={-1}
//                             key={index}
//                             className={
//                               index % 2 == 0
//                                 ? classes.firstRow
//                                 : classes.secondRow
//                             }
//                           >
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.datetableCell1 }}
//                             >
//                               <InputBase
//                                 className={classes.datetableCell1}
//                                 value={row.monthly != null ? row.monthly : "-"}
//                               />
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.vehicle_count != null
//                                 ? row.vehicle_count
//                                 : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.alert_count != null ? row.alert_count : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.warning_count != null
//                                 ? row.warning_count
//                                 : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.validewaybillcount != null
//                                 ? row.validewaybillcount
//                                 : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.invalidewaybillcount != null
//                                 ? row.invalidewaybillcount
//                                 : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.commercial_vehicle_count != null
//                                 ? row.commercial_vehicle_count
//                                 : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.other_vehicle_count != null
//                                 ? row.other_vehicle_count
//                                 : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.verified_count != null
//                                 ? row.verified_count
//                                 : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.total_exempted_goods != null
//                                 ? row.total_exempted_goods
//                                 : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.vegetables != null ? row.vegetables : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.fish != null ? row.fish : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.egg != null ? row.egg : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.chicken != null ? row.chicken : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.fruits != null ? row.fruits : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.milk != null ? row.milk : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.petroleum_products != null
//                                 ? row.petroleum_products
//                                 : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.value_below_50k != null
//                                 ? row.value_below_50k
//                                 : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.empty_vehicles != null
//                                 ? row.empty_vehicles
//                                 : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.passenger_vehicles != null
//                                 ? row.passenger_vehicles
//                                 : "-"}
//                             </TableCell>
//                             <TableCell
//                               component="th"
//                               scope="row"
//                               classes={{ root: classes.tableCell }}
//                             >
//                               {row.other_commodity != null
//                                 ? row.other_commodity
//                                 : "-"}
//                             </TableCell>
//                           </TableRow>
//                         ))}
//                       </TableBody>
//                     ) : (
//                       <TableBody>
//                         {reportData.map((row, index) => (
//                           <TableRow
//                             role="checkbox"
//                             className={
//                               index % 2 == 0
//                                 ? classes.firstRow
//                                 : classes.secondRow
//                             }
//                             tabIndex={-1}
//                             key={index}
//                           >
//                             {Object.keys(reportData[0]).map((cell, j) =>
//                               cell === "event_date" || cell === "weekly" ? (
//                                 <TableCell
//                                   key={j}
//                                   component="th"
//                                   scope="row"
//                                   classes={{ root: classes.datetableCell1 }}
//                                 >
//                                   {cell === "event_date" ? (
//                                     <InputBase
//                                       className={classes.datetableCell}
//                                       value={moment(row[cell]).format(
//                                         "YYYY-MM-DD"
//                                       )}
//                                     />
//                                   ) : (
//                                     <InputBase
//                                       className={classes.datetableCell1}
//                                       value={
//                                         row[cell] == null || row[cell] == ""
//                                           ? "-"
//                                           : row[cell]
//                                       }
//                                     />
//                                   )}
//                                 </TableCell>
//                               ) : (
//                                 <TableCell
//                                   key={j}
//                                   component="th"
//                                   scope="row"
//                                   classes={{ root: classes.tableCell }}
//                                 >
//                                   {row[cell] == null || row[cell] == ""
//                                     ? "-"
//                                     : row[cell]}
//                                 </TableCell>
//                               )
//                             )}
//                           </TableRow>
//                         ))}
//                       </TableBody>
//                     )}
//                   </Table>
//                   {/* </TableContainer> */}
//                 </div>
//                 {/* <TablePagination
//                 component="div"
//                 count={this.props.reportData.length}
//                 rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
//                 rowsPerPage={this.state.limit}
//                 page={this.state.page}
//                 backIconButtonProps={{
//                   "aria-label": "Previous Page",
//                 }}
//                 nextIconButtonProps={{
//                   "aria-label": "Next Page",
//                 }}
//                 onChangePage={this.handleChangePage}
//                 onChangeRowsPerPage={this.handleChangeRowsPerPage}
//               />{" "} */}
//               </>
//             ) : (
//               <>
//                 {this.props.loading ? (
//                   <SkeltonScreen />
//                 ) : (
//                   <div className={classes.notAddedStyle}>
//                     {messageConstants.CLICK_GENERATE_TO_VIEW_REPORT}
//                   </div>
//                 )}
//                 {/* <div className={classes.emptyReport}>
//                   <Typography
//                     variant="h5"
//                     align="center"
//                     color="textSecondary"
//                     className={classes.typo}
//                   >
//                     {messageConstants.NO_REPORTS_FOUND}
//                   </Typography>
//                 </div> */}
//               </>
//             )}
//           </Paper>
//         </Fragment>
//       </>
//     );
//   }
// }

// export default withStyles(styles)(Summary);
