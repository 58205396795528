export const FETCH_SQUAD_LIST = "FETCH_SQUAD_LIST";
export const FETCH_SQUAD_LIST_ERROR = "FETCH_SQUAD_LIST_ERROR";
export const DELETE_SQUAD = "DELETE_SQUAD";
export const RESET_DELETE = "RESET_DELETE";
export const DELETE_SQUAD_ERROR = "DELETE_SQUAD_ERROR";
export const FETCH_DISTRICT = "FETCH_DISTRICT";
export const FETCH_DISTRICT_FAILED = "FETCH_DISTRICT_FAILED";
export const DIALOGUE_WINDOW_STATUS = "DIALOGUE_WINDOW_STATUS";
export const FILTER_CHANGE = "FILTER_CHANGE";
export const FILTER_CHANGE_ERROR = "FILTER_CHANGE_ERROR";
