import { connect } from "react-redux";
import { withRouter } from "react-router";
import RecentActivity from "./RecentActivity";
import { fetchRecentActivity } from "./data/action";

function mapStateToProps(state) {
  return {
    plate: state.recentActivity.plate,
    recentActivities: state.recentActivity.recentActivities,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRecentActivity: (plate) => dispatch(fetchRecentActivity(plate)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecentActivity)
);
