import {
  FETCH_DUTY_LIST,
  DELETE_DUTY,
  RESET_DELETE,
  FETCH_DUTY_LIST_ERROR,
  DELETE_DUTY_ERROR,
  FILTER_CHANGE,
  FILTER_CHANGE_ERROR,
  DIALOGUE_WINDOW_STATUS,
  FETCH_DISTRICT_SUCCESS,
  FETCH_DISTRICT_FAILED,
} from "./constants";
import DutyMasterService from "../../../service/DutyMasterService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchDutyList = (page, limit) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DutyMasterService().fetchDutyList(page, limit).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };
  function success(data) {
    return { type: FETCH_DUTY_LIST, data };
  }
  function failure(error) {
    return { type: FETCH_DUTY_LIST_ERROR, error };
  }
};
export const fetchDistrict = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DutyMasterService().fetchDistrict().then((response) => {
      if (response.data) {
        // console.log("test district", response.data);
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };
  function success(data) {
    return { type: FETCH_DISTRICT_SUCCESS, data };
  }
  function failure(error) {
    return { type: FETCH_DISTRICT_FAILED, error };
  }
};

export const dialogueWindowStatus = () => {
  return (dispatch) => {
    // dispatch(progressActions.showProgress());
    dispatch(dialogueWindowStatus());
  };
  function dialogueWindowStatus() {
    return { type: DIALOGUE_WINDOW_STATUS };
  }
};
export const onFilterChange = (page, limit, squadname, district) => {
  let dutyMasterService = new DutyMasterService();
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    dutyMasterService
      .onFilterChange(page, limit, squadname, district)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: FILTER_CHANGE, data };
  }
  function failure(error) {
    return { type: FILTER_CHANGE_ERROR, error };
  }
};

export const deleteDuty = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DutyMasterService().deleteDuty(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: DELETE_DUTY, data };
  }
  function failure(error) {
    return { type: DELETE_DUTY_ERROR, error };
  }
};

export function resetDelete() {
  return {
    type: RESET_DELETE,
  };
}
