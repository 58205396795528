import {
  TOGGLE_FILTER_WINDOW,
  SET_ALERT_FILTER_PARAMS,
  RESET_ALERT_FILTER_PARAMS,
  FETCH_LANE,
  FETCH_LANE_FAILED,
  FETCH_CAMERA,
  FETCH_CAMERA_FAILED,
  FETCH_VEHICLE,
  FETCH_VEHICLE_FAILED,
  FETCH_ALERT,
  FETCH_ALERT_FAILED,
  CLOSE_WINDOW,
} from "./constants";
const initialState = {
  showFilterWindow: false,
  val: false,
  filterParams: {},
  fetchLanes: [],
  fetchCameras: [],
  fetchVehicles: [],
  fetchedAlert: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FILTER_WINDOW:
      return {
        ...state,
        showFilterWindow: !state.showFilterWindow,
      };
    case CLOSE_WINDOW:
      return {
        ...state,
        val: action.data,
      };

    case SET_ALERT_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.filterAlertParams,
      };
    case RESET_ALERT_FILTER_PARAMS:
      return {
        ...state,
        filterParams: {},
      };
    case FETCH_LANE:
      return {
        ...state,
        fetchLanes: action.data.data,
      };
    case FETCH_LANE_FAILED:
      return {
        ...initialState,
      };
    case FETCH_CAMERA:
      return {
        ...state,
        fetchCameras: action.data.data,
      };
    case FETCH_CAMERA_FAILED:
      return {
        ...initialState,
      };
    case FETCH_VEHICLE:
      return {
        ...state,
        fetchVehicles: action.data.data,
      };
    case FETCH_VEHICLE_FAILED:
      return {
        ...initialState,
      };
    case FETCH_ALERT:
      return {
        ...state,
        fetchedAlert: action.data.data,
      };
    case FETCH_ALERT_FAILED:
      return {
        ...state,
        fetchedAlert: [],
      };
    default:
      return state;
  }
}

// import {
//   TOGGLE_FILTER_WINDOW,
//   SET_ALERT_FILTER_PARAMS,
//   RESET_ALERT_FILTER_PARAMS,
//   FETCH_LANE,
//   FETCH_LANE_FAILED,
//   FETCH_CAMERA,
//   FETCH_CAMERA_FAILED,
//   FETCH_VEHICLE,
//   FETCH_VEHICLE_FAILED,
//   FETCH_ALERT,
//   FETCH_ALERT_FAILED,
//   CLOSE_WINDOW,
// } from "./constants";
// import moment from "moment";
// const initialState = {
//   showFilterWindow: false,
//   filterParams: {
//     from_date: new Date().setMonth(new Date().getMonth() - 6),
//     to_date: new Date().setMonth(new Date().getMonth() - 0),
//   },
//   fetchLanes: [],
//   fetchCameras: [],
//   fetchVehicles: [],
//   fetchedAlert: [],
// };
// export default function (state = initialState, action) {
//   switch (action.type) {
//     case TOGGLE_FILTER_WINDOW:
//       return {
//         ...state,
//         showFilterWindow: !state.showFilterWindow,
//       };
//     case CLOSE_WINDOW:
//       return {
//         ...state,
//         val: !state.val,
//       };

//     case SET_ALERT_FILTER_PARAMS:
//       return {
//         ...state,
//         filterParams: action.filterAlertParams,
//       };
//     case RESET_ALERT_FILTER_PARAMS:
//       return {
//         ...state,
//         filterParams: {
//           from_date: new Date().setMonth(new Date().getMonth() - 6),
//           to_date: new Date().setMonth(new Date().getMonth() - 0),
//         },
//       };
//     case FETCH_LANE:
//       return {
//         ...state,
//         fetchLanes: action.data.data,
//       };
//     case FETCH_LANE_FAILED:
//       return {
//         ...initialState,
//       };
//     case FETCH_CAMERA:
//       return {
//         ...state,
//         fetchCameras: action.data.data,
//       };
//     case FETCH_CAMERA_FAILED:
//       return {
//         ...initialState,
//       };
//     case FETCH_VEHICLE:
//       return {
//         ...state,
//         fetchVehicles: action.data.data,
//       };
//     case FETCH_VEHICLE_FAILED:
//       return {
//         ...initialState,
//       };
//     case FETCH_ALERT:
//       return {
//         ...state,
//         fetchedAlert: action.data.data,
//       };
//     case FETCH_ALERT_FAILED:
//       return {
//         ...state,
//         fetchedAlert: [],
//       };
//     default:
//       return state;
//   }
// }
