export const TOGGLE_FILTER_WINDOW = "TOGGLE_FILTER_WINDOW";
export const SET_HISTORY_FILTER_PARAMS = "SET_HISTORY_FILTER_PARAMS";
export const RESET_HISTORY_FILTER_PARAMS = "RESET_HISTORY_FILTER_PARAMS";
export const FETCH_LANE = "FETCH_LANE";
export const FETCH_LANE_FAILED = "FETCH_LANE_FAILED";
export const FETCH_CAMERA = "FETCH_CAMERA";
export const FETCH_CAMERA_FAILED = "FETCH_CAMERA_FAILED";
export const FETCH_VEHICLE = "FETCH_VEHICLE";
export const FETCH_VEHICLE_FAILED = "FETCH_VEHICLE_FAILED";
export const FETCH_ALERT_FAILED = "FETCH_ALERT_FAILED";
export const FETCH_ALERT = "FETCH_ALERT";
export const FETCH_WARNING = "FETCH_WARNING";
export const FETCH_WARNING_FAILED = "FETCH_WARNING_FAILED";
export const CLOSE_WINDOW = "CLOSE_WINDOW";
export const GET_ZONE = "GET_ZONE";
export const GET_ZONE_FAILED = "GET_ZONE_FAILED";
export const GET_DISTRICT = "GET_DISTRICT";
export const GET_DISTRICT_FAILED = "GET_DISTRICT_FAILED";
export const GET_FIELD = "GET_FIELD";
export const GET_FIELD_FAILED = "GET_FIELD_FAILED";

export const SET_FROMDATE = "SET_FROMDATE";
export const RESET_FROMDATE = "RESET_FROMDATE";
export const SET_TODATE = "SET_TODATE";
export const RESET_TODATE = "RESET_TODATE";
export const SET_PLATE = "SET_PLATE";
export const RESET_PLATE = "RESET_PLATE";
export const SET_ALERTTYPE = "SET_ALERTTYPE";
export const RESET_ALERTTYPE = "RESET_ALERTTYPE";
export const SET_ZONE = "SET_ZONE";
export const RESET_ZONE = "RESET_ZONE";
export const SET_DISTRICT = "SET_DISTRICT";
export const RESET_DISTRICT = "RESET_DISTRICT";
export const SET_FIELD = "SET_FIELD";
export const RESET_FIELD = "RESET_FIELD";
export const SET_CAMERA = "SET_CAMERA";
export const RESET_CAMERA = "RESET_CAMERA";
export const SET_COMMODITY = "SET_COMMODITY";
export const RESET_COMMODITY = "RESET_COMMODITY";
export const SET_OFFENDER = "SET_OFFENDER";
export const RESET_OFFENDER = "RESET_OFFENDER";
export const SET_VEHICLE = "SET_VEHICLE";
export const RESET_VEHICLE = "RESET_VEHICLE";
export const SET_LANE = "SET_LANE";
export const RESET_LANE = "RESET_LANE";
export const SET_WARNINGTYPE = "SET_WARNINGTYPE";
export const RESET_WARNINGTYPE = "RESET_WARNINGTYPE";
