import {
  FETCH_REVIEW_LIST,
  FETCH_REVIEW_LIST_ERROR,
  SET_WARNING_PAGE,
  RESET_WARNING_PAGE,
  RESET_ERROR_PAGE,
} from "./constants";
import ReviewService from "../../../service/ReviewService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchWarningList = (page, limit, filterParams) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new ReviewService()
      .fetchWarningList(page, limit, filterParams)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: FETCH_REVIEW_LIST, data };
  }
  function failure(error) {
    return { type: FETCH_REVIEW_LIST_ERROR, error };
  }
};

export const setWarningPage = (page) => {
  return (dispatch) => {
    dispatch(setWarningPage(page));
  };
  function setWarningPage(page) {
    return { type: SET_WARNING_PAGE, page };
  }
};

export const resetWarningPage = () => {
  return (dispatch) => {
    dispatch(resetWarningPage());
  };
  function resetWarningPage() {
    return { type: RESET_WARNING_PAGE };
  }
};
export const resetError = () => {
  return (dispatch) => {
    dispatch(resetError());
  };
  function resetError() {
    return { type: RESET_ERROR_PAGE };
  }
};
