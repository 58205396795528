export const TOGGLE_FILTER_WINDOW = "TOGGLE_FILTER_WINDOW";
export const SET_FILTER_PARAMS = "SET_FILTER_PARAMS";
export const RESET_FILTER_PARAMS = "RESET_FILTER_PARAMS";
export const FETCH_OFFICE_NAME = "FETCH_OFFICE_NAME";
export const FETCH_OFFICE_NAME_FAILED = "FETCH_OFFICE_NAME_FAILED";
export const FETCH_DISTRICT = "FETCH_DISTRICT";
export const FETCH_DISTRICT_FAILED = "FETCH_DISTRICT_FAILED";
export const FETCH_SQUAD_NAME = "FETCH_SQUAD_NAME";
export const FETCH_SQUAD_NAME_FAILED = "FETCH_SQUAD_NAME_FAILED";
export const CLOSE_WINDOW = "CLOSE_WINDOW";
