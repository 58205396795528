import {
  ADD_EDIT_DESIGNATION_MASTER,
  ADD_EDIT_DESIGNATION_MASTER_FAILED,
  RESET_DESIGNATION_MASTER_STATE,
  FETCH_DESIGNATION_MASTER_FAILED,
  FETCH_DESIGNATION_MASTER_SUCCESS,
  FETCH_DESIGNATION_DETAILS,
  FETCH_DESIGNATION_DETAILS_ERROR,
  DELETE_DESIGNATION_MASTER,
  DELETE_DESIGNATION_MASTER_ERROR,
} from "./constants";

const initialState = {
  designationMaster: "",
  singleDesignation: "",
  error: "",
  saveSuccess: false,
  deleteStatus: false,
  message: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DESIGNATION_MASTER_SUCCESS:
      return {
        ...initialState,
        designationMaster: action.data.data,
        saveSuccess: false,
      };
    case FETCH_DESIGNATION_DETAILS:
      return {
        ...initialState,
        singleDesignation: action.data.data,
      };
    case FETCH_DESIGNATION_DETAILS_ERROR:
      return {
        ...initialState,
        singleDesignation: "",
      };
    case DELETE_DESIGNATION_MASTER:
      return {
        ...initialState,
        deleteStatus: true,
      };
    case DELETE_DESIGNATION_MASTER_ERROR:
      return {
        ...initialState,
        deleteStatus: false,
      };

    case FETCH_DESIGNATION_MASTER_FAILED:
      sessionStorage.setItem("designationMasterError", true);
      return {
        ...initialState,
      };

    case ADD_EDIT_DESIGNATION_MASTER:
      return {
        ...state,
        designationMaster: action.data,
        saveSuccess: true,
        message: action.data.message,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };

    case ADD_EDIT_DESIGNATION_MASTER_FAILED:
      return {
        ...initialState,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };
    case RESET_DESIGNATION_MASTER_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
