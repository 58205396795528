import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import ExpansionPanel from "./components/viewRole/index";

class roleMasterView extends Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <ExpansionPanel />
      </div>
    );
  }
}
export default withStyles(styles)(roleMasterView);
