import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
// import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as FilterListIcon } from "../../assets/Visual Assets/filter.svg";
import { ReactComponent as ActionIcon } from "../../assets/Visual Assets/action_icon.svg";
import { ReactComponent as SortArrow } from "../../assets/Visual Assets/sort_down.svg";
import { toDateTime } from "util/date";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Filter from "../../components/filterhistory_form/";
// import FirstPageIcon from "@material-ui/icons/FirstPage";
// import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
// import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// import LastPageIcon from "@material-ui/icons/LastPage";
// import StorageHandler from "../../util/storage";
import messageConstants from "../../services/validator/MsgConsts/messageConstants";
import { Tooltip, Grid, Divider } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import "../userMaster/style.css";
import SkeltonScreen from "../../components/skelton_screen";

// const actionsStyles = (theme) => ({
//   root: {
//     flexShrink: 0,
//     color: theme.palette.text.secondary,
//     marginLeft: theme.spacing.unit * 2.5,
//   },
// });

// class TablePaginationActions extends React.Component {
//   handleFirstPageButtonClick = (event) => {
//     this.props.onChangePage(event, 0);
//   };

//   handleBackButtonClick = (event) => {
//     this.props.onChangePage(event, this.props.page - 1);
//   };

//   handleNextButtonClick = (event) => {
//     this.props.onChangePage(event, this.props.page + 1);
//   };

//   handleLastPageButtonClick = (event) => {
//     this.props.onChangePage(
//       event,
//       Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
//     );
//   };

//   render() {
//     const { classes, count, page, rowsPerPage, theme } = this.props;

//     return (
//       <div className={classes.root}>
//         <IconButton
//           onClick={this.handleFirstPageButtonClick}
//           disabled={page === 0}
//           aria-label="First Page"
//         >
//           {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
//         </IconButton>
//         <IconButton
//           onClick={this.handleBackButtonClick}
//           disabled={page === 0}
//           aria-label="Previous Page"
//         >
//           {theme.direction === "rtl" ? (
//             <KeyboardArrowRight />
//           ) : (
//             <KeyboardArrowLeft />
//           )}
//         </IconButton>
//         <IconButton
//           onClick={this.handleNextButtonClick}
//           disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//           aria-label="Next Page"
//         >
//           {theme.direction === "rtl" ? (
//             <KeyboardArrowLeft />
//           ) : (
//             <KeyboardArrowRight />
//           )}
//         </IconButton>
//         <IconButton
//           onClick={this.handleLastPageButtonClick}
//           disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//           aria-label="Last Page"
//         >
//           {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
//         </IconButton>
//       </div>
//     );
//   }
// }

// TablePaginationActions.propTypes = {
//   classes: PropTypes.object.isRequired,
//   count: PropTypes.number.isRequired,
//   onChangePage: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired,
//   theme: PropTypes.object.isRequired,
// };

// const TablePaginationActionsWrapped = withStyles(actionsStyles, {
//   withTheme: true,
// })(TablePaginationActions);

// let counter = 0;
// function createData(
//   plate,
//   vehicle_category,
//   event_timestamp,
//   confidence,
//   camera_id,
//   lane_id
// ) {
//   counter += 1;
//   return {
//     id: counter,
//     plate,
//     vehicle_category,
//     event_timestamp,
//     confidence,
//     camera_id,
//     lane_id,
//   };
// }

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: "plate",
    disablePadding: true,
    label: "Plate",
  },
  {
    id: "location",
    disablePadding: true,
    label: "Location",
  },
  {
    id: "vehicle_category",
    disablePadding: false,
    label: "Category",
  },
  {
    id: "event_timestamp",
    disablePadding: false,
    label: "Date Time",
  },
  {
    id: "confidence",
    disablePadding: false,
    label: "Confidence",
  },
  { id: "camera_id", disablePadding: false, label: "Camera" },
  { id: "lane_id", disablePadding: false, label: "Lane" },
];

// const CustomTableCell = withStyles((theme) => ({
//   root: {
//     padding: "4px 56px 4px 10px",
//   },
// }))(TableCell);
const headerStyles = (theme) => ({
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
    borderBottom: "1px solid #E7EBF4",
    paddingTop: "10px",
    paddingBottom: "6.5px",
    backgroundColor: "white",
  },
  tableTitle: {
    textAlign: "left",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.tableTitle,
    opacity: 1,
    paddingTop: "10px",
    paddingBottom: "6.5px",
    backgroundColor: "white",
  },
});
class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, classes } = this.props;

    return (
      <TableHead>
        <TableRow className={classes.tableHead}>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                // align={row.numeric ? "center" : "center"}
                // padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
                className={classes.tableTitle}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    //onClick={e => this.onSort(e, "event_timestamp")}
                    onClick={this.createSortHandler(row.id)}
                    className={classes.tableTitle}
                    IconComponent={SortArrow}
                  >
                    {/* onClick={e => this.onSort(e, row.label)} */}
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
          <TableCell className={classes.tableTitle}>Action</TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);

const toolbarStyles = (theme) => ({
  root: {
    borderRadius: theme.border.radius,
    padding: "11px",
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    color: theme.colors.chartHeading,
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    opacity: 1,
    marginLeft: "22px",
  },
  filterIcon: {
    opacity: 1,
    color: theme.colors.filterIconColor,
  },
  divider: { border: "1px solid #E7EBF4", opacity: 1 },
});

let EnhancedTableToolbar = (props) => {
  const { classes, showFilterWindow } = props;

  return (
    <>
      <Toolbar className={classNames(classes.root)}>
        <Typography variant="h6" id="tableTitle" className={classes.tableTitle}>
          History
        </Typography>

        <div className={classes.spacer} />
        <div className={classes.actions}>
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" onClick={showFilterWindow}>
              <FilterListIcon className={classes.filterIcon} />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>
      <Divider className={classes.divider} />
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.border.radius,
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    // paddingLeft: "33px",
    paddingRight: "31px",
    // paddingTop: "6px",

    marginLeft: "33px",

    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "50vh",
      overflowY: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      minHeight: "12vh",
      maxHeight: "45vh",
      overflowY: "auto",
    },
    // /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
  },
  notAddedStyle: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,
    fontSize: 25,
    fontWeight: theme.font.fontWeight,
    marginLeft: 33,
    marginRight: 33,
    marginTop: 33,
    padding: 55,
    fontFamily: theme.font.family,
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "5px",
    paddingBottom: "4.5px",
    height: "57px",
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
  grid: {
    marginTop: "21px",
    paddingTop: "22px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
  },
  paginationText: {
    // textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingTop: "35px",
    paddingLeft: "33px",
  },
});

class HistoryList extends React.Component {
  state = {
    order: "desc",
    orderBy: "event_timestamp",
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 10,
    alertCount: -1,
    selectedDate: new Date(),
    showHistoryFilterWindow: false,
    // filterParams: {},
    // assignedDistrict: new StorageHandler().getUser()
    //   ? new StorageHandler().getUser().basicDetails.district_master
    //   : "",
    // assignedField: new StorageHandler().getUser()
    //   ? new StorageHandler().getUser().basicDetails.location_master
    //   : "",
    // assignedZone: new StorageHandler().getUser()
    //   ? new StorageHandler().getUser().basicDetails.zone_master
    //   : "",
  };
  toggleFilterWindow = () => {
    this.setState({
      showHistoryFilterWindow: this.state.showHistoryFilterWindow
        ? false
        : true,
    });
  };

  componentDidMount() {
    this.props.resetEventTimeStamp();
    let condition = localStorage.getItem("history");
    if (condition === "false") {
      this.props.resetFilterParams();
      this.props.resetPage();
      this.props.resetFromDate();
      this.props.resetToDate();
      this.props.resetPlateNo();
      this.props.resetAlertType();
      this.props.resetZone();
      this.props.resetDistrict();
      this.props.resetField();
      this.props.resetCamera();
      this.props.resetCommodity();
      this.props.resetOffender();
      this.props.resetVehicle();
      this.props.resetLane();
      this.props.resetWarningType();
      this.props.fetchHistoryList(this.props.page, this.props.rowsPerPage, {});
    } else {
      this.props.fetchHistoryList(
        this.props.page,
        this.props.rowsPerPage,
        this.props.filterParams
      );
    }
    // if (
    //   this.state.assignedZone &&
    //   this.state.assignedZone != null &&
    //   this.state.assignedZone != "" &&
    //   this.state.assignedDistrict &&
    //   this.state.assignedDistrict != null &&
    //   this.state.assignedDistrict != "" &&
    //   this.state.assignedField === null
    // ) {
    //   this.setState(
    //     {
    //       filterParams: { district: this.state.assignedDistrict.district_id },
    //     },
    //     () => {
    //       this.props.setFilterParams(this.state.filterParams);
    //     }
    //   );
    // } else if (
    //   this.state.assignedZone &&
    //   this.state.assignedZone != null &&
    //   this.state.assignedZone != "" &&
    //   this.state.assignedDistrict &&
    //   this.state.assignedDistrict != null &&
    //   this.state.assignedDistrict != "" &&
    //   this.state.assignedField &&
    //   this.state.assignedField != null &&
    //   this.state.assignedField != ""
    // ) {
    //   this.setState(
    //     {
    //       filterParams: { field: this.state.assignedField.location_id },
    //     },
    //     () => {
    //       this.props.setFilterParams(this.state.filterParams);
    //     }
    //   );
    // } else if (
    //   this.state.assignedZone &&
    //   this.state.assignedZone != null &&
    //   this.state.assignedZone != "" &&
    //   this.state.assignedDistrict === null &&
    //   this.state.assignedField === null
    // ) {
    //   this.setState(
    //     {
    //       filterParams: { zone: this.state.assignedZone.zone_id },
    //     },
    //     () => {
    //       this.props.setFilterParams(this.state.filterParams);
    //     }
    //   );
    // } else {
    //   this.setState(
    //     {
    //       filterParams: {},
    //     },
    //     () => {
    //       this.props.setFilterParams(this.state.filterParams);
    //     }
    //   );
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.historyList != this.props.historyList) {
      this.setState({
        data: this.props.historyList,
        alertCount: this.props.historyCount,
      });
    }
    if (this.props.error != prevProps.error) {
      this.setState({
        c: 1,
      });
    }

    if (prevProps.page != this.props.page) {
      this.props.fetchHistoryList(
        this.props.page,
        this.props.rowsPerPage,
        this.props.filterParams
      );
    }
    if (prevProps.rowsPerPage != this.props.rowsPerPage) {
      this.props.fetchHistoryList(
        this.props.page,
        this.props.rowsPerPage,
        this.props.filterParams
      );
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "asc";

    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState((state) => ({ selected: state.data.map((n) => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, pagess) => {
    //this.props.setPage(page);
    this.props.setPage(pagess - 1);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.setRowsPerPage(event.target.value);
  };

  handleViewAlertClick = (id, event_timestamp) => {
    this.props.setEventTimeStamp(event_timestamp);
    this.props.navigateTo(`/history/${id}`);
  };
  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  onBackButtonClick = () => {
    this.props.navigateTo("/history");
  };
  componentWillUnmount() {
    this.props.resetError();
  }
  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    // const emptyRows =
    //   rowsPerPage -
    //   Math.min(rowsPerPage, this.state.alertCount - page * rowsPerPage);

    return (
      <Grid className={classes.grid}>
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            showFilterWindow={this.toggleFilterWindow}
            onBackButtonClick={this.onBackButtonClick}
          />
          {/* {this.state.alertCount === 0 ? (
            <>
              <div className={classes.notAddedStyle}>
                {messageConstants.NOT_FOUND}
              </div>
              <div style={{ height: "24px" }}></div>
            </>
          ) : ( */}
          {/* {this.state.alertCount === 0 ? (
            this.props.loading ? (
              <SkeltonScreen />
            ) : (
              <>
                <div className={classes.notAddedStyle}>
                  {messageConstants.NOT_ADDED}
                </div>
                <div style={{ height: "24px" }}></div>
              </>
            )
          ) : ( */}
          {this.props.loading === false && this.state.alertCount == 0 ? (
            <>
              <div className={classes.notAddedStyle}>
                {messageConstants.NOT_ADDED}
              </div>
              <div style={{ height: "24px" }}></div>
            </>
          ) : (
            <>
              <div className={classes.tableWrapper}>
                {data && data.length > 0 ? (
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={this.state.alertCount}
                    />
                    <TableBody>
                      {stableSort(data, getSorting(order, orderBy)).map((n) => {
                        const isSelected = this.isSelected(n.id);
                        return (
                          <TableRow
                            //onClick={event => this.handleClick(event, n.id)}
                            role="checkbox"
                            aria-checked={isSelected}
                            tabIndex={-1}
                            key={n.id}
                            selected={isSelected}
                            className={classes.zebrafont}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableCell}
                            >
                              {n.plate}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              component="th"
                              scope="row"
                            >
                              {n.location}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {n.vehicle_category}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {toDateTime(n.event_timestamp)}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {n.confidence}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {n.camera_id}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {n.lane_id}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              align="center"
                            >
                              <IconButton
                                color="inherit"
                                onClick={() =>
                                  this.handleViewAlertClick(
                                    n.id,
                                    n.event_timestamp
                                  )
                                }
                              >
                                <ActionIcon />

                                {/* {this.state.addaction === true ? <ViewIcon /> : "dd"} */}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 35 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )} */}
                    </TableBody>
                  </Table>
                ) : this.props.error == true ? (
                  <>
                    <div className={classes.notAddedStyle}>
                      {messageConstants.NOT_ADDED}
                    </div>

                    <div style={{ height: "24px" }}></div>
                  </>
                ) : (
                  <>
                    <SkeltonScreen />
                    <div style={{ height: "24px" }}></div>
                  </>
                )}
              </div>
              {data && data.length > 0 ? (
                <Grid container xs={12}>
                  <Grid item xs={4}>
                    <Typography className={classes.paginationText}>
                      {parseInt(this.props.page) * this.props.rowsPerPage +
                        1 +
                        " - " +
                        (parseInt(this.props.page) * this.props.rowsPerPage +
                          10 >
                        this.props.historyCount
                          ? this.props.historyCount
                          : parseInt(this.props.page) * this.props.rowsPerPage +
                            10) +
                        " of " +
                        +this.props.historyCount +
                        " Records"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{ float: "right", paddingRight: "43px" }}
                    xs={8}
                  >
                    <Pagination
                      count={Math.ceil(this.props.historyCount / rowsPerPage)}
                      shape="rounded"
                      page={this.props.page + 1}
                      onChange={this.handleChangePage}
                      color="secondary"
                      className="pagination"
                    />
                    <div style={{ height: "24px" }}></div>
                  </Grid>{" "}
                </Grid>
              ) : null}
            </>
          )}
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            count={this.props.historyCount}
            rowsPerPage={this.props.rowsPerPage}
            page={this.props.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActionsWrapped}
          /> */}
          <Filter
            toggleHistoryFilterWindow={this.toggleFilterWindow}
            showHistoryFilterWindow={this.state.showHistoryFilterWindow}
            searchMethod={this.props.fetchHistoryList}
            page={0}
            rowsPerPage={this.props.rowsPerPage}
            //page={this.props.page}
            //rowsPerPage={this.state.rowsPerPage}
          />
        </Paper>
      </Grid>
    );
  }
}

HistoryList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HistoryList);
