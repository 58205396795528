import {
  GET_WARNING_DATA,
  GET_MULTIPLE_EWAYBILL,
  GET_FLAMMABLE_GOODS,
  GET_CAMERA_LOCATION,
  GET_OVERVIEW_DATA,
  GET_MESSAGE_DATA,
} from "./constants";
const initialState = {
  warningCount: {},
  multipleEwayBillCount: {},
  flammableGoodsCount: {},
  camera_location: "",
  overviewData: {},
  messageData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OVERVIEW_DATA:
      return {
        ...state,
        overviewData: action.data,
      };
    case GET_MESSAGE_DATA:
      return {
        ...state,
        messageData: action.data,
      };
    case GET_CAMERA_LOCATION:
      return {
        ...state,
        camera_location: "",
      };
    case GET_WARNING_DATA:
      return {
        ...state,
        warningCount: action.data,
      };
    case GET_MULTIPLE_EWAYBILL:
      return {
        ...state,
        multipleEwayBillCount: action.data,
      };
    case GET_FLAMMABLE_GOODS:
      return {
        ...state,
        flammableGoodsCount: action.data,
      };

    default:
      return state;
  }
}
