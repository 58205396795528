import API from "./API";

export default async (options) => {
  try {
    return await API(options);
  } catch (e) {
    // console.log("Exception", e);
    return e;
  }
};
