import { push } from "connected-react-router";
import { withRouter } from "react-router";
import HabitualOffendersDetails from "./habitualOffendersDetails";
import { connect } from "react-redux";
import {
  addHabitualOffender,
  fetchHabitualOffender,
  editHabitualOffender,
  resetHabitualOffenderDetail
} from "./data/action";
import { snackbarActions } from "components/snackbar/data/action";

function mapStateToProps(state) {
  return {
    saveSuccess: state.habitualOffendersDetails.saveSuccess,
    message: state.habitualOffendersDetails.message,
    error: state.habitualOffendersDetails.error,
    habitualOffenders: state.habitualOffendersDetails.habitualOffenders,
    fetchedHabitualOffender:
      state.habitualOffendersDetails.fetchedHabitualOffender
  };
}

function mapDispatchToProps(dispach) {
  return {
    navigateTo: url => dispach(push(url)),
    addHabitualOffender: data => dispach(addHabitualOffender(data)),
    fetchHabitualOffender: id => dispach(fetchHabitualOffender(id)),
    editHabitualOffender: (data, id) => dispach(editHabitualOffender(data, id)),
    resetHabitualOffenderDetail: () => dispach(resetHabitualOffenderDetail()),
    showSnackbar: (message, type) =>
      dispach(snackbarActions.showSnackbar(message, type))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HabitualOffendersDetails)
);
