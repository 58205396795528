import React from "react";
import { Route, Switch } from "react-router";
import Layout from "screens/layout/";
import Camera from "screens/camera/";
import Login from "screens/login/";
import AlertsList from "screens/alerts/";
import HistoryList from "screens/history/";
import AlertView from "screens/alerts/components/alert_detailed_view/";
import AlertList from "screens/alerts/";
import HistoryView from "screens/history/components/history_detailed_view/";
import Dashboard from "../screens/dashboard/Dashboard";
import WarningList from "../screens/review";
import WarningView from "../screens/review/components/warnings_detailed_view/";
import NoEwayBillWarningList from "../screens/warning/";
import NoEwayBillWarningView from "../screens/warning/components/warnings_details/";
import Reports from "../screens/reports/";
import UserInfo from "../screens/userInfo";
import LandingPage from "../screens/dashboard/landingpage/";
// import CameraSelector from "../screens/locationSelector";
import SummaryReports from "../screens/summaryReport/";
import ActionSummaryReport from "../screens/actionsummaryReport/index";

// import CameraList from "../screens/cameraMaster/";
// import CameraDetails from "../screens/cameraMaster/components/camera_details/";
// import LocationList from "../screens/location/";
// import LocationDetails from "../screens/location/components/location_details/";
// import LaneList from "../screens/lane/";
// import LaneDetails from "../screens/lane/components/lane_details/";

import DutyUserLIst from "../screens/dutyMaster/index";
import AssignDuty from "../screens/dutyMaster/components/assignDuty";
import UpdateDuty from "../screens/dutyMaster/components/updateDuty";

import SquadMasterLIst from "../screens/squadMaster/";
import AssignSquad from "../screens/squadMaster/components/squadDetails/index";

import habitualOffendersList from "../screens/habitualOffenders/";
import HabitualOffendersDetails from "../screens/habitualOffenders/components/habitualOffenders_details/";
import FlammableGoodsList from "../screens/flammableGoods/";
import flammableGoodsDetails from "../screens/flammableGoods/components/flammableGoods_details/";

import UserList from "../screens/userMaster/";
import UserDetails from "../screens/userMaster/components/userDetails/";
import OfficeList from "../screens/officeMaster/";
import OfficeDetails from "../screens/officeMaster/components/officeDetails/index";

import RoleMaster from "../screens/roleMaster/";
import RoleMasterEdit from "../screens/roleMaster/components/editRole/index";
import RoleMasterAdd from "../screens/roleMaster/components/addRole/";
import RoleMasterView from "../screens/roleMaster/components/viewRole/components";

import DesignationMasterList from "../screens/designationMaster";
import DesignationMasterDetails from "../screens/designationMaster/components/designationMaster_details";

import CameraList from "../screens/camera/cameraSelector/index";
const Routes = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/login" component={Login} />
    <Layout>
      <Switch>
        <Route exact path="/dashboard" component={Dashboard} />
      </Switch>
      <Switch>
        <Route exact path="/landingPage" component={LandingPage} />
      </Switch>
      <Switch>
        <Route exact path="/camera" component={Camera} />
      </Switch>
      <Switch>
        <Route exact path="/selectCamera" component={CameraList} />
      </Switch>
      <Switch>
        <Route exact path="/alerts/" component={AlertsList} />
        <Route exact path="/alerts/:record_id" component={AlertView} />
        <Route
          exact
          path="/alerts/lists/:record_number"
          component={AlertList}
        />
      </Switch>
      <Switch>
        <Route exact path="/history" component={HistoryList} />
        <Route exact path="/history/:record_id" component={HistoryView} />
      </Switch>
      <Switch>
        <Route exact path="/review" component={WarningList} />
        <Route exact path="/review/:record_id" component={WarningView} />
      </Switch>
       <Switch>
        <Route exact path="/warnings" component={NoEwayBillWarningList} />
        <Route exact path="/warnings/:record_id" component={NoEwayBillWarningView} />
      </Switch>
      <Switch>
        <Route path={"/reports/:report_type"} component={Reports} />
      </Switch>
      <Switch>
        <Route path="/summaryreport" component={SummaryReports} />
      </Switch>
      <Switch>
        <Route path="/actionsummaryreport" component={ActionSummaryReport} />
      </Switch>
      <Switch>
        <Route exact path="/duty-master" component={DutyUserLIst} />
        <Route exact path="/dutymaster/assign" component={AssignDuty} />
        <Route
          exact
          path="/dutymaster/update/:duty_id"
          component={UpdateDuty}
        />
        <Route exact path="/squad-master" component={SquadMasterLIst} />
        <Route exact path="/squad-master/add" component={AssignSquad} />
        <Route
          exact
          path="/squad-master/edit/:squad_id"
          component={AssignSquad}
        />
        <Route
          exact
          path="/habitualOffender-master"
          component={habitualOffendersList}
        />
        <Route
          exact
          path="/habitualOffender-master/add"
          component={HabitualOffendersDetails}
        />
        <Route
          exact
          path="/habitualOffender-master/edit/:offender_id"
          component={HabitualOffendersDetails}
        />
        <Route exact path="/role-master" component={RoleMaster} />
        <Route exact path="/role-master/add" component={RoleMasterAdd} />
        <Route
          exact
          path="/role-master/edit/:role_id"
          component={RoleMasterEdit}
        />
        <Route
          exact
          path="/role-master/view/:role_id"
          component={RoleMasterView}
        />

        <Route
          exact
          path="/designation-master"
          component={DesignationMasterList}
        />
        <Route
          exact
          path="/designation-master/add"
          component={DesignationMasterDetails}
        />
        <Route
          exact
          path="/designation-master/add/:designation_id"
          component={DesignationMasterDetails}
        />
      </Switch>
      <Switch>
        <Route exact path="/master/user-master" component={UserList} />
        <Route
          exact
          path="/master/user-master/add/:stepID"
          component={UserDetails}
        />
        <Route
          exact
          path="/master/user-master/edit/:user_id/:stepID"
          component={UserDetails}
        />
      </Switch>
      <Switch>
        <Route exact path="/master/office-master" component={OfficeList} />
        <Route
          exact
          path="/master/office-master/add"
          component={OfficeDetails}
        />
        <Route
          exact
          path="/master/office-master/edit/:office_id/"
          component={OfficeDetails}
        />
      </Switch>
      <Switch>
        <Route path={"/user"} component={UserInfo} />
      </Switch>
      <Switch>
        {/* <Route path={"/chooseCamera"} component={CameraSelector} /> */}
        <Route path={"/chooseCamera"} component={CameraList} />
      </Switch>
      <Switch>
        <Route
          exact
          path={"/flammableGoods-master"}
          component={FlammableGoodsList}
        />
        <Route
          exact
          path={"/flammableGoods-master/add"}
          component={flammableGoodsDetails}
        />
        <Route
          exact
          path={"/flammableGoods-master/edit/:goods_id"}
          component={flammableGoodsDetails}
        />
      </Switch>
      {/* <Switch>
        <Route exact path="/camera-master" component={CameraList} />
        <Route exact path="/camera-master/add" component={CameraDetails} />
        <Route
          exact
          path="/camera-master/:camera_id"
          component={CameraDetails}
        />
      </Switch>
      <Switch>
        <Route exact path="/location" component={LocationList} />
        <Route exact path="/location/add" component={LocationDetails} />
        <Route
          exact
          path="/location/:location_id"
          component={LocationDetails}
        />
      </Switch>
      <Switch>
        <Route exact path="/lane" component={LaneList} />
        <Route exact path="/lane/add" component={LaneDetails} />
        <Route exact path="/lane/:lane_id" component={LaneDetails} />
      </Switch> */}
    </Layout>
  </Switch>
);

export default Routes;
