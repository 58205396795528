import React from "react";
import { Typography, withStyles, Grid } from "@material-ui/core";
import packageJson from "../../../package.json";
import UltsLogo from "../../screens/login/images/ULTSLOGO.png";

const styles = (theme) => ({
  footerdiv: {
    // marginTop: "10px",
    paddingTop: "22px",
    paddingRight: "18px",
    justifyContent: "center",
    display: "flex",
    bottom: 0,
    position: "absolute",
    left: "50%",
  },
  footerText: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#A7B1C7",
    opacity: 1,
  },
  poweredByLogoStyle: {
    height: "22px",
    width: "34px",
    paddingLeft: "2px",
    marginLeft: "2px",
    marginTop: "0px",
    cursor: "pointer",
  },
});
const AppFooter = (props) => {
  const { classes } = props;
  const onPoweredByClick = () => {
    let win = window.open("http://ults.in", "_blank");
    win.focus();
  };
  return (
    <div className={classes.footerdiv}>
      <Typography variant="body1" gutterBottom className={classes.footerText}>
        {/* Copyright &copy; ULTS 2018-Present version {packageJson.version} */}
        Powered By{" "}
        <span>
          <img
            href="http://www.ults.in/"
            onClick={onPoweredByClick}
            src={UltsLogo}
            className={classes.poweredByLogoStyle}
            alt="ULTS Logo"
          />
        </span>
      </Typography>
    </div>
  );
};

export default withStyles(styles)(AppFooter);
