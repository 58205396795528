import { connect } from "react-redux";
import { withRouter } from "react-router";
import squadDetails from "./squadDetails";

import { push } from "connected-react-router";

import {
  fetchSquadDetails,
  fetchZone,
  fetchDistrict,
  fetchField,
  addSquad,
  editSquad,
  resetSquadDetail,
  fetchOffice,
  fetchLeader,
  fetchMemberData,
} from "../data/action";
import { snackbarActions } from "components/snackbar/data/action";

function mapStateToProps(state) {
  return {
    saveStaus: state.squadDetails.saveStaus,
    saveSuccess: state.squadDetails.saveSuccess,
    statusmessage: state.squadDetails.statusmessage,
    message: state.squadDetails.message,
    error: state.squadDetails.error,
    field: state.squadDetails.field,
    zone: state.squadDetails.zone,
    district: state.squadDetails.district,
    office: state.squadDetails.office,
    leader: state.squadDetails.leader,
    members: state.squadDetails.members,
    squadDetails: state.squadDetails.fetchSquadDetails,
    memberData: state.squadDetails.memberData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => dispatch(push(url)),
    fetchSquadDeatils: (id) => dispatch(fetchSquadDetails(id)),
    fetchZone: (type) => dispatch(fetchZone(type)),
    fetchMemberData: (id,district_id) => dispatch(fetchMemberData(id, district_id)),
    fetchDistrict: (id) => dispatch(fetchDistrict(id)),
    fetchField: (id) => dispatch(fetchField(id)),
    fetchOffice: (id) => dispatch(fetchOffice(id)),
    fetchLeader: (id) => dispatch(fetchLeader(id)),
    addSquad: (params) => dispatch(addSquad(params)),
    editSquad: (params, id) => dispatch(editSquad(params, id)),
    resetSquadDetail: () => dispatch(resetSquadDetail()),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(squadDetails)
);
