import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppHeader from "components/appbar/";
import AppDrawer from "components/drawer/";
import AppFooter from "components/footer/AppFooter";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 3,
    height: "100vh",
    //overflowY: "auto",
    overflow: "auto",
  },
});

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) this.props.navigateTo("/login");
    if (this.props.showAppDrawer) {
      setTimeout(() => {
        this.props.drawerToggle();
      }, 200);
    }
  }
  componentDidUpdate(prevprops) {
    if (!this.props.isAuthenticated) this.props.navigateTo("/login");
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <AppHeader open={this.state.open} />
          <AppDrawer />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            {this.props.children}
            <AppFooter />
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Layout);
