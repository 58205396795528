import {
  FETCH_HABITUAL_OFFENDER_LIST,
  DELETE_HABITUAL_OFFENDER,
  RESET_DELETE,
  FETCH_HABITUAL_OFFENDER_LIST_ERROR,
  DELETE_HABITUAL_OFFENDER_ERROR,
} from "./constants";
import HabitualOffendersService from "../../../service/HabitualOffenderService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchHabitualOffendersList = (page, limit, filterParams) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new HabitualOffendersService()
      .fetchHabitualOffendersList(page, limit, filterParams)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };
  function success(data) {
    return { type: FETCH_HABITUAL_OFFENDER_LIST, data };
  }
  function failure(error) {
    return { type: FETCH_HABITUAL_OFFENDER_LIST_ERROR, error };
  }
};

export const deleteHabitualOffender = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new HabitualOffendersService()
      .deleteHabitualOffender(id)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: DELETE_HABITUAL_OFFENDER, data };
  }
  function failure(error) {
    return { type: DELETE_HABITUAL_OFFENDER_ERROR, error };
  }
};

export function resetDelete() {
  return {
    type: RESET_DELETE,
  };
}
