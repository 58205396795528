export const FETCH_FIELD = "FETCH_FIELD";
export const FETCH_FIELD_FAILED = "FETCH_FIELD_FAILED";

export const FETCH_ZONE = "FETCH_ZONE";
export const FETCH_ZONE_FAILED = "FETCH_ZONE_FAILED";

export const FETCH_MEMBER_DATA = "FETCH_MEMBER_DATA";
export const FETCH_MEMBER_DATA_FAILED = "FETCH_MEMBER_DATA_FAILED";

export const FETCH_DISTRICT = "FETCH_DISTRICT";
export const FETCH_DISTRICT_FAILED = "FETCH_DISTRICT_FAILED";

export const FETCH_OFFICE = "FETCH_OFFICE";
export const FETCH_OFFICE_FAILED = "FETCH_OFFICE_FAILED";

export const FETCH_LEADER = "FETCH_LEADER";
export const FETCH_LEADER_FAILED = "FETCH_LEADER_FAILED";

export const FETCH_ROLE = "FETCH_ROLE";
export const FETCH_ROLE_FAILED = "FETCH_ROLE_FAILED";

export const ADD_EDIT_SQUAD_MASTERS = "ADD_EDIT_SQUAD_MASTERS";
export const ADD_EDIT_SQUAD_MASTERS_FAILED = "ADD_EDIT_SQUAD_MASTERS_FAILED";

export const RESET_SQUAD_DETAIL = "RESET_SQUAD_DETAIL";

export const FETCH_SQUAD_DETAILS = "FETCH_SQUAD_DETAILS";
export const FETCH_SQUAD_DETAILS_FAILED = "FETCH_SQUAD_DETAILS_FAILED";

export const FILTER_CHANGE = "FILTER_CHANGE";
export const FILTER_CHANGE_ERROR = "FILTER_CHANGE_ERROR";

// export const FETCH_ZONE_SUCCESS = "FETCH_ZONE_SUCCESS";
// export const FETCH_ZONE_FAILED = "FETCH_ZONE_FAILED";

// export const FETCH_DISTRICT_SUCCESS = "FETCH_DISTRICT_SUCCESS";
// export const FETCH_DISTRICT_FAILED = "FETCH_DISTRICT_FAILED";

// export const FETCH_FIELD_SUCCESS = "FETCH_FIELD_SUCCESS";
// export const FETCH_FIELD_FAILED = "FETCH_FIELD_FAILED";

// export const FETCH_SQUAD_SUCCESS = "FETCH_SQUARD_SUCCESS";
// export const FETCH_SQUAD_FAILED = "FETCH_SQUARD_FAILED";

// export const FETCH_SQUAD_DETAILS_SUCCESS = "FETCH_SQUAD_DETAILS_SUCCESS";
// export const FETCH_SQUAD_DETAILS_FAILED = "FETCH_SQUAD_DETAILS_FAILED";
// // export const FETCH_ZONE_SUCCESS = "FETCH_ZONE_SUCCESS";
// // export const FETCH_ZONE_FAILED = "FETCH_ZONE_FAILED";

// export const RESET_DUTIES = "RESET_DUTIES";
