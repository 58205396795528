import { push } from "connected-react-router";
import AlertView from "./AlertView";
import { connect } from "react-redux";
import { setPlateNumber } from "../../../camera/components/recent_activity/data/action";
import { fetchRecord, fetchAlertNextandPrev } from "./data/action";

function mapStateToProps(state) {
  return {
    fetchedRecord: state.alertView.fetchedRecord,
    loading: state.progress.loading,
    filterParams: state.filteralert.filterParams,
    event_timestamp: state.historyList.event_timestamp,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => dispatch(push(url)),
    setPlateNumber: (plate) => dispatch(setPlateNumber(plate)),
    fetchRecord: (id, event_timestamp) =>
      dispatch(fetchRecord(id, event_timestamp)),
    fetchAlertNextandPrev: (data) => dispatch(fetchAlertNextandPrev(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertView);
