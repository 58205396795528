import { SHOW_PROGRESS, HIDE_PROGRESS } from "./constants";

export const progressActions = {
  showProgress,
  hideProgress
};

function showProgress() {
  return { type: SHOW_PROGRESS };
}

function hideProgress() {
  return { type: HIDE_PROGRESS };
}
