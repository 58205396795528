import {
  FETCH_FIELD,
  FETCH_FIELD_FAILED,
  FETCH_ZONE,
  FETCH_ZONE_FAILED,
  FETCH_DISTRICT,
  FETCH_DISTRICT_FAILED,
  ADD_EDIT_OFFICE_MASTERS,
  ADD_EDIT_OFFICE_MASTERS_FAILED,
  RESET_OFFICE_MASTER_STATE,
  FETCH_OFFICE_DETAILS,
  FETCH_OFFICE_DETAILS_FAILED,
} from "./constants";
import OfficeMasterService from "../../../../../service/OfficeMasterService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchField = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new OfficeMasterService().fetchField(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_FIELD, data };
  }

  function failure(error) {
    return { type: FETCH_FIELD_FAILED, error };
  }
};
export const fetchZone = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new OfficeMasterService().fetchZone().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_ZONE, data };
  }

  function failure(error) {
    return { type: FETCH_ZONE_FAILED, error };
  }
};
export const fetchDistrict = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new OfficeMasterService().fetchDistrict(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_DISTRICT, data };
  }

  function failure(error) {
    return { type: FETCH_DISTRICT_FAILED, error };
  }
};

export const addOffice = (params) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new OfficeMasterService().addOffice(params).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
    function success(data) {
      return { type: ADD_EDIT_OFFICE_MASTERS, data };
    }
    function failure(error) {
      return { type: ADD_EDIT_OFFICE_MASTERS_FAILED, error };
    }
  };
};

export const fetchOfficeDeatils = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new OfficeMasterService().fetchOfficeDeatils(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_OFFICE_DETAILS, data };
  }
  function failure(error) {
    return { type: FETCH_OFFICE_DETAILS_FAILED, error };
  }
};

export const editOffice = (params, id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new OfficeMasterService().editOffice(params, id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: ADD_EDIT_OFFICE_MASTERS, data };
  }
  function failure(error) {
    return { type: ADD_EDIT_OFFICE_MASTERS_FAILED, error };
  }
};

export function resetOfficeDetail() {
  return {
    type: RESET_OFFICE_MASTER_STATE,
  };
}
