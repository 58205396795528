import {
  ADD_EDIT_LOCATION,
  ADD_EDIT_LOCATION_FAILED,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAILED,
  RESET_LOCATION_STATE
} from "./constants";

const initialState = {
  fetchedLocation: {},
  locationData: "",
  error: "",
  saveSuccess: false,
  message: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_LOCATION_SUCCESS:
      return {
        ...initialState,
        fetchedLocation: action.data.data
      };

    case FETCH_LOCATION_FAILED:
      return {
        ...initialState
      };

    case ADD_EDIT_LOCATION:
      return {
        ...state,
        locationData: action.data,
        saveSuccess: true,
        message: action.data.message,
        error:
          action.response !== undefined ? action.response.data.error[0] : ""
      };

    case ADD_EDIT_LOCATION_FAILED:
      return {
        ...initialState,
        error:
          action.response !== undefined ? action.response.data.error[0] : ""
      };
    case RESET_LOCATION_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
