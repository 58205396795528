import React, { createRef } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  withStyles,
  Grid,
  FormHelperText,
  Drawer,
  Button,
  RadioGroup,
  Divider,
  Typography,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
// import { DatePicker } from "material-ui-pickers";
import validator from "../../services/validator";
import { find } from "lodash";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
//import DateTimePicker from "react-datetime-picker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import dropdownTypes from "../../util/dropdown/dropdownTypes";
import StorageHandler from "../../util/storage/index";
import "./style.css";
import StyledRadio from "../filteralert_form/styledRadio";
import { ReactComponent as ExpandLess } from "../../assets/Visual Assets/arrow_up.svg";
import { ReactComponent as ExpandMore } from "../../assets/Visual Assets/dropdown.svg";
import { ReactComponent as DateTime } from "../../assets/Visual Assets/date.svg";
import { ReactComponent as DownArrow } from "../../assets/SvgIcons/dropdown.svg";
const drawerWidth = 328;
// const PrettoSlider = withStyles({
//   root: {
//     color: "#1BB99A",
//     height: 7,
//     opacity: 1,
//   },
//   thumb: {
//     height: 24,
//     width: 24,
//     backgroundColor: "#fff",
//     border: "2px solid #1BB99A",
//     marginTop: -8,
//     marginLeft: -12,
//     "&:focus, &:hover, &$active": {
//       boxShadow: "inherit",
//     },
//   },
//   active: {},
//   valueLabel: {
//     left: "calc(-50% + 4px)",
//   },
//   track: {
//     height: 7,
//     borderRadius: 4,
//   },
//   rail: {
//     height: 7,
//     borderRadius: 4,
//   },
// })(Slider);
const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 400,
    height: 300,
  },
  // vldclr: {
  //   color: "#FF0000",
  // },
  button: {
    font: "normal normal normal 14px Karla",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    color: theme.colors.whiteColor,
    width: "121px",
    height: "42px",
    letterSpacing: "0px",
    opacity: 1,
    textTransform: "none",
    marginLeft: "-10px",
  },
  clearButton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.BlueButton,
    color: theme.colors.whiteColor,
  },
  closeButton: {
    width: "121px",
    height: "42px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
    opacity: 1,
    color: "#797C82",
    font: "normal normal normal 14px/30px Karla",
    letterSpacing: "0px",
    textTransform: "none",
    marginLeft: "-14px",
  },
  dialogActions: {
    background: "#F9F9FE 0% 0% no-repeat padding-box",
    opacity: 1,
  },
  formHelperText: {
    color: theme.colors.redColor,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  filterTitle: {
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginLeft: "-4px",
  },
  filterClearAll: {
    font: "normal normal normal 16px Karla",
    color: "#1BB99A",
    textAlign: "left",
    letterSpacing: "0px",
    opacity: 1,
    textTransform: "none",
    "&:hover": { background: "none" },
  },
  divider: {
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  textField: {
    // width: "100%",
    width: "291px",
    textAlign: "left",
    font: "normal normal normal 16px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    height: "45px",
    marginLeft: "-2px",
  },
  popIcon: {
    marginTop: "6px",
    marginRight: "6px",
  },
  popIconOpen: {
    marginTop: "4px",
    marginRight: "6px",
  },
  textNoOptions: {
    letterSpacing: "0px",
    opacity: 1,
    fontSize: "18px",
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  filterSubHeading: {
    font: "normal normal bold 16px Karla",
    color: "#2B3D51",
    textAlign: "left",
    letterSpacing: "0px",
    opacity: 1,
  },
  filterSubHeadingOptions: {
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
  },
  dateIcon: {
    marginTop: "0px",
    marginRight: "-8px",
  },
  drawerAction: {
    height: "74px",
    backgroundColor: "#F9F9FE",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  gridStyle: {
    marginBottom: "-3px",
  },
  gridStyle1: {
    marginTop: "1px",
  },
  gridStyle2: {
    // marginBottom: "66px",
  },
});

const offenders = [
  {
    value: "isHabitualOffender",
    label: "Habitual Offender",
  },
  {
    value: "isMultipleEwayBill",
    label: "Multiple truck with same E-way bill",
  },
];

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.confidence = createRef();
    this.state = {
      // data: {
      plate: "",
      camera: "",
      //selectedDate: null,
      selectedFromDate: null,
      selectedToDate: null,
      lane: "",
      confidence: 0,
      vehicle_category: "",
      offender: "",
      zone: "",
      district: "",
      field: "",
      // },
      type: "lane",
      lane_id: false,
      camera_id: false,
      vehicle_id: false,
      types: "vehicleCategory",
      typ: "camera",
      zoneType: "zone",
      districtType: "district",
      fieldType: "field",
      alert_type: "",
      alert_status: "",
      commodity_category: "",
      Alert: [],
      Camera: [],
      Warning: [
        { key: "Review", value: "warning" },
        { key: "Reduced review", value: "reduced_warning" },
      ],
      Commodity: [],
      Field: [],
      Zone: [],
      District: [],
      alertVisible: false,
      alert: "",
      searchParams: {},
      filterParams: {},
      val: false,
      extraFilterStatus: false,
      errors: {
        confidence: "",
        selectedToDate: "",
      },
      rules: {
        confidence: {
          number: true,
        },
      },
      // assignedDistrict: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.district_master
      //   : "",
      // assignedField: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.location_master
      //   : "",
      // assignedZone: new StorageHandler().getUser()
      //   ? new StorageHandler().getUser().basicDetails.zone_master
      //   : "",
    };
  }

  componentDidMount() {
    this.props.fetchLane(this.state.type);
    //this.props.fetchCamera(this.state.typ);
    this.props.fetchVehicle(this.state.types);
    this.props.fetchAlert();
    //this.props.fetchWarning();
    this.props.getDropdownData(dropdownTypes.COMMODITY_CATEGORY);
    this.props.fetchZone(this.state.zoneType);
    // if (
    //   new StorageHandler().getUser() &&
    //   new StorageHandler().getUser().basicDetails.zone_master &&
    //   new StorageHandler().getUser().basicDetails.zone_master != null
    // ) {
    //   this.props.fetchDistrict(this.state.assignedZone.zone_id);
    // }

    // if (
    //   new StorageHandler().getUser() &&
    //   new StorageHandler().getUser().basicDetails.district_master &&
    //   new StorageHandler().getUser().basicDetails.district_master != null
    // ) {
    //   this.props.fetchField(this.state.assignedDistrict.district_id);
    // }
    // if (
    //   this.state.assignedZone &&
    //   this.state.assignedZone != null &&
    //   this.state.assignedZone != "" &&
    //   this.state.assignedDistrict &&
    //   this.state.assignedDistrict != null &&
    //   this.state.assignedDistrict != "" &&
    //   this.state.assignedField === null
    // ) {
    //   this.setState({
    //     filterParams: { district: this.state.assignedDistrict.district_id },
    //   });
    // } else if (
    //   this.state.assignedZone &&
    //   this.state.assignedZone != null &&
    //   this.state.assignedZone != "" &&
    //   this.state.assignedDistrict &&
    //   this.state.assignedDistrict != null &&
    //   this.state.assignedDistrict != "" &&
    //   this.state.assignedField &&
    //   this.state.assignedField != null &&
    //   this.state.assignedField != ""
    // ) {
    //   this.setState({
    //     filterParams: { field: this.state.assignedField.location_id },
    //   });
    // } else if (
    //   this.state.assignedZone &&
    //   this.state.assignedZone != null &&
    //   this.state.assignedZone != "" &&
    //   this.state.assignedDistrict === null &&
    //   this.state.assignedField === null
    // ) {
    //   this.setState({
    //     filterParams: { zone: this.state.assignedZone.zone_id },
    //   });
    // } else {
    //   this.setState({
    //     filterParams: {},
    //   });
    // }
    // this.props.setValue(true);

    let condition = localStorage.getItem("history");
    if (!this.props.val && condition === "false") {
      this.props.resetFilterParams();
      this.props.resetPage();
      this.props.resetFromDate();
      this.props.resetToDate();
      this.props.resetPlateNo();
      this.props.resetAlertType();
      this.props.resetZone();
      this.props.resetDistrict();
      this.props.resetField();
      this.props.resetCamera();
      this.props.resetCommodity();
      this.props.resetOffender();
      this.props.resetVehicle();
      this.props.resetLane();
      this.props.resetWarningType();
      this.props.fetchStateCamera();
    } else {
      this.setState({
        ...this.state,
        selectedFromDate: this.props.selectedFromDate,
        selectedToDate: this.props.selectedToDate,
        plate: this.props.plate,
        alert_type: this.props.alert_type,
        assignedZone: this.props.assignedZone,
        assignedDistrict: this.props.assignedDistrict,
        assignedField: this.props.assignedField,
        camera: this.props.camera,
        commodity_category: this.props.commodity_category,
        offender: this.props.offender,
        vehicle_category: this.props.vehicle_category,
        lane: this.props.lane,
        alert_status: this.props.alert_status,
        filterParams: this.props.filterParams,
      });
      if (
        this.props.assignedZone &&
        this.props.assignedZone != null &&
        this.props.assignedZone != "" &&
        JSON.stringify(this.props.assignedDistrict) === "{}" &&
        JSON.stringify(this.props.assignedField) === "{}" &&
        JSON.stringify(this.props.assignedZone) != "{}"
      ) {
        this.props.fetchDistrict(
          this.state.districtType,
          this.props.assignedZone.zone_id
        );

        this.props.fetchZoneCamera(this.props.assignedZone.zone_id);
        this.setState({
          Field: [],
        });
      } else if (
        this.props.assignedZone &&
        this.props.assignedZone != null &&
        JSON.stringify(this.props.assignedZone) != "{}" &&
        this.props.assignedDistrict &&
        this.props.assignedDistrict != null &&
        JSON.stringify(this.props.assignedDistrict) != "{}" &&
        JSON.stringify(this.props.assignedField) === "{}"
      ) {
        this.props.fetchDistrict(
          this.state.districtType,
          this.props.assignedZone.zone_id
        );

        this.props.fetchField(
          this.state.fieldType,
          this.props.assignedDistrict.district_id
        );

        this.props.fetchDistrictCamera(this.props.assignedDistrict.district_id);
      } else if (
        this.props.assignedZone &&
        this.props.assignedZone != null &&
        JSON.stringify(this.props.assignedZone) != "{}" &&
        this.props.assignedDistrict &&
        this.props.assignedDistrict != null &&
        JSON.stringify(this.props.assignedDistrict) != "{}" &&
        this.props.assignedField &&
        this.props.assignedField != null &&
        JSON.stringify(this.props.assignedField) != "{}"
      ) {
        this.props.fetchDistrict(
          this.state.districtType,
          this.props.assignedZone.zone_id
        );
        this.props.fetchField(
          this.state.fieldType,
          this.props.assignedDistrict.district_id
        );
        this.props.fetchFieldCamera(this.props.assignedField.location_id);
      } else {
        this.props.fetchStateCamera();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.fetchLanes != this.props.fetchLanes) {
      this.setState({
        data: this.props.fetchLanes,
      });
    }
    if (prevProps.fetchCameras != this.props.fetchCameras) {
      this.setState({
        ...this.state,
        // data: this.props.fetchCameras,
        Camera: this.props.fetchCameras,
      });
    }
    if (prevProps.fetchVehicles != this.props.fetchVehicles) {
      this.setState({
        data: this.props.fetchVehicles,
      });
    }
    if (prevProps.fetchedAlert != this.props.fetchedAlert) {
      this.setState({
        ...this.state,
        Alert: this.props.fetchedAlert,
      });
    }
    // if (prevProps.fetchedWarning != this.props.fetchedWarning) {
    //   this.setState({
    //     ...this.state,
    //     Warning: this.props.fetchedWarning,
    //   });
    // }
    if (prevProps.commodityCategories != this.props.commodityCategories) {
      this.setState({
        ...this.state,
        Commodity: this.props.commodityCategories,
      });
    }
    if (prevProps.fetchedzone != this.props.fetchedzone) {
      this.setState({
        ...this.state,
        Zone: this.props.fetchedzone,
      });
    }
    if (prevProps.district != this.props.district) {
      this.setState({
        ...this.state,
        District: this.props.district,
      });
    }
    if (prevProps.field != this.props.field) {
      this.setState({
        ...this.state,
        Field: this.props.field,
      });
    }
  }
  handleFromDateChange = (date) => {
    this.props.setFromDate(date);
    this.setState({
      ...this.state,
      // selectedFromDate: date,
      searchParams: {
        ...this.state.searchParams,
        from_date: new Date(date).getTime(),
      },
    });
  };
  handleToDateChange = (date) => {
    this.props.setToDate(date);
    this.setState({
      ...this.state,
      // selectedToDate: date,
      searchParams: {
        ...this.state.searchParams,
        to_date: new Date(date).getTime(),
      },
      errors: {
        ...this.state.errors,
        selectedToDate: "",
      },
    });
  };
  handleChangeAlertType = (event, value) => {
    this.props.setAlertType(value);
    this.setState({
      // alert_type: value,
      searchParams: {
        ...this.state.searchParams,
        alert_type: value.value,
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };
  handleChangeWarningType = (event, value) => {
    this.props.setWarningType(value);
    this.setState({
      // alert_status: value,
      searchParams: {
        ...this.state.searchParams,
        alert_status: value,
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };
  handleChangeCommodity = (event, value) => {
    this.props.setCommodity(value);
    this.setState({
      // commodity_category: value,
      searchParams: {
        ...this.state.searchParams,
        commodity_category: value.nt_category,
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };
  handleChangeZone = (event, value) => {
    this.props.setZone(value);
    this.props.resetDistrict();
    this.props.resetField();
    this.props.resetCamera();
    this.setState(
      {
        ...this.state,
        // assignedZone: value,
        assignedDistrict: {},
        assignedField: {},
        zone: value,
        camera: "",
        // searchParams: {
        //   //...this.state.searchParams,
        //   zone: value.zone_id,
        // },
        filterParams: {
          zone: value.zone_id,
        },
      },
      () => {
        this.props.fetchDistrict(
          this.state.districtType,
          this.props.assignedZone.zone_id
        );
        this.props.fetchZoneCamera(this.props.assignedZone.zone_id);
        this.setState({
          Field: [],
        });
      }
    );
  };
  handleChangeDistrict = (event, value) => {
    this.props.setDistrict(value);
    this.props.resetField();
    this.props.resetCamera();
    this.setState(
      {
        ...this.state,
        // assignedDistrict: value,
        assignedField: {},
        camera: "",
        district: value,
        // searchParams: {
        //   //...this.state.searchParams,
        //   district: value.district_id,
        // },
        filterParams: {
          district: value.district_id,
        },
      },
      () => {
        this.props.fetchField(
          this.state.fieldType,
          this.props.assignedDistrict.district_id
        );
        this.props.fetchDistrictCamera(this.props.assignedDistrict.district_id);
      }
    );
  };
  handleChangeField = (event, value) => {
    this.props.setField(value);
    this.props.resetCamera();
    this.setState(
      {
        ...this.state,
        // assignedField: value,
        field: value,
        camera: "",
        // searchParams: {
        //   //...this.state.searchParams,
        //   field: value.location_id,
        // },
        filterParams: {
          field: value.location_id,
        },
      },
      () => {
        this.props.fetchFieldCamera(value.location_id);
      }
    );
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };

  handleChangePlate = (event) => {
    this.props.setPlateNo(event.target.value);
    this.setState({
      ...this.state,
      // [event.target.name]: event.target.value,
      searchParams: {
        ...this.state.searchParams,
        plate: event.target.value,
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };

  handleChangeConfidence = (event, value) => {
    this.setState({
      ...this.state,
      confidence: value,
      searchParams: {
        ...this.state.searchParams,
        confidence: value.toString(),
      },
      errors: {
        ...this.state.errors,
        confidence: "",
      },
    });
  };
  handleChangeCamera = (event, value) => {
    this.props.setCamera(value);
    this.setState({
      ...this.state,
      // camera: value,
      searchParams: {
        ...this.state.searchParams,
        camera_id: value.camera_id,
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };
  handleChangeVehicle = (event) => {
    this.props.setVehicle(event.target.value);
    this.setState({
      ...this.state,

      // [event.target.name]: event.target.value,
      searchParams: {
        ...this.state.searchParams,
        vehicle_category: event.target.value,
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };

  handleChangeLane = (event) => {
    this.props.setLane(event.target.value);
    this.setState({
      ...this.state,

      // [event.target.name]: event.target.value,
      searchParams: {
        ...this.state.searchParams,
        lane_id: event.target.value,
      },
      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };

  handleChangeOffender = (event) => {
    this.props.setOffender(event.target.value);
    this.setState(
      {
        ...this.state,
        [event.target.name]: event.target.value,
      },
      () => {
        if (this.props.offender === "isHabitualOffender") {
          this.setState({
            ...this.state,
            searchParams: {
              ...this.state.searchParams,
              isHabitualOffender: true,
              isMultipleEwayBill: false,
            },
          });
        } else if (this.props.offender === "isMultipleEwayBill") {
          this.setState({
            ...this.state,
            searchParams: {
              ...this.state.searchParams,
              isMultipleEwayBill: true,
              isHabitualOffender: false,
            },
          });
        } else {
          this.setState({
            ...this.state,
            searchParams: {
              ...this.state.searchParams,
              isMultipleEwayBill: "",
              isHabitualOffender: "",
            },
          });
        }
      }
    );
  };

  handleErrorFocus = (errors) => {
    let order = ["confidence"];
    for (var i = 0; i < order.length; i++) {
      if (errors[order[i]]) {
        this[order[i]].current.focus({
          preventScroll: true,
        });
        window.scrollTo({
          top: this[order[i]].current.getBoundingClientRect().y,
          behaviour: "smooth",
        });
        break;
      }
    }
  };

  validate = () => {
    const { rules } = this.state;
    const errors = validator(rules)(this.state);
    const hasErrors = find(errors, (error) => error !== "");
    this.setState({ errors });
    return !hasErrors;
  };

  setFilterParams = (e) => {
    // let params = {
    //   plate: this.state.plate,
    //   camera_id: this.state.camera,
    //   lane_id: this.state.lane,
    //   isHabitualOffender: this.state.offender === 2,
    //   isMultipleEwayBill: this.state.offender === 3,
    //   from_date:
    //     this.state.selectedFromDate == null
    //       ? null
    //       : new Date(this.state.selectedFromDate.getTime()).setHours(
    //           0,
    //           0,
    //           0,
    //           0
    //         ),
    //   to_date:
    //     this.state.selectedToDate == null
    //       ? null
    //       : new Date(this.state.selectedToDate.getTime()).setHours(0, 0, 0, 0),

    //   confidence: this.state.confidence,
    //   vehicle_category: this.state.vehicle_category,
    // };

    // this.setState({
    //   ...this.state,
    //   val: true,
    // });

    if (this.validate()) {
      if (
        this.validateDate(
          this.props.selectedFromDate,
          this.props.selectedToDate
        )
      ) {
        Object.keys(this.state.filterParams).map((item) => {
          if (
            this.state.filterParams[item] === "" ||
            this.state.filterParams[item] == null ||
            this.state.filterParams[item] == undefined
          ) {
            delete this.state.filterParams[item];
          }
        });

        let searchParams = {
          ...this.state.filterParams,
          ...this.state.searchParams,
        };

        //this.props.setFilterParams(this.state.searchParams);
        this.props.setValue(true);
        this.props.setHistoryFilterParams(searchParams);
        new StorageHandler().setHistoryFilterParams(true);
        this.props.toggleHistoryFilterWindow();
        this.props.searchMethod(
          this.props.page,
          this.props.rowsPerPage,
          // this.state.searchParams
          searchParams
        );
      }
    }
  };
  validateDate = (val1, val2) => {
    if (val2 < val1) {
      this.setState({
        ...this.state,
        errors: {
          ...this.state.errors,
          selectedToDate: "To Date should be greater than From Date",
        },
      });
    } else {
      return true;
    }
  };
  closeWindow = () => {
    this.props.toggleHistoryFilterWindow();
    if (!this.props.val) {
      // if (!this.state.val) {
      this.setState(
        {
          plate: "",
          camera: "",
          selectedFromDate: null,
          selectedToDate: null,
          lane: "",
          confidence: "",
          vehicle_category: "",
          offender: "",
          searchParams: {},
          filterParams: {},
          alert_type: "",
          alert_status: "",
          commodity_category: "",
          zone: "",
          district: "",
          field: "",
          assignedZone: {},
          assignedDistrict: {},
          assignedField: {},
          District: [],
          Field: [],
          extraFilterStatus: false,
          errors: {
            confidence: "",
            selectedToDate: "",
          },
        }
        // () => {
        //   if (new StorageHandler().getUser().basicDetails.zone_master == null) {
        //     this.setState({
        //       assignedZone: {},
        //       District: [],
        //       Field: [],
        //     });
        //   } else {
        //     this.props.fetchDistrict(
        //       new StorageHandler().getUser().basicDetails.zone_master.zone_id
        //     );
        //     this.setState({
        //       Field: [],

        //       filterParams: {
        //         zone: new StorageHandler().getUser().basicDetails.zone_master
        //           .zone_id,
        //       },
        //     });
        //   }
        //   if (
        //     new StorageHandler().getUser().basicDetails.district_master == null
        //   ) {
        //     this.setState({
        //       assignedDistrict: {},
        //       Field: [],
        //     });
        //   } else {
        //     this.props.fetchField(
        //       new StorageHandler().getUser().basicDetails.district_master
        //         .district_id
        //     );
        //     this.setState({
        //       filterParams: {
        //         district: new StorageHandler().getUser().basicDetails
        //           .district_master.district_id,
        //       },
        //     });
        //   }
        //   if (
        //     new StorageHandler().getUser().basicDetails.location_master == null
        //   ) {
        //     this.setState({
        //       assignedField: {},
        //     });
        //   } else {
        //     this.setState({
        //       filterParams: {
        //         field: new StorageHandler().getUser().basicDetails
        //           .location_master.location_id,
        //       },
        //     });
        //   }
        //   if (
        //     new StorageHandler().getUser().basicDetails.location_master ==
        //       null &&
        //     new StorageHandler().getUser().basicDetails.district_master ==
        //       null &&
        //     new StorageHandler().getUser().basicDetails.zone_master == null
        //   ) {
        //     this.setState({ filterParams: {} });
        //   }
        // }
      );
      this.props.fetchStateCamera();
      this.props.resetFilterParams();
      this.props.resetPage();
      this.props.resetFromDate();
      this.props.resetToDate();
      this.props.resetPlateNo();
      this.props.resetAlertType();
      this.props.resetZone();
      this.props.resetDistrict();
      this.props.resetField();
      this.props.resetCamera();
      this.props.resetCommodity();
      this.props.resetOffender();
      this.props.resetVehicle();
      this.props.resetLane();
      this.props.resetWarningType();
      this.props.searchMethod(this.props.page, this.props.rowsPerPage, {});
    }
  };

  resetFilterParams = () => {
    this.props.setValue(false);
    // this.setState({
    //   ...this.state,
    //   val: false,
    // });
    this.setState(
      {
        plate: "",
        camera: "",
        selectedFromDate: null,
        selectedToDate: null,
        lane: "",
        confidence: "",
        vehicle_category: "",
        offender: "",
        searchParams: {},
        filterParams: {},
        alert_type: "",
        alert_status: "",
        commodity_category: "",
        zone: "",
        district: "",
        field: "",
        assignedZone: {},
        assignedDistrict: {},
        assignedField: {},
        District: [],
        Field: [],
        errors: {
          confidence: "",
          selectedToDate: "",
        },
      }
      // () => {
      //   if (new StorageHandler().getUser().basicDetails.zone_master == null) {
      //     this.setState({
      //       assignedZone: {},
      //       District: [],
      //       Field: [],
      //     });
      //   } else {
      //     this.props.fetchDistrict(
      //       new StorageHandler().getUser().basicDetails.zone_master.zone_id
      //     );
      //     this.setState({
      //       Field: [],

      //       filterParams: {
      //         zone: new StorageHandler().getUser().basicDetails.zone_master
      //           .zone_id,
      //       },
      //     });
      //   }
      //   if (
      //     new StorageHandler().getUser().basicDetails.district_master == null
      //   ) {
      //     this.setState({
      //       assignedDistrict: {},
      //       Field: [],
      //     });
      //   } else {
      //     this.props.fetchField(
      //       new StorageHandler().getUser().basicDetails.district_master
      //         .district_id
      //     );
      //     this.setState({
      //       filterParams: {
      //         district: new StorageHandler().getUser().basicDetails
      //           .district_master.district_id,
      //       },
      //     });
      //   }
      //   if (
      //     new StorageHandler().getUser().basicDetails.location_master == null
      //   ) {
      //     this.setState({
      //       assignedField: {},
      //     });
      //   } else {
      //     this.setState({
      //       filterParams: {
      //         field: new StorageHandler().getUser().basicDetails.location_master
      //           .location_id,
      //       },
      //     });
      //   }
      //   if (
      //     new StorageHandler().getUser().basicDetails.location_master == null &&
      //     new StorageHandler().getUser().basicDetails.district_master == null &&
      //     new StorageHandler().getUser().basicDetails.zone_master == null
      //   ) {
      //     this.setState({ filterParams: {} });
      //   }
      // }
    );
    this.props.fetchStateCamera();
    this.props.resetFilterParams();
    this.props.resetFromDate();
    this.props.resetToDate();
    this.props.resetPlateNo();
    this.props.resetAlertType();
    this.props.resetZone();
    this.props.resetDistrict();
    this.props.resetField();
    this.props.resetCamera();
    this.props.resetCommodity();
    this.props.resetOffender();
    this.props.resetVehicle();
    this.props.resetLane();
    this.props.resetWarningType();
    this.props.resetPage();
    this.props.searchMethod(
      this.props.page,
      this.props.rowsPerPage,
      // this.state.filterParams
      {}
    );
  };
  render() {
    var d5f = new Date();
    d5f.setMonth(d5f.getMonth() - 6);
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          //variant="persistent"
          anchor="right"
          classes={{
            paper: classes.drawerPaper,
          }}
          // open={this.props.showFilterWindow}
          open={this.props.showHistoryFilterWindow}
          onClose={this.props.toggleHistoryFilterWindow}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title">
            <Grid container justify={"space-between"} alignItems={"center"}>
              <Grid item xs={6} md={6}>
                <span className={classes.filterTitle}>Filter</span>
              </Grid>
              <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                <Button
                  onClick={this.resetFilterParams}
                  className={classes.filterClearAll}
                >
                  Clear All
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider className={classes.divider} />
          <DialogContent className="DrawerMain">
            <>
              <Grid container>
                <Grid item xs={12} md={12} className={classes.gridStyle}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {" "}
                    <KeyboardDateTimePicker
                      fullWidth
                      margin="dense"
                      label="From Date"
                      placeholder="dd-MM-yyyy hh:mm a"
                      format="dd-MM-yyyy hh:mm a"
                      minDate={d5f}
                      disableFuture
                      inputVariant="outlined"
                      value={this.props.selectedFromDate}
                      onChange={this.handleFromDateChange}
                      KeyboardDateTimePickerProps={{
                        "aria-label": "change date",
                      }}
                      InputLabelProps={{
                        classes: { root: classes.textLabel },
                      }}
                      InputProps={{ classes: { root: classes.textValue } }}
                      keyboardIcon={<DateTime className={classes.dateIcon} />}
                      className={classes.textField}
                      style={{ marginTop: "12px" }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridStyle}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {" "}
                    <KeyboardDateTimePicker
                      fullWidth
                      margin="dense"
                      label="To Date"
                      placeholder="dd-MM-yyyy hh:mm a"
                      minDate={d5f}
                      disableFuture
                      format="dd-MM-yyyy hh:mm a"
                      inputVariant="outlined"
                      value={this.props.selectedToDate}
                      onChange={this.handleToDateChange}
                      className={classes.textField}
                      invalid={this.state.errors.selectedToDate ? true : false}
                      KeyboardDateTimePickerProps={{
                        "aria-label": "change date",
                      }}
                      InputLabelProps={{
                        classes: { root: classes.textLabel },
                      }}
                      InputProps={{ classes: { root: classes.textValue } }}
                      keyboardIcon={<DateTime className={classes.dateIcon} />}
                    />
                  </MuiPickersUtilsProvider>
                  {this.state.errors.selectedToDate ? (
                    <FormHelperText className={classes.formHelperText}>
                      {this.state.errors.selectedToDate}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridStyle}>
                  <TextField
                    label="Plate"
                    name="plate"
                    fullWidth
                    placeholder="eg.KL 12 AX 3242"
                    variant="outlined"
                    margin="dense"
                    onChange={this.handleChangePlate}
                    value={this.props.plate}
                    InputLabelProps={{ classes: { root: classes.textLabel } }}
                    InputProps={{ classes: { root: classes.textValue } }}
                    className={classes.textField}
                  />
                </Grid>

                <Grid item xs={12} md={12} className={classes.gridStyle}>
                  <Autocomplete
                    disableClearable="true"
                    disableOpenOnFocus="true"
                    options={this.state.Alert}
                    value={this.props.alert_type}
                    name="alert_type"
                    getOptionLabel={(option) => (option.key ? option.key : "")}
                    onChange={(e, value) =>
                      this.handleChangeAlertType(e, value)
                    }
                    popupIcon={<DownArrow />}
                    classes={{
                      input: classes.textValue,
                      option: classes.textValue,
                      popupIndicatorOpen: classes.popIconOpen,
                      popupIndicator: classes.popIcon,
                      noOptions: classes.textNoOptions,
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        className={classes.textField}
                        label="Alert Type"
                        name="alert_type"
                        id="outlined-margin-dense"
                        margin="dense"
                        variant="outlined"
                        {...params}
                        InputLabelProps={{
                          classes: { root: classes.textLabel },
                        }}
                      ></TextField>
                    )}
                  />
                  <br></br>
                </Grid>

                <Grid item xs={12} md={12} className={classes.gridStyle1}>
                  {this.state.Zone ? (
                    <Autocomplete
                      disableClearable="true"
                      disableOpenOnFocus="true"
                      // disabled={
                      //   new StorageHandler().getUser() &&
                      //   new StorageHandler().getUser().basicDetails &&
                      //   new StorageHandler().getUser().basicDetails.zone_id
                      //     ? true
                      //     : false
                      // }
                      options={this.state.Zone}
                      value={
                        this.props.assignedZone &&
                        this.props.assignedZone !== null
                          ? this.props.assignedZone
                          : ""
                      }
                      name="zone"
                      getOptionLabel={(option) =>
                        option.zone_name ? option.zone_name : ""
                      }
                      onChange={(event, value) =>
                        this.handleChangeZone(event, value)
                      }
                      popupIcon={<DownArrow />}
                      classes={{
                        input: classes.textValue,
                        option: classes.textValue,
                        popupIndicatorOpen: classes.popIconOpen,
                        popupIndicator: classes.popIcon,
                        noOptions: classes.textNoOptions,
                      }}
                      renderInput={(params) => (
                        <TextField
                          className={classes.textField}
                          fullWidth
                          label="Zone"
                          name="zone"
                          id="outlined-margin-dense"
                          margin="dense"
                          variant="outlined"
                          {...params}
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                        ></TextField>
                      )}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridStyle}>
                  {this.state.District ? (
                    <Autocomplete
                      disableClearable="true"
                      disableOpenOnFocus="true"
                      options={this.state.District}
                      value={
                        this.props.assignedDistrict &&
                        this.props.assignedDistrict !== null
                          ? this.props.assignedDistrict
                          : ""
                      }
                      // disabled={
                      //   new StorageHandler().getUser() &&
                      //   new StorageHandler().getUser().basicDetails &&
                      //   new StorageHandler().getUser().basicDetails.district_id
                      //     ? true
                      //     : false
                      // }
                      name="district"
                      getOptionLabel={(option) =>
                        option.district_name ? option.district_name : ""
                      }
                      onChange={(event, value) =>
                        this.handleChangeDistrict(event, value)
                      }
                      popupIcon={<DownArrow />}
                      classes={{
                        input: classes.textValue,
                        option: classes.textValue,
                        popupIndicatorOpen: classes.popIconOpen,
                        popupIndicator: classes.popIcon,
                        noOptions: classes.textNoOptions,
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          label="District"
                          name="district"
                          id="outlined-margin-dense"
                          margin="dense"
                          variant="outlined"
                          className={classes.textField}
                          {...params}
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                        ></TextField>
                      )}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12} md={12} className={classes.gridStyle2}>
                  {this.state.Field ? (
                    <Autocomplete
                      disableClearable="true"
                      disableOpenOnFocus="true"
                      options={this.state.Field}
                      value={
                        this.props.assignedField &&
                        this.props.assignedField !== null
                          ? this.props.assignedField
                          : ""
                      }
                      // disabled={
                      //   new StorageHandler().getUser() &&
                      //   new StorageHandler().getUser().basicDetails &&
                      //   new StorageHandler().getUser().basicDetails.location_id
                      //     ? true
                      //     : false
                      // }
                      name="field"
                      getOptionLabel={(option) =>
                        option.place_name ? option.place_name : ""
                      }
                      onChange={(event, value) =>
                        this.handleChangeField(event, value)
                      }
                      popupIcon={<DownArrow />}
                      classes={{
                        input: classes.textValue,
                        option: classes.textValue,
                        popupIndicatorOpen: classes.popIconOpen,
                        popupIndicator: classes.popIcon,
                        noOptions: classes.textNoOptions,
                      }}
                      renderInput={(params) => (
                        <TextField
                          className={classes.textField}
                          fullWidth
                          label="Field"
                          name="field"
                          id="outlined-margin-dense"
                          margin="dense"
                          variant="outlined"
                          {...params}
                          InputLabelProps={{
                            classes: { root: classes.textLabel },
                          }}
                        ></TextField>
                      )}
                    />
                  ) : null}
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  justify={"space-between"}
                  alignItems={"center"}
                >
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.filterSubHeading}
                      style={{ marginTop: "19px" }}
                    >
                      Advanced Filter
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={6}
                    style={{ textAlign: "right", marginTop: "18px" }}
                  >
                    <IconButton
                      color="inherit"
                      onClick={(e) => {
                        this.setState({
                          extraFilterStatus: !this.state.extraFilterStatus,
                        });
                      }}
                    >
                      {this.state.extraFilterStatus ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
                {this.state.extraFilterStatus ? (
                  <>
                    <Grid item xs={12} md={12} className={classes.gridStyle}>
                      <Autocomplete
                        disableClearable="true"
                        disableOpenOnFocus="true"
                        options={this.state.Camera}
                        value={this.props.camera}
                        name="camera"
                        getOptionLabel={(option) =>
                          option.field
                            ? option.camera_id + "-" + " " + option.field
                            : ""
                        }
                        className={classes.textField}
                        onChange={(e, value) =>
                          this.handleChangeCamera(e, value)
                        }
                        popupIcon={<DownArrow />}
                        classes={{
                          input: classes.textValue,
                          option: classes.textValue,
                          popupIndicatorOpen: classes.popIconOpen,
                          popupIndicator: classes.popIcon,
                          noOptions: classes.textNoOptions,
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            label="Camera"
                            name="camera"
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            {...params}
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                          ></TextField>
                        )}
                      />
                    </Grid>
                    {/* <div style={{ height: "10px" }}>&nbsp;</div> */}
                    {/* <Grid item xs={12} md={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.filterSubHeading}
                    style={{ marginTop: "18px" }}
                  >
                    Confidence
                  </Typography>

                  <div
                    style={{
                      paddingTop: "35px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <PrettoSlider
                      value={
                        typeof this.state.confidence === "number"
                          ? this.state.confidence
                          : 0
                      }
                      onChange={this.handleChangeConfidence}
                      valueLabelDisplay="on"
                      aria-label="pretto slider"
                    />
                  </div>
                  {this.state.errors.confidence ? (
                    <FormHelperText className={classes.formHelperText}>
                      {this.state.errors.confidence}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </Grid> */}
                    <div style={{ height: "10px" }}>&nbsp;</div>
                    <Grid item xs={12} md={12} className={classes.gridStyle}>
                      <Autocomplete
                        // disabled={
                        //   this.state.alert_type === "exempted goods" ? false : true
                        // }
                        disableClearable="true"
                        disableOpenOnFocus="true"
                        options={this.state.Commodity}
                        value={this.props.commodity_category}
                        name="commodity_category"
                        getOptionLabel={(option) =>
                          option.nt_category ? option.nt_category : ""
                        }
                        onChange={(e, value) =>
                          this.handleChangeCommodity(e, value)
                        }
                        popupIcon={<DownArrow />}
                        classes={{
                          input: classes.textValue,
                          option: classes.textValue,
                          popupIndicatorOpen: classes.popIconOpen,
                          popupIndicator: classes.popIcon,
                          noOptions: classes.textNoOptions,
                        }}
                        renderInput={(params) => (
                          <TextField
                            className={classes.textField}
                            fullWidth
                            label="Commodity Category"
                            name="commodity_category"
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            {...params}
                            InputLabelProps={{
                              classes: { root: classes.textLabel },
                            }}
                          ></TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.filterSubHeading}
                        style={{ marginTop: "18px" }}
                      >
                        Offenders
                      </Typography>
                      <RadioGroup
                        id="outlined-select-offender-native"
                        select
                        name="offender"
                        value={this.props.offender}
                        onChange={(e) => this.handleChangeOffender(e)}
                        margin="dense"
                        variant="outlined"
                        style={{ marginTop: "-7px" }}
                      >
                        <Grid container item xs={12}>
                          {offenders.map((option) => (
                            <Grid>
                              <FormControlLabel
                                classes={{
                                  label: classes.filterSubHeadingOptions,
                                }}
                                control={<StyledRadio />}
                                key={option.value}
                                value={option.value}
                                label={option.label}
                              >
                                {option.label}
                              </FormControlLabel>
                            </Grid>
                          ))}
                        </Grid>
                        <Grid container item xs={12}></Grid>
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.filterSubHeading}
                        style={{ marginTop: "15px" }}
                      >
                        Vehicle Category
                      </Typography>
                      <RadioGroup
                        style={{ display: "flex", marginTop: "-8px" }}
                        id="vehicle_category"
                        name="vehicle_category"
                        onChange={(e) => this.handleChangeVehicle(e)}
                        value={this.props.vehicle_category}
                      >
                        <Grid container item xs={12}>
                          {this.props.fetchVehicles.map((vehicles) => {
                            return (
                              <Grid item xs={12} md={6}>
                                <FormControlLabel
                                  classes={{
                                    label: classes.filterSubHeadingOptions,
                                  }}
                                  control={<StyledRadio />}
                                  value={vehicles.key}
                                  key={vehicles.key}
                                  label={vehicles.value}
                                >
                                  {vehicles.value}
                                </FormControlLabel>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </RadioGroup>
                    </Grid>
                    <div style={{ height: "10px" }}>&nbsp;</div>
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.filterSubHeading}
                        style={{ marginTop: "3px" }}
                      >
                        Lane
                      </Typography>
                      <RadioGroup
                        id="lane"
                        style={{ width: "auto", marginTop: "-8px" }}
                        onChange={(e) => this.handleChangeLane(e)}
                        name="lane"
                        value={this.props.lane}
                      >
                        <Grid container item xs={12}>
                          {this.props.fetchLanes.map((lane) => {
                            return (
                              <Grid item xs={12} md={6}>
                                <FormControlLabel
                                  classes={{
                                    label: classes.filterSubHeadingOptions,
                                  }}
                                  control={<StyledRadio />}
                                  value={lane.value.toString()}
                                  key={lane.key}
                                  label={lane.value}
                                >
                                  {lane.value}
                                </FormControlLabel>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </RadioGroup>
                    </Grid>
                    <div style={{ height: "10px" }}>&nbsp;</div>
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.filterSubHeading}
                        style={{ marginTop: "5px" }}
                      >
                        Review Type
                      </Typography>
                      <RadioGroup
                        style={{ display: "flex", marginTop: "-7px" }}
                        id="alert_status"
                        name="alert_status"
                        value={this.props.alert_status}
                        onChange={(e, value) =>
                          this.handleChangeWarningType(e, value)
                        }
                      >
                        <Grid container item xs={12}>
                          {this.state.Warning &&
                            this.state.Warning.map((option, i) => (
                              <Grid item xs={12} md={6}>
                                <FormControlLabel
                                  classes={{
                                    label: classes.filterSubHeadingOptions,
                                  }}
                                  control={<StyledRadio />}
                                  value={option.value}
                                  label={option.key}
                                >
                                  {option.key}
                                </FormControlLabel>
                              </Grid>
                            ))}
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </>
                ) : (
                  <div style={{ height: "150px" }}>&nbsp;</div>
                )}
              </Grid>
            </>
          </DialogContent>
          <DialogActions className={classes.drawerAction}>
            <Grid container xs={12} justify={"center"} spacing={2}>
              <Grid item xs={1} md={1}></Grid>
              <Grid item xs={5} md={5}>
                <Button
                  onClick={this.closeWindow}
                  className={classes.closeButton}
                  disableElevation={true}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={5} md={5}>
                <Button
                  onClick={() => this.setFilterParams()}
                  color="secondary"
                  variant="contained"
                  className={classes.button}
                  disableElevation={true}
                  fullWidth
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={1} md={1}></Grid>
            </Grid>
          </DialogActions>
        </Drawer>
      </div>
    );
  }
}
export default withStyles(styles)(Filter);
