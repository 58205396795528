export const TOGGLE_FILTER_WINDOW = "TOGGLE_FILTER_WINDOW";
export const SET_LOWALERT_FILTER_PARAMS = "SET_LOWALERT_FILTER_PARAMS";
export const RESET_LOWALERT_FILTER_PARAMS = "RESET_LOWALERT_FILTER_PARAMS";
export const FETCH_LANE = "FETCH_LANE";
export const FETCH_LANE_FAILED = "FETCH_LANE_FAILED";
export const FETCH_CAMERA = "FETCH_CAMERA";
export const FETCH_CAMERA_FAILED = "FETCH_CAMERA_FAILED";
export const FETCH_VEHICLE = "FETCH_VEHICLE";
export const FETCH_VEHICLE_FAILED = "FETCH_VEHICLE_FAILED";
export const CLOSE_WINDOW = "CLOSE_WINDOW";
