import React, { Component, Fragment } from "react";
import { LinearProgress } from "@material-ui/core";
import { connect } from "react-redux";

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        {this.props.loading && <LinearProgress color="secondary" />}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.progress.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Progress);
