import { FETCH_RECORD_SUCCESS, FETCH_RECORD_FAILED } from "./constants";
const initialState = {
  fetchedRecord: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_RECORD_SUCCESS:
      return {
        ...state,
        fetchedRecord: action.data.data,
      };
    case FETCH_RECORD_FAILED:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
