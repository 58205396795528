import React, { Component } from "react";
import { Grid, Divider, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
//import UltsLogo from "./images/ults-logo.png";
import UltsLogo from "./images/ULTSLOGO.png";
import validator from "../../services/validator/validator/";
import { find } from "lodash";
import { ReactComponent as Visibility } from "../../assets/SvgIcons/eye.svg";
import { ReactComponent as VisibilityOff } from "../../assets/SvgIcons/eye_hide.svg";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";

import { ReactComponent as AccountCircle } from "../../assets/SvgIcons/basic_info.svg";

import { ReactComponent as LockIcon } from "../../assets/SvgIcons/password.svg";
// import Img from "../../assets/SvgIcons/login_Illustration.svg";
//import UlcpLogo from "./images/logo.png";
import styles from "./styles";
import ReCAPTCHA from "react-google-recaptcha";
import CaptchaSiteKeyLogin from "../../services/siteKeyLogin";
// import "./style.css";
import { Paper, IconButton, Tooltip } from "@material-ui/core";
import { ReactComponent as ClearIcon } from "../../assets/Visual Assets/clear.svg";

class Login extends Component {
  constructor(props) {
    super(props);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.state = {
      showPassword: false,
      isVerified: false,
      data: { username: "", password: "", userInput: "" },
      rules: {
        username: {
          required: true,
        },
        password: {
          required: true,
        },
        userInput: {
          required: true,
        },
      },
      errors: {
        username: "",
        password: "",
        userInput: "",
      },
    };
  }
  verifyCallback(response) {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  }
  onExpiredCaptcha() {
    this.setState({
      isVerified: false,
    });
  }
  handleClickShowPassword = () => {
    this.setState({ ...this.state, showPassword: !this.state.showPassword });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      ...this.state,
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };
  handleChange = (event) => {
    this.setState({
      ...this.state,
      data: { ...this.state.data, [event.target.id]: event.target.value },

      errors: {
        ...this.state.errors,
        [event.target.name]: "",
      },
    });
  };
  validate = (rules, data) => {
    const errors = validator(rules)(data);
    const hasErrors = find(errors, (error) => error !== "");

    this.setState({ errors });
    return !hasErrors;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validate(this.state.rules, this.state.data)) {
      const userCredentials = {
        username: this.state.data.username,
        password: this.state.data.password,
        captchaKey: this.props.captchaText,
        captchaResult: this.state.data.userInput,
      };
      this.props.doLogin(userCredentials);
    }
  };
  componentDidMount() {
    this.props.getSvgCaptcha();
    if (this.props.isAuthenticated) {
      this.props.navigateTo("/dashboard");
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.isAuthenticated) {
      this.props.navigateTo("/dashboard");
    }

    if (this.props.checkStatus === "failed") {
      this.props.resetUserInput();
      this.props.getSvgCaptcha();
    }
    if (this.props.authStatus === false) {
      this.props.resetUserInput();
      this.props.getSvgCaptcha();
    }
    if (prevProps.userInput != this.props.userInput) {
      this.setState({
        ...this.state,
        data: { ...this.state.data, userInput: this.props.userInput },
        errors: {
          ...this.state.errors,
          userInput: "",
        },
      });
    }
  }
  onPoweredByClick = () => {
    let win = window.open("http://ults.in", "_blank");
    win.focus();
  };

  verifyCaptcha = (event) => {
    this.props.setUserInput(event.target.value);
  };

  resetCaptcha = (e) => {
    e.preventDefault();
    this.props.resetUserInput();
    this.setState(
      {
        ...this.state,
        data: { ...this.state.data, userInput: "" },
        errors: {
          ...this.state.errors,
          userInput: "",
        },
      },
      () => {
        this.props.getSvgCaptcha();
      }
    );
  };

  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    const { showPassword } = this.state;

    return (
      <Paper className="MainContainer">
        <Grid container className={classes.formStyle}>
          <Grid
            container
            item
            xs={12}
            md={6}
            className={classes.rightGridColor}
            className={classes.formStyle}
          >
            <div className={classes.head}>
              <React.Fragment>
                <span className={classes.heading1}>ANPR</span>
                <span className={classes.heading2}>&nbsp;Management</span>
              </React.Fragment>
              <div></div>

              <div>
                <Typography className={classes.dilog}>
                  A technology that uses optical character recognition on images
                  to read vehicle registration plates to create vehicle location
                  data
                </Typography>
              </div>

              <form style={{}} onSubmit={(e) => this.handleSubmit(e)}>
                <Grid container className={classes.root} spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justify="center" spacing={2}>
                      <Grid item xs={12}>
                        <Paper elevation={0}>
                          <FormControl
                            required
                            fullWidth
                            error={true}
                            className={classes.inputField}
                          >
                            <TextField
                              id="username"
                              name="username"
                              variant="outlined"
                              placeholder="Username"
                              className={classes.root2}
                              onChange={this.handleChange}
                              InputProps={{
                                maxLength: 20,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AccountCircle
                                      style={{
                                        color: "#C4CCDC",
                                        width: "14px",
                                        height: "16",
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {errors.username ? (
                              <FormHelperText>{errors.username}</FormHelperText>
                            ) : (
                              ""
                            )}
                          </FormControl>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper elevation={0}>
                          <FormControl
                            className={classes.inputField_2}
                            required
                            error={true}
                          >
                            <TextField
                              className={classes.root2}
                              id="password"
                              placeholder="Password"
                              name="password"
                              type="password"
                              variant="outlined"
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                maxLength: 20,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockIcon
                                      style={{
                                        color: "#C4CCDC",
                                        width: "15px",
                                        height: "20",
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      style={{ color: "#C4CCDC" }}
                                      aria-label="Toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                    >
                                      {showPassword ? (
                                        <Visibility
                                          style={{ color: "#C4CCDC" }}
                                        />
                                      ) : (
                                        <VisibilityOff
                                          style={{ color: "#C4CCDC" }}
                                        />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              onChange={this.handleChange}
                            />
                            {errors.password ? (
                              <FormHelperText>{errors.password}</FormHelperText>
                            ) : (
                              ""
                            )}
                          </FormControl>
                        </Paper>
                      </Grid>
                      <Grid container className={classes.captchaContainer}>
                        {/* <Paper elevation={0}>
                          <FormControl required className={classes.rcp}>
                            <ReCAPTCHA
                              sitekey={CaptchaSiteKeyLogin}
                              render="explicit"
                              theme="light"
                              type="image"
                              tabindex="0"
                              badge="bottomright"
                              onChange={this.verifyCallback}
                              onExpired={this.onExpiredCaptcha}
                            />
                          </FormControl>
                        </Paper> */}
                        <Grid item xs={12} md={3} className={classes.svgImg}>
                          <img
                            src={`data:image/svg+xml;base64,${btoa(
                              this.props.captchaImgs
                            )}`}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="userInput"
                            name="userInput"
                            variant="outlined"
                            placeholder="Enter Sum"
                            className={classes.textField1}
                            onChange={this.verifyCaptcha}
                            value={this.state.data.userInput}
                          />
                          {errors.userInput ? (
                            <FormHelperText className={classes.formHelperText}>
                              Please verify captcha
                            </FormHelperText>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={2} className={classes.resetGrid}>
                          <Tooltip
                            title="Get a New Challenge"
                            placement="right-start"
                          >
                            <IconButton
                              onClick={this.resetCaptcha}
                              className={classes.resetButton}
                            >
                              <ClearIcon className={classes.filterIcon} />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper elevation={0}>
                          <FormControl
                            required
                            // style={{
                            //   marginTop: "-9px",
                            // }}
                          >
                            <Button
                              // type="submit"
                              // variant="contained"
                              // color="#1BB99A"
                              // className={classes.buttonStyle}
                              type="submit"
                              variant="contained"
                              color="secondary"
                              style={{
                                backgroundColor: "#1BB99A",
                                width: "362px",
                                height: "54px",
                                fontSize: "19px",
                                color: "#FFFFFF",
                                borderRadius: 8,
                                textTransform: "none",
                                fontFamily: "Karla",
                              }}
                            >
                              Login
                            </Button>
                          </FormControl>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper elevation={0}>
                          <div className={classes.poweredByStyle}>
                            <span>Powered by &nbsp; </span>
                            <span>
                              <img
                                onClick={this.onPoweredByClick}
                                className={classes.poweredByLogoStyle}
                                src={UltsLogo}
                                alt="UL Technology Solutions logo"
                              />
                            </span>
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>

          <Grid className={classes.rightGridColor} xs={12} md={6} item>
            <div className={classes.featuresStyl}>
              {/* <img src={Img} fullWidth alt="logo" /> */}
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
export default withStyles(styles)(Login);
