import {
  FETCH_SQUAD_SUCCESS,
  FETCH_SQUAD_FAILED,
  FETCH_DISTRICT_SUCCESS,
  FETCH_DISTRICT_FAILED,
  FETCH_SQUAD_DETAILS_SUCCESS,
  FETCH_SQUAD_DETAILS_FAILED,
  ASSIGN_DUTY,
  ASSIGN_DUTY_FAILED,
  FETCH_DUTY_SUCCESS,
  FETCH_DUTY_FAILED,
  EDIT_DUTIES,
  EDIT_DUTIES_FAILED,
  RESET_DUTIES,
  RESET_SQUAD_FIELD,
} from "./constants";
import DutyMasterService from "service/DutyMasterService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchDistrict = () => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DutyMasterService().fetchDistrict().then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };
  function success(data) {
    return { type: FETCH_DISTRICT_SUCCESS, data };
  }
  function failure(error) {
    return { type: FETCH_DISTRICT_FAILED, error };
  }
};
export const fetchSqad = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DutyMasterService().fetchSqard(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };
  function success(data) {
    return { type: FETCH_SQUAD_SUCCESS, data };
  }
  function failure(error) {
    return { type: FETCH_SQUAD_FAILED, error };
  }
};
export const fetchSquardDetails = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DutyMasterService().fetchSquardDetails(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };
  function success(data) {
    return { type: FETCH_SQUAD_DETAILS_SUCCESS, data };
  }
  function failure(error) {
    return { type: FETCH_SQUAD_DETAILS_FAILED, error };
  }
};
export const assignDuty = (params) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DutyMasterService().addDuty(params).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: ASSIGN_DUTY, data };
  }
  function failure(error) {
    return { type: ASSIGN_DUTY_FAILED, error };
  }
};

export const fetchDuty = (id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DutyMasterService().fetchDuty(id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: FETCH_DUTY_SUCCESS, data };
  }
  function failure(error) {
    return { type: FETCH_DUTY_FAILED, error };
  }
};

export const editDuty = (params, id) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new DutyMasterService().editDuty(params, id).then((response) => {
      if (response.data) {
        dispatch(success(response.data));
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
        dispatch(failure(response));
      }
      dispatch(progressActions.hideProgress());
    });
  };

  function success(data) {
    return { type: EDIT_DUTIES, data };
  }
  function failure(error) {
    return { type: EDIT_DUTIES_FAILED, error };
  }
};

export function resetDuties() {
  return {
    type: RESET_DUTIES,
  };
}

export function resetOfficeandField() {
  return {
    type: RESET_SQUAD_FIELD,
  };
}
// export const fetchField = (id) => {
//   return (dispatch) => {
//     dispatch(progressActions.showProgress());
//     new DutyMasterService().fetchField(id).then((response) => {
//       if (response.data) {
//         dispatch(success(response.data));
//       } else {
//         dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
//         dispatch(failure(response));
//       }
//       dispatch(progressActions.hideProgress());
//     });
//   };
//   function success(data) {
//     return { type: FETCH_FIELD_SUCCESS, data };
//   }
//   function failure(error) {
//     return { type: FETCH_FIELD_FAILED, error };
//   }
// };
// export const fetchzone = () => {
//   return (dispatch) => {
//     dispatch(progressActions.showProgress());
//     new DutyMasterService().fetchzone().then((response) => {
//       if (response.data) {
//         dispatch(success(response.data));
//       } else {
//         dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
//         dispatch(failure(response));
//       }
//       dispatch(progressActions.hideProgress());
//     });
//   };
//   function success(data) {
//     return { type: FETCH_ZONE_SUCCESS, data };
//   }
//   function failure(error) {
//     return { type: FETCH_ZONE_FAILED, error };
//   }
// };
