const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.border.radius,
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
  },
  grid: {
    marginTop: "0px",
    paddingTop: "0px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
  },
  subTitleHead: {
    font: "normal normal normal 16px/10px Karla",
    color: "#797C82",
    display: "flex",
    marginBottom: "28.5px",
  },
  subTitleContent: {
    font: "normal normal bold 16px/10px Karla",
    color: "#2B3D51",
    textTransform: "capitalize",
  },
  textFielddes: {
    marginLeft: "33px",
    marginRight: "13px",
    paddingTop: "25px",
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
  instructions: {
    minHeight: "30vh",
    overflow: "auto",
    maxHeight: "37vh",

    /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      maxHeight: "39vh",
    },
  },
  table: {
    minWidth: "100%",
    borderTop: "2px solid #F1F4FB",
  },
  tableWrapper: {
    overflowX: "auto",
    // paddingLeft: "33px",
    paddingRight: "10px",
    // paddingTop: "6px",
  },
  tableCell: {
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "0px",
    paddingBottom: "0px",
    height: "41px",
  },

  notAddedStyle: {
    backgroundColor: "rgb(193, 191, 191)",
    textAlign: "center",
    margin: 15,
    color: "#fff",
    fontSize: 25,
    padding: 50,
  },
  button: {
    width: "121px",
    marginBottom: "3px",
    fontWeight: theme.font.fontWeight,
    backgroundColor: "#1BB99A",
    color: theme.colors.whiteColor,
    textTransform: "none",
    font: "normal normal normal 14px/30px Karla",
  },
  closeButton: {
    width: "121px",
    marginBottom: "3px",
    fontWeight: theme.font.fontWeight,
    backgroundColor: "#E7EBF4",
    color: "#797C82",
    textTransform: "none",
    font: "normal normal normal 14px/30px Karla",
  },
  btnAlignRight: {
    textAlign: "right",
  },

  Headroot: {
    paddingRight: theme.spacing.unit,
    backgroundColor: theme.toolbar.backgroundColor,
  },
  spacer: {
    flex: "1 1 20%",
  },
  actions: {
    paddingRight: theme.spacing.unit,
  },
  title: {
    flex: "0 0 auto",
  },
  tableTitle: {
    color: theme.toolbar.iconColor,
    fontWeight: "300",
  },
  filterIcon: {
    color: theme.toolbar.iconColor,
  },
  listTop: {
    paddingTop: "20px",
  },
  fabBtn: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 30,
    left: "auto",
    position: "fixed",
  },
  featureList: {
    paddingTop: "3%",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  paperSup: {
    width: "100%",
    paddingBottom: "2%",
    paddingTop: "2%",
  },
  head: {
    fontSize: "16px",
  },
  btn: {
    fontWeight: theme.font.fontWeight,
  },
});

export default styles;
