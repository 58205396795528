import {
  EDIT_ROLE_MASTER,
  EDIT_ROLE_MASTER_ERROR,
  RESET_ROLE_MASTER_STATE,
} from "./constants";

const initialState = {
  error: "",
  saveSuccess: false,
  message: "",
  roleMasterList: [],
};
export default function(state = initialState, action) {
  switch (action.type) {
    case EDIT_ROLE_MASTER:
      return {
        ...state,
        aveSuccess: true,
        message: action.data.message,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };

    case EDIT_ROLE_MASTER_ERROR:
      return {
        ...initialState,
        error:
          action.response !== undefined ? action.response.data.error[0] : "",
      };
    case RESET_ROLE_MASTER_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
