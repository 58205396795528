const NOT_FOUND = "NO RECORDS FOUND";
const NOT_ADDED = "NO RECORDS FOUND";
const CLICK_GENERATE_TO_VIEW_REPORT = "NO REPORTS FOUND";
const HABITUAL_OFFENDER_FOUND = "Habitual Offenders Found.....!";
const FLAMMABLE_GOODS_FOUND = "Flammable Goods Found.....!";
const SAME_EWAY_BILL = "Multiple Truck with Same E-way Bills Found.....!";
const SELECT_RECORD = "Select A Record to View Details";
const WITHOUT_EWAY_BILL = "Running without E-way Bill";
const REVIEW = "Please review this record";
const NO_REPORTS_FOUND = "NO REPORTS FOUND";

export default {
  NOT_FOUND,
  NO_REPORTS_FOUND,

  NOT_ADDED,
  HABITUAL_OFFENDER_FOUND,
  FLAMMABLE_GOODS_FOUND,
  SAME_EWAY_BILL,
  SELECT_RECORD,
  WITHOUT_EWAY_BILL,
  REVIEW,
  CLICK_GENERATE_TO_VIEW_REPORT,
};
