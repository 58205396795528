import { connect } from "react-redux";
import { withRouter } from "react-router";
import HabitualOffendersList from "./habitualOffendersList";
import { push } from "connected-react-router";
import {
  toggleFilterWindow,
  resetFilterParams,
} from "../../components/filter_form/data/action";
import {
  fetchHabitualOffendersList,
  deleteHabitualOffender,
  // deleteLocation,
  resetDelete,
} from "./data/action";
import { snackbarActions } from "components/snackbar/data/action";

function mapStateToProps(state) {
  return {
    habitualOffendersLists: state.habitualOffendersList.habitualOffendersLists,
    habitualOffendersCount: state.habitualOffendersList.habitualOffendersCount,
    filterParams: state.filter.filterParams,
    deleteSuccessReposnse: state.habitualOffendersList.deleteSuccessMessage,
    deleteSuccess: state.habitualOffendersList.deleteSuccess,
    loading: state.progress.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchHabitualOffendersList: (page, limit, filterParams) =>
      dispatch(fetchHabitualOffendersList(page, limit, filterParams)),
    navigateTo: (url) => dispatch(push(url)),
    toggleFilterWindow: () => dispatch(toggleFilterWindow()),
    resetFilterParams: () => dispatch(resetFilterParams()),
    deleteHabitualOffender: (id) => dispatch(deleteHabitualOffender(id)),
    // deleteLocation: id => dispatch(deleteLocation(id)),
    resetDelete: () => dispatch(resetDelete()),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HabitualOffendersList)
);
