import { push } from "connected-react-router";
import WarningView from "./WarningView";
import { connect } from "react-redux";
import { setPlateNumber } from "../../../camera/components/recent_activity/data/action";
import {
  fetchRecord,
  updateStatus,
  fetchWarningNextandPrev,
} from "./data/action";
import { snackbarActions } from "components/snackbar/data/action";

function mapStateToProps(state) {
  return {
    fetchedRecord: state.warningView.fetchedRecord,
    update: state.warningView.updateStatus,
    loading: state.progress.loading,
    filterParams: state.filterwarning.filterParams,
    event_timestamp: state.historyList.event_timestamp,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigateTo: (url) => dispatch(push(url)),
    setPlateNumber: (plate) => dispatch(setPlateNumber(plate)),
    fetchRecord: (id, event_timestamp) =>
      dispatch(fetchRecord(id, event_timestamp)),
    updateStatus: (params) => dispatch(updateStatus(params)),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
    fetchWarningNextandPrev: (data) => dispatch(fetchWarningNextandPrev(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WarningView);
