import {
  FETCH_HISTORY_LIST,
  FETCH_HISTORY_LIST_ERROR,
  SET_PAGE,
  SET_ROWSPERPAGE,
  RESET_PAGE,
  RESET_ERROR_PAGE,
  SET_TIME,
  RESET_TIME,
} from "./constants";

const initialState = {
  historyList: [],
  historyCount: 0,
  // filterParams: {},
  // success: false,
  rowsPerPage: 10,
  page: 0,
  error: false,
  event_timestamp: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_HISTORY_LIST:
      return {
        ...state,
        success: true,
        historyList: action.data.data,
        historyCount: action.data.recordCount,
      };
    case FETCH_HISTORY_LIST_ERROR:
      return {
        ...initialState,
        error: true,
      };

    case SET_ROWSPERPAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case RESET_PAGE:
      return {
        ...state,
        page: 0,
      };
    case RESET_ERROR_PAGE:
      return {
        ...state,
        error: false,
      };
    case SET_TIME:
      return {
        ...state,
        event_timestamp: action.time,
      };
    case RESET_TIME:
      return {
        ...state,
        event_timestamp: "",
      };
    default:
      return state;
  }
}
