import {
  FETCH_WARNINGRECORD_SUCCESS,
  FETCH_WARNINGRECORD_FAILED,
} from "./constants";
import WarningService from "../../../../../service/WarningService";
import { snackbarActions } from "components/snackbar/data/action";
import { progressActions } from "components/progressbar/data/action";

export const fetchWarningRecord = (id, event_timestamp) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new WarningService()
      .fetchWarningRecord(id, event_timestamp)
      .then((response) => {
        if (response.data) {
          dispatch(success(response.data));
        } else {
          dispatch(
            snackbarActions.showSnackbar(response.error.message, "error")
          );
          dispatch(failure(response));
        }
        dispatch(progressActions.hideProgress());
      });
  };

  function success(data) {
    return { type: FETCH_WARNINGRECORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: FETCH_WARNINGRECORD_FAILED, error };
  }
};

export const fetchWarningsNextandPrev = (data) => {
  return (dispatch) => {
    dispatch(progressActions.showProgress());
    new WarningService().warningsnextprev(data).then((response) => {
      if (response.data) {
        if (response.data.status === "success")
          dispatch(success(response.data));
        else
          dispatch(
            snackbarActions.showSnackbar(response.data.message, "error")
          );
      } else {
        dispatch(snackbarActions.showSnackbar(response.error.message, "error"));
      }
      dispatch(progressActions.hideProgress());
    });
  };
  function success(data) {
    return { type: FETCH_WARNINGRECORD_SUCCESS, data };
  }
};
