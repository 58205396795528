import { connect } from "react-redux";
import { withRouter } from "react-router";
import Summary from "./summary";
import { snackbarActions } from "components/snackbar/data/action";
import {
  fetchZone,
  fetchDistrict,
  fetchField,
} from "../../components/filterhistory_form/data/action.js";
import {
  fetchStateCamera,
  fetchZoneCamera,
  fetchDistrictCamera,
  fetchFieldCamera,
} from "../camera/cameraSelector/data/actions";
import { resetReportData, fetchReport, exportReport } from "./data/actions";

function mapStateToProps(state, ownProps) {
  return {
    field: state.filterhistory.field,
    fetchedzone: state.filterhistory.fetchedzone,
    district: state.filterhistory.district,
    Camera: state.cameraReducer.cameraList,
    reportData: state.summary.reportData,
    types: state.summary.types,
    loading: state.progress.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchReport: (data) => dispatch(fetchReport(data)),
    exportReport: (data) => dispatch(exportReport(data)),
    resetReportData: () => dispatch(resetReportData()),
    fetchStateCamera: () => dispatch(fetchStateCamera()),
    fetchZoneCamera: (zone) => dispatch(fetchZoneCamera(zone)),
    fetchDistrictCamera: (district) => dispatch(fetchDistrictCamera(district)),
    fetchFieldCamera: (field) => dispatch(fetchFieldCamera(field)),
    fetchZone: (type) => dispatch(fetchZone(type)),
    fetchDistrict: (type, id) => dispatch(fetchDistrict(type, id)),
    fetchField: (type, id) => dispatch(fetchField(type, id)),
    showSnackbar: (message, type) =>
      dispatch(snackbarActions.showSnackbar(message, type)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Summary)
);
