import React, { Fragment } from "react";
import classNames from "classnames";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Toolbar,
  Typography,
  withStyles,
  Paper,
  Grid,
  Button,
  Table,
  TableBody,
  TablePagination,
  Modal,
  IconButton,
  Tooltip,
  MenuItem,
  Divider,
  InputBase,
  FormControl,
  Select,
  InputLabel,
  InputAdornment,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { ReactComponent as Edit } from "../../assets/Visual Assets/edit.svg";
import { ReactComponent as Delete } from "../../assets/Visual Assets/delete.svg";
import { ReactComponent as ClearIcon } from "../../assets/Visual Assets/clear.svg";
import { ReactComponent as SearchIcon } from "../../assets/Visual Assets/search.svg";
import messageConstants from "../../services/validator/MsgConsts/messageConstants";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import StorageHandler from "../../util/storage/index";
import { ReactComponent as DownArrow } from "../../assets/SvgIcons/dropdown.svg";
import Pagination from "@material-ui/lab/Pagination";
import "../userMaster/style.css";
import clsx from "clsx";
import SkeltonScreen from "../../components/skelton_screen";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const rows = [
  {
    id: "Sl No",
    numeric: false,
    disablepadding: true,
    label: "Sl No",
    active: true,
  },

  {
    id: "Squad Name",
    numeric: false,
    disablepadding: true,
    label: "Squad Name",
    active: false,
  },

  {
    id: "Leader",
    numeric: false,
    disablepadding: true,
    label: "Leader",
    active: false,
  },

  {
    id: "District",
    numeric: false,
    disablepadding: true,
    label: "District",
    active: false,
  },
  {
    id: "Status",
    numeric: false,
    disablepadding: true,
    label: "Status",
    active: false,
  },
];
const headerStyles = (theme) => ({
  tableHead: {
    textAlign: "left",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 14px",
    backgroundColor: "white",
    borderColor: "#E7EBF4",
  },
  tableHead1: {
    textAlign: "right",
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 14px",
    borderColor: "#E7EBF4",
    backgroundColor: "white"
    // marginLeft: "100px",
  },
  tableh: {
    font: "normal normal bold 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    padding: "17px 24px",
    borderColor: "#E7EBF4",
    backgroundColor: "white"
  },
});
class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.render.props.onRequestSort(event, property);
  };
  render() {
    const { order, orderBy } = this.props;
    const { classes } = this.props;
    const attribute_permissions = new StorageHandler().getAttributPermissions();
    return (
      <TableHead>
        <TableRow className={classes.tableHead}>
          {rows.map((row) => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                sortDirection={orderBy === row.id ? order : false}
                className={row.active ? classes.tableh : classes.tableHead}
              >
                {row.label}
              </TableCell>
            );
          }, this)}
          {new StorageHandler().getAttributPermissions().edit_status == true ? (
            <TableCell className={classes.tableHead1}>Edit</TableCell>
          ) : null}
          {new StorageHandler().getAttributPermissions().delete_status ==
            true ? (
              <TableCell
                className={classes.tableHead1}
                style={{ paddingRight: "35px" }}
              >
                Delete
              </TableCell>
            ) : null}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSeleceted: PropTypes.number.isRequired,
  onRequestsort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
EnhancedTableHead = withStyles(headerStyles)(EnhancedTableHead);
const toolbarStyles = (theme) => ({});

let EnhancedTableToolbar = (props) => {
  const { classes, showFilterWindow } = props;

  return null;
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

// const toolbarStyles = (theme) => ({
//   root: {
//     paddingRight: theme.spacing.unit,
//     backgroundColor: theme.toolbar.backgroundColor,
//   },
//   spacer: {
//     flex: "1 1 20%",
//   },
//   actions: {
//     paddingRight: theme.spacing.unit,
//   },
//   title: {
//     flex: "0 0 auto",
//   },
//   tableTitle: {
//     color: theme.toolbar.iconColor,
//     fontWeight: theme.font.fontWeight,
//   },
//   filterIcon: {
//     color: theme.toolbar.iconColor,
//   },
// });
// let EnhancedTableToolbar = (props) => {
//   const { classes, showFilterWindow } = props;

//   return null;
// };

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.border.radius,
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
  },
  root1: {
    backgroundColor: theme.toolbar.backgroundColor,
  },
  spacer: {
    flex: "1 1 1%",
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: "33px",
    paddingRight: "31px",
    // paddingTop: "6px",
    /* devices (1280px and down) */
    "@media screen and (max-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /* devices (1280px and up) */
    "@media screen and (min-width : 1280px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
    // /* devices (height:800px) */
    "@media screen and (height : 800px)": {
      minHeight: "12vh",
      maxHeight: "57vh",
      overflowY: "auto",
    },
    // /*devices 1366px and up) */
    "@media screen and (min-width: 1366px)": {
      minHeight: "12vh",
      maxHeight: "45vh",
      overflowY: "auto",
    },
    // /* devices (1900px and up) */
    "@media screen and (min-width: 1900px)": {
      minHeight: "12vh",
      maxHeight: "100vh",
      overflowY: "auto",
    },
  },
  butt: {
    background: "white",
  },
  tableCell: {
    fontSize: theme.tableCell.fontSize,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
    border: "none",
    paddingTop: "5px",
    paddingBottom: "4.5px",
    height: "57px",
  },
  cursor: {
    cursor: "pointer",
  },
  notAddedStyle1: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,
    fontSize: 25,
    padding: 55,
    // marginLeft: 33,
    // marginRight: 33,
    marginTop: 33,
    font: "normal normal normal 24px Karla;",
  },
  notAddedStyle: {
    backgroundColor: theme.notAddedStyle.backgroundColor,
    textAlign: "center",
    color: theme.notAddedStyle.color,
    padding: 55,
    marginLeft: 33,
    marginRight: 33,
    marginTop: 33,
    font: "normal normal normal 24px Karla;",
  },
  actionIcon: {
    cursor: "pointer",
  },
  filterbutton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.palette.secondary.main,
    color: theme.colors.whiteColor,
  },
  filterclearButton: {
    fontWeight: theme.font.fontWeight,
    backgroundColor: theme.colors.BlueButton,
    color: theme.colors.whiteColor,
  },
  filtercloseButton: {
    fontWeight: theme.font.fontWeight,
    color: theme.colors.blackColor,
  },

  buttons: {
    marginTop: "14px",
    marginRight: "9px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    width: "111px",
    height: "42px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    fontSize: "14px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    textTransform: "none",
  },
  btn: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#E7EBF4 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#797C82",
    "&:hover": {
      background: "#E7EBF4 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginRight: "4px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  btns: {
    textTransform: "none",
    width: "121px",
    height: "42px",
    background: "#1BB99A 0% 0% no-repeat padding-box",
    opacity: 1,
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#FFFFFF",
    "&:hover": {
      background: "#1BB99A 0% 0% no-repeat padding-box",
    },
    marginTop: "-1px",
    marginBottom: "29px",
    boxShadow: "none",
  },
  tableTitle: {
    marginTop: "6px",
    marginLeft: "10px",
    width: "275px",
    height: "22px",
    textAlign: "left",
    font: "normal normal bold 18px Karla",
    letterSpacing: "0px",
    color: theme.colors.chartHeading,
    opacity: 1,
  },
  grid: {
    marginTop: "21px",
    paddingTop: "22px",
    paddingRight: "18px",
    paddingLeft: "22px",
    borderRadius: theme.border.radius,
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: theme.border.radius,
    paddingBottom: "24px",
  },
  divider: {
    marginTop: "15px",
    height: "0px",
    border: "1px solid #E7EBF4",
    opacity: 1,
  },
  zebrafont: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.zebrafont.oddBgColor,
      opacity: 1,
    },
    "&:nth-of-type(even)": {
      backgroundColor: theme.zebrafont.evenBgColor,
    },
  },
  filterIcon: {
    width: "25px",
    height: "23px",
    color: "#A4AFC6",
    opacity: 1,
  },
  actions1: {
    marginTop: "9px",
    paddingRight: "16px",
  },
  textStyle: {
    color: "#ffffff",
  },
  root2: {
    color: "red",
    backgroundColor: "white",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "0px solid red",
    borderRadius: "5px",
    "& .MuiInputLabel-shrink": {
      paddingLeft: "7px",
      paddingTop: "5px",
    },
    "& MuiInputLabel-formControl ": {
      // paddingLeft: "20px",
      left: "10",
    },
    "& MuiInputLabel-root ": {
      paddingLeft: "50px",
    },
    "& MuiFormLabel-root ": {
      paddingLeft: "10px",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
  textField1: {
    marginTop: "19px",
    // paddingLeft: "14px",
    // paddingTop: "12px",
    // paddingBottom: "13px",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    fontSize: theme.font.size3,
    fontFamily: theme.font.family,
    fontWeight: theme.font.regular,
    letterSpacing: "0px",
    color: "#797C82",
    height: "42px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    //  border: "1px solid #C4CCDC",
    borderRadius: "5px",
    opacity: 1,
  },
  textField2: {
    marginTop: "19px",
    // paddingLeft: "14px",
    // paddingTop: "12px",
    // paddingBottom: "13px",
    // width: "178px",
    height: "40px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    // border: "1px solid #C4CCDC",
    borderRadius: "5px",
    opacity: 1,
    letterSpacing: "0px",
    color: "#797C82",
    textAlign: "left",
    font: "normal normal normal 14px Karla",
    fontSize: theme.font.size3,
    fontFamily: theme.font.family,
    fontWeight: theme.font.regular,
    "& .MuiSelect-select.MuiSelect-select": {
      background: "none",
    },
  },
  dialogBox: {
    //width: "327px",
    height: "161px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 0px 4px #0000000D",
    opacity: 1,
    borderRadius: "20px",
  },
  dialogContent: {
    //width: "247px",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    marginTop: "11px",
    marginRight: "17px",
    marginLeft: "16px",
    font: "normal normal normal 16px Karla",
    // marginRight: "17px",
    // marginLeft: "16px",
  },
  dialogActions: {
    "&.MuiDialogActions-root": {
      justifyContent: "center",
    },
  },
  gridContainer: {
    display: "flex",
    flexDirection: "row",
  },
  actionIcon: {
    cursor: "pointer",
  },
  textLabel: {
    letterSpacing: "0px",
    color: "#797C82",
    opacity: 1,
    fontSize: theme.font.size3,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "0px",
    margin: "0px",
  },
  textValue: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
  },
  textMenu: {
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    fontSize: theme.font.size1,
    fontWeight: theme.font.regular,
    fontFamily: theme.font.family,
    padding: "6px 16px",
  },
  dropIcon: {
    marginTop: "8px",
    marginRight: "8px",
  },
  paginationText: {
    // textAlign: "left",
    font: "normal normal normal 14px Karla",
    letterSpacing: "0px",
    color: "#2B3D51",
    opacity: 1,
    paddingTop: "35px",
    paddingLeft: "33px",
  },
});

class SquadMasterList extends React.Component {
  constructor(props) {
    sessionStorage.setItem("squadMasterError", false);
    super(props);
    this.state = {
      order: "asc",
      orderBy: "date_time",
      selected: [],
      data: [],
      count: 0,
      modal: false,
      page: 0,
      rowsPerPage: 10,
      CountofOffenders: -1,
      squad_id: "",
      squad_name: "",
      open: false,
      squades: "",
      squad: "",
      dist: "",
      filter_squad: "",
      filter_district: "",

      filter_status: false,
      limit: 10,
    };
  }
  showFilterWindow = () => {
    this.props.toggleFilterWindow();
    this.props.fetchDistricts();
  };

  componentDidMount() {
    this.props.resetFilterParams();
    this.props.fetchSqadList(this.state.page, this.state.rowsPerPage);
    this.props.fetchDistricts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.squadList != this.props.squadList) {
      this.setState({
        data: this.props.squadList,
        CountofOffenders: this.props.squadCount,
      });
    }
    if (prevProps.squad != this.props.squad) {
      this.setState({
        data: this.props.squad.rows,
        CountofOffenders: this.props.squad.count,
      });
    }

    if (prevState.rowsPerPage != this.state.rowsPerPage) {
      this.props.fetchSqadList(
        this.state.page,
        this.state.rowsPerPage,
        this.state.filter_squad,
        this.state.filter_district
      );
    }
    if (this.props.deleteSuccess) {
      this.props.resetDelete();
      let params = {
        Page: this.state.page,
        rowsPerPage: this.state.rowsPerPage,
      };

      this.props.fetchSqadList(this.state.page, this.state.rowsPerPage);
      this.props.showSnackbar(
        this.props.deleteSuccessReposnse.message,
        "success"
      );
    }
    if (prevState.page != this.state.page) {
      this.props.onFilterChange(
        this.state.page,
        this.state.rowsPerPage,
        this.state.filter_squad,
        this.state.filter_district
      );
      // this.props.fetchRollMasterList(this.state.page, this.state.rowsPerPage);
    }
    if (prevProps.dist !== this.props.dist) {
      this.setState({ dist: this.props.dist });
    }
    if (prevProps.squad != this.props.squad) {
      this.setState({ squades: this.props.squad });
    }
  }

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleChangePage = (event, pagess) => {
    this.setState({ page: pagess - 1 });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  performAction = () => {
    this.props.navigateTo(`/squad-master/add`);
  };

  editSquad = (squad_id) => {
    this.props.navigateTo(`squad-master/edit/${squad_id}`);
  };

  handleOpen = (squad_id, squad_name) => {
    this.setState({
      squad_id: squad_id,
      squad_name: squad_name,
      modal: !this.state.modal,
    });
  };

  deleteSquad = (squad_id) => {
    this.setState({
      modal: !this.state.modal,
    });
    this.props.deleteSquad(squad_id);
  };
  hangeDialCloseStatus = () => {
    this.props.dialogueWindowStatus();
  };
  changeDialCloseStatus = () => {
    this.props.toggleFilterWindow();
  };
  onUserNameChange = (event) => {
    this.setState({ filter_squad: event.target.value }, () => {
      this.props.onFilterChange(
        this.state.page,
        this.state.limit,
        this.state.filter_squad,
        this.state.filter_district
      );
      this.setState({ filter_status: true });
    });
  };

  onDistrictNameChange = (event) => {
    this.setState({ filter_district: event.target.value }, () => {
      this.props.onFilterChange(
        this.state.page,
        this.state.limit,
        this.state.filter_squad,
        this.state.filter_district
      );
      this.setState({ filter_status: true });
    });
  };
  onFilterChange = () => {
    this.props.onFilterChange(
      this.state.page,
      this.state.limit,
      this.state.filter_squad,
      this.state.filter_district
    );
    this.setState({ filter_status: true });
  };
  onFilterReset = () => {
    // this.setState({
    //   ...this.state,
    //   val: false,
    // });
    this.setState({ filter_squad: "", filter_district: "", page: 0 }, () => {
      this.props.fetchSqadList(this.state.page, this.state.rowsPerPage);
    });
    // this.setState({ filter_status: true });
    // this.setState({ filter_squad: "" });
    // this.setState({ filter_district: "" });
    // this.setState({ page: 0 });

    // this.props.fetchSqadList(this.state.page, this.state.rowsPerPage);

    // this.props.fetchDistricts();
  };
  closeWindow = () => {
    this.props.toggleFilterWindow();
    if (!this.state.val) {
      this.setState({ filter_squad: "" });
      this.setState({ filter_district: "" });
      this.setState({ filter_stat: "" });
    }
  };
  componentWillUnmount() {
    sessionStorage.setItem("squadMasterError", false);
  }
  render() {
    const { classes, showFilterWindow } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, this.state.offendersCount - page * rowsPerPage);
    let firstPageNum = page * rowsPerPage + 1;
    return (
      <>
        <Grid className={classes.grid}>
          <Paper className={classes.paper}>
            <Toolbar className={classNames(classes.roots)}>
              <Typography
                variant="h6"
                id="tableTitle"
                className={classes.tableTitle}
              >
                Squad Master
              </Typography>
              <div className={classes.spacer} />
              <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} md={4}>
                  {/* <TextField
                    label="Search Office"
                    className={classes.textField1}
                    onChange={this.onOfficeNameChange}
                    value={this.state.filter_office}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                </Grid>
                <Grid item xs={12} md={4}>
                  {" "}
                  <TextField
                    id="custom-css-standard-input"
                    label="&nbsp; Search Squad"
                    className={classes.textField1}
                    fullWidth
                    onChange={this.onUserNameChange}
                    value={this.state.filter_squad}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    margin="dense"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      classes: { root: classes.textValue },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  {" "}
                  <TextField
                    className={classes.margin}
                    id="custom-css-standard-input"
                    label="&nbsp; District"
                    select="true"
                    fullWidth={true}
                    onChange={this.onDistrictNameChange}
                    value={this.state.filter_district}
                    fullWidth
                    select
                    variant="outlined"
                    margin="dense"
                    className={classes.textField2}
                    InputLabelProps={{
                      classes: { root: classes.textLabel },
                    }}
                    InputProps={{ classes: { root: classes.textValue } }}
                    SelectProps={{
                      IconComponent: DownArrow,
                      classes: {
                        icon: classes.dropIcon,
                        iconOpen: classes.dropIconOpen,
                      },
                    }}
                  >
                    <MenuItem value="" classes={{ root: classes.textMenu }}>
                      <em>None</em>
                    </MenuItem>
                    {this.state.dist !== "" && this.state.dist.rows
                      ? this.state.dist.rows.map((data) => (
                        <MenuItem
                          value={data.district_id}
                          classes={{ root: classes.textMenu }}
                        >
                          {data.district_name}
                        </MenuItem>
                      ))
                      : null}
                  </TextField>
                </Grid>
              </Grid>
              <div className={classes.actions1}>
                <Tooltip title="Clear fields">
                  <IconButton
                    aria-label="Filter list"
                    onClick={this.onFilterReset}
                  >
                    <ClearIcon className={classes.filterIcon} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.actions}>
                {new StorageHandler().getAttributPermissions() &&
                  new StorageHandler().getAttributPermissions().add_status ==
                  true ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.buttons}
                      disableElevation={true}
                      onClick={() => this.performAction()}
                    >
                      Add Squad
                    </Button>
                  ) : null}
              </div>
            </Toolbar>
            <Divider className={classes.divider} />
            {/* {this.state.CountofOffenders === 0 ? (
              <div className={classes.notAddedStyle}>NOT YET ADDED</div>
            ) : ( */}
            {/* {this.state.CountofOffenders === 0 ? (
              this.props.loading ? (
                <SkeltonScreen />
              ) : (
                <div className={classes.notAddedStyle}>
                  {messageConstants.NOT_ADDED}
                </div>
              )
            ) : ( */}
            {this.props.loading === false &&
              this.state.CountofOffenders == 0 ? (
                <div className={classes.notAddedStyle}>
                  {messageConstants.NOT_ADDED}
                </div>
              ) : (
                <>
                  <div className={classes.tableWrapper}>
                    {data && data.length > 0 ? (
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        stickyHeader
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={this.handleRequestSort}
                          rowCount={this.state.CountofOffenders}
                        />
                        <TableBody>
                          {data.map((n, i) => {
                            const isSelected = this.isSelected(n.id);
                            return (
                              <TableRow
                                className={classes.zebrafont}
                                role="checkbox"
                                aria-checked={isSelected}
                                tabIndex={-1}
                                key={n.id}
                                selected={isSelected}
                                align="center"
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ paddingLeft: "32px" }}
                                  className={classes.tableCell}
                                >
                                  {firstPageNum + i}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tableCell}
                                >
                                  {n.squad_name}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tableCell}
                                >
                                  {n.leader ? n.leader.name : null}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tableCell}
                                >
                                  {n.district_master
                                    ? n.district_master.district_name
                                    : null}
                                </TableCell>
                                {n.status == true ? (
                                  <TableCell className={classes.tableCell}>
                                    Active
                                  </TableCell>
                                ) : (
                                    <TableCell className={classes.tableCell}>
                                      Inactive
                                    </TableCell>
                                  )}{" "}
                                {new StorageHandler().getAttributPermissions() &&
                                  new StorageHandler().getAttributPermissions()
                                    .edit_status == true ? (
                                    <TableCell
                                      className={classes.tableCell}
                                      style={{
                                        textAlign: "right",
                                      }}
                                    >
                                      <i onClick={() => this.editSquad(n.squad_id)}>
                                        <Edit className={classes.actionIcon} />
                                      </i>
                                    </TableCell>
                                  ) : null}
                                {new StorageHandler().getAttributPermissions() &&
                                  new StorageHandler().getAttributPermissions()
                                    .delete_status == true ? (
                                    <TableCell
                                      className={classes.tableCell}
                                      style={{
                                        // paddingLeft: "25px",
                                        paddingRight: "50px",
                                        textAlign: "right",
                                      }}
                                    >
                                      <i
                                        onClick={() =>
                                          this.handleOpen(n.squad_id, n.squad_name)
                                        }
                                      >
                                        <Delete className={classes.actionIcon} />
                                      </i>
                                    </TableCell>
                                  ) : null}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    ) : sessionStorage.getItem("squadMasterError") === "true" ? (
                      <div className={classes.notAddedStyle1}>
                        {messageConstants.NOT_ADDED}
                      </div>
                    ) : (
                          <SkeltonScreen />
                        )}
                  </div>
                  {data && data.length > 0 ? (
                    <Grid container xs={12}>
                      <Grid item xs={4}>
                        <Typography className={classes.paginationText}>
                          {parseInt(page) * rowsPerPage +
                            1 +
                            " - " +
                            (parseInt(page) * rowsPerPage + 10 >
                              this.state.CountofOffenders
                              ? this.state.CountofOffenders
                              : parseInt(page) * rowsPerPage + 10) +
                            " of " +
                            +this.state.CountofOffenders +
                            " Squads"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{ float: "right", paddingRight: "43px" }}
                        xs={8}
                      >
                        <Pagination
                          count={Math.ceil(
                            this.state.CountofOffenders / rowsPerPage
                          )}
                          shape="rounded"
                          page={page + 1}
                          onChange={this.handleChangePage}
                          className="pagination"
                          color="secondary"
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </>
              )}
            {/* <TablePagination
              component="div"
              count={this.state.CountofOffenders}
              rowsPerPageOptions={[5, 10, 15]}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            /> */}
            <Dialog
              open={this.state.modal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              //className={classes.dialogBox}
              PaperComponent={Paper}
              classes={{
                paper: classes.dialogBox,
              }}
            >
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  className={classes.dialogContent}
                >
                  Are you sure you want to delete {this.state.squad_name}?{" "}
                  {/* Are you sure want to delete this ? */}
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btn}
                  disableElevation={true}
                  onClick={() => this.handleOpen()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btns}
                  disableElevation={true}
                  onClick={() => this.deleteSquad(this.state.squad_id)}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </>
    );
  }
}

SquadMasterList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(SquadMasterList);
