import {
  FETCH_REPORT_DATA_SUCCESS,
  FETCH_REPORT_DATA_FAILED,
  RESET_REPORT_DATA,
  FETCH_ALERT_FAILED,
  FETCH_ALERT,
  FETCH_SQUAD_FAILED,
  FETCH_SQUAD,
  FETCH_OFFICER_FAILED,
  FETCH_OFFICER,
  RESET_IS_GENERATE,
  IS_GENERATE,
} from "./constants";
const initialState = {
  reportData: {},
  fetchedAlert: [],
  squad: [],
  officer: [],
  isGenerate: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORT_DATA_SUCCESS:
      // var items = [];
      // var itemss = [
      //   [10, 100, 0, 0],
      //   [20, 200, 0, 0],
      //   [30, 300, 0, 0],
      // ];
      // itemss.map((item, i) => {
      //   var actual = item;
      //   var removed = actual.shift();
      //   actual.map((item2, j) => {
      //     var sum = items[j] ? items[j] : 0;
      //     items[j] = sum + item2;
      //   });
      // });
      // var final = items.unshift("Total");
      // console.log(items);
      return {
        ...state,
        reportData: action.data.data,
      };
    case FETCH_REPORT_DATA_FAILED:
      sessionStorage.setItem("reportsMasterError", true);
      return {
        ...state,
        reportData: {},
      };
    case RESET_REPORT_DATA:
      sessionStorage.setItem("reportsMasterError", false);
      return {
        ...state,
        reportData: {},
      };
    case RESET_IS_GENERATE:
      return {
        ...state,
        isGenerate: false,
      };
    case IS_GENERATE:
      return {
        ...state,
        isGenerate: !state.isGenerate,
      };
    case FETCH_ALERT:
      return {
        ...state,
        fetchedAlert: action.data.data,
      };
    case FETCH_ALERT_FAILED:
      return {
        ...state,
        fetchedAlert: [],
      };
    case FETCH_SQUAD:
      return {
        ...state,
        squad: action.data.squad,
      };
    case FETCH_SQUAD_FAILED:
      return {
        ...state,
        squad: [],
      };
    case FETCH_OFFICER:
      return {
        ...state,
        officer: action.data.data,
      };
    case FETCH_OFFICER_FAILED:
      return {
        ...state,
        officer: [],
      };
    default:
      return state;
  }
}
